import Handlebars from "handlebars-template-loader/runtime";

Handlebars.registerHelper("addClassToSubstring", function(
  string,
  substring,
  classValue,
) {
  var result = string;

  var startIndex = string.toLowerCase().indexOf(substring.toLowerCase());
  var endIndex = startIndex + substring.length;

  if (startIndex >= 0) {
    var extract = string.substring(startIndex, endIndex);
    result = string.replace(
      extract,
      '<span class="' + classValue + '">' + extract + "</span>",
    );
  }

  return new Handlebars.SafeString(result);
});
