import CompoundChildrenCollection from "scripts/collections/compoundChildrenCollection";
import BaseView from "scripts/views/baseView";
import AlbumTrackListTileView from "scripts/views/albumTrackListTileView";
import templateContentDetailsAlbumTracksSubPage from "templates/contentDetailsAlbumTracksSubPage.hbs";

class ContentDetailsAlbumTracksSubPageView extends BaseView {
  constructor(options) {
    super(options);

    this.collection = new CompoundChildrenCollection([], {
      mediaId: this.model.get("mediaId"),
    });
  }

  get template() {
    return templateContentDetailsAlbumTracksSubPage;
  }

  render() {
    this.$el.html(this.template());
    this.collection.each(trackMediaModel => this.addMedia(trackMediaModel));
    return this;
  }

  sync() {
    return this.collection.fetch();
  }

  addMedia(trackMediaModel) {
    const listTileView = new AlbumTrackListTileView({
      model: trackMediaModel,
      albumModel: this.model,
      tracksCollection: this.collection,
      showThumbnail: false,
    });

    this.addSubView(listTileView.render());

    listTileView.attachTo(this.$el.find(".bb-album-tracks-list-tiles-container"));
  }
}

export default ContentDetailsAlbumTracksSubPageView;
