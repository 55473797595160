import MediaModel from "scripts/models/mediaModel";

class CurationMediaModel extends MediaModel {
  get constraints() {
    return {
      curation: {
        presence: true,
      },
    };
  }

  urlRoot() {
    const curationMediaId = this.get("curation") || this.collection.mediaId;

    return `${this.apiUrlRoot}/curations/${curationMediaId}/media`;
  }
}

export default CurationMediaModel;
