import BaseApiModel from "scripts/models/baseApiModel";
import inject from "scripts/ioc/inject";

class OrganizationSessionModel extends BaseApiModel {
  constructor(attrs, options, deviceService = inject("deviceService")) {
    super(attrs, options);

    this.deviceService = deviceService;

    this.setDefaultModelProperties();
  }

  setDefaultModelProperties() {
    this.set({
      platformType: this.deviceService.platform,
      platformVersion: process.env.BASE_VERSION,
    });
  }

  url() {
    return `${this.apiUrlRoot}/api/institutionSession`;
  }
}

export default OrganizationSessionModel;
