var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--BAR COL-->\n<div class=\"bb-list-tile-bar-column\">\n  <div class=\"list-header-media\"></div>\n</div>\n\n<!--IMAGE COL-->\n<div class=\"bb-list-tile-cover-column\">\n  <div class=\"bb-list-tile-cover\">\n    <img class=\"thumbnail-xs\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"iconUrl") || (depth0 != null ? lookupProperty(depth0,"iconUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":35},"end":{"line":9,"column":46}}}) : helper)))
    + "\" data-fallback-src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"iconFallbackUrl") || (depth0 != null ? lookupProperty(depth0,"iconFallbackUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconFallbackUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":67},"end":{"line":9,"column":86}}}) : helper)))
    + "\">\n  </div>\n</div>\n\n<!--COPY COL-->\n<div class=\"bb-list-tile-details-column-media\">\n  <div class=\"bb-list-tile-cover-media bb-list-tile-type-label\" aria-hidden=\"true\">\n    <i class=\"bbico bbico-category\"></i>\n    <span class=\"clr-primary-dark text-uppercase\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"category") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</span>\n  </div>\n\n  <a href=\"/search-results/_cat=%22"
    + alias4(((helper = (helper = lookupProperty(helpers,"bisacCode") || (depth0 != null ? lookupProperty(depth0,"bisacCode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bisacCode","hash":{},"data":data,"loc":{"start":{"line":20,"column":35},"end":{"line":20,"column":48}}}) : helper)))
    + "%22%26_f=%22BOOK%22%26eft=false%26sort=true\"\n  aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"explore") : stack1)) != null ? lookupProperty(stack1,"category") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\">\n    <h2 class=\"h3 bb-list-tile-title-media\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":22,"column":44},"end":{"line":22,"column":52}}}) : helper)))
    + "</h2>\n  </a>\n</div>\n\n<!--BUTTONS COL-->\n<div class=\"bb-list-tile-buttons-column-media\" aria-hidden=\"true\">\n  <div class=\"bb-list-tile-text-buttons-row\">\n    <a class=\"btn btn-view btn-list-media\" href=\"/search-results/_cat=%22"
    + alias4(((helper = (helper = lookupProperty(helpers,"bisacCode") || (depth0 != null ? lookupProperty(depth0,"bisacCode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bisacCode","hash":{},"data":data,"loc":{"start":{"line":29,"column":73},"end":{"line":29,"column":86}}}) : helper)))
    + "%22%26_f=%22BOOK%22%26eft=false%26sort=true\"\n    data-toggle=\"tooltip\" title=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"explore") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n      <span class=\"fnt-bld\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"explore") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</span>\n    </a>\n  </div>\n</div>";
},"useData":true});