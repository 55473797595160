import _ from "lodash";
import BaseListTileView from "scripts/views/baseListTileView";
import templateBookListTile from "templates/bookListTile.hbs";

class BookListTileView extends BaseListTileView {
  get template() {
    return templateBookListTile;
  }

  get events() {
    return _.extend({}, super.events, {
      "click .btn-bookshelf": "onClickBookshelfButton",
    });
  }

  constructor(options) {
    super(options);

    if (this.bookshelfAvailable()) {
      this.initializeBookshelfDeps();
    }
  }

  render() {
    super.render();

    if (this.bookshelfAvailable()) {
      this.renderBookshelfElements();
    }

    return this;
  }
}

export default BookListTileView;
