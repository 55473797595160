import inject from "scripts/ioc/inject";
import BookmarkedContentCollection from "scripts/collections/bookmarkedContentCollection";
import BreadcrumbModel from "scripts/models/breadcrumbModel";
import BaseView from "scripts/views/baseView";
import MediaSalvattoreGridView from "scripts/views/mediaSalvattoreGridView";
import MediaListView from "scripts/views/mediaListView";
import { getCurrentFragment } from "scripts/utils/routerHelpers";

import templateBookmarksSubPage from "templates/bookmarksSubPage.hbs";

class BookmarksSubPageView extends BaseView {
  constructor(options, securityService = inject("securityService")) {
    super(options);

    this.securityService = securityService;

    const user = this.securityService.getUser();

    this.collection = new BookmarkedContentCollection([], {
      path: `/users/${user.getUserId()}/bookmarked-content`,
    });

    if (this.model.get("presentationType") === "masonry") {
      this.contentView = this.addSubView(
        new MediaSalvattoreGridView({
          collection: this.collection,
        }),
      );
    } else {
      this.contentView = this.addSubView(
        new MediaListView({
          collection: this.collection,
        }),
      );
    }
  }

  get template() {
    return templateBookmarksSubPage;
  }

  render() {
    this.$el.html(this.template());

    this.contentView
      .appendTo($("#bb-bookmarks-sub-page-content-region"))
      .render();

    return this;
  }

  sync() {
    return this.collection.fetch();
  }

  getBreadcrumbInfo() {
    return {
      breadcrumbModel: new BreadcrumbModel({
        text: "Bookmarks",
        path: "#" + getCurrentFragment(),
        topLevel: true,
      }),
    };
  }

  getDocumentTitle() {
    return "Bookmarks";
  }
}

export default BookmarksSubPageView;
