var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"auth") : stack1)) != null ? lookupProperty(stack1,"emailVerification") : stack1), depth0))
    + " <span class=\"clr-primary\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"auth") : stack1)) != null ? lookupProperty(stack1,"failed") : stack1), depth0))
    + "</span></h1>\n\n<div>\n    <p>T"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"auth") : stack1)) != null ? lookupProperty(stack1,"problem") : stack1), depth0))
    + "</p>\n</div>\n\n<div id=\"bb-email-verification-page-resend-form-region\"></div>\n\n";
},"useData":true});