var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <li id=\"bb-my-board-page-nav-item-bookshelf\">\n                                        <a href=\"/my-board/bookshelf/"
    + alias1(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":48,"column":69},"end":{"line":48,"column":89}}}) : helper)))
    + "\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"offlineBookshelf") : stack1), depth0))
    + "</a>\n                                    </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <li id=\"bb-my-board-page-nav-item-bookshelf\">\n                                    <a href=\"/my-board/bookshelf/"
    + alias1(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":137,"column":65},"end":{"line":137,"column":85}}}) : helper)))
    + "\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"offlineBookshelf") : stack1), depth0))
    + "</a>\n                                </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", alias6=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"my-board-nav\">\n    <div class=\"container\">\n      <div class=\"row-same-height row-full-height\">\n            <nav>\n                <div class=\"navigation-container\">\n                    <div class=\"myboard-navigation-wrapper\">\n\n                        <div class=\"navbar-header\">\n                            <div class=\"navbar-header-container\">\n\n                                <div class=\"fb-row fb-ai-center\">\n                                    <div class=\"active-tab\">\n                                        <div id=\"bb-my-board-page-nav-header-favorites\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"favorites") : stack1), depth0))
    + "</div>\n                                        <div id=\"bb-my-board-page-nav-header-bookmarks\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"bookmarks") : stack1), depth0))
    + "</div>\n                                        <div id=\"bb-my-board-page-nav-header-recents\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"recents") : stack1), depth0))
    + "</div>\n                                        <div id=\"bb-my-board-page-nav-header-bookshelf\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"offlineBookshelf") : stack1), depth0))
    + "</div>\n                                    </div>\n\n                                    <div class=\"m-l-10\">\n                                        <button type=\"button\" class=\"navbar-toggle collapsed\" data-toggle=\"collapse\" data-target=\"#my-board-cntntnavbar\">\n                                            <span class=\"sr-only\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"toggleNav") : stack1), depth0))
    + "</span>\n                                            <span class=\"fa fa-angle-down clr-primary\"></span>\n                                            <span class=\"fa fa-angle-up clr-primary\"></span>\n                                        </button>\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n\n\n                        <!-- Collect the nav links, forms, and other content for toggling -->\n                        <div id=\"bb-my-board-tab-list\" class=\"nav-tab-list\" role=\"navigation\" aria-label=\"Item details\">\n                            <ul class=\"nav navbar-nav\">\n                                <li id=\"bb-my-board-page-nav-item-favorites\"><a\n                                        href=\"/my-board/favorites/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":35,"column":66},"end":{"line":35,"column":86}}}) : helper)))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"favorites") : stack1), depth0))
    + "</a>\n                                </li>\n\n                                <li id=\"bb-my-board-page-nav-item-bookmarks\"><a\n                                        href=\"/my-board/bookmarks/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":39,"column":66},"end":{"line":39,"column":86}}}) : helper)))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"bookmarks") : stack1), depth0))
    + "</a>\n                                </li>\n\n                                <li id=\"bb-my-board-page-nav-item-recents\"><a\n                                        href=\"/my-board/recents/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":43,"column":64},"end":{"line":43,"column":84}}}) : helper)))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"recents") : stack1), depth0))
    + "</a>\n                                </li>\n\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"hasOfflineBookshelf") || (depth0 != null ? lookupProperty(depth0,"hasOfflineBookshelf") : depth0)) != null ? helper : alias4),(options={"name":"hasOfflineBookshelf","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":32},"end":{"line":50,"column":56}}}),(typeof helper === alias5 ? helper.call(alias3,options) : helper));
  if (!lookupProperty(helpers,"hasOfflineBookshelf")) { stack1 = alias6.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                            </ul>\n                        </div>\n\n                        <div class=\"collection-view-toggle\">\n                            <!--switch view type button-->\n                            <a id=\"bb-my-board-page-nav-presentation-type-toggle-favorites-list\"\n                               aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"list") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                               class=\"fade-on-hover hide cntntmast-view-type-button\"\n                               href=\"/my-board/favorites/list\">\n                                <div><i class=\"bbico bbico-list m-r-5\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"list") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</div>\n                            </a>\n\n                            <a id=\"bb-my-board-page-nav-presentation-type-toggle-favorites-masonry\"\n                               aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"grid") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                               class=\"fade-on-hover hide cntntmast-view-type-button\"\n                               href=\"/my-board/favorites/masonry\">\n                                <div><i class=\"fa fa-th m-r-5\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"grid") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</div>\n                            </a>\n\n                            <!-- bookmarks -->\n                            <a id=\"bb-my-board-page-nav-presentation-type-toggle-bookmarks-list\"\n                               aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"list") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                               class=\"fade-on-hover hide cntntmast-view-type-button\"\n                               href=\"/my-board/bookmarks/list\">\n                                <div><i class=\"bbico bbico-list m-r-5\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"list") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</div>\n                            </a>\n\n                            <a id=\"bb-my-board-page-nav-presentation-type-toggle-bookmarks-masonry\"\n                               aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"grid") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                               class=\"fade-on-hover hide cntntmast-view-type-button\"\n                               href=\"/my-board/bookmarks/masonry\">\n                                <div><i class=\"fa fa-th m-r-5\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"grid") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</div>\n                            </a>\n\n                            <!-- recents -->\n                            <a id=\"bb-my-board-page-nav-presentation-type-toggle-recents-list\"\n                               aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"list") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                               class=\"fade-on-hover hide cntntmast-view-type-button\"\n                               href=\"/my-board/recents/list\">\n                                <div><i class=\"bbico bbico-list m-r-5\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"list") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</div>\n                            </a>\n\n                            <a id=\"bb-my-board-page-nav-presentation-type-toggle-recents-masonry\"\n                               aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"grid") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                               class=\"fade-on-hover hide cntntmast-view-type-button\"\n                               href=\"/my-board/recents/masonry\">\n                                <div><i class=\"fa fa-th m-r-5\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"grid") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</div>\n                            </a>\n\n                            <!-- bookshelf -->\n                            <a id=\"bb-my-board-page-nav-presentation-type-toggle-bookshelf-list\"\n                               aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"list") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                               class=\"fade-on-hover hide cntntmast-view-type-button\"\n                               href=\"/my-board/bookshelf/list\">\n                                <div><i class=\"bbico bbico-list m-r-5\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"list") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</div>\n                            </a>\n\n                            <a id=\"bb-my-board-page-nav-presentation-type-toggle-bookshelf-masonry\"\n                               aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"grid") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                               class=\"fade-on-hover hide cntntmast-view-type-button\"\n                               href=\"/my-board/bookshelf/masonry\">\n                                <div><i class=\"fa fa-th m-r-5\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"grid") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</div>\n                            </a>\n                        </div>\n\n                    </div>\n\n\n                    <!-- Hide this when in desktop -->\n                    <div id=\"my-board-cntntnavbar\" class=\"navbar-collapse collapse nav-tab-list\" role=\"navigation\" aria-label=\"Item details\">\n                        <ul class=\"nav navbar-nav\">\n                            <li id=\"bb-my-board-page-nav-item-favorites\"><a\n                                    href=\"/my-board/favorites/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":124,"column":62},"end":{"line":124,"column":82}}}) : helper)))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"favorites") : stack1), depth0))
    + "</a>\n                            </li>\n\n                            <li id=\"bb-my-board-page-nav-item-bookmarks\"><a\n                                    href=\"/my-board/bookmarks/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":128,"column":62},"end":{"line":128,"column":82}}}) : helper)))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"bookmarks") : stack1), depth0))
    + "</a>\n                            </li>\n\n                            <li id=\"bb-my-board-page-nav-item-recents\"><a\n                                    href=\"/my-board/recents/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":132,"column":60},"end":{"line":132,"column":80}}}) : helper)))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"recents") : stack1), depth0))
    + "</a>\n                            </li>\n\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"hasOfflineBookshelf") || (depth0 != null ? lookupProperty(depth0,"hasOfflineBookshelf") : depth0)) != null ? helper : alias4),(options={"name":"hasOfflineBookshelf","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":135,"column":28},"end":{"line":139,"column":52}}}),(typeof helper === alias5 ? helper.call(alias3,options) : helper));
  if (!lookupProperty(helpers,"hasOfflineBookshelf")) { stack1 = alias6.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n                        </ul>\n                    </div>\n\n                </div>\n            </nav>\n        </div>\n    </div>\n</div>\n";
},"useData":true});