import _ from "lodash";
import BaseService from "scripts/services/baseService";
import inject from "scripts/ioc/inject";
import { getCurrentFragment } from "scripts/utils/routerHelpers";
import L from "lodash/fp";

const LOCAL_STORAGE_OPT_IN_KEY = "analytics-opt-in";

const chan = Backbone.Radio.channel;

class GoogleAnalyticsService extends BaseService {
  constructor(
    deviceService = inject("deviceService"),
    securityService = inject("securityService"),
    connectionService = inject("connectionService"),
  ) {
    super();

    this.deviceService = deviceService;
    this.securityService = securityService;
    this.connectionService = connectionService;

    this.googleAnalyticsKey = process.env.PATRON_UI_GOOGLE_ANALYTICS_KEY;

    // not used for legacy ga
    this.keys = [];

    chan("tracking").on("authentication:complete", this.setDynamicGoogleAnalyticsKey, this);
  }

  init() {
    if (this.deviceService.available) {
      console.log("Initializing hybrid analytics...");
      this.initHybrid();
    } else {
      console.log("Initializing web analytics...");
      this.initWeb();
    }
  }

  /**
   * To be used when turning on/off analytics in real time. IE, the user
   * selects/deselects analytics.
   */
  reinitialize() {
    this.init();
    this.setDynamicGoogleAnalyticsKey();
  }

  initHybrid() {
    // first remove the existing default
    this.keys.length = 0;

    if (this.isAnalyticsOptIn()) {
      if (gtag) {
        gtag("set", "allow_google_signals", false);
        gtag("set", "allow_ad_personalization_signals", false);
        gtag("set", "client_id", this.deviceService.uuid);
      }
    }
  }

  initWeb() {
    // first remove the existing default
    this.keys.length = 0;

    if (this.isAnalyticsOptIn()) {
      if (gtag) {
        gtag("set", "allow_google_signals", false);
        gtag("set", "allow_ad_personalization_signals", false);
      }
    }
  }

  initTrackers(keys) {
    //delete existing items
    this.keys.length = 0;

    // add items back if optin is yes
    if (this.isAnalyticsOptIn()) {
      if (this.googleAnalyticsKey) {
        this.keys.push(this.googleAnalyticsKey);
      }

      if (!_.isEmpty(keys)) {
        this.keys.push(...keys);
      }
    }
  }

  sendPageView(fragment) {
    if (this.connectionService.isOnline()) {
      fragment = fragment || getCurrentFragment();

      const gaRoute = "/" + fragment;

      console.log(`Google Analytics page view, route: ${gaRoute}`);

      const activeOrganizationId = this.securityService.getUser().getActiveOrganizationId();

      this.keys.forEach(k => {
        gtag("event", "page_view", {
          page_path: gaRoute,
          send_to: k,
          dimension1: activeOrganizationId ? activeOrganizationId : null,
        });
      });
    } else {
      console.log("Offline: skipping page view.");
    }
  }

  /**
   * See https://developers.google.com/analytics/devguides/collection/analyticsjs/events
   */
  sendEvent(category, action, label, nonInteraction) {
    if (this.connectionService.isOnline()) {
      console.log(`Google Analytics event, category: ${category}, action: ${action}, label: ${label},
        nonInteraction: ${nonInteraction}`);

      this.keys.forEach(k => {
        gtag("event", action, {
          event_category: category,
          event_label: label,
          non_interaction: nonInteraction,
          send_to: k,
        });
      });
    } else {
      console.log("Offline: skipping event.");
    }
  }

  setDynamicGoogleAnalyticsKey() {
    const user = this.securityService.getUser();
    const keys = !L.isNil(user) ? user.getGoogleAnalyticsKeys() : null;

    this.initTrackers(keys);
  }

  setAnalyticsOptIn(optin) {
    try {
      localStorage.setItem(LOCAL_STORAGE_OPT_IN_KEY, optin);
    } catch (error) {
      console.log("Error saving analytics optin: %O", error);
    }
    this.reinitialize();
  }

  isAnalyticsOptIn() {
    try {
      return (
        localStorage.getItem(LOCAL_STORAGE_OPT_IN_KEY) === null ||
        localStorage.getItem(LOCAL_STORAGE_OPT_IN_KEY) === "true"
      );
    } catch (error) {
      console.log("Error getting optin from localStorage: %O", error);
      return true;
    }
  }

  // whether the optin key has ever been set
  // used to show popup at least once
  isAnalyticsOptInSet() {
    try {
      return localStorage.getItem(LOCAL_STORAGE_OPT_IN_KEY) !== null;
    } catch (error) {
      console.log("Error accessing localStorage: %O", error);
      return false;
    }
  }
}

export default GoogleAnalyticsService;
