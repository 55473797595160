import L from "lodash/fp";
import Promise from "bluebird";
import inject from "scripts/ioc/inject";
import BaseModel from "scripts/models/baseModel";
import BaseFormView from "scripts/views/baseFormView";
import { saveWebToLead } from "scripts/utils/fetchSalesforce";

import template from "./RequestAccessForm.hbs";
import styles from "./RequestAccessForm.css";

class RequestAccessForm extends BaseFormView {
  constructor(options, securityService = inject("securityService")) {
    super(options);

    this.securityService = securityService;

    this.state = {
      mediaId: options.mediaId,
    };

    this.bindModel(
      new BaseModel(
        {},
        {
          constraints: this.constraints,
        },
      ),
    );
  }

  get constraints() {
    return {
      first_name: {
        presence: true,
      },
      last_name: {
        presence: true,
      },
      email: {
        presence: true,
        email: true,
      },
      company: {
        presence: true,
      },
      region: {
        presence: true,
      },
    };
  }

  get events() {
    return {
      "blur form *[name]": "onBlurValidate",
      "input form *[name]": "onInputEnableSubmit",
      "submit form": "onSubmit",
    };
  }

  doSubmit() {
    const user = this.securityService.getUser();
    const json = this.toJSON();

    const data = L.compose(
      L.merge({
        "00N0W000009TdFN": json.region,
        "00Nd00000085mKE": json.comments,
        "00N0W000009G9jz": this.state.mediaId,
        "00N0W000009G9mK": user.getUserId(),
      }),
      L.pick(["first_name", "last_name", "email", "phone", "company"]),
    )(json);

    saveWebToLead(data);

    // saveWebToLead is opaque
    return Promise.resolve();
  }

  render() {
    this.$el.html(
      template({
        styles,
      }),
    );

    const user = this.securityService.getUser();
    const activeOrganization = user.getActiveOrganization();
    const $firstNameField = this.findTarget("firstName");

    if (user.hasProfile()) {
      $firstNameField.val(user.getUserProfileProp("firstName"));
      this.findTarget("lastName").val(user.getUserProfileProp("lastName"));
      this.findTarget("email").val(user.getUserProfileProp("emailAddress"));
    } else {
      $firstNameField.focus();
    }

    if (activeOrganization && !activeOrganization.isPublic) {
      this.findTarget("libraryName").val(activeOrganization.name);
    }

    this.loaderView
      .appendTo(this.$el.find(".bb-form-submit-button-loader-region"))
      .render();

    return this;
  }
}

export default RequestAccessForm;
