import Handlebars from "handlebars-template-loader/runtime";

Handlebars.registerHelper("notEqual", function(lvalue, rvalue, options) {
  if (arguments.length < 3) {
    throw new Error("Handlebars Helper equal needs 2 parameters");
  } else if (lvalue !== rvalue) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});
