var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-body text-center\">\n    <i class=\"bbico bbico-times close fnt-12\" data-dismiss=\"modal\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"close") : stack1), depth0))
    + "\"></i>\n\n    <h1 class=\"modal-title text-center m-t-30\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"signIn") : stack1), depth0))
    + "</h1>\n\n    <p>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"createProfile") : stack1)) != null ? lookupProperty(stack1,"unlockFeatures") : stack1), depth0))
    + ":</p>\n\n    <div class=\"text-uppercase m-b-20\">\n        <span class=\"label bg-gray-dark\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"saveFavorites") : stack1), depth0))
    + "</span>\n        <span class=\"label bg-gray-dark\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"createBookmarks") : stack1), depth0))
    + "</span>\n        <span class=\"label bg-gray-dark\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"addNotes") : stack1), depth0))
    + "</span>\n        <span class=\"label bg-gray-dark\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"syncPreferences") : stack1), depth0))
    + "</span>\n    </div>\n\n    <div id=\"bb-sign-in-modal-login-form-region\" class=\"container-fluid\"></div>\n\n    <div class=\"m-b-40 m-t-20\">\n        <div class=\"h4 text-notransform m-t-30\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"dontHaveProfile") : stack1), depth0))
    + "</div>\n        <button id=\"bb-sign-in-modal-create-profile-link\" class=\"btn-outline-rounded btn-outline-gray m-t-10 p-lr-30\" data-dismiss=\"modal\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"getStartedNow") : stack1), depth0))
    + "</button>\n    </div>\n</div>";
},"useData":true});