import inject from "scripts/ioc/inject";
import BaseMediaSalvattoreTileView from "scripts/views/baseMediaSalvattoreTileView";

import templateCurationSalvattoreTile from "templates/curationSalvattoreTile.hbs";

class CurationSalvattoreTileView extends BaseMediaSalvattoreTileView {
  constructor(options, securityService = inject("securityService")) {
    super(options, securityService);
  }

  get template() {
    return templateCurationSalvattoreTile;
  }
}

export default CurationSalvattoreTileView;
