import BaseView from "scripts/views/baseView";
import CurationMediaThumbnailTileView from "scripts/views/curationMediaThumbnailTileView";
import templateCurationMediaThumbnailGrid from "templates/curationMediaThumbnailGrid.hbs";
import CurationUploadThumbnailTileView from "./curationUploadThumbnailTileView";
import CustomCurationThumbnailModel
  from "../models/customCurationThumbnailModel";

class CurationMediaThumbnailGridView extends BaseView {
  get template() {
    return templateCurationMediaThumbnailGrid;
  }

  render() {
    this.$el.html(this.template());

    this.addUploadTile();
    this.addMediaTiles(this.collection.thumbnailModels());

    return this;
  }

  addUploadTile(uploadModel) {
    const curationMediaId = this.model.get("id");

    const tileView = this.addSubView(
        new CurationUploadThumbnailTileView({
            model: new CustomCurationThumbnailModel({
              curationMediaId: curationMediaId,
            }),
        }),
    );

    this.bubble("thumbnailUploaded", tileView);
    this.bubble("thumbnailUploadCanceled", tileView);
    tileView
      .appendTo($("#bb-curation-media-thumbnail-grid-tile-region"))
      .render();
  }

  addMediaTiles(mediaModels) {
    mediaModels.forEach(mediaModel => {
      const tileView = this.addSubView(
        new CurationMediaThumbnailTileView({
          model: mediaModel,
          curationModel: this.model,
        }),
      );

      this.bubble("thumbnailSelected", tileView);

      tileView
        .appendTo($("#bb-curation-media-thumbnail-grid-tile-region"))
        .render();
    });
  }
}

export default CurationMediaThumbnailGridView;
