import Backbone from "backbone";
import dateFormat from "dateformat";

class CitationModel extends Backbone.Model {
  parse(response) {
    // replace the default host in the citation with the current host
    if (response && response.citation) {
      response.citation = response.citation.replace(/BiblioBoard/, this.getDatabaseFromSubdomain());

      // console.log(response.citation);

      // the server will only respond with library(-test) hence the hard code here
      response.citation = response.citation.replace(
        /https:\/\/library(-test)?\.biblioboard\.com/gi,
        window.location.origin,
      );

      response.citation = this.replaceWithTodaysDate(response.citation);
    }

    return response;
  }

  getDatabaseFromSubdomain() {
    const currentLocation = window.location.href;

    if (/^.*ebooks\.ohiolink\.edu/.test(currentLocation)) {
      return "OhioLINK EBC";
    } else if (/^https?:\/\/openresearchlibrary.org/.test(currentLocation)) {
      return "Open Research Library";
    } else {
      return "BiblioBoard";
    }
  }

  // this is hacky, but lets the backend maintain it's cache forever
  replaceWithTodaysDate(citation) {
    const now = new Date();
    var newCitation = citation
      .replace(
        /(January|Feburary|March|April|May|June|July|August|September|October|November|December)\s*\d{1,2},\s*\d{4}/,
        dateFormat(now, "mmmm d, yyyy"),
      )
      // this one is wonky from the service sometimes returning the full
      .replace(
        /\d{1,2}\s*(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec|January|Feburary|March|April|May|June|July|August|September|October|November|December)\.\s*\d{4}/,
        dateFormat(now, "d mmm. yyyy"),
      );

    return newCitation;
  }
}

export default CitationModel;
