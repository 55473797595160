import _ from "lodash";
import Controller from "scripts/controllers/controller";
import ModuleModel from "scripts/models/moduleModel";
import ModulePageModel from "scripts/models/modulePageModel";
import ModulePageView from "scripts/views/modulePageView";
import inject from "scripts/ioc/inject";
import BaseView from "scripts/views/baseView";

class ModuleController extends Controller {
  constructor(securityService = inject("securityService")) {
    super();

    this.securityService = securityService;
  }

  get route() {
    return "route:module";
  }

  get defaults() {
    return {
      presentationType: "masonry",
      pageIndex: 0,
    };
  }

  routeAttrs() {
    const user = this.securityService.getUser();

    let pageIndex = parseInt(arguments[2], 10);

    if (!isNaN(pageIndex)) {
      pageIndex = pageIndex - 1;
    } else {
      pageIndex = 0;
    }

    return _.merge(
      {},
      this.defaults,
      {
        presentationType: user.getContentViewPreference(),
      },
      {
        internalCode: arguments[0],
        presentationType: arguments[1] || void 0,
        pageIndex,
      },
    );
  }

  replyRoute() {
    let attrs = this.routeAttrs.apply(this, _.tail(arguments));

    console.log("attrs: %O", attrs);

    return this.showMainBodyView(
      new ModulePageView({
        subPageView: new BaseView(),
        model: new ModulePageModel(attrs),
        mediaModel: new ModuleModel({
          id: attrs.internalCode,
        }),
      }),
    );
  }
}

export default ModuleController;
