var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-xs-12 col-md-4 fnt-bld\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"geoTime") : stack1), depth0))
    + "</div>\n<div class=\"col-xs-12 col-md-8\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"gpsLatitude") || (depth0 != null ? lookupProperty(depth0,"gpsLatitude") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"gpsLatitude","hash":{},"data":data,"loc":{"start":{"line":2,"column":32},"end":{"line":2,"column":47}}}) : helper)))
    + " / "
    + alias1(((helper = (helper = lookupProperty(helpers,"gpsLongitude") || (depth0 != null ? lookupProperty(depth0,"gpsLongitude") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"gpsLongitude","hash":{},"data":data,"loc":{"start":{"line":2,"column":50},"end":{"line":2,"column":66}}}) : helper)))
    + " ("
    + alias1(((helper = (helper = lookupProperty(helpers,"gpsTime") || (depth0 != null ? lookupProperty(depth0,"gpsTime") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"gpsTime","hash":{},"data":data,"loc":{"start":{"line":2,"column":68},"end":{"line":2,"column":79}}}) : helper)))
    + ")</div>\n";
},"useData":true});