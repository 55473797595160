import CompoundChildrenCollection from "scripts/collections/compoundChildrenCollection";
import BaseView from "scripts/views/baseView";
import SummaryContentListTileView from "scripts/views/summaryContentListTileView";
import templateContentDetailsChaptersSubPage from "templates/contentDetailsChaptersSubPage.hbs";

class ContentDetailsChaptersSubPageView extends BaseView {
  constructor(options) {
    super(options);

    this.collection = new CompoundChildrenCollection([], {
      mediaId: this.model.get("mediaId"),
    });
  }

  get template() {
    return templateContentDetailsChaptersSubPage;
  }

  render() {
    this.$el.html(this.template());
    this.collection.each(mediaModel => this.addMedia(mediaModel));
    return this;
  }

  sync() {
    return this.collection.fetch();
  }

  addMedia(mediaModel) {
    const listTileView = new SummaryContentListTileView({
      model: mediaModel,
    });

    this.addSubView(listTileView.render());

    listTileView.attachTo(this.$el.find(".bb-chapter-list-tiles-container"));
  }
}

export default ContentDetailsChaptersSubPageView;
