import L from "lodash/fp";
import inject from "scripts/ioc/inject";
import Backbone from "backbone";
import Controller from "scripts/controllers/controller";
import OrganizationSessionModel from "scripts/models/organizationSessionModel";
import { pass } from "scripts/utils/generalHelpers";
import { logSessionLocation } from "scripts/utils/fetchSecurity";
import { fetchPosition } from "scripts/utils/geoHelpers";
import { formatIso8601 } from "scripts/utils/dateUtil";

const chan = Backbone.Radio.channel;

class OrganizationSessionController extends Controller {
  constructor(
    securityService = inject("securityService"),
    connectionService = inject("connectionService"),
  ) {
    super();

    this.securityService = securityService;
    this.connectionService = connectionService;

    const locationListener = () => {
      fetchPosition(navigator.geolocation)
        .then(pass(() => this.doLogSessionLocation()))
        .catch(e => {
          console.log(
            "Error fetching geolocation session location will not be logged: %O",
            e,
          );
          document.removeEventListener("resume", locationListener, false);
        });
    };

    document.addEventListener("resume", locationListener, false);

    chan("tracking").on(
      "authentication:complete",
      this.logOrganizationSession,
      this,
    );

    chan("tracking").on(
      "authentication:complete",
      this.doLogSessionLocation,
      this,
    );
  }

  logOrganizationSession() {
    if (this.connectionService.isOnline()) {
      const user = this.securityService.getUser();

      console.log("Logging organization session: %s", user.getToken());

      new OrganizationSessionModel({
        sessionId: user.getToken(),
      })
        .save()
        .catch(e => console.log("Error logging session location", e));
    }
  }

  doLogSessionLocation() {
    if (this.connectionService.isOnline()) {
      const user = this.securityService.getUser();

      if (!L.isNil(user)) {
        const lastSeenCoordsString = window.sessionStorage.getItem(
          "lastSeenCoords",
        );
        const lastSeenCoords = lastSeenCoordsString
          ? JSON.parse(lastSeenCoordsString)
          : {};

        const date = new Date();

        const sessionLocationParams = [
          user.getToken(),
          user.getActiveOrganizationId(),
          formatIso8601(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
            date.getTimezoneOffset(),
          ),
          this.securityService.referer,
          lastSeenCoords.latitude,
          lastSeenCoords.longitude,
        ];

        console.log("Logging session location", sessionLocationParams);

        logSessionLocation(...sessionLocationParams).catch(e =>
          console.log("Error logging session location: %O", e),
        );
      }
    }
  }
}

export default OrganizationSessionController;
