var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container pagination-container\" role=\"navigation\" aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"pager") : stack1), depth0))
    + "\">\n    <div class=\"pagination-bar\">\n        <div class=\" pager-prev\">\n            <!--prev page btn-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPreviousPage") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams),"inverse":container.program(4, data, 0, blockParams),"data":data,"blockParams":blockParams,"loc":{"start":{"line":8,"column":12},"end":{"line":20,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"totalPages") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams),"inverse":container.program(12, data, 0, blockParams),"data":data,"blockParams":blockParams,"loc":{"start":{"line":23,"column":8},"end":{"line":38,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"pager-next\">\n            <!--next page btn-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasNextPage") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams),"inverse":container.program(16, data, 0, blockParams),"data":data,"blockParams":blockParams,"loc":{"start":{"line":42,"column":12},"end":{"line":54,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"prevPage") : stack1), depth0))
    + "\"\n                        class=\"bb-pager-previous-page-link bb-pager-view-page-link btn-text btn-text-brand-primary pager-prev\">\n                    <span class=\"m-r-10\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"pager") : stack1)) != null ? lookupProperty(stack1,"prev") : stack1), depth0))
    + "</span>\n                    <i class=\"bbico bbico-chevron-left\"></i>\n                </button>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"btn-text\"\n                        aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"prevPage") : stack1), depth0))
    + "\" disabled=\"disabled\">\n                    <span class=\"m-r-10\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"pager") : stack1)) != null ? lookupProperty(stack1,"prev") : stack1), depth0))
    + "</span>\n                    <i class=\"bbico bbico-chevron-left\"></i>\n                </button>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"pageList") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":24,"column":12},"end":{"line":35,"column":21}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"pager-page\">\n"
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),blockParams[0][0],"X",{"name":"equal","hash":{},"fn":container.program(8, data, 0, blockParams),"inverse":container.program(10, data, 0, blockParams),"data":data,"blockParams":blockParams,"loc":{"start":{"line":26,"column":20},"end":{"line":33,"column":30}}})) != null ? stack1 : "")
    + "                </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                        <span class=\"pager-ellipsis\">...</span>\n";
},"10":function(container,depth0,helpers,partials,data,blockParams) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                        <button aria-label=\"page "
    + alias2(alias1(blockParams[1][0], depth0))
    + "\" value=\""
    + alias2(alias1(blockParams[1][0], depth0))
    + "\"\n                                class=\"bb-pager-page-btn pager-page-num-"
    + alias2(alias1(blockParams[1][0], depth0))
    + " btn-text\">\n                            "
    + alias2(alias1(blockParams[1][0], depth0))
    + "\n                        </button>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"p-lr-20\"></div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"nextPage") : stack1), depth0))
    + "\"\n                        class=\"bb-pager-next-page-link bb-pager-view-page-link btn-text  btn-text-brand-primary\">\n                    <i class=\"bbico bbico-chevron-right\"></i>\n                    <span class=\"m-l-10\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"pager") : stack1)) != null ? lookupProperty(stack1,"next") : stack1), depth0))
    + "</span>\n                </button>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"btn-text\"\n                        aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"nextPage") : stack1), depth0))
    + "\" disabled=\"disabled\">\n                    <i class=\"bbico bbico-chevron-right\"></i>\n                    <span class=\"m-l-10\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"pager") : stack1)) != null ? lookupProperty(stack1,"next") : stack1), depth0))
    + "</span>\n                </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"bb-pager-media-view-region\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showPager") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":3,"column":0},"end":{"line":58,"column":7}}})) != null ? stack1 : "")
    + "\n";
},"useData":true,"useBlockParams":true});