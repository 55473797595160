import Backbone from "backbone";
import BaseApiModel from "scripts/models/baseApiModel";

const chan = Backbone.Radio.channel;

class DisplayNameModel extends BaseApiModel {
  url() {
    const userId = chan("security")
      .request("user")
      .getUserId();

    return `${this.apiUrlRoot}/users/${userId}/comments/display-name`;
  }
}

export default DisplayNameModel;
