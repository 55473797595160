import { redirectToFragment } from "../../utils/routerHelpers";
import BaseModalView from "scripts/views/baseModalView";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";

import templateFacetedSearchFullTextMenuItem from "templates/search/facetedSearchOptionsMenuItem.hbs";

const chan = Backbone.Radio.channel;

class FacetedSearchOptionsMenuItemView extends BaseModalView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;
  }

  get events() {
    return {
      "change .faceted-search-checkbox": "onCheckboxChange",
      "click .faceted-search-facet-clear-btn": "onClearClicked",
      "click #bb-faceted-search-close-facet-menu": "closeFacetMenu",
    };
  }

  get className() {
    return "faceted-search-menu-item";
  }

  get template() {
    return templateFacetedSearchFullTextMenuItem;
  }

  render() {
    const searchField = this.model.get("searchField");

    const fullText = searchField ? searchField.isFullText : false;
    const titleOnly = searchField && searchField.field === "t";

    const i18n = this.getTranslations();

    this.$el.html(
      this.template({
        i18n,
        fullText,
        titleOnly,
      }),
    );

    return this;
  }

  getTranslations() {
    const { search } = this.i18nextService.getGlossary();
    const { clear } = L.get(["button"], this.i18nextService.getCommon());

    return {
      clear,
      search,
    };
  }

  onCheckboxChange(event) {
    const isChecked = event.currentTarget.checked;
    const $target = $(event.currentTarget);

    const $checkboxLabel = $target.parent().find("label.checkbox");
    if (isChecked) {
      //-- Reset both checkboxes
      this.clearCheckboxes();
      if ($target.val() === "fullText") {
        $("#titleOnly").prop("checked", false);
      } else {
        $("#fullText").prop("checked", false);
      }

      //-- Set the visual checkbox to checked.
      $checkboxLabel.addClass("checked");
    } else {
      $checkboxLabel.removeClass("checked");
    }

    this.triggerSearchUpdate();
  }

  onClearClicked() {
    this.trigger("clearAll");
  }

  triggerSearchUpdate() {
    const searchField = this.model.get("searchField");

    const isTitleOnly = $("#titleOnly").prop("checked");
    const isFullText = $("#fullText").prop("checked");

    this.trigger("apply", {
      term: searchField ? searchField.term : "*",
      field: isTitleOnly ? "t" : "g",
      isFullText,
    });
  }

  clearCheckboxes() {
    this.$el.find("label.checkbox").removeClass("checked");
  }

  closeFacetMenu() {
    const initialSearch = chan("facetMenu").request("toggle");
    redirectToFragment(initialSearch);
  }
}

export default FacetedSearchOptionsMenuItemView;
