import Backbone from "backbone";
import PromisedSyncHoc from "scripts/hoc/promisedSyncHoc";

class FeatureFlagsModel extends PromisedSyncHoc(Backbone.Model) {
  get defaults() {
    return {
      id: "featureFlags",
    };
  }

  constructor(attributes, options) {
    super(attributes, options);
    this.localStorage = new Backbone.LocalStorage("featureFlags");
  }

  enableFeature(feature) {
    this.set(feature, true);
    return this.save();
  }

  disableFeature(feature) {
    this.set(feature, false);
    return this.save();
  }

  isEnabled(feature) {
    return this.get(feature);
  }
}

export default FeatureFlagsModel;
