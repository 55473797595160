import _ from "lodash";
import L from "lodash/fp";
import inject from "scripts/ioc/inject";
import BaseFormView from "./baseFormView";
import CurationCollection from "scripts/collections/curationCollection";
import templateCurationAddMediaForm from "templates/curationAddMediaForm.hbs";

const formatCurationOption = curationMap => option => {
  const curation = curationMap[option.id];

  if (curation) {
    return $(
      `<div class="bb-curation-autocomplete-option">
        <div class="bb-curation-autocomplete-icon" style="background-image: url('${curation.thumbnailUrl}')"></div>
        <div class="bb-curation-autocomplete-text">${curation.title}</div>
      </div>`,
    );
  } else {
    return $(`<span>${option.text}</span>`);
  }
};

class CurationAddMediaFormView extends BaseFormView {
  get template() {
    return templateCurationAddMediaForm;
  }

  get events() {
    return {
      "change form *[name]": "onInputEnableSubmit",
      "submit form": "onSubmit",
      "click #bb-curation-add-media-form-new-curation": "onClickCreateCuration",
    };
  }

  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.curationCollection = new CurationCollection([], {
      comparator: "title",
      queryStringParams: {
        unpublished: true,
        sort: "title",
      },
    });

    this.bindModel(this.model);
  }

  render() {
    const curations = this.curationCollection.toJSON();

    const { add, cancel } = L.get(["button"], this.i18nextService.getCommon());

    const i18n = {
      add,
      cancel,
      createNew: L.get(
        ["curation", "button", "createNew"],
        this.i18nextService.getGlossary(),
      ),
    };

    this.$el.html(
      this.template({
        i18n,
        curations,
      }),
    );

    const curationMap = {};
    curations.forEach(curation => {
      curationMap[curation.id] = curation;
    });

    this.loaderView
      .appendTo(this.$el.find(".bb-form-submit-button-loader-region"))
      .render();

    this.$el.find("#bb-curation-add-media-form-curation-select").select2({
      dropdownParent: $("#bb-app-modal"),
      placeholder: "Select a curation",
      templateResult: formatCurationOption(curationMap),
    });

    this.enableSubmit(false);

    return this;
  }

  sync() {
    return this.curationCollection.fetch();
  }

  onSubmitFailed(error) {
    this.enableSubmit(true);

    this.trigger("failure", error);
  }

  onSubmitSucceeded() {
    this.enableSubmit(false);
    this.trigger("success", this.model.get("curation"));
  }

  save() {
    this.clearErrors();

    const ids = {
      ids: _.map(this.model.get("media"), i => i.get("id")),
    };

    return this.model.save(ids, {
      type: "POST",
    });
  }

  onClickCreateCuration() {
    this.trigger("createCuration");
  }
}

export default CurationAddMediaFormView;
