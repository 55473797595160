import BaseApiCollection from "scripts/collections/baseApiCollection";
import AnthologyCollectionModel from "scripts/models/anthologyCollectionModel";

class AnthologyCollectionCollection extends BaseApiCollection {
  get model() {
    return AnthologyCollectionModel;
  }
}

export default AnthologyCollectionCollection;
