import Handlebars from "handlebars-template-loader/runtime";
import inject from "scripts/ioc/inject";

Handlebars.registerHelper("featureFlag", function(feature, options) {
  const featureFlagsService = inject("featureFlagsService");

  if (featureFlagsService.isEnabled(feature)) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});
