import _ from "lodash";
import Backbone from "backbone";
import CompoundChildrenCollection from "../collections/compoundChildrenCollection";
import BaseListTileView from "scripts/views/baseListTileView";
import templateAlbumListTile from "templates/albumListTile.hbs";

const chan = Backbone.Radio.channel;

class AlbumListTileView extends BaseListTileView {
  get template() {
    return templateAlbumListTile;
  }

  get events() {
    return _.extend({}, super.events, {
      "click #bb-album-list-tile-button": "onClickAlbumPlay",
    });
  }

  onClickViewer() {
    if (this.model.has("referringParent")) {
      this.saveReferringParent();
    }

    chan("display").request("playAudio", this.model);
    chan("tracking").trigger("viewer:click", this.model);
    return false;
  }

  async onClickAlbumPlay() {
    const tracksCollection = new CompoundChildrenCollection([], {
      mediaId: this.model.get("id"),
    });
    await tracksCollection.fetch();
    chan("display").request("playAlbum", this.model, 0, tracksCollection);
  }
}

export default AlbumListTileView;
