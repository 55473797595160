import Promise from "bluebird";
import BaseApiModel from "scripts/models/baseApiModel";
import Util from "scripts/utils/util";

class ResetPasswordModel extends BaseApiModel {
  get constraints() {
    return {
      password: {
        presence: true,
        length: {
          minimum: 8,
        },
        format: {
          pattern: Util.passwordValidationRegex,
          message: "must contain at least one number",
        },
      },

      confirmPassword: {
        presence: true,
        equality: "password",
      },
    };
  }

  url() {
    //TODO: implement this
    return "implement me";
  }

  //TODO: remove me
  save(attrs) {
    this.set(attrs);

    if (this.isValid()) {
      return new Promise(resolve => {
        setTimeout(resolve, 600);
      });
    } else {
      return false;
    }
  }
}

export default ResetPasswordModel;
