import _ from "lodash";
import BaseGrowlAlertView from "scripts/views/baseGrowlAlertView";
import templateAppUpdateGrowlAlert from "templates/appUpdateGrowlAlert.hbs";

class AppUpdateGrowlAlertView extends BaseGrowlAlertView {
  get template() {
    return templateAppUpdateGrowlAlert;
  }

  get events() {
    return _.extend({}, super.events, {
      "click #bb-app-update-growl-alert-refresh-button": "onClickRefresh",
    });
  }

  render() {
    this.$el.html(this.template());

    this.animateIn();

    return this;
  }

  onClickRefresh() {
    window.location.reload();
    return false;
  }
}

export default AppUpdateGrowlAlertView;
