import Backbone from "backbone";
import BaseView from "scripts/views/baseView";

const chan = Backbone.Radio.channel;

class EmbeddedVideoShadowBoxView extends BaseView {
  constructor(options) {
    super(options);

    this.model = new Backbone.Model();
  }

  render() {
    this.setupShadowBox();

    return this;
  }

  setupShadowBox() {
    $.fancybox.open({
      helpers: {
        overlay: {
          locked: true,
        },
      },
      href: this.model.get("viewUrl"),
      type: "iframe",
      autoSize: false,
      width: 640,
      height: 360,
      aspectRatio: true,
      scrolling: "no",
      afterShow: this.onShadowBoxReady.bind(this),
    });
  }

  onShadowBoxReady() {
    // Needed to correct z-index issue with iOS devices (UIWebView)
    $(".fancybox-wrap.fancybox-mobile").css("transform", "translate3d(0,0,0)");

    chan("tracking").trigger("content:open", {
      mediaId: this.model.get("id"),
      contentType: this.model.get("type"),
    });

    const fancyboxIframe = $(".fancybox-iframe").get(0);

    if (fancyboxIframe) {
      fancyboxIframe.setAttribute("title", "Media player iframe");
    }
  }

  removeShadowBox() {
    if ($.fancybox.isOpen) {
      $.fancybox.close(true);
      console.log("FancyBox closed");
    }
  }

  close(...args) {
    this.removeShadowBox();

    super.close(...args);
  }
}

export default EmbeddedVideoShadowBoxView;
