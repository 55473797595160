var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <button\n      id=\"bb-audio-quality-button\"\n      type=\"button\" \n      class=\"btn dropdown-toggle\"\n      data-toggle=\"dropdown\"\n      aria-haspopup=\"true\" \n      aria-expanded=\"false\">\n        <svg id=\"waveform\" fill=\"currentColor\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 640 512\"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d=\"M320 0c12 0 22.1 8.8 23.8 20.7l42 304.4L424.3 84.2c1.9-11.7 12-20.3 23.9-20.2s21.9 8.9 23.6 20.6l28.2 197.3 20.5-102.6c2.2-10.8 11.3-18.7 22.3-19.3s20.9 6.4 24.2 16.9L593.7 264H616c13.3 0 24 10.7 24 24s-10.7 24-24 24H576c-10.5 0-19.8-6.9-22.9-16.9l-4.1-13.4-29.4 147c-2.3 11.5-12.5 19.6-24.2 19.3s-21.4-9-23.1-20.6L446.7 248.3l-39 243.5c-1.9 11.7-12.1 20.3-24 20.2s-21.9-8.9-23.5-20.7L320 199.6 279.8 491.3c-1.6 11.8-11.6 20.6-23.5 20.7s-22.1-8.5-24-20.2l-39-243.5L167.8 427.4c-1.7 11.6-11.4 20.3-23.1 20.6s-21.9-7.8-24.2-19.3l-29.4-147-4.1 13.4C83.8 305.1 74.5 312 64 312H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H46.3l26.8-87.1c3.2-10.5 13.2-17.5 24.2-16.9s20.2 8.5 22.3 19.3l20.5 102.6L168.2 84.6c1.7-11.7 11.7-20.5 23.6-20.6s22 8.5 23.9 20.2l38.5 240.9 42-304.4C297.9 8.8 308 0 320 0z\"/></svg>\n        </span> High <span>\n        <i class=\"fa fa-angle-down\"></i></span>\n      </button>\n      <ul class=\"dropdown-menu\">\n        <li id=\"bb-audio-quality-option\">Standard</li>\n      </ul>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <img class=\"bb-audio-modal-image\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"thumbnailUrl") || (depth0 != null ? lookupProperty(depth0,"thumbnailUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnailUrl","hash":{},"data":data,"loc":{"start":{"line":40,"column":43},"end":{"line":40,"column":59}}}) : helper)))
    + "\" data-fallback-src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"thumbnailFallbackUrl") || (depth0 != null ? lookupProperty(depth0,"thumbnailFallbackUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnailFallbackUrl","hash":{},"data":data,"loc":{"start":{"line":40,"column":80},"end":{"line":40,"column":104}}}) : helper)))
    + "\"\n         alt=\""
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"thumbnailAlt") : stack1), depth0)) != null ? stack1 : "")
    + "\" />\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"bb-audio-modal-image bg-black\"><i class=\"bbico bbico-audio\"></i></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"bb-audio-modal\" class=\"modal-body text-center\">\n  <div id=\"bb-audio-modal-header\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMultipleFormats") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":18,"column":11}}})) != null ? stack1 : "")
    + "    <button type=\"button\" class=\"bb-audio-modal-close-btn circle-btn\" data-dismiss=\"modal\" aria-label=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"closeModal") : stack1), depth0))
    + "\">\n      <span aria-hidden=\"true\"><i class=\"bbico bbico-times-circle\"></i></span>\n    </button>\n  </div>\n\n  <h4 class=\"bb-audio-modal-title\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":24,"column":35},"end":{"line":24,"column":46}}}) : helper)))
    + "</h4>\n  <h5 class=\"bb-audio-modal-sub-title\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"subtitle") || (depth0 != null ? lookupProperty(depth0,"subtitle") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"subtitle","hash":{},"data":data,"loc":{"start":{"line":25,"column":39},"end":{"line":25,"column":53}}}) : helper)))
    + "</h5>\n\n  <div id=\"bb-audio-modal-player-playlist-collapse\" class=\"collapse\">\n    <div id=\"bb-audio-modal-player-playlist-header\">\n      <label>"
    + alias3(((helper = (helper = lookupProperty(helpers,"playlistTitleLabel") || (depth0 != null ? lookupProperty(depth0,"playlistTitleLabel") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"playlistTitleLabel","hash":{},"data":data,"loc":{"start":{"line":29,"column":13},"end":{"line":29,"column":37}}}) : helper)))
    + "</label>\n      <button type=\"button\" class=\"btn\" data-toggle=\"collapse\" data-target=\"#bb-audio-modal-player-playlist-collapse\"\n              aria-label=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"close") : stack1), depth0))
    + "\" aria-expanded=\"false\">\n        <span aria-hidden=\"true\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"close") : stack1), depth0))
    + "</span>\n      </button>\n    </div>\n    <div id=\"bb-audio-modal-player-playlist-region\"></div>\n  </div>\n\n  <!--image-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"thumbnailUrl") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":39,"column":2},"end":{"line":44,"column":9}}})) != null ? stack1 : "")
    + "\n  <div id=\"bb-audio-modal-player-controls\">\n    <button id=\"bb-audio-modal-previous-track-btn\" type=\"button\" class=\"bb-audio-modal-control-btn\">\n      <i class=\"bbico bbico-beginning\"></i>\n    </button>\n    <button id=\"bb-audio-modal-skip-back-btn\" type=\"button\" class=\"bb-audio-modal-control-btn\">\n      <i class=\"bbico bbico-back30\"></i>\n    </button>\n    <button id=\"bb-audio-modal-toggle-play-btn\" type=\"button\" class=\"bb-audio-modal-control-btn\">\n      <i class=\"bbico bbico-play\"></i>\n    </button>\n    <button id=\"bb-audio-modal-skip-forward-btn\" type=\"button\" class=\"bb-audio-modal-control-btn\">\n      <i class=\"bbico bbico-forward30\"></i>\n    </button>\n    <button id=\"bb-audio-modal-next-track-btn\" type=\"button\" class=\"bb-audio-modal-control-btn\">\n      <i class=\"bbico bbico-end\"></i>\n    </button>\n  </div>\n  <div id=\"bb-audio-modal-player\"></div>\n  <div class=\"bb-audio-modal-track-title\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"trackTitle") || (depth0 != null ? lookupProperty(depth0,"trackTitle") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"trackTitle","hash":{},"data":data,"loc":{"start":{"line":64,"column":42},"end":{"line":64,"column":58}}}) : helper)))
    + "</div>\n  <div id=\"bb-audio-modal-options\">\n    <div>\n      <button type=\"button\" data-toggle=\"collapse\" data-target=\"#bb-audio-modal-player-playlist-collapse\" aria-label=\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"audio") : stack1)) != null ? lookupProperty(stack1,"viewTrackList") : stack1), depth0))
    + "\">\n        <span aria-hidden=\"true\" aria-expanded=\"false\"><i class=\"bbico bbico-chapters\"></i></span></button>\n      <p>"
    + alias3(((helper = (helper = lookupProperty(helpers,"playlistTitleLabel") || (depth0 != null ? lookupProperty(depth0,"playlistTitleLabel") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"playlistTitleLabel","hash":{},"data":data,"loc":{"start":{"line":69,"column":9},"end":{"line":69,"column":31}}}) : helper)))
    + "</p>\n    </div>\n    <div>\n      <button id=\"bb-audio-modal-change-playback-speed-btn\" type=\"button\" aria-label=\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"audio") : stack1)) != null ? lookupProperty(stack1,"changePlayback") : stack1), depth0))
    + "\"><span\n        aria-hidden=\"true\"><i class=\"bbico bbico-speed-1\"></i></span></button>\n      <p>Playback Speed</p>\n    </div>\n  </div>\n\n</div>";
},"useData":true});