import _ from "lodash";
import L from "lodash/fp";

export const getTitle = mediaModel => {
  const mediaType = mediaModel.get("mediaType");

  if (mediaType === "anthology" || mediaType === "collection" || mediaType === "module") {
    return mediaModel.get("name");
  } else if (mediaType === "content" || mediaType === "curation") {
    return mediaModel.get("title");
  } else {
    throw new Error(`Cannot determine title for curation media tile, media type: ${mediaType}`);
  }
};

export const getThumbnailUrl = mediaModel => {
  const mediaType = mediaModel.get("mediaType");

  if (mediaType === "anthology" || mediaType === "collection" || mediaType === "module") {
    return mediaModel.get("iconUrl");
  } else if (mediaType === "content" || mediaType === "curation") {
    if (mediaModel.get("type") === "AUDIO") {
      return "";
    } else {
      return mediaModel.get("thumbnailUrl");
    }
  } else {
    throw new Error(`Cannot determine thumbnail for curation media tile, media type: ${mediaType}`);
  }
};

export const getDetailUrl = mediaModel => {
  const mediaType = mediaModel.get("mediaType");
  const mediaId = mediaModel.get("id");

  console.log("from detail:", mediaModel);

  if (mediaType === "content") {
    return `/content/${mediaId}`;
  } else if (mediaType === "anthology") {
    const typeLabel = mediaModel.get("typeLabel").toLowerCase();
    return `/${typeLabel}/${mediaId}`;
  } else if (mediaType === "collection") {
    if (_.has(mediaModel, "anthology")) {
      const anthologyTypeLabel = mediaModel.get("anthology").typeLabel.toLowerCase();
      const collectionTypeLabel = mediaModel.get("typeLabel").toLowerCase();
      const anthologyId = mediaModel.get("anthology").id;
      return `/${anthologyTypeLabel}-${collectionTypeLabel}/${anthologyId}/${mediaId}`;
    } else {
      return `/collection/${mediaId}`;
    }
  } else if (mediaType === "module") {
    const internalCode = mediaModel.get("internalCode");
    return `/module/${internalCode}`;
  } else if (mediaType === "curation") {
    return `/curation/${mediaId}`;
  } else {
    throw new Error(`Cannot determine detail URL for curation media tile, media type: ${mediaType}`);
  }
};

export const getDownloadUrl = mediaModel => {
  const mimeType = mediaModel.get("mimeType");
  const contentType = mediaModel.get("type");

  let downloadUrl;

  if (contentType === "IMAGE") {
    downloadUrl = mediaModel.get("imageUrl");
  } else if (mimeType === "image/jpeg" || mimeType === "application/pdf") {
    downloadUrl = mediaModel.get("pdfUrl");
  } else if (mimeType === "application/epub+zip") {
    downloadUrl = mediaModel.get("epubFileUrl");
  }

  if (typeof downloadUrl === "string") {
    return downloadUrl.replace("content", "external_content");
  } else {
    return null;
  }
};

export const getContentCount = (contentType, countModel) => {
  switch (contentType) {
    case "all-media":
      return countModel.allMediaCount;
    case "all-content":
      return countModel.allContentCount;
    case "articles":
      return countModel.articleCount;
    case "audio":
      return countModel.audioCount;
    case "audiobook":
      return countModel.audiobookCount;
    case "books":
      return countModel.bookCount;
    case "videos":
      return countModel.videoCount;
    case "images":
      return countModel.imageCount;
    case "other-documents":
      return countModel.otherDocumentCount;
    case "highlights":
      return countModel.contentHighlightCount;
    case "modules":
      return countModel.moduleCount;
    case "anthologies":
      return countModel.anthologyCount;
    case "textbooks":
      return countModel.textbookCount;
    case "collections":
      return countModel.collectionCount;
    case "chapters":
      return countModel.chapterCount;
    default:
      return countModel.allMediaCount;
  }
};

export const getType = model => {
  const mediaType = model.get("mediaType");

  if (mediaType === "content") {
    const contentType = model.get("type");

    if (contentType === "OTHER_DOCUMENT") {
      return "DOCUMENT";
    } else {
      return contentType;
    }
  } else if (mediaType !== "content") {
    return mediaType.toUpperCase();
  } else {
    throw new Error(`Cannot associate media type with curation media tile, media type: ${mediaType}`);
  }
};

export const getItemType = model => {
  const type = model.get("type");

  switch (type) {
    case "ARTICLE":
      return "Periodical";
    case "IMAGE":
      return "ImageObject";
    case "VIDEO":
      return "VideoObject";
    case "AUDIO":
      return "AudioObject";
    default:
      return "Book";
  }
};

export const getIcon = model => {
  const type = model.get("type");

  switch (type) {
    case "ALBUM":
      return "fa-solid fa-album";
    case "ARTICLE":
      return "bbico bbico-article";
    case "AUDIO":
    case "AUDIOBOOK":
      return "bbico bbico-audio";
    case "DOCUMENT":
      return "bbico bbico-document";
    case "IMAGE":
      return "bbico bbico-photo";
    case "VIDEO":
      return "bbico bbico-video";
    default:
      return "bbico bbico-book";
  }
};

export const getAnthologies = L.filter(["mediaType", "anthology"]);
export const getCollections = L.filter(["mediaType", "collection"]);

// TODO: Temporary solution for adding michigan read logo to content details page; remove at a later point
// UPDATE: 11/6/2023 [Tyler] - No longer used. Leaving in case logo/badge needs to be added to these media IDs in the future.
export const isGreatMichiganRead = mediaId => {
  const greatMichiganReadIds = ["777e29e1-e162-4a68-a059-f7399875280e", "eedee105-0514-4dd3-9721-83866c3f6f89"];
  return _.includes(greatMichiganReadIds, mediaId);
};
