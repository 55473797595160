import _ from "lodash";
import Backbone from "backbone";
import Promise from "bluebird";
import Velocity from "velocity-animate";
import BaseTileView from "scripts/views/baseTileView";
import ProgressBarView from "scripts/views/progressBarView";
import ProgressBarModel from "scripts/models/progressBarModel";
import inject from "scripts/ioc/inject";

const chan = Backbone.Radio.channel;

class BaseMediaSalvattoreTileView extends BaseTileView {
  constructor(options, securityService, i18nextService = inject("i18nextService")) {
    super(options);

    this.securityService = securityService;
    this.i18nextService = i18nextService;

    this.isHoverState = false;
    this.isAnimatingToDefaultState = false;
    this.isAnimatingToHoverState = false;
  }

  get className() {
    return "bb-media-salvattore-tile";
  }

  get events() {
    return _.extend({}, super.events, {
      "focusin button": "onTileFocused",
      "focusin a": "onTileFocused",
      "focusout button": "onTileFocused",
      "focusout a": "onTileFocused",
    });
  }

  onConnectionStatusChange() {
    super.onConnectionStatusChange();

    this.$el.find(".tile-action").attr("href", this.getDetailUrl());
  }

  initializeBookshelfDeps() {
    super.initializeBookshelfDeps();

    this.tileProgressBarModel = new ProgressBarModel({
      showCancelButton: false,
    });

    this.tileProgressBarView = this.addSubView(
      new ProgressBarView({
        className: "bb-download-indicator-container tile-version",
        model: this.tileProgressBarModel,
      }),
    );

    this.tileHoverProgressBarModel = new ProgressBarModel();

    this.tileHoverProgressBarView = this.addSubView(
      new ProgressBarView({
        className: "bb-download-indicator-container tile-hover-version",
        model: this.tileHoverProgressBarModel,
      }),
    );

    this.tileHoverProgressBarView.on("progressBarCancel", () => this.onCancelBookshelfDownload(), this);
  }

  render() {
    const user = this.securityService.getUser();
    const modelData = this.model.toJSON();

    const templateData = _.merge(modelData, {
      viewerUrl: this.getViewerUrl(),
      detailUrl: this.getDetailUrl(),
      showBadges: user.getShowBadges(),
      showAwards: !_.isEmpty(modelData.awardMediaBadges),
      downloadUrl: this.getDownloadUrl(),
      isAudiobook: modelData.type === "AUDIOBOOK",
      i18n: this.getTranslations(modelData),
      isInAnthology: _.has(modelData, "anthology"),
    });

    this.$el.html(this.template(templateData));

    this.initCurationAddButton();
    this.initTooltips();

    if (this.securityService.getUser().get("bot")) {
      this.animateHoverState(0);
    }

    this.initializeDownloadButton();

    this.initializeCurationMultiSelect();
    this.initFavoriteButton();

    return this;
  }

  getTranslations(modelData) {
    const attributes = this.i18nextService.getAttributes();
    const common = this.i18nextService.getCommon();
    const glossary = this.i18nextService.getGlossary();

    return {
      addToBookshelf: {
        ariaLabel: attributes.ariaLabel.addToBookshelf,
        title: attributes.ariaLabel.addToBookshelf,
      },
      addToCuration: {
        ariaLabel: attributes.ariaLabel.addToCuration,
        title: attributes.title.addToCuration,
      },
      addToFavorites: {
        ariaLabel: attributes.ariaLabel.addToFavorites,
        title: attributes.title.addToFavorites,
      },
      anthology: {
        included: glossary.anthology.included,
        singular: glossary.anthology.singular,
      },
      browseName: common.actions.browseName(modelData.name),
      collection: {
        singular: glossary.collection.singular,
      },
      content: {
        album: { singular: glossary.content.album.singular },
        article: { singular: glossary.content.article.singular },
        audio: { singular: glossary.content.audio.singular },
        audiobook: { singular: glossary.content.audiobook.singular },
        book: { singular: glossary.content.book.singular },
        chapter: { singular: glossary.content.chapter.singular },
        document: { singular: glossary.content.document.singular },
        edition: glossary.content.ed(modelData.edition === undefined ? "" : modelData.edition),
        image: { singular: glossary.content.image.singular },
        issueNumber: glossary.content.no(modelData.issueNumber === undefined ? "" : modelData.issueNumber),
        volume: glossary.content.vol(modelData.volume === undefined ? "" : modelData.volume),
        video: { singular: glossary.content.video.singular },
      },
      contentProvider: attributes.alt.contentProvider,
      curation: { singular: glossary.curation.singular },
      highlight: glossary.content.highlight,
      languageContent: glossary.content.languageContent,
      module: { singular: glossary.module.singular },
      thumbnailAlt: attributes.alt.thumbnailForName(modelData.name),
    };
  }

  renderBookshelfElements() {
    super.renderBookshelfElements();

    this.tileProgressBarView.htmlOf(this.$el.find(".media-image-container .bb-download-progress-bar-region")).render();
  }

  hasTouchMoved() {
    return chan("display").request("touch:touchMoved");
  }

  isScrolling() {
    return chan("display").request("scroll:scrolling");
  }

  onTileFocused(event) {
    const $allTiles = $(".bb-media-salvattore-tile");
    $allTiles.removeClass("tile-selected");
    const $tile = $(event.target).parents(".bb-media-salvattore-tile");
    console.log(event.type);
    if (event.type === "focusin") {
      $tile.addClass("tile-selected");
    }
  }

  animateHoverState(duration = 200) {
    if ((!this.isHoverState || this.isAnimatingToDefaultState) && !this.isAnimatingToHoverState) {
      this.isAnimatingToHoverState = true;

      const tile = this.el;
      const $tile = this.$el;
      const elementsToFade = tile.querySelectorAll(".btn-block, .tile-overlay, .tile-hover-header");
      if (_.isEmpty(elementsToFade)) {
        // this seems to occur sometimes when leaving the page
        // no elements are found
        return Promise.resolve();
      }

      const tileHeight = $tile.height();
      const overlayHeight = tileHeight;
      const overlayWidth = $tile.width();
      const overlayMarginTop = -overlayHeight / 2;
      const overlayMarginLeft = -overlayWidth / 2;

      // allows for vertical centering at various heights
      tile.querySelector(".tile-hover").style.height = tileHeight + "px";

      tile.querySelector(".tile-overlay").style.width = overlayWidth + "px";
      tile.querySelector(".tile-overlay").style.height = overlayHeight + "px";
      tile.querySelector(".tile-overlay").style.marginTop = overlayMarginTop + "px";
      tile.querySelector(".tile-overlay").style.marginLeft = overlayMarginLeft + "px";

      return Velocity.animate(elementsToFade, "transition.fadeIn", {
        duration: duration,
      }).then(() => {
        this.isHoverState = true;
        this.isAnimatingToHoverState = false;
        this.isAnimatingToDefaultState = false;
      });
    }

    return Promise.resolve();
  }

  animateDefaultState() {
    if ((this.isHoverState || this.isAnimatingToHoverState) && !this.isAnimatingToDefaultState) {
      this.isAnimatingToDefaultState = true;

      const tile = this.el;
      const elementsToFade = tile.querySelectorAll(".btn-block, .tile-overlay, .tile-hover-header");

      return Velocity.animate(elementsToFade, "transition.fadeOut", {
        duration: 350,
      }).then(() => {
        this.isHoverState = false;
        this.isAnimatingToDefaultState = false;
        this.isAnimatingToHoverState = false;
        chan("salvattoreTile").off("touchEndHoverState", this.onTouchEndHoverState, this);
      });
    }

    return Promise.resolve();
  }

  updateProgressBar(percent) {
    this.tileHoverProgressBarModel.set("isVisible", true);
    this.tileProgressBarModel.set("isVisible", true);

    this.tileHoverProgressBarModel.set("percent", percent);
    this.tileProgressBarModel.set("percent", percent);
  }

  hideProgressBar() {
    this.tileHoverProgressBarModel.set("isVisible", false);
    this.tileProgressBarModel.set("isVisible", false);
  }
}

export default BaseMediaSalvattoreTileView;
