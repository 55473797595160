import Controller from "scripts/controllers/controller";
import ResetPasswordPageView from "scripts/views/resetPasswordPageView";

class ResetPasswordController extends Controller {
  get route() {
    return "route:resetPassword";
  }

  replyRoute() {
    return this.showAuthenticationScreenBodyView(new ResetPasswordPageView());
  }
}

export default ResetPasswordController;
