import Promise from "bluebird";
import _ from "lodash";
import Controller from "scripts/controllers/controller";

class ManageCurationController extends Controller {
  get route() {
    return "route:manageCuration";
  }

  routeAttrs() {
    return {
      mediaId: arguments[0],
    };
  }

  replyRoute() {
    const attrs = this.routeAttrs.apply(this, _.tail(arguments));

    return this.showMainBodyViewPromise(
      Promise.resolve(
        import(/* webpackChunkName: "manageCuration" */ "scripts/views/manageCurationPageView"),
      ).then(
        ({ default: ManageCurationPageView }) =>
          new ManageCurationPageView({
            mediaId: attrs.mediaId,
          }),
      ),
    );
  }
}

export default ManageCurationController;
