import _ from "lodash";
import { alert } from "scripts/alerts/alerts";
import messages from "scripts/utils/messageBundle";
import ForbiddenSyncError from "scripts/exceptions/forbiddenSyncError";
import GeneralSyncError from "scripts/exceptions/generalSyncError";
import InternalServerSyncError from "scripts/exceptions/internalServerSyncError";
import NotFoundSyncError from "scripts/exceptions/notFoundSyncError";
import UnauthorizedSyncError from "scripts/exceptions/unauthorizedSyncError";
import UnprocessableEntitySyncError from "scripts/exceptions/unprocessableEntitySyncError";

const getErrorMessage = (error, defaultMessage) => {
  let message;
  let firstError;

  let json = error.xhr && error.xhr.responseJSON;

  if (json) {
    if (json.errors) {
      firstError = _.head(json.errors);

      if (firstError) {
        message = messages.get(firstError.code);
      }
    }

    if (!message && json.code) {
      message = messages.get(json.code);
    }

    if (!message && json.errors) {
      firstError = _.head(json.errors);

      if (firstError) {
        message = firstError.message;
      }
    }

    if (!message && json.message) {
      message = json.message;
    }
  }

  if (defaultMessage && !message) {
    message = defaultMessage;
  }

  return message;
};

const createErrorAlert = (error, title, defaultMessage) =>
  alert({
    title: title,
    text: getErrorMessage(error, defaultMessage),
    type: "error",
  });

const errorAlert = error => {
  console.error("Showing error alert for error: %O", error);

  if (error instanceof ForbiddenSyncError) {
    return createErrorAlert(
      error,
      "Forbidden Error",
      "You don't have access to the requested resource.  Please contact support if the error persists.",
    );
  } else if (error instanceof NotFoundSyncError) {
    return createErrorAlert(
      error,
      "Not Found Error",
      "The requested resource was not found.  Please contact support if the error persists.",
    );
  } else if (error instanceof UnauthorizedSyncError) {
    return createErrorAlert(
      error,
      "Unauthorized Error",
      "You don't have permission to do that right now.  Your session may have timed out.",
    );
  } else if (error instanceof UnprocessableEntitySyncError) {
    return createErrorAlert(
      error,
      "Validation Error",
      "Something that was sent to us is not valid.  If you don't know what it is please contact support.",
    );
  } else if (error instanceof InternalServerSyncError) {
    return createErrorAlert(
      error,
      "Internal Server Error",
      "The server didn't like that.  Please contact support if the error persists.",
    );
  } else if (error instanceof GeneralSyncError) {
    return createErrorAlert(
      error,
      "General Error",
      `The server responded with status code: ${error.xhr
        ? error.xhr.status
        : "Unknown"}.  Please contact support if the error persists.`,
    );
  } else {
    return createErrorAlert(
      error,
      "Error",
      "Something unexpected happened.  Please contact support if the error persists.",
    );
  }
};

export default errorAlert;
