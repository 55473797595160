import Promise from "bluebird";
import Backbone from "backbone";
import { alert } from "scripts/alerts/alerts";
import errorAlert from "scripts/alerts/errorAlert";
import BaseView from "scripts/views/baseView";
import { redirectToFragment } from "scripts/utils/routerHelpers";

const chan = Backbone.Radio.channel;

class BaseNoteTileView extends BaseView {
  get events() {
    return {
      "click .bb-note-tile-edit": "onClickEdit",
      "click .bb-note-tile-delete": "onClickDelete",
      "click .bb-note-card-viewer": "onClickNoteCard",
    };
  }

  onClickNoteCard() {
    chan("tracking").trigger("viewer:click", this.mediaModel); // not listened to

    const mediaId = this.model.get("mediaId");
    let viewerUrl = `/viewer/${mediaId}`;

    if (this.model.has("pageSequence")) {
      const pageSequence = this.model.get("pageSequence");
      viewerUrl = `${viewerUrl}/${pageSequence}`
    }

    redirectToFragment(viewerUrl);

    console.log(this.model);

    return false;
  }

  onClickEdit() {
    this.trigger("editNote", this.model);

    return false;
  }

  onClickDelete() {
    alert({
      title: "Are you sure?",
      text: "Delete this note?",
      type: "error",
      showCancelButton: true,
      confirmButtonColor: "#F02259",
      confirmButtonText: "Yes, delete it!",
    })
      .show()
      .then(() => this.onConfirmDelete())
      .catch(Promise.CancellationError, () => {
        // swallow cancellation
      });

    return false;
  }

  onConfirmDelete() {
    chan("display").request("showBlockingLoader");

    this.model
      .destroy()
      .then(() => this.onDeleteSuccess())
      .catch(error => this.onDeleteFailed(error))
      .finally(() => {
        chan("display").request("hideBlockingLoader");
      });
  }

  onDeleteSuccess() {
    this.closeSubView(this);
  }

  onDeleteFailed(error) {
    errorAlert(error).show();
  }
}

export default BaseNoteTileView;
