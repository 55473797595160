import Backbone from "backbone";
import inject from "scripts/ioc/inject";
import AnthologyPageNavView from "scripts/views/anthologyPageNavView";
import BaseMediaDetailsPageView from "scripts/views/baseMediaDetailsPageView";
import templateAnthologyPage from "templates/anthologyPage.hbs";

const chan = Backbone.Radio.channel;

class AnthologyPageView extends BaseMediaDetailsPageView {
  constructor(
    options,
    breadcrumbService = inject("breadcrumbService"),
    connectionService = inject("connectionService"),
    securityService = inject("securityService"),
  ) {
    super(options, breadcrumbService, connectionService, securityService);

    this.model.on(
      "change:presentationType",
      this.onPresentationTypeChangeSaveUserPreference,
      this,
    );

    chan("controllerView").reply("anthologyPageView", this.replyPageView, this);
  }

  get template() {
    return templateAnthologyPage;
  }

  createNavView() {
    return new AnthologyPageNavView({
      model: this.model,
      anthologyModel: this.mediaModel,
    });
  }

  isOverflowed(element) {
    return element && (element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth);
  }


  render() {
    super.render();

    this.renderFavoriteButton();
    this.renderCurationButton();
    this.renderPageHeaderDescriptionContainer();

    chan("tracking").trigger("anthology:open", {
      mediaId: this.mediaModel.get("id"),
    });

    const $description = $("#description-container");

    //-- Make sure all anchor tags open in new tab.
    const $anchors = $description.find("a");
    $anchors.attr("target", "_blank");

    if (!this.isOverflowed($description[0])) {
      const $descriptionContainer = $("#bb-media-details-description-container");
      $descriptionContainer.removeClass("collapse");
      const $descriptionCollapseBtn = $("#description-collapse-button");
      $descriptionCollapseBtn.addClass("hidden");
    }

    return this;
  }

  getBreadcrumbText() {
    return this.mediaModel.get("name");
  }

  getDocumentTitle() {
    return `${this.mediaModel.get("name")}`;
  }
}

export default AnthologyPageView;
