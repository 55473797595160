var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <h2>File System Browsers</h2>\n\n    <ul>\n        <li><a href=\"/bower_components/idb.filesystem.js/demos/playground/index.html\" target=\"_blank\">File\n            System API Browser (Temporary)</a></li>\n        <li><a href=\"/bower_components/idb.filesystem.js/demos/playground/index.html?persistent\"\n               target=\"_blank\">File System API Browser (Persistent)</a></li>\n    </ul>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\n            <label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"enabled") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":23,"column":23}}})) != null ? stack1 : "")
    + "\n                "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"feature") || (depth0 != null ? lookupProperty(depth0,"feature") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"feature","hash":{},"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":25,"column":27}}}) : helper)))
    + "\n            </label>\n        </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"checkbox\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"feature") || (depth0 != null ? lookupProperty(depth0,"feature") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"feature","hash":{},"data":data,"loc":{"start":{"line":20,"column":50},"end":{"line":20,"column":61}}}) : helper)))
    + "\" class=\"bb-developer-options-feature-flag\" checked/>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"checkbox\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"feature") || (depth0 != null ? lookupProperty(depth0,"feature") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"feature","hash":{},"data":data,"loc":{"start":{"line":22,"column":50},"end":{"line":22,"column":61}}}) : helper)))
    + "\" class=\"bb-developer-options-feature-flag\"/>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1>Developer Options</h1>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showFileSystemOptions") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":12,"column":7}}})) != null ? stack1 : "")
    + "\n<h2>Feature Flags</h2>\n<ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"featureFlags") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":28,"column":13}}})) != null ? stack1 : "")
    + "</ul>\n\n<a href=\"/home\">Home</a>\n";
},"useData":true});