var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<nav id=\"bb-main-nav\" role=\"navigation\" aria-label=\"Main menu area\">\n\n    <a href=\"#main-content-region\" class=\"skip-link\" id=\"skip-to-primary-content-link\" aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"skipToContent") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"skipToContent") : stack1), depth0))
    + "</a>\n    <a href=\"#footer-region\" class=\"skip-link\" id=\"skip-to-footer-link\" aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"skipToFooter") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"skipToFooter") : stack1), depth0))
    + "</a>\n\n    <div class=\"container\">\n        <div class=\"row\">\n\n            <div class=\"col-xs-3\">\n                <button id=\"bb-main-nav-back-button\" class=\"bb-main-nav-button hide\" aria-label=\"Back\">\n                    <i class=\"bbico bbico-arrow-left\"></i>\n                </button>\n\n                <button id=\"bb-main-nav-menu-button\" class=\"bb-main-nav-button\"\n                    aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"menuOpen") : stack1), depth0))
    + "\">\n                    <i class=\"bbico bbico-menu\"></i>\n                    <span class=\"bb-main-nav-button-text\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1), depth0))
    + "</span>\n                </button>\n            </div>\n\n            <div class=\"col-xs-6\">\n                <div class=\"d-table\">\n                    <div id=\"bb-main-nav-logo-region\" aria-label=\"Branding\"></div>\n                </div>\n            </div>\n\n            <div class=\"col-xs-3 text-right\">\n                <button id=\"bb-main-nav-search-button\" class=\"bb-main-nav-button\"\n                    aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"searchOpen") : stack1), depth0))
    + "\">\n                    <i class=\"bbico bbico-search\"></i>\n                    <span class=\"bb-main-nav-button-text\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1), depth0))
    + "</span>\n                </button>\n                <button id=\"bb-main-nav-close-button\" class=\"bb-main-nav-button hide\"\n                    aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"closeMenu") : stack1), depth0))
    + "\">\n                    <i class=\"bbico bbico-times\"></i>\n                </button>\n            </div>\n        </div>\n    </div>\n</nav>";
},"useData":true});