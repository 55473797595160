var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form>\n  <div class=\"modal-header\">\n    <button class=\"close bbico bbico-times fnt-12\" data-dismiss=\"modal\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"close") : stack1), depth0))
    + "\"><i></i></button>\n    <h2 class=\"modal-title text-center fnt-22 m-t-15\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</h2>\n  </div>\n  <div class=\"modal-body\">\n    <input type=\"text\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"searchTerm") || (depth0 != null ? lookupProperty(depth0,"searchTerm") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"searchTerm","hash":{},"data":data,"loc":{"start":{"line":7,"column":30},"end":{"line":7,"column":44}}}) : helper)))
    + "\"  />\n    <button type=\"button\" id=\"bb-faceted-search-facet-modal-clear-input\" class=\"clear bbico bbico-times fnt-12\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"clearInput") : stack1), depth0))
    + "\"><i></i></button>\n  </div>\n  <div class=\"modal-footer\">\n    <button id=\"bb-faceted-search-facet-modal-apply-button\" data-dismiss=\"modal\"\n      class=\"faceted-search-facet-modal-apply-button\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"apply") : stack1), depth0))
    + "</button>\n  </div>\n</form>";
},"useData":true});