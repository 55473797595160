var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"bb-growl-alert bb-growl-alert-bl d-none\">\n    <div class=\"contentwell bg-gray-lighter\">\n        <button type=\"button\" class=\"close\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"close") : stack1), depth0))
    + "\"><span aria-hidden=\"true\">&times;</span></button>\n        <h2>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"contentAdded") : stack1), depth0))
    + "</h2>\n    </div>\n\n    <div class=\"contentwell bg-white text-center\">\n        <a href=\"/manage-curation/"
    + alias2(((helper = (helper = lookupProperty(helpers,"curationId") || (depth0 != null ? lookupProperty(depth0,"curationId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"curationId","hash":{},"data":data,"loc":{"start":{"line":8,"column":34},"end":{"line":8,"column":48}}}) : helper)))
    + "\" id=\"bb-curation-media-added-view-button\" class=\"btn-solid btn-solid-brand-primary btn-solid-condensed\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1), depth0))
    + "</a>\n    </div>\n</div>";
},"useData":true});