import L from "lodash/fp";

export const hmr = L.curry((mod, req, name, cb) => {
  if (mod.hot) {
    mod.hot.accept(name, () => {
      cb(req(name));
    });
  }
});

export const addSubView = L.curry((view, subView) => {
  view.addSubView(subView);
  return subView;
});

export const appendTo = L.curry(($region, view) => {
  view.appendTo($region);
  return view;
});

export const fetch = view => {
  view.fetch();
  return view;
};

export const htmlOf = L.curry((region, view) => {
  view.htmlOf(region);
  return view;
});

export const on = L.curry((event, cb, view) => {
  view.on(event, cb, view);
  return view;
});

export const prependTo = L.curry((region, view) => {
  view.prependTo(region);
  return view;
});

export const render = view => {
  view.render();
  return view;
};

//modified from: https://ctrlq.org/code/20294-regex-extract-links-javascript
export const addLinksToText = text =>
  (text || "").replace(
    /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi,
    (match, space, url) => {
      var hyperlink = url;

      if (!hyperlink.match("^https?://")) {
        hyperlink = "http://" + hyperlink;
      }

      return `${space}<a href="${hyperlink}" target="_blank" rel="noreferrer noopener">${url}</a>`;
    },
  );
