import _ from "lodash";
import { isRelativeUrl } from "scripts/utils/urlUtil";

const chan = Backbone.Radio.channel;

const AuthenticationSyncHoc = Model => {
  return class extends Model {
    get authenticationUrlRoot() {
      return process.env.PATRON_UI_GENERAL_AUTHENTICATION_URL_ROOT;
    }

    get organizationAuthenticationUrlRoot() {
      return process.env.PATRON_UI_AUTHENTICATION_URL_ROOT;
    }

    syncOptions(options) {
      const crossDomain = !isRelativeUrl(this.authenticationUrlRoot);
      const audience =
        this.get("audience") || process.env.PATRON_UI_BIBLIO_AUDIENCE;

      // Backbone.PageableCollection needs options to be mutated, otherwise the xhr won't make it through to parse
      options = _.defaults(options || {}, {
        headers: {
          "X-Biblio-Audience": audience,
          "X-Biblio-Captcha": process.env.PATRON_UI_BIBLIO_CAPTCHA,
          "Content-Type": "application/json",
        },
        crossDomain: crossDomain,
      });

      return options;
    }

    sync(method, model, options) {
      chan("security").trigger("fetch");
      return super.sync(method, model, this.syncOptions(options));
    }
  };
};

export default AuthenticationSyncHoc;
