import BaseModalView from "scripts/views/baseModalView";
import { getTitle, getThumbnailUrl, getDetailUrl } from "scripts/utils/mediaHelpers";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";
import { createAbsoluteUrl } from "scripts/utils/urlUtil";
import templateShareModal from "templates/shareModal.hbs";

const getFacebookUrl = url => `http://facebook.com/sharer.php?u=${url}`;
const getTwitterUrl = (url, title) =>
  `https://twitter.com/intent/tweet?hashtags=biblioboard&text=${title}&original_referer=${url}&url=${url}`;
const getPintrestUrl = (url, title, thumbnailUrl) =>
  `https://pinterest.com/pin/create/button/?url=${url}&media=${thumbnailUrl}&description=${title}`;

const LINK_COPY_SUCCESS = "Link copied to clipboard.";
const LINK_COPY_FAIL = "Copy to clipboard failed.";

class ShareModalView extends BaseModalView {
  get template() {
    return templateShareModal;
  }

  get events() {
    return {
      "click #bb-share-link": "onClickLink",
      "click #bb-share-facebook": "onClickShare",
      "click #bb-share-twitter": "onClickShare",
      "click #bb-share-pintrest": "onClickShare",
    };
  }

  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);
    this.mediaModel = options.mediaModel;

    this.i18nextService = i18nextService;
  }

  getMediaUrl() {
    const detailUrl = getDetailUrl(this.mediaModel);
    const mediaUrl = createAbsoluteUrl(detailUrl);

    if (!mediaUrl.includes("localhost")) {
      // running on the server web
      return mediaUrl;
    } else {
      // running locally in app...either way the share link will not work
      return `https://library.biblioboard.com${detailUrl}`;
    }
  }

  render() {
    const mediaUrl = this.getMediaUrl();
    const mediaTitle = getTitle(this.mediaModel);
    this.$el.html(
      this.template({
        i18n: this.getTranslations(),
        facebookUrl: getFacebookUrl(mediaUrl),
        twitterUrl: getTwitterUrl(mediaUrl, mediaTitle),
        pintrestUrl: getPintrestUrl(mediaUrl, mediaTitle, getThumbnailUrl(this.mediaModel)),
        linkUrl: mediaUrl,
        linkCopyResult: "",
      }),
    );

    return this;
  }

  getTranslations() {
    const close = L.get(["ariaLabel", "close"], this.i18nextService.getAttributes());
    const { actions, link, pin, tweet } = this.i18nextService.getCommon();
    const { post, shareIt } = actions;

    return {
      close,
      link,
      pin,
      post,
      shareIt,
      tweet,
    };
  }

  onClickLink() {
    // taken from https://stackoverflow.com/a/33928558
    const mediaUrl = this.getMediaUrl();
    let linkCopyResult = LINK_COPY_FAIL;
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(mediaUrl)
        .then(
          () => {
            linkCopyResult = LINK_COPY_SUCCESS;
          },
          err => {
            console.warn("Copy to clipboard failed.", err);
          },
        )
        .finally(() => {
          $("#bb-share-link-copy-result").text(linkCopyResult);
        });
    } else {
      if (window.clipboardData && window.clipboardData.setData) {
        // IE specific code path to prevent textarea being shown while dialog is visible.
        window.clipboardData.setData("BiblioBoardUrl", mediaUrl);
        linkCopyResult = LINK_COPY_SUCCESS;
      } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = mediaUrl;
        textarea.value = mediaUrl;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand("copy"); // Security exception may be thrown by some browsers.
          linkCopyResult = LINK_COPY_SUCCESS;
        } catch (ex) {
          console.warn("Copy to clipboard failed.", ex);
        } finally {
          document.body.removeChild(textarea);
        }
      }

      $("#bb-share-link-copy-result").text(linkCopyResult);
    }
  }

  onClickShare(ev) {
    if (window.cordova) {
      window.cordova.InAppBrowser.open(ev.currentTarget.href, "_system");
      return false;
    }
  }
}

export default ShareModalView;
