import BaseApiCollection from "scripts/collections/baseApiCollection";
import ContentFileModel from "scripts/models/contentFileModel";

class ContentFileCollection extends BaseApiCollection {
  initialize(models, options) {
    super.initialize(models, options);

    this.mediaId = options.mediaId;
  }

  path() {
    return `/content/${this.mediaId}/content_files`;
  }

  get model() {
    return ContentFileModel;
  }
}

export default ContentFileCollection;
