import L from "lodash/fp";
import { propEq } from "scripts/utils/generalHelpers";

// OrganizationInfo
// {
//     isAssociatedWithUser: boolean,
//     isActiveOrganization: boolean,
//     token: string,
//     organization: object,
// }

// getUserOrganizations :: User -> [Organization]
export const getUserOrganizations = user => user.getOrganizations();

// getUserOrganizationForAlias :: User -> String -> Organization
export const getUserOrganizationForAlias = L.curry((user, alias) =>
  user.getOrganizationForAlias(alias),
);

// hasOrganization :: User -> String -> boolean
export const hasOrganization = L.curry(
  (user, organizationId) =>
    !L.isNil(organizationId) && user.hasOrganization(organizationId),
);

// setActiveOrganizationId :: User -> String -> User
export const setActiveOrganizationId = L.curry((user, organizationId) => {
  const newUser = user.clone();
  newUser.setActiveOrganizationId(organizationId);
  return newUser;
});

// addUserAssociationInfoToOrganizationsInfo :: User -> [OrganizationInfo] -> [OrganizationInfo]
export const addUserAssociationInfoToOrganizationsInfo = user =>
  L.map(orgInfo => {
    const { organization } = orgInfo;
    const isAssociatedWithUser = user.hasOrganization(
      organization.organizationId,
    );

    return {
      isAssociatedWithUser,
      isActiveOrganization:
        organization.organizationId === user.getActiveOrganizationId(),
      ...orgInfo,
    };
  });

// getUserOrganizationsInfoWithToken :: User -> [OrganizationInfo]
export const getUserOrganizationsInfoWithToken = user =>
  L.compose(
    L.map(organization => ({ token: user.getToken(), organization })),
    getUserOrganizations,
  )(user);

// getAllOrganizationsInfoAndTokens :: [User] -> [OrganizationInfo]
export const getAllOrganizationsInfoAndTokens = L.compose(
  L.sortBy(L.path(["organization", "name"])),
  L.reduce(L.unionBy(L.path(["organization", "organizationId"])), []),
  L.map(getUserOrganizationsInfoWithToken),
);

// getAllOrganizationsAndTokens :: User -> [User] -> [OrganizationInfo]
export const getOrganizationsInfoFromUsers = currentUser =>
  L.compose(
    addUserAssociationInfoToOrganizationsInfo(currentUser),
    getAllOrganizationsInfoAndTokens,
  );

// getOrganizationIdsFromInfoArray :: [OrganizationInfo] -> [String]
export const getOrganizationIdsFromInfoArray = L.map(
  L.path(["organization", "organizationId"]),
);

// isPublicOrganization :: Organization -> Boolean
export const isPublicOrganization = propEq("isPublic", true);
