var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--IMAGE COL-->\n<div class=\"bb-list-tile-cover-column\">\n  <div class=\"bb-list-tile-cover\">\n    <div class=\"thumbnail-xs\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"thumbnailUrl") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":11,"column":6},"end":{"line":18,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <img class=\"thumbnail-xs\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"thumbnailUrl") || (depth0 != null ? lookupProperty(depth0,"thumbnailUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnailUrl","hash":{},"data":data,"loc":{"start":{"line":12,"column":39},"end":{"line":12,"column":55}}}) : helper)))
    + "\" data-fallback-src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"thumbnailFallbackUrl") || (depth0 != null ? lookupProperty(depth0,"thumbnailFallbackUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnailFallbackUrl","hash":{},"data":data,"loc":{"start":{"line":12,"column":76},"end":{"line":12,"column":100}}}) : helper)))
    + "\"\n             alt=\""
    + alias4(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"thumbnail") : stack1)) != null ? lookupProperty(stack1,"alt") : stack1), depth0))
    + "\" />\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"bb-list-tile-cover-audio\">\n          <div class=\"bbico bbico-audio clr-primary\"></div>\n        </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"bb-list-tile-cover bb-list-tile-type-label\">\n        <i class=\"bbico bbico-audio\"></i>\n        <span class=\"clr-written text-uppercase\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"content") : stack1)) != null ? lookupProperty(stack1,"audio") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</span>\n\n        <span class=\"bb-vertical-separator\"> | </span>\n        <span class=\"bb-list-tile-language-label\">\n            <a href=\"/content/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"parentContent") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"parentContent") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</a>\n        </span>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--BAR COL-->\n<div class=\"bb-list-tile-bar-column\">\n  <div class=\"list-header\"></div>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSearchTile") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":22,"column":7}}})) != null ? stack1 : "")
    + "\n<!--COPY COL-->\n<div class=\"bb-list-tile-details-column\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSearchTile") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":4},"end":{"line":36,"column":11}}})) != null ? stack1 : "")
    + "\n    <a class=\"btn-view\" href=\"/content/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":38,"column":39},"end":{"line":38,"column":45}}}) : helper)))
    + "\">\n        <h2 class=\"h3 bb-list-tile-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":39,"column":42},"end":{"line":39,"column":51}}}) : helper)))
    + "</h2>\n    </a>\n    <div class=\"bb-list-tile-contributor-region\"></div>\n</div>\n\n<!--BUTTONS COL-->\n<div class=\"bb-list-tile-buttons-column\">\n  <div class=\"bb-list-tile-text-buttons-row\">\n    <!-- FAV BUTTON -->\n    <button aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToFavorites") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n            class=\"btn circle-btn circle-btn-small btn-favorite clr-written\"\n            data-toggle=\"tooltip\"\n            title=\""
    + ((stack1 = alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToFavorites") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0)) != null ? stack1 : "")
    + "\">\n        <i class=\"bbico bbico-favorite\"></i>\n    </button>\n\n\n    <button id=\"bb-album-track-list-tile\" class=\"btn btn-view btn-list clr-written\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"viewerUrl") || (depth0 != null ? lookupProperty(depth0,"viewerUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"viewerUrl","hash":{},"data":data,"loc":{"start":{"line":56,"column":90},"end":{"line":56,"column":103}}}) : helper)))
    + "\" aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"listen") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "\" data-toggle=\"tooltip\" title=\""
    + ((stack1 = alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"listen") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0)) != null ? stack1 : "")
    + "\">\n      <span class=\"fnt-bld\">PLAY</span>\n    </button>\n  </div>\n</div>";
},"useData":true});