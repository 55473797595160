import BaseApiCollection from "scripts/collections/baseApiCollection";
import NoteModel from "scripts/models/noteModel";

class NoteCollection extends BaseApiCollection {
  get model() {
    return NoteModel;
  }

  sortByAdded(order) {
    if (order === "asc") {
      this.comparator = noteModel => Date.parse(noteModel.get("dateAdded"));
    } else {
      this.comparator = noteModel => -Date.parse(noteModel.get("dateAdded"));
    }

    this.sort();
  }

  sortByPage(order) {
    if (order === "asc") {
      this.comparator = noteModel =>
        noteModel.has("pageSequence") ? noteModel.get("pageSequence") : -1;
    } else {
      this.comparator = noteModel =>
        noteModel.has("pageSequence") ? -noteModel.get("pageSequence") : 1;
    }

    this.sort();
  }

  getNotes(pageSequence) {
    return this.where({
      pageSequence: pageSequence,
    });
  }

  pageHasNote(pageSequence) {
    return (
      this.findWhere({
        pageSequence: pageSequence,
      }) !== undefined
    );
  }

  pageNotes() {
    return this.filter(model => model.has("pageSequence"));
  }

  globalNotes() {
    return this.filter(model => !model.has("pageSequence"));
  }
}

export default NoteCollection;
