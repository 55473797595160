import _ from "lodash";
import BaseSecondaryStickyView from "scripts/views/baseSecondaryStickyNavView";
import templateAnthologyPageNav from "templates/anthologyPageNav.hbs";
import mediaPageNavTranslations from "i18n/mediaPageNavTranslations";

class AnthologyPageNavView extends BaseSecondaryStickyView {
  get template() {
    return templateAnthologyPageNav;
  }

  constructor(options) {
    super(options);

    this.anthologyModel = options.anthologyModel;

    this.mediaPageNavTranslations = new mediaPageNavTranslations();
  }

  render() {
    this.$el.html(
      this.template(
        _.merge(
          { i18n: this.mediaPageNavTranslations.getTranslations() },
          this.model.toJSON(),
          this.anthologyModel.toJSON(),
        ),
      ),
    );

    this.updateLinkState();
    this.updatePosition();

    this.toggleOverviewTab();

    return this;
  }

  updateLinkState() {
    const subPageType = this.model.get("subPageType");
    const presentationType = this.model.get("presentationType");

    if (subPageType === "content") {
      $("#bb-anthology-page-nav-item-content").addClass("active");
      $("#bb-content-details-page-nav-heading-content").addClass("active");

      if (presentationType === "masonry") {
        $(
          "#bb-anthology-page-nav-presentation-type-toggle-content-list",
        ).removeClass("hide");
      } else {
        $(
          "#bb-anthology-page-nav-presentation-type-toggle-content-masonry",
        ).removeClass("hide");
      }
    } else if (subPageType === "overview") {
      $("#bb-anthology-page-nav-item-overview").addClass("active");
    } else if (subPageType === "collections" || subPageType === "chapters") {
      $("#bb-anthology-page-nav-item-collections").addClass("active");
      $("#bb-content-details-page-nav-heading-collections").addClass("active");

      if (presentationType === "masonry") {
        $(
          "#bb-anthology-page-nav-presentation-type-toggle-collections-list",
        ).removeClass("hide");
      } else {
        $(
          "#bb-anthology-page-nav-presentation-type-toggle-collections-masonry",
        ).removeClass("hide");
      }
    }
  }

  toggleOverviewTab() {
    if (!this.anthologyModel.has("overviewText")) {
      $("#bb-anthology-page-nav-item-overview").hide();
    }
  }
}

export default AnthologyPageNavView;
