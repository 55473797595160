import LogoutAuthenticationModel from "scripts/models/logoutAuthenticationModel";

class LogoutUserAuthenticationModel extends LogoutAuthenticationModel {
  url() {
    const userId = this.get("userId");

    return `${this.authenticationUrlRoot}/users/${userId}/logout`;
  }
}

export default LogoutUserAuthenticationModel;
