import Backbone from "backbone";
import BaseService from "scripts/services/baseService";
import PurchaseLinkClickModel from "scripts/models/purchaseLinkClickModel";
import LibraryCardLinkClickModel from "scripts/models/libraryCardLinkClickModel";

const chan = Backbone.Radio.channel;

class ClientTrackingService extends BaseService {
  constructor(...args) {
    super(...args);

    const trackingChannel = chan("tracking");

    trackingChannel.on("purchase:click", this.logPurchaseClick, this);
    trackingChannel.on("libraryCard:click", this.logLibraryCardClick, this);
  }

  logPurchaseClick(attrs) {
    console.log("Logging purchase link click for %s", attrs.mediaId);
    new PurchaseLinkClickModel(attrs).save();
  }

  logLibraryCardClick(attrs) {
    console.log("Logging library card link click");
    new LibraryCardLinkClickModel(attrs).save();
  }

}

export default ClientTrackingService;
