import inject from "scripts/ioc/inject";
import BaseView from "scripts/views/baseView";
import templateDeveloperPage from "templates/developerPage.hbs";

class DeveloperPageView extends BaseView {
  constructor(
    options,
    fileSystemService = inject("fileSystemService"),
    featureFlagsService = inject("featureFlagsService"),
  ) {
    super(options);

    this.fileSystemService = fileSystemService;
    this.featureFlagsService = featureFlagsService;
  }

  get className() {
    return "bb-developer-page";
  }

  get events() {
    return {
      "click .bb-developer-options-feature-flag": "onClickFeatureFlag",
    };
  }

  onClickFeatureFlag(e) {
    const checkbox = e.currentTarget;
    const feature = checkbox.value;

    if (checkbox.checked) {
      console.log(`Turning ON feature: ${feature}`);
      this.featureFlagsService.enableFeature(feature);
    } else {
      console.log(`Turning OFF feature: ${feature}`);
      this.featureFlagsService.disableFeature(feature);
    }
  }

  render() {
    this.$el.html(
      templateDeveloperPage({
        showFileSystemOptions: this.fileSystemService.isAvailable(),
        featureFlags: this.featureFlagsService.getAllFlags(),
      }),
    );

    return this;
  }
}

export default DeveloperPageView;
