import L from "lodash/fp";
import BaseModel from "scripts/models/baseModel";
import { isRelativeUrl } from "scripts/utils/urlUtil";

class FetchModel extends BaseModel {
  constructor({ audience, token, url }) {
    super();

    this.audience = audience;
    this.token = token;
    this.url = url;
  }

  syncOptions(options) {
    let crossDomain = !isRelativeUrl(this.url);

    const headers = {};

    if (this.audience) {
      headers["X-Biblio-Audience"] = this.audience;
    }

    if (this.token) {
      headers["X-Auth-Token"] = this.token;
    }

    // Backbone.PageableCollection needs options to be mutated, otherwise the xhr won't make it through to parse
    options = L.defaults(options || {}, {
      headers,
      crossDomain,
    });

    return options;
  }

  sync(method, model, options) {
    return super.sync(method, model, this.syncOptions(options));
  }
}

export default FetchModel;
