import BaseAuthenticationModel from "scripts/models/baseAuthenticationModel";

class ForgotPasswordModel extends BaseAuthenticationModel {
  get constraints() {
    return {
      emailAddress: {
        presence: true,
        email: true,
      },
    };
  }

  url() {
    return `${this.authenticationUrlRoot}/notifications/forgot_password`;
  }
}

export default ForgotPasswordModel;
