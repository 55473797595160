var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"anthology") : depth0)) != null ? lookupProperty(stack1,"typeLabelLowerCase") : stack1), depth0))
    + "-"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":7,"column":55},"end":{"line":7,"column":77}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":7,"column":78},"end":{"line":7,"column":93}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyCollectionId") || (depth0 != null ? lookupProperty(depth0,"anthologyCollectionId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyCollectionId","hash":{},"data":data,"loc":{"start":{"line":7,"column":94},"end":{"line":7,"column":119}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":7,"column":120},"end":{"line":7,"column":140}}}) : helper)))
    + "/highlights?sort="
    + alias2(((helper = (helper = lookupProperty(helpers,"contentSort") || (depth0 != null ? lookupProperty(depth0,"contentSort") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"contentSort","hash":{},"data":data,"loc":{"start":{"line":7,"column":157},"end":{"line":7,"column":172}}}) : helper)))
    + "\" class=\"content-filter\"\n               aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"highlights") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"highlights") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"anthology") : depth0)) != null ? lookupProperty(stack1,"typeLabelLowerCase") : stack1), depth0))
    + "-"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":12,"column":55},"end":{"line":12,"column":77}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":12,"column":78},"end":{"line":12,"column":93}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyCollectionId") || (depth0 != null ? lookupProperty(depth0,"anthologyCollectionId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyCollectionId","hash":{},"data":data,"loc":{"start":{"line":12,"column":94},"end":{"line":12,"column":119}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":12,"column":120},"end":{"line":12,"column":140}}}) : helper)))
    + "/articles?sort="
    + alias2(((helper = (helper = lookupProperty(helpers,"contentSort") || (depth0 != null ? lookupProperty(depth0,"contentSort") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"contentSort","hash":{},"data":data,"loc":{"start":{"line":12,"column":155},"end":{"line":12,"column":170}}}) : helper)))
    + "\" class=\"content-filter\"\n               aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"articles") : stack1)) != null ? lookupProperty(stack1,"arialabel") : stack1), depth0))
    + "\"><div>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"articles") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"anthology") : depth0)) != null ? lookupProperty(stack1,"typeLabelLowerCase") : stack1), depth0))
    + "-"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":17,"column":55},"end":{"line":17,"column":77}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":17,"column":78},"end":{"line":17,"column":93}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyCollectionId") || (depth0 != null ? lookupProperty(depth0,"anthologyCollectionId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyCollectionId","hash":{},"data":data,"loc":{"start":{"line":17,"column":94},"end":{"line":17,"column":119}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":17,"column":120},"end":{"line":17,"column":140}}}) : helper)))
    + "/books?sort="
    + alias2(((helper = (helper = lookupProperty(helpers,"contentSort") || (depth0 != null ? lookupProperty(depth0,"contentSort") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"contentSort","hash":{},"data":data,"loc":{"start":{"line":17,"column":152},"end":{"line":17,"column":167}}}) : helper)))
    + "\" class=\"content-filter\"\n               aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"books") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"books") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"anthology") : depth0)) != null ? lookupProperty(stack1,"typeLabelLowerCase") : stack1), depth0))
    + "-"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":22,"column":55},"end":{"line":22,"column":77}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":22,"column":78},"end":{"line":22,"column":93}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyCollectionId") || (depth0 != null ? lookupProperty(depth0,"anthologyCollectionId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyCollectionId","hash":{},"data":data,"loc":{"start":{"line":22,"column":94},"end":{"line":22,"column":119}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":22,"column":120},"end":{"line":22,"column":140}}}) : helper)))
    + "/chapters?sort="
    + alias2(((helper = (helper = lookupProperty(helpers,"contentSort") || (depth0 != null ? lookupProperty(depth0,"contentSort") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"contentSort","hash":{},"data":data,"loc":{"start":{"line":22,"column":155},"end":{"line":22,"column":170}}}) : helper)))
    + "\" class=\"content-filter\"\n               aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"chapters") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"chapters") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"anthology") : depth0)) != null ? lookupProperty(stack1,"typeLabelLowerCase") : stack1), depth0))
    + "-"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":27,"column":55},"end":{"line":27,"column":77}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":27,"column":78},"end":{"line":27,"column":93}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyCollectionId") || (depth0 != null ? lookupProperty(depth0,"anthologyCollectionId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyCollectionId","hash":{},"data":data,"loc":{"start":{"line":27,"column":94},"end":{"line":27,"column":119}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":27,"column":120},"end":{"line":27,"column":140}}}) : helper)))
    + "/other-documents?sort="
    + alias2(((helper = (helper = lookupProperty(helpers,"contentSort") || (depth0 != null ? lookupProperty(depth0,"contentSort") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"contentSort","hash":{},"data":data,"loc":{"start":{"line":27,"column":162},"end":{"line":27,"column":177}}}) : helper)))
    + "\" class=\"content-filter\"\n               aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"documents") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"documents") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"anthology") : depth0)) != null ? lookupProperty(stack1,"typeLabelLowerCase") : stack1), depth0))
    + "-"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":32,"column":55},"end":{"line":32,"column":77}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":32,"column":78},"end":{"line":32,"column":93}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyCollectionId") || (depth0 != null ? lookupProperty(depth0,"anthologyCollectionId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyCollectionId","hash":{},"data":data,"loc":{"start":{"line":32,"column":94},"end":{"line":32,"column":119}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":32,"column":120},"end":{"line":32,"column":140}}}) : helper)))
    + "/images?sort="
    + alias2(((helper = (helper = lookupProperty(helpers,"contentSort") || (depth0 != null ? lookupProperty(depth0,"contentSort") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"contentSort","hash":{},"data":data,"loc":{"start":{"line":32,"column":153},"end":{"line":32,"column":168}}}) : helper)))
    + "\" class=\"content-filter\"\n               aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"images") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"images") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"anthology") : depth0)) != null ? lookupProperty(stack1,"typeLabelLowerCase") : stack1), depth0))
    + "-"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":37,"column":55},"end":{"line":37,"column":77}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":37,"column":78},"end":{"line":37,"column":93}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyCollectionId") || (depth0 != null ? lookupProperty(depth0,"anthologyCollectionId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyCollectionId","hash":{},"data":data,"loc":{"start":{"line":37,"column":94},"end":{"line":37,"column":119}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":37,"column":120},"end":{"line":37,"column":140}}}) : helper)))
    + "/audio?sort="
    + alias2(((helper = (helper = lookupProperty(helpers,"contentSort") || (depth0 != null ? lookupProperty(depth0,"contentSort") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"contentSort","hash":{},"data":data,"loc":{"start":{"line":37,"column":152},"end":{"line":37,"column":167}}}) : helper)))
    + "\" class=\"content-filter\"\n               aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"audio") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"audio") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"anthology") : depth0)) != null ? lookupProperty(stack1,"typeLabelLowerCase") : stack1), depth0))
    + "-"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":42,"column":55},"end":{"line":42,"column":77}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":42,"column":78},"end":{"line":42,"column":93}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyCollectionId") || (depth0 != null ? lookupProperty(depth0,"anthologyCollectionId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyCollectionId","hash":{},"data":data,"loc":{"start":{"line":42,"column":94},"end":{"line":42,"column":119}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":42,"column":120},"end":{"line":42,"column":140}}}) : helper)))
    + "/audiobooks?sort="
    + alias2(((helper = (helper = lookupProperty(helpers,"contentSort") || (depth0 != null ? lookupProperty(depth0,"contentSort") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"contentSort","hash":{},"data":data,"loc":{"start":{"line":42,"column":157},"end":{"line":42,"column":172}}}) : helper)))
    + "\" class=\"content-filter\"\n               aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"audiobook") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"audiobook") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"anthology") : depth0)) != null ? lookupProperty(stack1,"typeLabelLowerCase") : stack1), depth0))
    + "-"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":47,"column":55},"end":{"line":47,"column":77}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":47,"column":78},"end":{"line":47,"column":93}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyCollectionId") || (depth0 != null ? lookupProperty(depth0,"anthologyCollectionId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyCollectionId","hash":{},"data":data,"loc":{"start":{"line":47,"column":94},"end":{"line":47,"column":119}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":47,"column":120},"end":{"line":47,"column":140}}}) : helper)))
    + "/videos?sort="
    + alias2(((helper = (helper = lookupProperty(helpers,"contentSort") || (depth0 != null ? lookupProperty(depth0,"contentSort") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"contentSort","hash":{},"data":data,"loc":{"start":{"line":47,"column":153},"end":{"line":47,"column":168}}}) : helper)))
    + "\" class=\"content-filter\"\n               aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"videos") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"videos") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container\">\n    <div class=\"row m-0 p-0 m-b-40\">\n        <a id=\"bb-anthology-collection-page-content-sub-page-all-content-link\" href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"anthology") : depth0)) != null ? lookupProperty(stack1,"typeLabelLowerCase") : stack1), depth0))
    + "-"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":3,"column":119},"end":{"line":3,"column":141}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":3,"column":142},"end":{"line":3,"column":157}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyCollectionId") || (depth0 != null ? lookupProperty(depth0,"anthologyCollectionId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyCollectionId","hash":{},"data":data,"loc":{"start":{"line":3,"column":158},"end":{"line":3,"column":183}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":3,"column":184},"end":{"line":3,"column":204}}}) : helper)))
    + "?sort="
    + alias2(((helper = (helper = lookupProperty(helpers,"contentSort") || (depth0 != null ? lookupProperty(depth0,"contentSort") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"contentSort","hash":{},"data":data,"loc":{"start":{"line":3,"column":210},"end":{"line":3,"column":225}}}) : helper)))
    + "\" class=\"content-filter\"\n           aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"all") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"all") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"highlightCount") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"articleCount") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"bookCount") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"chapterCount") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"otherDocumentCount") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":29,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"imageCount") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":34,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"audioCount") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":8},"end":{"line":39,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"audiobookCount") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":44,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"videoCount") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":8},"end":{"line":49,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n<div id=\"bb-anthology-collection-page-content-sub-page-content-region\"></div>";
},"useData":true});