import Handlebars from "handlebars-template-loader/runtime";
import inject from "scripts/ioc/inject";

Handlebars.registerHelper("hasOfflineBookshelf", function(options) {
  const bookshelfService = inject("bookshelfService");

  if (bookshelfService.isAvailable()) {
    return options.fn(this);
  }
});
