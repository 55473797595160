import Promise from "bluebird";
import Backbone from "backbone";
import ContentDetailsNotesPageModel from "scripts/models/contentDetailsNotesPageModel";
import BaseView from "scripts/views/baseView";
import ContentDetailsPageAllNotesSubPageView from "scripts/views/contentDetailsPageAllNotesSubPageView";
import ContentDetailsPageNoteSubPageView from "scripts/views/contentDetailsPageNoteSubPageView";

const chan = Backbone.Radio.channel;

class ContentDetailsPageNotesSubPageView extends BaseView {
  constructor(options) {
    super(options);

    this.notesPageModel = new ContentDetailsNotesPageModel();

    this.subPageView = this.addSubView(this.createAllNotesSubView());
  }

  render() {
    this.subPageView.htmlOf(this.$el).render();

    return this;
  }

  sync() {
    return this.subPageView.fetch();
  }

  createAllNotesSubView() {
    const allNotesSubView = new ContentDetailsPageAllNotesSubPageView({
      model: this.notesPageModel,
      contentModel: this.model,
    });

    allNotesSubView.on(
      "createNote editNote",
      noteModel => {
        this.showSubPageView(this.createNoteSubView(noteModel));
      },
      this,
    );

    return allNotesSubView;
  }

  createNoteSubView(noteModel) {
    const noteSubView = new ContentDetailsPageNoteSubPageView({
      model: noteModel,
    });

    noteSubView.on(
      "allNotes",
      () => {
        this.showSubPageView(this.createAllNotesSubView());
      },
      this,
    );

    return noteSubView;
  }

  showSubPageView(subPageView) {
    if (this.subPageViewPromise && this.subPageViewPromise.isPending()) {
      this.subPageViewPromise.cancel();
    }

    chan("display").trigger("bodyViewLoading");

    this.subPageViewPromise = this.showSubView(
      "subPageView",
      subPageView,
      this.$el,
    )
      .catch(Promise.CancellationError, () => {
        // swallow cancellation
        console.log("show sub page view cancelled");
      })
      .finally(value => {
        chan("display").trigger("bodyViewLoaded");

        if (chan("display").request("isStuck")) {
          $(window).scrollTop(chan("display").request("scrollTopToStick"));
        }

        return value;
      });

    return this.subPageViewPromise;
  }
}

export default ContentDetailsPageNotesSubPageView;
