import inject from "scripts/ioc/inject";
import BaseLoginView from "scripts/views/authentication/baseLoginView";

import templateGenericLogin from "templates/authentication/genericLogin.hbs";

class GenericLoginView extends BaseLoginView {
  constructor(
    options,
    historyController = inject("historyController"),
    securityService = inject("securityService"),
  ) {
    super(options, historyController, securityService);
  }

  get template() {
    return templateGenericLogin;
  }
}

export default GenericLoginView;
