import BaseApiCollection from "scripts/collections/baseApiCollection";
import ModuleModel from "scripts/models/moduleModel";

class ModuleCollection extends BaseApiCollection {
  get model() {
    return ModuleModel;
  }
}

export default ModuleCollection;
