import _ from "lodash";
import Backbone from "backbone";
import Promise from "bluebird";
import BreadcrumbModel from "scripts/models/breadcrumbModel";
import BaseView from "scripts/views/baseView";
import FileSizeView from "scripts/views/fileSizeView";
import CurationAddMediaModalView from "scripts/views/curationAddMediaModalView";
import CurationMediaAddedGrowlAlertView from "scripts/views/curationMediaAddedGrowlAlertView";
import CurationMultiSelectModel from "scripts/models/curationMultiSelectModel";
import errorAlert from "scripts/alerts/errorAlert";
import inject from "scripts/ioc/inject";
import { getFirstChapterMediaId } from "scripts/utils/ajaxUtil";
import { getDownloadUrl } from "scripts/utils/mediaHelpers";
import { redirectToFragment } from "scripts/utils/routerHelpers";

const chan = Backbone.Radio.channel;

class BaseTileView extends BaseView {
  constructor(
    options,
    bookshelfService = inject("bookshelfService"),
    connectionService = inject("connectionService"),
    breadcrumbService = inject("breadcrumbService"),
    securityService = inject("securityService"),
    curationMultiSelectService = inject("curationMultiSelectService"),
  ) {
    super(options);

    this.unfavoriteConfirmationRequired = options.unfavoriteConfirmationRequired || false;

    this.curationSelectMode = options.curationSelectMode || false;
    this.viewerParams = options.viewerParams;

    this.bookshelfService = bookshelfService;
    this.connectionService = connectionService;
    this.breadcrumbService = breadcrumbService;
    this.securityService = securityService;
    this.curationMultiSelectService = curationMultiSelectService;
  }

  get events() {
    return {
      "click .btn-view": "onClickViewer",
      "click .btn-detail": "onClickDetail",
      "click .btn-favorite": "onClickFavoriteButton",
      "click .btn-curation-add": "onClickCurationAddButton",
      "click .bb-curation-multi-select-overlay": "handleCurationMultiSelectItem",
      "keydown .bb-curation-multi-select-overlay": "handleCurationMultiSelectItem",
    };
  }

  initializeCurationMultiSelect() {
    chan("curationMultiSelectService").on("toggleSelect", () => this.renderCurationMultiSelectOverlay(), this);

    chan("curationMultiSelectService").on("cancelSelect", () => this.renderCurationMultiSelectOverlay(), this);

    chan("curationMultiSelectService").on("itemAdded", () => this.renderCurationMultiSelectOverlay(), this);

    chan("curationMultiSelectService").on("itemRemoved", () => this.renderCurationMultiSelectOverlay(), this);

    this.renderCurationMultiSelectOverlay();
  }

  bookshelfAvailable() {
    return this.bookshelfService.isAvailable();
  }

  initializeBookshelfDeps() {
    this.fileSizeView = this.addSubView(
      new FileSizeView({
        model: this.model,
      }),
    );

    chan("bookshelfService").on("download:progress", attrs => this.onBookshelfDownloadProgress(attrs), this);
    chan("bookshelfService").on("download:complete", attrs => this.onBookshelfDownloadComplete(attrs), this);
    chan("bookshelfService").on("download:failed", attrs => this.onBookshelfDownloadFailed(attrs), this);

    chan("connectionService").on("status:change", () => this.onConnectionStatusChange(), this);
  }

  initializeDownloadButton() {
    const isOnline = this.connectionService.getConnectionStatus().isOnline;
    const isCompound = this.model.get("mimeType") === "application/biblioboard+compound_content";
    const contentAllowDownload = this.model.get("allowDownload") && !isCompound;

    if (process.env.PATRON_UI_PREVENT_FILE_DOWNLOAD !== "true" && contentAllowDownload) {
      this.showDownloadButton();
    } else {
      this.hideDownloadButton();
    }

    if (isOnline) {
      this.enableDownloadButton();
    } else {
      this.disableDownloadButton();
    }
  }

  showDownloadButton() {
    this.$el.find(".btn-download").removeClass("hide");
  }

  hideDownloadButton() {
    this.$el.find(".btn-download").addClass("hide");
  }

  enableDownloadButton() {
    this.$el.find(".btn-download").removeClass("disabled");
  }

  disableDownloadButton() {
    this.$el.find(".btn-download").addClass("disabled");
  }

  getDownloadUrl() {
    return getDownloadUrl(this.model);
  }

  renderBookshelfElements() {
    this.renderViewButton();
    this.renderBookshelfButton();
    this.renderTotalFileSize();
  }

  //TODO: make common
  renderTotalFileSize(fadeIn) {
    const $totalFileSizeRegion = this.$el.find(".bb-file-size-region");

    if (this.model.get("onBookshelf")) {
      this.fileSizeView.htmlOf($totalFileSizeRegion).render(fadeIn);
    } else {
      $totalFileSizeRegion.empty();
    }
  }

  initDetailButton() {
    const isOnline = this.connectionService.getConnectionStatus().isOnline;

    if (isOnline) {
      this.showDetailButton();
    } else {
      this.hideDetailButton();
    }
  }

  initFavoriteButton() {
    const mediaType = this.model.get("mediaType");

    if (_.includes(["anthology", "content"], mediaType)) {
      const isOnline = this.connectionService.getConnectionStatus().isOnline;

      if (isOnline) {
        if (this.model.get("isFavorite")) {
          this.checkFavoriteButton();
        } else {
          this.uncheckFavoriteButton();
        }

        this.showFavoriteButton();
      } else {
        this.hideFavoriteButton();
      }
    }
  }

  initCurationAddButton() {
    const user = this.securityService.getUser();
    const mediaType = this.model.get("mediaType");

    if (user.hasCuratorRole() && _.includes(["content", "anthology", "collection", "module"], mediaType)) {
      const isOnline = this.connectionService.getConnectionStatus().isOnline;

      if (isOnline) {
        this.showCurationAddButton();
      } else {
        this.hideCurationAddButton();
      }
    }
  }

  onClickCurationAddButton() {
    const curationAddMediaModal = new CurationAddMediaModalView({
      model: new CurationMultiSelectModel({
        media: [this.model],
      }),
    });

    curationAddMediaModal.once(
      "success",
      curationId => {
        const curationMediaAddedGrowlAlertView = this.addSubView(
          new CurationMediaAddedGrowlAlertView({
            curationId: curationId,
            autoClose: true,
          }),
        );

        curationMediaAddedGrowlAlertView.on("close", () => {
          curationMediaAddedGrowlAlertView.animateOut().then(() => this.closeSubView(curationMediaAddedGrowlAlertView));
        });

        curationMediaAddedGrowlAlertView.appendTo($("#bb-app-growl-alert-region")).render();
      },
      this,
    );

    curationAddMediaModal.once("failure", errorAlert);

    chan("display").request("showBlockingLoader");

    curationAddMediaModal.fetch().then(() => {
      chan("display").request("hideBlockingLoader");
      chan("display").request("showModal", curationAddMediaModal);
    });

    return false;
  }

  initTooltips() {
    // set up to NOT initialize for tablets...just caused too many conflicts
    if (chan("display").request("isLargerThanSm")) {
      this.$el.find('*[data-toggle="tooltip"]').tooltip({
        placement: "top",
        trigger: "hover",
        delay: 350,
        container: "body",
      });
    }
  }

  renderViewButton() {
    const mediaType = this.model.get("mediaType");

    /**
     * Prevent viewing content if offline and content file(s)
     * are not downloaded to bookshelf.
     */
    if (mediaType === "content") {
      const isOnline = this.connectionService.getConnectionStatus().isOnline;
      const mediaId = this.model.get("id");
      const organizationId = this.securityService.getUser().getActiveOrganizationId();

      this.bookshelfService.isContentFileAvailableOnBookshelf(mediaId, organizationId).then(isAvailable => {
        if (!isAvailable && !isOnline) {
          this.disableViewButton();
        } else {
          this.enableViewButton();
        }
      });
    }
  }

  disableViewButton() {
    this.$el.find(".btn-view").addClass("disabled");
  }

  enableViewButton() {
    this.$el.find(".btn-view").removeClass("disabled");
  }

  renderBookshelfButton() {
    if (this.bookshelfAvailable()) {
      if (this.model.get("onBookshelf")) {
        this.checkBookshelfButton();
      } else {
        this.uncheckBookshelfButton();
      }

      this.showBookshelfButton();
    } else {
      this.hideBookshelfButton();
    }
  }

  onClickFavoriteButton() {
    if (this.model.get("isFavorite")) {
      this.unfavorite();
    } else {
      this.favorite();
    }

    return false;
  }

  favorite() {
    this.model.set("isFavorite", true);
    this.checkFavoriteButton();

    chan("myBoard")
      .request("favorite", this.model)
      .bind(this)
      .catch(error => {
        this.model.set("isFavorite", false);
        this.uncheckFavoriteButton();
        if (!(error instanceof Promise.CancellationError)) {
          errorAlert(error).show();
        }
      });
  }

  unfavorite() {
    this.model.set("isFavorite", false);
    this.uncheckFavoriteButton();

    chan("myBoard")
      .request("unfavorite", this.model, this.unfavoriteConfirmationRequired)
      .bind(this)
      .catch(error => {
        this.model.set("isFavorite", true);
        this.checkFavoriteButton();
        if (!(error instanceof Promise.CancellationError)) {
          errorAlert(error).show();
        }
      });
  }

  hideFavoriteButton() {
    this.$el.find(".btn-favorite").addClass("hide");
  }

  showFavoriteButton() {
    this.$el.find(".btn-favorite").removeClass("hide");
  }

  checkFavoriteButton() {
    this.$el.find(".btn-favorite").addClass("active");
  }

  uncheckFavoriteButton() {
    this.$el.find(".btn-favorite").removeClass("active");
  }

  hideFavoriteRibbon() {
    this.$el.find(".bb-list-tile-ribbon-favorite").addClass("hide");
  }

  showFavoriteRibbon() {
    this.$el.find(".bb-list-tile-ribbon-favorite").removeClass("hide");
  }

  onConnectionStatusChange() {
    this.initFavoriteButton();
    this.renderViewButton();
    this.initCurationAddButton();
  }

  //TODO: make common
  onBookshelfDownloadProgress(attrs) {
    if (attrs.mediaId === this.model.get("id") && this.model.get("onBookshelf")) {
      this.updateProgressBar((attrs.filesCompleted / attrs.totalFiles) * 100);
    }
  }

  //TODO: make common
  onBookshelfDownloadComplete(attrs) {
    if (attrs.mediaId === this.model.get("id")) {
      this.model.set({
        downloadComplete: true,
        totalSize: attrs.totalSize,
      });

      this.hideProgressBar();
      this.renderTotalFileSize(true);
    }
  }

  //TODO: make common
  onBookshelfDownloadFailed(attrs) {
    if (attrs.mediaId === this.model.get("id")) {
      this.model.set({
        downloadComplete: false,
        totalSize: attrs.totalSize,
      });

      this.hideProgressBar();
      this.renderTotalFileSize(true);
    }
  }

  //TODO: make common
  onClickBookshelfButton() {
    if (!this.isBookshelfButtonEnabled()) {
      return false;
    }

    if (this.model.get("onBookshelf")) {
      this.removeFromBookshelf();
    } else {
      this.addToBookshelf();
    }

    return false;
  }

  //TODO: make common
  addToBookshelf() {
    chan("myBoard")
      .request("bookshelfAdd", this.model)
      .then(() => {
        this.model.set({
          onBookshelf: true,
        });

        this.renderTotalFileSize();
        this.renderBookshelfButton();
      })
      .catch(error => {
        if (!(error instanceof Promise.CancellationError)) {
          throw error;
        }
      });
  }

  //TODO: make common
  onCancelBookshelfDownload() {
    chan("myBoard")
      .request("bookshelfCancelDownload", this.model)
      .then(() => {
        this.model.set({
          onBookshelf: false,
          downloadComplete: false,
          totalSize: void 0,
        });

        this.hideProgressBar();
        this.renderTotalFileSize();
        this.renderBookshelfButton();
      })
      .catch(error => {
        if (!(error instanceof Promise.CancellationError)) {
          throw error;
        }
      });
  }

  //TODO: make common
  removeFromBookshelf() {
    chan("myBoard")
      .request("bookshelfRemove", this.model)
      .then(() => {
        this.model.set({
          onBookshelf: false,
          downloadComplete: false,
          totalSize: void 0,
        });

        this.hideProgressBar();
        this.renderTotalFileSize();
        this.renderBookshelfButton();
      })
      .catch(error => {
        if (!(error instanceof Promise.CancellationError)) {
          throw error;
        }
      });
  }

  hideBookshelfButton() {
    this.$el.find(".btn-bookshelf").addClass("hide");
  }

  showBookshelfButton() {
    this.$el.find(".btn-bookshelf").removeClass("hide");
  }

  disableBookshelfButton() {
    this.$el.find(".btn-bookshelf").addClass("disabled");
  }

  enableBookshelfButton() {
    this.$el.find(".btn-bookshelf").removeClass("disabled");
  }

  isBookshelfButtonEnabled() {
    return !this.$el.find(".btn-bookshelf").hasClass("disabled");
  }

  checkBookshelfButton() {
    this.$el.find(".btn-bookshelf").addClass("active");
  }

  uncheckBookshelfButton() {
    this.$el.find(".btn-bookshelf").removeClass("active");
  }

  onClickViewer() {
    if (this.model.has("referringParent")) {
      this.saveReferringParent();
    }

    chan("tracking").trigger("viewer:click", this.model);

    const mimeType = this.model.get("mimeType");
    const mediaId = this.model.get("id");

    if (mimeType === "application/biblioboard+compound_content") {
      getFirstChapterMediaId(mediaId).then(chapterMediaId => {
        if (chapterMediaId) {
          redirectToFragment(`/viewer/${chapterMediaId}`);
        } else {
          errorAlert(new Error()).show();
        }
      });

      return false;
    }
  }

  hideDetailButton() {
    this.$el.find(".btn-detail").addClass("hide");
  }

  showDetailButton() {
    this.$el.find(".btn-detail").removeClass("hide");
  }

  onClickDetail() {
    if (this.model.has("referringParent")) {
      this.saveReferringParent();
    }

    chan("tracking").trigger("detail:click", this.model);
  }

  hideCurationAddButton() {
    this.$el.find(".btn-curation-add").addClass("hide");
  }

  showCurationAddButton() {
    this.$el.find(".btn-curation-add").removeClass("hide");
  }

  saveReferringParent() {
    const breadcrumbModel = new BreadcrumbModel({
      referrer: {
        parent: this.model.get("referringParent"),
        child: this.model,
      },
    });

    this.breadcrumbService.updateBreadcrumb({ breadcrumbModel });
  }

  handleCurationMultiSelectItem(event) {
    if (event.type === "keydown") {
      if (event.key !== " ") {
        return;
      }
      event.preventDefault();
    }
    const isSelected = $(event.target).attr("aria-checked") === "true";
    $(event.target).attr("aria-checked", !isSelected);
    this.curationMultiSelectService.toggleSelectItem(this.model);
  }

  renderCurationMultiSelectOverlay() {
    if (this.curationMultiSelectService.getActive()) {
      let $overlay = this.$el.find(".bb-curation-multi-select-overlay");
      if ($overlay.length === 0) {
        $overlay = $("<div />")
          .addClass("bb-curation-multi-select-overlay")
          .attr("role", "checkbox")
          .attr("aria-labelledby", "bb-curation-multiselect-add-button")
          .attr("aria-checked", "false")
          .attr("tabIndex", 0);
        $overlay.appendTo(this.$el);
      }

      if (this.curationMultiSelectService.isSelected(this.model)) {
        $overlay.addClass("bb-curation-multi-select-overlay-selected");
      } else {
        $overlay.removeClass("bb-curation-multi-select-overlay-selected");
      }
    } else {
      this.$el.find(".bb-curation-multi-select-overlay").remove();
    }
  }

  getViewerUrl() {
    const modelId = this.model.get("id");
    let viewerUrl = `/viewer/${modelId}`;
    if (!_.isNil(this.viewerParams)) {
      viewerUrl = `${viewerUrl}?${this.viewerParams}`;
    }

    return viewerUrl;
  }

  getDetailUrl() {
    const modelId = this.model.get("id");
    let detailUrl = `/content/${modelId}`;
    if (this.connectionService.isOnline()) {
      if (!_.isNil(this.viewerParams)) {
        // this is done to pass params through to the viewer...
        detailUrl = `${detailUrl}?${this.viewerParams}`;
      }
    } else {
      // when offline there is no detail page.
      detailUrl = this.getViewerUrl();
    }

    return detailUrl;
  }
}

export default BaseTileView;
