import _ from "lodash";
import Promise from "bluebird";

function getJson(fileSystemService, model) {
  const filePath = _.result(model, "filePath");

  if (filePath) {
    console.log(
      `File system sync reading model/collection from file system path: ${filePath}`,
    );
    return fileSystemService.readObject(filePath);
  } else {
    return Promise.reject(
      new Error(
        "Cannot retrieve model / collection data from file system, there is no associated filePath.",
      ),
    );
  }
}

export default function(fileSystemService) {
  return function(method, model, options) {
    let promise;

    switch (method) {
      case "read":
        promise = getJson(fileSystemService, model);
        break;
      case "create":
        throw new Error("Create not implemented.");
      case "update":
        throw new Error("Update not implemented.");
      case "delete":
        throw new Error("Delete not implemented.");
      default:
        throw new Error("Unknown method");
    }

    if (options && options.success) {
      promise = promise.then(resp => {
        options.success(resp);
      });
    }

    if (options && options.error) {
      promise = promise.catch(error => {
        options.error(error);
        throw error;
      });
    }

    return promise.then(() => model);
  };
}
