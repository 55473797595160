import _ from "lodash";
import Backbone from "backbone";
import ContentDetailsControllerView from "scripts/views/contentDetailsControllerView";
import Controller from "scripts/controllers/controller";
import { replaceFragment } from "scripts/utils/routerHelpers";
import { parseQueryString } from "scripts/utils/urlUtil";

const chan = Backbone.Radio.channel;
const uuidRegex = /^\w{8}(-\w{4}){3}-\w{12}$/;

class ContentDetailsController extends Controller {
  get route() {
    return "route:content";
  }

  get defaults() {
    return {
      subPageType: "details",
    };
  }

  routeAttrs(args) {
    let mediaId = args[0];
    let subPageType = args[1];
    const queryParams = parseQueryString(args[args.length -1]);

    if (!uuidRegex.test(args[0])) {
      mediaId = args[1];
      subPageType = args[2];
    }

    let rewriteUrl = `/content/${mediaId}`;

    if (subPageType) {
      rewriteUrl = `/content/${mediaId}/${subPageType}`;
    }

    replaceFragment(rewriteUrl);

    return _.merge({}, this.defaults, {
      mediaId: mediaId,
      subPageType: subPageType ? subPageType : void 0,
      queryParams: queryParams,
    });
  }

  replyRoute(route, ...args) {
    const attrs = this.routeAttrs(args);

    const contentDetailsControllerView = chan("controllerView").request(
      "contentDetailsControllerView",
      attrs.mediaId,
    );

    if (contentDetailsControllerView) {
      return contentDetailsControllerView.showContentDetailsSubPageType(
        attrs.subPageType,
      );
    } else {
      chan("tracking").trigger("content:record:view", attrs);
      return this.showMainBodyView(new ContentDetailsControllerView(attrs));
    }
  }
}

export default ContentDetailsController;
