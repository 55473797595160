import serviceLocator from "scripts/ioc/serviceLocator";

import BreadcrumbService from "scripts/services/breadcrumbService";
import ClientTrackingService from "scripts/services/clientTrackingService";
import CounterService from "scripts/services/counterService";
import CurrentLocationService from "scripts/services/currentLocationService";
import FeatureFlagsService from "scripts/services/featureFlagsService";
import GeolocationService from "scripts/services/geolocationService";
import GoogleAnalyticsService from "scripts/services/googleAnalyticsService";
import RelatedContentService from "scripts/services/relatedContentService";
import SecurityService from "scripts/services/securityService";
import SelfEService from "scripts/services/selfEService";
import SessionStorageService from "scripts/services/sessionStorageService";
import CurationMultiSelectService from "scripts/services/curationMultiSelectService";
import UserOrganizationPreferencesService from "scripts/services/userOrganizationPreferencesService";
import i18nextService from "scripts/services/i18nextService";

import AnthologyCollectionController from "scripts/controllers/anthologyCollectionController";
import AnthologyController from "scripts/controllers/anthologyController";
import BugsnagController from "scripts/controllers/bugsnagController";
import CategoriesController from "scripts/controllers/categoriesController";
import ChooseCurationThumbnailController from "scripts/controllers/chooseCurationThumbnailController";
import ContentDetailsController from "scripts/controllers/contentDetailsController";
import CurationController from "scripts/controllers/curationController";
import CurationsController from "scripts/controllers/curationsController";
import DeveloperController from "scripts/controllers/developerController";
import DiagnosticsController from "scripts/controllers/diagnosticsController";
import ErrorController from "scripts/controllers/errorController";
import SearchController from "scripts/controllers/searchController";
import GenericController from "scripts/controllers/genericController";
import HistoryController from "scripts/controllers/historyController";
import HomeController from "scripts/controllers/homeController";
import LegacyAnthologyUrlForwardController from "scripts/controllers/legacyAnthologyUrlForwardController";
import LegacyContentUrlForwardController from "scripts/controllers/legacyContentUrlForwardController";
import LegacyModuleUrlForwardController from "scripts/controllers/legacyModuleUrlForwardController";
import ManageCurationController from "scripts/controllers/manageCurationController";
import ManageCurationsController from "scripts/controllers/manageCurationsController";
import ModuleController from "scripts/controllers/moduleController";
import ModulesController from "scripts/controllers/modulesController";
import MyBoardController from "scripts/controllers/myBoardController";
import OrganizationSessionController from "scripts/controllers/organizationSessionController";
import ResetPasswordController from "scripts/controllers/resetPasswordController";
import SecurityController from "scripts/controllers/securityController";
import SignUpController from "scripts/controllers/signUpController";
import UserPreferencesController from "scripts/controllers/userPreferencesController";
import VerifyEmailController from "scripts/controllers/verifyEmailController";
import ViewerController from "scripts/controllers/viewerController";
import WhichOrganizationController from "scripts/controllers/whichOrganizationController";
import AssociateOrganizationController from "scripts/controllers/associateOrganizationController";
import RequestAccessController from "scripts/controllers/requestAccessController";
import CollectionController from "scripts/controllers/collectionController";

class BaseApplicationConfiguration {
  registerInstance(name, instance) {
    console.log(`Registering instance: ${name}`);

    serviceLocator.reply(name, instance);
  }

  registerSingleton(name, fn) {
    console.log(`Registering singleton: ${name}`);

    serviceLocator.reply(name, new fn());
  }

  configureServices() {
    this.registerSingleton("i18nextService", i18nextService);
    this.registerSingleton("sessionStorageService", SessionStorageService);
    this.registerSingleton("breadcrumbService", BreadcrumbService);
    this.registerSingleton("clientTrackingService", ClientTrackingService);
    this.registerSingleton("counterService", CounterService);
    this.registerSingleton("geolocationService", GeolocationService);
    this.registerSingleton("userOrganizationPreferencesService", UserOrganizationPreferencesService);
    this.registerSingleton("securityService", SecurityService);
    this.registerSingleton("googleAnalyticsService", GoogleAnalyticsService);
    this.registerSingleton("currentLocationService", CurrentLocationService);
    this.registerSingleton("selfEService", SelfEService);
    this.registerSingleton("featureFlagsService", FeatureFlagsService);
    this.registerSingleton("relatedContentService", RelatedContentService);
    this.registerSingleton("curationMultiSelectService", CurationMultiSelectService);
  }

  configureControllers() {
    this.registerSingleton("anthologyCollectionController", AnthologyCollectionController);
    this.registerSingleton("collectionController", CollectionController);
    this.registerSingleton("anthologyController", AnthologyController);
    this.registerSingleton("bugsnagController", BugsnagController);
    this.registerSingleton("categoriesController", CategoriesController);
    this.registerSingleton("contentDetailsController", ContentDetailsController);
    this.registerSingleton("diagnosticsController", DiagnosticsController);
    this.registerSingleton("errorController", ErrorController);
    this.registerSingleton("genericController", GenericController);
    this.registerSingleton("historyController", HistoryController);
    this.registerSingleton("homeController", HomeController);
    this.registerSingleton("legacyAnthologyUrlForwardController", LegacyAnthologyUrlForwardController);
    this.registerSingleton("legacyContentUrlForwardController", LegacyContentUrlForwardController);
    this.registerSingleton("legacyModuleUrlForwardController", LegacyModuleUrlForwardController);
    this.registerSingleton("moduleController", ModuleController);
    this.registerSingleton("modulesController", ModulesController);
    this.registerSingleton("myBoardController", MyBoardController);
    this.registerSingleton("organizationSessionController", OrganizationSessionController);
    this.registerSingleton("resetPasswordController", ResetPasswordController);
    this.registerSingleton("securityController", SecurityController);
    this.registerSingleton("signUpController", SignUpController);
    this.registerSingleton("userPreferencesController", UserPreferencesController);
    this.registerSingleton("viewerController", ViewerController);
    this.registerSingleton("verifyEmailController", VerifyEmailController);
    this.registerSingleton("whichOrganizationController", WhichOrganizationController);
    this.registerSingleton("curationController", CurationController);
    this.registerSingleton("manageCurationsController", ManageCurationsController);
    this.registerSingleton("manageCurationController", ManageCurationController);
    this.registerSingleton("curationsController", CurationsController);
    this.registerSingleton("chooseCurationThumbnailController", ChooseCurationThumbnailController);
    this.registerSingleton("searchController", SearchController);
    this.registerSingleton("associateOrganizationController", AssociateOrganizationController);
    this.registerSingleton("requestAccessController", RequestAccessController);

    this.registerSingleton("developerController", DeveloperController);
  }

  configure() {
    this.configureServices();
    this.configureControllers();
  }
}

export default BaseApplicationConfiguration;
