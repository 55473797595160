import _ from "lodash";
import inject from "scripts/ioc/inject";
import BasePageableCollection from "scripts/collections/basePageableCollection";
import ApiCollectionHoc from "scripts/hoc/apiCollectionHoc";

class BaseApiPageableCollection extends ApiCollectionHoc(
  BasePageableCollection,
) {
  constructor(models, options, bookshelfService = inject("bookshelfService")) {
    options = options || {};

    options.state = _.defaults(options.state || {}, {
      firstPage: 0,
      currentPage: 0,
      pageSize: 20,
    });

    options.queryParams = _.defaults(options.queryParams || {}, {
      totalPages: null,
      totalRecords: null,
      currentPage: null,
      pageSize: "limit",
      offset: () => {
        return this.state.currentPage * this.state.pageSize;
      },
    });

    options.mode = options.mode || "infinite";

    super(models, options);

    this.bookshelfService = bookshelfService;
  }

  parse(response, options) {
    this.totalRecords = parseInt(
      options.xhr.getResponseHeader("X-Total-Count"),
      10,
    );
    return super.parse(response, options);
  }

  /**
   * Convert relative URLs returned by API to absolute URLs.
   */
  parseLinks(...args) {
    const links = super.parseLinks(...args);
    return _.mapValues(links, url => this.absoluteUrl(url));
  }

  absoluteUrl(relativeUrl) {
    return this.apiUrlRoot + relativeUrl.replace(/^\/api/, "");
  }
}

export default BaseApiPageableCollection;
