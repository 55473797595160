import BaseService from "scripts/services/baseService";
import SessionStorageModel from "scripts/models/sessionStorageModel";

class SessionStorageService extends BaseService {
  constructor() {
    super();

    this.sessionStorageModel = new SessionStorageModel();
  }

  getAttributes() {
    return this.sessionStorageModel
      .fetch()
      .then(model => {
        return model.toJSON();
      })
      .catch(() => {
        return {};
      });
  }

  setAttributes(attributes) {
    return this.sessionStorageModel.save(attributes);
  }

  clearAttributes() {
    return this.sessionStorageModel.destroy().then(model => {
      model.clear();
    });
  }
}

export default SessionStorageService;
