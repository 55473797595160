import _ from "lodash";
import L from "lodash/fp";
import Backbone from "backbone";
import BaseView from "scripts/views/baseView";
import DiagnosticsAuthenticationModel from "scripts/models/diagnosticsAuthenticationModel";
import inject from "scripts/ioc/inject";
import templateDiagnosticsScreen from "templates/diagnosticsScreen.hbs";
import templateDiagnosticsScreenGps from "templates/diagnosticsScreenGps.hbs";

const chan = Backbone.Radio.channel;

class DiagnosticsScreenView extends BaseView {
  constructor(
    options,
    geolocationService = inject("geolocationService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);

    this.diagnosticsAuthenticationModel = new DiagnosticsAuthenticationModel();
    this.geolocationService = geolocationService;
    this.i18nextService = i18nextService;
  }

  get template() {
    return templateDiagnosticsScreen;
  }

  get events() {
    return {
      "click #bb-diagnositcs-screen-gps-button": "onClickGetGps",
      "click #bb-diagnostics-screen-back-button": "onClickBackButton",
      "click #bb-diagnostics-email": "onClickEmail",
      "click #bb-diagnostics-copy": "onClickCopy",
    };
  }

  render() {
    const organization =
      this.diagnosticsAuthenticationModel.get("organization");

    const i18n = this.getTranslations();

    this.$el.html(
      this.template(
        _.merge(
          {
            i18n,
            date: this.diagnosticsAuthenticationModel.get("timestamp"),
            organizationName: organization
              ? organization.organizationName
              : void 0,
          },
          this.diagnosticsAuthenticationModel.toJSON(),
        ),
      ),
    );

    // initialize tooltip
    this.$el.find("#bb-diagnostics-copy").tooltip({
      placement: "top",
      trigger: "manual",
      container: "body",
    });

    return this;
  }

  getTranslations() {
    const glossary = this.i18nextService.getGlossary();
    const common = this.i18nextService.getCommon();
    const attributes = this.i18nextService.getAttributes();

    const diagnostics = L.get(["app", "diagnostics"])(glossary);
    const organization = L.get(["organization", "singular"])(glossary);
    const back = L.get(["button", "back"], common);
    const diagnosticsCopied = L.get(["title", "diagnosticsCopied"], attributes);

    return { back, diagnostics, diagnosticsCopied, organization };
  }

  renderGpsInfo(gpsInfo) {
    const i18n = {
      geoTime: L.get(["diagnostics", "geoTime"], this.getTranslations()),
    };

    $("#gps-info-region").html(
      templateDiagnosticsScreenGps(L.merge({ i18n }, gpsInfo)),
    );
  }

  sync() {
    return this.diagnosticsAuthenticationModel.fetch();
  }

  initialScrollTop() {
    return "bottom";
  }

  getDiagnosticsText() {
    return document
      .getElementById("bb-diagnostics-container")
      .innerText.replace(
        /Back|Email Diagnostics to (Support|support@biblioboard.com)|Copy Diagnostics/g,
        "",
      );
  }

  onClickGetGps() {
    chan("display").request("showBlockingLoader");

    this.geolocationService
      .getCurrentPosition()
      .then(location => {
        this.renderGpsInfo({
          gpsTime: parseInt(location.timeTaken, 10),
          gpsLatitude: location.position.coords.latitude,
          gpsLongitude: location.position.coords.longitude,
        });

        chan("display").request("hideBlockingLoader");
      })
      .catch(error => {
        console.error(error);

        chan("display").request("hideBlockingLoader");
      });
  }

  onClickBackButton() {
    chan("history").request("back");
  }

  onClickEmail(e) {
    e.target.href += `&body=${encodeURIComponent(this.getDiagnosticsText())}`;
  }

  onClickCopy() {
    navigator.clipboard
      .writeText(this.getDiagnosticsText())
      .then(() => {
        this.$el.find("#bb-diagnostics-copy").tooltip("show");
        setTimeout(() => {
          this.$el.find("#bb-diagnostics-copy").tooltip("hide");
        }, 3000);
      })
      .catch(() => console.log("Text cannot be copied"));
  }
}

export default DiagnosticsScreenView;
