import _ from "lodash";
import Backbone from "backbone";
import Controller from "scripts/controllers/controller";
import AnthologyModel from "scripts/models/anthologyModel";
import AnthologyPageView from "scripts/views/anthologyPageView";
import AnthologyPageCollectionsSubPageView from "scripts/views/anthologyPageCollectionsSubPageView";
import AnthologyPageContentSubPageView from "scripts/views/anthologyPageContentSubPageView";
import AnthologyPageOverviewSubPageView from "scripts/views/anthologyPageOverviewSubPageView";
import inject from "scripts/ioc/inject";

const chan = Backbone.Radio.channel;

class AnthologyController extends Controller {
  constructor(securityService = inject("securityService")) {
    super();

    this.securityService = securityService;
  }

  get route() {
    return "route:anthology";
  }

  get defaults() {
    return {
      subPageType: "collections",
      contentType: "all-content",
      presentationType: "masonry",
    };
  }

  routeAttrs() {
    const user = this.securityService.getUser();

    let pageIndex = parseInt(arguments[4], 10);

    if (!isNaN(pageIndex)) {
      pageIndex = pageIndex - 1;
    } else {
      pageIndex = 0;
    }

    return _.merge(
      {},
      this.defaults,
      {
        presentationType: user.getContentViewPreference(),
      },
      {
        anthologyId: arguments[0],
        subPageType: arguments[1] || void 0,
        presentationType: arguments[2] || void 0,
        contentType: arguments[3] || void 0,
        pageIndex,
      },
    );
  }

  replyRoute() {
    const attrs = this.routeAttrs.apply(this, _.tail(arguments));

    const subPageView = this.createSubPageView(attrs);

    let anthologyPageView = chan("controllerView").request(
      "anthologyPageView",
      attrs.anthologyId,
    );

    if (anthologyPageView) {
      anthologyPageView.model.set(attrs);
      return anthologyPageView.showSubPageView(subPageView);
    } else {
      anthologyPageView = new AnthologyPageView({
        model: new Backbone.Model(attrs),
        subPageView: subPageView,
        mediaModel: new AnthologyModel({
          id: attrs.anthologyId,
        }),
      });

      return this.showMainBodyView(anthologyPageView);
    }
  }

  createSubPageView(attrs) {
    let subPage;

    if (attrs.subPageType === "overview") {
      subPage = new AnthologyPageOverviewSubPageView({
        model: new AnthologyModel({
          id: attrs.anthologyId,
        }),
      });
    } else if (attrs.subPageType === "content") {
      subPage = new AnthologyPageContentSubPageView({
        model: new Backbone.Model({
          anthologyId: attrs.anthologyId,
          presentationType: attrs.presentationType,
          contentType: attrs.contentType,
          pageIndex: attrs.pageIndex,
        }),
        anthologyModel: new AnthologyModel({
          id: attrs.anthologyId,
        }),
      });
    } else {
      // collections or chapters
      subPage = new AnthologyPageCollectionsSubPageView({
        model: new Backbone.Model({
          anthologyId: attrs.anthologyId,
          presentationType: attrs.presentationType,
        }),
        anthologyModel: new AnthologyModel({
          id: attrs.anthologyId,
        }),
      });
    }

    return subPage;
  }
}

export default AnthologyController;
