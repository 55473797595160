import Backbone from "backbone";
import BaseListTileView from "scripts/views/baseListTileView";
import templateAudioListTile from "templates/audioListTile.hbs";

const chan = Backbone.Radio.channel;

class AudioListTileView extends BaseListTileView {
  get template() {
    return templateAudioListTile;
  }

  onClickViewer() {
    if (this.model.has("referringParent")) {
      this.saveReferringParent();
    }

    chan("display").request("playAudio", this.model);
    chan("tracking").trigger("viewer:click", this.model);
    return false;
  }
}

export default AudioListTileView;
