import _ from "lodash";
import L from "lodash/fp";
import BaseApiPageableCollection from "scripts/collections/baseApiPageableCollection";
import MediaCollectionHoc from "scripts/hoc/mediaCollectionHoc";
import HybridCollectionHoc from "scripts/hoc/hybridCollectionHoc";

const contentFacetFilter = L.filter(v => v.code !== "CONTENT");

class FacetedSearchResultCollection extends HybridCollectionHoc(
  MediaCollectionHoc(BaseApiPageableCollection),
) {
  get defaultQueryStringParams() {
    return _.merge({ "facets-only": true }, super.defaultQueryStringParams);
  }

  parse(response, options) {
    this.facets = response.facets;

    // 'content' doesn't make much sense in the UI where the omniType is used
    if (
      this.facets &&
      this.facets.omniType &&
      this.facets.omniType.facetValues
    ) {
      this.facets.omniType.facetValues = contentFacetFilter(
        this.facets.omniType.facetValues,
      );
    }

    this.searchTime = response.searchTime;

    return super.parse(response.media, options);
  }
}

export default FacetedSearchResultCollection;
