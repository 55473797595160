import Backbone from "backbone";
import Promise from "bluebird";
import Velocity from "velocity-animate";
import BaseView from "scripts/views/baseView";
import templateScrollToTop from "templates/scrollToTop.hbs";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";

const chan = Backbone.Radio.channel;

class ScrollToTopView extends BaseView {
  get template() {
    return templateScrollToTop;
  }

  get events() {
    return {
      "click #bb-scroll-to-top-btn": "scrollToTop",
      "mouseenter #bb-scroll-to-top-btn": "resetScrollTimeout",
      "mouseleave #bb-scroll-to-top-btn": "setupFadeOutTimer",
    };
  }

  get scrollTopThreshold() {
    return 1200;
  }

  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.isAutoScrolling = false;
    this.isVisible = false;
    this.isAnimatingIn = false;
    this.isAnimatingOut = false;

    chan("display").on("scroll:scrolling", this.onScroll, this);
    chan("display").on("scroll:stop", this.setupFadeOutTimer, this);
  }

  render() {
    const scrollToTop = L.get(
      ["ariaLabel", "scrollToTop"],
      this.i18nextService.getAttributes(),
    );

    this.$el.html(this.template({ i18n: { scrollToTop } }));

    return this;
  }

  scrollToTop() {
    this.isAutoScrolling = true;

    return Promise.all([this.doScrollToTop(), this.fadeOut()]).then(() => {
      this.isAutoScrolling = false;
    });
  }

  doScrollToTop() {
    return Velocity.animate(document.querySelector("html"), "scroll", {
      duration: 1000,
      easing: "easeInOutQuint",
      mobileHA: false,
    });
  }

  fadeOut() {
    if (this.isVisible && !this.isAnimatingOut) {
      this.isAnimatingOut = true;
      const scrollTopButton = document.querySelector("#bb-scroll-to-top-btn");

      Velocity(scrollTopButton, "stop", true);

      return Velocity.animate(scrollTopButton, "fadeOut", {
        duration: 300,
      }).then(() => {
        this.isVisible = false;
        this.isAnimatingOut = false;
      });
    } else {
      return Promise.resolve();
    }
  }

  fadeIn() {
    if (!this.isVisible && !this.isAnimatingIn) {
      this.isAnimatingIn = true;
      const scrollTopButton = document.querySelector("#bb-scroll-to-top-btn");

      Velocity(scrollTopButton, "stop", true);

      return Velocity.animate(scrollTopButton, "fadeIn", {
        duration: 500,
      }).then(() => {
        this.isVisible = true;
        this.isAnimatingIn = false;
      });
    } else {
      return Promise.resolve();
    }
  }

  resetScrollTimeout() {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
  }

  onScroll(scrollTop) {
    this.resetScrollTimeout();

    if (!this.isAutoScrolling) {
      if (scrollTop >= this.scrollTopThreshold) {
        this.fadeIn();
      } else {
        this.fadeOut();
      }
    }
  }

  setupFadeOutTimer() {
    const scrollTopButton = document.querySelector("#bb-scroll-to-top-btn");

    if (scrollTopButton && !scrollTopButton.querySelector(":hover")) {
      this.scrollTimeout = setTimeout(() => {
        this.fadeOut();
      }, 3000);
    }
  }

  close() {
    clearTimeout(this.scrollTimeout);
    super.close();
  }
}

export default ScrollToTopView;
