var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"bb-change-password-form\" class=\"bb-form\">\n    <div class=\"row m-t-30 m-lr-0\">\n        <div class=\"col-md-6\">\n            <div class=\"form-group\">\n                <input name=\"oldPassword\" type=\"password\" class=\"form-control input-outline m-t-5\"\n                    id=\"bb-change-password-form-old-password-field\" placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"password") : stack1)) != null ? lookupProperty(stack1,"old") : stack1), depth0))
    + "\"\n                    autocomplete=\"current-password\">\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row m-t-10 m-b-20 m-lr-0\">\n        <div class=\"col-md-6\">\n            <div class=\"form-group\">\n                <input name=\"newPassword\" type=\"password\" class=\"form-control input-outline m-t-5\"\n                    id=\"bb-change-password-form-new-password-field\" placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"password") : stack1)) != null ? lookupProperty(stack1,"new") : stack1), depth0))
    + "\"\n                    autocomplete=\"new-password\">\n            </div>\n        </div>\n        <div class=\"col-md-6\">\n            <div class=\"form-group\">\n                <input name=\"confirmPassword\" type=\"password\" class=\"form-control input-outline m-t-5\"\n                    id=\"bb-change-password-form-confirm-password-field\" placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"password") : stack1)) != null ? lookupProperty(stack1,"confirm") : stack1), depth0))
    + "\"\n                    autocomplete=\"new-password\">\n            </div>\n        </div>\n    </div>\n\n    <!--submit button-->\n  <div class=\"row m-t-10 m-b-20 m-lr-15\">\n    <button type=\"submit\" class=\"btn-rounded btn-rounded-brand-primary btn-block m-b-20\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"change") : stack1), depth0))
    + "</button>\n      <div class=\"bb-form-submit-button-loader-region btn btn-rounded btn-rounded-brand-primary btn-block disabled hide\">\n      </div>\n  </div>\n</form>";
},"useData":true});