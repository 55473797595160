import BaseView from "scripts/views/baseView";

class CommentsFlagConfirmDialogView extends BaseView {
  render() {
    this.$el.modal("show");

    return this;
  }

  remove() {
    this.$el.modal("hide");
  }
}

export default CommentsFlagConfirmDialogView;
