import Handlebars from "handlebars-template-loader/runtime";
import inject from "scripts/ioc/inject";

Handlebars.registerHelper("hasProfile", function(options) {
  const securityService = inject("securityService");
  const user = securityService.getUser();

  if (user.hasProfile()) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});
