import Backbone from "backbone";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";
import CurationCollection from "scripts/collections/curationCollection";
import BaseView from "scripts/views/baseView";
import SignInModalView from "scripts/views/signInModalView";
import ChangeOrganizationView from "scripts/views/changeOrganizationView";
import { getCurrentFragment } from "scripts/utils/routerHelpers";
import { getMainMenuLogoOption } from "scripts/utils/whiteLabelUtil";
import templateMainOverlayMenuView from "templates/mainOverlayMenu.hbs";
import i18next from "i18next";

const chan = Backbone.Radio.channel;

class MainOverlayMenuView extends BaseView {
  constructor(
    options,
    bookshelfService = inject("bookshelfService"),
    selfEService = inject("selfEService"),
    securityService = inject("securityService"),
    connectionService = inject("connectionService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);

    this.bookshelfService = bookshelfService;
    this.securityService = securityService;
    this.selfEService = selfEService;
    this.connectionService = connectionService;
    this.i18nextService = i18nextService;

    this.changeOrganizationView = this.addSubView(new ChangeOrganizationView());
    this.changeOrganizationView.on("close", () => this.trigger("close"));

    this.languages = {
      en: "English",
      ar: "عربي (Arabic)",
    };
  }

  get template() {
    return templateMainOverlayMenuView;
  }

  get events() {
    return {
      "click #bb-main-overlay-logout-link": "onClickLogoutLink",
      "click #bb-main-overlay-sign-in-link": "onClickSignInLink",
      "click #bb-main-overlay-sign-up-link": "onClickSignUpLink",
      "click #bb-main-overlay-manage-libraries-link": "onClickManageLibrariesLink",
      "click #bb-main-overlay-change-password-link": "onClickChangePasswordLink",
      "click #bb-main-overlay-create-profile-link": "onClickCreateProfile",
      "click .change-language-btn": "changeLanguage",
    };
  }

  sync() {
    return new CurationCollection([], {
      queryStringParams: {
        limit: 1,
      },
    })
      .fetch({ isOnline: this.connectionService.isOnline() })
      .then(col => {
        this.curationCollection = col;
      });
  }

  render() {
    const user = this.securityService.getUser();
    const activeOrganization = user.getActiveOrganization();
    const useDefaultSocialLink = user.getActiveOrganizationDynamicProperty("useDefaultSocialLink")
      ? user.getActiveOrganizationDynamicProperty("hideSocialLinks") === "1"
      : true;

    // Marketing
    const marketingUrl = user.getMarketingUrl();
    const marketingUrlName = user.getMarketingUrlName();

    // Keep Up
    const twitterUrl =
      user.getActiveOrganizationDynamicProperty("twitterUrl") ||
      (useDefaultSocialLink ? "https://twitter.com/biblioboard" : null);
    const facebookUrl =
      user.getActiveOrganizationDynamicProperty("facebookUrl") ||
      (useDefaultSocialLink ? "https://www.facebook.com/BiblioBoard" : null);
    const instagramUrl =
      user.getActiveOrganizationDynamicProperty("instagramUrl") ||
      (useDefaultSocialLink ? "http://instagram.com/biblioboard" : null);
    const linkedInUrl =
      user.getActiveOrganizationDynamicProperty("linkedInUrl") ||
      (useDefaultSocialLink
        ? "https://www.linkedin.com/company/1641574?trk=tyah&trkInfo=idx%3A1-1-1%2CtarId%3A1424272183652%2Ctas%3Abibliolabs"
        : null);

    const blogUrl = user.getActiveOrganizationDynamicProperty("blogUrl");

    // For institutions
    const showLinksToMetadataPage = user.getActiveOrganizationDynamicProperty("showLinksToMetadataPage") === "1";
    const pledgeUrl = user.getActiveOrganizationDynamicProperty("pledgeUrl");
    const showInstitutionsMenu = showLinksToMetadataPage || pledgeUrl;

    // For Publishers
    const publisherParticipationLink = user.getActiveOrganizationDynamicProperty("publisherParticipationLink");
    const showPublishersMenu = !!publisherParticipationLink;

    // For Authors
    const authorsOpenAccessInfoUrl = user.getActiveOrganizationDynamicProperty("authorsOpenAccessInfoUrl");
    const pressbooksUrl = activeOrganization.pressbooksUrl;
    const partnerSelfEUrl = this.selfEService.getPartnerSelfEUrl();
    const showSelfe = user.getShowSelfe();
    const generalSelfEUrl = this.selfEService.getGeneralSelfEUrl();
    const showAuthorsMenu = authorsOpenAccessInfoUrl || pressbooksUrl || partnerSelfEUrl || showSelfe;

    // About us
    const orgSupportEmail = user.getActiveOrganizationDynamicProperty("supportEmail");
    const externalAboutPageUrl = user.getActiveOrganizationDynamicProperty("aboutPageUrl");
    const showLegalNotices = user.getActiveOrganizationDynamicProperty("hideLegalNotices") !== "1";

    const externalUrls = JSON.parse(user.getActiveOrganizationDynamicProperty("externalUrls"));

    console.log(this.i18nextService.getCurrentLanguage());
    console.log(i18next.resolvedLanguage);

    this.$el.html(
      this.template({
        i18n: this.getTranslations(),
        currentLanguage: this.languages[this.i18nextService.getCurrentLanguage()],
        currentLanguageCode: this.i18nextService.getCurrentLanguage(),
        languages: this.languages,
        userId: user.getUserId(),
        username: user.getUsername(),
        organizationName: activeOrganization.name,
        hasCuratorRole: user.hasCuratorRole(),
        authScope: this.securityService.authScope,
        logo: getMainMenuLogoOption(user.getActiveOrganizationDynamicProperty("mainMenuLogo")),
        hideWatchReadListen: user.getActiveOrganizationDynamicProperty("hideWatchReadListen"),
        hideCurations: !this.curationCollection.length,
        marketingUrl,
        marketingUrlName,
        externalUrls,
        twitterUrl,
        facebookUrl,
        instagramUrl,
        linkedInUrl,
        blogUrl,
        showInstitutionsMenu,
        showLinksToMetadataPage,
        pledgeUrl,
        showPublishersMenu,
        publisherParticipationLink,
        showAuthorsMenu,
        authorsOpenAccessInfoUrl,
        pressbooksUrl,
        partnerSelfEUrl,
        showSelfe,
        generalSelfEUrl,
        orgSupportEmail,
        externalAboutPageUrl,
        showLegalNotices,
      }),
    );

    if (!this.securityService.authScope) {
      this.changeOrganizationView.htmlOf($("#bb-main-overlay-change-org-region")).render();
    }

    return this;
  }

  getTranslations() {
    const glossary = this.i18nextService.getGlossary();
    const attributes = this.i18nextService.getAttributes();
    const common = this.i18nextService.getCommon();

    const { about, aboutUs, follow, home, keepUp, legalNotices, support } = common;
    const button = L.flowRight([L.pick(["createYourProfile", "logOut", "more", "signIn"]), L.get(["button"])])(common);

    const actions = L.flowRight([L.pick(["changePassword", "explore", "listen", "read", "watch"]), L.get(["actions"])])(
      common,
    );

    const { menu } = glossary;
    const curations = L.get(["curation", "plural"], glossary);
    const categories = L.get(["search", "category", "plural"], glossary);
    const modules = L.get(["module", "plural"], glossary);

    const ariaLabel = L.flowRight([L.pick(["changeOrgMenu", "externalLinks"]), L.get(["ariaLabel"])])(attributes);

    return {
      about,
      aboutUs,
      actions,
      categories,
      curations,
      ariaLabel,
      button,
      follow: {
        twitter: follow.social("Twitter"),
        facebook: follow.social("Facebook"),
        instagram: follow.social("Instagram"),
        blog: follow.blog,
      },
      home,
      keepUp,
      legalNotices,
      menu,
      modules,
      support,
    };
  }

  changeLanguage(event) {
    i18next.changeLanguage(event.currentTarget.value);
  }

  onClickLogoutLink() {
    chan("security").request("logout", true);
    this.trigger("close");
  }

  onClickSignInLink() {
    chan("display").request("showModal", new SignInModalView());
    this.trigger("close");
  }

  onClickChangePasswordLink() {
    chan("security").request("showChangePasswordModal");
    this.trigger("close");
  }

  onClickManageLibrariesLink() {
    chan("history").request("replaceScrollTop", getCurrentFragment, 0);

    this.trigger("close");
  }

  onClickSignUpLink() {
    this.trigger("close");
  }

  onClickCreateProfile() {
    this.trigger("close");
  }
}

export default MainOverlayMenuView;
