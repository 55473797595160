import _ from "lodash";

const facetNameToFacetParam = {
  bisacCode: "_cat",
  contentCreatorName: "_c",
  contentDatePublished: "_pd",
  contentLanguage: "_l",
  contentLicenseType: "_lt",
  contentPlaceOfPublication: "_pop",
  contentPublisherName: "_p",
  omniType: "_ot",
  modules: "_mod",
  query: "g",
  title: "t",
  //type: '_type',
};

const facetParamToFacetName = {
  _cat: "bisacCode",
  _c: "contentCreatorName",
  _pd: "contentDatePublished",
  _l: "contentLanguage",
  _lt: "contentLicenseType",
  _pop: "contentPlaceOfPublication",
  _p: "contentPublisherName",
  _ot: "omniType",
  _mod: "modules",
  //_type: 'type',
};

const facetParamToFacetLabel = {
  _cat: "Category",
  _c: "Contributor",
  _pd: "Publication Year",
  _l: "Language",
  _lt: "License Type",
  _pop: "Place of Publication",
  _p: "Publisher",
  _ot: "Content Type",
  _mod: "Module",
  //_type: 'Media Type',
};

// non-facet search terms that can be individually searched
const searchFieldParamToLabel = {
  g: "Search",
  t: "Title Search",
};

// non-facet searchable fields
export const searchFields = ["g", "t"];

export const comboboxFacetParams = ["_cat", "_c", "_pop", "_p", "_mod"];

export const legacyToFacet = {
  cat: "_cat",
  c: "_c",
  pd: "_pd",
  l: "_l",
  lt: "_lt",
  pop: "_pop",
  p: "_p",
  ot: "_ot",
  mod: "_mod",
};

export const facetParamOrder = [
  "_cat",
  "_ot",
  //'_type',
  "_c",
  "_pd",
  "_l",
  "_p",
  "_pop",
  "_mod",
  "_lt",
];

export const publicationYearGroups = [
  {
    label: "Before 1000",
    end: 1000,
    expression: "|0999-12-31T23:59:59Z",
  },
  {
    label: "1000 - 1499",
    start: 1000,
    end: 1500,
    expression: "1000-01-01T00:00:00Z|1499-12-31T23:59:59Z",
  },
  {
    label: "1500s",
    start: 1500,
    end: 1600,
    expression: "1500-01-01T00:00:00Z|1599-12-31T23:59:59Z",
  },
  {
    label: "1600s",
    start: 1600,
    end: 1700,
    expression: "1600-01-01T00:00:00Z|1699-12-31T23:59:59Z",
  },
  {
    label: "1700s",
    start: 1700,
    end: 1800,
    expression: "1700-01-01T00:00:00Z|1799-12-31T23:59:59Z",
  },
  {
    label: "1800s",
    start: 1800,
    end: 1900,
    expression: "1800-01-01T00:00:00Z|1899-12-31T23:59:59Z",
  },
  {
    label: "1900s",
    start: 1900,
    end: 1910,
    expression: "1900-01-01T00:00:00Z|1909-12-31T23:59:59Z",
  },
  {
    label: "1910s",
    start: 1910,
    end: 1920,
    expression: "1910-01-01T00:00:00Z|1919-12-31T23:59:59Z",
  },
  {
    label: "1920s",
    start: 1920,
    end: 1930,
    expression: "1920-01-01T00:00:00Z|1929-12-31T23:59:59Z",
  },
  {
    label: "1930s",
    start: 1930,
    end: 1940,
    expression: "1930-01-01T00:00:00Z|1939-12-31T23:59:59Z",
  },
  {
    label: "1940s",
    start: 1940,
    end: 1950,
    expression: "1940-01-01T00:00:00Z|1949-12-31T23:59:59Z",
  },
  {
    label: "1950s",
    start: 1950,
    end: 1960,
    expression: "1950-01-01T00:00:00Z|1959-12-31T23:59:59Z",
  },
  {
    label: "1960s",
    start: 1960,
    end: 1970,
    expression: "1960-01-01T00:00:00Z|1969-12-31T23:59:59Z",
  },
  {
    label: "1970s",
    start: 1970,
    end: 1980,
    expression: "1970-01-01T00:00:00Z|1979-12-31T23:59:59Z",
  },
  {
    label: "1980s",
    start: 1980,
    end: 1990,
    expression: "1980-01-01T00:00:00Z|1989-12-31T23:59:59Z",
  },
  {
    label: "1990s",
    start: 1990,
    end: 2000,
    expression: "1990-01-01T00:00:00Z|1999-12-31T23:59:59Z",
  },
  {
    label: "Recent",
    start: 2000,
    expression: "2000-01-01T00:00:00Z|",
  },
];

export const searchLabelForSearchField = searchField =>
  searchFieldParamToLabel[searchField];

export const facetParamForFacetName = facetName =>
  facetNameToFacetParam[facetName];

export const facetNameForFacetParam = facetParam =>
  facetParamToFacetName[facetParam];

export const facetLabelForFacetParam = facetParam =>
  facetParamToFacetLabel[facetParam];

export const selectedTerms = (facetValues, selectedTermCodes) =>
  facetValues.map(info => {
    return _.merge({}, info, {
      selected: selectedTermCodes.includes(info.code),
    });
  });

export const facetValuesForFacetParam = (facets, facetParam) => {
  const facetName = facetNameForFacetParam(facetParam);
  const facetInfo = facets[facetName];
  return facetInfo ? facetInfo.facetValues : null;
};

export const termLabelsForTermCodes = (facets, facetParam, termCodes) => {
  const facetValues = facetValuesForFacetParam(facets, facetParam);

  return termCodes.map(termCode => {
    let label = termCode;

    if (facetValues) {
      if (facetParam === "_pd") {
        const yearGroup = publicationYearGroups.find(
          group => group.expression === termCode,
        );

        if (yearGroup) {
          label = yearGroup.label;
        }
      }

      const termInfo = facetValues.find(o => o.code === termCode);

      if (termInfo) {
        label = termInfo.label;
      }
    }

    return label;
  });
};

const parseYearFromTerm = term => parseInt(term.label, 10);

const groupForExpression = expression =>
  publicationYearGroups.find(group => group.expression === expression);

const groupForYear = year =>
  publicationYearGroups.find(
    group =>
      (group.start === void 0 || year >= group.start) &&
      (group.end === void 0 || year < group.end),
  );

export const groupedPublicationYearTerms = (facetValues, chosenTermCodes) => {
  const chosenGroups = chosenTermCodes.map(groupForExpression).filter(o => o);

  const map = new Map();

  facetValues.forEach(facetTerm => {
    const yearGroup = groupForYear(parseYearFromTerm(facetTerm));

    const group = map.get(yearGroup);

    facetTerm.selected =
      chosenTermCodes.includes(facetTerm.code) ||
      chosenGroups.includes(yearGroup);

    if (group) {
      group.push(facetTerm);
      group.sort((a, b) => parseInt(a.label, 10) - parseInt(b.label, 10));
    } else {
      map.set(yearGroup, [facetTerm]);
    }
  });

  const groupedTerms = [];

  Array.from(map.keys()).forEach(yearGroup => {
    const group = {};

    const terms = map.get(yearGroup);

    group.terms = terms;
    group.label = yearGroup.label;
    group.expression = yearGroup.expression;
    group.selected =
      chosenGroups.includes(yearGroup) || terms.every(term => term.selected);
    group.expanded = !group.selected && terms.some(term => term.selected);

    group.count = terms.reduce(
      (acc, term) => acc + parseInt(term.count, 10),
      0,
    );

    groupedTerms.push(group);
  });

  groupedTerms.sort(
    (a, b) =>
      publicationYearGroups.findIndex(
        yearGroup => yearGroup.label === a.label,
      ) -
      publicationYearGroups.findIndex(yearGroup => yearGroup.label === b.label),
  );

  return groupedTerms;
};

const parseDate = date => parseInt(date.substring(0, 4));

export const termLabelForPublicationDates = termCode => {
  const dates = _.split(termCode, "|");

  if (dates.length === 1 || dates[0] === dates[1]) {
    return parseDate(dates[0]);
  } else {
    return `${parseDate(dates[0])}-${parseDate(dates[1])}`;
  }
};
