import inject from "scripts/ioc/inject";

class mediaPageContentSubPageTranslations {
  constructor(i18nextService = inject("i18nextService")) {
    this.i18nextService = i18nextService;
  }

  getTranslations({ modelMediaType, mediaCountModel }) {
    const attributes = this.i18nextService.getAttributes();
    const glossary = this.i18nextService.getGlossary();

    return {
      all: {
        ariaLabel: attributes.ariaLabel.filterList.showMedia(
          glossary.content.all,
          modelMediaType === "curation" ? mediaCountModel.allMediaCount : mediaCountModel.allContentCount,
        ),
        count: glossary.content.allCount(
          modelMediaType === "curation"
            ? this.i18nextService.formatNumber(mediaCountModel.allMediaCount)
            : this.i18nextService.formatNumber(mediaCountModel.allContentCount),
        ),
      },
      anthologies: {
        ariaLabel: attributes.ariaLabel.filterList.showMedia(glossary.anthology.plural, mediaCountModel.anthologyCount),
        count: glossary.anthology.count(this.i18nextService.formatNumber(mediaCountModel.anthologyCount)),
      },
      articles: {
        ariaLabel: attributes.ariaLabel.filterList.showMedia(
          glossary.content.article.plural,
          mediaCountModel.articleCount,
        ),
        count: glossary.content.article.count(this.i18nextService.formatNumber(mediaCountModel.articleCount)),
      },
      audio: {
        ariaLabel: attributes.ariaLabel.filterList.showMedia(
          glossary.content.audio.singular,
          mediaCountModel.audioCount,
        ),
        count: glossary.content.audio.count(this.i18nextService.formatNumber(mediaCountModel.audioCount)),
      },
      audiobook: {
        ariaLabel: attributes.ariaLabel.filterList.showMedia(
          glossary.content.audiobook.plural,
          mediaCountModel.audiobookCount,
        ),
        count: glossary.content.audiobook.count(this.i18nextService.formatNumber(mediaCountModel.audiobookCount)),
      },
      books: {
        ariaLabel: attributes.ariaLabel.filterList.showMedia(glossary.content.book.plural, mediaCountModel.bookCount),
        count: glossary.content.book.count(this.i18nextService.formatNumber(mediaCountModel.bookCount)),
      },
      chapters: {
        ariaLabel: attributes.ariaLabel.filterList.showMedia(
          glossary.content.chapter.plural,
          mediaCountModel.chapterCount,
        ),
        count: glossary.content.chapter.count(this.i18nextService.formatNumber(mediaCountModel.chapterCount)),
      },
      collections: {
        ariaLabel: attributes.ariaLabel.filterList.showMedia(
          glossary.collection.plural,
          mediaCountModel.collectionCount,
        ),
        count: glossary.collection.count(this.i18nextService.formatNumber(mediaCountModel.collectionCount)),
      },
      documents: {
        ariaLabel: attributes.ariaLabel.filterList.showMedia(
          glossary.content.document.plural,
          mediaCountModel.otherDocumentCount,
        ),
        count: glossary.content.document.count(this.i18nextService.formatNumber(mediaCountModel.otherDocumentCount)),
      },
      highlights: {
        ariaLabel: attributes.ariaLabel.filterList.showMedia(
          glossary.content.highlights,
          mediaCountModel.highlightCount,
        ),
        count: glossary.content.highlightsCount(
          modelMediaType === "curation"
            ? this.i18nextService.formatNumber(mediaCountModel.contentHighlightCount)
            : this.i18nextService.formatNumber(mediaCountModel.highlightCount),
        ),
      },
      images: {
        ariaLabel: attributes.ariaLabel.filterList.showMedia(glossary.content.image.plural, mediaCountModel.imageCount),
        count: glossary.content.image.count(this.i18nextService.formatNumber(mediaCountModel.imageCount)),
      },
      modules: {
        ariaLabel: attributes.ariaLabel.filterList.showMedia(glossary.module.plural, mediaCountModel.moduleCount),
        count: glossary.module.count(this.i18nextService.formatNumber(mediaCountModel.moduleCount)),
      },
      textbooks: {
        ariaLabel: attributes.ariaLabel.filterList.showMedia(
          glossary.content.textbook.plural,
          mediaCountModel.textbookCount,
        ),
        count: glossary.content.textbook.count(this.i18nextService.formatNumber(mediaCountModel.textbookCount)),
      },
      videos: {
        ariaLabel: attributes.ariaLabel.filterList.showMedia(glossary.content.video.plural, mediaCountModel.videoCount),
        count: glossary.content.video.count(this.i18nextService.formatNumber(mediaCountModel.videoCount)),
      },
    };
  }
}

export default mediaPageContentSubPageTranslations;
