import BaseView from "scripts/views/baseView";
import CommentDisplayNameDialogView from "scripts/views/commentDisplayNameDialogView";
import CommentLoadingDialogView from "scripts/views/commentLoadingDialogView";
import DisplayNameModel from "scripts/models/displayNameModel";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";

import templateCommentForm from "templates/commentForm.hbs";

class CommentFormView extends BaseView {
  get tagName() {
    return "div";
  }

  get className() {
    return "row";
  }

  get template() {
    return templateCommentForm;
  }

  get events() {
    return {
      "submit .comment-new-form": "checkDisplayName",
    };
  }

  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    options = options || {};

    this.i18nextService = i18nextService;

    this.disqusModel = options.disqusModel;

    this.data = {
      parentId: options.parentId || "",
      textMessage: options.textMessage || "",
      displayName: this.disqusModel.get("disqusDisplayName") || "",
      avatar: this.disqusModel.get("disqusAvatar") || "",
    };
  }

  render() {
    const post = L.get(["actions", "post"], this.i18nextService.getCommon());
    const joinDiscussion = L.get(
      ["placeholder", "comment", "joinDiscussion"],
      this.i18nextService.getAttributes(),
    );

    this.$el.html(
      this.template(L.merge({ i18n: { joinDiscussion, post } }, this.data)),
    );

    return this;
  }

  checkDisplayName(event) {
    // If user doesn't have a display name, prompt for one
    if (!this.disqusModel.has("disqusDisplayName")) {
      event.preventDefault();
      event.stopPropagation();

      const prompt = this.addSubView(
        new CommentDisplayNameDialogView({
          el: $("#comments-display-name-modal"),
        }).once("confirm", displayName => {
          prompt.remove();

          const loading = this.addSubView(
            new CommentLoadingDialogView(),
          ).render();
          // const userId = chan('security').request('user').getUserId();
          const displayNameModel = new DisplayNameModel({
            displayName: displayName,
          });

          displayNameModel
            .save({}, { type: "put" })
            .then(() => {
              this.disqusModel.set("disqusDisplayName", displayName);

              // Submit the comment
              $(event.currentTarget).submit();
            })
            .finally(() => {
              loading.remove();
            });
        }),
      ).render();
    }
  }
}

export default CommentFormView;
