import _ from "lodash";
import Backbone from "backbone";
import BaseService from "scripts/services/baseService";

const chan = Backbone.Radio.channel;

class CurationMultiSelectService extends BaseService {
  constructor() {
    super();

    this.itemsToAdd = [];

    this.active = false;
  }

  toggleActive() {
    this.active = true;
    chan("curationMultiSelectService").trigger("toggleSelect");
  }

  getActive() {
    return this.active;
  }

  getItems() {
    return _.clone(this.itemsToAdd);
  }

  selectItem(media) {
    // put any types to avoid adding to curations
    if (media.get("mediaType") === "curation") {
      return;
    }

    this.itemsToAdd.push(media);
    chan("curationMultiSelectService").trigger("itemAdded", { media });
  }

  isSelected(media) {
    return _.find(this.itemsToAdd, i => i.get("id") === media.get("id"))
      ? true
      : false;
  }

  deselectItem(media) {
    _.remove(this.itemsToAdd, i => i.get("id") === media.get("id"));
    chan("curationMultiSelectService").trigger("itemRemoved", { media });
  }

  toggleSelectItem(media) {
    if (this.isSelected(media)) {
      this.deselectItem(media);
    } else {
      this.selectItem(media);
    }
  }

  cancel() {
    this.itemsToAdd = [];
    this.active = false;
    chan("curationMultiSelectService").trigger("cancelSelect");
  }
}

export default CurationMultiSelectService;
