import errorAlert from "scripts/alerts/errorAlert";
import { alert } from "scripts/alerts/alerts";
import BaseModalView from "scripts/views/baseModalView";
import PatronVerificationFormView from "scripts/views/patronVerificationFormView";
import UnauthorizedSyncError from "scripts/exceptions/unauthorizedSyncError";

import templatePatronVerificationModal from "templates/patronVerificationModal.hbs";

class PatronVerificationModalView extends BaseModalView {
  constructor(options) {
    super();

    const { organizationId, organizationName, pinRequired } = options;

    this.patronVerificationFormView = this.addSubView(
      new PatronVerificationFormView({
        organizationId,
        organizationName,
        pinRequired,
      }),
    );

    this.patronVerificationFormView.on(
      "success",
      organizationUser => {
        const patronId = this.patronVerificationFormView.model.get(
          "cardNumber",
        );
        this.onPatronVerificationSuccess({ organizationUser, patronId });
      },
      this,
    );

    this.patronVerificationFormView.on(
      "failure",
      error => this.onPatronVerificationFailure(error),
      this,
    );
  }

  get events() {
    return {
      "click #bb-patron-verification-modal-cancel-button": "onCancel",
    };
  }

  get template() {
    return templatePatronVerificationModal;
  }

  render() {
    this.$el.html(this.template());

    this.patronVerificationFormView
      .appendTo(
        this.$el.find(
          "#bb-patron-verification-modal-patron-verification-form-region",
        ),
      )
      .render();

    return this;
  }

  onCancel() {
    this.trigger("cancel");
    this.closeModal();
  }

  onPatronVerificationSuccess(organizationUser) {
    this.trigger("success", organizationUser);
    this.closeModal();
  }

  onPatronVerificationFailure(error) {
    console.log("Patron verification failure, error: %O", error);

    if (error instanceof UnauthorizedSyncError) {
      this.closeModal().then(() => {
        alert(
          "We were unable to verify your account.",
          "Please re-enter your credentials, or contact support",
          "error",
        ).show();
      });
    } else {
      errorAlert(error).show();
    }

    this.trigger("failure");
  }
}

export default PatronVerificationModalView;
