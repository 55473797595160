import _ from "lodash";
import HybridMediaCollection from "scripts/collections/hybridMediaCollection";

class RecentCollection extends HybridMediaCollection {
  // merge lastViewed timestamp with media model
  parse(response) {
    return _.map(response, mediaWrapper => {
      mediaWrapper.media.lastViewed = mediaWrapper.lastViewed;
      return mediaWrapper.media;
    });
  }
}

export default RecentCollection;
