import L from "lodash/fp";
import inject from "scripts/ioc/inject";
import BaseView from "scripts/views/baseView";
import FacetedSearchResultCollection from "scripts/collections/facetedSearchResultCollection";
import FacetedSearchPublicationYearModalView from "scripts/views/search/facetedSearchPublicationYearModalView";
import { redirectToFragment } from "scripts/utils/routerHelpers";
import template from "templates/advancedSearchPage.hbs";
import {
  selectedTerms,
  termLabelsForTermCodes,
  // groupedPublicationYearTerms,
  facetParamForFacetName,
  searchFields,
} from "scripts/views/search/facetMapper";

import { chosenAriaLabels } from "scripts/utils/chosenAriaLabels";

const chan = Backbone.Radio.channel;

const getSelectedTermsFromForm = (form, isTitleSearch) => {
  const formData = new FormData(form);
  const termsParams = {};

  Array.from(formData.keys()).forEach(key => {
    const values = formData.getAll(key).filter(o => o);

    if (key === "query") {
      // a bit messy and will have to be expanded to add more
      const searchKey = isTitleSearch ? "title" : "query";

      if (values.length < 1) {
        termsParams[searchKey] = ["*"];
      } else {
        termsParams[searchKey] = values;
      }
    } else if (values.length) {
      termsParams[key] = values;
    }
  });

  return termsParams;
};

const mapInSelections = (facets, selections) => {
  const mapped = {};

  Object.keys(facets).forEach(key => {
    mapped[key] = selectedTerms(facets[key].facetValues, selections[key] || []);
  });

  return mapped;
};

// const getCountForTermCode = (facetValues, groups) => termCode => {
//   const group = L.find(group => group.expression === termCode, groups);
//
//   if (group) {
//     return group.count;
//   } else {
//     const facetValue = L.find(value => value.code === termCode, facetValues);
//
//     if (facetValue) {
//       return facetValue.count;
//     }
//   }
//
//   return 0;
// };

const mapSelectionsToQueryParams = (selections, isFullText) => {
  const params = {};

  Object.keys(selections).forEach(name => {
    const param = facetParamForFacetName(name);

    params[param] = (selections[name] || [])
      .map(term => {
        // facets all start with _
        if (
          param === "_pd" ||
          term === "*" ||
          (searchFields.includes(param) && !isFullText)
        ) {
          return term;
        } else {
          return `"${term}"`;
        }
      })
      .join(" OR ");
  });

  return params;
};

class AdvancedSearchPageView extends BaseView {
  constructor(
    options,
    deviceService = inject("deviceService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);
    this.deviceService = deviceService;
    this.i18nextService = i18nextService;

    this.collection = new FacetedSearchResultCollection([], {
      path: "/search/v2",
      state: {
        currentPage: 0,
      },
    });

    this.selectedTerms = {};
    this.openPublicationYearDialogOnFocus = true;
  }

  get events() {
    return {
      "submit #bb-advanced-search-form": "onSubmit",
      "keydown #bb-advanced-search-query": "onQueryKeyDown",
      // "blur #bb-advanced-search-query": "onFieldChange",
      "click #bb-advanced-search-full-text-btn": "toggleFullTextClick",
      "click #bb-advanced-search-title-btn": "toggleFullTextClick",
    };
  }

  onQueryKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      this.onSubmit(e);
    }
  }

  onSubmit(e) {
    this.selectedTerms = getSelectedTermsFromForm(
      $("#bb-advanced-search-form").get(0),
      // this and ft should end up as query options - add if we add another checkbox
      this.isChecked("bb-advanced-search-title-btn"),
    );

    const queryParams = mapSelectionsToQueryParams(
      this.selectedTerms,
      this.isChecked("bb-advanced-search-full-text-btn"),
    );

    const encodedTermsParams = Object.keys(queryParams)
      .map(param => `${param}=${encodeURIComponent(queryParams[param])}`)
      .join("&")
      .concat(
        `&full-text=${this.isChecked("bb-advanced-search-full-text-btn")}`,
      );

    redirectToFragment(
      `search-results/${encodeURIComponent(encodedTermsParams)}`,
    );

    return false;
  }

  onPublicationYearFocus() {
    const facetValues = this.collection.facets.contentDatePublished.facetValues;

    const model = new Backbone.Model({
      facetParam: "_pd",
      facetLabel: "Publication Year",
      facetValues,
      chosenTermCodes: this.selectedTerms["contentDatePublished"] || [],
    });

    const modal = new FacetedSearchPublicationYearModalView(
      { model },
      { showCounts: false },
    );

    modal.on(
      "apply",
      (facetParam, termCodes) => {
        this.selectedTerms["contentDatePublished"] = termCodes;
        this.renderPublicationYearOptions();
        this.publicationYearChosen.trigger("chosen:updated");
      },
      this,
    );

    modal.on("close", () => {
      $("#bb_advanced_search_publication_year_chosen input").focus();
      setTimeout(() => {
        this.openPublicationYearDialogOnFocus = true;
      }, 1000);
    });

    chan("display").request("showModal", modal);

    return false;
  }

  renderPublicationYearOptions() {
    // const facetValues = this.collection.facets.contentDatePublished.facetValues;
    const chosenTermCodes = this.selectedTerms["contentDatePublished"] || [];

    const labels = termLabelsForTermCodes(
      this.collection.facets,
      "_pd",
      chosenTermCodes,
    );

    // const counts = L.map(
    //   getCountForTermCode(
    //     facetValues,
    //     groupedPublicationYearTerms(facetValues, chosenTermCodes),
    //   ),
    //   chosenTermCodes,
    // );

    const data = [];
    chosenTermCodes.forEach((termCode, i) => {
      data.push({
        expression: termCode,
        label: labels[i],
        // count: counts[i],
      });
    });

    $("#bb-advanced-search-publication-year option").remove();

    data.forEach(({ label, count, expression }) => {
      const option = document.createElement("option");
      // option.text = `${label} (${count})`;
      option.text = label;
      option.value = expression;
      option.setAttribute("selected", true);
      $("#bb-advanced-search-publication-year").append(option);
    });
  }

  render() {
    const $chosenFields = this.$el.find(".chosen-select");
    $chosenFields.chosen("destroy");

    if (this.publicationYearChosen) {
      this.publicationYearChosen.chosen("destroy");
    }

    const query = (this.selectedTerms["query"] || [])[0];

    this.$el.html(
      template({
        i18n: this.getTranslations(),
        facets: mapInSelections(this.collection.facets, this.selectedTerms),
        query: query === "*" ? "" : query,
      }),
    );

    this.$el
      .find(".chosen-select")
      .chosen({
        width: "100%",
        search_contains: true,
      })
      .each(chosenAriaLabels);
    // .on("change", () => this.onFieldChange());

    this.renderPublicationYearOptions();

    this.publicationYearChosen = $("#bb-advanced-search-publication-year")
      .chosen({
        width: "100%",
      })
      .each(chosenAriaLabels)
      .on("chosen:showing_dropdown", () => {
        if (this.openPublicationYearDialogOnFocus) {
          this.openPublicationYearDialogOnFocus = false;
          this.onPublicationYearFocus();
        }
        return false;
      })
      .on("change", () => {
        // this.onFieldChange();
      })
      .on("chosen:updated", () => {
        // this.onFieldChange();
      });

    $("#bb_advanced_search_publication_year_chosen input").on("click", () => {
      if (this.openPublicationYearDialogOnFocus) {
        this.openPublicationYearDialogOnFocus = false;
        this.onPublicationYearFocus();
      }
    });

    return this;
  }

  getTranslations() {
    const attributes = this.i18nextService.getAttributes();
    const glossary = this.i18nextService.getGlossary();

    const { search, module } = L.flowRight([L.pick(["search", "module"])])(
      glossary,
    );
    const ariaLabel = L.get(["ariaLabel", "search"], attributes);
    const placeholder = L.flowRight(
      L.pick(["search"]),
      L.get(["placeholder"]),
    )(attributes);
    return { ariaLabel, placeholder, module, search };
  }

  onFieldChange(e) {
    // console.log("on field change", e);
    //
    // this.selectedTerms = getSelectedTermsFromForm(
    //   $("#bb-advanced-search-form").get(0),
    // );
    //
    // chan("display").request("showBlockingLoader");
    //
    // this.syncCollection().then(() => {
    //   this.render();
    //   chan("display").request("hideBlockingLoader");
    // });
  }

  syncCollection() {
    const queryParams = mapSelectionsToQueryParams(
      this.selectedTerms,
      this.isChecked("bb-advanced-search-full-text-btn"),
    );

    this.collection.queryParams = L.merge(
      {
        platform: () => this.deviceService.platform,
        limit: 20,
        offset: 0,
        g: "*",
      },
      queryParams,
    );

    return this.collection.fetch();
  }

  sync() {
    return this.syncCollection();
  }

  getDocumentTitle() {
    return "Advanced Search";
  }

  initialScrollTop() {
    return 0;
  }

  toggleFullTextClick(e) {
    e.preventDefault();
    const id = e.target.id;

    if (this.isChecked(id)) {
      this.uncheckButton(id);
    } else {
      this.checkButton(id);
    }
  }

  isChecked(id) {
    return this.$el.find("#" + id + ".btn-chktoggle-checked").length > 0;
  }

  checkButton(id) {
    // uncheck the other buttons
    this.$el
      .find(".btn-chktoggle-checked")
      .removeClass("btn-chktoggle-checked")
      .addClass("btn-chktoggle-unchecked");

    this.$el
      .find("#" + id)
      .removeClass("btn-chktoggle-unchecked")
      .addClass("btn-chktoggle-checked");
  }

  uncheckButton(id) {
    this.$el
      .find("#" + id)
      .removeClass("btn-chktoggle-checked")
      .addClass("btn-chktoggle-unchecked");
  }
}

export default AdvancedSearchPageView;
