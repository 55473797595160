var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div id=\"bb-media-details-highlight\" class=\"content-details-highlight-banner\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"highlight") : stack1), depth0))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"blackMediaBadges") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":10},"end":{"line":77,"column":19}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"content-provider-badge content-provider-badge--tile\">\n              <img class=\"content-provider-badge__img--tile\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" alt=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"contentProvider") : stack1), depth0))
    + "\">\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"h4 truncate-2\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"subtitle") || (depth0 != null ? lookupProperty(depth0,"subtitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"subtitle","hash":{},"data":data,"loc":{"start":{"line":87,"column":37},"end":{"line":87,"column":49}}}) : helper)))
    + "</div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"contributor-list\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"contributorList") || (depth0 != null ? lookupProperty(depth0,"contributorList") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"contributorList","hash":{},"data":data,"loc":{"start":{"line":94,"column":40},"end":{"line":94,"column":59}}}) : helper)))
    + "</div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"notEqual")||(depth0 && lookupProperty(depth0,"notEqual"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"languageIso639_2") : depth0),"zxx",{"name":"notEqual","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":4},"end":{"line":103,"column":17}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"tile-language\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"language") || (depth0 != null ? lookupProperty(depth0,"language") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"language","hash":{},"data":data,"loc":{"start":{"line":102,"column":33},"end":{"line":102,"column":45}}}) : helper)))
    + " "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"contentProvider") : stack1), depth0))
    + "</div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <!-- awards -->\n    <div class=\"tile-awards-row\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"awardMediaBadges") : depth0),{"name":"each","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":109,"column":6},"end":{"line":111,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <img class=\"tile-award-img\" src=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" alt=\"award badge\">        \n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--tile-->\n<div class=\"content-tile\">\n\n  <!-- header -->\n  <div class=\"tile-header\">\n    <div class=\"tile-header-content-type\">\n      <i class=\"bbico bbico-document m-r-5\"></i>\n      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"content") : stack1)) != null ? lookupProperty(stack1,"document") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "\n    </div>\n\n\n    <div class=\"tile-header-actions\">\n\n      <!--        <a href=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"downloadUrl") || (depth0 != null ? lookupProperty(depth0,"downloadUrl") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"downloadUrl","hash":{},"data":data,"loc":{"start":{"line":14,"column":27},"end":{"line":14,"column":42}}}) : helper)))
    + "\" download target=\"_blank\" aria-label=\"Download\"-->\n      <!--           class=\"btn btn-download circle-btn-mini bg-white hide\" data-toggle=\"tooltip\" title=\"Download\">-->\n      <!--            <i class=\"bbico bbico-download clr-written\"></i>-->\n      <!--        </a>-->\n\n      <!--offline bookshelf button-->\n      <div>\n        <button aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToBookshelf") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                class=\"btn-bookshelf circle-btn-mini hide\"\n                data-toggle=\"tooltip\"\n                title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToBookshelf") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n          <i class=\"bbico bbico-offline-bookshelf\"></i>\n          <i class=\"bbico bbico-offline-bookshelf-filled\"></i>\n        </button>\n      </div>\n\n      <div>\n        <button aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToFavorites") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                class=\"btn-favorite circle-btn-mini\"\n                data-toggle=\"tooltip\"\n                title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToFavorites") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n          <i class=\"bbico bbico-favorite\"></i>\n          <i class=\"bbico bbico-favorite-filled\"></i>\n        </button>\n      </div>\n\n      <div>\n        <button aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToCuration") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                class=\"btn-curation-add circle-btn-mini hide\"\n                data-toggle=\"tooltip\"\n                title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToCuration") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n          <i class=\"bbico bbico-add\"></i>\n        </button>\n      </div>\n    </div>\n\n  </div>\n\n  <div class=\"tile-main-body\">\n    <a href=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"detailUrl") || (depth0 != null ? lookupProperty(depth0,"detailUrl") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"detailUrl","hash":{},"data":data,"loc":{"start":{"line":53,"column":13},"end":{"line":53,"column":26}}}) : helper)))
    + "\" class=\"btn-detail tile-action\">\n\n      <!--image-->\n      <div class=\"media-image-container\">\n        <!--file size indicator-->\n        <div class=\"bb-file-size-region\"></div>\n        <!--download indicator-->\n        <div class=\"bb-download-progress-bar-region\"></div>\n        <!--media image-->\n        <img class=\"bb-media-salvattore-tile-image\" src=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"thumbnailUrl") || (depth0 != null ? lookupProperty(depth0,"thumbnailUrl") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"thumbnailUrl","hash":{},"data":data,"loc":{"start":{"line":62,"column":57},"end":{"line":62,"column":73}}}) : helper)))
    + "\" data-fallback-src=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"thumbnailFallbackUrl") || (depth0 != null ? lookupProperty(depth0,"thumbnailFallbackUrl") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"thumbnailFallbackUrl","hash":{},"data":data,"loc":{"start":{"line":62,"column":94},"end":{"line":62,"column":118}}}) : helper)))
    + "\"\n             alt=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"thumbnailAlt") : stack1), depth0))
    + "\">\n      </div>\n\n      <div class=\"tile-banners\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"collectionHighlight") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":8},"end":{"line":69,"column":15}}})) != null ? stack1 : "")
    + "\n        <!--content provider badges-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"showBadges") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":8},"end":{"line":78,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n\n\n      <div class=\"title\">\n        <!--title-->\n        <div class=\"h3 truncate-2\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":84,"column":35},"end":{"line":84,"column":44}}}) : helper)))
    + "</div>\n        <!--subtitle-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"subtitle") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":8},"end":{"line":88,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n\n      <!--metadata-->\n      <div class=\"tile-metadata\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"contributorList") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":8},"end":{"line":95,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n    </a>\n  </div>\n\n"
    + ((stack1 = (lookupProperty(helpers,"notEqual")||(depth0 && lookupProperty(depth0,"notEqual"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"languageIso639_2") : depth0),"eng",{"name":"notEqual","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":2},"end":{"line":104,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"showAwards") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":2},"end":{"line":113,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});