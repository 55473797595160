import Promise from "bluebird";
import BaseView from "scripts/views/baseView";
import CurationMediaThumbnailGridView from "scripts/views/curationMediaThumbnailGridView";
import BreadcrumbModel from "scripts/models/breadcrumbModel";
import {
  getCurrentFragment,
  redirectToFragment,
} from "scripts/utils/routerHelpers";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";
import templateChooseCurationThumbnailPage from "templates/chooseCurationThumbnailPage.hbs";
import LoaderView from "./loaderView";

class ChooseCurationThumbnailPageView extends BaseView {
  get template() {
    return templateChooseCurationThumbnailPage;
  }

  get events() {
    return {
      "click #bb-choose-curation-thumbnail-accept": "onClickAccept",
    };
  }

  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.subPageView = this.addSubView(
      new CurationMediaThumbnailGridView({
        model: this.model,
        collection: this.collection,
      }),
    );

    this.loaderView = this.addSubView(new LoaderView());

    this.subPageView.on("thumbnailSelected", this.onThumbnailSelected, this);
    this.subPageView.on("thumbnailUploaded", this.onThumbnailUploaded, this);
    this.subPageView.on(
      "thumbnailUploadCanceled",
      this.onThumbnailUploadCanceled,
      this,
    );
  }

  render() {
    const model = this.model.toJSON();

    const i18n = this.getTranslations(model);

    this.$el.html(this.template(L.merge(i18n, model)));

    this.subPageView
      .attachTo($("#bb-choose-curation-thumbnail-media-thumbnail-grid-region"))
      .render();

    return this;
  }

  sync() {
    return Promise.all([this.model.fetch(), this.collection.fetch()]);
  }

  getTranslations(model) {
    const thumbnailForName = L.get(
      ["alt", "thumbnailForName"],
      this.i18nextService.getAttributes(),
    );
    const { accept, cancel } = L.get(
      ["button"],
      this.i18nextService.getCommon(),
    );
    const chooseThumbnail = L.get(
      ["curation", "chooseThumbnail"],
      this.i18nextService.getGlossary(),
    );

    return {
      i18n: {
        accept,
        cancel,
        chooseThumbnail,
        thumbnailAlt: thumbnailForName(model.name),
      },
    };
  }

  onThumbnailUploaded(thumbnailUploadModel) {
    if (thumbnailUploadModel) {
      this.thumbnailUploadModel = thumbnailUploadModel;
      this.enableAcceptLink();
    }
  }

  onThumbnailUploadCanceled() {
    this.thumbnailUploadModel = null;
    if (!this.selectedCurationMediaModel) {
      this.disableAcceptLink();
    }
  }

  onThumbnailSelected(curationMediaModel) {
    if (
      this.model.get("thumbnailUrl") !==
      this.getCurationMediaThumbnailUrl(curationMediaModel)
    ) {
      this.enableAcceptLink();
    } else {
      this.disableAcceptLink();
    }

    this.selectedCurationMediaModel = curationMediaModel;
  }

  getBreadcrumbInfo() {
    return {
      breadcrumbModel: new BreadcrumbModel({
        text: "Curation Thumbnail",
        path: "#" + getCurrentFragment(),
        topLevel: false,
        id: this.model.get("id"),
      }),
    };
  }

  saveCurationThumbnail(curationMediaModel) {
    return this.model.save(
      {
        thumbnailUrl: this.getCurationMediaThumbnailUrl(curationMediaModel),
      },
      {
        patch: true,
        parse: false,
      },
    );
  }

  showLoader() {
    this.loaderView.htmlOf($("#bb-main-nav-logo-region")).render();
    this.loaderView.startAnimation();
  }

  uploadCurationThumbnail(thumbnailUploadModel) {
    this.showLoader();
    return thumbnailUploadModel.save().then(model => {
      model.set("file", null);
    });
  }

  onClickAccept() {
    if (!this.isAcceptLinkEnabled()) {
      return false;
    }

    const curationMediaId = this.model.get("id");

    if (this.thumbnailUploadModel) {
      this.uploadCurationThumbnail(this.thumbnailUploadModel).then(() => {
        redirectToFragment(`/manage-curation/${curationMediaId}`);
      });
    } else {
      this.saveCurationThumbnail(this.selectedCurationMediaModel).then(() => {
        redirectToFragment(`/manage-curation/${curationMediaId}`);
      });
    }

    return false;
  }

  getCurationMediaThumbnailUrl(curationMediaModel) {
    const mediaType = curationMediaModel.get("mediaType");

    if (
      mediaType === "anthology" ||
      mediaType === "collection" ||
      mediaType === "module"
    ) {
      return curationMediaModel.get("iconUrl");
    } else if (mediaType === "content") {
      return curationMediaModel.get("thumbnailUrl");
    } else {
      throw new Error(
        `Cannot determine thumbnail for curation media tile, media type: ${mediaType}`,
      );
    }
  }

  disableAcceptLink() {
    $("#bb-choose-curation-thumbnail-accept").addClass("disabled");
  }

  enableAcceptLink() {
    $("#bb-choose-curation-thumbnail-accept").removeClass("disabled");
  }

  isAcceptLinkEnabled() {
    return !$("#bb-choose-curation-thumbnail-accept").hasClass("disabled");
  }
}

export default ChooseCurationThumbnailPageView;
