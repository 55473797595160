import _ from "lodash";
import { isRelativeUrl } from "scripts/utils/urlUtil";

const chan = Backbone.Radio.channel;

const ApiSyncHoc = Model => {
  return class extends Model {
    get apiUrlRoot() {
      return process.env.PATRON_UI_API_URL_ROOT;
    }

    syncOptions(options) {
      let token = chan("security").request("user").getToken();
      let crossDomain = !isRelativeUrl(this.apiUrlRoot);

      //return _.merge({}, {
      //    headers: {
      //        'X-Biblio-Audience': '/* @echo biblioAudience */',
      //        'X-Auth-Token': token,
      //    },
      //    crossDomain: crossDomain,
      //}, options);

      // Backbone.PageableCollection needs options to be mutated, otherwise the xhr won't make it through to parse
      options = _.defaults(options || {}, {
        headers: {
          "X-Biblio-Audience": process.env.PATRON_UI_BIBLIO_AUDIENCE,
          "X-Auth-Token": token,
        },
        crossDomain: crossDomain,
      });

      return options;
    }

    sync(method, model, options) {
      chan("security").trigger("fetch");
      return super.sync(method, model, this.syncOptions(options));
    }
  };
};

export default ApiSyncHoc;
