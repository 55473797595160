import L from "lodash/fp";
import Promise from "bluebird";
import Backbone from "backbone";
import Velocity from "velocity-animate";
import inject from "scripts/ioc/inject";
import BaseView from "scripts/views/baseView";
import { redirectToFragment } from "scripts/utils/routerHelpers";

import templateAuthenticationScreen from "templates/authenticationScreen.hbs";

const chan = Backbone.Radio.channel;

class AuthenticationScreenView extends BaseView {
  get template() {
    return templateAuthenticationScreen;
  }

  get events() {
    return {
      "click #bb-authentication-screen-mobile-sign-up-link": "onClickSignUp",
      "click #bb-authentication-screen-mobile-sign-in-link": () =>
        this.scrollToBody(),
    };
  }

  constructor(
    options,
    securityService = inject("securityService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);

    this.securityService = securityService;
    this.i18nextService = i18nextService;

    this.bodyView = this.addSubView(options.bodyView);

    chan("controllerView").reply(
      "authenticationScreenView",
      this.replyAuthenticationScreenView,
      this,
    );
    chan("display").on("window:resize", this.adjustHeight, this);
  }

  replyAuthenticationScreenView() {
    return this;
  }

  sync() {
    return this.bodyView.fetch();
  }

  render() {
    const { app, organization } = this.i18nextService.getGlossary();
    const { button } = this.i18nextService.getCommon();
    const orgName = this.securityService.authScopeInfo.organizationName;

    const templateProperties = {
      i18n: {
        createProfile: button.createProfile,
        inactive: orgName === undefined ? "" : organization.inactive(orgName),
        notFound: organization.notFound,
        notYourLibrary: organization.notYourLibrary,
        pleaseVisit: app.pleaseVisit,
        poweredBy: app.poweredBy,
        signIn: button.signIn,
        sorry: app.sorry,
        switch: organization.switch,
      },
    };

    if (!L.isNil(this.securityService.authScope)) {
      templateProperties.authScope = true;

      templateProperties.authScopeOrganizationName =
        this.securityService.authScopeInfo.organizationName;

      templateProperties.authScopeOrganizationBrandingLogoUrl =
        this.securityService.authScopeInfo.brandingLogoUrl;

      const { active } = this.securityService.authScopeInfo;

      if (
        (!L.isNil(active) && !active) ||
        L.isEmpty(this.securityService.authScopeInfo)
      ) {
        templateProperties.authScopeOrganizationInvalid = true;
      }
    }

    console.log(templateProperties);

    this.$el.html(this.template(templateProperties));

    if (!templateProperties.authScopeOrganizationInvalid) {
      this.bodyView
        .attachTo(this.$el.find("#bb-authentication-screen-body-region"))
        .render();
    }

    this.adjustHeight();
  }

  adjustHeight() {
    console.log("adjusting height");

    // fix for 100% height issue
    const authContentHeight = $(
      "#bb-authentication-screen-body-region",
    ).innerHeight();

    if (authContentHeight > window.innerHeight) {
      $(".bb-authentication-screen").css("height", authContentHeight);
    }
  }

  showBodyView(bodyView) {
    if (this.bodyViewPromise && this.bodyViewPromise.isPending()) {
      this.bodyViewPromise.cancel();
    }

    chan("display").request("showBlockingLoader");

    this.bodyViewPromise = this.showSubView(
      "bodyView",
      bodyView,
      this.$el.find("#bb-authentication-screen-body-region"),
    )
      .catch(Promise.CancellationError, () => {
        // swallow cancellation
        console.debug("authentication view show body view cancelled");
      })
      .finally(value => {
        chan("display").request("hideBlockingLoader");

        this.adjustHeight();
        this.scrollToBody(0);

        return value;
      });

    return this.bodyViewPromise;
  }

  onClickSignUp() {
    redirectToFragment("/sign-up/create-profile");
  }

  scrollToBody(duration = 800) {
    return Velocity.animate(
      this.$el.find("#bb-authentication-screen-body-region"),
      "scroll",
      {
        duration: duration,
        easing: "easeInOut",
      },
    );
  }

  initialScrollTop() {
    return this.bodyView.initialScrollTop();
  }
}

export default AuthenticationScreenView;
