import BaseModalView from "scripts/views/baseModalView";
import template from "templates/chaptersDownloadModal.hbs";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";

import { getDownloadUrl } from "scripts/utils/mediaHelpers";

class ChaptersDownloadModalView extends BaseModalView {
  get template() {
    return template;
  }

  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.compoundChildrenCollection = options.compoundChildrenCollection;
  }

  render() {
    const chapterData = this.compoundChildrenCollection.map(mediaModel => {
      return {
        title: mediaModel.get("title"),
        downloadUrl: getDownloadUrl(mediaModel),
      };
    });

    const { close, download } = L.get(
      ["ariaLabel"],
      this.i18nextService.getAttributes(),
    );

    const downloadChapter = L.get(
      ["actions", "download", "chapter"],
      this.i18nextService.getCommon(),
    );

    this.$el.html(
      this.template({
        i18n: { close, download, downloadChapter },
        chapterData,
      }),
    );

    return this;
  }
}

export default ChaptersDownloadModalView;
