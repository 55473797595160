import Backbone from "backbone";
import Promise from "bluebird";
import inject from "scripts/ioc/inject";
import AudioModal from "scripts/views/audioModalView";
import BaseView from "scripts/views/baseView";
import EmbeddedVideoShadowBoxView from "scripts/views/embeddedVideoShadowBoxView";
import FooterView from "scripts/views/footerView";
import HostedVideoShadowBoxView from "scripts/views/hostedVideoShadowBoxView";
import MastheadView from "scripts/views/mastheadView";
import ScrollToTopView from "scripts/views/scrollToTopView";
import CookieSettingsModalView from "scripts/views/cookieSettingsModalView";
import { alert } from "scripts/alerts/alerts";
import { redirectToFragment } from "scripts/utils/routerHelpers";

import templateMain from "templates/main.hbs";
import saIconOffline from "images/sa-icon-offline.png";

const chan = Backbone.Radio.channel;

class MainView extends BaseView {
  constructor(
    options,
    sessionStorageService = inject("sessionStorageService"),
    breadcrumbService = inject("breadcrumbService"),
    googleAnalyticsService = inject("googleAnalyticsService"),
    securityService = inject("securityService"),
  ) {
    super(options);

    this.sessionStorageService = sessionStorageService;
    this.breadcrumbService = breadcrumbService;
    this.googleAnalyticsService = googleAnalyticsService;
    this.securityService = securityService;

    this.embeddedVideoShadowboxView = this.addSubView(new EmbeddedVideoShadowBoxView());

    this.hostedVideoShadowboxView = this.addSubView(new HostedVideoShadowBoxView());

    this.mastheadView = this.addSubView(new MastheadView());

    this.bodyView = this.addSubView(options.bodyView);

    if (!this.isFixedMainMenuLayout()) {
      this.footerView = this.addSubView(new FooterView());
    }

    this.scrollToTopView = this.addSubView(new ScrollToTopView());

    chan("display").reply("playAlbum", this.complyPlayAlbum, this);
    chan("display").reply("playAudio", this.complyPlayAudio, this);
    chan("display").reply("playVideo", this.complyPlayVideo, this);
    chan("display").reply("showCookieSettingsModal", this.complyShowCookieSettingsModal, this);

    chan("controllerView").reply("mainView", this.replyMainView, this);

    chan("connectionService").on(
      "status:change",
      connectionStatus => {
        if (!connectionStatus.isOnline) {
          this.showOfflineConfirmationModal();
        }
      },
      this,
    );
  }

  get template() {
    return templateMain;
  }

  get className() {
    return "bb-main";
  }

  replyMainView() {
    return this;
  }

  sync() {
    return Promise.all([this.bodyView.fetch(), this.mastheadView.fetch()]);
  }

  render() {
    this.$el.html(this.template());

    this.mastheadView.attachTo(this.$el.find("#bb-main-masthead-region")).render();

    this.updateBreadcrumbs(this.bodyView);
    this.updateDocumentTitleAndDescription(this.bodyView);

    this.bodyView.attachTo(this.$el.find("#main-content-region")).render();

    this.scrollToTopView.attachTo(this.$el.find("#bb-main-scroll-to-top-region")).render();

    if (!this.isFixedMainMenuLayout()) {
      this.footerView.attachTo(this.$el.find("#footer-region")).render();

      //show settings modal if it's never been set
      if (
        !this.googleAnalyticsService.isAnalyticsOptInSet() &&
        this.securityService.getAuthScopeFromSubdomain() === "openresearchlibrary"
      ) {
        this.complyShowCookieSettingsModal();
      }
    }

    return this;
  }

  updateBreadcrumbs(bodyView) {
    const breadcrumbInfo = bodyView.getBreadcrumbInfo ? bodyView.getBreadcrumbInfo() : null;

    if (breadcrumbInfo) {
      this.breadcrumbService.addBreadcrumb(bodyView.getBreadcrumbInfo());

      if (breadcrumbInfo.hide) {
        this.mastheadView.hideBreadcrumbView();
      } else {
        this.mastheadView.showBreadcrumbView();
      }
    } else {
      this.mastheadView.hideBreadcrumbView();
    }
  }

  updateDocumentTitleAndDescription(bodyView) {
    const user = this.securityService.getUser();
    const documentTitle = user.getActiveOrganizationName();
    const baseDocumentTitle = documentTitle ? documentTitle : "BiblioBoard";
    const title = bodyView.getDocumentTitle
      ? baseDocumentTitle + " - " + bodyView.getDocumentTitle()
      : baseDocumentTitle;

    document.title = title;

    $("meta[name=description]").remove();
    $("head").append(`<meta name="description" content="${title}">`);
  }

  showBodyView(bodyView) {
    return this.showBodyViewPromise(Promise.resolve(bodyView));
  }

  showBodyViewPromise(viewPromise) {
    if (this.bodyViewPromise && this.bodyViewPromise.isPending()) {
      this.bodyViewPromise.cancel();
    }

    chan("display").trigger("bodyViewLoading");

    this.bodyViewPromise = viewPromise.then(bodyView =>
      this.showSubView("bodyView", bodyView, this.$el.find("#main-content-region"))
        .then(() => {
          this.updateBreadcrumbs(bodyView);
          this.updateDocumentTitleAndDescription(bodyView);
        })
        .catch(Promise.CancellationError, () => {
          // swallow cancellation
          console.debug("main view show body view cancelled");
        })
        .finally(value => {
          this.closeShadowBoxes();

          $(window).scrollTop(bodyView.initialScrollTop() || 0);

          chan("display").trigger("bodyViewLoaded");

          return value;
        }),
    );

    return this.bodyViewPromise;
  }

  closeShadowBoxes() {
    this.hostedVideoShadowboxView.removeShadowBox();
    this.embeddedVideoShadowboxView.removeShadowBox();
  }

  complyPlayAlbum(contentModel, indexToPlay, compoundChildrenCollection) {
    chan("display").request("showModal", new AudioModal({ contentModel, indexToPlay, compoundChildrenCollection }));

    this.googleAnalyticsService.sendPageView(`audio-player/${contentModel.get("id")}`);
  }

  complyPlayAudio(contentModel) {
    chan("display").request("showModal", new AudioModal({ contentModel }));

    this.googleAnalyticsService.sendPageView(`audio-player/${contentModel.get("id")}`);
  }

  complyPlayVideo(contentModel) {
    let videoShadowboxView =
      contentModel.get("videoProvider") === "INTERNAL_AWS_CREATOR"
        ? this.hostedVideoShadowboxView
        : this.embeddedVideoShadowboxView;

    videoShadowboxView.model.set(contentModel.toJSON());
    videoShadowboxView.render();

    this.googleAnalyticsService.sendPageView(`video-player/${contentModel.get("id")}`);
  }

  complyShowCookieSettingsModal() {
    chan("display").request("showModal", new CookieSettingsModalView());
  }

  initialScrollTop() {
    return this.bodyView.initialScrollTop();
  }

  showOfflineConfirmationModal() {
    console.log("Showing offline modal");

    // TODO: Can we figure out a better way to handle this?`
    const bookshelfPage = chan("controllerView").request("bookshelfSubPageView");

    return alert({
      title: "It appears you've gone offline.",
      text: "Most functionality is unavailable while offline, but your Offline Bookshelf is not affected by connectivity issues. Content you've stored there can be read without an internet connection.",
      customClass: "stacked-buttons",
      confirmButtonText: bookshelfPage ? "OK" : "GO TO OFFLINE BOOKSHELF",
      allowEscapeKey: false,
      allowOutsideClick: false,
      imageUrl: saIconOffline,
    })
      .show()
      .then(() => {
        redirectToFragment("/my-board/bookshelf");
      });
  }
}

export default MainView;
