import Backbone from "backbone";

class ContentDetailsNotesPageModel extends Backbone.Model {
  get defaults() {
    return {
      sortBy: "ADDED_DESC",
      filter: "ALL",
    };
  }
}

export default ContentDetailsNotesPageModel;
