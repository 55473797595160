import BaseView from "scripts/views/baseView";
import templateProgressBar from "templates/progressBar.hbs";

class ProgressBarView extends BaseView {
  get template() {
    return templateProgressBar;
  }

  get events() {
    return {
      "click .circle-btn-close": "onClickCancel",
    };
  }

  initialize() {
    this.model.on("change:percent", this.onChangePercent, this);
    this.model.on("change:isVisible", this.handleIsVisible, this);
  }

  render() {
    this.handleIsVisible();

    this.$el.html(this.template(this.model.toJSON()));

    return this;
  }

  showProgressBar() {
    this.$el.removeClass("hide");
  }

  hideProgressBar() {
    this.$el.addClass("hide");
    this.model.set("percent", 0);
  }

  handleIsVisible() {
    const isVisible = this.model.get("isVisible");

    if (isVisible) {
      this.showProgressBar();
    } else {
      this.hideProgressBar();
    }
  }

  onChangePercent() {
    const percent = this.model.get("percent");

    this.$el.find(".progress-bar").width(percent + "%");
  }

  onClickCancel() {
    this.trigger("progressBarCancel", this);
  }
}

export default ProgressBarView;
