import _ from "lodash";
import BaseTileView from "scripts/views/baseTileView";
import ProgressBarView from "scripts/views/progressBarView";
import ProgressBarModel from "scripts/models/progressBarModel";
import i18next from "i18next";

import templateContributor from "templates/contributorLink.hbs";

class BaseListTileView extends BaseTileView {
  get className() {
    return "bb-list-tile";
  }

  initializeBookshelfDeps() {
    super.initializeBookshelfDeps();

    this.progressBarModel = new ProgressBarModel();

    this.progressBarView = this.addSubView(
      new ProgressBarView({
        className: "bb-download-indicator-container list-version",
        model: this.progressBarModel,
      }),
    );

    this.progressBarView.on("progressBarCancel", this.onCancelBookshelfDownload.bind(this), this);
  }

  initializeContributors() {
    const $contributors = this.$el.find(".bb-list-tile-contributor-region");
    $contributors.empty();

    const contributors = this.model.get("contributors");
    if (contributors) {
      contributors.forEach(contributor => {
        contributor.searchContributorQueryParam = this.connectionService.isOnline()
          ? encodeURIComponent(`"${contributor.fullName}"`)
          : undefined;
        $contributors.append(templateContributor(contributor));
      });
    }
  }

  updateTitleLinkUrl() {
    this.$el.find(".bb-list-tile-title-link").attr("href", this.getDetailUrl());
  }

  onConnectionStatusChange() {
    super.onConnectionStatusChange();

    this.initializeContributors();
    this.updateTitleLinkUrl();
  }

  render() {
    const user = this.securityService.getUser();
    const modelData = this.model.toJSON();

    const templateData = _.merge(modelData, {
      viewerUrl: this.getViewerUrl(),
      detailUrl: this.getDetailUrl(),
      showBadges: user.getShowBadges(),
      downloadUrl: this.getDownloadUrl(),
      isAudiobook: modelData.type === "AUDIOBOOK",
      isInAnthology: _.has(modelData, "anthology"),
      i18n: this.getTranslations(modelData),
    });

    this.$el.html(this.template(templateData));

    this.initFavoriteButton();
    this.initCurationAddButton();
    this.initTooltips();
    this.initializeContributors();

    this.initializeDownloadButton();

    this.initializeCurationMultiSelect();

    return this;
  }

  getTranslations(modelData) {
    const anthology = modelData.anthology === undefined ? "" : modelData.anthology;

    return {
      anthology: {
        foundIn: {
          ariaLabel: i18next.t("ariaLabel.anthology.foundIn", "found in {{anthologyName}} anthology", {
            anthologyName: anthology.name,
            ns: "attributes",
          }),
        },
        singular: i18next.t("anthology.singular", "Anthology", {
          ns: "glossary",
        }),
      },
      anthologyIncluded: i18next.t("anthology.included", "Included in the Anthology", { ns: "glossary" }),
      category: {
        singular: i18next.t("category.singular", "Category", {
          ns: "glossary",
        }),
      },
      collection: {
        singular: i18next.t("collection.singular", "Collection", {
          ns: "glossary",
        }),
      },
      content: {
        article: {
          singular: i18next.t("content.article.singular", "Article", {
            ns: "glossary",
          }),
        },
        audio: {
          singular: i18next.t("content.audio.singular", "Audio", {
            ns: "glossary",
          }),
        },
        audiobook: {
          singular: i18next.t("content.audiobook.singular", "Audiobook", {
            ns: "glossary",
          }),
        },
        book: {
          singular: i18next.t("content.book.singular", "Book", {
            ns: "glossary",
          }),
        },
        chapter: {
          singular: i18next.t("content.chapter.singular", "Chapter", {
            ns: "glossary",
          }),
        },
        document: {
          singular: i18next.t("content.document.singular", "Document", {
            ns: "glossary",
          }),
        },
        highlight: i18next.t("content.highlight", "Highlight", {
          ns: "glossary",
        }),
        image: {
          singular: i18next.t("content.image.singular", "Image", {
            ns: "glossary",
          }),
        },
        languageContent: i18next.t("content.languageContent", "Language Content", { ns: "glossary" }),
        video: {
          singular: i18next.t("content.video.singular", "Video", {
            ns: "glossary",
          }),
        },
        viewBookDetails: {
          ariaLabel: i18next.t("ariaLabel.viewBookDetails", "view book details of {{name}}", {
            name: modelData.name,
            ns: "attributes",
          }),
        },
      },
      contentProvider: {
        alt: i18next.t("alt.contentProvider", "Content provider", {
          ns: "attributes",
        }),
      },
      curation: {
        addTo: {
          ariaLabel: i18next.t("ariaLabel.addToCuration", "Add to curation", {
            ns: "attributes",
          }),
          title: i18next.t("title.addNameToCuration", "Add {{name}} to Curation", {
            name: modelData.name,
            ns: "attributes",
          }),
        },
        singular: i18next.t("curation.singular", "Curation", {
          ns: "glossary",
        }),
      },
      download: {
        ariaLabel: i18next.t("ariaLabel.download", "Download", {
          ns: "attributes",
        }),
        title: i18next.t("title.downloadName", "Download {{name}}", {
          name: modelData.ariaLabel,
          ns: "attributes",
        }),
      },
      explore: {
        anthology: {
          ariaLabel: i18next.t("ariaLabel.explore.anthology", "explore {{name}} anthology - {{subName}}", {
            name: modelData.name,
            subName: modelData.subName === undefined ? "" : modelData.subName,
            ns: "attributes",
          }),
        },
        ariaLabel: i18next.t("ariaLabel.explore.singular", "Explore", {
          ns: "attributes",
        }),
        category: {
          ariaLabel: i18next.t("ariaLabel.explore.category", "explore category {{name}}", {
            name: modelData.name,
            ns: "attributes",
          }),
        },
        collection: {
          ariaLabel: i18next.t("ariaLabel.explore.collection", "explore {{collectionName}} collection", {
            collectionName: modelData.name,
            ns: "attributes",
          }),
        },
        curation: {
          ariaLabel: i18next.t("title.explore.name", "Explore {{name}}", {
            name: modelData.name,
            ns: "attributes",
          }),
        },
        module: {
          ariaLabel: i18next.t("ariaLabel.exploreNameModule", "Explore {{name}} module", {
            name: modelData.name,
            ns: "attributes",
          }),
        },
        singular: i18next.t("actions.explore.singular", "Explore", {
          ns: "common",
        }),
        title: i18next.t("title.explore.name", "Explore {{name}}", {
          name: modelData.name,
          ns: "attributes",
        }),
      },
      favorite: i18next.t("actions.favorite", "Favorite", { ns: "common" }),
      favoriteName: i18next.t("title.favoriteName", "Favorite {{name}}", {
        name: modelData.name,
        ns: "attributes",
      }),
      listen: {
        singular: i18next.t("actions.listen", "Listen", { ns: "common" }),
        title: i18next.t("title.listenTo", "Listen to {{name}}", {
          name: modelData.name,
          ns: "attributes",
        }),
      },
      module: {
        singular: i18next.t("module.singular", "Module", { ns: "glossary" }),
      },
      read: i18next.t("actions.read", "Read", { ns: "common" }),
      readBook: {
        ariaLabel: i18next.t("ariaLabel.readBook", "read book", {
          ns: "attributes",
        }),
      },
      readName: {
        title: i18next.t("title.readName", "Read {{name}}", {
          name: modelData.name,
          ns: "attributes",
        }),
      },
      thumbnail: {
        alt: i18next.t("alt.thumbnailForName", "Thumbnail for {{name}}", {
          name: modelData.name,
          ns: "attributes",
        }),
      },
      toggleBookshelf: {
        ariaLabel: i18next.t("ariaLabel.toggleBookshelf", "{{title}} toggle bookshelf", {
          title: modelData.title,
          ns: "attributes",
        }),
        title: i18next.t("title.addTitleToBookshelf", "Add {{title}} to Offline Bookshelf", {
          title: modelData.title,
          ns: "attributes",
        }),
      },
      view: {
        image: {
          ariaLabel: i18next.t("ariaLabel.viewImage", "view image", {
            ns: "attributes",
          }),
        },
        singular: i18next.t("button.view", "View", { ns: "common" }),
      },
      unfavoriteName: i18next.t("title.unfavoriteName", "Unfavorite {{name}}", {
        name: modelData.name,
        ns: "attributes",
      }),
      watch: {
        singular: i18next.t("actions.watch", "Watch", { ns: "common" }),
        title: i18next.t("ariaLabel.watch", "Watch {{title}}", {
          title: modelData.name,
          ns: "attributes",
        }),
      },
    };
  }

  renderBookshelfElements() {
    super.renderBookshelfElements();

    this.progressBarView.htmlOf(this.$el.find(".bb-download-progress-bar-region-list-tile")).render();
  }

  updateProgressBar(percent) {
    this.progressBarModel.set("isVisible", true);

    this.progressBarModel.set("percent", percent);
  }

  hideProgressBar() {
    this.progressBarModel.set("isVisible", false);
  }

  checkFavoriteButton() {
    this.$el
      .find(".btn-favorite")
      .addClass("active")
      .find("i")
      .removeClass("bbico-favorite")
      .addClass("bbico-favorite-filled");
  }

  uncheckFavoriteButton() {
    this.$el
      .find(".btn-favorite")
      .removeClass("active")
      .find("i")
      .removeClass("bbico-favorite-filled")
      .addClass("bbico-favorite");
  }

  checkBookshelfButton() {
    this.$el
      .find(".btn-bookshelf")
      .addClass("active")
      .find("i")
      .removeClass("bbico-offline-bookshelf")
      .addClass("bbico-offline-bookshelf-filled");
  }

  uncheckBookshelfButton() {
    this.$el
      .find(".btn-bookshelf")
      .removeClass("active")
      .find("i")
      .addClass("bbico-offline-bookshelf")
      .removeClass("bbico-offline-bookshelf-filled");
  }

  addContributors(contributors) {
    const $contributors = this.$el.find(".bb-list-tile-contributor-region");

    contributors.forEach(contributor => {
      contributor.searchContributorQueryParam = encodeURIComponent(`"${contributor.fullName}"`);
      $contributors.append(templateContributor(contributor));
    });
  }

  onClickFavoriteButton() {
    this.$el.find('[data-toggle="tooltip"]').tooltip("hide");

    const isFavorite = this.model.get("isFavorite");
    const favoriteButton = this.$el.find(".btn-favorite");

    const newTitleText = isFavorite ? "Favorite" : "Unfavorite";

    favoriteButton.attr("title", newTitleText).attr("data-original-title", newTitleText);

    super.onClickFavoriteButton();

    return false;
  }
}

export default BaseListTileView;
