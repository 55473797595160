import Handlebars from "handlebars-template-loader/runtime";

// Solution based on http://stackoverflow.com/a/18650828
Handlebars.registerHelper("formatBytes", function(bytes, decimals) {
  if (bytes === 0) return "0 Bytes";
  if (bytes === 1) return "1 Byte";

  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const k = 1000;
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return (bytes / Math.pow(k, i)).toFixed(decimals) + " " + sizes[i];
});
