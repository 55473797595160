import BaseService from "scripts/services/baseService";
import FeatureFlagsModel from "scripts/models/featureFlagsModel";

const activeFeatureFlags = [];

class FeatureFlagsService extends BaseService {
  constructor() {
    super();

    this.featureFlagsModel = new FeatureFlagsModel();
  }

  initialize() {
    return this.featureFlagsModel
      .fetch()
      .catch(() => {
        console.log("No feature flags model found");
      })
      .then(() => {
        console.log("Loaded feature flags model");

        Object.keys(this.featureFlagsModel.toJSON()).forEach(key => {
          if (key !== "id" && !activeFeatureFlags.includes(key)) {
            console.log("Cleaning up unused feature flag: " + key);
            this.featureFlagsModel.unset(key);
          }
        });

        return this.featureFlagsModel.save();
      })
      .catch(error => {
        console.error(
          "Error initializing the feature flags service: %O",
          error,
        );
      });
  }

  getAllFlags() {
    return activeFeatureFlags.map(feature => ({
      feature: feature,
      enabled: this.featureFlagsModel.get(feature) || false,
    }));
  }

  enableFeature(feature) {
    return this.featureFlagsModel.enableFeature(feature);
  }

  disableFeature(feature) {
    return this.featureFlagsModel.disableFeature(feature);
  }

  isEnabled(feature) {
    return this.featureFlagsModel.isEnabled(feature);
  }
}

export default FeatureFlagsService;
