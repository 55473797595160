import _ from "lodash";
import inject from "scripts/ioc/inject";
import { getDownloadUrl, getIcon, getItemType } from "scripts/utils/mediaHelpers";
import BaseContentDetailsPageView from "scripts/views/baseContentDetailsPageView";
import templateContentDetailsPage from "templates/contentDetailsPage.hbs";

class ContentDetailsPageView extends BaseContentDetailsPageView {
  constructor(
    options,
    breadcrumbService = inject("breadcrumbService"),
    connectionService = inject("connectionService"),
    securityService = inject("securityService"),
    bookshelfService = inject("bookshelfService"),
  ) {
    super(options, breadcrumbService, connectionService, securityService, bookshelfService);

    this.mediaModel.set("isAudioBook", this.mediaModel.get("type") === "AUDIOBOOK");
    this.mediaModel.set("isAudio", this.mediaModel.get("type") === "AUDIO" && !this.isAlbumTrack());
    this.mediaModel.set("isVideo", this.mediaModel.get("type") === "VIDEO");
    this.mediaModel.set("isImage", this.mediaModel.get("type") === "IMAGE");
    this.mediaModel.set("isAlbum", this.mediaModel.get("type") === "ALBUM");
    this.mediaModel.set("isAlbumTrack", this.isAlbumTrack());

    this.initializeBookshelfDeps();
  }

  get template() {
    return templateContentDetailsPage;
  }

  getTemplateData() {
    const user = this.securityService.getUser();

    const mediaModelData = this.mediaModel.toJSON();
    const parentContentData = mediaModelData.parentContent;

    const translations = super.getTranslations(mediaModelData);
    const type = mediaModelData.type === "OTHER_DOCUMENT" ? "DOCUMENT" : mediaModelData.type;
    const templateData = _.merge(mediaModelData, {
      showBuyLinks: user.getShowBuyLinks(),
      showShareLinks: user.getShowShareLinks(),
      showBadges: user.getShowBadges(),
      viewerUrl: `/viewer/${mediaModelData.id}`,
      parentMediaId: parentContentData ? parentContentData.mediaId : null,
      parentContentTitle: parentContentData ? parentContentData.title : null,
      thumbnailUrl: mediaModelData.parentContent
        ? mediaModelData.parentContent.thumbnailUrl
        : mediaModelData.thumbnailUrl,
      downloadUrl: getDownloadUrl(this.mediaModel),
      i18n: translations,
      itemtype: getItemType(this.mediaModel),
      icon: {
        text: translations.content[_.toLower(type)].singular,
        class: getIcon(this.mediaModel),
      },
    });

    return templateData;
  }

  render() {
    super.render();
    this.renderBookshelfElements();

    return this;
  }

  isAlbumTrack() {
    const parentContent = this.mediaModel.get("parentContent");
    return this.mediaModel.get("type") === "AUDIO" && parentContent && parentContent.type === "ALBUM";
  }
}

export default ContentDetailsPageView;
