import L from "lodash/fp";
import Backbone from "backbone";
import { equals, propEq } from "scripts/utils/generalHelpers";
import PromisedSyncHoc from "scripts/hoc/promisedSyncHoc";
import { isPublicOrganization } from "scripts/utils/userHelpers";

class UserModel extends PromisedSyncHoc(Backbone.Model) {
  constructor(attributes, options) {
    super(attributes, options);

    this.activeOrganizationId = null;

    const storageName = (options && options.storageName) || "modelCookieStorage";
    this.localStorage = new Backbone.LocalStorage(storageName);
  }

  get defaults() {
    return {
      id: "user",
      preferences: {},
    };
  }

  clone() {
    const clone = new UserModel(this.toJSON());
    clone.activeOrganizationId = this.activeOrganizationId;
    return clone;
  }

  isAuthenticated() {
    return this.has("token");
  }

  isOrganizationGuest() {
    return this.isAuthenticated() && !this.hasProfile();
  }

  isOnlyPublicGuest() {
    return this.isAuthenticated() && !this.hasProfile() && L.all(isPublicOrganization, this.getOrganizations());
  }

  isBot() {
    return this.get("bot");
  }

  getUserProfileProp(prop) {
    const user = this.get("user");
    if (!L.isNil(user)) {
      return user[prop];
    } else {
      return void 0;
    }
  }

  getUserId() {
    return this.getUserProfileProp("userId");
  }

  getUsername() {
    return this.getUserProfileProp("username");
  }

  isForcePasswordReset() {
    return this.getUserProfileProp("forcePasswordReset");
  }

  getToken() {
    return this.get("token");
  }

  hasProfile() {
    return this.isAuthenticated() && this.getUserId();
  }

  hasCuratorRole() {
    const userRoles = this.getUserProfileProp("roles");
    const activeOrganization = this.getActiveOrganization();
    const activeOrgRoles = activeOrganization ? activeOrganization.roles : [];
    return L.some(equals("ROLE_CURATOR"), L.concat(userRoles, activeOrgRoles));
  }

  setPreference(preference, value) {
    let prefObj = {};

    if (typeof preference === "string" && value) {
      prefObj[preference] = value;
    } else if (typeof preference === "object") {
      prefObj = preference;
    }

    this.set("preferences", L.merge(this.get("preferences"), prefObj));
  }

  getPreference(key) {
    return this.get("preferences")[key];
  }

  getContentViewPreference() {
    const userContentViewPreference = this.getPreference("contentView");

    if (userContentViewPreference) {
      return userContentViewPreference;
    } else {
      const organizationProperties = this.getActiveOrganizationProperty("organizationProperties");

      if (organizationProperties && organizationProperties.contentView) {
        return organizationProperties.contentView;
      } else {
        return "masonry";
      }
    }
  }

  // clear() {
  //     this.localStorage.destroy(this);
  //     super.clear();
  //     return this.set(_.clone(this.defaults));
  // }

  getActiveOrganizationId() {
    return this.activeOrganizationId;
  }

  setActiveOrganizationId(activeOrganizationId) {
    if (this.hasOrganization(activeOrganizationId)) {
      this.activeOrganizationId = activeOrganizationId;
    }
  }

  getActiveOrganization() {
    const activeOrganizationId = this.getActiveOrganizationId();
    return activeOrganizationId ? this.getOrganization(activeOrganizationId) : null;
  }

  getActiveBuyingGroup() {
    const activeOrganization = this.getActiveOrganization();
    return activeOrganization
      ? activeOrganization.memberOf
        ? this.getOrganization(activeOrganization.memberOf)
        : null
      : null;
  }

  getActiveOrganizationName() {
    const activeOrganization = this.getActiveOrganization();
    const displayNameOverride = this.getActiveOrganizationDynamicProperty("patronDisplayNameOverride");
    return displayNameOverride ? displayNameOverride : activeOrganization ? activeOrganization.name : null;
  }

  getActiveOrganizationBrandingLogoUrl() {
    const activeOrganization = this.getActiveOrganization();
    return activeOrganization ? activeOrganization.brandingLogoUrl : null;
  }

  getOrganization(organizationId) {
    return L.find(propEq("organizationId", organizationId), this.getOrganizations());
  }

  getOrganizationForAlias(alias) {
    const propAlias = L.prop("alias");

    return L.find(propEq("alias", alias), L.reject(L.compose(L.isNil, propAlias), this.getOrganizations()));
  }

  getOrganizations() {
    const organizations = this.get("organizations");
    return L.isNil(organizations) ? [] : organizations;
  }

  getOrganizationIds() {
    return L.map(L.prop("organizationId"), this.getOrganizations());
  }

  setOrganizations(organizations) {
    this.set("organizations", organizations);
  }

  getShowSelfe() {
    const showSelfe = this.getActiveOrganizationProperty("showSelfe");
    return showSelfe !== false;
  }

  getSelfEEnabled() {
    const selfeEnabled = this.getActiveOrganizationProperty("selfeEnabled");
    return !!selfeEnabled;
  }

  getShowBuyLinks() {
    const showBuyLink = this.getActiveOrganizationProperty("showBuyLink");
    return !!showBuyLink;
  }

  getShowShareLinks() {
    const showShareLinks = this.getActiveOrganizationProperty("showShareLinks");
    return !!showShareLinks;
  }

  getShowBadges() {
    const organizationProperties = this.getActiveOrganizationProperty("organizationProperties");

    return organizationProperties && organizationProperties.showBadges === "true";
  }

  getBannerAdMobileApp() {
    const bannerAdMobileApp = this.getActiveOrganizationProperty("bannerAdMobileApp");
    return bannerAdMobileApp !== false;
  }

  getActiveOrganizationProperty(property) {
    const activeOrganization = this.getActiveOrganization();

    if (activeOrganization) {
      return activeOrganization[property];
    } else {
      return null;
    }
  }

  getOrganizationDynamicProperty(organization, property) {
    const dynamicProperties = organization ? organization["organizationProperties"] : null;

    return dynamicProperties && dynamicProperties[property] ? dynamicProperties[property] : null;
  }

  getActiveOrganizationDynamicProperty(property) {
    return this.getOrganizationDynamicProperty(this.getActiveOrganization(), property);
  }

  getOauthDisplayName() {
    return this.getActiveOrganizationProperty("oauthDisplayName");
  }

  getMarketingUrl() {
    return this.getActiveOrganizationProperty("marketingUrl");
  }

  getMarketingUrlName() {
    return this.getActiveOrganizationProperty("marketingUrlName");
  }

  getGoogleAnalyticsKeys() {
    const buyingGroupKeys = this.getOrganizationDynamicProperty(this.getActiveBuyingGroup(), "googleAnalyticsKey");

    const activeOrganizationKeys = this.getActiveOrganizationDynamicProperty("googleAnalyticsKey");

    return L.compose(
      L.uniq,
      L.map(L.trim),
      L.flatMap(L.split(",")),
      L.filter(k => !L.isEmpty(k)),
    )([buyingGroupKeys, activeOrganizationKeys]);
  }

  hasOrganization(organizationId) {
    return !L.isNil(this.getOrganization(organizationId));
  }

  hasOrganizations(organizationIds) {
    return L.all(organizationId => this.hasOrganization(organizationId), organizationIds);
  }

  hasActiveOrganization() {
    return !L.isNil(this.getActiveOrganizationId());
  }

  getPublicOrganization() {
    return L.find(isPublicOrganization, this.getOrganizations());
  }

  isActiveOrganizationPublic() {
    return isPublicOrganization(this.getActiveOrganization());
  }
}

export default UserModel;
