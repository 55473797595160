import _ from "lodash";
import BaseListTileView from "scripts/views/baseListTileView";
import template from "templates/summaryContentListTile.hbs";

class SummaryContentListTileView extends BaseListTileView {
  get events() {
    return _.extend({}, super.events, {
      "click .btn-bookshelf": "onClickBookshelfButton",
    });
  }

  constructor(options) {
    super(options);

    if (this.bookshelfAvailable()) {
      this.initializeBookshelfDeps();
    }
  }

  render() {
    const modelData = this.model.toJSON();

    const templateData = _.merge(modelData, {
      viewerUrl: `/viewer/${modelData.id}`,
    });

    this.$el.html(template(templateData));

    this.initDetailButton();
    this.addContributors(this.model.get("contributors"));

    return this;
  }
}

export default SummaryContentListTileView;
