import Promise from "bluebird";
import Backbone from "backbone";
import BaseView from "scripts/views/baseView";
import BreadcrumbModel from "scripts/models/breadcrumbModel";
import LegalNoticesPageNavView from "scripts/views/legalNoticesPageNavView";
import { getCurrentFragment } from "scripts/utils/routerHelpers";

import templateLegalNoticesPage from "templates/legalNoticesPage.hbs";

const chan = Backbone.Radio.channel;

class LegalNoticesPageView extends BaseView {
  get template() {
    return templateLegalNoticesPage;
  }

  constructor(options) {
    super(options);

    this.navView = this.addSubView(
      new LegalNoticesPageNavView({
        model: this.model,
      }),
    );

    this.subPageView = this.addSubView(options.subPageView);

    //TODO: make these common
    chan("controllerView").reply(
      "legalNoticesPageView",
      this.replyLegalNoticesPageView,
      this,
    );
  }

  //TODO: make these common
  replyLegalNoticesPageView() {
    return this;
  }

  render() {
    this.$el.html(this.template());

    this.navView
      .appendTo($("#bb-legal-notices-page-sticky-nav-region"))
      .render();

    this.subPageView
      .attachTo($("#bb-legal-notices-page-sub-page-region"))
      .render();

    return this;
  }

  getBreadcrumbInfo() {
    return {
      breadcrumbModel: new BreadcrumbModel({
        text: "Legal Notices",
        path: "#" + getCurrentFragment(),
        topLevel: true,
      }),
    };
  }

  //TODO: make common
  showSubPageView(subPageView) {
    if (this.subPageViewPromise && this.subPageViewPromise.isPending()) {
      this.subPageViewPromise.cancel();
    }

    //        chan('display').trigger('bodyViewLoading');

    this.subPageViewPromise = this.showSubView(
      "subPageView",
      subPageView,
      $("#bb-legal-notices-page-sub-page-region"),
    )
      .catch(Promise.CancellationError, () => {
        // swallow cancellation
        console.debug("show sub page view cancelled");
      })
      .finally(value => {
        //                    chan('display').trigger('bodyViewLoaded');

        if (this.navView.isStuck()) {
          $(window).scrollTop(this.navView.scrollTopToStick());
        }

        return value;
      });

    return this.subPageViewPromise;
  }

  getDocumentTitle() {
    return "Legal";
  }
}

export default LegalNoticesPageView;
