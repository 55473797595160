import _ from "lodash";
import L from "lodash/fp";
import inject from "scripts/ioc/inject";
import Backbone from "backbone";
import scrollToElement from "scripts/functions/scrollToElement";
import BaseView from "scripts/views/baseView";
import MediaSalvattoreGridView from "scripts/views/mediaSalvattoreGridView";

import templateCurationSalvattoreGrid from "templates/curationSalvattoreGrid.hbs";

class CurationSalvattoreGridView extends BaseView {
  get template() {
    return templateCurationSalvattoreGrid;
  }

  get className() {
    return "bb-curation-salvattore-grid";
  }

  get previewSize() {
    return 5;
  }

  get events() {
    return {
      "click .bb-curation-salvattore-grid-show-more-btn": "onClickShowMore",
      "click .bb-curation-salvattore-grid-show-less-btn": "onClickShowLess",
    };
  }

  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    options = options || {};

    this.i18nextService = i18nextService;

    this.expanded = options.expanded;

    this.mediaSalvattoreGridView = this.addSubView(
      new MediaSalvattoreGridView({
        collection: new Backbone.Collection(),
      }),
    );

    this.bubble("imagesLoaded", this.mediaSalvattoreGridView);
  }

  render() {
    const i18n = this.getTranslations();

    this.$el.html(this.template(L.merge({ i18n }, this.model.toJSON())));

    this.mediaSalvattoreGridView
      .attachTo(this.$el.find(".bb-media-salvattore-grid-region"))
      .render();

    this.expand(this.expanded);

    return this;
  }

  getTranslations() {
    const glossary = this.i18nextService.getGlossary();

    return L.flowRight(
      L.pick(["explore", "preview", "showLess"]),
      L.get(["curation", "button"]),
    )(glossary);
  }

  sync() {
    return this.collection.fetch();
  }

  expand(expand) {
    this.expanded = expand;

    this.mediaSalvattoreGridView.removeAllGridTileViews();

    let mediaModels;

    if (expand) {
      mediaModels = this.collection.models;

      if (this.collection.length > this.previewSize) {
        this.$el
          .find(".bb-curation-salvattore-grid-show-more-btn")
          .addClass("hide");
        this.$el
          .find(".bb-curation-salvattore-grid-show-less-btn")
          .removeClass("hide");
      }

      this.$el
        .find(".bb-curation-salvattore-grid-see-all-btn")
        .removeClass("hide");
    } else {
      mediaModels = _.take(this.collection.models, this.previewSize);

      if (this.collection.length > this.previewSize) {
        this.$el
          .find(".bb-curation-salvattore-grid-show-more-btn")
          .removeClass("hide");
        this.$el
          .find(".bb-curation-salvattore-grid-see-all-btn")
          .addClass("hide");
        this.$el
          .find(".bb-curation-salvattore-grid-show-less-btn")
          .addClass("hide");
      } else {
        this.$el
          .find(".bb-curation-salvattore-grid-see-all-btn")
          .removeClass("hide");
      }
    }

    this.mediaSalvattoreGridView.addMedia(mediaModels);
  }

  onClickShowMore() {
    this.trigger("expanded", true, this.model.get("id"));

    this.expand(true);
  }

  onClickShowLess() {
    this.trigger("expanded", false, this.model.get("id"));

    this.expand(false);

    const $el = this.$el.find(".bb-curation-salvattore-grid-display-name");
    scrollToElement($el.get(0), -150); // why such a large offset?
  }
}

export default CurationSalvattoreGridView;
