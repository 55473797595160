import Backbone from "backbone";
import BaseView from "scripts/views/baseView";
import MainNavView from "scripts/views/mainNavView";
import MastheadOverlayView from "scripts/views/mastheadOverlayView";
import BreadcrumbView from "scripts/views/breadcrumbView";

import templateMasthead from "templates/masthead.hbs";
import { replaceAndLoadFragment } from "scripts/utils/routerHelpers";
import Promise from "bluebird";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";

const chan = Backbone.Radio.channel;

class MastheadView extends BaseView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.mainNavView = this.addSubView(new MainNavView());

    this.breadcrumbView = this.addSubView(new BreadcrumbView());

    this.mastheadOverlayView = this.addSubView(new MastheadOverlayView());

    this.mainNavView.on("close", () => this.closeOverlay(), this);
    this.mainNavView.on("menu", () => this.openMenuOverlay(), this);
    this.mainNavView.on("search", () => this.openSearchOverlay(), this);

    this.mastheadOverlayView.on("close", () => this.closeOverlay(), this);

    chan("display").on("scroll:scrolling", this.handleScroll, this);

    chan("display").on(
      "bodyViewLoading",
      () => {
        this.resetPosition();
        this.showLoader();
      },
      this,
    );

    chan("display").on(
      "bodyViewLoaded",
      () => {
        this.hideLoader();
        this.closeOverlay();
      },
      this,
    );

    chan("display").on(
      "openMenuOverlay",
      (openedOnLoad = false) => {
        this.hideLoader();
        this.openMenuOverlay();
        this.openedOnLoad = openedOnLoad;
      },
      this,
    );

    chan("display").reply("openSearchMenu", searchTerm => {
      this.openSearchOverlay(searchTerm);
    });

    $(document).on("keydown.masthead", e => {
      if (e.which === 27) {
        // <esc>
        this.closeOverlay();
      }
    });
  }

  get id() {
    return "bb-masthead";
  }

  get template() {
    return templateMasthead;
  }

  handleScroll(scrollTop, delta) {
    if (scrollTop >= 0) {
      // safari goes negative when it does it's bounce thing
      const $masthead = $("#bb-main-masthead-region");
      const mastheadHeight = $masthead.height();
      const mastheadTop = parseInt($masthead.css("top"), 10);

      const $stickyNav = $("#bb-secondary-sticky-nav");
      const isStickyFixed = $stickyNav.css("position") === "fixed";
      const secondaryNavHalfCircleHeight = 20;

      const minMastheadTop =
        -1 *
        (isStickyFixed
          ? $stickyNav.height() + secondaryNavHalfCircleHeight + mastheadHeight
          : mastheadHeight);

      let newMastheadTop = mastheadTop + delta;

      if (newMastheadTop < minMastheadTop) {
        newMastheadTop = minMastheadTop;
      } else if (newMastheadTop > 0) {
        newMastheadTop = 0;
      }

      $masthead.css("top", newMastheadTop);
    }
  }

  resetPosition() {
    chan("display").off("scroll:scrolling", this.handleScroll, this);

    const $masthead = $("#bb-main-masthead-region");
    $masthead.css("top", 0);

    setTimeout(() => {
      chan("display").on("scroll:scrolling", this.handleScroll, this);
    }, 100);
  }

  showLoader() {
    this.mainNavView.showLoader();
  }

  hideLoader() {
    this.mainNavView.hideLoader();
  }

  sync() {
    return Promise.all([
      this.mainNavView.fetch(),
      this.mastheadOverlayView.fetch(),
    ]);
  }

  render() {
    const { breadcrumbs, mainMenu } = L.get(
      ["ariaLabel"],
      this.i18nextService.getAttributes(),
    );
    this.$el.html(this.template({ i18n: { breadcrumbs, mainMenu } }));

    this.mainNavView
      .attachTo(this.$el.find("#bb-main-masthead-main-nav-region"))
      .render();

    this.breadcrumbView
      .attachTo(this.$el.find("#bb-main-masthead-breadcrumb-region"))
      .render();

    this.mastheadOverlayView.attachTo(this.$el).render();

    return this;
  }

  closeOverlay() {
    this.resetPosition();

    this.mastheadOverlayView.hide();
    this.mainNavView.showButtons();

    if (this.openedOnLoad) {
      replaceAndLoadFragment("/home");
      this.openedOnLoad = false;
    }
  }

  openMenuOverlay() {
    this.resetPosition();

    this.mastheadOverlayView.showMainMenu();
    this.mainNavView.hideButtons();
  }

  openSearchOverlay(searchTerm) {
    this.resetPosition();

    this.mastheadOverlayView.showSearchMenu(searchTerm);
    this.mainNavView.hideButtons();
  }

  hideBreadcrumbView() {
    $("#bb-main-masthead-breadcrumb-region").addClass("hide");
  }

  showBreadcrumbView() {
    $("#bb-main-masthead-breadcrumb-region").removeClass("hide");
  }

  close() {
    $(document).off("keydown.masthead");
    super.close();
  }
}

export default MastheadView;
