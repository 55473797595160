import _ from "lodash";
import HybridMediaCollection from "scripts/collections/hybridMediaCollection";

class BookmarkedContentCollection extends HybridMediaCollection {
  // merge bookmark count with content model
  parse(response) {
    return _.map(response, contentWrapper => {
      contentWrapper.content.bookmarkCount = contentWrapper.count;
      return contentWrapper.content;
    });
  }
}

export default BookmarkedContentCollection;
