import BaseView from "scripts/views/baseView";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";
import {
  redirectToFragment,
  getCurrentFragment,
} from "scripts/utils/routerHelpers";

import templateSearchOverlayMenuView from "templates/searchOverlayMenu.hbs";

class SearchOverlayMenuView extends BaseView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);
    this.i18nextService = i18nextService;
  }

  get template() {
    return templateSearchOverlayMenuView;
  }

  get events() {
    return {
      "keydown #bb-search-overlay-menu-search-input": "handleKeyDown",
      "click #bb-search-overlay-menu-search-button": "goSearchResults",
    };
  }

  render() {
    const attributes = this.i18nextService.getAttributes();
    const glossary = this.i18nextService.getGlossary();

    const { advanced } = this.i18nextService.getCommon();

    const ariaLabel = L.flowRight([
      L.pick(["input", "submitButton"]),
      L.get(["ariaLabel", "search"]),
    ])(attributes);

    const search = L.get(["search", "search", "singular"], glossary);

    this.$el.html(this.template({ i18n: { advanced, ariaLabel, search } }));

    return this;
  }

  handleKeyDown(event) {
    // Enter key pressed with search terms
    if (event.which === 13) {
      this.goSearchResults();
    }
  }

  goSearchResults() {
    const $input = $("#bb-search-overlay-menu-search-input");
    const query = $input.val();

    if (query) {
      $input.blur();

      const urlFragment = `search-results/${encodeURIComponent(
        "g=" + encodeURIComponent(query),
      )}`;

      if (getCurrentFragment() === urlFragment) {
        this.trigger("close");
      } else {
        redirectToFragment(urlFragment);
      }
    }
  }

  focus() {
    $("#bb-search-overlay-menu-search-input").focus();
  }

  clear() {
    $("#bb-search-overlay-menu-search-input").val("");
  }

  setSearchTerm(searchTerm) {
    $("#bb-search-overlay-menu-search-input").val(searchTerm);
  }

  close() {
    super.close();
    $(document).off("keydown");
  }
}

export default SearchOverlayMenuView;
