import BaseView from "scripts/views/baseView";
import MediaListView from "scripts/views/mediaListView";
import templateModulesPageListSubPage from "templates/modulesPageListSubPage.hbs";
import ModulesPageNavView from "./modulesPageNavView";

class ModulesPageListSubPageView extends BaseView {
  get template() {
    return templateModulesPageListSubPage;
  }

  constructor(options) {
    super(options);

    this.model.set("collection", this.collection);
    this.navView = this.addSubView(
        new ModulesPageNavView({
          model: this.model,
        }),
    );

    this.mediaListView = this.addSubView(
      new MediaListView({
        collection: this.collection,
      }),
    );
  }

  render() {
    this.$el.html(
      this.template({
        moduleCount: this.collection.length,
      }),
    );

    this.navView
        .attachTo(this.$el.find("#bb-modules-page-sticky-nav-region"))
        .render();

    this.mediaListView
      .appendTo($("#bb-modules-page-list-sub-page-list-view-region"))
      .render();

    return this;
  }

  sync() {
    return this.mediaListView.fetch();
  }
}

export default ModulesPageListSubPageView;
