import BaseView from "scripts/views/baseView";
import templateStackedMediaTypesChart from "templates/stackedMediaTypesChart.hbs";

class StackedMediaTypesChartView extends BaseView {
  get template() {
    return templateStackedMediaTypesChart;
  }

  render() {
    this.$el.html(this.template(this.model.toJSON()));
    return this;
  }
}

export default StackedMediaTypesChartView;
