import BaseView from "scripts/views/baseView";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";

import styles from "sharedStyles/changeOrganizationTile.css";
import template from "templates/changeOrganizationTile.hbs";

class ChangeOrganizationTileView extends BaseView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);
    this.options = options;

    this.i18nextService = i18nextService;
  }

  get className() {
    return styles.container;
  }

  get events() {
    return {
      click: "onClick",
    };
  }

  template() {
    const switchButton = L.get(
      ["button", "switch"],
      this.i18nextService.getCommon(),
    );
    return template({
      i18n: { switchButton },
      styles,
      organization: this.options.organization,
      isActive: this.options.isActive,
      disabled: this.options.isActive ? "disabled" : "",
    });
  }

  render() {
    this.$el.html(this.template());

    return this;
  }

  onClick() {
    this.trigger("click", this.options);
    return false;
  }
}

export default ChangeOrganizationTileView;
