var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-xs-12\">\n    <form class=\"form-horizontal comment-new-form\">\n        <input type=\"hidden\" name=\"parentId\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"parentId") || (depth0 != null ? lookupProperty(depth0,"parentId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"parentId","hash":{},"data":data,"loc":{"start":{"line":3,"column":52},"end":{"line":3,"column":64}}}) : helper)))
    + "\">\n        <div class=\"form-group\">\n            <label class=\"control-label col-xs-2 col-sm-1\">\n                <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"avatar") || (depth0 != null ? lookupProperty(depth0,"avatar") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"avatar","hash":{},"data":data,"loc":{"start":{"line":6,"column":26},"end":{"line":6,"column":36}}}) : helper)))
    + "\" alt=\"\" class=\"comment-input-user-avatar\" />\n            </label>\n            <div class=\"col-xs-10 col-sm-11 comment-input-wrapper\">\n                <textarea aria-label=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"joinDiscussion") : stack1), depth0))
    + "\" class=\"form-control comment-input-content\" name=\"textMessage\"\n                          placeholder=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"joinDiscussion") : stack1), depth0))
    + ".\"></textarea>\n                <div class=\"text-right comment-input-submit\">\n                    <button type=\"submit\" class=\"btn-rounded btn-rounded-brand-primary p-lr-30\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"post") : stack1), depth0))
    + "</button>\n                </div>\n            </div>\n        </div>\n    </form>\n</div>";
},"useData":true});