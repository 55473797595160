import Promise from "bluebird";
import BaseService from "scripts/services/baseService";
import CurrentLocationModel from "scripts/models/currentLocationModel";
import CurrentLocationLocalStorageModel from "scripts/models/currentLocationLocalStorageModel";
import inject from "scripts/ioc/inject";

class CurrentLocationService extends BaseService {
  constructor(
    securityService = inject("securityService"),
    connectionService = inject("connectionService"),
  ) {
    super();

    this.securityService = securityService;
    this.connectionService = connectionService;
  }

  fetch(attrs) {
    const user = this.securityService.getUser();

    return Promise.all([
      user.hasProfile() ? this.fetchServer(attrs) : Promise.resolve(null),
      this.fetchLocal(attrs),
    ]).then(locations => {
      if (!locations || (!locations[0] && !locations[1])) {
        return Promise.reject();
      }

      let offset;
      if (!locations[0]) {
        offset = locations[1].get("offset");
      } else if (!locations[1]) {
        offset = locations[0].get("offset");
      } else {
        const localDate = locations[1].get("lastUpdate");
        const serverDate = Date.parse(locations[0].get("lastUpdate"));
        offset =
          localDate > serverDate
            ? locations[1].get("offset")
            : locations[0].get("offset");
      }

      return Promise.resolve(offset);
    });
  }

  save(attrs) {
    const user = this.securityService.getUser();

    console.log("Saving current location %O", attrs);
    if (user.hasProfile() && this.connectionService.isOnline()) {
      new CurrentLocationModel(attrs).save().catch(e => {
        console.log("Error saving current location: %O", e);
      });
    }

    new CurrentLocationLocalStorageModel({
      id: attrs.mediaId,
      mediaId: attrs.mediaId,
      offset: attrs.offset,
      lastUpdate: Date.now(),
    })
      .save()
      .catch(e => {
        console.log("Error saving current location local: %O", e);
      });
  }

  fetchServer(attrs) {
    return new CurrentLocationModel(attrs)
      .fetch()
      .then(model => {
        console.log("Fetched Server current location: %o", model);
        return Promise.resolve(model);
      })
      .catch(() => null);
  }

  fetchLocal(attrs) {
    return new CurrentLocationLocalStorageModel({ id: attrs.mediaId })
      .fetch()
      .then(model => {
        console.log("Fetched local current location: %o", model);
        return Promise.resolve(model);
      })
      .catch(() => null);
  }
}

export default CurrentLocationService;
