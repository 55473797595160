import BaseListTileView from "scripts/views/baseListTileView";
import templateModuleListTile from "templates/moduleListTile.hbs";

class ModuleListTileView extends BaseListTileView {
  get template() {
    return templateModuleListTile;
  }
}

export default ModuleListTileView;
