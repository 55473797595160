import BaseView from "scripts/views/baseView";
import CurationAddMediaModalView from "scripts/views/curationAddMediaModalView";
import CurationMediaAddedGrowlAlertView from "scripts/views/curationMediaAddedGrowlAlertView";
import errorAlert from "scripts/alerts/errorAlert";
import inject from "scripts/ioc/inject";
import CurationMultiSelectModel from "scripts/models/curationMultiSelectModel";

import templateCurationMultiSelectView from "templates/curationMultiSelect.hbs";

const chan = Backbone.Radio.channel;

class CurationMultiSelectView extends BaseView {
  constructor(
    options,
    curationMultiSelectService = inject("curationMultiSelectService"),
    securityService = inject("securityService"),
    connectionService = inject("connectionService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);

    this.curationMultiSelectService = curationMultiSelectService;
    this.securityService = securityService;
    this.connectionService = connectionService;
    this.i18nextService = i18nextService;

    chan("curationMultiSelectService").on(
      "itemAdded",
      () => this.render(),
      this,
    );
    chan("curationMultiSelectService").on(
      "itemRemoved",
      () => this.render(),
      this,
    );
    chan("curationMultiSelectService").on(
      "toggleSelect",
      () => this.render(),
      this,
    );
    chan("curationMultiSelectService").on(
      "cancelSelect",
      () => this.render(),
      this,
    );

    chan("connectionService").on(
      "status:change",
      () => this.onConnectionStatusChange(),
      this,
    );
  }

  get template() {
    return templateCurationMultiSelectView;
  }

  get id() {
    return "bb-curation-multiselect-view";
  }

  get className() {
    return "curation-multiselect-view";
  }

  get events() {
    return {
      "click #bb-curation-multiselect-toggle-button":
        "onClickToggleCurationMultiSelect",
      "click #bb-curation-multiselect-cancel-button":
        "onClickCancelCurationMultiSelect",
      "click #bb-curation-multiselect-add-button": "onClickSaveCurationItems",
    };
  }

  render() {
    const user = this.securityService.getUser();

    if (user.hasCuratorRole()) {
      const isOnline = this.connectionService.getConnectionStatus().isOnline;
      if (isOnline) {
        const i18n = this.getTranslations();

        this.$el.html(
          this.template({
            i18n,
            selectedCount: this.curationMultiSelectService.getItems().length,
          }),
        );

        if (this.curationMultiSelectService.getActive()) {
          $("#bb-curation-multiselect-inactive").addClass("hide");
          $("#bb-curation-multiselect-active").removeClass("hide");
        }
        return;
      }
    }

    this.$el.html("<div />");
  }

  getTranslations() {
    const glossary = this.i18nextService.getGlossary();
    const common = this.i18nextService.getCommon();

    const { addToCuration, button } = glossary.curation;
    const { cancel, selected } = common.button;

    return {
      addToCuration,
      cancel,
      selected,
      selectForCuration: button.select,
    };
  }

  onClickToggleCurationMultiSelect() {
    this.curationMultiSelectService.toggleActive();
  }

  onClickCancelCurationMultiSelect() {
    this.curationMultiSelectService.cancel();
  }

  onClickSaveCurationItems() {
    if (this.curationMultiSelectService.getItems().length === 0) {
      return;
    }

    const curationAddMediaModal = new CurationAddMediaModalView({
      model: new CurationMultiSelectModel({
        media: this.curationMultiSelectService.getItems(),
      }),
    });

    curationAddMediaModal.once(
      "success",
      curationId => {
        const curationMediaAddedGrowlAlertView = this.addSubView(
          new CurationMediaAddedGrowlAlertView({
            curationId: curationId,
            autoClose: true,
          }),
        );

        curationMediaAddedGrowlAlertView.on("close", () => {
          curationMediaAddedGrowlAlertView
            .animateOut()
            .then(() => this.closeSubView(curationMediaAddedGrowlAlertView));
        });

        curationMediaAddedGrowlAlertView
          .appendTo($("#bb-app-growl-alert-region"))
          .render();

        this.curationMultiSelectService.cancel();
      },
      this,
    );

    curationAddMediaModal.once("failure", errorAlert);

    chan("display").request("showBlockingLoader");

    curationAddMediaModal.fetch().then(() => {
      chan("display").request("hideBlockingLoader");
      chan("display").request("showModal", curationAddMediaModal);
    });

    return false;
  }

  onConnectionStatusChange() {
    this.render();
  }

  close() {
    super.close();
  }
}

export default CurationMultiSelectView;
