import Backbone from "backbone";

Backbone.LocalStorage.extend = Backbone.Model.extend;

Backbone.SessionStorage = Backbone.LocalStorage.extend({
  localStorage: function() {
    return sessionStorage;
  },
});

export default Backbone.SessionStorage;
