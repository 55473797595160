import _ from "lodash";
import Backbone from "backbone";
import BaseView from "scripts/views/baseView";
import LoaderView from "scripts/views/loaderView";

import UnauthorizedSyncError from "scripts/exceptions/unauthorizedSyncError";

const chan = Backbone.Radio.channel;

class BaseFormView extends BaseView {
  constructor(options) {
    super(options);

    this.loaderView = this.addSubView(new LoaderView());

    this.on("invalid", this.showErrors, this);
  }

  bindModel(model) {
    this.model = model;

    this.model.on("invalid", this.bubbleModelInvalid, this);
  }

  toJSON() {
    return this.$el.find("form").serializeObject();
  }

  isValid() {
    return !this.validate({
      silent: true,
    });
  }

  isValidField(field) {
    return !this.validateField(field, {
      silent: true,
    });
  }

  validate(options) {
    options = options || {};

    let errors = this.model.validate(this.toJSON());

    if (errors && !options.silent) {
      this.trigger("invalid", errors);
    }

    return errors;
  }

  validateField(field, options) {
    options = options || {};

    let errors = this.model.validateAttribute(field, this.toJSON());

    if (errors && !options.silent) {
      this.trigger("invalid", errors);
    }

    return errors;
  }

  save() {
    this.clearErrors();

    return this.model.save(this.toJSON(), {
      patch: true,
    });
  }

  bubbleModelInvalid(model, error) {
    this.trigger("invalid", error);
  }

  findField(fieldName) {
    return this.$el.find('form *[name="' + fieldName + '"]');
  }

  showError(error, field) {
    // params are reversed for convenience
    this.clearError(field);

    this.findField(field)
      .tooltip({
        delay: 0,
        animation: false,
        placement: "top",
        trigger: "manual",
        title: error[0],
      })
      .tooltip("show")
      .closest(".form-group")
      .addClass("has-error");
  }

  showErrors(errors) {
    _.each(errors, this.showError.bind(this));
  }

  clearError(field) {
    this.findField(field)
      .tooltip("destroy")
      .closest(".form-group")
      .removeClass("has-error");
  }

  clearErrors() {
    let fieldNames = _.map(this.$el.find("form *[name]"), el => {
      return $(el).attr("name");
    });

    _.each(fieldNames, this.clearError.bind(this));
  }

  onBlurValidate(event) {
    let field = $(event.currentTarget).attr("name");
    this.clearError(field);
    this.validateField(field);
  }

  onInputEnableSubmit(event) {
    let field = $(event.currentTarget).attr("name");
    this.clearError(field);
    this.enableSubmit(this.isValid());
  }

  onKeyupClearError(event) {
    let field = $(event.currentTarget).attr("name");
    if (event.which !== 13) {
      this.clearError(field);
    }
  }

  enableSubmit(enable) {
    let $submitButton = this.$el.find('form button[type="submit"]');

    if (enable) {
      $submitButton.removeAttr("disabled");
    } else {
      $submitButton.attr("disabled", "disabled");
    }
  }

  showLoader() {
    this.$el.find(".bb-form-submit-button-loader-region").removeClass("hide");
    this.$el.find('button[type="submit"]').addClass("hide");
    this.loaderView.startAnimation();
    return this;
  }

  hideLoader() {
    this.$el.find(".bb-form-submit-button-loader-region").addClass("hide");
    this.$el.find('button[type="submit"]').removeClass("hide");
    this.loaderView.stopAnimation();
    return this;
  }

  onSubmit() {
    let errors = this.validate();

    if (!errors) {
      this.model.set(this.toJSON());
      this.enableSubmit(false);
      this.showLoader();

      this.doSubmit()
        .then(this.onSubmitSucceeded.bind(this), this.onSubmitFailed.bind(this))
        .finally(this.hideLoader.bind(this));
    }

    return false;
  }

  doSubmit() {
    return this.save();
  }

  onSubmitSucceeded(value) {
    this.enableSubmit(false);
    this.trigger("success", value);
  }

  onSubmitFailed(error) {
    this.enableSubmit(true);

    if (error instanceof UnauthorizedSyncError) {
      console.log(
        "base form view caught unauthorized error, logging user out...",
      );
      return chan("security").request("logout", false);
    } else {
      this.trigger("failure", error);
    }
  }
}

export default BaseFormView;
