import Backbone from "backbone";
import inject from "scripts/ioc/inject";
import BaseModalView from "scripts/views/baseModalView";
import L from "lodash/fp";

import templateCookieSettingsModal from "templates/cookieSettingsModal.hbs";

const chan = Backbone.Radio.channel;

class CookieSettingsModalView extends BaseModalView {
  constructor(
    options,
    googleAnalyticsService = inject("googleAnalyticsService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);

    this.googleAnalyticsService = googleAnalyticsService;
    this.i18nextService = i18nextService;

    chan("connectionService").on(
      "status:change",
      connectionStatus => {
        if (!connectionStatus.isOnline) {
          this.closeModal();
        }
      },
      this,
    );
  }

  get template() {
    return templateCookieSettingsModal;
  }

  get events() {
    return {
      "click #bb-cookie-settings-modal-confirm": "confirmCookieSettingsClick",
      "click #bb-cookie-settings-analytics": "toggleCookieAnalyticsClick",
    };
  }

  render() {
    const modal = L.get(
      ["cookies", "modal"],
      this.i18nextService.getGlossary(),
    );
    console.log(modal);
    this.$el.html(this.template({ i18n: { modal } }));
    this.renderCookieAnalyticsButton();
    return this;
  }

  renderCookieAnalyticsButton() {
    if (this.googleAnalyticsService.isAnalyticsOptIn()) {
      this.checkCookieAnalyticsButton();
    } else {
      this.unCheckCookieAnalyticsButton();
    }
  }

  toggleCookieAnalyticsClick() {
    if (this.isCookieAnalyticsChecked()) {
      this.unCheckCookieAnalyticsButton();
    } else {
      this.checkCookieAnalyticsButton();
    }
  }

  confirmCookieSettingsClick() {
    this.googleAnalyticsService.setAnalyticsOptIn(
      this.isCookieAnalyticsChecked(),
    );
  }

  isCookieAnalyticsChecked() {
    return (
      this.$el.find("#bb-cookie-settings-analytics.btn-chktoggle-checked")
        .length > 0
    );
  }

  checkCookieAnalyticsButton() {
    this.$el
      .find("#bb-cookie-settings-analytics")
      .removeClass("btn-chktoggle-unchecked")
      .addClass("btn-chktoggle-checked");
  }

  unCheckCookieAnalyticsButton() {
    this.$el
      .find("#bb-cookie-settings-analytics")
      .removeClass("btn-chktoggle-checked")
      .addClass("btn-chktoggle-unchecked");
  }
}

export default CookieSettingsModalView;
