import L from "lodash/fp";
import { purep } from "bluebird-promisell";
import querystring from "querystring";
import inject from "scripts/ioc/inject";
import Controller from "scripts/controllers/controller";
import { parseRedirectFragmentFromUrl } from "scripts/utils/urlUtil";
import { replaceAndLoadFragment } from "scripts/utils/routerHelpers";

class AppSchemeLoginController extends Controller {
  constructor(securityService = inject("securityService")) {
    super();

    this.securityService = securityService;
  }

  get route() {
    return "route:appSchemeLogin";
  }

  replyRoute() {
    const token = this.securityService.getUser().getToken();
    const redirectFragment = parseRedirectFragmentFromUrl(window.location.href);
    const params = {
      redirect_fragment: !L.isNil(redirectFragment)
        ? redirectFragment
        : "/home",
    };

    setTimeout(() => {
      const appSchemeUrl = `biblioboardlibrary://login/${token}?${querystring.stringify(
        params,
      )}`;
      console.log("Setting app scheme url: %O", appSchemeUrl);
      window.location.href = appSchemeUrl;
    }, 0);

    replaceAndLoadFragment("/about");

    return purep(null);
  }
}

export default AppSchemeLoginController;
