var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button id=\"bb-error-page-back-button\" class=\"btn-outline btn-outline-gray m-t-10\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"takeMeBack") : stack1), depth0))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"error-page error-page-999 bg-white\">\n    <div class=\"container m-t-60 m-b-40\">\n        <div class=\"row header-row\">\n            <div class=\"col-xs-12 col-md-offset-1 col-md-9\">\n                <h1 class=\"fnt-56\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"errorPage") : stack1)) != null ? lookupProperty(stack1,"999") : stack1)) != null ? lookupProperty(stack1,"subscriptionError") : stack1), depth0))
    + "</h1>\n                <p class=\"fnt-22 m-t-5 clr-gray-dark\"><span class=\"fa fa-music\"></span>&nbsp;"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"errorPage") : stack1)) != null ? lookupProperty(stack1,"999") : stack1)) != null ? lookupProperty(stack1,"cantTouchThis") : stack1), depth0))
    + "&nbsp;<span class=\"fa fa-music\"></span></p>\n            </div>\n        </div>\n\n        <img class=\"subscription-img\" src=\"" + require("/opt/atlassian/pipelines/agent/build/node_modules/handlebars-template-loader/file-loader.js?url=..%2Fimages%2FsubscriptionError.gif!../images/subscriptionError.gif") + "\" alt=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"error") : stack1)) != null ? lookupProperty(stack1,"subscription") : stack1), depth0))
    + "\"/>\n\n        <div class=\"row\">\n            <div class=\"col-xs-12 col-md-offset-1 col-md-5\">\n                <p class=\"fnt-18\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"errorPage") : stack1)) != null ? lookupProperty(stack1,"999") : stack1)) != null ? lookupProperty(stack1,"forReal") : stack1), depth0))
    + "</p>\n                <p class=\"fnt-18 m-b-30\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"errorPage") : stack1)) != null ? lookupProperty(stack1,"999") : stack1)) != null ? lookupProperty(stack1,"talkToLibrarian") : stack1), depth0))
    + "</p>\n            </div>\n        </div>\n\n        <div class=\"row m-b-20\">\n            <div class=\"col-xs-12 col-md-offset-1 col-md-9\">\n                <!-- button container-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"canGoBack") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":24,"column":23}}})) != null ? stack1 : "")
    + "\n                <button id=\"bb-error-page-home-button\" class=\"btn-solid btn-solid-brand-primary m-t-10\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"biblioHome") : stack1), depth0))
    + "</button>\n            </div>\n        </div>\n\n        <div class=\"row\">\n            <div class=\"col-xs-12 col-md-offset-1 col-md-9\">\n                <a href=\"http://support.biblioboard.com\" target=\"_blank\">\n                    <img src=\"" + require("/opt/atlassian/pipelines/agent/build/node_modules/handlebars-template-loader/file-loader.js?url=..%2Fimages%2Fsupport-icon.gif!../images/support-icon.gif") + "\" height=\"28\" width=\"28\" class=\"m-r-10\" alt=\"\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"support") : stack1), depth0))
    + "\n                </a>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});