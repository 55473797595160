import BaseSecondaryStickyView from "scripts/views/baseSecondaryStickyNavView";
import templateMyBoardPageNav from "templates/myBoardPageNav.hbs";

class MyBoardPageNavView extends BaseSecondaryStickyView {
  get template() {
    return templateMyBoardPageNav;
  }

  shouldStick() {
    return true;
  }

  updateLinkState() {
    let subPageType = this.model.get("subPageType");
    let presentationType = this.model.get("presentationType");

    if (subPageType === "favorites") {
      $("#bb-my-board-page-nav-item-favorites").addClass("active");
      $("#bb-my-board-page-nav-header-favorites").addClass("active");

      if (presentationType === "masonry") {
        $(
          "#bb-my-board-page-nav-presentation-type-toggle-favorites-list",
        ).removeClass("hide");
      } else {
        $(
          "#bb-my-board-page-nav-presentation-type-toggle-favorites-masonry",
        ).removeClass("hide");
      }
    } else if (subPageType === "bookmarks") {
      $("#bb-my-board-page-nav-item-bookmarks").addClass("active");
      $("#bb-my-board-page-nav-header-bookmarks").addClass("active");

      if (presentationType === "masonry") {
        $(
          "#bb-my-board-page-nav-presentation-type-toggle-bookmarks-list",
        ).removeClass("hide");
      } else {
        $(
          "#bb-my-board-page-nav-presentation-type-toggle-bookmarks-masonry",
        ).removeClass("hide");
      }
    } else if (subPageType === "bookshelf") {
      $("#bb-my-board-page-nav-item-bookshelf").addClass("active");
      $("#bb-my-board-page-nav-header-bookshelf").addClass("active");

      if (presentationType === "masonry") {
        $(
          "#bb-my-board-page-nav-presentation-type-toggle-bookshelf-list",
        ).removeClass("hide");
      } else {
        $(
          "#bb-my-board-page-nav-presentation-type-toggle-bookshelf-masonry",
        ).removeClass("hide");
      }
    } else if (subPageType === "recents") {
      $("#bb-my-board-page-nav-item-recents").addClass("active");
      $("#bb-my-board-page-nav-header-recents").addClass("active");

      if (presentationType === "masonry") {
        $(
          "#bb-my-board-page-nav-presentation-type-toggle-recents-list",
        ).removeClass("hide");
      } else {
        $(
          "#bb-my-board-page-nav-presentation-type-toggle-recents-masonry",
        ).removeClass("hide");
      }
    }
  }
}

export default MyBoardPageNavView;
