import inject from "scripts/ioc/inject";

class mediaPageNavTranslations {
  constructor(i18nextService = inject("i18nextService")) {
    this.i18nextService = i18nextService;
  }

  getTranslations() {
    const { ariaLabel } = this.i18nextService.getAttributes();
    const { actions, button } = this.i18nextService.getCommon();
    const { collection, content, curation, menu, module, search } = this.i18nextService.getGlossary();
    return {
      categories: search.category.plural,
      collection,
      content,
      curation,
      itemDetails: ariaLabel.itemDetails,
      menu,
      module,
      search,
      toggleNav: actions.toggleNav,
      view: {
        grid: {
          ariaLabel: ariaLabel.toggleView(button.gridView),
          button: button.gridView,
          switchView: ariaLabel.switchView(button.gridView),
        },
        list: {
          ariaLabel: ariaLabel.toggleView(button.listView),
          button: button.listView,
          switchView: ariaLabel.switchView(button.listView),
        },
      },
    };
  }
}

export default mediaPageNavTranslations;
