var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/curation/"
    + alias4(((helper = (helper = lookupProperty(helpers,"mediaId") || (depth0 != null ? lookupProperty(depth0,"mediaId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mediaId","hash":{},"data":data,"loc":{"start":{"line":9,"column":31},"end":{"line":9,"column":42}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":9,"column":43},"end":{"line":9,"column":63}}}) : helper)))
    + "/modules\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"modules") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"modules") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/curation/"
    + alias4(((helper = (helper = lookupProperty(helpers,"mediaId") || (depth0 != null ? lookupProperty(depth0,"mediaId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mediaId","hash":{},"data":data,"loc":{"start":{"line":14,"column":31},"end":{"line":14,"column":42}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":14,"column":43},"end":{"line":14,"column":63}}}) : helper)))
    + "/anthologies\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"anthologies") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"anthologies") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/curation/"
    + alias4(((helper = (helper = lookupProperty(helpers,"mediaId") || (depth0 != null ? lookupProperty(depth0,"mediaId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mediaId","hash":{},"data":data,"loc":{"start":{"line":19,"column":31},"end":{"line":19,"column":42}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":19,"column":43},"end":{"line":19,"column":63}}}) : helper)))
    + "/textbooks\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"textbooks") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"textbooks") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/curation/"
    + alias4(((helper = (helper = lookupProperty(helpers,"mediaId") || (depth0 != null ? lookupProperty(depth0,"mediaId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mediaId","hash":{},"data":data,"loc":{"start":{"line":24,"column":31},"end":{"line":24,"column":42}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":24,"column":43},"end":{"line":24,"column":63}}}) : helper)))
    + "/collections\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"collections") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"collections") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/curation/"
    + alias4(((helper = (helper = lookupProperty(helpers,"mediaId") || (depth0 != null ? lookupProperty(depth0,"mediaId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mediaId","hash":{},"data":data,"loc":{"start":{"line":29,"column":31},"end":{"line":29,"column":42}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":29,"column":43},"end":{"line":29,"column":63}}}) : helper)))
    + "/chapters\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"chapters") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"chapters") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/curation/"
    + alias4(((helper = (helper = lookupProperty(helpers,"mediaId") || (depth0 != null ? lookupProperty(depth0,"mediaId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mediaId","hash":{},"data":data,"loc":{"start":{"line":34,"column":31},"end":{"line":34,"column":42}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":34,"column":43},"end":{"line":34,"column":63}}}) : helper)))
    + "/highlights\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"highlights") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"highlights") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/curation/"
    + alias4(((helper = (helper = lookupProperty(helpers,"mediaId") || (depth0 != null ? lookupProperty(depth0,"mediaId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mediaId","hash":{},"data":data,"loc":{"start":{"line":39,"column":31},"end":{"line":39,"column":42}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":39,"column":43},"end":{"line":39,"column":63}}}) : helper)))
    + "/articles\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"articles") : stack1)) != null ? lookupProperty(stack1,"arialabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"articles") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/curation/"
    + alias4(((helper = (helper = lookupProperty(helpers,"mediaId") || (depth0 != null ? lookupProperty(depth0,"mediaId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mediaId","hash":{},"data":data,"loc":{"start":{"line":44,"column":31},"end":{"line":44,"column":42}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":44,"column":43},"end":{"line":44,"column":63}}}) : helper)))
    + "/books\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"books") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"books") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/curation/"
    + alias4(((helper = (helper = lookupProperty(helpers,"mediaId") || (depth0 != null ? lookupProperty(depth0,"mediaId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mediaId","hash":{},"data":data,"loc":{"start":{"line":49,"column":31},"end":{"line":49,"column":42}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":49,"column":43},"end":{"line":49,"column":63}}}) : helper)))
    + "/other-documents\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"documents") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"documents") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/curation/"
    + alias4(((helper = (helper = lookupProperty(helpers,"mediaId") || (depth0 != null ? lookupProperty(depth0,"mediaId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mediaId","hash":{},"data":data,"loc":{"start":{"line":54,"column":31},"end":{"line":54,"column":42}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":54,"column":43},"end":{"line":54,"column":63}}}) : helper)))
    + "/images\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"images") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"images") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/curation/"
    + alias4(((helper = (helper = lookupProperty(helpers,"mediaId") || (depth0 != null ? lookupProperty(depth0,"mediaId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mediaId","hash":{},"data":data,"loc":{"start":{"line":59,"column":31},"end":{"line":59,"column":42}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":59,"column":43},"end":{"line":59,"column":63}}}) : helper)))
    + "/audio\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"audio") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"audio") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/curation/"
    + alias4(((helper = (helper = lookupProperty(helpers,"mediaId") || (depth0 != null ? lookupProperty(depth0,"mediaId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mediaId","hash":{},"data":data,"loc":{"start":{"line":64,"column":31},"end":{"line":64,"column":42}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":64,"column":43},"end":{"line":64,"column":63}}}) : helper)))
    + "/audiobooks\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"audiobook") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"audiobook") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/curation/"
    + alias4(((helper = (helper = lookupProperty(helpers,"mediaId") || (depth0 != null ? lookupProperty(depth0,"mediaId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mediaId","hash":{},"data":data,"loc":{"start":{"line":69,"column":31},"end":{"line":69,"column":42}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":69,"column":43},"end":{"line":69,"column":63}}}) : helper)))
    + "/videos\" class=\"content-filter\"\n               aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"videos") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"videos") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container\">\n    <div class=\"row m-0 p-0 m-b-40\">\n        <a id=\"bb-curation-page-media-sub-page-all-media-link\"\n           href=\"/curation/"
    + alias4(((helper = (helper = lookupProperty(helpers,"mediaId") || (depth0 != null ? lookupProperty(depth0,"mediaId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mediaId","hash":{},"data":data,"loc":{"start":{"line":4,"column":27},"end":{"line":4,"column":38}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":4,"column":39},"end":{"line":4,"column":59}}}) : helper)))
    + "/all-media\"\n           aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"all") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n           class=\"content-filter\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"all") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"moduleCount") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":11,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"anthologyCount") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"textbookCount") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":21,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"collectionCount") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":26,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"chapterCount") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":31,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"contentHighlightCount") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":36,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"articleCount") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":41,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"bookCount") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":8},"end":{"line":46,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"otherDocumentCount") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":8},"end":{"line":51,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"imageCount") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":8},"end":{"line":56,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"audioCount") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":8},"end":{"line":61,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"audiobookCount") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":8},"end":{"line":66,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"videoCount") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":8},"end":{"line":71,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n<div id=\"bb-curation-page-content-sub-page-content-region\"></div>\n";
},"useData":true});