import BaseSecondaryStickyView from "scripts/views/baseSecondaryStickyNavView";
import templateModulesPageNav from "templates/modulesPageNav.hbs";

class ModulesPageNavView extends BaseSecondaryStickyView {
  get template() {
    return templateModulesPageNav;
  }

  shouldStick() {
    return false;
  }
}

export default ModulesPageNavView;
