import inject from "scripts/ioc/inject";
import BaseService from "scripts/services/baseService";

class SelfEService extends BaseService {
  constructor(securityService = inject("securityService")) {
    super();
    this.securityService = securityService;
  }

  getGeneralSelfEUrl() {
    return "https://indieauthorproject.com/authors/";
  }

  getPartnerSelfEUrl() {
    if (this.isSelfEEnabled()) {
      const baseSelfeUrl = 'https://indieauthorproject.librariesshare.com/indieauthor';
      const organization = this.securityService
        .getUser()
        .getActiveOrganization();
      if (organization && organization.alias) {
        return `${baseSelfeUrl}/org/${organization.alias}`;
      } else {
        return baseSelfeUrl;
      }
    } else {
      return null;
    }
  }

  isSelfEEnabled() {
    return this.securityService.getUser().getSelfEEnabled();
  }

  getShowSelfE() {
    return this.securityService.getUser().getShowSelfe();
  }
}

export default SelfEService;
