import Promise from "bluebird";
import Controller from "scripts/controllers/controller";

class ManageCurationsController extends Controller {
  get route() {
    return "route:manageCurations";
  }

  replyRoute() {
    return this.showMainBodyViewPromise(
      Promise.resolve(
        import(/* webpackChunkName: "manageCurations" */ "scripts/views/manageCurationsPageView"),
      ).then(
        ({ default: ManageCurationsPageView }) => new ManageCurationsPageView(),
      ),
    );
  }
}

export default ManageCurationsController;
