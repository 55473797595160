import Backbone from "backbone";
import ContentModel from "scripts/models/contentModel";
import bookshelfSync from "scripts/functions/bookshelfSync";
import HybridCollectionHoc from "scripts/hoc/hybridCollectionHoc";

class OfflineContentCollection extends HybridCollectionHoc(
  Backbone.Collection,
) {
  model(attrs, options) {
    return new ContentModel(attrs, options);
  }

  sync(...args) {
    return bookshelfSync(...args);
  }
}

export default OfflineContentCollection;
