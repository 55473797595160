import _ from "lodash";
// import L from "lodash/fp";
import Backbone from "backbone";
import Promise from "bluebird";
import inject from "scripts/ioc/inject";
import BaseMediaDetailsPageView from "scripts/views/baseMediaDetailsPageView";
import ModulePageNavView from "scripts/views/modulePageNavView";
import PagerView from "scripts/views/ellipsisPagerView";
import MediaListView from "scripts/views/mediaListView";
import MediaSalvattoreGridView from "scripts/views/mediaSalvattoreGridView";
import { redirectToFragment, replaceFragment } from "scripts/utils/routerHelpers";

import templateModulePage from "templates/modulePage.hbs";
import SearchResultCollection from "../collections/searchResultCollection";

const chan = Backbone.Radio.channel;

const PAGE_SIZE = 20;

// // const saginawModuleId = "c853ba0b-035f-4ca3-b32d-c0fbb10e79d0";
// const saginawModuleId = "the-saginaw-afro-herald";

class ModulePageView extends BaseMediaDetailsPageView {
  constructor(
    options,
    breadcrumbService = inject("breadcrumbService"),
    connectionService = inject("connectionService"),
    securityService = inject("securityService"),
  ) {
    super(options, breadcrumbService, connectionService, securityService);

    this.model.on("change:presentationType", this.onPresentationTypeChangeSaveUserPreference, this);

    const pageIndex = this.model.get("pageIndex");

    this.sortOption = "name asc";

    this.collection = new SearchResultCollection([], {
      path: "/search/v2",
      state: {
        currentPage: pageIndex,
        pageSize: PAGE_SIZE,
      },
    });

    /**
     * Hack to satisfy Backbone.PageableCollection._checkState
     * if starting from a page other than 0.
     */
    if (pageIndex > 0) {
      this.collection.links[pageIndex] = this.collection.url;
    }
  }

  get events() {
    return _.extend({}, super.events, {
      "click #bb-secondary-sticky-nav-presentation-type-toggle-list": "onClickListView",
      "click #bb-secondary-sticky-nav-presentation-type-toggle-masonry": "onClickMasonryView",
      "click .sort-option": "onClickSortButton",
    });
  }

  get template() {
    return templateModulePage;
  }

  isOverflowed(element) {
    return element && (element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth);
  }

  render() {
    super.render();

    this.renderCurationButton();
    this.renderPageHeaderDescriptionContainer();

    const $description = $("#description-container");

    //-- Make sure all anchor tags open in new tab.
    const $anchors = $description.find("a");
    $anchors.attr("target", "_blank");

    if (!this.isOverflowed($description[0])) {
      const $descriptionContainer = $("#bb-media-details-description-container");
      $descriptionContainer.removeClass("collapse");
      const $descriptionCollapseBtn = $("#description-collapse-button");
      $descriptionCollapseBtn.addClass("hidden");
    }
  }

  sync() {
    return this.mediaModel
      .fetch()
      .then(() => this.syncCollection())
      .then(() => {
        this.subPageView = this.addSubView(this.createSubPageView());
      });
  }

  syncCollection() {
    this.collection.state.currentPage = this.model.get("pageIndex");

    const mediaId = this.mediaModel.get("id");
    const contentSearchFilter = this.mediaModel.get("contentSearchFilter");
    const moduleQueryParams = {};

    // search filter form should be 'p=*&c=BOOK'
    if (contentSearchFilter) {
      console.log("ContentSearchFilter=%O", contentSearchFilter);
      _.forEach(_.split(contentSearchFilter, "&"), t => {
        const filter = _.split(t, "=");
        if (filter && filter.length === 2) {
          moduleQueryParams[filter[0]] = filter[1];

          if (filter[0] === "sort-fields") {
            this.sortOption = filter[1];
            chan("modulePageNav").trigger("sortOptionChange", this.sortOption);
          }
        }
      });
    }

    this.collection.queryParams = _.assign(
      {
        limit: PAGE_SIZE,
        offset: this.collection.state.currentPage * this.collection.state.pageSize,
        sort: true,
        "sort-fields": this.sortOption,
        _dpm: mediaId,
      },
      moduleQueryParams,
    );

    return this.collection
      .fetch()
      .cancellable()
      .then(o => {
        return o;
      });
  }

  onClickSortButton(event) {
    this.sortOption = event.target.value;
    this.collection.queryParams["sort-fields"] = this.sortOption;
    this.collection.queryParams.offset = 0;
    this.model.set("pageIndex", 0);
    chan("display").trigger("bodyViewLoading");
    chan("modulePageNav").trigger("sortOptionChange", this.sortOption);

    return this.collection
      .fetch()
      .cancellable()
      .then(() => this.showSubPageView(Promise.resolve(this.createSubPageView())))
      .finally(() => {
        this.updateFragment();
        chan("display").trigger("bodyViewLoaded");
      });
  }

  createNavView() {
    return new ModulePageNavView({
      model: this.model,
      moduleModel: this.mediaModel,
      sortOption: this.sortOption,
    });
  }

  getBreadcrumbText() {
    return this.mediaModel.get("displayName");
  }

  getDocumentTitle() {
    return `${this.mediaModel.get("displayName")}`;
  }

  createSubPageView() {
    let subPage;

    let pageViewOptions = {
      hasPreviousPage: this.collection.hasPreviousPage(),
      hasNextPage: this.collection.hasNextPage(),
      pageNumber: this.model.get("pageIndex") + 1,
      totalPages: Math.ceil(this.collection.totalRecords / this.collection.state.pageSize),
    };

    let presentationType = this.model.get("presentationType");

    if (presentationType === "list") {
      subPage = new PagerView(
        _.extend(
          {
            mediaView: new MediaListView({
              collection: this.collection,
            }),
          },
          pageViewOptions,
        ),
      );
    } else {
      subPage = new PagerView(
        _.extend(
          {
            mediaView: new MediaSalvattoreGridView({
              collection: this.collection,
            }),
          },
          pageViewOptions,
        ),
      );
    }

    subPage.on(
      "pageSequenceChanged",
      pageNumber => {
        this.model.set("pageIndex", pageNumber - 1);
        redirectToFragment(this.getUrlFragment());
      },
      this,
    );

    return subPage;
  }

  updateFragment() {
    const newFragment = this.getUrlFragment();
    replaceFragment(newFragment);
    chan("history").request("pushFragment", newFragment.replace(/^\//, ""));
    this.breadcrumbService.updateBreadcrumb();
  }

  getUrlFragment() {
    const pageIndex = this.model.get("pageIndex") || 0;
    const presentationType = this.model.get("presentationType");
    const moduleId = this.mediaModel.get("internalCode");
    return `/module/${moduleId}/${presentationType}/${pageIndex + 1}`;
  }

  onClickListView() {
    this.model.set("presentationType", "list");
    this.showSubPageView(Promise.resolve(this.createSubPageView()));
    this.updateFragment();
    return false;
  }

  onClickMasonryView() {
    this.model.set("presentationType", "masonry");
    this.showSubPageView(Promise.resolve(this.createSubPageView()));
    this.updateFragment();
    return false;
  }

  showSubPageView(subPagePromise) {
    if (this.showSubPagePromise && this.showSubPagePromise.isPending()) {
      this.showSubPagePromise.cancel();
    }

    chan("display").trigger("bodyViewLoading");

    this.showSubPagePromise = subPagePromise
      .then(view => this.showSubView("subPageView", view, $("#bb-media-details-sub-page-region")))
      .catch(Promise.CancellationError, () => {
        // swallow cancellation
        console.debug("show sub page view cancelled");
      })
      .finally(value => {
        chan("display").trigger("bodyViewLoaded");

        if (this.navView.isStuck()) {
          $(window).scrollTop(this.navView.scrollTopToStick());
        }

        return value;
      });
  }
}

export default ModulePageView;
