import Backbone from "backbone";
import PromisedSyncHoc from "scripts/hoc/promisedSyncHoc";

class UserOrganizationPreferencesLocalStorageModel extends PromisedSyncHoc(Backbone.Model) {
  constructor(attributes, options) {
    super(attributes, options);

    this.localStorage = new Backbone.LocalStorage(
      (options && options.storageName) || "userOrganizationPreferencesLocalStorage",
    );
  }
}

export default UserOrganizationPreferencesLocalStorageModel;
