import Backbone from "backbone";
import inject from "scripts/ioc/inject";
import HybridMediaCollection from "scripts/collections/hybridMediaCollection";
import BreadcrumbModel from "scripts/models/breadcrumbModel";
import BaseView from "scripts/views/baseView";
import MediaSalvattoreGridView from "scripts/views/mediaSalvattoreGridView";
import MediaListView from "scripts/views/mediaListView";
import StackedMediaTypesChartView from "scripts/views/stackedMediaTypesChartView";
import { getCurrentFragment } from "scripts/utils/routerHelpers";

import templateFavoritesSubPage from "templates/favoritesSubPage.hbs";

const chan = Backbone.Radio.channel;

class FavoritesSubPageView extends BaseView {
  constructor(options, securityService = inject("securityService")) {
    super(options);

    this.securityService = securityService;

    const user = this.securityService.getUser();

    this.collection = new HybridMediaCollection([], {
      path: `/users/${user.getUserId()}/favorites`,
    });

    this.stackedMediaTypesChartView = this.addSubView(
      new StackedMediaTypesChartView({
        model: new Backbone.Model({
          includeAnthology: true,
        }),
      }),
    );

    const contentViewOptions = {
      collection: this.collection,
      unfavoriteConfirmationRequired: true,
    };

    if (this.model.get("presentationType") === "masonry") {
      this.contentView = this.addSubView(
        new MediaSalvattoreGridView(contentViewOptions),
      );
    } else {
      this.contentView = this.addSubView(new MediaListView(contentViewOptions));
    }

    chan("myBoard").on("mediaUnfavorited", this.removeMedia, this);
  }

  get template() {
    return templateFavoritesSubPage;
  }

  subPageTitle() {
    return this.collection.length === 1
      ? "1 Favorite"
      : this.collection.length + " Favorites";
  }

  render() {
    this.$el.html(
      this.template({
        title: this.subPageTitle(),
      }),
    );

    this.renderStackedMediaTypesChart();

    this.contentView
      .appendTo($("#bb-favorites-sub-page-content-region"))
      .render();

    return this;
  }

  renderStackedMediaTypesChart() {
    this.stackedMediaTypesChartView.model.set(
      this.collection.mediaTypePercentages(),
    );
    this.stackedMediaTypesChartView.render();

    this.stackedMediaTypesChartView
      .htmlOf($("#bb-favorites-sub-page-chart-region"))
      .render();
  }

  sync() {
    return this.collection.fetch();
  }

  getBreadcrumbInfo() {
    return {
      breadcrumbModel: new BreadcrumbModel({
        text: "Favorites",
        path: "#" + getCurrentFragment(),
        topLevel: true,
      }),
    };
  }

  removeMedia(media) {
    this.collection.remove(media);

    this.renderStackedMediaTypesChart();

    this.$el.find("h1").text(this.subPageTitle());
  }

  getDocumentTitle() {
    return "Favorites";
  }
}

export default FavoritesSubPageView;
