import BaseView from "scripts/views/baseView";
import L from "lodash/fp";
import inject from "scripts/ioc/inject";

import verifyEmailSuccessSubPage from "templates/verifyEmailSuccessSubPage.hbs";

class VerifyEmailSuccessSubView extends BaseView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;
  }

  get template() {
    return verifyEmailSuccessSubPage;
  }

  render() {
    this.$el.html(
      this.template({
        i18n: this.getTranslations(),
        emailAddress: this.model.emailAddress,
      }),
    );

    return this;
  }

  getTranslations() {
    const common = this.i18nextService.getCommon();
    const glossary = this.i18nextService.getGlossary();

    const { email } = common;

    const auth = L.flowRight([
      L.pick(["thankYouVerify", "yourVerifiedEmail"]),
      L.get(["auth"]),
    ])(glossary);
    return { auth, email };
  }
}

export default VerifyEmailSuccessSubView;
