import Backbone from "backbone";
import Promise from "bluebird";
import inject from "scripts/ioc/inject";
import OfflineContentCollection from "scripts/collections/offlineContentCollection";
import BreadcrumbModel from "scripts/models/breadcrumbModel";
import BaseView from "scripts/views/baseView";
import MediaSalvattoreGridView from "scripts/views/mediaSalvattoreGridView";
import MediaListView from "scripts/views/mediaListView";
import { getCurrentFragment } from "scripts/utils/routerHelpers";

import templateBookshelfSubPage from "templates/bookshelfSubPage.hbs";

const chan = Backbone.Radio.channel;

class BookshelfSubPageView extends BaseView {
  get template() {
    return templateBookshelfSubPage;
  }

  constructor(
    options,
    bookshelfService = inject("bookshelfService"),
    securityService = inject("securityService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);

    this.bookshelfService = bookshelfService;
    this.securityService = securityService;
    this.i18nextService = i18nextService;

    this.collection = new OfflineContentCollection();

    this.totalSize = 0;

    if (this.model.get("presentationType") === "masonry") {
      this.contentView = this.addSubView(
        new MediaSalvattoreGridView({
          collection: this.collection,
        }),
      );
    } else {
      this.contentView = this.addSubView(
        new MediaListView({
          collection: this.collection,
        }),
      );
    }

    chan("myBoard").on(
      "mediaRemovedFromBookshelf",
      this.removeMedia.bind(this),
      this,
    );
    chan("bookshelfService").on(
      "download:complete",
      () => this.onBookshelfDownloadComplete(),
      this,
    );
  }

  onBookshelfDownloadComplete() {
    this.updateTotalSize();
  }

  render() {
    const { content } = this.i18nextService.getGlossary();
    this.$el.html(
      this.template({
        title: this.subPageTitle(),
        totalContentSize: this.totalSize,
        i18n: { onDevice: content.onDevice },
      }),
    );

    // this.renderHeader();

    this.contentView
      .appendTo($("#bb-bookshelf-sub-page-content-region"))
      .render();

    return this;
  }

  renderHeader() {
    $("#bb-bookshelf-sub-page-header-region").html(
      this.headerTemplate({
        title: this.subPageTitle(),
        totalContentSize: this.totalSize,
      }),
    );
  }

  subPageTitle() {
    return this.collection.length === 1
      ? "1 Item"
      : `${this.collection.length} Items`;
  }

  removeMedia(mediaModel) {
    this.collection.remove(mediaModel);
    this.updateTotalSize();
  }

  //TODO: add loading indicator or something
  updateTotalSize() {
    this.syncTotalSize().then(() => this.renderHeader());
  }

  sync() {
    return Promise.all([this.collection.fetch(), this.syncTotalSize()]);
  }

  syncTotalSize() {
    const organizationId = this.securityService
      .getUser()
      .getActiveOrganizationId();

    return this.bookshelfService
      .getBookshelfSize(organizationId)
      .then(totalSize => {
        this.totalSize = totalSize;
      });
  }

  getBreadcrumbInfo() {
    return {
      breadcrumbModel: new BreadcrumbModel({
        text: "Offline Bookshelf",
        path: "#" + getCurrentFragment(),
        topLevel: true,
      }),
    };
  }
}

export default BookshelfSubPageView;
