import L from "lodash/fp";
import Promise from "bluebird";
import querystring from "querystring";
import { pipep, purep, reject, toPromise } from "bluebird-promisell";
import Backbone from "backbone";
import UnauthorizedSyncError from "scripts/exceptions/unauthorizedSyncError";
import ForbiddenSyncError from "scripts/exceptions/forbiddenSyncError";
import inject from "scripts/ioc/inject";
import { parseOAuthParamsFromUrl } from "scripts/utils/urlUtil";

import { pass } from "scripts/utils/generalHelpers";

import { offlineGoToBookshelfAlert, offlineNoProfileAlert } from "scripts/alerts/alerts";

import {
  fetchOrganizationsWithAccessToMedia,
  getOrganizationsWithHighestCredentialType,
} from "scripts/utils/securityHelpers";

import {
  ROUTES,
  getCurrentFragment,
  getMediaIdFromRouteProps,
  // getSplashScreenShown,
  // setSplashScreenShown,
  isInsecureRoute,
  isMediaRoute,
  isOfflineRoute,
  isRouteAuthorized,
  pushFragment,
  replaceAndLoadFragment,
} from "scripts/utils/routerHelpers";

const chan = Backbone.Radio.channel;
const routeChan = chan("route");

class RefreshException {
  constructor(fragment) {
    this.fragment = fragment;
  }
}

class RedirectException {
  constructor(fragment) {
    this.fragment = fragment;
  }
}

const rejectRedirect = fragment => reject(new RedirectException(fragment));
const rejectRefresh = fragment => reject(new RefreshException(fragment));

const pushCurrentFragmentToHistory = () => chan("history").request("pushFragment");
const turnOnScrollTracking = () => chan("history").request("trackScrollTop", true);
const turnOffScrollTracking = () => chan("history").request("trackScrollTop", false);

const scrollToLastScrollTop = () => {
  // reset scrollTop to what we last tracked in case back button caused premature scroll
  if (process.env.PATRON_UI_PUSHSTATE === "true") {
    return chan("history").request("scrollToLastScrollTop");
  } else {
    return null;
  }
};

const handleRouteCancellation = () => {
  chan("history").request("popFragment");

  const lastFragment = chan("history").request("lastFragment");

  if (lastFragment) {
    console.log("Router caught cancellation, reverting url to last fragment: " + lastFragment);
    pushFragment(lastFragment);
  } else {
    // console.log('Router caught cancellation, no history, showing home...');
    // return this.showHome();
  }
};

const getRequestArguments = (route, params) => {
  const request = "route:" + route;
  const requestArguments = [request].concat(route).concat(params);
  return requestArguments;
};

const routeChanRequest = ({ route, params }) => {
  try {
    return routeChan.request(...getRequestArguments(route, params));
  } catch (e) {
    return Promise.reject(e);
  }
};

const authorizationInterceptor = user =>
  toPromise(
    ({ route }) => isRouteAuthorized(route, user),
    () => new RedirectException("/login"),
  );

const offlineInterceptor =
  (user, isOnline) =>
  ({ route, params }) => {
    if (!isOnline && user.hasProfile() && !isOfflineRoute(route)) {
      console.log("Redirecting to bookshelf...");
      return offlineGoToBookshelfAlert.show().then(() => rejectRedirect("/my-board/bookshelf"));
    } else if (!isOnline && !user.hasProfile() && route !== "login") {
      return offlineNoProfileAlert.show().then(() => rejectRefresh("/login"));
    } else {
      return purep({ route, params });
    }
  };

const undeterminedOrganizationInterceptor =
  securityService =>
  ({ route, params }) => {
    const user = securityService.getUser();

    if (
      route !== "whichOrganization" &&
      route !== "associateOrganization" &&
      route !== "manageOrganizations" &&
      !user.hasActiveOrganization() &&
      user.isAuthenticated() &&
      ((user.isAuthenticated() && route === "login") || !isInsecureRoute(route))
    ) {
      if (route === "signUpCreateProfile") {
        //For now during sign-up we need to choose an active organization if there isn't one
        const newActiveOrganization = L.head(getOrganizationsWithHighestCredentialType(user.getOrganizations()));
        user.setActiveOrganizationId(newActiveOrganization.organizationId);

        console.log("Sign up, selecting active organization: %O", newActiveOrganization);

        return securityService.setUser(user).then(() => ({ route, params }));
      } else if (isMediaRoute(route)) {
        return fetchOrganizationsWithAccessToMedia(getMediaIdFromRouteProps({ route, params }), user).then(
          organizations => {
            const precedenceOrganizations = getOrganizationsWithHighestCredentialType(organizations);

            if (precedenceOrganizations.length === 1) {
              const activeOrganization = L.head(precedenceOrganizations);
              user.setActiveOrganizationId(activeOrganization.organizationId);

              return securityService.setUser(user).then(() => ({ route, params }));
            } else {
              const priorityOrganizations = L.filter(L.prop("priority"), precedenceOrganizations);

              if (priorityOrganizations.length === 1) {
                const activeOrganization = L.head(priorityOrganizations);
                user.setActiveOrganizationId(activeOrganization.organizationId);

                return securityService.setUser(user).then(() => ({ route, params }));
              } else {
                return rejectRedirect("/welcome");
              }
            }
          },
        );
      } else {
        return rejectRedirect("/welcome");
      }
    } else {
      return purep({ route, params });
    }
  };

// const isPublicSplashScreenNeeded = (route, user, sessionStorageService) => {
//   if (
//     route !== "whichOrganization" &&
//     route !== "signUpCreateProfile" &&
//     route !== "verifyEmail" &&
//     route !== "diagnostics" &&
//     !isMediaRoute(route) &&
//     !user.isBot() &&
//     user.isOnlyPublicGuest()
//   ) {
//     return getSplashScreenShown(sessionStorageService).then(not);
//   } else {
//     return purep(false);
//   }
// };

// const publicSplashScreenInterceptor = (user, sessionStorageService) => ({
//   route,
//   params,
// }) =>
//   isPublicSplashScreenNeeded(route, user, sessionStorageService).then(
//     needed => (needed ? rejectRedirect("/welcome") : { route, params }),
//   );

const createProfileInterceptor =
  user =>
  ({ route, params }) => {
    if (route === "signUpCreateProfile" && user.hasProfile()) {
      console.log("User already has a profile, redirecting to home...");
      return rejectRedirect("/home");
    } else if (route === "signUpChooseOrganization") {
      if (user.hasProfile()) {
        console.log("Redirecting legacy /sign-up/choose-organization -> /manage-organizations...");
        return rejectRedirect("/manage-organizations");
      } else {
        console.log("Redirecting legacy /sign-up/choose-organization -> /sign-up/create-profile...");
        return rejectRedirect("/sign-up/create-profile");
      }
    } else if (route === "signUpSuggestedOrganization") {
      console.log("Redirecting legacy /sign-up/suggested-organization -> /sign-up/create-profile...");

      const oAuthParams = parseOAuthParamsFromUrl(window.location.href);

      if (L.isEmpty(oAuthParams)) {
        return rejectRedirect("/sign-up/create-profile");
      } else {
        return rejectRedirect(`/sign-up/create-profile?${querystring.stringify(oAuthParams)}`);
      }
    } else {
      return purep({ route, params });
    }
  };

const controllerInterceptor =
  (googleAnalyticsService, securityService, sessionStorageService) =>
  ({ route, params }) => {
    turnOffScrollTracking();
    scrollToLastScrollTop();

    return (
      routeChanRequest({ route, params })
        .then(pass(() => googleAnalyticsService.sendPageView()))
        .then(pass(turnOnScrollTracking))
        // .then(passP(() => setSplashScreenShown(sessionStorageService)))
        .then(
          pass(() => {
            routeChan.trigger("changed");
          }),
        )
        .catch(error => {
          turnOnScrollTracking();

          if (error instanceof ForbiddenSyncError && isMediaRoute(route) && L.isNil(securityService.authScope)) {
            console.log("The forbidden sync error: %O", error);

            // WARNING: This will end up in an endless loop if the 403
            // was caused by something other than the media request

            const user = securityService.getUser();

            return fetchOrganizationsWithAccessToMedia(getMediaIdFromRouteProps({ route, params }), user).then(
              organizations => {
                const precedenceOrganizations = getOrganizationsWithHighestCredentialType(organizations);

                if (precedenceOrganizations.length === 1) {
                  const activeOrganization = L.head(precedenceOrganizations);
                  user.setActiveOrganizationId(activeOrganization.organizationId);
                  return securityService.setUser(user).then(() => rejectRefresh(getCurrentFragment()));
                } else {
                  const priorityOrganizations = L.filter(L.prop("priority"), precedenceOrganizations);

                  if (priorityOrganizations.length === 1) {
                    const activeOrganization = L.head(priorityOrganizations);
                    user.setActiveOrganizationId(activeOrganization.organizationId);

                    return securityService.setUser(user).then(() => rejectRefresh(getCurrentFragment()));
                  } else {
                    return rejectRedirect("/welcome");
                  }
                }
              },
            );
          } else {
            throw error;
          }
        })
    );
  };

class Router extends Backbone.Router {
  constructor(
    options,
    connectionService = inject("connectionService"),
    errorController = inject("errorController"),
    googleAnalyticsService = inject("googleAnalyticsService"),
    securityController = inject("securityController"),
    securityService = inject("securityService"),
    sessionStorageService = inject("sessionStorageService"),
    whichOrganizationController = inject("whichOrganizationController"),
  ) {
    super(options);

    this.connectionService = connectionService;
    this.errorController = errorController;
    this.googleAnalyticsService = googleAnalyticsService;
    this.securityController = securityController;
    this.securityService = securityService;
    this.sessionStorageService = sessionStorageService;
    this.whichOrganizationController = whichOrganizationController;

    this.on("route", this.onRoute, this);
  }

  get routes() {
    return L.merge(ROUTES, {
      "": () => {
        this.navigate("/home", { replace: true });
        this.onRoute("home");
      },

      "*actions": () => {
        this.onRoute("error", 404);
      },
    });
  }

  onRoute(route, params) {
    console.log("on route: %O, params: %O", route, params);

    if (L.isNil(route) || L.isEmpty(route)) {
      return;
    }

    pushCurrentFragmentToHistory();

    const user = this.securityService.getUser();

    const interceptors = pipep([
      pass(props => console.log("Authorization Interceptor: %O", props)),
      authorizationInterceptor(user),

      pass(props => console.log("Offline Interceptor: %O", props)),
      offlineInterceptor(user, this.connectionService.isOnline()),

      pass(props => console.log("Create Profile Interceptor: : %O", props)),
      createProfileInterceptor(user),

      pass(props => console.log("Undetermined Organization Interceptor: %O", props)),
      undeterminedOrganizationInterceptor(this.securityService),

      // pass(props => console.log("Public Splash Screen Interceptor: %O", props)),
      // publicSplashScreenInterceptor(user, this.sessionStorageService),

      pass(props => console.log("Controller Interceptor: %O", props)),
      controllerInterceptor(this.googleAnalyticsService, this.securityService, this.sessionStorageService),
    ]);

    interceptors({ route, params }).catch(error => {
      if (error instanceof Promise.CancellationError) {
        console.log("Router caught cancellation: %O", error);
        handleRouteCancellation();
      } else if (error instanceof UnauthorizedSyncError) {
        console.log("Router caught unauthorized error: %O", error);
        this.securityController.logout();
      } else if (error instanceof RedirectException) {
        console.log("Router caught redirect exception: %O", error);
        replaceAndLoadFragment(error.fragment);
      } else if (error instanceof RefreshException) {
        console.log("Router caught refresh exception: %O", error);
        chan("display").request("refreshScreen", error.fragment);
      } else {
        console.log("Router caught unhandled error: %O", error);
        this.errorController.showErrorPage(error);
      }
    });
  }
}

export default Router;
