import BaseView from "scripts/views/baseView";
import MediaListView from "scripts/views/mediaListView";
import templateCategoriesPageListSubPage from "templates/categoriesPageListSubPage.hbs";
import L from "lodash/fp";
import inject from "scripts/ioc/inject";

class CategoriesPageListSubPageView extends BaseView {
  get template() {
    return templateCategoriesPageListSubPage;
  }

  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.mediaListView = this.addSubView(
      new MediaListView({
        collection: this.collection,
      }),
    );
  }

  render() {
    const browseByCategory = L.get(
      ["search", "category", "browse"],
      this.i18nextService.getGlossary(),
    );

    this.$el.html(this.template({ i18n: { browseByCategory } }));

    this.mediaListView
      .appendTo($("#bb-categories-page-list-sub-page-list-view-region"))
      .render();

    return this;
  }

  sync() {
    return this.mediaListView.fetch();
  }
}

export default CategoriesPageListSubPageView;
