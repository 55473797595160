import Backbone from "backbone";

const displayChan = Backbone.Radio.channel("display");

class ImageBookViewerModel extends Backbone.Model {
  get zoomMagnifications() {
    return [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2, 4, 6, 8];
  }

  get columnSteps() {
    return [6, 4, 3, 2];
  }

  get defaults() {
    return {
      presentationType: "single",
      zoomLevel: 3,
      columnCountLevel: this.getColumnCountLevelForScreenWidth(), //TODO: this doesn't belong here
    };
  }

  getColumnCountLevelForScreenWidth() {
    if (displayChan.request("isSmallerThanSm")) {
      return 3; //2
    } else if (displayChan.request("isSmallerThanMd")) {
      return 2; //3
    } else {
      return 1; //4
    }
  }

  canIncColumnCountLevel() {
    return this.get("columnCountLevel") < this.columnSteps.length - 1;
  }

  canDecColumnCountLevel() {
    return this.get("columnCountLevel") > 0;
  }

  canIncZoomLevel() {
    return this.get("zoomLevel") < this.zoomMagnifications.length - 1;
  }

  canDecZoomLevel() {
    return this.get("zoomLevel") > 0;
  }

  columnCountForLevel(level) {
    return this.columnSteps[level];
  }

  zoomMagnificationForLevel(level) {
    return this.zoomMagnifications[level];
  }
}

export default ImageBookViewerModel;
