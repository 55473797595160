var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <input id=\"fullText\" type=\"checkbox\" value=\"fullText\" class=\"faceted-search-checkbox\" checked />\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <input id=\"fullText\" type=\"checkbox\" value=\"fullText\" class=\"faceted-search-checkbox\" />\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                <input id=\"titleOnly\" type=\"checkbox\" value=\"titleOnly\" class=\"faceted-search-checkbox\" checked />\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                <input id=\"titleOnly\" type=\"checkbox\" value=\"titleOnly\" class=\"faceted-search-checkbox\" />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"faceted-search-facet-widget-full-text\">\n\n    <div class=\"faceted-search-facet-clear-all\">\n        <i id=\"bb-faceted-search-close-facet-menu\" class=\"fa fa-chevron-left p-r-20\" data-toggle=\"collapse\" data-target=\"#bb-faceted-search-facet-menu-region\" aria-expanded=\"false\"></i>\n        <div class=\"search-filter-drawer-title\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"filter") : stack1)) != null ? lookupProperty(stack1,"plural") : stack1), depth0))
    + "</div>\n        <button id=\"bb-faceted-search-facet-clear-btn\" class=\"faceted-search-facet-clear-btn\">\n            "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"clear") : stack1), depth0))
    + "\n        </button>\n    </div>\n\n  <div class=\"faceted-search-checkbox-wrapper\">\n    <div>\n      <label for=\"fullText\">\n        "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"fullText") : stack1), depth0))
    + "\n      </label>\n    </div>\n    <div class=\"faceted-search-checkbox-input-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"fullText") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":18,"column":6},"end":{"line":22,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n\n    <div class=\"faceted-search-checkbox-wrapper\">\n        <div>\n            <label for=\"titleOnly\">\n                "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"titleOnly") : stack1), depth0))
    + "\n            </label>\n        </div>\n        <div class=\"faceted-search-checkbox-input-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"titleOnly") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":33,"column":12},"end":{"line":37,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>";
},"useData":true});