var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n\n    <div id=\"bb-curation-thumbnail-upload\">\n      <i class=\"bbico bbico-upload\"></i>\n      <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"upload") : stack1), depth0))
    + "</p>\n      <input type=\"file\" accept=\"image/png, image/jpeg\" id=\"bb-curation-upload-thumbnail-input\" class=\"file-upload\"  />\n      <label for=\"bb-curation-upload-thumbnail-input\" class=\"btn-rounded btn-solid-brand-primary\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"browse") : stack1), depth0))
    + "</label>\n    </div>\n\n    <img id=\"bb-curation-upload-thumbnail-loaded\" class=\"img-responsive hide\" alt=\"Thumbnail\">\n\n    <div class=\"bb-curation-media-thumbnail-checkbox hide\">\n        <i class=\"fa fa-check-square\"></i>\n    </div>\n\n  <div id=\"bb-curation-thumbnail-upload-cancel\" class=\"bb-curation-media-thumbnail-cancel hide\">\n    <i class=\"fa fa-times-circle\"></i>\n  </div>\n\n</div>";
},"useData":true});