import BaseApiCollection from "scripts/collections/baseApiCollection";
import CategoryModel from "scripts/models/categoryModel";

class CategoryCollection extends BaseApiCollection {
  get model() {
    return CategoryModel;
  }

  get path() {
    return "/search/categories";
  }
}

export default CategoryCollection;
