import { redirectToFragment } from "scripts/utils/routerHelpers";

export default function () {
  // const securityService = inject('securityService');

  $(document).on("click", "a", (event) => {
    const $currentTarget = $(event.currentTarget);
    const href = $currentTarget.attr("href");

    console.log(href);

    if (
      href &&
      !href.startsWith("http") &&
      !href.startsWith("javascript") &&
      !$currentTarget.is('[target="_blank"]') &&
      !$currentTarget.is('[target="_system"]') &&
      !$currentTarget.is('[role="tab"]') &&
      !$currentTarget.is(".skip-link") && // any element with this class is thought to have a page anchor
      !$currentTarget.is('[data-toggle="tab"]') &&
      !(event.altKey || event.ctrlKey || event.metaKey || event.shiftKey)
    ) {
      //if (securityService.authScope) {
      //    redirectToFragment(`${href}?authScope=${securityService.authScope}`);
      //} else {
      redirectToFragment(href);
      //}

      event.preventDefault();
    }
  });
}
