import BaseFormView from "scripts/views/baseFormView";
import VerifyEmailNotificationModel from "scripts/models/verifyEmailNotificationModel";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";

import verifyEmailNotificationForm from "templates/verifyEmailNotificationForm.hbs";

class VerifyEmailNotificationFormView extends BaseFormView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;
  }

  get template() {
    return verifyEmailNotificationForm;
  }

  get events() {
    return {
      "submit form": "onSubmit",
    };
  }

  initialize(options) {
    this.bindModel(
      new VerifyEmailNotificationModel({
        targetUrl: `${window.location.origin}/verify-email`,
        emailAddress: options && options.emailAddress,
      }),
    );
  }

  render() {
    this.$el.html(
      this.template(
        L.merge({ i18n: this.getTranslations() }, this.model.attributes),
      ),
    );

    return this;
  }

  getTranslations() {
    const common = this.i18nextService.getCommon();
    const glossary = this.i18nextService.getGlossary();

    const auth = L.flowRight([
      L.pick(["resend", "sending", "verification"]),
      L.get(["auth"]),
    ])(glossary);

    const { email } = common;

    return { auth, email };
  }
}

export default VerifyEmailNotificationFormView;
