import L from "lodash/fp";
import inject from "scripts/ioc/inject";
import BaseView from "scripts/views/baseView";
import { addLinksToText } from "scripts/utils/viewHelpers";
import ContentDetailsPageAlsoFoundInSubPageView from "./contentDetailsPageAlsoFoundInSubPageView";
import ContentDetailsPageNotesSubPageView from "scripts/views/contentDetailsPageNotesSubPageView";
import ContentDetailsPageCommentsSubPageView from "./contentDetailsPageCommentsSubPageView";
import ContentDetailsChaptersSubPageView from "scripts/views/contentDetailsChaptersSubPageView";
import ContentModel from "scripts/models/contentModel";
import CitationsView from "./citationsView";
import templateCategoryLink from "../../templates/categoryLink.hbs";
import ContentDetailsAlbumTracksSubPageView from "scripts/views/contentDetailsAlbumTracksSubPageView";

import templateContentDetailsSubPage from "templates/contentDetailsSubPage.hbs";

const getDoiLink = doi => (doi ? (/^https?/.test(doi) ? doi : `https://doi.org/${doi}`) : null);

class ContentDetailsSubPageView extends BaseView {
  constructor(options, securityService = inject("securityService"), i18nextService = inject("i18nextService")) {
    super(options);

    this.securityService = securityService;
    this.i18nextService = i18nextService;

    this.alsoFoundInView = this.addSubView(
      new ContentDetailsPageAlsoFoundInSubPageView({
        model: new Backbone.Model({
          mediaId: this.model.get("id"),
        }),
      }),
    );

    if (L.includes(this.model.get("type"), ["BOOK", "ARTICLE", "CHAPTER"])) {
      this.citationsView = new CitationsView({
        mediaId: this.model.get("id"),
      });
    }

    if (Boolean(this.securityService.getUser().hasProfile())) {
      this.notesView = new ContentDetailsPageNotesSubPageView({
        model: new ContentModel({
          id: this.model.get("id"),
        }),
      });
    }

    this.commentsView = new ContentDetailsPageCommentsSubPageView({
      model: new Backbone.Model({
        mediaId: this.model.get("id"),
      }),
    });

    this.chaptersView = new ContentDetailsChaptersSubPageView({
      model: new Backbone.Model({
        mediaId: this.model.get("id"),
      }),
    });

    this.albumTracksView = new ContentDetailsAlbumTracksSubPageView({
      model: this.model,
    });
  }

  get template() {
    return templateContentDetailsSubPage;
  }

  get events() {
    return {
      "click button.content-details-header": "onAccordionClick",
    };
  }

  render() {
    const authScope = this.securityService.getAuthScopeFromSubdomain();
    const user = this.securityService.getUser();

    const organizationProperties = user.getActiveOrganizationProperty("organizationProperties");

    const modelData = this.model.toJSON();
    modelData.audioType = L.capitalize(L.lowerCase(modelData.audioType));

    const attrs = L.merge(modelData, {
      i18n: this.getTranslations(),
      hasChapters: modelData.mimeType === "application/biblioboard+compound_content" && modelData.type !== "ALBUM",
      isAlbum: modelData.type === "ALBUM",
      showComments: !organizationProperties.hideCommentsTab,
      showCitations: L.includes(modelData.type, ["BOOK", "ARTICLE", "CHAPTER"]),
      showAwards: !L.isEmpty(modelData.awardMediaBadges),
      attribution: addLinksToText(this.model.get("attribution")),
      doi: addLinksToText(getDoiLink(this.model.get("doi"))),
      isTorl: authScope === "openresearchlibrary",
    });

    this.$el.html(this.template(attrs));

    if (this.model.has("bisacCategories")) {
      this.addCategories(this.model.get("bisacCategories"));
    }

    if (this.alsoFoundInView) {
      this.alsoFoundInView
        .attachTo($("#bb-content-details-also-found-in-region"))
        .fetch()
        .then(() => this.alsoFoundInView.render());
    }

    if (this.citationsView) {
      const $citations = $("#bb-citations-sub-page-container");
      this.citationsView
        .attachTo($citations)
        .fetch()
        .then(() => this.citationsView.render());
    }

    if (this.notesView) {
      const $notes = $("#bb-content-details-page-note-form-region");
      this.notesView
        .attachTo($notes)
        .fetch()
        .then(() => this.notesView.render());
    }

    if (this.commentsView) {
      const $comments = $("#bb-content-details-comment-region");
      this.commentsView
        .attachTo($comments)
        .fetch()
        .then(() => this.commentsView.render());
    }

    if (this.chaptersView) {
      const $chapters = $(".bb-chapter-list-tiles-container");
      this.chaptersView
        .attachTo($chapters)
        .fetch()
        .then(() => this.chaptersView.render());
    }

    if (this.albumTracksView) {
      const $tracks = $(".bb-album-tracks-list-tiles-container");
      this.albumTracksView
        .attachTo($tracks)
        .fetch()
        .then(() => this.albumTracksView.render());
    }

    return this;
  }

  get categoryTemplate() {
    return templateCategoryLink;
  }

  addCategories(categories) {
    const $categories = $("#bb-content-details-book-page-details-sub-page-category-region");

    const commaSeparatedCategoryLinks = categories
      .map(category => {
        return this.categoryTemplate(category);
      })
      .join(",&nbsp;");

    $categories.html(commaSeparatedCategoryLinks);
  }

  getTranslations() {
    const media = this.model.toJSON();

    const glossary = this.i18nextService.getGlossary();

    return {
      details: L.merge(
        {
          edition: {
            singular: glossary.content.ed(media.edition === undefined ? "" : media.edition),
          },
          episode: glossary.content.details.episode,
          issue: {
            singular: glossary.content.no(media.issueNumber === undefined ? "" : media.issueNumber),
          },
          volume: {
            singular: glossary.content.vol(media.volume === undefined ? "" : media.volume),
          },
          chapter: glossary.content.chapter,
        },
        glossary.content.details,
      ),
    };
  }

  // JQuery version under 3 does not allow toggleClass on svg elements; this is a workaround
  onAccordionClick(event) {
    $(event.target)
      .siblings()
      .attr("class", (_index, classNames) => {
        const regex = new RegExp("hide");
        if (regex.test(classNames)) {
          return classNames.replace("hide", "");
        } else {
          return classNames + " hide";
        }
      });
  }
}

export default ContentDetailsSubPageView;
