var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"progress-bar bg-anthology\" style=\"width: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"anthologyPercent") || (depth0 != null ? lookupProperty(depth0,"anthologyPercent") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"anthologyPercent","hash":{},"data":data,"loc":{"start":{"line":4,"column":65},"end":{"line":4,"column":85}}}) : helper)))
    + "%\"></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <div>\n                <div class=\"bg-anthology\"></div>\n                <span>Anthology</span>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row m-0 p-0 m-b-20 text-center\">\n    <div class=\"progress progress-content-distribution\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"includeAnthology") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":5,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"progress-bar bg-written\" style=\"width: "
    + alias4(((helper = (helper = lookupProperty(helpers,"writtenPercent") || (depth0 != null ? lookupProperty(depth0,"writtenPercent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"writtenPercent","hash":{},"data":data,"loc":{"start":{"line":6,"column":59},"end":{"line":6,"column":77}}}) : helper)))
    + "%\"></div>\n        <div class=\"progress-bar bg-image\" style=\"width: "
    + alias4(((helper = (helper = lookupProperty(helpers,"imagePercent") || (depth0 != null ? lookupProperty(depth0,"imagePercent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imagePercent","hash":{},"data":data,"loc":{"start":{"line":7,"column":57},"end":{"line":7,"column":73}}}) : helper)))
    + "%\"></div>\n        <div class=\"progress-bar bg-audio\" style=\"width: "
    + alias4(((helper = (helper = lookupProperty(helpers,"audioPercent") || (depth0 != null ? lookupProperty(depth0,"audioPercent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"audioPercent","hash":{},"data":data,"loc":{"start":{"line":8,"column":57},"end":{"line":8,"column":73}}}) : helper)))
    + "%\"></div>\n        <div class=\"progress-bar bg-video\" style=\"width: "
    + alias4(((helper = (helper = lookupProperty(helpers,"videoPercent") || (depth0 != null ? lookupProperty(depth0,"videoPercent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"videoPercent","hash":{},"data":data,"loc":{"start":{"line":9,"column":57},"end":{"line":9,"column":73}}}) : helper)))
    + "%\"></div>\n    </div>\n    <label class=\"vizlegend\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"includeAnthology") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":17,"column":15}}})) != null ? stack1 : "")
    + "        <div>\n            <div class=\"bg-written\"></div>\n            <span>Written</span>\n        </div>\n        <div>\n            <div class=\"bg-image\"></div>\n            <span>Image</span>\n        </div>\n        <div>\n            <div class=\"bg-audio\"></div>\n            <span>Audio</span>\n        </div>\n        <div>\n            <div class=\"bg-video\"></div>\n            <span>Video</span>\n        </div>\n    </label>\n</div>\n";
},"useData":true});