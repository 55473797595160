import _ from "lodash";
import AnthologyModel from "scripts/models/anthologyModel";
import AnthologyCollectionModel from "scripts/models/anthologyCollectionModel";
import CategoryModel from "scripts/models/categoryModel";
import ContentModel from "scripts/models/contentModel";
import ModuleModel from "scripts/models/moduleModel";
import CurationModel from "scripts/models/curationModel";
import BaseSalvattoreGridView from "scripts/views/baseSalvattoreGridView";
import AnthologySalvattoreTileView from "scripts/views/anthologySalvattoreTileView";
import AnthologyCollectionSalvattoreTileView from "scripts/views/anthologyCollectionSalvattoreTileView";
import ArticleSalvattoreTileView from "scripts/views/articleSalvattoreTileView";
import BookSalvattoreTileView from "scripts/views/bookSalvattoreTileView";
import ChapterSalvattoreTileView from "scripts/views/chapterSalvattoreTileView";
import CategorySalvattoreTileView from "scripts/views/categorySalvattoreTileView";
import ImageSalvattoreTileView from "scripts/views/imageSalvattoreTileView";
import VideoSalvattoreTileView from "scripts/views/videoSalvattoreTileView";
import AudioSalvattoreTileView from "scripts/views/audioSalvattoreTileView";
import OtherDocumentSalvattoreTileView from "scripts/views/otherDocumentSalvattoreTileView";
import ModuleSalvattoreTileView from "scripts/views/moduleSalvattoreTileView";
import CurationSalvattoreTileView from "scripts/views/curationSalvattoreTileView";
import templateMediaSalvattoreGrid from "templates/mediaSalvattoreGrid.hbs";
import AlbumSalvattoreTileView from "scripts/views/albumSalvattoreTileView";

class MediaSalvattoreGridView extends BaseSalvattoreGridView {
  get template() {
    return templateMediaSalvattoreGrid;
  }

  get className() {
    return "bb-salvattore-grid-container container";
  }

  constructor(options) {
    super(options);

    this.unfavoriteConfirmationRequired = options.unfavoriteConfirmationRequired || false;

    this.facetedSearchGrid = options.facetedSearchGrid || false;

    this.collection.on("remove", this.removeMedia, this);

    this.viewerParams = options.viewerParams;
  }

  sync() {
    return this.collection.fetch();
  }

  render() {
    this.$el.html(this.template());

    this.initializeSalvattoreGrid(this.$el.find(".bb-salvattore-grid"));

    this.addMedia(this.collection.models);

    return this;
  }

  addMedia(mediaModels) {
    let tileViews = mediaModels
      .filter(model => !model.get("hideInPatronModuleList"))
      .map(model => {
        return this.createTileView(model);
      });

    this.addGridTileViews(tileViews);

    tileViews.forEach(tileView => tileView.render());
  }

  removeMedia(mediaModel) {
    let tileView = _.find(this.subViews, view => {
      return view.model && mediaModel.get("id") === view.model.get("id");
    });

    if (tileView) {
      this.removeGridTileViews(tileView);
    }
  }

  createTileView(mediaModel) {
    let tileView;
    const tileViewOptions = {
      model: mediaModel,
      unfavoriteConfirmationRequired: this.unfavoriteConfirmationRequired,
      viewerParams: this.viewerParams,
    };

    if (mediaModel instanceof ModuleModel) {
      tileView = new ModuleSalvattoreTileView(tileViewOptions);
    } else if (mediaModel instanceof CategoryModel) {
      tileView = new CategorySalvattoreTileView(tileViewOptions);
    } else if (mediaModel instanceof AnthologyModel) {
      tileView = new AnthologySalvattoreTileView(tileViewOptions);
    } else if (mediaModel instanceof AnthologyCollectionModel) {
      tileView = new AnthologyCollectionSalvattoreTileView(tileViewOptions);
    } else if (mediaModel instanceof ContentModel) {
      tileView = this.createContentTileView(tileViewOptions);
    } else if (mediaModel instanceof CurationModel) {
      tileView = new CurationSalvattoreTileView(tileViewOptions);
    } else {
      const mediaType = mediaModel.get("mediaType");
      throw new Error(`Cannot associate media model with masonry tile, media type: ${mediaType}`);
    }

    return tileView;
  }

  createContentTileView(tileViewOptions) {
    let tileView;
    const mediaModel = tileViewOptions.model;
    const contentType = mediaModel.get("type");

    if (contentType === "ARTICLE") {
      tileView = new ArticleSalvattoreTileView(tileViewOptions);
    } else if (contentType === "AUDIO" || contentType === "AUDIOBOOK") {
      tileView = new AudioSalvattoreTileView(tileViewOptions);
    } else if (contentType === "ALBUM") {
      tileView = new AlbumSalvattoreTileView(tileViewOptions);
    } else if (contentType === "BOOK") {
      tileView = new BookSalvattoreTileView(tileViewOptions);
    } else if (contentType === "CHAPTER") {
      tileView = new ChapterSalvattoreTileView(tileViewOptions);
    } else if (contentType === "IMAGE") {
      tileView = new ImageSalvattoreTileView(tileViewOptions);
    } else if (contentType === "OTHER_DOCUMENT") {
      tileView = new OtherDocumentSalvattoreTileView(tileViewOptions);
    } else if (contentType === "VIDEO") {
      tileView = new VideoSalvattoreTileView(tileViewOptions);
    } else {
      throw new Error("Cannot associate media model with masonry tile, content type: " + contentType);
    }

    return tileView;
  }
}

export default MediaSalvattoreGridView;
