var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a href=\"/home\" id=\"bb-sign-up-create-profile-cancel-btn\" class=\"d-block m-tb-30 clr-primary-dark\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"auth") : stack1)) != null ? lookupProperty(stack1,"noThanks") : stack1), depth0))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"bb-sign-up-create-profile-page\">\n  <h1>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"auth") : stack1)) != null ? lookupProperty(stack1,"createYourProfile") : stack1), depth0))
    + "</h1>\n\n  <p class=\"m-t-30 m-b-10\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"auth") : stack1)) != null ? lookupProperty(stack1,"unlock") : stack1), depth0))
    + "</p>\n  <div class=\"text-uppercase m-b-40\">\n    <span class=\"label bg-gray-dark\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"saveFavorites") : stack1), depth0))
    + "</span>\n    <span class=\"label bg-gray-dark\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"createBookmarks") : stack1), depth0))
    + "</span>\n    <span class=\"label bg-gray-dark\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"addNotes") : stack1), depth0))
    + "</span>\n    <span class=\"label bg-gray-dark\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"syncPreferences") : stack1), depth0))
    + "</span>\n  </div>\n\n  <div id=\"bb-sign-up-create-profile-form-region\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isOAuth") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":2},"end":{"line":16,"column":13}}})) != null ? stack1 : "")
    + "\n  <a href=\"https://library.biblioboard.com/privacy\" target=\"_blank\" class=\"d-block m-tb-40 fnt-12 clr-primary-dark\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"auth") : stack1)) != null ? lookupProperty(stack1,"viewPrivacyPolicy") : stack1), depth0))
    + "</a>\n</div>";
},"useData":true});