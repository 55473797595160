import L from "lodash/fp";
import Backbone from "backbone";
import errorAlert from "scripts/alerts/errorAlert";
import inject from "scripts/ioc/inject";
import { alert } from "scripts/alerts/alerts";
import BaseModalView from "scripts/views/baseModalView";
import LoginFormView from "scripts/views/authentication/loginFormView";
import ForgotPasswordModalView from "scripts/views/forgotPasswordModalView";
import UserForcePasswordResetException from "scripts/exceptions/userForcePasswordResetException";
import UnauthorizedSyncError from "scripts/exceptions/unauthorizedSyncError";
import { redirectToFragment, refreshRoute } from "scripts/utils/routerHelpers";

import templateSignInModal from "templates/signInModal.hbs";

const chan = Backbone.Radio.channel;

class SignInModalView extends BaseModalView {
  constructor(
    options,
    sessionStorageService = inject("sessionStorageService"),
    securityService = inject("securityService"),
    historyController = inject("historyController"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);

    this.sessionStorageService = sessionStorageService;
    this.securityService = securityService;
    this.historyController = historyController;
    this.i18nextService = i18nextService;

    this.loginFormView = this.addSubView(new LoginFormView());
    this.loginFormView.on("forgot", this.onForgot, this);
    this.loginFormView.on("success", this.onAuthenticationSuccess, this);
    this.loginFormView.on("failure", this.onAuthenticationFailure, this);

    chan("connectionService").on(
      "status:change",
      connectionStatus => {
        if (!connectionStatus.isOnline) {
          this.closeModal();
        }
      },
      this,
    );
  }

  get events() {
    return {
      "click #bb-sign-in-modal-create-profile-link": "onClickCreateProfile",
    };
  }

  get template() {
    return templateSignInModal;
  }

  render() {
    const glossary = this.i18nextService.getGlossary();
    const { auth, menu } = glossary;
    const signIn = L.get(["button", "signIn"], this.i18nextService.getCommon());
    const close = L.get(
      ["ariaLabel", "close"],
      this.i18nextService.getAttributes(),
    );

    this.$el.html(
      this.template({
        i18n: { close, getStartedNow: auth.getStartedNow, menu, signIn },
      }),
    );

    this.loginFormView
      .appendTo(this.$el.find("#bb-sign-in-modal-login-form-region"))
      .render();

    setTimeout(() => {
      $("#bb-login-form-username-field").focus();
    }, 500);

    chan("display").trigger("signInModalShown");

    return this;
  }

  onForgot() {
    this.closeModal().then(() => {
      return chan("display").request(
        "showModal",
        new ForgotPasswordModalView({
          showRemember: true,
        }),
      );
    });

    return false;
  }

  onAuthenticationSuccess() {
    const lastFragment = this.historyController.getLastFragment();
    chan("controllerView").stopReplying();
    refreshRoute(L.isNil(lastFragment) ? "/home" : lastFragment);
    this.closeModal();
  }

  onAuthenticationFailure(error) {
    if (error instanceof UserForcePasswordResetException) {
      console.log(
        "Sign in modal: authentication failed b/c password needs to be reset",
      );

      this.onAuthenticationSuccess();

      chan("security")
        .request("forcePasswordReset", error)
        .catch(error => errorAlert(error).show());
    } else if (error instanceof UnauthorizedSyncError) {
      alert(
        "Invalid Credentials",
        "Are you sure that you entered your username and password correctly?",
        "error",
      ).show();
    } else {
      errorAlert(error).show();
    }
  }

  onClickCreateProfile() {
    redirectToFragment("/sign-up/create-profile");
  }
}

export default SignInModalView;
