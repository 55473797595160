import _ from "lodash";
import Promise from "bluebird";
import Backbone from "backbone";
import SearchResultCollection from "scripts/collections/searchResultCollection";
import FacetedSearchResultCollection from "scripts/collections/facetedSearchResultCollection";
import BreadcrumbModel from "scripts/models/breadcrumbModel";
import UnauthorizedSyncError from "scripts/exceptions/unauthorizedSyncError";
import inject from "scripts/ioc/inject";
import BaseView from "scripts/views/baseView";
import MediaListView from "scripts/views/mediaListView";
import MediaSalvattoreGridView from "scripts/views/mediaSalvattoreGridView";
import PagerView from "scripts/views/ellipsisPagerView";
import FacetedSearchResultsMenuView from "scripts/views/search/facetedSearchResultsMenuView";
import CurationMultiSelectView from "scripts/views/curationMultiSelectView";
import { pushFragment, getCurrentFragment } from "scripts/utils/routerHelpers";

import templateFacetedSearchResultsPage from "templates/search/facetedSearchResultsPage.hbs";
import { getObjectDiff } from "scripts/utils/generalHelpers";

const chan = Backbone.Radio.channel;

class FacetedSearchResultsPageView extends BaseView {
  constructor(options, breadcrumbService = inject("breadcrumbService"), deviceService = inject("deviceService")) {
    super(options);

    this.breadcrumbService = breadcrumbService;
    this.deviceService = deviceService;

    chan("tracking").on("viewer:click", this.trackSearchClick, this);
    chan("tracking").on("detail:click", this.trackSearchClick, this);

    const pageIndex = this.model.get("pageIndex");
    this.queryParams = this.model.get("queryParams");

    this.collection = new SearchResultCollection([], {
      path: "/search/v2",
      state: {
        currentPage: pageIndex,
      },
    });

    this.facetCollection = new FacetedSearchResultCollection([], {
      path: "/search/v2",
    });

    this.menuView = this.addSubView(
      new FacetedSearchResultsMenuView({
        model: this.model,
        collection: this.facetCollection,
        resultCollection: this.collection,
      }),
    );

    this.curationMultiSelectView = this.addSubView(new CurationMultiSelectView());

    this.model.on(
      "change:queryParams",
      (model, queryParams) => {
        console.log("Search query params changed, new params: %O and old params: %O", queryParams, this.queryParams);

        // diff and put then store the new facets
        const diff = getObjectDiff(this.queryParams, queryParams);
        this.queryParams = queryParams;

        // are any of the diffs NOT facets?
        console.log("Diff: %O", diff);
        const reloadFacets =
          _.findIndex(diff, v => {
            return v[0] !== "_";
          }) !== -1;

        this.updateResults(model, 0, reloadFacets);
      },
      this,
    );

    this.model.on(
      "change:presentationType",
      (model, presentationType) => {
        this.updateLocation();

        this.onPresentationTypeChangeSaveUserPreference(model, presentationType);
        this.showSubPageView(this.createSubPageView());
      },
      this,
    );

    this.model.on(
      "change:pageIndex",
      (model, pageIndex) => {
        this.updateResults(model, pageIndex, false);
      },
      this,
    );

    this.handleResize();
  }

  get events() {
    return {
      keyup: "onKeyup",
      "DOMMouseScroll #bb-faceted-search-facet-menu-region-overlay": "onOverlayScroll",
      "touchmove #bb-faceted-search-facet-menu-region-overlay": "onOverlayScroll",
      "shown.bs.collapse #bb-faceted-search-facet-menu-region": "onFacetMenuShown",
      "hidden.bs.collapse #bb-faceted-search-facet-menu-region": "onFacetMenuHidden",
    };
  }

  handleResize() {
    $(window).on("resize", () => {
      const $searchOverlay = $("#bb-masthead-overlay-menu-close-container");
      if ($searchOverlay.hasClass("hide")) {
        if ($(window).width() >= 769 && $(window).width() < 993) {
          $("body").css({ overflow: "auto" });
        }

        const $facetMenu = $("#bb-faceted-search-facet-menu-region");
        if ($facetMenu.hasClass("expanded") && $(window).width() < 769) {
          $("body").css({ overflow: "hidden" });
        }
      }
    });
  }

  onFacetMenuShown() {
    if ($(window).width() < 769) {
      $("body").css({ overflow: "hidden" });
    }

    $("#bb-faceted-search-facet-menu-region").addClass("expanded");
  }

  onFacetMenuHidden() {
    if ($(window).width() < 769) {
      $("body").css({ overflow: "auto" });
    }

    $("#bb-faceted-search-facet-menu-region").removeClass("expanded");
  }

  onOverlayScroll(event) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  onKeyup(e) {
    if (e.key === "Tab") {
      this.menuView.expandRefineMenu();
    }
  }

  updateLocation() {
    const urlFragment = this.getUrlFragment();

    pushFragment(urlFragment);
    chan("history").request("replaceFragment", urlFragment);

    this.breadcrumbService.updateBreadcrumb();
  }

  getUrlFragment(pageIndex) {
    if (pageIndex === void 0) {
      pageIndex = this.model.get("pageIndex");
    }

    const url = `/search-results/${encodeURIComponent(this.getQueryUrlParams())}/${this.model.get("presentationType")}`;

    if (pageIndex !== 0) {
      return `${url}/${pageIndex + 1}`;
    } else {
      return url;
    }
  }

  getQueryUrlParams() {
    const queryParams = this.model.get("queryParams");

    const searchTerm = this.getSearchTerm();
    return (searchTerm ? [`g=${searchTerm}`] : [])
      .concat(
        Object.keys(queryParams)
          .filter(param => param !== "g")
          .map(param => `${param}=${encodeURIComponent(queryParams[param])}`),
      )
      .join("&");
  }

  getSearchTerm() {
    const queryParams = this.model.get("queryParams");
    const searchTerm = queryParams["g"];
    return searchTerm ? `${encodeURIComponent(searchTerm)}` : undefined;
  }

  isFullTextSearch() {
    const queryParams = this.model.get("queryParams");
    return queryParams["full-text"] && decodeURIComponent(queryParams["full-text"]) === "true";
  }

  get template() {
    return templateFacetedSearchResultsPage;
  }

  sync() {
    return this.syncCollection().then(() => {
      this.subPageView = this.addSubView(this.createSubPageView());
    });
  }

  syncCollection() {
    const pageIndex = this.model.get("pageIndex");
    this.collection.state.currentPage = pageIndex;

    if (pageIndex > 0) {
      this.collection.links[pageIndex] = this.collection.url;
    }

    this.collection.queryParams = _.merge(
      {
        platform: () => this.deviceService.platform,
        limit: 20,
        offset: this.collection.state.currentPage * this.collection.state.pageSize,
      },
      this.model.get("queryParams"),
    );

    return this.collection
      .fetch()
      .cancellable()
      .then(o => {
        return o;
      });
  }

  syncFacetCollection() {
    this.facetCollection.queryParams = _.merge(
      {
        platform: () => this.deviceService.platform,
      },
      this.model.get("queryParams"),
    );

    return this.facetCollection
      .fetch()
      .cancellable()
      .then(o => {
        return o;
      });
  }

  render() {
    this.$el.html(this.template());

    this.menuView.attachTo($("#bb-faceted-search-results-menu-region")).render();

    this.subPageView.attachTo($("#bb-faceted-search-results-sub-page-region")).render();

    this.curationMultiSelectView.attachTo($("#bb-faceted-search-curation-multiselect-view")).render();

    this.renderFacets();

    return this;
  }

  renderFacets() {
    return Promise.resolve(this)
      .then(() => this.syncFacetCollection())
      .then(() => {
        this.menuView.update();
      });
  }

  showSubPageView(subPageView) {
    return this.showSubView("subPageView", subPageView, $("#bb-faceted-search-results-sub-page-region")).finally(
      value => {
        $(window).scrollTop(0);
        return value;
      },
    );
  }

  createSubPageView() {
    let subPage;

    let presentationType = this.model.get("presentationType");

    let pageViewOptions = {
      hasPreviousPage: this.collection.hasPreviousPage(),
      hasNextPage: this.collection.hasNextPage(),
      pageNumber: this.model.get("pageIndex") + 1,
      totalPages: Math.ceil(this.collection.totalRecords / this.collection.state.pageSize),
    };

    const searchTerm = this.getSearchTerm();
    const viewerParams =
      searchTerm && this.isFullTextSearch() ? `searchTerm=${encodeURIComponent(searchTerm)}` : undefined;

    if (presentationType === "list") {
      subPage = new PagerView(
        _.extend(
          {
            mediaView: new MediaListView({
              collection: this.collection,
              viewerParams: viewerParams,
            }),
          },
          pageViewOptions,
        ),
      );
    } else {
      subPage = new PagerView(
        _.extend(
          {
            mediaView: new MediaSalvattoreGridView({
              collection: this.collection,
              viewerParams: viewerParams,
              facetedSearchGrid: true,
            }),
          },
          pageViewOptions,
        ),
      );
    }

    subPage.on("pageSequenceChanged", this.goPageSequence, this);

    return subPage;
  }

  getPreviousPageUrl() {
    if (this.collection.hasPreviousPage()) {
      return this.getUrlFragment(this.collection.state.currentPage - 1);
    } else {
      return false;
    }
  }

  getNextPageUrl() {
    if (this.collection.hasNextPage()) {
      return this.getUrlFragment(this.collection.state.currentPage + 1);
    } else {
      return false;
    }
  }

  goPageSequence(pageSequence) {
    this.model.set("pageIndex", pageSequence - 1);
  }

  getBreadcrumbInfo() {
    return {
      breadcrumbModel: new BreadcrumbModel({
        text: '<i class="bbico bbico-search" aria-label="current search"></i>',
        path: "#" + getCurrentFragment(),
        topLevel: true,
      }),
      hide: true,
    };
  }

  trackSearchClick(model) {
    chan("tracking").trigger("search:click", {
      mediaId: model.get("id"),
    });
  }

  getDocumentTitle() {
    return "Search Results";
  }

  updateResults(model, pageIndex, reloadFacets) {
    // when query params change, links are reset by setting the mode. It
    // should already be infinite.
    this.collection.switchMode("infinite");

    chan("tracking").trigger("search");

    model.set("pageIndex", pageIndex);

    this.updateLocation();

    if (this.syncCollectionPromise && this.syncCollectionPromise.isPending()) {
      console.log("Cancelling pending search results collection sync");

      this.syncCollectionPromise.cancel();
    } else {
      chan("display").trigger("bodyViewLoading");
      this.model.set("loading", true);
    }

    this.syncCollectionPromise = this.syncCollection()
      .then(() => {
        if (reloadFacets) {
          // set the facet collection to null and re-render to show the loading placeholder
          console.log("Reload facets...");
          this.facetCollection.facets = null;
          this.menuView.update();
          this.renderFacets();
        } else {
          this.menuView.update();
        }
        this.showSubPageView(this.createSubPageView());

        chan("display").trigger("bodyViewLoaded");
        this.model.set("loading", false);
      })
      .catch(Promise.CancellationError, () => {
        console.log("Search results collection sync cancelled");
      })
      .catch(UnauthorizedSyncError, () => {
        console.log("Faceted search collection sync caught unauthorized error, logging user out...");
        return chan("security").request("logout", false);
      })
      .catch(error => {
        return chan("display").request("showErrorPage", error);
      });
  }
}

export default FacetedSearchResultsPageView;
