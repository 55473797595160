import Promise from "bluebird";
import AnthologyCollectionCollection from "scripts/collections/anthologyCollectionCollection";
import BaseView from "scripts/views/baseView";
import MediaListView from "scripts/views/mediaListView";
import MediaSalvattoreGridView from "scripts/views/mediaSalvattoreGridView";
import templateAnthologyPageCollectionsSubPage from "templates/anthologyPageCollectionsSubPage.hbs";

class AnthologyPageCollectionsSubPageView extends BaseView {
  get template() {
    return templateAnthologyPageCollectionsSubPage;
  }

  constructor(options) {
    super(options);

    this.anthologyModel = options.anthologyModel;

    const anthologyId = this.model.get("anthologyId");
    const collection = new AnthologyCollectionCollection([], {
      path: `/anthologies/${anthologyId}/collections`,
    });

    if (this.model.get("presentationType") === "masonry") {
      this.collectionsView = this.addSubView(
        new MediaSalvattoreGridView({
          collection: collection,
        }),
      );
    } else {
      this.collectionsView = this.addSubView(
        new MediaListView({
          collection: collection,
        }),
      );
    }
  }

  render() {
    this.$el.html(this.template(this.anthologyModel.toJSON()));

    this.collectionsView
      .appendTo($("#bb-anthology-page-collections-sub-page-content-region"))
      .render();

    return this;
  }

  sync() {
    return Promise.all([
      this.collectionsView.fetch(),
      this.anthologyModel.fetch(),
    ]);
  }
}

export default AnthologyPageCollectionsSubPageView;
