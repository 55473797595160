var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":9,"column":26},"end":{"line":9,"column":48}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":9,"column":49},"end":{"line":9,"column":64}}}) : helper)))
    + "/content/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":9,"column":73},"end":{"line":9,"column":93}}}) : helper)))
    + "/highlights\"\n                    class=\"content-filter\"\n                    aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"highlights") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"highlights") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":15,"column":26},"end":{"line":15,"column":48}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":15,"column":49},"end":{"line":15,"column":64}}}) : helper)))
    + "/content/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":15,"column":73},"end":{"line":15,"column":93}}}) : helper)))
    + "/articles\"\n                    class=\"content-filter\"\n                    aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"articles") : stack1)) != null ? lookupProperty(stack1,"arialabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"articles") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":21,"column":26},"end":{"line":21,"column":48}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":21,"column":49},"end":{"line":21,"column":64}}}) : helper)))
    + "/content/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":21,"column":73},"end":{"line":21,"column":93}}}) : helper)))
    + "/books\"\n                    class=\"content-filter\"\n                    aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"books") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"books") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"anthology") : depth0)) != null ? lookupProperty(stack1,"typeLabelLowerCase") : stack1), depth0))
    + "-"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":27,"column":59},"end":{"line":27,"column":81}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":27,"column":82},"end":{"line":27,"column":97}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyCollectionId") || (depth0 != null ? lookupProperty(depth0,"anthologyCollectionId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyCollectionId","hash":{},"data":data,"loc":{"start":{"line":27,"column":98},"end":{"line":27,"column":123}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":27,"column":124},"end":{"line":27,"column":144}}}) : helper)))
    + "/chapters\"\n                    class=\"content-filter\"\n                    aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"chapters") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"chapters") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":33,"column":26},"end":{"line":33,"column":48}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":33,"column":49},"end":{"line":33,"column":64}}}) : helper)))
    + "/content/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":33,"column":73},"end":{"line":33,"column":93}}}) : helper)))
    + "/other-documents\"\n                    class=\"content-filter\"\n                    aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"documents") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"documents") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":39,"column":26},"end":{"line":39,"column":48}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":39,"column":49},"end":{"line":39,"column":64}}}) : helper)))
    + "/content/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":39,"column":73},"end":{"line":39,"column":93}}}) : helper)))
    + "/images\"\n                    class=\"content-filter\"\n                    aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"images") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"images") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":45,"column":26},"end":{"line":45,"column":48}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":45,"column":49},"end":{"line":45,"column":64}}}) : helper)))
    + "/content/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":45,"column":73},"end":{"line":45,"column":93}}}) : helper)))
    + "/audio\"\n                    class=\"content-filter\"\n                    aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"audio") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"audio") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":51,"column":26},"end":{"line":51,"column":48}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":51,"column":49},"end":{"line":51,"column":64}}}) : helper)))
    + "/content/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":51,"column":73},"end":{"line":51,"column":93}}}) : helper)))
    + "/audiobooks\"\n                    class=\"content-filter\"\n                    aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"audiobook") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"audiobook") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":57,"column":26},"end":{"line":57,"column":48}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":57,"column":49},"end":{"line":57,"column":64}}}) : helper)))
    + "/content/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":57,"column":73},"end":{"line":57,"column":93}}}) : helper)))
    + "/videos\"\n                    class=\"content-filter\"\n                    aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"videos") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"videos") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div aria-labelledby=\"bb-anthology-page-nav-item-content-link\">\n    <div class=\"container\">\n        <div class=\"row m-0 p-0 m-b-40\">\n            <a id=\"bb-anthology-page-content-sub-page-all-content-link\"\n                href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":5,"column":23},"end":{"line":5,"column":45}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":5,"column":46},"end":{"line":5,"column":61}}}) : helper)))
    + "/content/"
    + alias4(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":5,"column":70},"end":{"line":5,"column":90}}}) : helper)))
    + "\" class=\"content-filter\"\n                aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"all") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"><div>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"all") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</div></a>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"highlightCount") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":12,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"articleCount") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"bookCount") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":24,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"chapterCount") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":30,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"otherDocumentCount") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":36,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"imageCount") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":42,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"audioCount") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":12},"end":{"line":48,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"audiobookCount") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":12},"end":{"line":54,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"videoCount") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":12},"end":{"line":60,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n\n    <div id=\"bb-anthology-page-content-sub-page-content-region\"></div>\n</div>";
},"useData":true});