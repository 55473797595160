import _ from "lodash";
import L from "lodash/fp";
import Promise from "bluebird";
import Backbone from "backbone";
import { getCurrentFragment } from "scripts/utils/routerHelpers";

const chan = Backbone.Radio.channel;

class BaseView extends Backbone.View {
  constructor(...args) {
    super(...args);

    this.on("cascadedAttach", this.checkForDomRefresh, this);
  }

  isFixedMainMenuLayout() {
    return process.env.PATRON_UI_FIXED_MAIN_MENU_LAYOUT === "true";
  }

  close() {
    //console.debug('Closing view: ' + this.constructor.name);

    _.each(this.subViews, subView => {
      if (subView.close) {
        subView.close();
      }
    });

    this.$el.empty();
    this.$el.remove();

    this.off();

    if (this.model) {
      this.model.off(null, null, this);
    }

    if (this.collection) {
      this.collection.off(null, null, this);
    }

    Backbone.Radio.off(null, null, this);
    Backbone.Radio.stopReplying(null, null, this);
    Backbone.Radio.stopComplying(null, null, this);

    return this;
  }

  addSubView(subView) {
    //        console.debug('Adding sub view: ' + subView.name + ' to view: ' + this.name);

    if (!this.subViews) {
      this.subViews = [];
    }

    if (_(this.subViews).indexOf(subView) === -1) {
      this.subViews.push(subView);
    }

    return subView;
  }

  addSubViews(subViews) {
    if (!L.isNil(subViews) && !L.isEmpty(subViews)) {
      subViews.forEach(subView => {
        this.addSubView(subView);
      });
    }

    return subViews;
  }

  appendSubView(subView, $region) {
    return this.showSubView(void 0, subView, $region);
  }

  replaceSubView(subViewName, subView) {
    return this.showSubView(
      subViewName,
      subView,
      this[subViewName].$el.parent(),
    );
  }

  showSubView(subViewName, subView, $region) {
    return subView
      .fetch()
      .cancellable()
      .then(() => {
        let oldSubView;

        if (subViewName) {
          oldSubView = this[subViewName];
          this[subViewName] = subView;
        }

        if (oldSubView) {
          this.closeSubView(oldSubView);
        }

        this.addSubView(subView).attachTo($region).render();

        return subView;
      })
      .catch(error => {
        subView.close();
        throw error;
      });
  }

  renderSubView(subView) {
    return this.addSubView(subView).render();
  }

  //TODO: remove this method and replace with closeSubViews
  closeSubView(subView) {
    if (!L.isNil(subView)) {
      this.subViews = _.without(this.subViews, subView);
      return subView.close();
    }
  }

  closeSubViews(subViews) {
    if (!L.isNil(subViews) && !L.isEmpty(subViews)) {
      subViews = [].concat(subViews);

      _.each(subViews, subView => {
        subView.close();
      });

      this.subViews = _.xor(this.subViews, subViews);
    }
  }

  fetch() {
    return this.sync().then(() => {
      this.trigger("sync", this);
      return this;
    });
  }

  sync() {
    return Promise.resolve(this);
  }

  renderOnDomAttach() {
    this.off("dom:refresh", this.render, this); // don't let these pile up if this gets called repeatedly

    if (this.isDomAttached()) {
      this.render();
    } else {
      this.once("dom:refresh", this.render, this);
    }

    return this;
  }

  /**
   * @param eventNames... var args event names
   * @param [sources...] optional let args event sources to listen to, if empty, sources = all sub views
   * @returns this
   */
  bubble() {
    const eventNames = _.filter(arguments, _.isString);
    let sources = _.filter(arguments, arg => {
      return !_.isString(arg);
    });

    if (!sources.length) {
      sources = this.subViews;
    }

    _.each(eventNames, eventName => {
      _.each(sources, source => {
        source.on(
          eventName,
          (...args) => {
            const triggerArgs = [eventName].concat(
              Array.prototype.slice.call(args),
            );
            this.trigger(...triggerArgs);
          },
          source,
        );
      });
    });

    return this;
  }

  isDomAttached() {
    return $.contains(document.documentElement, this.$el[0]);
  }

  attachTo($region) {
    return this.appendTo($region);
  }

  appendTo($region) {
    $region.append(this.$el);
    this.cascadeAttach(this);
    return this;
  }

  prependTo($region) {
    $region.prepend(this.$el);
    this.cascadeAttach(this);
    return this;
  }

  htmlOf($region) {
    $region.html(this.$el);

    this.cascadeAttach(this);
    return this;
  }

  checkForDomRefresh(ancestorView) {
    if (this.isDomAttached()) {
      this.trigger("dom:refresh", ancestorView);
    }
  }

  cascadeAttach(ancestorView) {
    this.trigger("cascadedAttach", ancestorView);

    _.each(this.subViews, subView => {
      subView.cascadeAttach(ancestorView);
    });
  }

  initialScrollTop() {
    let lastNavigationDirection = chan("history").request(
      "lastNavigationDirection",
    );

    if (lastNavigationDirection === "back") {
      return chan("history").request("lastScrollTop", getCurrentFragment());
    } else {
      return 0;
    }
  }

  onPresentationTypeChangeSaveUserPreference(model, newPresentationType) {
    chan("preferences").request("set", "contentView", newPresentationType);
  }

  delegateEventsRecursively() {
    this.delegateEvents();

    _.each(this.subViews, subView => {
      subView.delegateEventsRecursively();
    });
  }

  findTarget(targetName) {
    return this.$el.find(`.target-${targetName}`);
  }

  /**
   * This resets the tab position to the first h1 found on the page. This is
   * mainly to help tab/screen readers avoid repeating the menu over and over
   * as the pages are navigated.
   */
  resetTabPosition() {
    const firstItem = this.$el.find("h1")[0];
    if (firstItem) {
      firstItem.focus();
    }
  }
}

export default BaseView;
