import Backbone from "backbone";
import inject from "scripts/ioc/inject";
import BaseMediaSalvattoreTileView from "scripts/views/baseMediaSalvattoreTileView";

import templateAlbumSalvattoreTile from "templates/albumSalvattoreTile.hbs";

const chan = Backbone.Radio.channel;

class AlbumSalvattoreTileView extends BaseMediaSalvattoreTileView {
  constructor(options, securityService = inject("securityService")) {
    super(options, securityService);
  }

  get template() {
    return templateAlbumSalvattoreTile;
  }

  onClickViewer() {
    if (this.model.has("referringParent")) {
      this.saveReferringParent();
    }

    chan("display").request("playAudio", this.model);
    chan("tracking").trigger("viewer:click", this.model);
    return false;
  }
}

export default AlbumSalvattoreTileView;
