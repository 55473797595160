var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2 class=\"text-center m-b-40\">\n    <a href=\"/curation/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":23},"end":{"line":2,"column":29}}}) : helper)))
    + "\"\n        class=\"bb-curation-salvattore-grid-display-name clr-gray-darker lnk-wrapper fade-on-hover\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":3,"column":99},"end":{"line":3,"column":108}}}) : helper)))
    + "</a>\n</h2>\n\n<div class=\"bb-media-salvattore-grid-region\"></div>\n\n<div class=\"text-center\">\n    <button\n        class=\"bb-curation-salvattore-grid-show-more-btn btn btn-outline-curations m-t-20 hide text-uppercase\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"preview") : stack1), depth0))
    + "</button>\n\n    <a class=\"bb-curation-salvattore-grid-see-all-btn fnt-16 fnt-serif m-t-20 d-block hide\" href=\"/curation/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":12,"column":108},"end":{"line":12,"column":114}}}) : helper)))
    + "\"><i\n            class=\"bbico bbico-search\"></i> "
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"explore") : stack1), depth0))
    + "</a>\n\n    <button\n        class=\"bb-curation-salvattore-grid-show-less-btn btn btn-outline btn-outline-curations m-t-20 hide text-uppercase\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"showLess") : stack1), depth0))
    + "</button>\n</div>";
},"useData":true});