import L from "lodash/fp";
import Promise from "bluebird";
import Backbone from "backbone";
import inject from "scripts/ioc/inject";
import BaseView from "scripts/views/baseView";
import LoaderView from "scripts/views/loaderView";
import {
  getCurrentFragment,
  redirectToFragment,
} from "scripts/utils/routerHelpers";

import templateMainNav from "templates/mainNav.hbs";
import templateMainNavLogoText from "templates/mainNavLogoText.hbs";
import templateMainNavLogoImage from "templates/mainNavLogoImage.hbs";

const chan = Backbone.Radio.channel;

const isAdvancedSearchRoute = () =>
  /^advanced-search/.test(getCurrentFragment());

const isHomeRoute = () => /^home/.test(getCurrentFragment());

class MainNavView extends BaseView {
  constructor(
    options,
    breadcrumbService = inject("breadcrumbService"),
    connectionService = inject("connectionService"),
    securityService = inject("securityService"),
    bookshelfService = inject("bookshelfService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);

    this.breadcrumbService = breadcrumbService;
    this.connectionService = connectionService;
    this.securityService = securityService;
    this.bookshelfService = bookshelfService;
    this.i18nextService = i18nextService;

    this.loaderView = this.addSubView(new LoaderView());

    this.numLoaderRequests = 0;

    if (this.isFixedMainMenuLayout()) {
      chan("breadcrumb").on(
        "update",
        () => this.toggleBackButtonVisibility(),
        this,
      );
    }

    chan("route").on("changed", () => {
      if (this.shouldHideSearch()) {
        this.hideSearchButton();
      } else {
        this.showSearchButton();
      }
    });
  }

  shouldHideSearch() {
    const user = this.securityService.getUser();
    const showNewHomepage =
      user.getActiveOrganizationDynamicProperty("showNewHomepage");

    return isAdvancedSearchRoute() || (isHomeRoute() && showNewHomepage);
  }

  get template() {
    return templateMainNav;
  }

  get events() {
    return {
      "click #bb-main-nav-logo-region a": "onClickBrandLogoRegion",
      "click #bb-main-nav-close-button": "onClickCloseButton",
      "click #bb-main-nav-back-button": "onClickBackButton",
      "click #bb-main-nav-menu-button": "onClickMenuButton",
      "click #bb-main-nav-search-button": "onClickSearchButton",
      "focusin #bb-main-nav-logo-region a": "onFocusInNavLogo",
      "focusout #bb-main-nav-logo-region a": "onFocusOutNavLogo",
    };
  }

  toggleBackButtonVisibility() {
    const $backButton = $("#bb-main-nav-back-button");

    if (this.breadcrumbService.getHistoryLength() > 1) {
      $backButton.removeClass("hide");
    } else {
      $backButton.addClass("hide");
    }
  }

  onFocusInNavLogo() {
    $("#bb-main-nav-logo-region").addClass("focus-visible");
  }

  onFocusOutNavLogo() {
    $("#bb-main-nav-logo-region").removeClass("focus-visible");
  }

  showBackButton() {
    $("#bb-main-nav-back-button").removeClass("hide");
  }

  hideBackButton() {
    $("#bb-main-nav-back-button").addClass("hide");
  }

  showCloseButton() {
    $("#bb-main-nav-close-button").removeClass("hide");
  }

  hideCloseButton() {
    $("#bb-main-nav-close-button").addClass("hide");
  }

  showMenuButton() {
    $("#bb-main-nav-menu-button").removeClass("hide");
  }

  hideMenuButton() {
    $("#bb-main-nav-menu-button").addClass("hide");
  }

  showSearchButton() {
    $("#bb-main-nav-search-button").removeClass("hide");
  }

  hideSearchButton() {
    $("#bb-main-nav-search-button").addClass("hide");
  }

  showButtons() {
    if (
      this.isFixedMainMenuLayout() &&
      this.breadcrumbService.getHistoryLength() > 1
    ) {
      this.showBackButton();
    }

    this.showMenuButton();

    if (!this.shouldHideSearch()) {
      this.showSearchButton();
    }

    this.hideCloseButton();
  }

  hideButtons() {
    this.hideBackButton();
    this.hideMenuButton();
    this.hideSearchButton();
    this.showCloseButton();
  }

  sync() {
    return this.getOrganizationLogoUrl()
      .then(organizationLogoUrl => {
        this.organizationLogoUrl = organizationLogoUrl;
      })
      .catch(error => {
        console.log("Error fetching organization logo url: %O", error);
      });
  }

  getOrganizationLogoUrl() {
    const user = this.securityService.getUser();
    const activeOrganizationId = user.getActiveOrganizationId();
    const remoteUrl = user.getActiveOrganizationBrandingLogoUrl();

    if (remoteUrl) {
      if (this.bookshelfService.isAvailable()) {
        return this.bookshelfService.serveOrganizationLogoUrl(
          remoteUrl,
          activeOrganizationId,
        );
      } else {
        return Promise.resolve(remoteUrl);
      }
    } else {
      return Promise.reject();
    }
  }

  renderOrganizationBranding() {
    const $logoRegion = $("#bb-main-nav-logo-region");

    if (this.organizationLogoUrl) {
      const orgLogoAlt = L.get(
        ["alt", "orgLogo"],
        this.i18nextService.getAttributes(),
      );

      $logoRegion.html(
        templateMainNavLogoImage({
          organizationLogoUrl: this.organizationLogoUrl,
          i18n: { orgLogoAlt },
        }),
      );
    } else {
      $logoRegion.html(
        templateMainNavLogoText({
          organizationName: this.securityService
            .getUser()
            .getActiveOrganizationName(),
        }),
      );
    }
  }

  renderLoader() {
    this.loaderView.htmlOf($("#bb-main-nav-logo-region")).render();
  }

  render() {
    this.$el.html(
      this.template({
        i18n: this.getTranslations(),
      }),
    );

    this.renderOrganizationBranding();

    if (this.shouldHideSearch()) {
      this.hideSearchButton();
    }

    return this;
  }

  getTranslations() {
    const attributes = this.i18nextService.getAttributes();
    const { menu, search } = this.i18nextService.getGlossary();

    const common = L.flowRight([
      L.pick(["skipToContent", "skipToFooter"]),
      L.get(["button"]),
    ])(this.i18nextService.getCommon());

    const ariaLabel = L.flowRight([
      L.pick([
        "closeMenu",
        "menuOpen",
        "searchOpen",
        "skipToContent",
        "skipToFooter",
      ]),
      L.get(["ariaLabel"]),
    ])(attributes);

    return {
      ariaLabel,
      common,
      menu: menu.singular,
      search: search.search.singular,
    };
  }

  showLoader() {
    this.numLoaderRequests++;

    if (this.numLoaderRequests === 1) {
      this.renderLoader();
      this.loaderView.startAnimation();
    }
  }

  hideLoader() {
    if (this.numLoaderRequests > 0) {
      this.numLoaderRequests--;
    }

    if (this.numLoaderRequests === 0) {
      this.renderOrganizationBranding();
      this.loaderView.stopAnimation();
    }
  }

  onClickBrandLogoRegion() {
    const isOnline = this.connectionService.getConnectionStatus().isOnline;

    //-- Close the menu if it's open
    this.trigger("close");

    if (!isOnline) {
      return false;
    }
  }

  onClickBackButton() {
    const lastPath = this.breadcrumbService.getLastPath();

    if (lastPath) {
      redirectToFragment(lastPath);
    }
  }

  onClickCloseButton() {
    this.trigger("close");
  }

  onClickMenuButton() {
    this.trigger("menu");
  }

  onClickSearchButton() {
    this.trigger("search");
  }
}

export default MainNavView;
