import domready from "domready";
import "scripts/packageExtensions";
import inject from "scripts/ioc/inject";
import WebApplicationConfiguration from "scripts/ioc/webApplicationConfiguration";
import App from "scripts/app";

domready(() => {
  WebApplicationConfiguration.configure();

  const googleAnalyticsService = inject("googleAnalyticsService");
  googleAnalyticsService.init();

  const app = new App();
  app.start();
});

if (module.hot) {
  const reload = () => {
    console.log("HMR, refreshing browser...");
    window.location.reload();
  };

  module.hot.accept("scripts/ioc/inject", reload);
  module.hot.accept("scripts/app", reload);
  module.hot.accept("scripts/ioc/webApplicationConfiguration", reload);
}
