import inject from "scripts/ioc/inject";

export default function(method, model, options) {
  const bookshelfService = inject("bookshelfService");
  const securityService = inject("securityService");
  const organizationId = securityService.getUser().getActiveOrganizationId();

  let promise;

  switch (method) {
    case "read":
      promise = bookshelfService.getAllContentModelDataOnBookshelf(
        organizationId,
      );
      break;
    case "create":
      throw new Error("Create not implemented.");
    case "update":
      throw new Error("Update not implemented.");
    case "delete":
      throw new Error("Delete not implemented.");
    default:
      throw new Error("Unknown method");
  }

  if (promise) {
    // Fire success or fail
    if (options && options.success) {
      promise.then(resp => {
        options.success(resp);
      });
    }

    if (options && options.error) {
      promise.catch(error => {
        options.error(error);
      });
    }
  }

  return promise;
}
