export default function(Model) {
  return class extends Model {
    fetch(...args) {
      return super
        .fetch(...args)
        .then(() => this.fetchHybridData())
        .then(data => this.parseHybridData(data))
        .then(() => this);
    }

    fetchHybridData() {}

    parseHybridData() {}
  };
}
