import _ from "lodash";
import BaseListTileView from "scripts/views/baseListTileView";
import template from "templates/albumTrackListTile.hbs";
import CompoundChildrenCollection from "scripts/collections/compoundChildrenCollection";

const chan = Backbone.Radio.channel;

class AlbumTrackListTileView extends BaseListTileView {
  get events() {
    return _.extend({}, super.events, {
      "click .btn-bookshelf": "onClickBookshelfButton",
      "click #bb-album-track-list-tile": "onClickAlbumTrackPlay",
    });
  }

  constructor(options) {
    super(options);

    this.albumModel = options.albumModel;
    this.isSearchTile = options.isSearchTile;
    this.tracksCollection = options.tracksCollection;

    if (this.bookshelfAvailable()) {
      this.initializeBookshelfDeps();
    }
  }

  render() {
    const modelData = this.model.toJSON();

    const templateData = _.merge(modelData, {
      isSearchTile: this.isSearchTile,
      i18n: super.getTranslations(modelData),
    });

    this.$el.html(template(templateData));

    this.initDetailButton();
    this.addContributors(this.model.get("contributors"));

    this.initFavoriteButton();
    this.initTooltips();

    return this;
  }

  async onClickAlbumTrackPlay() {
    if (this.tracksCollection) {
      chan("display").request("playAlbum", this.albumModel, this.model.get("childOrder"), this.tracksCollection);
    } else {
      const albumModel = new Backbone.Model(this.model.get("parentContent"));
      const tracksCollection = new CompoundChildrenCollection([], {
        mediaId: albumModel.get("id"),
      });
      await tracksCollection.fetch();
      chan("display").request("playAlbum", albumModel, this.model.get("childOrder"), tracksCollection);
    }
  }
}

export default AlbumTrackListTileView;
