import BaseService from "scripts/services/baseService";

class WebConnectionService extends BaseService {
  getConnectionStatus() {
    return {
      isOnline: true,
    };
  }

  isOnline() {
    return this.getConnectionStatus().isOnline;
  }
}

export default WebConnectionService;
