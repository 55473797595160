import Controller from "scripts/controllers/controller";
import AnthologyModel from "scripts/models/anthologyModel";
import { replaceAndLoadFragment } from "scripts/utils/routerHelpers";

class LegacyAnthologyUrlForwardController extends Controller {
  get route() {
    return "route:legacyAnthologyUrlForward";
  }

  replyRoute(route, appId) {
    return new AnthologyModel({
      id: appId,
    })
      .fetch()
      .then(anthologyModel => {
        replaceAndLoadFragment("/anthology/" + anthologyModel.get("id"));
      });
  }
}

export default LegacyAnthologyUrlForwardController;
