import _ from "lodash";
import { addQueryStringParams } from "scripts/utils/urlUtil";
import ApiSyncHoc from "scripts/hoc/apiSyncHoc";

const chan = Backbone.Radio.channel;

const ApiCollectionHoc = Collection => {
  return class extends ApiSyncHoc(Collection) {
    constructor(models, options) {
      super(models, options);

      if (options && options.path) {
        this.path = options.path;
      }

      this.queryStringParams =
        options && options.queryStringParams
          ? _.merge(
              {},
              this.defaultQueryStringParams,
              options.queryStringParams,
            )
          : this.defaultQueryStringParams;
    }

    get defaultQueryStringParams() {
      const defaultParams = {
        limit: "all",
      };

      const user = chan("security").request("user");
      const activeOrganizationId = user.getActiveOrganizationId();

      if (activeOrganizationId) {
        defaultParams["org-id"] = activeOrganizationId;
      }

      return defaultParams;
    }

    url() {
      if (this.path) {
        return addQueryStringParams(
          this.queryStringParams,
          this.apiUrlRoot + _.result(this, "path"),
        );
      } else {
        throw new Error("Tried to create URL but path is missing");
      }
    }
  };
};

export default ApiCollectionHoc;
