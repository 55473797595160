import Controller from "scripts/controllers/controller";
import DiagnosticsScreenView from "scripts/views/diagnosticsScreenView";

class DiagnosticsController extends Controller {
  get route() {
    return "route:diagnostics";
  }

  replyRoute() {
    return this.showScreenView(new DiagnosticsScreenView());
  }
}

export default DiagnosticsController;
