import _ from "lodash";
import L from "lodash/fp";
import inject from "scripts/ioc/inject";
import BaseView from "scripts/views/baseView";
import templateCurationMediaThumbnailTile from "templates/curationUploadThumbnailTile.hbs";

class CurationUploadThumbnailTileView extends BaseView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;
  }

  get className() {
    return `bb-curation-media-thumbnail-tile`;
  }

  get events() {
    return _.extend({}, super.events, {
      "change #bb-curation-upload-thumbnail-input": "onChangeUpload",
      "click #bb-curation-thumbnail-upload-cancel": "onCancelUpload",
    });
  }

  get template() {
    return templateCurationMediaThumbnailTile;
  }

  render() {
    const upload = L.get(
      ["curation", "upload"],
      this.i18nextService.getGlossary(),
    );

    const browse = L.get(
      ["actions", "browse"],
      this.i18nextService.getCommon(),
    );

    this.$el.html(
      this.template({
        i18n: { browse, upload },
      }),
    );

    return this;
  }

  onChangeUpload() {
    this.deselectAllThumbnails();
    this.toggleDisableThumbnails();

    const fileObject = this.$("#bb-curation-upload-thumbnail-input")[0]
      .files[0];
    const imageLoc = this.$("#bb-curation-upload-thumbnail-loaded");

    const reader = new FileReader();
    reader.onloadend = e => {
      imageLoc[0].src = reader.result;
      this.hideUploadArea();
      this.showUploadedImage();
    };

    reader.readAsDataURL(fileObject);
    this.model.set("file", fileObject);
    this.trigger("thumbnailUploaded", this.model);
    return false;
  }

  onCancelUpload() {
    this.$("#bb-curation-upload-thumbnail-loaded")[0].src = null;
    this.$("#bb-curation-upload-thumbnail-input")[0].value = null;

    this.model.set("file", null);
    this.hideUploadedImage();
    this.showUploadArea();
    this.reselectPrevious();
    this.toggleDisableThumbnails();
    this.trigger("thumbnailUploadCanceled", this.model);
  }

  showUploadedImage() {
    this.$("#bb-curation-upload-thumbnail-loaded").removeClass("hide");
    this.selectThumbnail();
  }

  hideUploadedImage() {
    this.$("#bb-curation-upload-thumbnail-loaded").addClass("hide");
    this.$el.find("img").removeClass("selected");
    this.$el.find(".bb-curation-media-thumbnail-checkbox").addClass("hide");
    this.$el.find(".bb-curation-media-thumbnail-cancel").addClass("hide");
  }

  hideUploadArea() {
    this.$("#bb-curation-thumbnail-upload").addClass("hide");
  }

  showUploadArea() {
    this.$("#bb-curation-thumbnail-upload").removeClass("hide");
  }

  reselectPrevious() {
    this.previousSelected.addClass(".selected");
    this.previousSelected
      .parent()
      .find(".bb-curation-media-thumbnail-checkbox")
      .removeClass("hide");
  }

  toggleDisableThumbnails() {
    const $siblings = this.$el.siblings();
    $siblings.find(".bb-curation-media-thumbnail-disabled").toggleClass("hide");
  }

  deselectAllThumbnails() {
    const $siblings = this.$el.siblings();
    this.previousSelected = $siblings.find(".selected");
    $siblings.find("img").removeClass("selected");
    $siblings.find(".bb-curation-media-thumbnail-checkbox").addClass("hide");
  }

  selectThumbnail() {
    this.$el.find("img").addClass("selected");
    this.$el.find(".bb-curation-media-thumbnail-checkbox").removeClass("hide");
    this.$el.find(".bb-curation-media-thumbnail-cancel").removeClass("hide");
  }
}

export default CurationUploadThumbnailTileView;
