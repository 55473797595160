import Backbone from "backbone";

class ProgressBarModel extends Backbone.Model {
  get defaults() {
    return {
      percent: 0,
      showCancelButton: true,
      isVisible: false,
    };
  }
}

export default ProgressBarModel;
