import Promise from "bluebird";
import BaseView from "scripts/views/baseView";
import CommentMasterView from "scripts/views/commentMasterView";
import CommentThreadView from "scripts/views/commentThreadView";
import DisqusModel from "scripts/models/disqusModel";
import CommentUserModel from "scripts/models/commentUserModel";
import CommentThreadModel from "scripts/models/commentThreadModel";
import L from "lodash/fp";
import inject from "scripts/ioc/inject";

import templateDisqusComments from "templates/disqusComments.hbs";

class DisqusCommentsView extends BaseView {
  get template() {
    return templateDisqusComments;
  }

  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.disqusModel = new DisqusModel({
      id: options.mediaId,
    });

    this.errorSyncing = false;
  }

  sync() {
    return this.disqusModel
      .fetch()
      .bind(this)
      .then(() => {
        return Promise.all([this.initUserModel(), this.initThreadModel()]);
      })
      .then(() => {
        this.disqusModel.set(
          "disqusUsername",
          this.commentUserModel.get("username"),
        );
        this.disqusModel.set("disqusName", this.commentUserModel.get("name"));
        this.disqusModel.set(
          "disqusAvatar",
          this.commentUserModel.get("avatar"),
        );
      })
      .catch(error => {
        console.error("Failed to load Disqus comments: %O", error);

        this.errorSyncing = true;
      });
  }

  showError() {
    let $masterContainer = this.$el.find(".master-container");

    $masterContainer.removeClass("loading").addClass("error");

    $masterContainer.find(".comments-loading").hide();
    $masterContainer.find(".comments-loading-error").show();
  }

  initUserModel() {
    this.commentUserModel = new CommentUserModel({
      disqusModel: this.disqusModel,
    });

    if (this.disqusModel.has("disqusAuthentication")) {
      return this.commentUserModel.fetch();
    }
  }

  initThreadModel() {
    this.commentThreadModel = new CommentThreadModel({
      disqusModel: this.disqusModel,
    });

    return this.commentThreadModel.fetch();
  }

  render() {
    const i18n = this.getTranslations();

    this.$el.html(this.template({ i18n }));

    if (this.errorSyncing) {
      this.showError();
    } else {
      this.addSubView(
        new CommentMasterView({
          el: $(".master-container"),
          commentThreadModel: this.commentThreadModel,
        }),
      );

      this.addSubView(
        new CommentThreadView({
          el: $("#thread"),
          model: this.commentThreadModel,
        }),
      );
    }

    return this;
  }

  getTranslations() {
    const glossary = this.i18nextService.getGlossary();
    const common = this.i18nextService.getCommon();

    const disqus = L.get(["disqus"], glossary);
    const { actions, button } = common;

    return {
      disqus,
      cancel: button.cancel,
      close: actions.close,
      confirm: actions.confirm,
    };
  }
}

export default DisqusCommentsView;
