import Promise from "bluebird";
import Backbone from "backbone";
import BreadcrumbModel from "scripts/models/breadcrumbModel";
import BaseView from "scripts/views/baseView";
import { getCurrentFragment } from "scripts/utils/routerHelpers";

import templateModulesPage from "templates/modulesPage.hbs";

let chan = Backbone.Radio.channel;

class ModulesPageView extends BaseView {
  get template() {
    return templateModulesPage;
  }

  initialize(options) {
    this.subPageView = this.addSubView(options.subPageView);

    this.model.on(
      "change:presentationType",
      this.onPresentationTypeChangeSaveUserPreference,
      this,
    );

    chan("controllerView").reply(
      "modulesPageView",
      this.replyModulesPageView,
      this,
    );
  }

  replyModulesPageView() {
    return this;
  }

  render() {
    this.$el.html(this.template(this.model.toJSON()));

    this.subPageView
      .attachTo(this.$el.find("#bb-modules-page-sub-page-region"))
      .render();

    return this;
  }

  sync() {
    return this.subPageView.fetch();
  }

  //TODO: make common
  showSubPageView(subPageView) {
    if (this.subPageViewPromise && this.subPageViewPromise.isPending()) {
      this.subPageViewPromise.cancel();
    }

    chan("display").trigger("bodyViewLoading");

    this.subPageViewPromise = this.showSubView(
      "subPageView",
      subPageView,
      this.$el.find("#bb-modules-page-sub-page-region"),
    )
      .catch(Promise.CancellationError, () => {
        // swallow cancellation
        console.debug("show sub page view cancelled");
      })
      .finally(value => {
        chan("display").trigger("bodyViewLoaded");

        // if (this.navView.isStuck()) {
        //   $(window).scrollTop(this.navView.scrollTopToStick());
        // }

        return value;
      });

    return this.subPageViewPromise;
  }

  getBreadcrumbInfo() {
    return {
      breadcrumbModel: new BreadcrumbModel({
        text: "Modules",
        path: "#" + getCurrentFragment(),
        topLevel: true,
        id: "modulesPage",
      }),
    };
  }

  getDocumentTitle() {
    return "Modules";
  }
}

export default ModulesPageView;
