import BaseApiModel from "scripts/models/baseApiModel";

class CurationMediaCountModel extends BaseApiModel {
  url() {
    const mediaId = this.get("id");

    return `${this.apiUrlRoot}/curations/${mediaId}/type-summary`;
  }

  parse(response) {
    let data = {};

    data.allMediaCount = "_ALL" in response ? response._ALL : 0;
    data.moduleCount = "modules" in response ? response.modules : 0;
    data.anthologyCount = "anthologies" in response ? response.anthologies : 0;
    data.textbookCount = "textbooks" in response ? response.textbooks : 0;
    data.collectionCount = "collections" in response ? response.collections : 0;
    data.chapterCount = "chapters" in response ? response.chapters : 0;
    data.allContentCount = "_ALL" in response.content ? response.content._ALL : 0;
    data.contentHighlightCount = "_HIGHLIGHTS" in response.content ? response.content._HIGHLIGHTS : 0;
    data.articleCount = "ARTICLE" in response.content ? response.content.ARTICLE : 0;
    data.audioCount = "AUDIO" in response.content ? response.content.AUDIO : 0;
    data.audiobookCount = "AUDIOBOOK" in response.content ? response.content.AUDIOBOOK : 0;
    data.bookCount = "BOOK" in response.content ? response.content.BOOK : 0;
    data.chapterCount = "CHAPTER" in response.content ? response.content.CHAPTER : 0;
    data.imageCount = "IMAGE" in response.content ? response.content.IMAGE : 0;
    data.otherDocumentCount = "OTHER_DOCUMENT" in response.content ? response.content.OTHER_DOCUMENT : 0;
    data.videoCount = "VIDEO" in response.content ? response.content.VIDEO : 0;

    return data;
  }

  // Helper function to reduce an attr count by 1
  decrement(attr) {
    this.set(attr, this.get(attr) - 1);
  }
}

export default CurationMediaCountModel;
