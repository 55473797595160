import BaseApiModel from "scripts/models/baseApiModel";

class PurchaseLinkClickModel extends BaseApiModel {
  constructor(attrs, options) {
    super(attrs, options);

    this.mediaId = attrs.mediaId;
  }

  url() {
    return `${this.apiUrlRoot}/tracking/purchase-clicks/${this.mediaId}`;
  }
}

export default PurchaseLinkClickModel;
