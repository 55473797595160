import BaseFormView from "./baseFormView";
import ChangePasswordAuthenticationModel from "scripts/models/changePasswordAuthenticationModel";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";

import templateChangePasswordForm from "templates/changePasswordForm.hbs";

class ChangePasswordFormView extends BaseFormView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.oldPassword = options && options.oldPassword;

    this.bindModel(
      new ChangePasswordAuthenticationModel(
        {},
        {
          username: options && options.username,
          token: options && options.token,
        },
      ),
    );
  }

  get template() {
    return templateChangePasswordForm;
  }

  get events() {
    return {
      "blur form *[name]": "onBlurValidate",
      "submit form": "onSubmit",
    };
  }

  render() {
    const password = L.get(
      ["placeholder", "password"],
      this.i18nextService.getAttributes(),
    );
    const change = L.get(["button", "change"], this.i18nextService.getCommon());

    this.$el.html(this.template({ i18n: { password, change } }));

    if (this.oldPassword) {
      const $passwordField = $("#bb-change-password-form-old-password-field");

      $passwordField.attr("type", "hidden");
      $passwordField.val(this.oldPassword);
    }

    this.loaderView
      .appendTo(this.$el.find(".bb-form-submit-button-loader-region"))
      .render();

    return this;
  }

  onSubmitFailed(error) {
    this.enableSubmit(true);
    this.trigger("failure", error);
  }

  save() {
    this.clearErrors();
    return this.model.save(this.toJSON());
  }
}

export default ChangePasswordFormView;
