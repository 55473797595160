import BaseApiModel from "scripts/models/baseApiModel";

class RatingModel extends BaseApiModel {
  url() {
    const id = this.get("id");

    return `${this.apiUrlRoot}/media/${id}/votes`;
  }
}

export default RatingModel;
