import inject from "scripts/ioc/inject";
import BaseView from "scripts/views/baseView";
import Footer from "scripts/views/footerView";

import RouteInfo from "components/route/RouteInfo";
import RequestAccessForm from "components/leads/RequestAccessForm";
import { fetchOpenMediaMetadataFromApi } from "scripts/utils/fetchApi";
import { redirectToFragment } from "scripts/utils/routerHelpers";
import { thanksForSubmissionAlert } from "scripts/alerts/alerts";
import errorAlert from "scripts/alerts/errorAlert.js";

import styles from "./RequestAccessScreen.css";
import template from "./RequestAccessScreen.hbs";

class RequestAccessScreen extends BaseView {
  constructor(
    options,
    historyController = inject("historyController"),
    securityService = inject("securityService"),
  ) {
    super(options);

    this.historyController = historyController;
    this.securityService = securityService;

    this.state = {
      mediaId: options.mediaId,
    };

    this.template = () =>
      template({
        styles,
        ...this.state,
      });
  }

  sync() {
    return fetchOpenMediaMetadataFromApi(this.state.mediaId).then(media => {
      this.state.media = media;
    });
  }

  render() {
    this.$el.html(this.template());
    this.renderBanner();
    this.renderForm();
    this.renderFooter();

    return this;
  }

  renderBanner() {
    this.closeSubView(this.banner);

    const media = this.state.media;

    const title = media.title || media.displayName;
    const subtitle = media.subtitle || media.subName;
    const thumbnailUrl = media.thumbnailUrl || media.iconUrl;

    this.banner = new RouteInfo({
      title,
      subtitle,
      thumbnailUrl,
    });

    this.addSubView(this.banner)
      .appendTo(this.findTarget("bannerRegion"))
      .render();
  }

  renderForm() {
    this.closeSubView(this.form);

    this.form = new RequestAccessForm({
      mediaId: this.state.mediaId,
    });

    this.form.on("success", () => {
      thanksForSubmissionAlert.show().then(() => {
        // might need to make this last fragment or home at some point
        redirectToFragment("/home");
      });
    });

    this.form.on("failure", error => {
      errorAlert(error).show();
    });

    this.addSubView(this.form)
      .appendTo(this.findTarget("requestAccessFormRegion"))
      .render();
  }

  renderFooter() {
    this.closeSubView(this.footer);

    this.footer = this.addSubView(new Footer());
    this.footer.appendTo(this.findTarget("footerRegion")).render();
  }
}

export default RequestAccessScreen;
