import Backbone from "backbone";
import inject from "scripts/ioc/inject";
import growlNotification from "../functions/growlNotification";
import Velocity from "velocity-animate";
import RatingModel from "scripts/models/ratingModel";
import VoteModel from "scripts/models/voteModel";
import BaseView from "scripts/views/baseView";
import RatingModalView from "scripts/views/ratingModalView";
import SignInModalView from "scripts/views/signInModalView";
import LoaderView from "scripts/views/loaderView";

import templateRating from "templates/rating.hbs";

const chan = Backbone.Radio.channel;

class RatingView extends BaseView {
  get template() {
    return templateRating;
  }

  get events() {
    return {
      "click .bb-rating-container": "onClickRatingWidget",
    };
  }

  constructor(
    options,
    connectionService = inject("connectionService"),
    bookshelfService = inject("bookshelfService"),
    securityService = inject("securityService"),
  ) {
    super(options);

    this.connectionService = connectionService;
    this.bookshelfService = bookshelfService;
    this.securityService = securityService;

    if (this.isBookshelfAvailable()) {
      this.initializeBookshelfDeps();
    }

    this.ratingModel = new RatingModel({
      id: options.mediaId,
    });

    this.contentModel = options.contentModel;

    this.loaderView = this.addSubView(new LoaderView());
  }

  render() {
    this.$el.html(this.template(this.ratingModel.attributes));

    this.onConnectionStatusChange(this.connectionService.getConnectionStatus());

    this.highlightStars();

    const $bbRatingContainer = this.$el.find(".bb-rating-container");
    $bbRatingContainer.css("padding-left", "0");

    Velocity.animate(
        $bbRatingContainer.children(),
      "fadeIn",
      {
        queue: false,
        duration: 400,
      },
    );

    this.loaderView
      .appendTo(this.$el.find(".bb-rating-loader-region"))
      .render();

    return this;
  }

  sync() {
    return this.ratingModel.fetch();
  }

  initializeBookshelfDeps() {
    chan("connectionService").on(
      "status:change",
      this.onConnectionStatusChange,
      this,
    );
  }

  highlightStars() {
    // Star rating rounded to nearest 0.5
    const starMeanRoundedHalf =
      Math.round(this.ratingModel.get("mean") * 2) / 2;
    const wholeStars = Math.floor(starMeanRoundedHalf);
    const starClassNumber =
      starMeanRoundedHalf % 1 === 0 ? wholeStars : wholeStars + "half";
    const starClass = `bb-${starClassNumber}-star`;

    this.$el.find(".bb-rating-stars-container").addClass(starClass);
  }

  onClickRatingWidget() {
    if (this.isEnabled()) {
      const user = this.securityService.getUser();

      if (user.isOrganizationGuest()) {
        chan("display").request("showModal", new SignInModalView());
      } else {
        const ratingModalView = new RatingModalView({
          ratingModel: this.ratingModel,
          contentModel: this.contentModel,
        });

        ratingModalView.once("rateMedia", this.onRateMedia, this);

        chan("display").request("showModal", ratingModalView);
      }
    }

    return false;
  }

  onRateMedia(starRating) {
    console.log("Media rating: %d stars", starRating);

    chan("display").request("closeModal");
    this.showLoader();

    const voteModel = new VoteModel({
      id: this.ratingModel.get("id"),
      vote: starRating,
    });

    voteModel
      .save({}, { type: "post" })
      .then(() => this.onRatingSuccess())
      .catch(error => {
        console.log("Error rating content: %O", error);
        this.onRatingFailure();
      });
  }

  onRatingSuccess() {
    return this.ratingModel.fetch().then(() => this.render()); // Re-rendering hides the loader
  }

  onRatingFailure() {
    this.hideLoader();

    growlNotification("error", "Error rating media - try again");
  }

  showLoader() {
    this.$el
      .find(".bb-rating-container")
      .children()
      .not(".bb-rating-loader-region")
      .addClass("hide");

    this.$el.find(".bb-rating-loader-region").removeClass("hide");
    this.loaderView.startAnimation();
  }

  hideLoader() {
    this.$el
      .find(".bb-rating-container")
      .children()
      .not(".bb-rating-loader-region")
      .removeClass("hide");

    this.$el.find(".bb-rating-loader-region").addClass("hide");
    this.loaderView.stopAnimation();
  }

  isBookshelfAvailable() {
    return this.bookshelfService.isAvailable();
  }

  onConnectionStatusChange(connectionStatus) {
    if (connectionStatus.isOnline) {
      this.enable();
    } else {
      this.disable();
    }
  }

  isEnabled() {
    return !this.$el.find(".bb-rating-container").hasClass("disabled");
  }

  enable() {
    this.$el.find(".bb-rating-container").removeClass("disabled");
  }

  disable() {
    this.$el.find(".bb-rating-container").addClass("disabled");
  }
}

export default RatingView;
