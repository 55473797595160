import L from "lodash/fp";
import Backbone from "backbone";
import inject from "scripts/ioc/inject";
import BaseView from "scripts/views/baseView";
import scrollToElement from "scripts/functions/scrollToElement";
import SelectableOrganizationCard from "components/authentication/SelectableOrganizationCard";
import PatronVerificationModalView from "scripts/views/patronVerificationModalView";
import errorAlert from "scripts/alerts/errorAlert";
import EzProxyRedirectAlert from "scripts/alerts/ezProxyRedirectAlert";
import { sequencep } from "bluebird-promisell";
import { fetchOrganizationVerificationsFromSecurity } from "scripts/utils/fetchSecurity";
import { redirectToFragment } from "scripts/utils/routerHelpers";
import { oAuthRedirectUrl, parseOAuthParamsFromUrl } from "scripts/utils/urlUtil";

import {
  alert,
  geoLocationErrorAlert,
  noAdditionalLibrariesAlert,
  foundAdditionalLibrariesUsingLocation,
} from "scripts/alerts/alerts";

import { propEq } from "scripts/utils/generalHelpers";

import { USE_LOCATION_FOR_ORG_AUTH, getOrganizationsWithHighestCredentialType } from "scripts/utils/securityHelpers";

import { isPublicOrganization, getOrganizationsInfoFromUsers } from "scripts/utils/userHelpers";

import styles from "./ManageOrganizations.css";
import template from "./ManageOrganizations.hbs";

const LIBRARY_FQDN = process.env.PATRON_UI_LIBRARY_FQDN;
const chan = Backbone.Radio.channel;

// getOrganizationIdsFromInfo :: [OrganizationInfo] -> [string]
const getOrganizationIdsFromInfo = L.map(L.path(["organization", "organizationId"]));

class ManageOrganizations extends BaseView {
  constructor(
    options,
    deviceService = inject("deviceService"),
    securityService = inject("securityService"),
    userOrganizationPreferencesService = inject("userOrganizationPreferencesService"),
  ) {
    super(options);

    this.deviceService = deviceService;
    this.securityService = securityService;
    this.userOrganizationPreferencesService = userOrganizationPreferencesService;

    this.state = {
      organizationVerifications: [],
      organizationsInfo: [],
    };

    this.template = () =>
      template({
        styles,
        showGeo: !USE_LOCATION_FOR_ORG_AUTH,
        ...this.state,
      });
  }

  get events() {
    return {
      "click .target-geoButton": "onClickGeoButton",
      "change .target-libraryList": "onOrganizationSelected",
      "click .target-doneButton": "onDone",
      "focusin a.chosen-single": "onFocusInChosen",
      "focusout a.chosen-single": "onFocusOutChosen",
    };
  }

  sync() {
    return sequencep([this.syncOrganizationVerifications(), this.syncOrganizationsInfo()]);
  }

  syncOrganizationVerifications() {
    return fetchOrganizationVerificationsFromSecurity().then(organizationVerifications => {
      console.log("organization verifications: %O", organizationVerifications);
      this.state.organizationVerifications = organizationVerifications;
    });
  }

  syncOrganizationsInfo() {
    return this.updateOrganizationUser(this.securityService.fetchOrganizationUser());
  }

  updateOrganizationUser(organizationUserP) {
    const user = this.securityService.getUser();

    return organizationUserP
      .then(organizationUser => [user, organizationUser])
      .then(getOrganizationsInfoFromUsers(user))
      .then(organizationsInfo => {
        console.log("organizations info: %O", organizationsInfo);
        this.state.organizationsInfo = organizationsInfo;
      });
  }

  onFocusInChosen() {
    this.$el.find(".chosen-container").addClass("chosen-container-focus-visible");
  }

  onFocusOutChosen() {
    this.$el.find(".chosen-container").removeClass("chosen-container-focus-visible");
  }

  onFocusOutHideList() {
    this.findTarget("libraryList").trigger("chosen:close");
  }

  onClickGeoButton() {
    chan("display").request("showBlockingLoader", 0);

    this.securityService
      .fetchOrganizationUserUsingGeo()
      .then(geoUser => {
        const geoUserOrganizationIds = geoUser.getOrganizationIds();

        const currentOrganizationIds = getOrganizationIdsFromInfo(this.state.organizationsInfo);

        const newOrganizationIds = L.filter(
          L.complement(L.contains(L.__, currentOrganizationIds)),
          geoUserOrganizationIds,
        );

        if (newOrganizationIds.length > 0) {
          foundAdditionalLibrariesUsingLocation.show();

          return this.associateUserWithOrganizations(geoUser.getToken(), newOrganizationIds);
        } else {
          return noAdditionalLibrariesAlert.show();
        }
      })
      .catch(error => {
        console.log("Error fetching org user with geo: %O", error);
        return geoLocationErrorAlert(this.deviceService).show();
      })
      .finally(() => {
        chan("display").request("hideBlockingLoader");
      });
  }

  onOrganizationSelected(event) {
    const selectedOrganizationId = event.target.value;

    const { organizationId, organizationName, pinRequired, proxyPrefix } = L.find(
      propEq("organizationId", selectedOrganizationId),
      this.state.organizationVerifications,
    );

    if (proxyPrefix) {
      this.showEzProxyRedirectAlert(organizationId, organizationName, proxyPrefix);
    } else {
      this.showPatronVerificationModal(organizationId, organizationName, pinRequired);
    }

    return false;
  }

  showEzProxyRedirectAlert(organizationId, organizationName, proxyPrefix) {
    const user = this.securityService.getUser();
    const oAuthParams = parseOAuthParamsFromUrl(window.location.href);

    const platform = this.deviceService.platform;

    const qurl =
      platform !== "WEB"
        ? `${LIBRARY_FQDN}/associate-organization/${organizationId}/hybridapp`
        : `${window.location.origin}/associate-organization/${organizationId}`;

    const qurlParams = {
      token: user.getToken(),
      ...oAuthParams,
    };

    const alert = new EzProxyRedirectAlert({
      organizationName,
      proxyPrefix,
      qurl,
      qurlParams,
      target: platform !== "WEB" ? "_blank" : "_self",
    });

    alert.show();
  }

  showPatronVerificationModal(organizationId, organizationName, pinRequired) {
    const modal = new PatronVerificationModalView({
      organizationId,
      organizationName,
      pinRequired,
    });

    modal.on("success", ({ organizationUser, patronId }) => {
      alert({
        title: "Validated!",
        text: `${organizationName} has been added to your profile`,
        type: "success",
        showCancelButton: false,
        confirmButtonText: "OK",
      }).show();

      const token = organizationUser.getToken();
      const organizationIds = L.map(L.prop("organizationId"), organizationUser.getOrganizations());

      this.associateUserWithOrganizations(token, organizationIds, patronId);

      this.findTarget("libraryList").val("").trigger("chosen:updated");
    });

    modal.on("failure cancel", () => {
      this.findTarget("libraryList").val("").trigger("chosen:updated");
    });

    chan("display").request("showModal", modal);
  }

  onDone() {
    const user = this.securityService.getUser();

    const oAuthParams = parseOAuthParamsFromUrl(window.location.href);

    if (L.isEmpty(oAuthParams)) {
      const createProfileRouteProps = JSON.parse(window.sessionStorage.getItem("create-profile-initial-route-props"));

      if (createProfileRouteProps && createProfileRouteProps.route !== "whichOrganization") {
        redirectToFragment(createProfileRouteProps.fragment);
        window.sessionStorage.removeItem("create-profile-initial-route-props");
      } else {
        // will go home if no history
        redirectToFragment("/home");
      }
    } else {
      window.location.href = oAuthRedirectUrl(oAuthParams, user.getToken());
    }
  }

  render() {
    this.$el.html(this.template());

    this.tooltips();

    this.renderOrganizationCards();

    this.findTarget("libraryList")
      .chosen({
        width: "100%",
        search_contains: true,
        place_holder_text_single: "Select your library",
      })
      .on("chosen:showing_dropdown", () =>
        this.$el.find(".chosen-container").addClass("chosen-container-active-focus-visible"),
      )
      .on("chosen:hiding_dropdown", () =>
        this.$el.find(".chosen-container").removeClass("chosen-container-active-focus-visible"),
      );

    setTimeout(() => {
      scrollToElement($("#bb-authentication-screen-body-region").get(0));
    }, 10);

    return this;
  }

  tooltips() {
    // set up to NOT initialize for tablets...just caused too many conflicts
    if (chan("display").request("isLargerThanSm")) {
      this.$el.find('*[data-toggle="tooltip"]').tooltip({
        placement: "top",
        trigger: "hover",
        delay: 350,
        container: "body",
      });
    }
  }

  renderOrganizationCards() {
    this.closeSubViews(this.organizationCards);

    const { organizationsInfo } = this.state;

    this.organizationCards = L.map(
      organizationInfo =>
        new SelectableOrganizationCard({
          organizationInfo,
          isPublic: isPublicOrganization(organizationInfo.organization),
        }),
      organizationsInfo,
    );

    this.organizationCards.forEach(card => {
      this.addSubView(card).appendTo(this.findTarget("cardsRegion")).render();

      card.on("click", card => this.handleOrganizationCardClick(card));
    });
  }

  handleOrganizationCardClick(card) {
    const { isPublic, isAssociatedWithUser, organization, token } = card.state;

    if (isPublic) {
      alert({
        title: "BiblioBoard Public can't be removed",
        text: "You can remove other libraries, but you cannot remove BiblioBoard public from your profile.",
        type: "info",
      }).show();
    } else if (isAssociatedWithUser) {
      alert({
        title: "Remove library?",
        text: `Are you sure you want to remove "${organization.name}" from your profile?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, remove",
        dangerMode: true,
      })
        .show()
        .then(() => this.disassociateUserFromOrganization(token, organization.organizationId))
        .catch(() => {});
    } else {
      this.associateUserWithOrganizations(token, [organization.organizationId]);
    }
  }

  associateUserWithOrganizations(token, organizationIds, patronId) {
    chan("display").request("showBlockingLoader", 0);

    return this.securityService
      .associateUserWithOrganizations(token, organizationIds, patronId)
      .then(() => {
        const user = this.securityService.getUser();
        user.setActiveOrganizationId(L.head(organizationIds));
        return this.securityService.setUser(user);
      })
      .then(() => this.syncOrganizationsInfo())
      .then(() => this.renderOrganizationCards())
      .catch(error => errorAlert(error).show())
      .finally(() => {
        chan("display").request("hideBlockingLoader");
      });
  }

  disassociateUserFromOrganization(token, organizationId) {
    const user = this.securityService.getUser();

    chan("display").request("showBlockingLoader", 0);

    if (organizationId === user.getActiveOrganizationId()) {
      const newActiveOrganization = L.head(
        getOrganizationsWithHighestCredentialType(
          L.reject(propEq("organizationId", organizationId), user.getOrganizations()),
        ),
      );
      user.setActiveOrganizationId(newActiveOrganization.organizationId);
    }

    this.userOrganizationPreferencesService
      .removeOrg({
        id: user.getUserId(),
        orgId: organizationId,
        orgIds: L.map("organizationId", user.getOrganizations()),
        activeOrgId: user.getActiveOrganizationId(),
      })
      .then(() => {
        return this.securityService
          .setUser(user)
          .then(() => this.securityService.disassociateUserFromOrganizations(token, [organizationId]))
          .then(() => this.syncOrganizationsInfo())
          .then(() => this.renderOrganizationCards())
          .catch(error => {
            console.log("Showing error alert for error: %O", error);
            return errorAlert(error).show();
          })
          .finally(() => {
            chan("display").request("hideBlockingLoader");
          });
      });
  }
}

export default ManageOrganizations;
