import BaseView from "scripts/views/baseView";
import CommentView from "scripts/views/commentView";

class CommentsView extends BaseView {
  get tagName() {
    return "div";
  }

  constructor(options) {
    super(options);

    this.filter = (options && options.filter) || (() => true);

    this.listenTo(this.model, "add", this.render);
    this.listenTo(this.model, "remove", this.render);
    this.listenTo(this.model, "reset", this.render);
  }

  render() {
    this.$el.empty();
    this.model.each(this.renderComment.bind(this));

    // Prepend 'Load New Comments' button
    if (this.model.hasPrevious) {
      this.$el.prepend(
        $(document.createElement("button"))
          .text("Load New Comments")
          .addClass("comment-load-previous btn btn-primary"),
      );
    }

    // Append 'Load More Comments' button
    if (this.model.hasNext) {
      this.$el.append(
        $(document.createElement("button"))
          .text("Load More Comments")
          .addClass("comment-load-next btn btn-primary"),
      );
    }

    return this;
  }

  renderComment(comment) {
    if (this.filter(comment)) {
      const view = this.addSubView(new CommentView({ model: comment }));

      this.$el.append(view.render().el);
    }
  }
}

export default CommentsView;
