import _ from "lodash";
import Backbone from "backbone";
import PromisedSyncHoc from "scripts/hoc/promisedSyncHoc";

class SessionStorageModel extends PromisedSyncHoc(Backbone.Model) {
  get defaults() {
    return {
      id: "biblioboard-session",
    };
  }

  constructor(attributes, options) {
    super(attributes, options);

    this.localStorage = new Backbone.SessionStorage("sessionStorage");
  }

  clear() {
    this.localStorage.destroy(this);
    super.clear();
    return this.set(_.clone(this.defaults));
  }
}

export default SessionStorageModel;
