import BaseApiCollection from "scripts/collections/baseApiCollection";
import CitationModel from "scripts/models/citationModel";

class CitationCollection extends BaseApiCollection {
  get model() {
    return CitationModel;
  }
}

export default CitationCollection;
