import Backbone from "backbone";
import BaseListTileView from "scripts/views/baseListTileView";
import templateVideoListTile from "templates/videoListTile.hbs";

const chan = Backbone.Radio.channel;

class VideoListTileView extends BaseListTileView {
  get template() {
    return templateVideoListTile;
  }

  onClickViewer() {
    if (this.model.has("referringParent")) {
      this.saveReferringParent();
    }

    chan("display").request("playVideo", this.model);
    chan("tracking").trigger("viewer:click", this.model);
    return false;
  }
}

export default VideoListTileView;
