var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"faceted-search-facet-widget-header\">\n    "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"facetLabel") : stack1), depth0))
    + "\n</div>\n<div class=\"faceted-search-facet-widget-slider\">\n    <div class=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"classPrefix") || (depth0 != null ? lookupProperty(depth0,"classPrefix") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"classPrefix","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":31}}}) : helper)))
    + "-slider-range\"></div>\n\n    <div class=\"slider-selected-values\">\n        <div id=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"classPrefix") || (depth0 != null ? lookupProperty(depth0,"classPrefix") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"classPrefix","hash":{},"data":data,"loc":{"start":{"line":8,"column":17},"end":{"line":8,"column":32}}}) : helper)))
    + "-selected-min\" class=\"slider-value\"></div>\n        <div id=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"classPrefix") || (depth0 != null ? lookupProperty(depth0,"classPrefix") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"classPrefix","hash":{},"data":data,"loc":{"start":{"line":9,"column":17},"end":{"line":9,"column":32}}}) : helper)))
    + "-selected-max\" class=\"slider-value\"></div>\n    </div>\n</div>\n";
},"useData":true});