import BaseView from "scripts/views/baseView";
import CommentFormView from "scripts/views/commentFormView";
import CommentsView from "scripts/views/commentsView";
import CommentsFlagConfirmDialogView from "scripts/views/commentsFlagConfirmDialogView";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";

import CommentModel from "scripts/models/commentModel";

import templateComment from "templates/comment.hbs";

class CommentView extends BaseView {
  get tagName() {
    return "div";
  }

  get className() {
    return "comment";
  }

  get template() {
    return templateComment;
  }

  get eventMap() {
    return {
      "click .comment-toggle": "toggleComment",
      "click .comment-delete": "deleteComment",
      "click .comment-flag": "flagComment",
      "click .comment-vote-button": "submitVote",
      "click .comment-reply": "toggleReply",
      "submit .comment-new-form": "createReply",
      "submit .comment-edit-form": "updateComment",
      "click .comment-edit": "toggleEdit",
      "click .comment-edit-cancel": "toggleEdit",
    };
  }

  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.disqusModel = options.model.disqusModel;

    this.depth = (options && options.depth) || 0;

    this.listenTo(this.model, "change", this.render);

    this.eventList = [];
    for (let key in this.eventMap) {
      if (this.eventMap.hasOwnProperty(key)) {
        let parts = key.trim().match(/^(\S+)\s*(.*)$/);

        this.eventList.push({
          event: parts[1] + ".attached",
          selector: parts[2],
          callback: $.proxy(this[this.eventMap[key]], this),
        });
      }
    }
  }

  remove() {
    this.detachEvents();
  }

  render() {
    let voteClasses = {
      "-1": "dislike",
      0: "neutral",
      1: "like",
    };

    const { cancel, edit, reply, saveEdit } = L.get(
      ["button"],
      this.i18nextService.getCommon(),
    );

    const { comment } = this.i18nextService.getGlossary();

    this.$el.addClass("comment-" + this.model.get("commentId"));
    this.$el.html(
      this.template(
        L.merge(
          { i18n: { cancel, comment, edit, reply, saveEdit } },
          this.model.toJSON(),
        ),
      ),
    );
    this.$el
      .find(".comment-vote")
      .removeClass("like dislike neutral")
      .addClass(voteClasses[this.model.get("userScore")]);

    let replyView = this.addSubView(
      new CommentFormView({
        parentId: this.model.get("commentId"),
        disqusModel: this.disqusModel,
      }),
    );

    this.$el.find(".comment-reply-wrapper").html(replyView.render().el);

    this.attachEvents();

    // Render any child comments
    if (this.model.children && this.model.children.size() > 0) {
      let childrenView = this.addSubView(
        new CommentsView({
          model: this.model.children,
        }),
      );

      this.$el.append(
        childrenView.render().$el.addClass("comment-children clearfix"),
      );
    }

    return this;
  }

  attachEvents() {
    for (let i = 0; i < this.eventList.length; i++) {
      let event = this.eventList[i];

      this.$el.find(event.selector).on(event.event, event.callback);
    }
  }

  detachEvents() {
    for (let i = 0; i < this.eventList.length; i++) {
      let event = this.eventList[i];

      this.$el.find(event.selector).off(event.event);
    }
  }

  toggleComment() {
    this.$el.toggleClass("toggled");
  }

  deleteComment() {
    this.model.destroy({ wait: true });
  }

  flagComment() {
    $.ajax({
      url: `${process.env.PATRON_UI_DISQUS_URL_ROOT}/api/3.0/posts/report.json`,
      type: "post",
      data: {
        post: this.model.get("commentId"),
        api_key: this.disqusModel.get("disqusKey"),
      },

      success() {
        this.addSubView(
          new CommentsFlagConfirmDialogView({
            el: $("#comments-flag-confirm-modal"),
          }),
        ).render();
      },
    });
  }

  submitVote(event) {
    let context = this;
    let vote = 0;

    if ($(event.currentTarget).hasClass("comment-dislike")) {
      // Undo the dislike, or dislike the comment
      vote = this.model.get("userScore") === -1 ? 0 : -1;
    } else if ($(event.currentTarget).hasClass("comment-like")) {
      // Undo the like, or like the comment
      vote = this.model.get("userScore") === 1 ? 0 : 1;
    }

    $.ajax({
      url: `${process.env.PATRON_UI_DISQUS_URL_ROOT}/api/3.0/posts/vote.json`,
      type: "post",
      data: {
        vote: vote,
        post: this.model.get("commentId"),
        api_key: this.disqusModel.get("disqusKey"),
        remote_auth: this.disqusModel.get("disqusAuthentication"),
      },
      success: function (response) {
        context.model.set("score", response.response.post.points);
        context.model.set("userScore", response.response.vote);
      },
    });
  }

  toggleReply() {
    this.$el.toggleClass("reply");
  }

  createReply(event) {
    event.preventDefault();
    event.stopPropagation();
    let $form = $(event.currentTarget);
    let data = this.serializeObject($form);
    data.disqusModel = this.disqusModel;

    $form.get(0).reset();

    let comment = new CommentModel(data, { collection: this.model.collection });

    comment.save(undefined, {
      success: () => {
        this.model.collection.add(comment);
        this.model.children.add(comment);
      },
    });
  }

  toggleEdit(event) {
    event.preventDefault();

    this.$el.toggleClass("edit");
  }

  updateComment(event) {
    event.preventDefault();
    let $form = $(event.currentTarget);

    this.model.set("textMessage", this.serializeObject($form).textMessage);
    this.model.save();

    this.$el.removeClass("edit");
  }

  // Helper method to serialize form as JSON object
  serializeObject($form) {
    let object = {};
    let array = $form.serializeArray();

    $.each(array, function () {
      // Check if data for the given key already exists
      if (object[this.name] !== undefined) {
        // Check if the existing element is already an array
        if (!object[this.name].push) {
          // If not, convert it to an array
          object[this.name] = [object[this.name]];
        }

        // Add the additional data to the end of the array
        object[this.name].push(this.value || "");
      } else {
        // Set a plain K/V pair for the field name and data
        object[this.name] = this.value || "";
      }
    });

    // Ensure all multi-select fields are represented as an array in `object`
    $form
      .find("select")
      .filter("[multiple]")
      .each(function () {
        let name = $(this).attr("name");

        if (object[name] && !object[name].push) {
          object[name] = [object[name]];
        }
      });

    return object;
  }
}

export default CommentView;
