import _ from "lodash";
import Promise from "bluebird";
import Backbone from "backbone";
import HybridPageableMediaCollection from "scripts/collections/hybridPageableMediaCollection";
import AnthologyContentCountModel from "scripts/models/anthologyContentCountModel";
import BaseView from "scripts/views/baseView";
import MediaListView from "scripts/views/mediaListView";
import MediaSalvattoreGridView from "scripts/views/mediaSalvattoreGridView";
import PagerView from "scripts/views/ellipsisPagerView";
import StackedMediaTypesChartView from "scripts/views/stackedMediaTypesChartView";
import { redirectToFragment } from "scripts/utils/routerHelpers";
import mediaPageContentSubPageTranslations from "i18n/mediaPageContentSubPageTranslations";

import templateAnthologyPageContentSubPage from "templates/anthologyPageContentSubPage.hbs";
import { getContentCount } from "../utils/mediaHelpers";

// const PAGE_SIZE = 100;
const PAGE_SIZE = 20;

class AnthologyPageContentSubPageView extends BaseView {
  get template() {
    return templateAnthologyPageContentSubPage;
  }

  constructor(options) {
    super(options);

    this.anthologyModel = options.anthologyModel;

    this.anthologyContentCountModel = new AnthologyContentCountModel({
      id: this.model.get("anthologyId"),
    });

    this.mediaPageContentSubPageTranslations = new mediaPageContentSubPageTranslations();

    const anthologyId = this.model.get("anthologyId");
    const contentTypeUrlFragment = this.contentTypeUrlFragment(this.model.get("contentType"));

    const pageIndex = this.model.get("pageIndex");

    this.collection = new HybridPageableMediaCollection([], {
      path: `/anthologies/${anthologyId}/content${contentTypeUrlFragment}`,
      state: {
        currentPage: pageIndex,
        pageSize: PAGE_SIZE,
      },
    });

    /**
     * Hack to satisfy Backbone.PageableCollection._checkState
     * if starting from a page other than 0.
     */
    if (pageIndex > 0) {
      this.collection.links[pageIndex] = this.collection.url;
    }

    this.stackedMediaTypesChartView = this.addSubView(
      new StackedMediaTypesChartView({
        model: new Backbone.Model(),
      }),
    );
  }

  createContentView() {
    let subPage;

    const mediaCount = getContentCount(this.model.get("contentType"), this.anthologyContentCountModel.toJSON());
    const totalPages = Math.ceil(mediaCount / PAGE_SIZE);

    let pageViewOptions = {
      hasPreviousPage: this.collection.hasPreviousPage(),
      hasNextPage: this.collection.hasNextPage(),
      pageNumber: this.model.get("pageIndex") + 1,
      totalPages: totalPages,
    };

    let presentationType = this.model.get("presentationType");

    if (presentationType === "list") {
      subPage = new PagerView(
        _.extend(
          {
            mediaView: new MediaListView({
              collection: this.collection,
            }),
          },
          pageViewOptions,
        ),
      );
    } else {
      subPage = new PagerView(
        _.extend(
          {
            mediaView: new MediaSalvattoreGridView({
              collection: this.collection,
            }),
          },
          pageViewOptions,
        ),
      );
    }

    subPage.on(
      "pageSequenceChanged",
      pageNumber => {
        this.model.set("pageIndex", pageNumber - 1);
        redirectToFragment(this.urlFragment());
      },
      this,
    );

    return subPage;
  }

  render() {
    const contentCountModel = this.anthologyContentCountModel.toJSON();
    const model = this.model.toJSON();

    this.$el.html(
      this.template(
        _.merge(
          {
            i18n: this.mediaPageContentSubPageTranslations.getTranslations({
              modelMediaType: model.mediaType,
              mediaCountModel: contentCountModel,
            }),
          },
          this.model.toJSON(),
          contentCountModel,
          this.anthologyModel.toJSON(),
        ),
      ),
    );

    this.stackedMediaTypesChartView.model.set(this.mediaTypePercentages());

    this.stackedMediaTypesChartView
      .appendTo($("#bb-anthology-page-content-sub-page-stacked-media-types-chart-region"))
      .render();

    this.contentView.appendTo($("#bb-anthology-page-content-sub-page-content-region")).render();

    this.setActiveFilter();

    return this;
  }

  setActiveFilter() {
    let contentType = this.model.get("contentType");

    if (contentType === "all-content") {
      $("#bb-anthology-page-content-sub-page-all-content-link").addClass("active").attr("aria-current", "page");
    } else {
      let presentationType = this.model.get("presentationType");
      let anthologyId = this.model.get("anthologyId");
      let href = `"/anthology/${anthologyId}/content/${presentationType}/${contentType}"`;
      this.$el
        // .find("a[href$=" + contentType + "].content-filter")
        .find("a[href=" + href + "].content-filter")
        .addClass("active")
        .attr("aria-current", "page");
    }
  }

  percent(num, divisor) {
    return (num / divisor) * 100;
  }

  mediaTypePercentages() {
    let allContent = this.anthologyContentCountModel.get("allContentCount");

    let written = this.anthologyContentCountModel.get("articleCount");
    written += this.anthologyContentCountModel.get("bookCount");
    written += this.anthologyContentCountModel.get("otherDocumentCount");
    written += this.anthologyContentCountModel.get("chapterCount");

    let image = this.anthologyContentCountModel.get("imageCount");
    let audio = this.anthologyContentCountModel.get("audioCount");
    let video = this.anthologyContentCountModel.get("videoCount");

    return {
      writtenPercent: this.percent(written, allContent),
      imagePercent: this.percent(image, allContent),
      audioPercent: this.percent(audio, allContent),
      videoPercent: this.percent(video, allContent),
    };
  }

  sync() {
    return Promise.all([
      this.anthologyContentCountModel.fetch(),
      this.anthologyModel.fetch(),
      this.syncCollection(),
    ]).then(() => {
      this.contentView = this.addSubView(this.createContentView());
    });
  }

  syncCollection() {
    this.collection.state.currentPage = this.model.get("pageIndex");

    this.collection.queryParams = {
      limit: PAGE_SIZE,
      offset: this.collection.state.currentPage * this.collection.state.pageSize,
    };

    return this.collection
      .fetch()
      .cancellable()
      .then(o => o);
  }

  contentTypeUrlFragment(contentType) {
    if (contentType === "highlights") {
      return "?collectionHighlight=1";
    } else if (contentType === "all-content") {
      return "";
    } else {
      return "/" + contentType;
    }
  }

  urlFragment() {
    const anthologyId = this.model.get("anthologyId");
    const presentationType = this.model.get("presentationType");
    const contentType = this.model.get("contentType");
    const pageNumber = this.model.get("pageIndex") + 1;

    return `/anthology/${anthologyId}/content/${presentationType}/${contentType}/${pageNumber}`;
  }
}

export default AnthologyPageContentSubPageView;
