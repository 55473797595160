import { alert } from "scripts/alerts/alerts";
import Velocity from "velocity-animate";
import BaseView from "scripts/views/baseView";
import ResetPasswordFormView from "scripts/views/resetPasswordFormView";
import { redirectToFragment } from "scripts/utils/routerHelpers";
import templateResetPasswordPage from "templates/resetPasswordPage.hbs";

class ResetPasswordPageView extends BaseView {
  get template() {
    return templateResetPasswordPage;
  }

  constructor(options) {
    super(options);

    this.resetPasswordFormView = this.addSubView(new ResetPasswordFormView());

    this.resetPasswordFormView.on("success", this.onSuccess, this);
    this.resetPasswordFormView.on("failure", this.onFailure, this);
  }

  render() {
    this.$el.html(this.template());

    this.resetPasswordFormView
      .appendTo(
        this.$el.find("#bb-reset-password-page-reset-password-form-region"),
      )
      .render();

    Velocity.animate(
      this.$el.find("#bb-reset-password-page-reset-password-form-region"),
      "scroll",
      {
        duration: 1600,
        easing: "easeInOutQuint",
      },
    );

    return this;
  }

  onSuccess() {
    alert(
      "Password reset!",
      "Your password was reset, you're good to go!",
      "success",
    ).show();

    redirectToFragment("/home");
  }

  onFailure() {
    alert(
      "Oops...",
      "Something went wrong, your password wasn't reset.  The link may have expired.  You might want to give it another try.",
      "error",
    ).show();
  }

  initialScrollTop() {
    return "bottom";
  }
}

export default ResetPasswordPageView;
