import Backbone from "backbone";
import Promise from "bluebird";
import loadRecaptchaScript from "scripts/functions/loadRecaptchaScript";
import inject from "scripts/ioc/inject";
import BaseFormView from "scripts/views/baseFormView";
import BaseModel from "scripts/models/baseModel";

import templateLoginForm from "templates/loginForm.hbs";

const chan = Backbone.Radio.channel;

class LoginFormView extends BaseFormView {
  get template() {
    return templateLoginForm;
  }

  get recaptchaSiteKey() {
    return process.env.PATRON_UI_RECAPTCHA_SITE_KEY;
  }

  get constraints() {
    return {
      username: {
        presence: true,
      },
      password: {
        presence: true,
      },
    };
  }

  get events() {
    return {
      "click #bb-login-form-forgot-button": "onClickForgot",
      "submit form": "onSubmit",
    };
  }

  constructor(
    options,
    securityService = inject("securityService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);

    this.securityService = securityService;
    this.i18nextService = i18nextService;

    this.bindModel(
      new BaseModel(
        {},
        {
          constraints: this.constraints,
        },
      ),
    );
  }

  render() {
    const { ariaLabel, placeholder } = this.i18nextService.getAttributes();
    const { actions } = this.i18nextService.getCommon();
    const { auth } = this.i18nextService.getGlossary();
    this.$el.html(
      this.template({
        i18n: {
          explore: {
            ariaLabel: ariaLabel.explore.singular,
            singular: actions.explore.singular,
          },
          forgotPassword: auth.forgotPassword.question,
          placeholder,
        },
      }),
    );

    this.loaderView
      .appendTo(this.$el.find(".bb-form-submit-button-loader-region"))
      .render();

    this.focusUsernameField();

    return this;
  }

  focusUsernameField() {
    // Only focus on username field when it's visible on page load
    if (chan("display").request("isLargerThanXs")) {
      $("#bb-login-form-username-field").focus();
    }
  }

  renderRecaptcha() {
    window.grecaptcha.render("bb-login-form-recaptcha", {
      sitekey: this.recaptchaSiteKey,
      callback: recaptchaToken => {
        this.onRecaptchaVerified(recaptchaToken);
      },
    });
  }

  recaptchaRendered() {
    return !$("#bb-login-form-recaptcha").is(":empty");
  }

  onRecaptchaVerified(recaptchaToken) {
    this.recaptchaToken = recaptchaToken;
  }

  syncRecaptcha() {
    if (window.grecaptcha === void 0) {
      return loadRecaptchaScript();
    } else {
      return Promise.resolve();
    }
  }

  showRecaptcha() {
    chan("display").request("showBlockingLoader");

    this.syncRecaptcha()
      .bind(this)
      .catch(error => {
        console.error(
          "Something went wrong trying to load recaptcha libs: %O",
          error,
        );
      })
      .finally(() => {
        chan("display").request("hideBlockingLoader");
        this.renderRecaptcha();
      });
  }

  resetCaptcha() {
    if (window.grecaptcha) {
      window.grecaptcha.reset();
    }
  }

  onClickForgot() {
    this.trigger("forgot");
  }

  onSubmitFailed(error) {
    // Login failed and API is forcing CAPTCHA
    if (
      error &&
      error.xhr &&
      error.xhr.responseJSON &&
      error.xhr.responseJSON.captcha
    ) {
      if (this.recaptchaRendered()) {
        this.recaptchaToken = void 0;
        this.resetCaptcha();
      } else {
        this.showRecaptcha();
      }
    }

    this.enableSubmit(true);
    this.trigger("failure", error);
  }

  doSubmit() {
    return this.securityService.loginWithUsernameAndPassword({
      username: this.model.get("username"),
      password: this.model.get("password"),
      captcha: this.recaptchaToken,
    });
  }

  // Override to re-enable the submit button on success
  onSubmitSucceeded(value) {
    this.resetCaptcha(); // Remove Recaptcha iframe
    this.enableSubmit(true);
    this.trigger("success", value);
  }

  getPasswordFieldValue() {
    return $("#bb-login-form-password-field").val();
  }
}

export default LoginFormView;
