import Velocity from "velocity-animate";
import BaseView from "scripts/views/baseView";
import templateFileSize from "templates/fileSize.hbs";

class FileSizeView extends BaseView {
  get template() {
    return templateFileSize;
  }

  render(fadeIn) {
    this.$el.html(this.template(this.model.toJSON()));

    if (fadeIn) {
      Velocity.animate(this.$el.children(), "fadeIn", {
        queue: false,
        duration: 400,
      });
    }

    return this;
  }
}

export default FileSizeView;
