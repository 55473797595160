import BaseView from "scripts/views/baseView";
import ForbiddenSyncError from "scripts/exceptions/forbiddenSyncError";
import NotFoundSyncError from "scripts/exceptions/notFoundSyncError";
import SubscriptionSyncError from "scripts/exceptions/subscriptionSyncError";
import { pushFragment } from "scripts/utils/routerHelpers";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";

import templateErrorPage403 from "templates/errorPage403.hbs";
import templateErrorPage404 from "templates/errorPage404.hbs";
import templateErrorPage500 from "templates/errorPage500.hbs";
import templateErrorPage999 from "templates/errorPage999.hbs";

const chan = Backbone.Radio.channel;

class ErrorPageView extends BaseView {
  get events() {
    return {
      "click #bb-error-page-back-button": "onClickBack",
      "click #bb-error-page-home-button": "onClickHome",
    };
  }

  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    // Commenting this out, because the function doesn't exist [AS]
    //chan('display').on('window:resize', this.setMinHeightWinHeight, this);
  }

  render() {
    const template = this.templateForError();

    this.$el.html(
      template(
        L.merge(
          {
            i18n: this.getTranslations(),
          },
          this.model.toJSON(),
        ),
      ),
    );

    return this;
  }

  getTranslations() {
    const { app, auth, errorPage } = this.i18nextService.getGlossary();
    const { button } = this.i18nextService.getCommon();
    const error = L.get(["alt", "error"], this.i18nextService.getAttributes());

    return {
      biblioHome: auth.biblioHome,
      error,
      errorPage,
      support: app.support,
      takeMeBack: button.takeMeBack,
      visitHome: button.visitHome,
    };
  }

  templateForError() {
    const error = this.model.get("error");

    let template;

    if (error instanceof ForbiddenSyncError) {
      template = templateErrorPage403;
    } else if (error instanceof NotFoundSyncError) {
      template = templateErrorPage404;
    } else if (error instanceof SubscriptionSyncError) {
      template = templateErrorPage999;
    } else {
      chan("bugsnag").request("notify", error);
      template = templateErrorPage500;
    }

    return template;
  }

  onClickBack() {
    const previousFragment = chan("history").request("previousFragment");
    pushFragment(previousFragment ? previousFragment : "/home");
    window.location.reload();
    return false;
  }

  onClickHome() {
    pushFragment("/home");
    window.location.reload();
    return false;
  }
}

export default ErrorPageView;
