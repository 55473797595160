import _ from "lodash";
import Backbone from "backbone";
import BaseView from "scripts/views/baseView";
import templateMediaPlaylistRow from "templates/mediaPlaylistRow.hbs";

const chan = Backbone.Radio.channel;

class MediaPlaylistRowView extends BaseView {
  get template() {
    return templateMediaPlaylistRow;
  }

  get tagName() {
    return "tr";
  }

  get events() {
    return {
      click: "onClickRow",
    };
  }

  constructor(options) {
    super(options);

    chan("playlist").on("playlistItemChange", this.onPlaylistItemChange, this);
  }

  render() {
    const trackTitle = this.model.get("trackTitle");
    const title = this.model.get("title");
    const subtitle = this.model.get("subtitle");

    const rowTitle = trackTitle
      ? trackTitle
      : title.concat(subtitle ? ": " + subtitle : "");

    this.$el.html(this.template(_.merge(this.model.toJSON(), { rowTitle })));

    return this;
  }

  onClickRow() {
    this.trigger("playlistTrackClicked", this.model);
  }

  onPlaylistItemChange(mediaId) {
    if (this.model.get("id") === mediaId) {
      this.$el.addClass("selected");
    } else {
      this.$el.removeClass("selected");
    }
  }
}

export default MediaPlaylistRowView;
