import _ from "lodash";
import Backbone from "backbone";
import AuthenticationSyncHoc from "scripts/hoc/authenticationSyncHoc";

const chan = Backbone.Radio.channel;

const AuthenticationSecureSyncHoc = Model => {
  return class extends AuthenticationSyncHoc(Model) {
    syncOptions(options) {
      options = super.syncOptions(options);

      const token = this.token
        ? this.token
        : chan("security")
            .request("user")
            .getToken();

      _.merge(options, {
        headers: {
          "X-Auth-Token": token,
        },
      });

      return options;
    }
  };
};

export default AuthenticationSecureSyncHoc;
