import BaseView from "scripts/views/baseView";

class CommentDisplayNameDialogView extends BaseView {
  get events() {
    return {
      "click .btn-confirm": "confirm",
      "click .btn-cancel": "cancel",
    };
  }

  render() {
    this.$name = this.$el.find("#comments-display-name");
    this.$el.modal("show");

    return this;
  }

  remove() {
    this.$el.modal("hide");
  }

  confirm() {
    this.trigger("confirm", this.$name.val());
  }
}

export default CommentDisplayNameDialogView;
