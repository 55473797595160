import _ from "lodash";
import Backbone from "backbone";
import Promise from "bluebird";
import { parseQueryString } from "scripts/utils/urlUtil";
import Controller from "scripts/controllers/controller";
import fileSystemSync from "scripts/functions/fileSystemSync";
import inject from "scripts/ioc/inject";
import ContentModel from "scripts/models/contentModel";
import ImageBookViewerModel from "scripts/models/imageBookViewerModel";
import ImageViewerModel from "scripts/models/imageViewerModel";
import { pushFragment, replaceFragment } from "scripts/utils/routerHelpers";
import { getOptimizedPdfUrl } from "scripts/utils/ajaxUtil";

const chan = Backbone.Radio.channel;

class ViewerController extends Controller {
  static get defaults() {
    return {
      queryParams: [],
      presentationType: "single", // scrolling, grid, single
    };
  }

  constructor(
    connectionService = inject("connectionService"),
    bookshelfService = inject("bookshelfService"),
    fileSystemService = inject("fileSystemService"),
    securityService = inject("securityService"),
    contentDetailsController = inject("contentDetailsController"),
  ) {
    super();

    this.connectionService = connectionService;
    this.bookshelfService = bookshelfService;
    this.fileSystemService = fileSystemService;
    this.securityService = securityService;
    this.contentDetailsController = contentDetailsController;

    this.epubViewerInitialized = false;

    chan("viewerController").reply(
      "reloadViewer",
      mediaId => {
        return this.replyRoute("viewer", mediaId);
      },
      this,
    );
  }

  get route() {
    return "route:viewer";
  }

  routeAttrs(args) {
    let pageSequence;

    if (args[1] !== "last") {
      const parsedPageSequence = parseInt(args[1], 10);

      if (!isNaN(parsedPageSequence)) {
        pageSequence = parsedPageSequence;
      } else {
        pageSequence = null;
      }
    } else {
      pageSequence = "last";
    }

    const queryParams = parseQueryString(args[args.length -1]);

    const attrs = _.defaults(
      {
        mediaId: args[0],
        pageSequence,
        presentationType: args[2] || void 0,
        queryParams,
      },
      ViewerController.defaults,
    );

    let rewriteUrl = `/viewer/${attrs.mediaId}`;

    if (attrs.pageSequence && attrs.presentationType) {
      rewriteUrl = `/viewer/${attrs.mediaId}/${attrs.pageSequence}/${attrs.presentationType}`;
    } else if (attrs.pageSequence) {
      rewriteUrl = `/viewer/${attrs.mediaId}/${attrs.pageSequence}`;
    }

    replaceFragment(rewriteUrl);

    return attrs;
  }

  async replyRoute(route, ...args) {
    const attrs = this.routeAttrs(args);

    chan("display").request("showBlockingLoader");

    const contentModel = await this.createContentModel(attrs.mediaId);

    return contentModel
      .fetch()
      .then(() => {
        const contentType = contentModel.get("type");

        if (contentType === "AUDIO" || contentType === "VIDEO") {
          chan("display").request("hideBlockingLoader");

          return this.contentDetailsController
            .replyRoute("content", attrs.mediaId)
            .then(() => {
              pushFragment(`/content/${attrs.mediaId}`);

              // it would be nice if these commands returned a promise
              if (contentType === "VIDEO") {
                chan("display").request("playVideo", contentModel);
                //chan('tracking').trigger('viewer:click', this.mediaModel);
              } else {
                chan("display").request("playAudio", contentModel);
                //chan('tracking').trigger('viewer:click', this.mediaModel);
              }
            });
        } else {
          return this.createViewer(contentModel, attrs)
            .then(viewer => {
              chan("display").request("hideBlockingLoader");
              return chan("display").request("showScreenView", viewer);
            })
            .catch(e => {
              console.log("Unable to create viewer: %O", e);
              throw e;
            });
        }
      })
      .catch(error => {
        chan("display").request("hideBlockingLoader");
        throw error;
      });
  }

  async createViewer(contentModel, routeAttrs) {
    const contentType = contentModel.get("type");
    const mediaId = contentModel.get("id");

    if (contentModel.get("isEpub")) {
      return Promise.resolve(
        import(
          /* webpackChunkName: "epubViewer" */ "scripts/views/viewers/epubViewer/epubViewerView"
        ),
      ).then(
        ({ default: EpubViewerView }) =>
          new EpubViewerView({
            model: new Backbone.Model({
              mediaId: mediaId,
              queryParams: routeAttrs.queryParams,
            }),
            contentModel,
          }),
      );
    } else if (
      contentType === "BOOK" ||
      contentType === "CHAPTER" ||
      contentType === "OTHER_DOCUMENT" ||
      contentType === "ARTICLE"
    ) {
      const isPdfOnBookshelf = await this.isPdfOnBookshelf(mediaId);

      let optimizedPdfUrl = null;

      if (this.connectionService.getConnectionStatus().isOnline) {
        optimizedPdfUrl = await getOptimizedPdfUrl(contentModel);
      }

      if (isPdfOnBookshelf || optimizedPdfUrl) {
        return Promise.resolve(
          import(
            /* webpackChunkName: "pdfViewer" */ "scripts/views/viewers/pdfViewer/pdfViewerView"
          ),
        ).then(
          ({ default: PdfViewerView }) =>
            new PdfViewerView({
              pdfUrl: optimizedPdfUrl,
              model: new Backbone.Model({
                mediaId: routeAttrs.mediaId,
                pageSequence: routeAttrs.pageSequence,
                queryParams: routeAttrs.queryParams,
              }),
              contentModel,
            }),
        );
      } else {
        return Promise.resolve(
          import(
            /* webpackChunkName: "imageBookViewer" */ "scripts/views/viewers/imageBookViewer/imageBookViewerView"
          ),
        ).then(
          ({ default: ImageBookViewerView }) =>
            new ImageBookViewerView({
              model: new ImageBookViewerModel(routeAttrs),
              contentModel,
            }),
        );
      }
    } else if (contentType === "IMAGE") {
      return Promise.resolve(
        import(
          /* webpackChunkName: "imageViewer" */ "scripts/views/viewers/imageViewer/imageViewerView"
        ),
      ).then(
        ({ default: ImageViewerView }) =>
          new ImageViewerView({
            model: new ImageViewerModel(),
            contentModel,
          }),
      );
    } else {
      console.error("Unable to create viewer for content: %O", contentModel);
      throw new Error("Failed to create viewer for content");
    }
  }

  async createContentModel(mediaId) {
    const contentModel = new ContentModel({
      id: mediaId,
    });

    const organizationId = this.securityService
      .getUser()
      .getActiveOrganizationId();

    if (
      this.bookshelfService.isAvailable() &&
      (await this.bookshelfService.isOnBookshelf(mediaId, organizationId)) &&
      !this.connectionService.getConnectionStatus().isOnline
    ) {
      contentModel.filePath = this.bookshelfService.getContentModelFilePath(
        mediaId,
        organizationId,
      );
      contentModel.sync = fileSystemSync(this.fileSystemService);
    }

    return contentModel;
  }

  async isPdfOnBookshelf(mediaId) {
    if (this.bookshelfService.isAvailable()) {
      const organizationId = this.securityService
        .getUser()
        .getActiveOrganizationId();

      const isPdfOnBookshelf = await this.bookshelfService.isLocalPdfFileAvailableOnBookshelf(
        mediaId,
        organizationId,
      );

      return isPdfOnBookshelf;
    } else {
      return false;
    }
  }
}

export default ViewerController;
