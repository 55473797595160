var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"p-lr-20\">\n    <!--note title-->\n    <div class=\"row m-0 p-0 m-b-20\">\n        <label>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"note") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + " <span class=\"text-notransform fnt-bld clr-warning\">("
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"note") : stack1)) != null ? lookupProperty(stack1,"optional") : stack1), depth0))
    + ")</span></label>\n\n        <div class=\"form-group\">\n            <textarea name=\"title\" class=\"form-control input-outline\" rows=\"3\" maxlength=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"maxTitleLength") || (depth0 != null ? lookupProperty(depth0,"maxTitleLength") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"maxTitleLength","hash":{},"data":data,"loc":{"start":{"line":7,"column":90},"end":{"line":7,"column":108}}}) : helper)))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":7,"column":110},"end":{"line":7,"column":119}}}) : helper)))
    + "</textarea>\n        </div>\n    </div>\n    <!--///note title-->\n\n    <!--note-->\n    <div class=\"row m-0 p-0 m-b-20\">\n        <label>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"note") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</label>\n\n        <div class=\"form-group\">\n            <textarea name=\"text\" class=\"form-control input-outline\" rows=\"6\" maxlength=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"maxTextLength") || (depth0 != null ? lookupProperty(depth0,"maxTextLength") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"maxTextLength","hash":{},"data":data,"loc":{"start":{"line":17,"column":89},"end":{"line":17,"column":106}}}) : helper)))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":17,"column":108},"end":{"line":17,"column":116}}}) : helper)))
    + "</textarea>\n        </div>\n    </div>\n    <!--///note-->\n\n    <!--submit row-->\n    <div class=\"button-row\">\n        <div>\n            <a class=\"btn btn-link m-t-5 m-r-20\"\n                id=\"bb-content-details-page-note-form-cancel\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"cancel") : stack1), depth0))
    + "</a>\n        </div>\n        <div>\n            <button type=\"submit\" class=\"btn-rounded btn-rounded-brand-primary m-t-5 p-lr-30\" disabled=\"disabled\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"submit") : stack1), depth0))
    + "</button>\n            <div class=\"bb-form-submit-button-loader-region btn btn-solid btn-solid-brand-primary btn-block disabled hide\"></div>\n        </div>\n    </div>\n    <!--///submit row-->\n</form>\n";
},"useData":true});