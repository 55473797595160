import L from "lodash/fp";
import inject from "scripts/ioc/inject";
// import {hmr} from 'scripts/utils/viewHelpers';
import { USE_LOCATION_FOR_ORG_AUTH } from "scripts/utils/securityHelpers";
import { pushFragment } from "scripts/utils/routerHelpers";
import Backbone from "backbone";
import BaseView from "scripts/views/baseView";
import ChangeOrganizationTileView from "scripts/views/changeOrganizationTileView";

import {
  geoLocationErrorAlert,
  noAdditionalLibrariesAlert,
  foundAdditionalLibrariesUsingLocation,
} from "scripts/alerts/alerts";

import styles from "sharedStyles/changeOrganization.css";
import template from "templates/changeOrganization.hbs";

// const hot = hmr(module, require);
const chan = Backbone.Radio.channel;

const createTile = L.curry((t, options) => new t(options));

class ChangeOrganizationView extends BaseView {
  constructor(
    options,
    deviceService = inject("deviceService"),
    securityService = inject("securityService"),
    userOrganizationPreferencesService = inject("userOrganizationPreferencesService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);

    this.deviceService = deviceService;
    this.securityService = securityService;
    this.userOrganizationPreferencesService = userOrganizationPreferencesService;
    this.i18nextService = i18nextService;

    this.tileViews = [];

    const organization = this.securityService.getUser().getActiveOrganization();
    const { libraryCardUrl, name } = organization;

    this.state = {
      hasProfile: this.securityService.getUser().hasProfile(),
      showGeoLink: !USE_LOCATION_FOR_ORG_AUTH,
      activeOrganizationLibraryCardUrl: libraryCardUrl,
      activeOrganizationName: name,
    };

    this.template = otherTemplateProps =>
      template(
        L.merge(otherTemplateProps, {
          styles,
          ...this.state,
        }),
      );

    // hot('scripts/views/changeOrganizationTileView', c => this.renderTiles(c));
  }

  get events() {
    return {
      "click .target-manageLibrariesLink": "onClickManageLibraries",
      "click .target-geoLink": "onClickGeoLink",
      "click #bb-library-card-link": "onClickLibraryCardLink",
    };
  }

  className() {
    return styles.container;
  }

  render() {
    const { organization } = this.i18nextService.getGlossary();

    this.$el.html(
      this.template({
        i18n: {
          organization: {
            ...organization,
            getLibraryCard: organization.getLibraryCard(this.state.activeOrganizationName),
          },
        },
      }),
    );

    this.renderTiles(ChangeOrganizationTileView);

    return this;
  }

  async renderTiles(tileClass) {
    this.closeSubViews(this.tileViews);

    const user = this.securityService.getUser();
    const activeOrganizationId = user.getActiveOrganizationId();

    const organizations = await this.setOrganizationOrder(user);

    this.tileViews = L.map(
      organization =>
        createTile(tileClass, {
          organization,
          isActive: organization.organizationId === activeOrganizationId,
        }),
      organizations,
    );

    this.tileViews.forEach(tileView => {
      this.addSubView(tileView).appendTo(this.findTarget("cardRegion")).render();

      tileView.on("click", options => {
        if (!options.isActive) {
          this.onChangeOrganization(options.organization.organizationId);
        }
      });
    });
  }

  getOrganizationIds(organizations) {
    return L.map("organizationId", organizations);
  }

  async setOrganizationOrder(user) {
    const userInfo = {
      id: user.getUserId(),
    };

    const localStorageOrganizationPreferences = await this.userOrganizationPreferencesService.getPreferences(userInfo);

    if (!L.isEmpty(localStorageOrganizationPreferences)) {
      console.log("User has local organization preferences, fetching them from localStorage...");
      return L.sortBy(organization => {
        return localStorageOrganizationPreferences.indexOf(organization.organizationId);
      }, user.getOrganizations());
    } else {
      console.log("User does not have preferences, saving user's organizations as new preferences...");
      userInfo.organizationPreferences = this.getOrganizationIds(user.getOrganizations());
      this.userOrganizationPreferencesService.save(userInfo);
    }

    return user.getOrganizations();
  }

  onChangeOrganization(organizationId) {
    const user = this.securityService.getUser();
    user.setActiveOrganizationId(organizationId);

    this.userOrganizationPreferencesService
      .updatePreferences({
        id: user.getUserId(),
        activeOrganizationId: organizationId,
      })
      .then(() => {
        this.securityService.setUser(user).then(() => {
          pushFragment("/home");
          chan("history").request("clear");
          chan("display").request("showFreshHomepage");
        });
      });
  }

  onClickLibraryCardLink() {
    chan("tracking").trigger("libraryCard:click", {
      url: this.state.activeOrganizationLibraryCardUrl,
    });
  }

  onClickManageLibraries() {
    this.trigger("close");
  }

  onClickGeoLink() {
    chan("display").request("showBlockingLoader", 0);

    // this.state.showGeoLink = false;

    const user = this.securityService.getUser();

    this.securityService
      .fetchOrganizationUserUsingGeo()
      .then(geoUser => {
        const geoUserOrganizationIds = geoUser.getOrganizationIds();
        const currentOrganizationIds = user.getOrganizationIds();

        const newOrganizationIds = L.filter(
          L.complement(L.contains(L.__, currentOrganizationIds)),
          geoUserOrganizationIds,
        );

        if (newOrganizationIds.length > 0) {
          foundAdditionalLibrariesUsingLocation.show();

          geoUser.setActiveOrganizationId(user.getActiveOrganizationId());

          return this.securityService.setUser(geoUser);
        } else {
          return noAdditionalLibrariesAlert.show();
        }
      })
      .then(() => this.render())
      .catch(error => {
        console.log("Error fetching org user with geo: %O", error);
        return geoLocationErrorAlert(this.deviceService).show();
      })
      .finally(() => {
        chan("display").request("hideBlockingLoader");
      });
  }
}

export default ChangeOrganizationView;
