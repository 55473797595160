import Promise from "bluebird";
import CompoundChildrenCollection from "scripts/collections/compoundChildrenCollection";

export const headRequest = url =>
  new Promise(resolve => {
    const xhr = new XMLHttpRequest();
    xhr.open("HEAD", url);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.DONE) {
        if (xhr.status === 200) {
          resolve(true);
        } else {
          resolve(false);
        }
      }
    };
    xhr.send();
  });

export const getOptimizedPdfUrl = contentModel => {
  const pdfUrl = contentModel.get("pdfUrl");

  if (pdfUrl) {
    const optimizedPdfUrl = pdfUrl
      ? pdfUrl.replace("content.pdf", "content_optimized.pdf")
      : "";

    return headRequest(optimizedPdfUrl).then(fileExists => {
      if (fileExists) {
        return optimizedPdfUrl;
      } else if (contentModel.get("type") === "CHAPTER") {
        return pdfUrl;
      } else {
        return "";
      }
    });
  } else {
    return "";
  }
};

export const getFirstChapterMediaId = mediaId => {
  const collection = new CompoundChildrenCollection([], {
    mediaId,
  });

  return collection
    .fetch()
    .then(col => {
      const firstModel = col.at(0);
      if (firstModel) {
        return firstModel.get("id");
      } else {
        return null;
      }
    })
    .catch(error => {
      console.log("Error fetching first chapter mediaId: %O", error);
      return null;
    });
};
