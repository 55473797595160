var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"bb-forgot-password-form\">\n    <h4 class=\"text-notransform m-tb-20\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"forgot") : stack1), depth0))
    + "</h4>\n\n    <div class=\"form-group\">\n        <input name=\"emailAddress\" class=\"form-control input-outline\" id=\"bb-forgot-email-field\"\n            placeholder=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"enterEmail") : stack1), depth0))
    + "\" type=\"email\" autocomplete=\"email\">\n    </div>\n\n    <!--submit button-->\n    <button type=\"submit\" class=\"btn-rounded btn-rounded-brand-primary btn-block m-t-5\" disabled=\"disabled\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"send") : stack1), depth0))
    + "</button>\n    <div class=\"bb-form-submit-button-loader-region btn btn-solid btn-solid-brand-primary btn-block disabled hide\">\n    </div>\n</form>";
},"useData":true});