import _ from "lodash";
import inject from "scripts/ioc/inject";
import Backbone from "backbone";
import BaseView from "scripts/views/baseView";
import HybridMediaCollection from "scripts/collections/hybridMediaCollection";
import MediaModel from "scripts/models/mediaModel";

const chan = Backbone.Radio.channel;

class HostedVideoShadowBoxView extends BaseView {
  get defaultPlayerOptions() {
    return {
      skin: "five",
      fallback: false,
      autostart: true,
      width: "100%",
      aspectratio: "16:9",
      visualplaylist: false,
      base: "https://ssl.p.jwpcdn.com/player/v/7.12.13/",
    };
  }

  constructor(
    options,
    relatedContentService = inject("relatedContentService"),
  ) {
    super(options);

    this.relatedContentService = relatedContentService;

    this.model = new Backbone.Model();
  }

  render() {
    this.setupShadowBox();

    return this;
  }

  setupShadowBox() {
    $.fancybox.open({
      helpers: {
        overlay: {
          locked: true,
        },
      },
      content: '<div id="bb-video-player"></div>',
      autoSize: false,
      width: 800,
      height: 450,
      aspectRatio: true,
      scrolling: "no",
      afterShow: this.setupPlayer.bind(this),
      beforeClose: this.removePlayer.bind(this),
    });
  }

  async setupPlayer() {
    $.fancybox.showLoading();

    // Needed to correct z-index issue with iOS devices (UIWebView)
    $(".fancybox-wrap.fancybox-mobile").css("transform", "translate3d(0,0,0)");

    const playerOptions = _.clone(this.defaultPlayerOptions);
    const playlist = await this.generatePlaylist();

    console.log("JWPlayer playlist: %O", playlist);

    if (playlist.length) {
      playerOptions.playlist = playlist;
    } else {
      playerOptions.sources = this.jwPlayerSources(
        this.model.get("videoFormats"),
      );
      playerOptions.image = this.model.get("thumbnailUrl");
    }

    jwplayer("bb-video-player")
      .setup(playerOptions)
      .on("firstFrame", this.onFirstFrame.bind(this))
      .on("ready", this.onPlayerReady.bind(this, playlist));

    console.log("JWPlayer initialized");
  }

  /**
   * Triggered when playback begins.
   */
  onFirstFrame() {
    const currentPlaylistIndex = jwplayer().getPlaylistIndex();
    const playlistItem = jwplayer().getPlaylist()[currentPlaylistIndex];
    const mediaId = playlistItem.mediaid || this.model.get("id");

    this.sendCounterEvent(mediaId);
  }

  onPlayerReady(playlist) {
    if (playlist.length) {
      const indexToPlay = _.findIndex(playlist, {
        mediaid: this.model.get("id"),
      });

      jwplayer().playlistItem(indexToPlay);
    }

    $.fancybox.hideLoading();

    $("#bb-video-player").focus();
  }

  /**
   * Generate JW Player sources object from API video formats.
   */
  jwPlayerSources(formats) {
    return _.map(formats, format => {
      return {
        file: format.url,
        label: format.resolution,
      };
    });
  }

  removeShadowBox() {
    if ($.fancybox.isOpen) {
      $.fancybox.close(true);
      console.log("FancyBox closed");
    }
  }

  removePlayer() {
    if (this.isPlayerInitialized()) {
      jwplayer().remove();
      console.log("JWPlayer removed");
    }
  }

  isPlayerInitialized() {
    return jwplayer().id === "bb-video-player";
  }

  async generatePlaylist() {
    const relatedVideoCollection = await this.fetchRelated();

    return [].concat(
      relatedVideoCollection
        .filter(this.isHostedVideo.bind(this))
        .map(this.getPlaylistItem.bind(this)),
    );
  }

  isHostedVideo(videoModel) {
    return videoModel.get("videoProvider") === "INTERNAL_AWS_CREATOR";
  }

  getPlaylistItem(videoModel) {
    return {
      mediaid: videoModel.get("id"),
      sources: this.jwPlayerSources(videoModel.get("videoFormats")),
      image: videoModel.get("thumbnailUrl"),
      title: videoModel.get("title"),
    };
  }

  fetchRelated() {
    return this.relatedContentService.fetchSiblingsForContentType(
      new HybridMediaCollection(),
      this.model,
      new MediaModel(),
    );
  }

  sendCounterEvent(contentMediaId) {
    chan("tracking").trigger("content:open", {
      mediaId: contentMediaId,
      contentType: this.model.get("type"),
    });
  }

  close() {
    this.removeShadowBox(); // Callback will cleanup JWPlayer

    super.close();
  }
}

export default HostedVideoShadowBoxView;
