import _ from "lodash";
import BaseApiModel from "scripts/models/baseApiModel";

class CustomCurationThumbnailModel extends BaseApiModel {
  constructor(options) {
    super(options);

    this.curationMediaId = options && options.curationMediaId;
  }

  url() {
    return `${this.apiUrlRoot}/curations/${this.curationMediaId}/thumbnail`;
  }

  sync(method, model, options) {
    const formData = new FormData();
    formData.append("file", model.get("file"));

    // Set processData and contentType to false so data is sent as FormData
    _.defaults(options || (options = {}), {
      data: formData,
      processData: false,
      contentType: false,
      xhr: () => {
        const xhr = $.ajaxSettings.xhr();

        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            model.trigger('progress', (event.loaded / event.total) * 100);
          }
        };

        xhr.upload.onload = () => {
          model.trigger('progress', 100);
        };

        return xhr;
      },
    });

    if (method === "create" || method === "update") {
      method = "update";
    }

    return super.sync(method, model, options);
  }

}

export default CustomCurationThumbnailModel;
