import Promise from "bluebird";

export default function(Collection) {
  return class extends Collection {
    fetch(...args) {
      return super
        .fetch(...args)
        .then(() =>
          Promise.all(
            this.models.map(model => {
              if (model.fetchHybridData && model.parseHybridData) {
                return Promise.resolve(model.fetchHybridData()).then(data =>
                  model.parseHybridData(data),
                );
              } else {
                return null;
              }
            }),
          ),
        )
        .then(() => this);
    }
  };
}
