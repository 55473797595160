var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"nav-elem\">\n          <button data-page-ref=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ref") || (depth0 != null ? lookupProperty(depth0,"ref") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ref","hash":{},"data":data,"loc":{"start":{"line":4,"column":33},"end":{"line":4,"column":40}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":4,"column":42},"end":{"line":4,"column":51}}}) : helper)))
    + "</button>\n          "
    + ((stack1 = (lookupProperty(helpers,"bookViewerTocItems")||(depth0 && lookupProperty(depth0,"bookViewerTocItems"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"margin")),{"name":"bookViewerTocItems","hash":{},"data":data,"loc":{"start":{"line":5,"column":10},"end":{"line":5,"column":53}}})) != null ? stack1 : "")
    + "\n        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <ul style=\"margin: 0 0 0 "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"margin") || (depth0 != null ? lookupProperty(depth0,"margin") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"margin","hash":{},"data":data,"loc":{"start":{"line":1,"column":29},"end":{"line":1,"column":39}}}) : helper)))
    + "px; padding: 0;\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"tocData") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":6},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "    </ul>";
},"useData":true});