import BaseSecondaryStickyView from "scripts/views/baseSecondaryStickyNavView";
import templateLegalNoticesNav from "templates/legalNoticesPageNav.hbs";

class LegalNoticesNavView extends BaseSecondaryStickyView {
  get template() {
    return templateLegalNoticesNav;
  }

  shouldStick() {
    return true;
  }

  updateLinkState() {
    const route = this.model.get("route");
    if (route === "eula") {
      $("#bb-legal-notices-page-nav-item-eula").addClass("active");
      $("#bb-legal-notices-page-nav-header-eula").addClass("active");

    } else if (route === "privacy") {
      $("#bb-legal-notices-page-nav-item-privacy").addClass("active");
      $("#bb-legal-notices-page-nav-header-privacy").addClass("active");

    } else if (route === "cookiePolicy") {
      $("#bb-legal-notices-page-nav-item-cookie").addClass("active");
      $("#bb-legal-notices-page-nav-header-cookie").addClass("active");
    }
  }
}

export default LegalNoticesNavView;