import Backbone from "backbone";
import Url from "url";
import querystring from "querystring";
import inject from "scripts/ioc/inject";
const originalNavigate = Backbone.history.navigate;

Backbone.history.navigate = function(fragment, options) {
  const securityService = inject("securityService");

  if (
    securityService.authScope &&
    !securityService.getAuthScopeFromSubdomain()
  ) {
    const urlObject = Url.parse(fragment);
    const urlParameters = querystring.parse(urlObject.query);

    if (!urlParameters.authScope) {
      urlParameters.authScope = securityService.authScope;
    }

    urlObject.search = querystring.stringify(urlParameters);

    fragment = Url.format(urlObject);
  }

  return originalNavigate.call(this, fragment, options);
};
