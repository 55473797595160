import _ from "lodash";
import Backbone from "backbone";
import Promise from "bluebird";
import ContentModel from "scripts/models/contentModel";
import ProgressBarModel from "scripts/models/progressBarModel";
import BaseMediaDetailsPageView from "scripts/views/baseMediaDetailsPageView";
import CompoundChildrenCollection from "scripts/collections/compoundChildrenCollection";
import ChaptersDownloadModalView from "scripts/views/chaptersDownloadModalView";
import RatingView from "scripts/views/ratingView";
import ProgressBarView from "scripts/views/progressBarView";
import FileSizeView from "scripts/views/fileSizeView";
import { getFirstChapterMediaId } from "scripts/utils/ajaxUtil";
import { redirectToFragment } from "scripts/utils/routerHelpers";
import errorAlert from "scripts/alerts/errorAlert";

import templateContributor from "templates/contributorLink.hbs";

const chan = Backbone.Radio.channel;

class BaseContentDetailsPageView extends BaseMediaDetailsPageView {
  constructor(options, breadcrumbService, connectionService, securityService, bookshelfService) {
    super(options, breadcrumbService, connectionService, securityService);

    this.bookshelfService = bookshelfService;

    this.ratingView = this.addSubView(this.createRatingView());

    if (this.isCompound()) {
      this.compoundChildrenCollection = new CompoundChildrenCollection([], {
        mediaId: this.mediaModel.get("id"),
      });
    }
  }

  get contributorTemplate() {
    return templateContributor;
  }

  get events() {
    return _.extend({}, super.events, {
      "click .bb-content-details-viewer-button": "onClickViewer",
      "click #bb-media-details-bookshelf-button": "onClickBookshelf",
      "click #bb-media-details-purchase-link": "onClickPurchaseLink",
      "click #bb-media-details-download-button": "onClickDownload",
      "click #bb-audio-details-player-button": "onPlayAudio",
      "click #bb-album-details-player-button": "onPlayAlbum",
      "click #bb-video-details-player-button": "onPlayVideo",
    });
  }

  initializeBookshelfDeps() {
    this.progressBarModel = new ProgressBarModel();

    this.progressBarView = this.addSubView(
      new ProgressBarView({
        className: "bb-download-indicator-container details-version",
        model: this.progressBarModel,
      }),
    );

    this.fileSizeView = this.addSubView(
      new FileSizeView({
        model: this.mediaModel,
      }),
    );

    this.progressBarView.on("progressBarCancel", () => this.onCancelBookshelfDownload(), this);

    chan("bookshelfService").on("download:progress", attrs => this.onBookshelfDownloadProgress(attrs), this);
    chan("bookshelfService").on("download:complete", attrs => this.onBookshelfDownloadComplete(attrs), this);
    chan("bookshelfService").on("download:failed", attrs => this.onBookshelfDownloadFailed(attrs), this);
  }

  createRatingView() {
    return new RatingView({
      mediaId: this.mediaModel.get("id"),
      contentModel: this.mediaModel,
    });
  }

  createMediaModel() {
    return new ContentModel({
      id: this.model.get("mediaId"),
    });
  }

  sync() {
    return Promise.all([
      this.subPageView.fetch(),
      this.mediaLineageModel.fetch(),
      this.ratingView.fetch(),
      this.compoundChildrenCollection ? this.compoundChildrenCollection.fetch() : Promise.resolve(),
    ]);
  }

  render() {
    super.render();

    this.renderFavoriteButton();
    this.renderHighlightBadge();
    this.renderCurationButton();
    this.renderRatingView();
    this.addContributors(this.mediaModel.get("contributors"));

    return this;
  }

  renderBookshelfElements() {
    this.renderBookshelfButton();
    this.renderTotalFileSize();
    this.renderProgressBar();
  }

  renderRatingView() {
    this.ratingView.attachTo($("#bb-content-details-rating-region")).render();
  }

  renderProgressBar() {
    this.progressBarView.htmlOf(this.$el.find(".bb-download-progress-bar-region")).render();
  }

  renderBookshelfButton() {
    if (this.bookshelfService.isAvailable()) {
      if (this.mediaModel.get("onBookshelf")) {
        this.checkBookshelfButton();
      } else {
        this.uncheckBookshelfButton();
      }

      this.showBookshelfButton();
    } else {
      this.hideBookshelfButton();
    }
  }

  //TODO: make common
  renderTotalFileSize(fadeIn) {
    const $totalFileSizeRegion = this.$el.find(".bb-file-size-region");

    if (this.mediaModel.get("onBookshelf")) {
      this.fileSizeView.htmlOf($totalFileSizeRegion).render(fadeIn);
    } else {
      $totalFileSizeRegion.empty();
    }
  }

  isOverflowed(element) {
    return element && (element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth);
  }

  addContributors(contributors) {
    const $contributors = $("#bb-content-details-page-contributor-region");

    contributors.forEach(contributor => {
      contributor.searchContributorQueryParam = encodeURIComponent(`"${contributor.fullName}"`);
      $contributors.append(this.contributorTemplate(contributor));
    });

    const $contributorList = $("#contributorsList");
    if (!this.isOverflowed($contributors[0])) {
      $contributorList.removeClass("collapse");
      const $contributorListBtn = $("#contributorListBtn");
      $contributorListBtn.addClass("hidden");
    }
  }

  onClickPurchaseLink() {
    chan("tracking").trigger("purchase:click", {
      mediaId: this.mediaModel.get("id"),
      url: this.mediaModel.get("purchaseLink").url,
    });
  }

  onClickViewer() {
    chan("tracking").trigger("viewer:click", this.mediaModel); // not listened to

    if (this.mediaModel.get("type") === "AUDIOBOOK") {
      chan("display").request("playAudio", this.mediaModel);
      return false;
    } else {
      const mimeType = this.mediaModel.get("mimeType");
      const mediaId = this.mediaModel.get("id");

      if (mimeType === "application/biblioboard+compound_content") {
        getFirstChapterMediaId(mediaId).then(chapterMediaId => {
          if (chapterMediaId) {
            redirectToFragment(`/viewer/${chapterMediaId}`);
          } else {
            errorAlert(new Error()).show();
          }
        });

        return false;
      }
    }
  }

  //TODO: make common
  onBookshelfDownloadProgress(attrs) {
    if (attrs.mediaId === this.mediaModel.get("id") && this.mediaModel.get("onBookshelf")) {
      const percent = (attrs.filesCompleted / attrs.totalFiles) * 100;

      this.progressBarModel.set("isVisible", true);
      this.progressBarModel.set("percent", percent);
    }
  }

  //TODO: make common
  onBookshelfDownloadComplete(attrs) {
    if (attrs.mediaId === this.mediaModel.get("id")) {
      this.mediaModel.set({
        downloadComplete: true,
        totalSize: attrs.totalSize,
      });

      this.hideProgressBar();
      this.renderTotalFileSize(true);
    }
  }

  //TODO: make common
  onBookshelfDownloadFailed(attrs) {
    if (attrs.mediaId === this.mediaModel.get("id")) {
      this.mediaModel.set({
        downloadComplete: false,
        totalSize: attrs.totalSize,
      });

      this.hideProgressBar();
      this.renderTotalFileSize(true);
    }
  }

  //TODO: make common
  onClickBookshelf() {
    if (!this.isBookshelfButtonEnabled()) {
      return false;
    }

    if (this.mediaModel.get("onBookshelf")) {
      this.removeFromBookshelf();
    } else {
      this.addToBookshelf();
    }

    return false;
  }

  //TODO: make common
  addToBookshelf() {
    chan("myBoard")
      .request("bookshelfAdd", this.mediaModel)
      .then(() => {
        this.mediaModel.set({
          onBookshelf: true,
        });

        this.renderTotalFileSize();
        this.renderBookshelfButton();
      })
      .catch(error => {
        if (!(error instanceof Promise.CancellationError)) {
          throw error;
        }
      });
  }

  //TODO: make common
  onCancelBookshelfDownload() {
    chan("myBoard")
      .request("bookshelfCancelDownload", this.mediaModel)
      .then(() => {
        this.mediaModel.set({
          onBookshelf: false,
          downloadComplete: false,
          totalSize: void 0,
        });

        this.hideProgressBar();
        this.renderTotalFileSize();
        this.renderBookshelfButton();
      })
      .catch(error => {
        if (!(error instanceof Promise.CancellationError)) {
          throw error;
        }
      });
  }

  //TODO: make common
  removeFromBookshelf() {
    chan("myBoard")
      .request("bookshelfRemove", this.mediaModel)
      .then(() => {
        this.mediaModel.set({
          onBookshelf: false,
          downloadComplete: false,
          totalSize: void 0,
        });

        this.hideProgressBar();
        this.renderTotalFileSize();
        this.renderBookshelfButton();
      })
      .catch(error => {
        if (!(error instanceof Promise.CancellationError)) {
          throw error;
        }
      });
  }

  hideBookshelfButton() {
    $("#bb-media-details-bookshelf-button").addClass("hide");
  }

  showBookshelfButton() {
    $("#bb-media-details-bookshelf-button").removeClass("hide");
  }

  disableBookshelfButton() {
    $("#bb-media-details-bookshelf-button").addClass("disabled");
  }

  enableBookshelfButton() {
    $("#bb-media-details-bookshelf-button").removeClass("disabled");
  }

  isBookshelfButtonEnabled() {
    return !$("#bb-media-details-bookshelf-button").hasClass("disabled");
  }

  checkBookshelfButton() {
    $("#bb-media-details-bookshelf-button").addClass("active");
    $("#bb-media-details-bookshelf-button-icon").removeClass("bbico-offline-bookshelf");
    $("#bb-media-details-bookshelf-button-icon").addClass("bbico-offline-bookshelf-filled");
  }

  uncheckBookshelfButton() {
    $("#bb-media-details-bookshelf-button").removeClass("active");
    $("#bb-media-details-bookshelf-button-icon").removeClass("bbico-offline-bookshelf-filled");
    $("#bb-media-details-bookshelf-button-icon").addClass("bbico-offline-bookshelf");
  }

  hideProgressBar() {
    this.progressBarModel.set("isVisible", false);
  }

  isCompound() {
    return this.mediaModel.get("mimeType") === "application/biblioboard+compound_content";
  }

  onClickDownload() {
    if (this.isCompound()) {
      chan("display").request(
        "showModal",
        new ChaptersDownloadModalView({
          compoundChildrenCollection: this.compoundChildrenCollection,
        }),
      );

      return false;
    }
  }

  async onPlayAlbum() {
    if (this.mediaModel.get("isAlbumTrack")) {
      const albumModel = new Backbone.Model(this.mediaModel.get("parentContent"));
      this.compoundChildrenCollection = new CompoundChildrenCollection([], {
        mediaId: albumModel.get("id"),
      });
      await this.compoundChildrenCollection.fetch();

      chan("display").request(
        "playAlbum",
        albumModel,
        this.mediaModel.get("childOrder"),
        this.compoundChildrenCollection,
      );
    } else {
      chan("display").request("playAlbum", this.mediaModel, 0, this.compoundChildrenCollection);
    }

    chan("tracking").trigger("viewer:click", this.mediaModel); // not listened to
    return false;
  }

  onPlayAudio() {
    chan("display").request("playAudio", this.mediaModel);
    chan("tracking").trigger("viewer:click", this.mediaModel); // not listened to
    return false;
  }

  onPlayVideo() {
    chan("display").request("playVideo", this.mediaModel);
    chan("tracking").trigger("viewer:click", this.mediaModel); // not listened to
    return false;
  }
}

export default BaseContentDetailsPageView;
