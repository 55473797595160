import Controller from "scripts/controllers/controller";
import DeveloperPageView from "scripts/views/developerPageView";

class DeveloperController extends Controller {
  get route() {
    return "route:developer";
  }

  replyRoute() {
    return this.showScreenView(new DeveloperPageView());
  }
}

export default DeveloperController;
