import _ from "lodash";
import Controller from "scripts/controllers/controller";
import ChooseCurationThumbnailPageView from "scripts/views/chooseCurationThumbnailPageView";
import CurationModel from "scripts/models/curationModel";
import CurationMediaModel from "scripts/models/curationMediaModel";
import CurationMediaCollection from "scripts/collections/curationMediaCollection";

class ChooseCurationThumbnailController extends Controller {
  get route() {
    return "route:chooseCurationThumbnail";
  }

  routeAttrs() {
    return {
      mediaId: arguments[0],
    };
  }

  replyRoute() {
    const attrs = this.routeAttrs.apply(this, _.tail(arguments));
    const mediaId = attrs.mediaId;

    return this.showMainBodyView(
      new ChooseCurationThumbnailPageView({
        model: new CurationModel({
          id: mediaId,
        }),
        collection: new CurationMediaCollection([], {
          path: `/curations/${mediaId}/media`,
          mediaId: mediaId,
          model: CurationMediaModel,
          state: {
            currentPage: 0,
            pageSize: 9999,
          },
        }),
      }),
    );
  }
}

export default ChooseCurationThumbnailController;
