import Backbone from "backbone";
import dateFormat from "dateformat";
import BaseApiModel from "scripts/models/baseApiModel";

const chan = Backbone.Radio.channel;

class NoteModel extends BaseApiModel {
  get defaults() {
    return {
      maxTitleLength: 128,
      maxTextLength: 2048,
    };
  }

  urlRoot() {
    const userId = chan("security")
      .request("user")
      .getUserId();
    const mediaId = this.get("mediaId");

    return `${this.apiUrlRoot}/users/${userId}/media/${mediaId}/notes`;
  }

  sync(method, model, options) {
    if (method === "create") {
      const userId = chan("security")
        .request("user")
        .getUserId();
      const mediaId = this.get("mediaId");

      if (model.has("logicalPageIndex") || model.has("cfi")) {
        this.url = `${
          this.apiUrlRoot
        }/users/${userId}/media/${mediaId}/notes/internal`;
      }
    }

    return super.sync(method, model, options);
  }

  parse(response) {
    // On PATCH requests, the server returns an empty body, so guard against that.
    response = response || {};

    // Add a formatted added date in format 'Feb 5, 2015'
    if ("dateAdded" in response) {
      response.dateAddedFormatted = dateFormat(
        response.dateAdded,
        "mmm d, yyyy",
      );
    }

    if ("logicalPageIndex" in response) {
      response.pageSequence = response.logicalPageIndex + 1;
    }

    return response;
  }
}

export default NoteModel;
