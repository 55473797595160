import Backbone from "backbone";

const chan = Backbone.Radio.channel;

class ErrorPageModel extends Backbone.Model {
  canGoBack() {
    let back = false;

    if (chan("history").request("length")) {
      back = true;
    }

    return back;
  }

  toJSON(...args) {
    const attributes = super.toJSON(...args);
    attributes.canGoBack = this.canGoBack();
    return attributes;
  }
}

export default ErrorPageModel;
