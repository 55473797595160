import Handlebars from "handlebars-template-loader/runtime";

/**
 * Formats seconds into format 'hh:mm:ss'
 */
Handlebars.registerHelper("formatSeconds", seconds => {
  let out = "";

  if (seconds === parseInt(seconds, 10)) {
    const date = new Date(null);
    date.setSeconds(seconds);

    const isoString = date.toISOString();

    // only show hours if present
    if (isoString.substr(11, 1) !== "0" || isoString.substr(12, 1) !== "0") {
      out = isoString.substr(11, 8);
    } else {
      out = isoString.substr(14, 5);
    }
  }

  return out;
});
