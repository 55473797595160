import BaseListTileView from "scripts/views/baseListTileView";
import templateCategoryListTile from "templates/categoryListTile.hbs";

class CategoryListTileView extends BaseListTileView {
  get template() {
    return templateCategoryListTile;
  }
}

export default CategoryListTileView;
