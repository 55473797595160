import L from "lodash/fp";
import Backbone from "backbone";
import UnauthorizedSyncError from "scripts/exceptions/unauthorizedSyncError";
import UserForcePasswordResetException from "scripts/exceptions/userForcePasswordResetException";
import errorAlert from "scripts/alerts/errorAlert";
import { alert } from "scripts/alerts/alerts";
import BaseView from "scripts/views/baseView";
import ForgotPasswordModalView from "scripts/views/forgotPasswordModalView";
import LoginFormView from "scripts/views/authentication/loginFormView";
import { redirectToFragment } from "scripts/utils/routerHelpers";
import inject from "scripts/ioc/inject";

const chan = Backbone.Radio.channel;

class BaseLoginView extends BaseView {
  constructor(
    options,
    historyController,
    securityService,
    i18nextService = inject("i18nextService"),
  ) {
    super(options);

    this.historyController = historyController;
    this.securityService = securityService;
    this.i18nextService = i18nextService;

    this.loginFormView = this.addSubView(new LoginFormView());
    this.loginFormView.on("forgot", this.onForgotPassword, this);
    this.loginFormView.on("success", this.onAuthenticationSuccess, this);
    this.loginFormView.on("failure", this.onAuthenticationFailure, this);
  }

  get events() {
    return {
      "click #bb-login-create-profile-link": "onCreateProfile",
    };
  }

  render() {
    const { auth, menu } = this.i18nextService.getGlossary();

    this.$el.html(
      this.template({
        i18n: {
          dontHaveProfile: menu.dontHaveProfile,
          getStartedNow: auth.getStartedNow,
        },
      }),
    );

    this.loginFormView.appendTo($("#bb-login-form-region")).render();

    return this;
  }

  onCreateProfile() {
    this.trigger("createProfile");
  }

  onAuthenticationSuccess() {
    const lastFragment = this.historyController.getLastFragment();
    redirectToFragment(L.isNil(lastFragment) ? "/home" : lastFragment);
  }

  onAuthenticationFailure(error) {
    if (error instanceof UserForcePasswordResetException) {
      this.onAuthenticationSuccess();

      chan("security")
        .request("forcePasswordReset", error)
        .catch(error => errorAlert(error).show());
    } else if (error instanceof UnauthorizedSyncError) {
      alert(
        "Invalid Credentials",
        "Are you sure that you entered your username and password correctly?",
        "error",
      ).show();
    } else {
      errorAlert(error).show();
    }
  }

  onForgotPassword() {
    return chan("display").request("showModal", new ForgotPasswordModalView());
  }
}

export default BaseLoginView;
