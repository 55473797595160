import _ from "lodash";
import Backbone from "backbone";
import docCookies from "doc-cookies";

const rootPathDocCookies = _.extend(docCookies);

rootPathDocCookies.superSetItem = rootPathDocCookies.setItem;
rootPathDocCookies.superRemoveItem = rootPathDocCookies.removeItem;

rootPathDocCookies.setItem = function(
  sKey,
  sValue,
  vEnd,
  sPath,
  sDomain,
  bSecure,
) {
  return this.superSetItem(sKey, sValue, vEnd, "/", sDomain, bSecure);
};

rootPathDocCookies.removeItem = function(sKey, sPath, sDomain) {
  return this.superRemoveItem(sKey, "/", sDomain);
};

Backbone.CookieStorage = Backbone.SessionStorage.extend({
  localStorage: function() {
    return rootPathDocCookies;
  },
});

export default Backbone.CookieStorage;
