import inject from "scripts/ioc/inject";
import BaseMediaSalvattoreTileView from "scripts/views/baseMediaSalvattoreTileView";
import templateAnthologySalvattoreTile from "templates/anthologySalvattoreTile.hbs";

class AnthologySalvattoreTileView extends BaseMediaSalvattoreTileView {
  constructor(options, securityService = inject("securityService")) {
    super(options, securityService);
  }

  get template() {
    return templateAnthologySalvattoreTile;
  }
}

export default AnthologySalvattoreTileView;
