import BaseModalView from "scripts/views/baseModalView";
import inject from "scripts/ioc/inject";
import _ from "lodash";
import L from "lodash/fp";
import templateFacetedSearchTermModal from "templates/search/facetedSearchTermModal.hbs";

class FacetedSearchTermModalView extends BaseModalView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.searchTerm = options.searchTerm;
  }

  get events() {
    return {
      "click #bb-faceted-search-facet-modal-apply-button": "onClickApply",
      "click #bb-faceted-search-facet-modal-clear-input": "onClear",
      "keydown input": "onSubmit",
    };
  }

  get className() {
    return "modal-content faceted-search-modal";
  }

  get template() {
    return templateFacetedSearchTermModal;
  }

  render() {
    const glossary = this.i18nextService.getGlossary();
    const attributes = this.i18nextService.getAttributes();
    const common = this.i18nextService.getCommon();

    const i18n = _.merge(
      L.flowRight([L.pick(["close", "clearInput"]), L.get(["ariaLabel"])])(
        attributes,
      ),
      L.flowRight([L.pick(["search"]), L.get(["search"])])(glossary),
      L.flowRight([L.pick(["apply"]), L.get(["button"])])(common),
    );

    this.$el.html(
      this.template({
        i18n,
        searchTerm: this.searchTerm,
      }),
    );

    return this;
  }

  onClickApply() {
    this.searchTerm = this.$el.find("input").val();
    this.trigger("apply", this.searchTerm);
  }

  onClear(e) {
    e.preventDefault();
    const search = this.$el.find("input");
    search.val("");
    search.focus();
  }

  onSubmit(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      this.onClickApply();
      this.closeModal();
    }
  }
}

export default FacetedSearchTermModalView;
