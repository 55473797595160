import _ from "lodash";
import AuthenticationSyncHoc from "scripts/hoc/authenticationSyncHoc";

const AuthenticationCollectionHoc = Collection => {
  return class extends AuthenticationSyncHoc(Collection) {
    constructor(models, options) {
      super(models, options);

      if (options && options.path) {
        this.path = options.path;
      }
    }

    url() {
      if (this.path) {
        return this.authenticationUrlRoot + _.result(this, "path");
      } else {
        throw new Error("Tried to create URL but path is missing");
      }
    }
  };
};

export default AuthenticationCollectionHoc;
