import _ from "lodash";
import BaseAuthenticationModel from "scripts/models/baseAuthenticationModel";
import uuid from "uuid";

class DiagnosticsAuthenticationModel extends BaseAuthenticationModel {
  url() {
    return `${this.organizationAuthenticationUrlRoot}/diagnostics`;
  }

  syncOptions(options) {
    options = super.syncOptions(options);

    _.merge(options, {
      headers: {
        "X-Auth-Token": uuid.v1(),
      },
    });

    return options;
  }
}

export default DiagnosticsAuthenticationModel;
