import Backbone from "backbone";
import BaseApiModel from "scripts/models/baseApiModel";

const chan = Backbone.Radio.channel;

class FavoriteModel extends BaseApiModel {
  urlRoot() {
    const userId = chan("security")
      .request("user")
      .getUserId();

    return `${this.apiUrlRoot}/users/${userId}/favorites/`;
  }
}

export default FavoriteModel;
