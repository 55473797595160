import _ from "lodash";
import BaseApiModel from "scripts/models/baseApiModel";
import CommentCollection from "scripts/collections/commentCollection";

class CommentThreadModel extends BaseApiModel {
  get idAttribute() {
    return "threadId";
  }

  get defaults() {
    return {
      threadId: "",
      comments: undefined,
      commentsCount: 0,
      favorite: false,
      subscribe: false,
    };
  }

  initialize(attrs) {
    this.disqusModel = attrs.disqusModel;

    this.set(
      "comments",
      new CommentCollection([], { disqusModel: this.disqusModel }),
    );
  }

  url() {
    const disqusThread = this.disqusModel.get("disqusThread");

    return `${this.apiUrlRoot}/comments/threads/${disqusThread}`;
  }

  parse(response) {
    response = response.response || response;

    let data = {
      threadId: response.id,
      comments:
        this.get("comments") ||
        new CommentCollection([], { disqusModel: this.disqusModel }),
      commentsCount: response.posts,
      favorite: response.userScore === 1,
      subscribe: response.userSubscription,
    };

    this.disqusModel.set("disqusThreadId", data.threadId);

    return _.extend({}, this.defaults, data);
  }
}

export default CommentThreadModel;
