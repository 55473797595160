import BaseView from "scripts/views/baseView";
import UnauthorizedSyncError from "scripts/exceptions/unauthorizedSyncError";
import VerifyEmailSuccessSubView from "scripts/views/verifyEmailSuccessSubView";
import VerifyEmailErrorSubView from "scripts/views/verifyEmailErrorSubView";

import verifyEmailPage from "templates/verifyEmailPage.hbs";

class VerifyEmailPageView extends BaseView {
  get template() {
    return verifyEmailPage;
  }

  sync() {
    return this.model
      .save()
      .then(model => {
        this.verifyEmailSubPage = this.addSubView(
          new VerifyEmailSuccessSubView({ model: model }),
        );
      })
      .catch(UnauthorizedSyncError, error => {
        this.verifyEmailSubPage = this.addSubView(
          new VerifyEmailErrorSubView({ model: error.model }),
        );
      });
  }

  render() {
    this.$el.html(this.template());

    this.verifyEmailSubPage
      .appendTo(this.$el.find("#bb-email-verification-page-sub-page-region"))
      .render();

    return this;
  }
}

export default VerifyEmailPageView;
