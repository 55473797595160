import Controller from "scripts/controllers/controller";
import AppUpdateModel from "scripts/models/appUpdateModel";
import AppUpdateGrowlAlertView from "scripts/views/appUpdateGrowlAlertView";

class AppUpdateController extends Controller {
  constructor() {
    super();

    if (
      window.location.href.startsWith("http://127") ||
      window.location.href.startsWith("http://localhost")
    ) {
      console.log("Not checking for app update in dev environment");
      return;
    }
    this.startUpdatePoller();
  }

  get appVersion() {
    return process.env.VERSION;
  }

  startUpdatePoller(delay = 60000) {
    console.log("Checking for app update in %d seconds", delay / 1000);

    clearTimeout(this.timeoutHandle);

    this.timeoutHandle = setTimeout(() => {
      this.checkForUpdate();
    }, delay);
  }

  checkForUpdate() {
    new AppUpdateModel()
      .fetch()
      .then(appUpdateModel => {
        this.onCheckForUpdateSuccess(appUpdateModel);
      })
      .catch(error => {
        console.log("Error checking for app update: %O", error);
        this.startUpdatePoller();
      });
  }

  onCheckForUpdateSuccess(appUpdateModel) {
    if (appUpdateModel.has("version")) {
      if (appUpdateModel.get("version") !== this.appVersion) {
        console.log(
          "New app version available: %s. Current version: %s.",
          appUpdateModel.get("version"),
          this.appVersion,
        );

        this.showUserUpdateMsg();
      } else {
        console.log("No app update available");

        this.startUpdatePoller();
      }
    } else {
      console.log("App update model is missing 'version' property");

      this.startUpdatePoller();
    }
  }

  showUserUpdateMsg() {
    new AppUpdateGrowlAlertView()
      .appendTo($("#bb-app-growl-alert-region"))
      .render();
  }
}

export default AppUpdateController;
