import BaseApiModel from "scripts/models/baseApiModel";

class AnthologyCollectionContentCountModel extends BaseApiModel {
  url() {
    const id = this.get("id");

    return `${this.apiUrlRoot}/collections/${id}/content/content-type-summary`;
  }

  parse(response) {
    let data = {};

    data.allContentCount = "_ALL" in response ? response._ALL : 0;
    data.highlightCount = "_HIGHLIGHTS" in response ? response._HIGHLIGHTS : 0;
    data.articleCount = "ARTICLE" in response ? response.ARTICLE : 0;
    data.audioCount = "AUDIO" in response ? response.AUDIO : 0;
    data.audiobookCount = "AUDIOBOOK" in response ? response.AUDIOBOOK : 0;
    data.bookCount = "BOOK" in response ? response.BOOK : 0;
    data.chapterCount = "CHAPTER" in response ? response.CHAPTER : 0;
    data.imageCount = "IMAGE" in response ? response.IMAGE : 0;
    data.otherDocumentCount = "OTHER_DOCUMENT" in response ? response.OTHER_DOCUMENT : 0;
    data.videoCount = "VIDEO" in response ? response.VIDEO : 0;

    return data;
  }
}

export default AnthologyCollectionContentCountModel;
