var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container\">\n    <div class=\"row m-0 p-0\" aria-labelledby=\"bb-anthology-page-nav-item-overview-link\">\n        <h1 class=\" text-center m-b-40\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"anthology") : stack1)) != null ? lookupProperty(stack1,"overview") : stack1), depth0))
    + "</h1>\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"overviewText") || (depth0 != null ? lookupProperty(depth0,"overviewText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"overviewText","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":26}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n</div>";
},"useData":true});