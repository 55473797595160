import _ from "lodash";
import L from "lodash/fp";
import inject from "scripts/ioc/inject";
import BaseGrowlAlertView from "scripts/views/baseGrowlAlertView";
import templateCurationMediaAddedGrowlAlert from "templates/curationMediaAddedGrowlAlert.hbs";

class CurationMediaAddedGrowlAlertView extends BaseGrowlAlertView {
  get template() {
    return templateCurationMediaAddedGrowlAlert;
  }

  get events() {
    return _.extend({}, super.events, {
      "click #bb-curation-media-added-view-button": "onClickView",
      "click .close": "onClickClose",
    });
  }

  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.curationId = options.curationId;
  }

  render() {
    const contentAdded = L.get(
      ["curation", "contentAdded"],
      this.i18nextService.getGlossary(),
    );
    const view = L.get(["button", "view"], this.i18nextService.getCommon());
    const close = L.get(
      ["ariaLabel", "close"],
      this.i18nextService.getAttributes(),
    );

    this.$el.html(
      this.template({
        i18n: {
          close,
          contentAdded,
          view,
        },
        curationId: this.curationId,
      }),
    );

    this.animateIn();

    return this;
  }

  onClickView() {
    // User navigated to 'manage curation' page via anchor href.
    this.trigger("close");
  }

  onClickClose() {
    this.trigger("close");

    return false;
  }
}

export default CurationMediaAddedGrowlAlertView;
