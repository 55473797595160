import _ from "lodash";
import Backbone from "backbone";
import BaseApiModel from "scripts/models/baseApiModel";

const chan = Backbone.Radio.channel;

class CommentModel extends BaseApiModel {
  get idAttribute() {
    return "commentId";
  }

  get defaults() {
    return {
      author: {
        authorId: "",
        username: "",
        displayName: "",
        avatarSmall: "",
        avatarLarge: "",
      },
      isApproved: true,
      isDeleted: false,
      isEdited: false,
      isFlagged: false,
      isHighlighted: false,
      isSpam: false,
      score: 0,
      userScore: 0,
      userVote: "",
      commentId: "",
      parentId: "",
      created: new Date(),
      htmlMessage: "",
      textMessage: "",
    };
  }

  constructor(attrs, options) {
    super(attrs, options);
    this.disqusModel = attrs.disqusModel;
  }

  initialize(attrs) {
    this.disqusModel = attrs.disqusModel;

    // If this comment has a parent comment, use the collection to attach it to the parent
    if (this.get("parentId")) {
      this.collection.addChild(this);
    }

    // Convert the created date into a message
    this.set(
      "createdMessage",
      this.generateCreatedMessage(this.get("created")),
    );

    // Add currently unattached child comments to this comment
    this.children = new Backbone.Collection();
    this.children.add(this.collection && this.collection.getChildren(this));
  }

  sync(method, model, options) {
    if (
      method === "create" ||
      (method === "update" && !model.get("commentId"))
    ) {
      method = "create";
      options = this.createComment(model, options);
    } else if (method === "update") {
      options = this.updateComment(model, options);
    } else if (method === "delete") {
      options = this.deleteComment(model, options);
    }

    return Backbone.sync.call(this, method, model, options);
  }

  parse(response) {
    response = response.response || response;

    /**
         * The 'disqusModel' property is usually in the response when a comment model
         * is created from a collection.
         */
    if ("disqusModel" in response) {
      this.disqusModel = response.disqusModel;
    }

    let data = {
      author: {
        authorId: response.author.id,
        username: response.author.username,
        displayName: response.author.name,
        avatarLarge: response.author.avatar.permalink,
        avatarSmall: response.author.avatar.small.permalink,
      },
      commentId: response.id,
      parentId: response.parent,
      created: new Date(Date.parse(response.createdAt)),
      createdMessage: "",
      htmlMessage: response.message,
      textMessage: response.raw_message,
      score: response.points,
      userScore: response.userScore,
    };

    data.createdMessage = this.generateCreatedMessage(data.created);
    data.editable =
      this.disqusModel.get("disqusUsername") === data.author.username;
    data.canReply = this.disqusModel.has("disqusAuthentication");
    data.canDelete =
      this.disqusModel.get("disqusUsername") === data.author.username;

    return _.extend({}, this.defaults, data);
  }

  createComment(model, options) {
    options.url = `${process.env
      .PATRON_UI_DISQUS_URL_ROOT}/api/3.0/posts/create.json`;
    options.type = "post";
    options.data = {
      message: model.get("textMessage"),
      parent: model.get("parentId"),
      thread: this.disqusModel.get("disqusThreadId"),
      api_key: this.disqusModel.get("disqusKey"),
      remote_auth: this.disqusModel.get("disqusAuthentication"),
    };

    // Convert data into request parameters
    options.data = (options.data && $.param(options.data)) || options.data;

    return options;
  }

  updateComment(model, options) {
    const userId = chan("security")
      .request("user")
      .getUserId();
    const disqusThread = this.disqusModel.get("disqusThread");
    const commentId = model.get("commentId");

    options = this.syncOptions(options);
    options.url = `${this
      .apiUrlRoot}/users/${userId}/comments/${disqusThread}/${commentId}`;
    options.type = "post";
    options.contentType = "application/json";
    options.data = {
      message: model.get("textMessage"),
    };

    // Convert data into JSON
    options.data =
      (options.data && JSON.stringify(options.data)) || options.data;

    return options;
  }

  deleteComment(model, options) {
    const userId = chan("security")
      .request("user")
      .getUserId();
    const disqusThread = this.disqusModel.get("disqusThread");
    const commentId = model.get("commentId");

    options = this.syncOptions(options);
    options.url = `${this
      .apiUrlRoot}/users/${userId}/comments/${disqusThread}/${commentId}`;
    options.type = "delete";

    return options;
  }

  generateCreatedMessage(created) {
    let difference = new Date().getTime() - created.getTime();
    let seconds = difference / 1000;
    let minutes = seconds / 60;
    let hours = minutes / 60;
    let days = Math.max(Math.floor(hours / 24), 1);
    let weeks = Math.max(Math.floor(days / 7), 1);
    let months = Math.max(Math.floor(weeks / 4), 1);
    let years = Math.max(Math.floor(months / 12), 1);

    if (seconds < 60) {
      // Less than 60 seconds ago
      return "a few seconds ago";
    } else if (minutes < 60) {
      // Less than 60 minutes ago
      return "a few minutes ago";
    } else if (hours < 24) {
      // Less than 24 hours ago
      return "a few hours ago";
    } else if (days < 7) {
      // Less than 7 days ago
      return "%x day%s ago"
        .replace("%x", days)
        .replace("%s", days !== 1 ? "s" : "");
    } else if (weeks < 4) {
      // Less than 4 weeks ago
      return "%x week%s ago"
        .replace("%x", weeks)
        .replace("%s", weeks !== 1 ? "s" : "");
    } else if (months < 12) {
      // Less than 12 months ago
      return "%x month%s ago"
        .replace("%x", months)
        .replace("%s", months !== 1 ? "s" : "");
    } else {
      // X years ago
      return "%x year%s ago"
        .replace("%x", years)
        .replace("%s", years !== 1 ? "s" : "");
    }
  }

  addChild(child) {
    this.children.add(child);
  }
}

export default CommentModel;
