import Promise from "bluebird";
import BaseView from "scripts/views/baseView";
import HybridPageableMediaCollection from "scripts/collections/hybridPageableMediaCollection";
import CategoryCollection from "scripts/collections/categoryCollection";
import { redirectToFragment } from "scripts/utils/routerHelpers";
import inject from "scripts/ioc/inject";
import template from "templates/newHomePage.hbs";
import templateCategoryTile from "templates/categoryTile.hbs";
import _ from "lodash";
import L from "lodash/fp";

const chan = Backbone.Radio.channel;

class CategoryTile extends BaseView {
  get className() {
    return "bb-category-tile";
  }

  get events() {
    return _.extend({}, super.events, {
      "focus .tile-action": "onTileFocused",
    });
  }

  onTileFocused(event) {
    const $allTiles = $(".category-tile");
    $allTiles.removeClass("tile-selected");
    const $tile = $(event.target).parents(".category-tile");
    $tile.addClass("tile-selected");
  }

  render() {
    this.$el.html(
      templateCategoryTile({
        bisacCode: this.model.get("bisacCode"),
        name: this.model.get("name"),
        iconUrl: this.model.get("iconUrl"),
      }),
    );

    // this.$el.find(".bb-category-tile-thumbnail").css({
    //   "background-image": `url('${this.model.get("iconUrl")}')`,
    // });

    return this;
  }
}
class NewHomePageView extends BaseView {
  constructor(
    options,
    securityService = inject("securityService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);
    this.showMenu = options.showMenu;

    this.i18nextService = i18nextService;

    this.hideCurations =
      securityService
        .getUser()
        .getActiveOrganizationDynamicProperty("hideNewHomepageCurations") ===
      "1";

    if (!this.hideCurations) {
      this.curationCollection = new HybridPageableMediaCollection([], {
        path: "/curations",
        state: {
          currentPage: 0,
          pageSize: 10,
        },
      });
    }

    this.categoryCollection = new CategoryCollection();
  }

  get className() {
    return "bb-new-home-page";
  }

  get events() {
    return {
      "submit #target-new-home-search-form": "onSubmit",
    };
  }

  onSubmit() {
    const $input = $("#target-new-home-search-form").find("input");
    const query = $input.val();

    if (query) {
      $input.blur();

      const urlFragment = `search-results/${encodeURIComponent(
        "g=" + encodeURIComponent(query),
      )}`;

      redirectToFragment(urlFragment);
    }

    return false;
  }

  render() {
    this.$el.html(template({ i18n: this.getTranslations() }));

    if (this.categoryCollection.length) {
      this.$el.find(".bb-new-home-categories-section").removeClass("hide");

      const categoryTilesContainer = this.$el.find(
        ".bb-category-tiles-container",
      );

      this.categoryCollection.forEach(model => {
        this.addSubView(new CategoryTile({ model }))
          .appendTo(categoryTilesContainer)
          .render();
      });
    }

    if (this.showMenu) {
      chan("display").trigger("openMenuOverlay", true);
    }

    return this;
  }

  getTranslations() {
    const attributes = this.i18nextService.getAttributes();

    const advanced = L.get(["advanced"], this.i18nextService.getCommon());

    const { ariaLabel, placeholder } = attributes;

    return {
      advanced,
      search: {
        ariaLabel: L.get(["search", "singular"], ariaLabel),
        placeholder: L.get(["search"], placeholder),
      },
    };
  }

  sync() {
    if (!this.hideCurations) {
      return Promise.all([
        this.curationCollection.fetch(),
        this.categoryCollection.fetch(),
      ]);
    } else {
      return this.categoryCollection.fetch();
    }
  }

  close() {
    if (this.curationsGlider) {
      this.curationsGlider.destroy();
    }

    super.close();
  }
}

export default NewHomePageView;
