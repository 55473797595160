import "fonts/biblioboardicons/styles.css";
import "less/app.less";
import Backbone from "backbone";
import Router from "scripts/router";
import AppView from "scripts/views/appView";
import inject from "scripts/ioc/inject";
import ErrorPageModel from "scripts/models/errorPageModel";
import ErrorPageView from "scripts/views/errorPageView";

class App {
  constructor(
    connectionService = inject("connectionService"),
    bookshelfService = inject("bookshelfService"),
    securityService = inject("securityService"),
    featureFlagsService = inject("featureFlagsService"),
    i18nextService = inject("i18nextService"),
  ) {
    this.connectionService = connectionService;
    this.bookshelfService = bookshelfService;
    this.securityService = securityService;
    this.featureFlagsService = featureFlagsService;
    this.i18nextService = i18nextService;
  }

  start() {
    console.log("Starting application...");

    this.appView = new AppView();

    this.appView.htmlOf($("#bb-app-region")).render();

    this.router = new Router();

    this.featureFlagsService
      .initialize()
      .then(() => this.i18nextService.initialize())
      .then(() => this.securityService.initialize())
      .then(() => this.startHistory())
      .catch(error => this.showErrorScreen(error));
  }

  startHistory() {
    console.log("Starting history...");

    Backbone.history.start({
      pushState: process.env.PATRON_UI_PUSHSTATE === "true",
    });
  }

  showErrorScreen(error) {
    console.error("An error occurred during app initialization: %O", error);

    return this.appView.showScreenView(
      new ErrorPageView({
        model: new ErrorPageModel({
          error: error,
        }),
      }),
    );
  }
}

export default App;
