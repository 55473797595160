import _ from "lodash";
import Backbone from "backbone";
import BaseView from "scripts/views/baseView";
import Salvattore from "../../bower_components/salvattore/dist/salvattore";

const chan = Backbone.Radio.channel;

class BaseSalvattoreGridView extends BaseView {
  close(...args) {
    if (this.$salvattoreGrid) {
      Salvattore.unregisterGrid(this.$salvattoreGrid.get(0));
    }

    super.close(...args);
  }

  initializeSalvattoreGrid($grid) {
    this.tileViews = [];

    Salvattore.registerGrid($grid.get(0));

    this.$salvattoreGrid = $grid;

    if (this.facetedSearchGrid === true) {
      $grid.addClass("bb-salvattore-grid-search");
    }

    let appStyleSheet = document.querySelector("link[href*=app\\.css]");
    console.log(appStyleSheet);
    if (!appStyleSheet || !appStyleSheet.sheet || !appStyleSheet.sheet.cssRules) {
      Salvattore.recreateColumns($grid.get(0));
      chan("display").on(
        "window:resize",
        () => {
          Salvattore.recreateColumns($grid.get(0));
        },
        this,
      );
    }
  }

  addGridTileViews(tileViews) {
    tileViews = [].concat(tileViews);

    tileViews.forEach(this.addSubView.bind(this));

    let viewElements = this.elementsOfViews(tileViews);

    this.tileViews = this.tileViews.concat(tileViews);

    this.appendToGrid(viewElements);

    this.$salvattoreGrid.imagesLoaded().always(_.partial(this.trigger, "imagesLoaded", this));
  }

  removeGridTileViews(tileViews) {
    tileViews = [].concat(tileViews);

    // remove all the elements from the grid
    this.tileViews.forEach(view => {
      view.$el.remove();
    });

    // close the views to be removed
    this.closeSubViews(tileViews);

    this.tileViews = _.xor(this.tileViews, tileViews);

    // add back the elements that weren't removed
    this.appendToGrid(
      this.tileViews.map(view => {
        view.delegateEventsRecursively();
        return view.$el;
      }),
    );
  }

  removeAllGridTileViews() {
    this.removeGridTileViews(this.tileViews);
  }

  elementsOfViews(tileViews) {
    return tileViews.map(view => {
      return view.$el;
    });
  }

  appendToGrid($items) {
    let gridElement = this.$salvattoreGrid.get(0);

    let itemElements = _.map([].concat($items), $item => {
      return $item.get(0);
    });

    Salvattore.appendElements(gridElement, itemElements);
  }
}

export default BaseSalvattoreGridView;
