import L from "lodash/fp";
import Backbone from "backbone";
import { complement } from "scripts/utils/generalHelpers";
import { normalizeFragment } from "scripts/utils/urlUtil";

export const ROUTES = {
  // Home
  "home(/)(:page)(/)(:expandedCurationIds)(/)": "home",

  // Log In
  "login(/)": "login",

  // Sign Up
  "sign-up/suggested-organization(/)": "signUpSuggestedOrganization",
  "sign-up/choose-organization(/)": "signUpChooseOrganization",
  "sign-up/create-profile(/)(:type)(/)(:platform)(/)(:referralUrl)(/)": "signUpCreateProfile",
  "manage-organizations(/)": "manageOrganizations",
  "associate-organization/:organizationId(/)(:platform)(/)": "associateOrganization",

  // MARC URLs for Anthology
  "anthology/appid/:appId(/)": "legacyAnthologyUrlForward",

  // Anthology / Textbook
  "anthology/:anthologyId(/)(:subPageType)(/)(:presentationType)(/)(:contentType)(/)(:page)(/)": "anthology",
  "textbook/:anthologyId(/)(:subPageType)(/)(:presentationType)(/)(:contentType)(/)(:page)(/)": "anthology",

  // Anthology Collection / Chapter
  "anthology-collection/:anthologyId/:anthologyCollectionId(/)(:presentationType)(/)(:contentType)(/)(:page)(/)":
    "anthologyCollection",
  "textbook-chapter/:anthologyId/:anthologyCollectionId(/)(:presentationType)(/)(:contentType)(/)(:page)(/)":
    "anthologyCollection",

  // Collection
  "collection/:collectionId(/)(:presentationType)(/)(:contentType)(/)(:page)(/)": "collection",

  // Categories
  "categories(/)(:presentationType)(/)": "categories",

  // MARC URLs for Content Details
  "module/modid/:moduleId(/)": "legacyModuleUrlForward",

  // Module
  "module/:internalCode(/)(:presentationType)(/)(:page)(/)": "module",

  // Modules
  "modules(/)(:presentationType)(/)": "modules",

  // Curations
  "curations(/)(:presentationType)(/)": "curations",
  "curation/:mediaId(/)(:presentationType)(/)(:contentType)(/)(:page)(/)": "curation",
  "manage-curations(/)": "manageCurations",
  "manage-curation/:mediaId(/)": "manageCuration",
  "choose-curation-thumbnail/:mediaId(/)": "chooseCurationThumbnail",

  // My Board
  "my-board/favorites(/)(:presentationType)(/)": "favorites",
  "my-board/bookmarks(/)(:presentationType)(/)": "bookmarks",
  "my-board/recents(/)(:presentationType)(/)": "recents",
  "my-board/bookshelf(/)(:presentationType)(/)": "bookshelf",
  "my-board(/)": "myBoard",

  // Marc URLs for Content Details
  "content/acid/:acid(/)": "legacyContentUrlForward",

  // Content Details
  "content/:contentType(/)(:mediaId)(/)(:subPageType)(/)": "content",
  "book/(:mediaId)(/)": "content",
  "other_document/(:mediaId)(/)": "content",
  "document/(:mediaId)(/)": "content",
  "chapter/(:mediaId)(/)": "content",
  "audio/(:mediaId)(/)": "content",
  "video/(:mediaId)(/)": "content",
  "image/(:mediaId)(/)": "content",
  "article/(:mediaId)(/)": "content",

  // Search Results
  "advanced-search(/)": "advancedSearch",
  "search-results/:query(/)(:presentationType)(/)(:page)(/)": "searchResults",

  // Viewer
  "pdf-viewer/:mediaId(/)(:pageSequence)(/)(:presentationType)(/)": "viewer",
  "image-viewer/:mediaId(/)": "viewer",
  "image-book-viewer/:mediaId(/)(:pageSequence)(/)(:presentationType)(/)": "viewer",
  "epub-viewer/:mediaId(/)": "viewer",
  "viewer/:mediaId(/)(:pageSequence)(/)(:presentationType)(/)": "viewer",

  // Which organization chooser
  "which-biblioboard(/)": "whichOrganization",
  "welcome(/)": "whichOrganization",
  "which-organization(/)": "whichOrganization",

  // Verify Email
  "verify-email(/)": "verifyEmail",

  // Generic
  "about(/)": "about",
  "eula(/)": "eula",
  "privacy(/)": "privacy",
  "cookie-policy(/)": "cookiePolicy",
  "metadata-info(/)": "metadataInfo",

  // Request Access
  "request-access/(:mediaId)(/)": "requestAccess",

  // Errors
  "error/:statusCode(/)": "error",

  // Diagnostics
  "diagnostics(/)": "diagnostics",

  // App Scheme Login
  "app-scheme-login(/)": "appSchemeLogin",

  // Developer
  "developer(/)": "developer",
};

export const NON_MEDIA_ROUTE_TITLES = {
  home: "BiblioBoard Home",
  login: "Login",
  signUpSuggestedOrganization: "Sign Up",
  signUpChooseOrganization: "Sign Up - Choose Organization",
  signUpCreateProfile: "Sign Up - Create Profile",
  categories: "Categories",
  curations: "Curations",
  modules: "Modules",
  favorites: "My Board - Favorites",
  bookmarks: "My Board - Bookmarks",
  recents: "My Board - Recents",
  bookshelf: "Offline Bookshelf",
  myBoard: "My Board",
  searchResults: "Search Results",
  advancedSearch: "Advanced Search",
  verifyEmail: "Verify Email",
  about: "About Us",
  eula: "Legal Notices",
  privacy: "Legal Notices",
  diagnostics: "Diagnostics",
  manageOrganizations: "Manage Organizations",
};

export const ROUTES_INSECURE = ["login", "error", "diagnostics", "verifyEmail"];

export const ROUTES_OFFLINE = ["viewer", "error", "login", "bookshelf"];

export const ROUTES_PROFILE_REQUIRED = [
  "myBoard",
  "favorites",
  "bookmarks",
  "recents",
  "bookshelf",
  "manageOrganizations",
  "associateOrganization",
];

export const ROUTES_MEDIA = [
  "module",
  "anthology",
  "collection",
  "content",
  "curation",
  "viewer",
  "pdfViewer",
  "anthologyCollection",
];

const APP_SCHEMES = {
  login: /biblioboardlibrary:\/\/login\/([^/^?]+)/,
  manageOrganizations: /biblioboardlibrary:\/\/manage-organizations\/([^/^?]+)/,
  signUp: /biblioboardlibrary:\/\/sign-up\/verified\/([^/^?]+)/, // deprecated
};

export const isInsecureRoute = L.contains(L.__, ROUTES_INSECURE);
export const isProfileRequired = L.contains(L.__, ROUTES_PROFILE_REQUIRED);
export const isOfflineRoute = L.contains(L.__, ROUTES_OFFLINE);

export const pushFragment = fragment => Backbone.history.navigate(fragment);

export const redirectToFragment = fragment => Backbone.history.navigate(fragment, { trigger: true });

export const refreshRoute = fragment => {
  if (!fragment) {
    fragment = "/" + Backbone.history.fragment;
  }

  Backbone.history.navigate(fragment);
  Backbone.history.loadUrl(fragment);
};

export const replaceFragment = fragment => Backbone.history.navigate(fragment, { replace: true });

export const replaceAndLoadFragment = fragment =>
  Backbone.history.navigate(fragment, {
    replace: true,
    trigger: true,
  });

export const goBack = () => {
  Backbone.history.history.back();
};

export const getCurrentFragment = () => Backbone.history.getFragment();

export const isRouteAuthorized = (route, user) => {
  if (isInsecureRoute(route)) {
    return true;
  } else if (isProfileRequired(route)) {
    if (user.isAuthenticated() && user.hasProfile()) {
      return true;
    } else {
      return false;
    }
  } else if (user.isAuthenticated()) {
    return true;
  } else {
    return false;
  }
};

export const isMediaRoute = L.contains(L.__, ROUTES_MEDIA);

export const getMediaIdFromRouteProps = ({ route, params }) => {
  if (
    route === "module" ||
    route === "anthology" ||
    route === "collection" ||
    route === "content" ||
    route === "curation" ||
    route === "viewer" ||
    route === "pdfViewer"
  ) {
    return params[0];
  } else if (route === "anthologyCollection") {
    return params[1];
  }
};

export const getSplashScreenShown = sessionStorageService =>
  sessionStorageService.getAttributes().then(L.prop("splashScreenShown"));

export const setSplashScreenShown = sessionStorageService =>
  sessionStorageService.setAttributes({
    splashScreenShown: true,
  });

const routesToRegexes = L.compose(
  L.map(([routeExpression, routeName]) => ({
    regex: Backbone.Router.prototype._routeToRegExp(routeExpression),
    route: routeName,
  })),
  L.toPairs,
);

export const getRouteNameAndRegexForFragment = (fragment, routes) =>
  L.find(({ regex }) => regex.test(normalizeFragment(fragment)), routesToRegexes(routes));

const extractParameters = Backbone.Router.prototype._extractParameters;

export const parseFragment = (fragment, routes = ROUTES) => {
  const props = !L.isNil(fragment) ? getRouteNameAndRegexForFragment(fragment, routes) : {};

  if (!L.isEmpty(props)) {
    return {
      fragment,
      route: props.route,
      params: L.filter(complement(L.isNil), extractParameters(props.regex, normalizeFragment(fragment))),
    };
  } else {
    return {};
  }
};

export const parseAppSchemeUrl = url => {
  const appScheme = L.compose(
    L.find(({ results }) => !L.isNil(results)),
    L.map(([route, regex]) => ({
      route,
      regex,
      results: regex.exec(url),
    })),
  )(L.toPairs(APP_SCHEMES));

  if (appScheme) {
    return {
      route: appScheme.route,
      params: [appScheme.results[1]],
    };
  }

  return null;
};
