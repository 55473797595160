import Promise from "bluebird";
import Backbone from "backbone";
import inject from "scripts/ioc/inject";
import BaseView from "scripts/views/baseView";
import MyBoardPageNavView from "scripts/views/myBoardPageNavView";

import templateMyBoardPage from "templates/myBoardPage.hbs";

const chan = Backbone.Radio.channel;

class MyBoardPageView extends BaseView {
  constructor(options, breadcrumbService = inject("breadcrumbService")) {
    super(options);

    this.breadcrumbService = breadcrumbService;

    this.navView = this.addSubView(
      new MyBoardPageNavView({
        model: this.model,
      }),
    );

    this.subPageView = this.addSubView(options.subPageView);

    this.model.on(
      "change:presentationType",
      this.onPresentationTypeChangeSaveUserPreference,
      this,
    );

    chan("controllerView").reply(
      "myBoardPageView",
      this.replyMyBoardPageView,
      this,
    );
    chan("controllerView").reply(
      "bookshelfSubPageView",
      this.replyBookshelfSubPageView,
      this,
    );
  }

  get template() {
    return templateMyBoardPage;
  }

  replyMyBoardPageView() {
    return this;
  }

  replyBookshelfSubPageView() {
    if (this.model.get("subPageType") === "bookshelf") {
      return this.subPageView;
    }
  }

  render() {
    this.$el.html(this.template(this.model.toJSON()));

    this.navView
      .attachTo(this.$el.find("#bb-my-board-page-nav-region"))
      .render();

    this.subPageView
      .attachTo(this.$el.find("#bb-my-board-sub-page-region"))
      .render();

    if (this.subPageView.getBreadcrumbInfo) {
      this.breadcrumbService.addBreadcrumb(
        this.subPageView.getBreadcrumbInfo(),
      );
    }

    return this;
  }

  sync() {
    return this.subPageView.fetch();
  }

  //TODO: make common
  showSubPageView(subPageView) {
    if (this.subPageViewPromise && this.subPageViewPromise.isPending()) {
      this.subPageViewPromise.cancel();
    }

    chan("display").trigger("bodyViewLoading");

    this.subPageViewPromise = this.showSubView(
      "subPageView",
      subPageView,
      $("#bb-my-board-sub-page-region"),
    )
      .then(() => {
        if (subPageView.getBreadcrumbInfo) {
          this.breadcrumbService.addBreadcrumb(subPageView.getBreadcrumbInfo());
        }

        document.title = this.getDocumentTitle();
      })
      .catch(Promise.CancellationError, () => {
        // swallow cancellation
        console.debug("show sub page view cancelled");
      })
      .finally(value => {
        chan("display").trigger("bodyViewLoaded");

        if (this.navView.isStuck()) {
          $(window).scrollTop(this.navView.scrollTopToStick());
        }

        return value;
      });

    return this.subPageViewPromise;
  }

  getDocumentTitle() {
    return this.subPageView.getDocumentTitle
      ? this.subPageView.getDocumentTitle()
      : "My Board";
  }
}

export default MyBoardPageView;
