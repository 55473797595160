var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"bb-cookie-settings-modal\" class=\"modal-body text-center\">\n  <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\"><span\n    aria-hidden=\"true\">&times;</span></button>\n\n  <h1 class=\"modal-title text-center clr-primary\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"settings") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</h1>\n\n  <hr>\n\n  <p>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"settings") : stack1)) != null ? lookupProperty(stack1,"body") : stack1), depth0))
    + "</p>\n\n  <div class=\"m-t-20\">\n    <button class=\"btn-outline btn-outline-brand-primary btn-block btn-chktoggle btn-chktoggle-checked\" disabled >"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"required") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</button>\n    <p class=\"bb-cookie-description\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"required") : stack1)) != null ? lookupProperty(stack1,"body") : stack1), depth0))
    + "</p>\n  </div>\n  <div>\n    <button id=\"bb-cookie-settings-analytics\" class=\"btn-outline btn-outline-brand-primary btn-block btn-chktoggle btn-chktoggle-checked\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"research") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</button>\n    <p class=\"bb-cookie-description\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"research") : stack1)) != null ? lookupProperty(stack1,"body") : stack1), depth0))
    + "</p>\n  </div>\n\n  <div class=\"m-t-20\">\n    <p>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"cookiePolicy") : stack1)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + " <a href=\"/cookie-policy\" target=\"_blank\" rel=\"noreferrer noopener\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"cookiePolicy") : stack1)) != null ? lookupProperty(stack1,"link") : stack1), depth0))
    + "</a>.</p>\n    <p>To learn more about how we use and protect your data, please see our <a href=\"/privacy\" target=\"_blank\" rel=\"noreferrer noopener\">privacy policy</a>.</p>\n  </div>\n  <div class=\"m-tb-30\">\n    <button id=\"bb-cookie-settings-modal-confirm\" class=\"btn-solid btn-solid-brand-primary\" data-dismiss=\"modal\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"confirm") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</button>\n  </div>\n</div>";
},"useData":true});