import _ from "lodash";

//TODO: remove this util
export default {
  usernameValidationRegex: /^[A-Za-z0-9@._-]+$/,
  passwordValidationRegex: /^(?=.*\d).{8,}$/,

  /**
   * Given a contributor object, return an object with a formatted name and role.
   *
   * Assumes all contributors have a role.
   * Assumes a person will have a last name and a corporation will have a full name.
   */
  getFormattedContributor: function(contributor) {
    var nameParts = [];

    if ("name" in contributor) {
      // this contributor has already been formatted
      return contributor;
    }

    if ("PERSON" === contributor.contributorType) {
      if ("prefix" in contributor) {
        nameParts.push(contributor.prefix);
      }
      if ("firstName" in contributor) {
        nameParts.push(contributor.firstName);
      }
      if ("middleName" in contributor) {
        nameParts.push(contributor.middleName);
      }
      nameParts.push(contributor.lastName);
      if ("suffix" in contributor) {
        nameParts.push(contributor.suffix);
      }
    } else if ("CORPORATION" === contributor.contributorType) {
      nameParts.push(contributor.fullName);
    }

    return {
      name: nameParts.join(" "),
      role: contributor.role.label,
      fullName: contributor.fullName,
    };
  },

  /**
   * Given an array of contributor objects, return a new array of
   * ordered contributor objects with a formatted name and role.
   */
  getFormattedContributors: function(contributors) {
    var sortedContributors = _.sortBy(contributors, "displayOrder");

    return _.map(
      sortedContributors,
      _.bind(function(contributor) {
        return this.getFormattedContributor(contributor);
      }, this),
    );
  },
};
