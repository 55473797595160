var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"bb-masthead-overlay-menu-close-container\" class=\"container hide\" role=\"navigation\">\n    <div class=\"row\">\n        <div class=\"col-xs-3\"></div>\n        <div class=\"col-xs-6\"></div>\n        <div class=\"col-xs-3 text-right\">\n            <button id=\"bb-masthead-overlay-menu-close-btn\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"closeMainMenu") : stack1), depth0))
    + "\">\n                <i class=\"bbico bbico-times\"></i>\n            </button>\n        </div>\n    </div>\n</div>\n<div id=\"bb-main-masthead-overlay\" role=\"navigation\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"overlayMainMenu") : stack1), depth0))
    + "\">\n    <div id=\"bb-masthead-overlay-main-menu-region\" class=\"hide\"></div>\n    <div id=\"bb-masthead-overlay-search-menu-region\" class=\"hide\"></div>\n</div>";
},"useData":true});