import Controller from "scripts/controllers/controller";
import ModuleModel from "scripts/models/moduleModel";
import { replaceAndLoadFragment } from "scripts/utils/routerHelpers";

class LegacyModuleUrlForwardController extends Controller {
  get route() {
    return "route:legacyModuleUrlForward";
  }

  replyRoute(route, moduleId) {
    return new ModuleModel({
      id: moduleId,
    })
      .fetch()
      .then(moduleModel => {
        const internalCode = moduleModel.get("internalCode");
        replaceAndLoadFragment(`/module/${internalCode}`);
      });
  }
}

export default LegacyModuleUrlForwardController;
