var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"headerContainer") : stack1), depth0))
    + "\">\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"header") : stack1), depth0))
    + "\">\n        <a aria-label=\"logo links to home\" href=\"/home\">\n            <img alt=\"logo\" class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"logo") : stack1), depth0))
    + "\" src=\"" + require("/opt/atlassian/pipelines/agent/build/node_modules/handlebars-template-loader/file-loader.js?url=..%2F..%2Fimages%2Fbiblioboard.svg!../../images/biblioboard.svg") + "\"/>\n        </a>\n    </div>\n</div>\n\n<div class=\"target-bannerRegion "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"bannerRegion") : stack1), depth0))
    + "\"></div>\n\n<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"body") : stack1), depth0))
    + "\">\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"formBox") : stack1), depth0))
    + "\">\n        <h1>If you'd like to gain access to this content through your library, let us know!</h1>\n        <div class=\"target-requestAccessFormRegion\"></div>\n    </div>\n</div>\n\n<div class=\"target-footerRegion\"></div>\n\n";
},"useData":true});