var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--BAR COL-->\n<div class=\"bb-list-tile-bar-column\">\n  <div class=\"list-header\"></div>\n</div>\n\n<!--COPY COL-->\n<div class=\"bb-list-tile-details-column p-l-30\">\n  <a class=\"btn-view\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"viewerUrl") || (depth0 != null ? lookupProperty(depth0,"viewerUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"viewerUrl","hash":{},"data":data,"loc":{"start":{"line":8,"column":28},"end":{"line":8,"column":41}}}) : helper)))
    + "\">\n    <h2 class=\"h3 bb-list-tile-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":9,"column":38},"end":{"line":9,"column":47}}}) : helper)))
    + "</h2>\n  </a>\n  <div class=\"bb-list-tile-contributor-region\"></div>\n</div>\n\n<!--BUTTONS COL-->\n<div class=\"bb-list-tile-buttons-column\">\n  <div class=\"bb-list-tile-text-buttons-row\">\n    <a class=\"btn btn-view clr-written\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"viewerUrl") || (depth0 != null ? lookupProperty(depth0,"viewerUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"viewerUrl","hash":{},"data":data,"loc":{"start":{"line":17,"column":46},"end":{"line":17,"column":59}}}) : helper)))
    + "\" aria-label=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"readName") : stack1), depth0))
    + "\">\n      <span class=\"fnt-bld\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"read") : stack1), depth0))
    + "</span>\n    </a>\n  </div>\n</div>";
},"useData":true});