import BaseModalView from "scripts/views/baseModalView";
import { selectedTerms } from "scripts/views/search/facetMapper";
import { chosenAriaLabels } from "scripts/utils/chosenAriaLabels";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";

import templateFacetedSearchComboBoxMultiSelectModal from "templates/search/facetedSearchComboBoxMultiSelectMenuItem.hbs";

class FacetedSearchComboBoxMultiSelectMenuItemView extends BaseModalView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;
  }

  get events() {
    return {
      "click .bb-faceted-search-facet-multiselect-remove-button":
        "onRemoveTerm",
    };
  }

  get className() {
    return "faceted-search-menu-item";
  }

  get template() {
    return templateFacetedSearchComboBoxMultiSelectModal;
  }

  render() {
    const attributes = this.i18nextService.getAttributes();
    const common = this.i18nextService.getCommon();

    const { search } = this.i18nextService.getGlossary();

    const facetLabelProp = L.camelCase(this.model.get("facetLabel"));

    const facetLabel =
      facetLabelProp === "category"
        ? search.category.singular
        : L.has([facetLabelProp], search) && L.isString(search[facetLabelProp])
        ? search[facetLabelProp]
        : this.model.get("facetLabel");

    const chosenTermCodes = this.model.get("chosenTermCodes");
    const terms = selectedTerms(this.model.get("facetValues"), chosenTermCodes);

    const remove = L.get(["button", "remove"], common);
    const placeholder = L.get(["data", "placeholder"], attributes);

    this.$el.html(
      this.template({
        i18n: {
          facetLabel,
          placeholder,
          remove,
        },
        facetLabel: this.model.get("facetLabel"),
        terms,
      }),
    );

    this.$el
      .find(".selectize-combo")
      .selectize({
        create: false,
        maxItems: 1,
        closeAfterSelect: true,
        highlight: false,
        selectOnTab: false,
        onChange: () => {
          $(".selectize-input input[placeholder]").attr(
            "style",
            "width: 100%;",
          );
        },
        onItemAdd: value => {
          chosenTermCodes.push(value);
          this.trigger("apply", this.model.get("facetParam"), chosenTermCodes);
        },
      })
      .each(chosenAriaLabels);

    return this;
  }

  onRemoveTerm(event) {
    const value = event.currentTarget.value;
    const chosenTermCodes = this.model.get("chosenTermCodes");
    const newTermCodes = chosenTermCodes.filter(term => term !== value);

    this.trigger("apply", this.model.get("facetParam"), newTermCodes);
  }
}

export default FacetedSearchComboBoxMultiSelectMenuItemView;
