import _ from "lodash";
import L from "lodash/fp";
import Promise from "bluebird";
import Backbone from "backbone";
import HybridPageableMediaCollection from "scripts/collections/hybridPageableMediaCollection";
import inject from "scripts/ioc/inject";
import HybridMediaCollection from "scripts/collections/hybridMediaCollection";
import BreadcrumbModel from "scripts/models/breadcrumbModel";
import BaseView from "scripts/views/baseView";
import CurationSalvattoreGridView from "scripts/views/curationSalvattoreGridView";
import {
  getCurrentFragment,
  replaceFragment,
} from "scripts/utils/routerHelpers";

import templateHomePage from "templates/homePage.hbs";
import templateSeparator from "templates/separator.hbs";

const chan = Backbone.Radio.channel;

class HomePageView extends BaseView {
  constructor(
    options,
    breadcrumbService = inject("breadcrumbService"),
    googleAnalyticsService = inject("googleAnalyticsService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);
    this.showMenu = options.showMenu;

    this.breadcrumbService = breadcrumbService;
    this.googleAnalyticsService = googleAnalyticsService;
    this.i18nextService = i18nextService;

    const pageIndex = this.model.get("pageIndex");

    this.collection = new HybridPageableMediaCollection([], {
      path: "/curations",
      state: {
        currentPage: pageIndex,
        pageSize: 4,
      },
    });

    /**
     * Hack to satisfy Backbone.PageableCollection._checkState
     * if starting from a page other than 0.
     */
    if (pageIndex > 0) {
      this.collection.links[pageIndex] = this.collection.url;
    }
  }

  get template() {
    return templateHomePage;
  }

  get separatorTemplate() {
    return templateSeparator;
  }

  get className() {
    return "bb-home-page";
  }

  get events() {
    return {
      "click .bb-home-page-page-link": "onClickPageLink",
    };
  }

  render() {
    this.$el.html(
      this.template({
        i18n: this.getTranslations(),
        previousPageUrl: this.previousPageUrl(),
        nextPageUrl: this.nextPageUrl(),
      }),
    );

    this.curationViews.forEach((curationView, index) => {
      const $curationsRegion = $("#bb-home-page-curations-region");

      curationView.appendTo($curationsRegion).render();

      if (index < this.curationViews.length - 1) {
        $curationsRegion.append(this.separatorTemplate());
      }
    });

    if (this.showMenu) {
      chan("display").trigger("openMenuOverlay", true);
    }

    return this;
  }

  getTranslations() {
    const glossary = this.i18nextService.getGlossary();
    const attributes = this.i18nextService.getAttributes();
    const curation = L.flowRight([L.pick(["viewAll"]), L.get(["curation"])])(
      glossary,
    );
    const ariaLabel = L.flowRight([
      L.pick(["firstPage", "nextPage", "pager", "prevPage"]),
      L.get(["ariaLabel"]),
    ])(attributes);

    return { ariaLabel, curation };
  }

  sync() {
    return this.collection.fetch().then(this.fetchCurationViews.bind(this));
  }

  fetchCurationViews() {
    this.curationViews = this.collection.models.map(model => {
      const curationView = this.createCurationView(model);
      this.addSubView(curationView);
      return curationView;
    });

    return Promise.all(_.invokeMap(this.curationViews, "fetch"));
  }

  createCurationView(curation) {
    const curationId = curation.get("id");
    const curationMediaCollection = new HybridMediaCollection([], {
      path: `/curations/${curationId}/media`,
      queryStringParams: {
        limit: 20,
      },
    });

    curationMediaCollection.on("add", mediaModel => {
      mediaModel.set("referringParent", curation);
    });

    const gridView = new CurationSalvattoreGridView({
      collection: curationMediaCollection,
      model: curation,
      expanded:
        _.includes(this.model.get("expandedCurationIds"), curationId) ||
        this.collection.length === 1,
    });

    gridView.on("expanded", this.onCurationExpanded, this);

    return gridView;
  }

  previousPageUrl() {
    if (this.collection.hasPreviousPage()) {
      const previousPageIndex = this.collection.state.currentPage - 1;

      if (previousPageIndex === 0) {
        return "/home";
      } else {
        return `/home/${previousPageIndex + 1}`;
      }
    } else {
      return false;
    }
  }

  nextPageUrl() {
    if (this.collection.hasNextPage()) {
      const nextPageIndex = this.collection.state.currentPage + 1;

      return `/home/${nextPageIndex + 1}`;
    } else {
      return false;
    }
  }

  getBreadcrumbInfo() {
    return {
      breadcrumbModel: new BreadcrumbModel({
        ariaLabel: "Home",
        text: '<i class="bbico bbico-home"></i>',
        path: "#" + getCurrentFragment(),
        topLevel: true,
      }),
      hide: true,
    };
  }

  onCurationExpanded(expanded, curationId) {
    const currentFragment = getCurrentFragment();
    const urlComponents = currentFragment.split("/");
    const pageNumber = parseInt(urlComponents[1], 10) || 1;

    let expandedCurations = [];

    if (urlComponents[2]) {
      expandedCurations = urlComponents[2].split("|");
    }

    if (expanded) {
      expandedCurations.push(curationId);
    } else {
      expandedCurations = _.without(expandedCurations, curationId);
    }

    const newUrlFragment =
      "home/" + pageNumber + "/" + expandedCurations.join("|");

    replaceFragment(newUrlFragment);

    this.googleAnalyticsService.sendPageView();

    this.breadcrumbService.updateBreadcrumb(this.getBreadcrumbInfo());

    chan("history").request("replaceFragment", newUrlFragment);
  }

  onClickPageLink(event) {
    const $link = $(event.currentTarget);
    const fragment = $link.attr("href").slice(1);

    chan("history").request("replaceScrollTop", fragment, 0);
  }
}

export default HomePageView;
