import BaseModalView from "scripts/views/baseModalView";
import { selectedTerms } from "scripts/views/search/facetMapper";
import L from "lodash/fp";
import inject from "scripts/ioc/inject";
import templateFacetedSearchCheckboxMultiSelectModal from "templates/search/facetedSearchCheckboxMultiSelectMenuItem.hbs";

class FacetedSearchCheckboxMultiSelectMenuItemView extends BaseModalView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;
  }

  get events() {
    return {
      "click .btn-expand-toggle": "onToggle",
      "change .faceted-search-checkbox": "onCheckboxChange",
    };
  }

  get className() {
    return "faceted-search-menu-item";
  }

  get template() {
    return templateFacetedSearchCheckboxMultiSelectModal;
  }

  render() {
    const { search } = this.i18nextService.getGlossary();

    const facetLabelProp = L.camelCase(this.model.get("facetLabel"));

    const facetLabel = L.has([facetLabelProp], search) ? search[facetLabelProp] : this.model.get("facetLabel");

    const terms = selectedTerms(this.model.get("facetValues"), this.model.get("chosenTermCodes"));

    const selected = terms.filter(term => term.selected);
    const unselected = terms.filter(term => !term.selected);
    const orderedTerms = selected.concat(unselected);

    this.$el.html(
      this.template({
        i18n: { facetLabel },
        facetLabel: this.model.get("facetLabel"),
        terms: orderedTerms,
      }),
    );

    const $termElements = this.$el.find(".faceted-search-checkbox-wrapper");

    if ($termElements.length <= 4) {
      this.$el.find(".faceted-search-facet-widget-expand-toggle").addClass("hidden");
    } else if ($termElements.length > 4) {
      this.$el.find(".faceted-search-facet-widget-expand-toggle").removeClass("expanded");

      for (let i = 4; i < $termElements.length; i++) {
        const $termElement = $($termElements.get(i));
        $termElement.hide();
      }
    }

    return this;
  }

  onToggle() {
    const $expandToggle = this.$el.find(".faceted-search-facet-widget-expand-toggle");
    const $termElements = this.$el.find(".faceted-search-checkbox-wrapper");

    if ($expandToggle.hasClass("expanded")) {
      $expandToggle.removeClass("expanded");
      for (let i = 4; i < $termElements.length; i++) {
        const $termElement = $($termElements.get(i));
        $termElement.hide();
      }
    } else {
      $expandToggle.addClass("expanded");
      for (let i = 4; i < $termElements.length; i++) {
        const $termElement = $($termElements.get(i));
        $termElement.show();
      }
    }
  }

  onCheckboxChange(event) {
    const isChecked = event.currentTarget.checked;

    if (isChecked) {
      this.addTerm(event.currentTarget.value);
    } else {
      this.removeTerm(event.currentTarget.value);
    }
  }

  addTerm(value) {
    const chosenTermCodes = this.model.get("chosenTermCodes");

    chosenTermCodes.push(value);
    this.trigger("apply", this.model.get("facetParam"), chosenTermCodes);
    this.trigger("apply", this.model.get("facetParam"), chosenTermCodes);
  }

  removeTerm(value) {
    const chosenTermCodes = this.model.get("chosenTermCodes");
    const newTermCodes = chosenTermCodes.filter(term => term !== value);

    this.trigger("apply", this.model.get("facetParam"), newTermCodes);
    this.trigger("apply", this.model.get("facetParam"), newTermCodes);
  }
}

export default FacetedSearchCheckboxMultiSelectMenuItemView;
