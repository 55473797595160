var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"bb-growl-alert bb-growl-alert-bl d-none\">\n    <div class=\"contentwell bg-gray-lighter\">\n        <h2>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"session") : stack1)) != null ? lookupProperty(stack1,"existing") : stack1), depth0))
    + "</h2>\n    </div>\n\n    <div class=\"contentwell bg-white text-center\">\n        <button id=\"bb-app-update-growl-alert-refresh-button\"\n            class=\"btn-rounded btn-rounded-brand-primary\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"session") : stack1)) != null ? lookupProperty(stack1,"extend") : stack1), depth0))
    + "</button>\n    </div>\n</div>";
},"useData":true});