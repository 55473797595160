import L from "lodash/fp";
import BaseModel from "scripts/models/baseModel";
import { isRelativeUrl } from "scripts/utils/urlUtil";

class BasicApiModel extends BaseModel {
  get apiUrlRoot() {
    return process.env.PATRON_UI_API_URL_ROOT;
  }

  syncOptions(options) {
    let crossDomain = !isRelativeUrl(this.apiUrlRoot);

    // Backbone.PageableCollection needs options to be mutated, otherwise the xhr won't make it through to parse
    options = L.defaults(options || {}, {
      headers: {
        "X-Biblio-Audience": process.env.PATRON_UI_BIBLIO_AUDIENCE,
        "X-Auth-Token": this.get("token"),
      },
      crossDomain: crossDomain,
    });

    return options;
  }

  sync(method, model, options) {
    return super.sync(method, model, this.syncOptions(options));
  }
}

export default BasicApiModel;
