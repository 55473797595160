var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"content-details-nav\">\n    <div class=\"container\">\n        <div class=\"row-same-height row-full-height\">\n            <nav>\n                <div class=\"navigation-container\">\n                    <div class=\"anthology-navigation-wrapper\">\n\n                        <div class=\"navbar-header\">\n                            <div class=\"navbar-header-container\">\n                                <div class=\"fb-row fb-ai-center\">\n                                    <div class=\"active-tab\">\n                                        <div id=\"bb-content-details-page-nav-heading-collections\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"collection") : stack1)) != null ? lookupProperty(stack1,"plural") : stack1), depth0))
    + "</div>\n                                        <div id=\"bb-content-details-page-nav-heading-content\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"content") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</div>\n                                    </div>\n\n                                    <div class=\"m-l-10\">\n                                        <button type=\"button\" class=\"navbar-toggle collapsed\" data-toggle=\"collapse\" data-target=\"#anthology-cntntnavbar\">\n                                            <span class=\"sr-only\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"toggleNav") : stack1), depth0))
    + "</span>\n                                            <span class=\"fa fa-angle-down clr-primary\"></span>\n                                            <span class=\"fa fa-angle-up clr-primary\"></span>\n                                        </button>\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n\n\n                        <!-- Collect the nav links, forms, and other content for toggling -->\n                        <div id=\"bb-anthology-tab-list\" class=\"navbar-collapse collapse nav-tab-list\" role=\"navigation\" aria-label=\"Item details\">\n                            <ul class=\"nav\">\n                                <li id=\"bb-anthology-page-nav-item-collections\"><a\n                                        id=\"bb-anthology-page-nav-item-collections-link\"\n                                        href=\"/"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":33,"column":47},"end":{"line":33,"column":69}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":33,"column":70},"end":{"line":33,"column":85}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"collectionTypeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"collectionTypeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"collectionTypeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":33,"column":86},"end":{"line":33,"column":118}}}) : helper)))
    + "s/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":33,"column":120},"end":{"line":33,"column":140}}}) : helper)))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"collection") : stack1)) != null ? lookupProperty(stack1,"plural") : stack1), depth0))
    + "</a>\n                                </li>\n                                <li id=\"bb-anthology-page-nav-item-content\"><a\n                                        id=\"bb-anthology-page-nav-item-content-link\"\n                                        href=\"/"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":37,"column":47},"end":{"line":37,"column":69}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":37,"column":70},"end":{"line":37,"column":85}}}) : helper)))
    + "/content/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":37,"column":94},"end":{"line":37,"column":114}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"contentType") || (depth0 != null ? lookupProperty(depth0,"contentType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"contentType","hash":{},"data":data,"loc":{"start":{"line":37,"column":115},"end":{"line":37,"column":130}}}) : helper)))
    + "\">\n                                    "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"content") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</a>\n                                </li>\n                            </ul>\n                        </div>\n\n                        <div class=\"collection-view-toggle\">\n                            <!--switch view type button-->\n                            <a id=\"bb-anthology-page-nav-presentation-type-toggle-collections-list\" aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"list") : stack1)) != null ? lookupProperty(stack1,"switchView") : stack1), depth0))
    + "\"\n                               class=\"fade-on-hover hide cntntmast-view-type-button\"\n                               href=\"/"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":47,"column":38},"end":{"line":47,"column":60}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":47,"column":61},"end":{"line":47,"column":76}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"collectionTypeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"collectionTypeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"collectionTypeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":47,"column":77},"end":{"line":47,"column":109}}}) : helper)))
    + "s/list\">\n                                <div><i class=\"bbico bbico-list m-r-5\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"list") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</div>\n                            </a>\n\n                            <a id=\"bb-anthology-page-nav-presentation-type-toggle-collections-masonry\"\n                               class=\"fade-on-hover hide cntntmast-view-type-button\" aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"grid") : stack1)) != null ? lookupProperty(stack1,"switchView") : stack1), depth0))
    + "\"\n                               href=\"/"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":53,"column":38},"end":{"line":53,"column":60}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":53,"column":61},"end":{"line":53,"column":76}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"collectionTypeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"collectionTypeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"collectionTypeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":53,"column":77},"end":{"line":53,"column":109}}}) : helper)))
    + "s/masonry\">\n                                <div><i class=\"fa fa-th m-r-5\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"grid") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</div>\n                            </a>\n\n                            <a id=\"bb-anthology-page-nav-presentation-type-toggle-content-list\"\n                               aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"list") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                               class=\"fade-on-hover hide cntntmast-view-type-button\"\n                               href=\"/"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":60,"column":38},"end":{"line":60,"column":60}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":60,"column":61},"end":{"line":60,"column":76}}}) : helper)))
    + "/content/list/"
    + alias2(((helper = (helper = lookupProperty(helpers,"contentType") || (depth0 != null ? lookupProperty(depth0,"contentType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"contentType","hash":{},"data":data,"loc":{"start":{"line":60,"column":90},"end":{"line":60,"column":105}}}) : helper)))
    + "\">\n                                <div><i class=\"bbico bbico-list m-r-5\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"list") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</div>\n                            </a>\n\n                            <a id=\"bb-anthology-page-nav-presentation-type-toggle-content-masonry\"\n                               aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"grid") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                               class=\"fade-on-hover hide cntntmast-view-type-button\"\n                               href=\"/"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":67,"column":38},"end":{"line":67,"column":60}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":67,"column":61},"end":{"line":67,"column":76}}}) : helper)))
    + "/content/masonry/"
    + alias2(((helper = (helper = lookupProperty(helpers,"contentType") || (depth0 != null ? lookupProperty(depth0,"contentType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"contentType","hash":{},"data":data,"loc":{"start":{"line":67,"column":93},"end":{"line":67,"column":108}}}) : helper)))
    + "\">\n                                <div><i class=\"fa fa-th m-r-5\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"grid") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</div>\n                            </a>\n                        </div>\n                    </div>\n\n                    <div id=\"anthology-cntntnavbar\" class=\"navbar-collapse collapse nav-tab-list\" role=\"navigation\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"itemDetails") : stack1), depth0))
    + "\">\n                        <ul class=\"nav\">\n                            <li id=\"bb-anthology-page-nav-item-collections\"><a\n                                    id=\"bb-anthology-page-nav-item-collections-link\"\n                                    href=\"/"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":77,"column":43},"end":{"line":77,"column":65}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":77,"column":66},"end":{"line":77,"column":81}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"collectionTypeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"collectionTypeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"collectionTypeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":77,"column":82},"end":{"line":77,"column":114}}}) : helper)))
    + "s/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":77,"column":116},"end":{"line":77,"column":136}}}) : helper)))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"collection") : stack1)) != null ? lookupProperty(stack1,"plural") : stack1), depth0))
    + "</a>\n                            </li>\n                            <li id=\"bb-anthology-page-nav-item-content\"><a\n                                    id=\"bb-anthology-page-nav-item-content-link\"\n                                    href=\"/"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":81,"column":43},"end":{"line":81,"column":65}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"anthologyId") || (depth0 != null ? lookupProperty(depth0,"anthologyId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"anthologyId","hash":{},"data":data,"loc":{"start":{"line":81,"column":66},"end":{"line":81,"column":81}}}) : helper)))
    + "/content/"
    + alias2(((helper = (helper = lookupProperty(helpers,"presentationType") || (depth0 != null ? lookupProperty(depth0,"presentationType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"presentationType","hash":{},"data":data,"loc":{"start":{"line":81,"column":90},"end":{"line":81,"column":110}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"contentType") || (depth0 != null ? lookupProperty(depth0,"contentType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"contentType","hash":{},"data":data,"loc":{"start":{"line":81,"column":111},"end":{"line":81,"column":126}}}) : helper)))
    + "\">\n                                "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"content") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</a>\n                            </li>\n                        </ul>\n                    </div>\n\n                </div>\n            </nav>\n        </div>\n    </div>\n</div>\n";
},"useData":true});