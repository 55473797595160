import _ from "lodash";
import CurationModel from "scripts/models/curationModel";
import AnthologyModel from "scripts/models/anthologyModel";
import AnthologyCollectionModel from "scripts/models/anthologyCollectionModel";
import ContentModel from "scripts/models/contentModel";
import ModuleModel from "scripts/models/moduleModel";
import CategoryModel from "scripts/models/categoryModel";
import BaseView from "scripts/views/baseView";
import CurationListTileView from "scripts/views/curationListTileView";
import AnthologyListTileView from "scripts/views/anthologyListTileView";
import AnthologyCollectionListTileView from "scripts/views/anthologyCollectionListTileView";
import ArticleListTileView from "scripts/views/articleListTileView";
import AudioListTileView from "scripts/views/audioListTileView";
import BookListTileView from "scripts/views/bookListTileView";
import ChapterListTileView from "scripts/views/chapterListTileView";
import OtherDocumentListTileView from "scripts/views/otherDocumentListTileView";
import ImageListTileView from "scripts/views/imageListTileView";
import ModuleListTileView from "scripts/views/moduleListTileView";
import VideoListTileView from "scripts/views/videoListTileView";
import CategoryListTileView from "scripts/views/categoryListTileView";
import AlbumListTileView from "scripts/views/albumListTileView";
import AlbumTrackListTileView from "scripts/views/albumTrackListTileView";

import templateMediaList from "templates/mediaList.hbs";

class MediaListView extends BaseView {
  get template() {
    return templateMediaList;
  }

  constructor(options) {
    super(options);

    this.unfavoriteConfirmationRequired = options.unfavoriteConfirmationRequired || false;

    this.curationSelectMode = options.curationSelectMode || false;

    this.collection.on("remove", this.removeMedia, this);

    this.viewerParams = options.viewerParams;
  }

  render() {
    this.$el.html(this.template());
    this.collection.each(this.addMedia.bind(this));
    return this;
  }

  sync() {
    return this.collection.fetch();
  }

  addMedia(mediaModel) {
    const listTileView = this.createTileView(mediaModel);

    this.addSubView(listTileView.render());
    listTileView.attachTo(this.$el.find("#bb-media-list"));
  }

  removeMedia(mediaModel) {
    let tileView = _.find(this.subViews, view => {
      return view.model && mediaModel.get("id") === view.model.get("id");
    });

    if (tileView) {
      this.closeSubView(tileView);
      tileView.$el.remove();
    }
  }

  createTileView(mediaModel) {
    let tileView;

    const tileViewOptions = {
      model: mediaModel,
      unfavoriteConfirmationRequired: this.unfavoriteConfirmationRequired,
      viewerParams: this.viewerParams,
    };

    if (mediaModel instanceof CurationModel) {
      tileView = new CurationListTileView(tileViewOptions);
    } else if (mediaModel instanceof AnthologyModel) {
      tileView = new AnthologyListTileView(tileViewOptions);
    } else if (mediaModel instanceof AnthologyCollectionModel) {
      tileView = new AnthologyCollectionListTileView(tileViewOptions);
    } else if (mediaModel instanceof ContentModel) {
      tileView = this.createContentTileView(tileViewOptions);
    } else if (mediaModel instanceof ModuleModel) {
      tileView = new ModuleListTileView(tileViewOptions);
    } else if (mediaModel instanceof CategoryModel) {
      tileView = new CategoryListTileView(tileViewOptions);
    } else {
      const mediaType = mediaModel.get("mediaType");
      throw new Error(`Cannot associate media model with list tile, media type: ${mediaType}`);
    }

    return tileView;
  }

  createContentTileView(tileViewOptions) {
    let tileView;
    let mediaModel = tileViewOptions.model;
    let contentType = mediaModel.get("type");
    let parentContent = mediaModel.get("parentContent");

    if (contentType === "ARTICLE") {
      tileView = new ArticleListTileView(tileViewOptions);
    } else if (contentType === "AUDIO" && parentContent && parentContent.type === "ALBUM") {
      tileViewOptions.isSearchTile = true;
      tileView = new AlbumTrackListTileView(tileViewOptions);
    } else if (contentType === "AUDIO" || contentType === "AUDIOBOOK") {
      tileView = new AudioListTileView(tileViewOptions);
    } else if (contentType === "ALBUM") {
      tileView = new AlbumListTileView(tileViewOptions);
    } else if (contentType === "BOOK") {
      tileView = new BookListTileView(tileViewOptions);
    } else if (contentType === "CHAPTER") {
      tileView = new ChapterListTileView(tileViewOptions);
    } else if (contentType === "OTHER_DOCUMENT") {
      tileView = new OtherDocumentListTileView(tileViewOptions);
    } else if (contentType === "IMAGE") {
      tileView = new ImageListTileView(tileViewOptions);
    } else if (contentType === "VIDEO") {
      tileView = new VideoListTileView(tileViewOptions);
    } else {
      throw new Error(`Cannot associate content model with list tile, content type: ${contentType}`);
    }

    return tileView;
  }
}

export default MediaListView;
