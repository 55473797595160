var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"bb-create-profile-form\">\n  <!--email address-->\n  <div class=\"form-group\">\n    <input aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"emailAriaLabel") : stack1), depth0))
    + "\" class=\"form-control input-outline m-t-5\"\n           placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"placeholder") : stack1)) != null ? lookupProperty(stack1,"emailOptional") : stack1), depth0))
    + "\" name=\"emailAddress\" autocomplete=\"email\">\n\n    <p class=\"fnt-bld m-0 p-0 m-t-20\"><em>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"profile") : stack1)) != null ? lookupProperty(stack1,"create") : stack1)) != null ? lookupProperty(stack1,"whyEmail") : stack1)) != null ? lookupProperty(stack1,"header") : stack1), depth0))
    + "</em></p>\n\n    <p class=\"m-0 p-0 m-b-30\"><em>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"profile") : stack1)) != null ? lookupProperty(stack1,"create") : stack1)) != null ? lookupProperty(stack1,"whyEmail") : stack1)) != null ? lookupProperty(stack1,"body") : stack1), depth0))
    + "</em></p>\n  </div>\n  <!--///email address-->\n\n  <div class=\"form-group\">\n    <input aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"placeholder") : stack1)) != null ? lookupProperty(stack1,"username") : stack1), depth0))
    + "\" class=\"form-control input-outline m-t-5\" placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"placeholder") : stack1)) != null ? lookupProperty(stack1,"username") : stack1), depth0))
    + "\" name=\"username\"\n           autocomplete=\"username\">\n  </div>\n  <div class=\"form-group\">\n    <input aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"placeholder") : stack1)) != null ? lookupProperty(stack1,"password") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "\" class=\"form-control input-outline m-t-5\" type=\"password\" placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"placeholder") : stack1)) != null ? lookupProperty(stack1,"password") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "\"\n           name=\"password\" autocomplete=\"new-password\">\n    <p class=\"m-0 p-0 m-b-30 m-t-10\"><em>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"profile") : stack1)) != null ? lookupProperty(stack1,"password") : stack1)) != null ? lookupProperty(stack1,"validation") : stack1), depth0))
    + "</em></p>\n  </div>\n  <div class=\"form-group\">\n    <input aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"placeholder") : stack1)) != null ? lookupProperty(stack1,"password") : stack1)) != null ? lookupProperty(stack1,"confirm") : stack1), depth0))
    + "\" class=\"form-control input-outline m-t-5 m-b-30\" type=\"password\"\n           placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"placeholder") : stack1)) != null ? lookupProperty(stack1,"password") : stack1)) != null ? lookupProperty(stack1,"confirm") : stack1), depth0))
    + "\" name=\"confirmPassword\" autocomplete=\"new-password\"/>\n  </div>\n\n  <!--submit button-->\n  <button type=\"submit\" class=\"btn-rounded btn-rounded-brand-primary btn-block\" disabled=\"disabled\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"createProfile") : stack1), depth0))
    + "\n  </button>\n\n  <div class=\"bb-form-submit-button-loader-region btn-rounded btn-rounded-brand-primary btn-block disabled hide\">\n  </div>\n</form>";
},"useData":true});