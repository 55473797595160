import _ from "lodash";
import { addQueryStringParams } from "scripts/utils/urlUtil";
import BaseModel from "scripts/models/baseModel";
import ApiSyncHoc from "scripts/hoc/apiSyncHoc";

const chan = Backbone.Radio.channel;

class BaseApiModel extends ApiSyncHoc(BaseModel) {
  constructor(attrs, options) {
    super(attrs, options);

    this.queryStringParams =
      options && options.queryStringParams
        ? _.merge({}, this.defaultQueryStringParams, options.queryStringParams)
        : this.defaultQueryStringParams;
  }

  get defaultQueryStringParams() {
    const defaultParams = {};

    const user = chan("security").request("user");
    const activeOrganizationId = user.getActiveOrganizationId();

    if (activeOrganizationId) {
      defaultParams["org-id"] = activeOrganizationId;
    }

    return defaultParams;
  }

  sync(...args) {
    const url = _.result(this, "url");

    if (url) {
      this.url = addQueryStringParams(this.queryStringParams, url);
    }

    return super.sync(...args);
  }
}

export default BaseApiModel;
