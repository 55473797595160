import BaseAuthenticationModel from "scripts/models/baseAuthenticationModel";

class UserAuthenticationModel extends BaseAuthenticationModel {
  url() {
    const username = this.get("username");

    return `${this.authenticationUrlRoot}/users/${username}/authentication`;
  }

  // parse(data) {
  //     return _.merge({}, {
  //         password: void 0,
  //         token: data.token,
  //         bot: data.bot,
  //         principals: data.principals,
  //     }, data.user);
  // }
}

export default UserAuthenticationModel;
