var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"bb-new-home-container\">\n  <h1 style=\"margin: 0\" aria-label=\"BiblioBoard\"></h1>\n\n  <div class=\"bb-new-home-search-section container\">\n    <div class=\"bb-new-home-search-component\">\n      <i class=\"fa fa-search\"></i>\n      <form id=\"target-new-home-search-form\" role=\"search\">\n        <input name=\"search\" class=\"bb-new-home-search-input\" placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"placeholder") : stack1), depth0))
    + "\" aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\" />\n      </form>\n      <a href=\"/advanced-search\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"advanced") : stack1), depth0))
    + "</a>\n    </div>\n  </div>\n\n  <div class=\"bb-new-home-categories-section hide container\">\n    <div class=\"bb-category-tiles-container\"></div>\n  </div>\n</div>";
},"useData":true});