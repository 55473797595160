import BaseListTileView from "scripts/views/baseListTileView";
import templateCurationListTile from "templates/curationListTile.hbs";

class CurationListTileView extends BaseListTileView {
  get template() {
    return templateCurationListTile;
  }
}

export default CurationListTileView;
