var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":7,"column":25},"end":{"line":7,"column":31}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":7,"column":33},"end":{"line":7,"column":42}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"bb-curation-add-media-form\" class=\"bb-form\">\n  <div class=\"row m-t-30 m-lr-0\">\n    <div class=\"form-group\">\n      <select id=\"bb-curation-add-media-form-curation-select\" class=\"bb-curation-autocomplete-select form-control input-outline m-t-5\" name=\"curation\">\n        <option></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"curations") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":8,"column":17}}})) != null ? stack1 : "")
    + "      </select>\n    </div>\n  </div>\n\n    <div class=\"text-right m-b-20\">\n        <button id=\"bb-curation-add-media-form-new-curation\" class=\"bb-btn-a\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"createNew") : stack1), depth0))
    + "</button>\n    </div>\n\n    <!--submit button-->\n    <div class=\"row m-t-10 m-b-20 m-lr-0 text-right\">\n      <button class=\"btn btn-link\" data-dismiss=\"modal\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"cancel") : stack1), depth0))
    + "</button>\n      <button type=\"submit\" class=\"btn-rounded btn-rounded-brand-primary p-lr-40\" disabled=\"disabled\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"add") : stack1), depth0))
    + "</button>\n      <div class=\"bb-form-submit-button-loader-region btn btn-rounded btn-rounded-brand-primary disabled hide\">\n      </div>\n    </div>\n\n</form>";
},"useData":true});