const messageBundle = {
  "error.invalid.username": "The Username is invalid.",
  "duplicate.user_resource.username": "This Username is already in use.",
  "error.invalid.email_address": "You must enter a valid Email Address.",
  "duplicate.user_resource.email_address":
    "This Email Address is already in use.",
  "error.expired.signature": "The Signed URL has expired.",
  "error.blocked.patron_verification":
    "This card number has been temporarily blocked. See your librarian for details.",
  "error.mismatch.password_confirm": "New and Confirm Password must match.",
  "error.invalid.operation":
    "You do not have the permissions required to perform this operation.",
  "locked.user_account.password":
    "This password is locked and cannot be changed.",
  "error.invalid.method_argument": "This resource is invalid.",
  "not_null.token_resource.token": "You must have a token.",
  "not_blank.forgot_password_notification_resource.email_address":
    "You must enter an Email Address.",
  "email.forgot_password_notification_resource.email_address":
    "You must enter a valid Email Address.",
  "not_null.forgot_password_notification_resource.target_url":
    "This URL is invalid.",
  "not_blank.password_reset_notification_resource.email_address":
    "You must enter an Email Address.",
  "email.password_reset_notification_resource.email_address":
    "You must enter a valid Email Address.",
  "not_null.password_reset_notification_resource.target_url":
    "This URL is invalid.",
  "not_blank.verify_email_notification_resource.email_address":
    "You must enter a valid Email Address.",
  "email.verify_email_notification_resource.email_address":
    "You must enter a valid Email Address.",
  "not_null.verify_email_notification_resource.target_url":
    "This URL is invalid.",
  "password.change_password_resource.old_password":
    "Passwords must contain at least 8 characters and at least 1 number.",
  "password.change_password_resource.new_password":
    "Passwords must contain at least 8 characters and at least 1 number.",
  "password.change_password_resource.confirm_password":
    "Passwords must contain at least 8 characters and at least 1 number.",
  "not_blank.user_resource.username": "You must enter a Username.",
  "email.user_resource.email_address": "You must enter a valid Email Address.",
  "password.user_resource.password":
    "Passwords must contain at least 8 characters and at least 1 number.",
  "not_null.user_resource.password_reset_allowed":
    "You must set the Password Rest Allowed flag.",
  "username.user_resource.username":
    "Usernames must contain only letters, numbers or select special characters, including @._-",

  "com.biblioboard.api.support.validations.UniqueUsername.message":
    "That username is already in use",
};

export default {
  get: function(code) {
    return messageBundle[code];
  },
};
