var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + " <span class=\"clr-primary\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"auth") : stack1)) != null ? lookupProperty(stack1,"verified") : stack1), depth0))
    + ".</span></h1>\n\n<div>\n  <p>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"auth") : stack1)) != null ? lookupProperty(stack1,"thankYouVerify") : stack1), depth0))
    + "</p>\n  <p>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"auth") : stack1)) != null ? lookupProperty(stack1,"yourVerifiedEmail") : stack1), depth0))
    + " <br><span class=\"fnt-16 fnt-bld\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"emailAddress") || (depth0 != null ? lookupProperty(depth0,"emailAddress") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"emailAddress","hash":{},"data":data,"loc":{"start":{"line":5,"column":70},"end":{"line":5,"column":86}}}) : helper)))
    + "</span></p>\n\n  <!--home button-->\n  <a href=\"/home\" class=\"btn-outline btn-outline-gray btn-block m-t-20\">BiblioBoard Home</a>\n\n</div>\n";
},"useData":true});