var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"bb-secondary-sticky-nav\" class=\"cntntmast-nav bg-category\">\n  <div class=\"container\">\n\n    <!--row-->\n    <div class=\"row-same-height row-full-height\">\n      <!--col-->\n      <div class=\"col-xs-2 text-center col-xs-height col-full-height col-middle\">\n        <!--content icon desktop version-->\n        <div class=\"typeicon\">\n          <div class=\"circle-content-type bg-category\">\n            <i class=\"bbico bbico-category\"></i>\n          </div>\n          <label>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"categories") : stack1), depth0))
    + "</label>\n        </div>\n      </div>\n      <!--///col-->\n      <!--col-->\n      <div class=\"col-xs-8 col-xs-height col-full-height col-middle\">\n\n      </div>\n      <!--///col-->\n      <!--col-->\n      <div class=\"col-xs-2 col-xs-height col-full-height col-middle cntntmast-nav-right\">\n        <!--switch view type button-->\n        <a id=\"bb-secondary-sticky-nav-presentation-type-toggle-list\"\n           aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"list") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n           class=\"fade-on-hover hide cntntmast-view-type-button\"\n           href=\"/categories/list\">\n          <div><i class=\"bbico bbico-list m-r-5\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"list") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</div>\n        </a>\n\n        <a id=\"bb-secondary-sticky-nav-presentation-type-toggle-masonry\"\n           aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"grid") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n           class=\"fade-on-hover hide cntntmast-view-type-button\"\n           href=\"/categories/masonry\">\n          <div><i class=\"fa fa-th m-r-5\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"grid") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</div>\n        </a>\n      </div>\n      <!--///col-->\n    </div>\n    <!--///row-->\n\n  </div>\n</div>\n";
},"useData":true});