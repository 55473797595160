import BaseApiModel from "scripts/models/baseApiModel";
import Util from "scripts/utils/util";

class NewUserProfileModel extends BaseApiModel {
  get constraints() {
    return {
      emailAddress: {
        email: true,
      },
      username: {
        presence: true,
        format: {
          pattern: Util.usernameValidationRegex,
          message: "valid special characters: @ - . _",
        },
      },
      password: {
        presence: true,
        length: {
          minimum: 8,
        },
        format: {
          pattern: Util.passwordValidationRegex,
          message: "must contain at least one number",
        },
      },
      confirmPassword: {
        presence: true,
        equality: "password",
      },
    };
  }

  urlRoot() {
    return `${this.apiUrlRoot}/users`;
  }
}

export default NewUserProfileModel;
