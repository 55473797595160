import BaseModalView from "scripts/views/baseModalView";
import { selectedTerms } from "scripts/views/search/facetMapper";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";

import templateFacetedSearchSliderMenuItem from "templates/search/facetedSearchSlidedrMenuItem.hbs";

class FacetedSearchSliderMenuItemView extends BaseModalView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;
  }

  get events() {
    return {
      "click .btn-expand-toggle": "onToggle",
      "change .faceted-search-checkbox": "onCheckboxChange",
    };
  }

  get className() {
    return "faceted-search-menu-item";
  }

  get template() {
    return templateFacetedSearchSliderMenuItem;
  }

  render() {
    const { search } = this.i18nextService.getGlossary();

    const facetLabelProp = L.camelCase(this.model.get("facetLabel"));

    const facetLabel =
      L.has([facetLabelProp], search) && L.isString(search[facetLabelProp])
        ? search[facetLabelProp]
        : this.model.get("facetLabel");

    const terms = selectedTerms(
      this.model.get("facetValues"),
      this.model.get("chosenTermCodes"),
    );

    const classPrefix = facetLabel.replaceAll(/\W/g, "_");

    this.$el.html(
      this.template({
        i18n: { facetLabel },
        facetLabel,
        classPrefix,
      }),
    );

    this.minValue = terms[0] ? parseInt(terms[0].label) : 0;
    this.maxValue = terms[terms.length - 1]
      ? parseInt(terms[terms.length - 1].label)
      : 0;

    const chosenTermCodes = this.model.get("chosenTermCodes");

    let minSelectedTerm = null;
    let maxSelectedTerm = null;
    if (chosenTermCodes.length) {
      const chosenList = chosenTermCodes[0].split("|");
      minSelectedTerm = terms.find(t => t.code === chosenList[0]);
      maxSelectedTerm = terms.find(t => t.code === chosenList[1]);
    }

    const selectedMin = minSelectedTerm
      ? parseInt(minSelectedTerm.label)
      : this.minValue;
    const selectedMax = maxSelectedTerm
      ? parseInt(maxSelectedTerm.label)
      : this.maxValue;

    $(`.${classPrefix}-slider-range`).slider({
      range: true,
      min: this.minValue,
      max: this.maxValue,
      values: [selectedMin, selectedMax],
      slide: (event, ui) => {
        $(`#${classPrefix}-selected-min`).empty().append(ui.values[0]);
        $(`#${classPrefix}-selected-max`).empty().append(ui.values[1]);
      },
      stop: (event, ui) => {
        this.updateTerms(ui.values[0], ui.values[1]);
      },
    });

    $(`#${classPrefix}-selected-min`)
      .empty()
      .append($(`.${classPrefix}-slider-range`).slider("values", 0));
    $(`#${classPrefix}-selected-max`)
      .empty()
      .append($(`.${classPrefix}-slider-range`).slider("values", 1));

    return this;
  }

  updateTerms(min, max) {
    const facetValues = this.model.get("facetValues");
    const facetParam = this.model.get("facetParam");

    const chosenTermCodes = facetValues.filter(
      fv => fv.label >= min && fv.label <= max,
    );
    const minYearTerm = chosenTermCodes[0];
    const maxYearTerm = chosenTermCodes[chosenTermCodes.length - 1];

    this.trigger("apply", facetParam, [
      `${minYearTerm.code}|${maxYearTerm.code}`,
    ]);
  }
}

export default FacetedSearchSliderMenuItemView;
