import _ from "lodash";
import BaseApiModel from "scripts/models/baseApiModel";

import fallbackImage from "images/fallbacks/fallback_524_360.jpg";
import fallBackImageAnthology from "images/fallbacks/fallback_160_160.png";

class AnthologyCollectionModel extends BaseApiModel {
  urlRoot() {
    return `${this.apiUrlRoot}/collections/`;
  }

  parse(response) {
    response.iconFallbackUrl = fallbackImage;
    response.typeLabelLowerCase = response.typeLabel.toLowerCase();

    if (!_.isNil(response.anthology)) {
      response.anthology.iconFallbackUrl = fallBackImageAnthology;
      response.anthology.typeLabelLowerCase = response.anthology.typeLabel.toLowerCase();
    }

    response.whiteMediaBadges = _.filter(response.mediaBadges, {
      type: "white",
    });
    response.blackMediaBadges = _.filter(response.mediaBadges, {
      type: "black",
    });

    return response;
  }
}

export default AnthologyCollectionModel;
