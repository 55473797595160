import Backbone from "backbone";
import BaseApiModel from "scripts/models/baseApiModel";

const chan = Backbone.Radio.channel;

class RecentModel extends BaseApiModel {
  urlRoot() {
    const userId = chan("security")
      .request("user")
      .getUserId();
    const mediaId = this.get("mediaId");

    return `${this.apiUrlRoot}/users/${userId}/usage/${mediaId}`;
  }
}

export default RecentModel;
