var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"bb-note-tile-page hide\">\n                    <label>"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"page") : stack1), depth0))
    + "</label>\n                    <span class=\"fnt-20 lh-20 fnt-sans-alt clr-white\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"pageSequence") || (depth0 != null ? lookupProperty(depth0,"pageSequence") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"pageSequence","hash":{},"data":data,"loc":{"start":{"line":7,"column":70},"end":{"line":7,"column":86}}}) : helper)))
    + "</span>\n                </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"fnt-bld clr-white\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":32,"column":40},"end":{"line":32,"column":49}}}) : helper)))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"bb-note-card-viewer\">\n    <div class=\"row m-0 p-0 splitcol\">\n        <div class=\"col-xs-3\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"pageSequence") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":9,"column":19}}})) != null ? stack1 : "")
    + "\n            <div class=\"bb-note-tile-global hide\">\n                <i class=\"fa fa-globe fnt-22 clr-white\"></i>\n            </div>\n        </div>\n        <div class=\"col-xs-6\">\n            <label>"
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"added") : stack1), depth0))
    + "</label>\n            <span class=\"fnt-20 lh-20 fnt-sans-alt clr-white\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"dateAddedFormatted") || (depth0 != null ? lookupProperty(depth0,"dateAddedFormatted") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"dateAddedFormatted","hash":{},"data":data,"loc":{"start":{"line":17,"column":62},"end":{"line":17,"column":84}}}) : helper)))
    + "</span>\n        </div>\n        <div class=\"col-xs-3 text-right\">\n            <!-- keep this button a div element since you \"can't\"\n            put anchor in an anchor and keep the hover effects intact-->\n            <div class=\"bb-note-tile-edit circle-btn circle-btn-gray-darker circle-btn-mini m-t-5\">\n                <i class=\"bbico bbico-edit\"></i>\n            </div>\n            <div class=\"bb-note-tile-delete circle-btn circle-btn-delete circle-btn-mini m-t-5\">\n                <i class=\"fa fa-trash\"></i>\n            </div>\n        </div>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":4},"end":{"line":33,"column":11}}})) != null ? stack1 : "")
    + "\n    <!-- pre tag will respect line termination characters -->\n    <pre>"
    + alias2(((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":36,"column":9},"end":{"line":36,"column":17}}}) : helper)))
    + "</pre>\n</div>\n";
},"useData":true});