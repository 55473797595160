var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <!-- get the apps-->\n                        <h2 class=\"m-t-40 m-b-10\">Get the app.</h2>\n                        <a aria-label=\"itunes\"\n                            href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"appleAppDownloadUrl") || (depth0 != null ? lookupProperty(depth0,"appleAppDownloadUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"appleAppDownloadUrl","hash":{},"data":data,"loc":{"start":{"line":18,"column":34},"end":{"line":18,"column":57}}}) : helper)))
    + "\"\n                            target=\"_blank\">\n                            <div class=\"btnAppStore\" id=\"btnApple\"></div>\n                        </a>\n                        <a aria-label=\"play store\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"googleAppDownloadUrl") || (depth0 != null ? lookupProperty(depth0,"googleAppDownloadUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"googleAppDownloadUrl","hash":{},"data":data,"loc":{"start":{"line":22,"column":57},"end":{"line":22,"column":81}}}) : helper)))
    + "\"\n                            target=\"_blank\">\n                            <div class=\"btnAppStore\" id=\"btnGoogle\"></div>\n                        </a>\n                        <a aria-label=\"amazon\"\n                            href=\"http://www.amazon.com/BiblioLabs-LLC-BiblioBoard-Library/dp/B00BFDHJUA/ref=sr_1_1?s=mobile-apps&ie=UTF8&qid=1458165359&sr=1-1\"\n                            target=\"_blank\">\n                            <div class=\"btnAppStore\" id=\"btnAmazon\"></div>\n                        </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"row p-b-20\">\n                            <div class=\"col-xs-12 col-md-4 fnt-bld\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"organization") : stack1), depth0))
    + "</div>\n                            <div class=\"col-xs-12 col-md-8\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"organizationName") || (depth0 != null ? lookupProperty(depth0,"organizationName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"organizationName","hash":{},"data":data,"loc":{"start":{"line":77,"column":60},"end":{"line":77,"column":80}}}) : helper)))
    + "</div>\n                        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"row p-b-20\">\n                            <div class=\"col-xs-12 col-md-4 fnt-bld\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data,"loc":{"start":{"line":83,"column":68},"end":{"line":83,"column":76}}}) : helper)))
    + "</div>\n                            <div class=\"col-xs-12 col-md-8 ovr-hidden\">"
    + alias1(container.lambda(depth0, depth0))
    + "</div>\n                        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.lambda, alias4=container.escapeExpression, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- TODO: create common styles, don't steal from auth -->\n\n<div class=\"bb-authentication-screen\">\n    <div class=\"container bg-white\">\n        <div class=\"row\">\n            <!--col-->\n            <div class=\"col-sm-6 text-center h-inherit\" id=\"bb-authentication-screen-sign-in-brand\">\n                <div>\n                    <!--biblioboard logo-->\n                    <a href=\"/home\">\n                        <img src=\"" + require("/opt/atlassian/pipelines/agent/build/node_modules/handlebars-template-loader/file-loader.js?url=..%2Fimages%2Flogo-bb-horo.png!../images/logo-bb-horo.png") + "\" class=\"img-responsive\" alt=\"BiblioBoard\" />\n                    </a>\n\n"
    + ((stack1 = (lookupProperty(helpers,"ifEnv")||(depth0 && lookupProperty(depth0,"ifEnv"))||alias2).call(alias1,"PATRON_UI_SHOW_APP_LOGOS",{"name":"ifEnv","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":31,"column":30}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n            <!--///col-->\n\n            <!--col-->\n            <div class=\"col-sm-6 text-center bg-gray-lighter h-inherit p-t-40\" id=\"bb-diagnostics-container\">\n                <h1 class=\"m-b-30\">"
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"diagnostics") : stack1)) != null ? lookupProperty(stack1,"plural") : stack1), depth0))
    + "</h1>\n\n                <div class=\"container-fluid text-left p-20\">\n                    <div class=\"row p-b-20\">\n                        <div class=\"col-xs-12 col-md-4 fnt-bld\">"
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"diagnostics") : stack1)) != null ? lookupProperty(stack1,"date") : stack1), depth0))
    + "</div>\n                        <div class=\"col-xs-12 col-md-8\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"date") || (depth0 != null ? lookupProperty(depth0,"date") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"date","hash":{},"data":data,"loc":{"start":{"line":43,"column":56},"end":{"line":43,"column":64}}}) : helper)))
    + "</div>\n                    </div>\n\n                    <div class=\"row p-b-20\">\n                        <div class=\"col-xs-12 col-md-4 fnt-bld\">"
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"diagnostics") : stack1)) != null ? lookupProperty(stack1,"browser") : stack1), depth0))
    + "</div>\n                        <div class=\"col-xs-12 col-md-8\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"browser") || (depth0 != null ? lookupProperty(depth0,"browser") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"browser","hash":{},"data":data,"loc":{"start":{"line":48,"column":56},"end":{"line":48,"column":67}}}) : helper)))
    + "</div>\n                    </div>\n\n                    <div class=\"row p-b-20\">\n                        <div class=\"col-xs-12 col-md-4 fnt-bld\">"
    + alias4(alias3(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"diagnostics") : stack1)) != null ? lookupProperty(stack1,"ip") : stack1)) != null ? lookupProperty(stack1,"address") : stack1), depth0))
    + "</div>\n                        <div class=\"col-xs-12 col-md-8\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"ipAddress") || (depth0 != null ? lookupProperty(depth0,"ipAddress") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"ipAddress","hash":{},"data":data,"loc":{"start":{"line":53,"column":56},"end":{"line":53,"column":69}}}) : helper)))
    + "</div>\n                    </div>\n\n                    <div class=\"row p-b-20\">\n                        <div class=\"col-xs-12 col-md-4 fnt-bld\">"
    + alias4(alias3(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"diagnostics") : stack1)) != null ? lookupProperty(stack1,"ip") : stack1)) != null ? lookupProperty(stack1,"coordinates") : stack1), depth0))
    + "</div>\n                        <div class=\"col-xs-12 col-md-8\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"latitude") || (depth0 != null ? lookupProperty(depth0,"latitude") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"latitude","hash":{},"data":data,"loc":{"start":{"line":58,"column":56},"end":{"line":58,"column":68}}}) : helper)))
    + " / "
    + alias4(((helper = (helper = lookupProperty(helpers,"longitude") || (depth0 != null ? lookupProperty(depth0,"longitude") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"longitude","hash":{},"data":data,"loc":{"start":{"line":58,"column":71},"end":{"line":58,"column":84}}}) : helper)))
    + "</div>\n                    </div>\n\n                    <div class=\"row p-b-20\">\n                        <div class=\"col-xs-12 col-md-4 fnt-bld\">"
    + alias4(alias3(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"diagnostics") : stack1)) != null ? lookupProperty(stack1,"ip") : stack1)) != null ? lookupProperty(stack1,"region") : stack1), depth0))
    + "</div>\n                        <div class=\"col-xs-12 col-md-8\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"region") || (depth0 != null ? lookupProperty(depth0,"region") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"region","hash":{},"data":data,"loc":{"start":{"line":63,"column":56},"end":{"line":63,"column":66}}}) : helper)))
    + "</div>\n                    </div>\n\n                    <div class=\"row p-b-20\">\n                        <div class=\"col-xs-12 col-md-4 fnt-bld\">"
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"diagnostics") : stack1)) != null ? lookupProperty(stack1,"geoLocation") : stack1), depth0))
    + "</div>\n                        <div class=\"col-xs-12 col-md-8\"><a class=\"clr-primary-dark fnt-bld\"\n                                id=\"bb-diagnositcs-screen-gps-button\">"
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"diagnostics") : stack1)) != null ? lookupProperty(stack1,"updateLocation") : stack1), depth0))
    + "</a></div>\n                    </div>\n\n                    <div id=\"gps-info-region\" class=\"row p-b-20\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"organizationName") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":20},"end":{"line":79,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"requestHeaders") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":20},"end":{"line":86,"column":29}}})) != null ? stack1 : "")
    + "\n                    <div class=\"p-b-20\" style=\"display: flex; justify-content: space-between; flex-wrap: wrap; gap: 20px;\">\n                        <a id=\"bb-diagnostics-email\" class=\"btn-outline btn-outline-gray\" href=\"mailto:support@biblioboard.com?subject=Diagnostics\" target=\"_blank\">"
    + alias4(alias3(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"diagnostics") : stack1)) != null ? lookupProperty(stack1,"button") : stack1)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + "</a>\n                        <button id=\"bb-diagnostics-copy\" data-toggle=\"tooltip\" data-title=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"diagnosticsCopied") : stack1), depth0))
    + "\" class=\"btn-outline btn-outline-gray\">\n                            "
    + alias4(alias3(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"diagnostics") : stack1)) != null ? lookupProperty(stack1,"button") : stack1)) != null ? lookupProperty(stack1,"copy") : stack1), depth0))
    + "\n                        </button>\n                    </div>\n                    <div class=\"p-b-20\">\n                        <button id=\"bb-diagnostics-screen-back-button\" class=\"btn-outline btn-outline-gray\">\n                            <i class=\"fa fa-angle-left\"></i>\n                            "
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"back") : stack1), depth0))
    + "\n                        </button>\n                    </div>\n                </div>\n            </div>\n            <!--///col-->\n        </div>\n    </div>\n</div>";
},"useData":true});