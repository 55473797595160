import L from "lodash/fp";

const WEB_TO_LEAD_URL =
  "https://www.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8";
const OID = "00Dd0000000eIZc";
const LEAD_SOURCE = "BB-PatronInquiry";

// This shows CORS errors in the console and returns status 0, but sends the POST
const postFormEncoded = (url, data) => {
  const body = $.param(data);

  const xhr = new XMLHttpRequest();
  xhr.open("POST", url);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.send(body);
};

export const saveWebToLead = fields => {
  postFormEncoded(
    WEB_TO_LEAD_URL,
    L.merge(fields, {
      oid: OID,
      lead_source: LEAD_SOURCE,
    }),
  );
};
