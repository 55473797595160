import _ from "lodash";
import BaseSecondaryStickyView from "scripts/views/baseSecondaryStickyNavView";
import templateCollectionPageNav from "templates/collectionPageNav.hbs";
import mediaPageNavTranslations from "i18n/mediaPageNavTranslations";

const DEFAULT_SORT = "title asc";

const chan = Backbone.Radio.channel;

class CollectionPageNavView extends BaseSecondaryStickyView {
  get template() {
    return templateCollectionPageNav;
  }

  constructor(options) {
    super(options);

    this.collectionModel = options.collectionModel;

    this.mediaPageNavTranslations = new mediaPageNavTranslations();

    this.sortOption = DEFAULT_SORT;

    chan("collectionPageContentSubPage").on(
      "sortOptionChange",
      sortOption => {
        this.sortOption = sortOption;
        this.render();
      },
      this,
    );
  }

  get events() {
    return _.extend({}, super.events, {
      "click .sort-option": "onClickSortButton",
    });
  }

  render() {
    this.$el.html(
      this.template(
        _.merge(
          {
            i18n: this.mediaPageNavTranslations.getTranslations(),
            sortOption: this.sortOption,
          },
          this.model.toJSON(),
          this.collectionModel.toJSON(),
        ),
      ),
    );

    this.updateLinkState();
    this.updatePosition();

    return this;
  }

  onClickSortButton(event) {
    this.sortOption = event.target.value;
    chan("collectionPageNav").trigger("sortOptionChange", this.sortOption);
    this.render();
  }
}

export default CollectionPageNavView;
