import Backbone from "backbone";
import inject from "scripts/ioc/inject";
import BaseView from "scripts/views/baseView";

import templateBreadcrumb from "templates/breadcrumb.hbs";

const chan = Backbone.Radio.channel;

class BreadcrumbView extends BaseView {
  constructor(options, breadcrumbService = inject("breadcrumbService")) {
    super(options);

    this.breadcrumbService = breadcrumbService;

    chan("breadcrumb").on("update", () => this.render(), this);
  }

  get template() {
    return templateBreadcrumb;
  }

  get className() {
    return "bb-breadcrumb";
  }

  replyBreadcrumbView() {
    return this;
  }

  render() {
    this.$el.html(
      this.template({
        active: this.breadcrumbService.getActive(),
        paths: this.breadcrumbService.getPaths(),
      }),
    );

    return this;
  }
}

export default BreadcrumbView;
