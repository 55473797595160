import Promise from "bluebird";
import sweetAlert from "sweetalert";

const NO_AUTH_SCOPE_ACCESS_MESSAGE = `<p>In order to create a profile for this library, you must be within the library’s IP range.</p>
<br/><p><a href="http://support.biblioboard.com" target="_blank">Contact support</a>
for assistance with creating an account when outside the library.</p>`;

export const alert = (...args) => ({
  show: () => {
    const customizations = args[0];
    let options;

    if (typeof customizations === "string") {
      options = {};
      options.title = customizations;
      options.text = args[1] || "";
      options.type = args[2] || "";
    } else {
      options = customizations;
    }

    return new Promise((resolve, reject) => {
      sweetAlert(options, isConfirm => {
        setTimeout(() => {
          if (isConfirm) {
            resolve();
          } else {
            reject(new Promise.CancellationError());
          }
        }, 200);
      });

      $(".sweet-alert").attr("role", "dialog");
    });
  },
});

export const offlineGoToBookshelfAlert = alert({
  title: "You're Currently Offline",
  text:
    "No bars or networks? No problem! Your Offline Bookshelf is good to go!",
  type: "info",
  showCancelButton: false,
  confirmButtonText: "Go There Now",
});

export const offlineNotSignedInAlert = alert({
  title: "You're Currently Offline",
  text:
    "Looks like you haven't signed in to BiblioBoard on this device. Normally, we'd have you sign in again to continue, but we can't find an internet connection. Once you're back online, reopen this app.",
  type: "info",
  showCancelButton: false,
  confirmButtonText: "OK",
});

export const offlineNoProfileAlert = alert({
  title: "You're Currently Offline",
  text:
    "Looks like you're not signed in to BiblioBoard. If you were we would take you to your offline bookshelf. Be sure to create an account when you come back online!",
  type: "info",
  showCancelButton: false,
  confirmButtonText: "OK",
});

export const noAdditionalLibrariesAlert = alert({
  html: true,
  title: "No additional libraries found",
  text: "Sorry, we couldn't find any additional libraries.",
  type: "warning",
});

export const noAdditionalLibrariesWithAccessAlert = alert({
  html: true,
  title: "No additional libraries with access",
  text:
    "Sorry, we couldn't find any libraries in your area with access to the item you requested.",
  type: "warning",
});

export const addedOrganizationToProfileButNoAccess = alert({
  html: true,
  title: "Added library to your profile",
  text:
    "We added that library to your profile but it doesn't have access to the item you requested",
  type: "warning",
});

export const organizationAuthSuccessButNoAccess = alert({
  html: true,
  title: "Successfully authenticated",
  text:
    '<p>You successfully authenticated with that library but it doesn\'t have access to the item you requested.</p><br/><p><a id="bb-auth-success-no-access-request-access-link">Request access</a></p>',
  type: "warning",
});

export const foundAdditionalLibrariesUsingLocation = alert({
  title: "Good News!",
  text: "We've found additional libraries based on your location!",
  type: "success",
  showCancelButton: false,
  confirmButtonText: "OK",
});

export const foundMultipleLibrariesWithAccess = alert({
  title: "Good News!",
  text:
    "We've found multiple additional libraries with access to the item you requested!",
  type: "success",
  showCancelButton: false,
  confirmButtonText: "OK",
});

export const geoInfoAlert = alert({
  title: "Why do you want to know my location?",
  text:
    "Some libraries allow access based on your location.  We try to determine your location using your IP address and that works pretty well most of the time but sometimes we don't get it quite right.   If you let us lookup your location we might find libraries that are freely accessible to you.",
  type: "info",
  showCancelButton: false,
});

export const thanksForSubmissionAlert = alert({
  title: "Thanks",
  text: "Your information has been submitted.",
  type: "success",
  showCancelButton: false,
});

export const geoLocationErrorAlert = deviceService => {
  const platform =
    deviceService.isAvailable() && deviceService.platform !== "WEB"
      ? "device"
      : "browser";

  return alert({
    title: "Error retrieving location",
    text: `We weren't able to pinpoint your location. Ensure your ${platform} has location services enabled, and try again.`,
    type: "error",
  });
};

export const noAuthScopeAccessAlert = alert({
  html: true,
  title: "Unable to verify your library membership.",
  text: NO_AUTH_SCOPE_ACCESS_MESSAGE,
  type: "warning",
});

export const generalErrorAlert = (title, text) =>
  alert({
    title: title,
    text: text,
    type: "error",
    confirmButtonText: "OK",
  });
