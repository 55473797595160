var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container\" role=\"search\">\n  <div class=\"row m-t-60\">\n    <div class=\"col-xs-12 input-icon\">\n      <i class=\"bbico bbico-search\"></i>\n      <input id=\"bb-search-overlay-menu-search-input\" class=\"form-control input-outline m-t-5\" type=\"text\"\n        aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"input") : stack1), depth0))
    + "\" />\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-xs-12 bb-search-overlay-search-button-col\" style=\"\">\n      <a id=\"bb-search-overlay-advanced-search-link\" href=\"/advanced-search\" style=\"margin-top: 16px; text-transform: lowercase;\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"advanced") : stack1), depth0))
    + "</a>\n      <button id=\"bb-search-overlay-menu-search-button\" class=\"btn-rounded btn-solid-brand-primary m-tb-15 p-lr-30\"\n              aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"submitButton") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1), depth0))
    + "</button>\n    </div>\n  </div>\n</div>";
},"useData":true});