import BaseApiModel from "scripts/models/baseApiModel";

import fallBackImage from "images/fallbacks/fallback_380_380.png";

class CurationModel extends BaseApiModel {
  get maxTitleLength() {
    return 100;
  }

  get maxSubtitleLength() {
    return 100;
  }

  get maxDescriptionLength() {
    return 4096;
  }

  get constraints() {
    return {
      title: {
        presence: true,
      },
    };
  }

  urlRoot() {
    return `${this.apiUrlRoot}/curations`;
  }

  parse(response, options) {
    // Don't parse on PATCH requests where API doesn't return model data
    if (options && !options.parse) return;

    // Thumbnail fallback image
    response.thumbnailFallbackUrl = fallBackImage;

    return response;
  }
}

export default CurationModel;
