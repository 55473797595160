import _ from "lodash";
import Backbone from "backbone";
import Controller from "scripts/controllers/controller";
import HomePageView from "scripts/views/homePageView";
import NewHomePageView from "scripts/views/newHomePageView";
import MainView from "scripts/views/mainView";
import inject from "scripts/ioc/inject";

const chan = Backbone.Radio.channel;

class HomeController extends Controller {
  constructor(securityService = inject("securityService")) {
    super();

    this.securityService = securityService;

    chan("display").reply("showFreshHomepage", this.showFreshHomepage, this);
  }

  get route() {
    return "route:home route:home/menu";
  }

  routeAttrs(...args) {
    let pageIndex = parseInt(args[0], 10);

    if (!isNaN(pageIndex)) {
      pageIndex = pageIndex - 1;
    } else {
      pageIndex = 0;
    }

    return {
      pageIndex: pageIndex,
      expandedCurationIds: (args[1] && args[1].split("|")) || [],
    };
  }

  createHomepage(...args) {
    const user = this.securityService.getUser();
    const showNewHomepage =
      user.getActiveOrganizationDynamicProperty("showNewHomepage") === "1";

    return showNewHomepage
      ? new NewHomePageView({
          showMenu: args.includes("menu"),
        })
      : new HomePageView({
          model: new Backbone.Model(this.routeAttrs.apply(this, _.tail(args))),
          showMenu: args.includes("menu"),
        });
  }

  showFreshHomepage() {
    return this.showScreenView(
      new MainView({
        bodyView: this.createHomepage(),
      }),
    );
  }

  replyRoute(...args) {
    return this.showMainBodyView(this.createHomepage(...args));
  }
}

export default HomeController;
