import _ from "lodash";
import inject from "scripts/ioc/inject";
import BaseMediaSalvattoreTileView from "scripts/views/baseMediaSalvattoreTileView";
import templateImageSalvattoreTile from "templates/imageSalvattoreTile.hbs";

class ImageSalvattoreTileView extends BaseMediaSalvattoreTileView {
  get template() {
    return templateImageSalvattoreTile;
  }

  get events() {
    return _.extend({}, super.events, {
      "click .btn-bookshelf": "onClickBookshelfButton",
    });
  }

  constructor(options, securityService = inject("securityService")) {
    super(options, securityService);

    if (this.bookshelfAvailable()) {
      this.initializeBookshelfDeps();
    }
  }

  render() {
    super.render();

    if (this.bookshelfAvailable()) {
      this.renderBookshelfElements();
    }

    return this;
  }
}

export default ImageSalvattoreTileView;
