import BaseView from "scripts/views/baseView";
import templateAnthologyPageOverviewSubPage from "templates/anthologyPageOverviewSubPage.hbs";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";

class AnthologyPageOverviewSubPageView extends BaseView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);
    this.i18nextService = i18nextService;
  }

  get template() {
    return templateAnthologyPageOverviewSubPage;
  }

  render() {
    const { anthology } = this.i18nextService.getGlossary();
    this.$el.html(
      this.template(L.merge({ i18n: anthology }, this.model.toJSON())),
    );
    return this;
  }

  sync() {
    return this.model.fetch();
  }
}

export default AnthologyPageOverviewSubPageView;
