import _ from "lodash";
import Promise from "bluebird";
import CurationMediaCollection from "scripts/collections/curationMediaCollection";
import CurationMediaCountModel from "scripts/models/curationMediaCountModel";
import PagerView from "scripts/views/ellipsisPagerView";
import BaseView from "scripts/views/baseView";
import MediaListView from "scripts/views/mediaListView";
import MediaSalvattoreGridView from "scripts/views/mediaSalvattoreGridView";
import templateCurationPageContentSubPage from "templates/curationPageContentSubPage.hbs";
import { redirectToFragment } from "scripts/utils/routerHelpers";
import { getContentCount } from "../utils/mediaHelpers";
import mediaPageContentSubPageTranslations from "../../i18n/mediaPageContentSubPageTranslations";

// const PAGE_SIZE = 100;
const PAGE_SIZE = 20;

class CurationPageMediaSubPageView extends BaseView {
  get template() {
    return templateCurationPageContentSubPage;
  }

  constructor(options) {
    super(options);

    const mediaId = this.model.get("mediaId");

    this.curationMediaCountModel = new CurationMediaCountModel({
      id: mediaId,
    });

    this.mediaPageContentSubPageTranslations = new mediaPageContentSubPageTranslations();

    const contentTypeUrlFragment = this.contentTypeUrlFragment(this.model.get("contentType"));

    const pageIndex = this.model.get("pageIndex");

    this.collection = new CurationMediaCollection([], {
      path: `/curations/${mediaId}${contentTypeUrlFragment}`,
      mediaId: mediaId,
      state: {
        currentPage: pageIndex,
        pageSize: PAGE_SIZE,
      },
    });

    /**
     * Hack to satisfy Backbone.PageableCollection._checkState
     * if starting from a page other than 0.
     */
    if (pageIndex > 0) {
      this.collection.links[pageIndex] = this.collection.url;
    }
  }

  createContentView() {
    let contentView;

    const mediaCount = getContentCount(this.model.get("contentType"), this.curationMediaCountModel.toJSON());
    const totalPages = Math.ceil(mediaCount / PAGE_SIZE);

    let pageViewOptions = {
      hasPreviousPage: this.collection.hasPreviousPage(),
      hasNextPage: this.collection.hasNextPage(),
      pageNumber: this.model.get("pageIndex") + 1,
      totalPages: totalPages,
    };

    if (this.model.get("presentationType") === "list") {
      contentView = new PagerView(
        _.extend(
          {
            mediaView: new MediaListView({
              collection: this.collection,
            }),
          },
          pageViewOptions,
        ),
      );
    } else {
      contentView = new PagerView(
        _.extend(
          {
            mediaView: new MediaSalvattoreGridView({
              collection: this.collection,
            }),
          },
          pageViewOptions,
        ),
      );
    }

    contentView.on(
      "pageSequenceChanged",
      pageNumber => {
        this.model.set("pageIndex", pageNumber - 1);
        redirectToFragment(this.urlFragment());
      },
      this,
    );

    return contentView;
  }

  urlFragment() {
    const mediaId = this.model.get("mediaId");
    const presentationType = this.model.get("presentationType");
    const contentType = this.model.get("contentType");
    const pageNumber = this.model.get("pageIndex") + 1;
    return `/curation/${mediaId}/${presentationType}/${contentType}/${pageNumber}`;
  }

  render() {
    const model = this.model.toJSON();
    console.log(model);
    const curationMediaCountModel = this.curationMediaCountModel.toJSON();

    this.$el.html(
      this.template(
        _.merge(
          {
            i18n: this.mediaPageContentSubPageTranslations.getTranslations({
              modelMediaType: "curation",
              mediaCountModel: curationMediaCountModel,
            }),
          },
          model,
          curationMediaCountModel,
        ),
      ),
    );

    this.contentView.appendTo($("#bb-curation-page-content-sub-page-content-region")).render();

    this.setActiveFilter();

    return this;
  }

  setActiveFilter() {
    let contentType = this.model.get("contentType");

    if (contentType === "all-media") {
      $("#bb-curation-page-media-sub-page-all-media-link").addClass("active");
    } else {
      let mediaId = this.model.get("mediaId");
      let presentationType = this.model.get("presentationType");
      let href = `"/curation/${mediaId}/${presentationType}/${contentType}"`;
      this.$el.find("a[href=" + href + "].content-filter").addClass("active");
    }
  }

  sync() {
    return Promise.all([this.curationMediaCountModel.fetch(), this.syncCollection()]).then(() => {
      this.contentView = this.addSubView(this.createContentView());
    });
  }

  syncCollection() {
    this.collection.state.currentPage = this.model.get("pageIndex");

    this.collection.queryParams = {
      limit: PAGE_SIZE,
      offset: this.collection.state.currentPage * this.collection.state.pageSize,
    };

    return this.collection
      .fetch()
      .cancellable()
      .then(o => o);
  }

  contentTypeUrlFragment(contentType) {
    if (contentType === "all-media") {
      return "/media";
    } else if (_.includes(["modules", "anthologies", "textbooks", "collections", "chapters"], contentType)) {
      return `/${contentType}`;
    } else if (contentType === "highlights") {
      return "/content?collectionHighlight=1";
    } else {
      return `/content/${contentType}`;
    }
  }
}

export default CurationPageMediaSubPageView;
