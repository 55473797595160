import BaseView from "scripts/views/baseView";
import MediaSalvattoreGridView from "scripts/views/mediaSalvattoreGridView";
import templateCategoriesPageSalvattoreSubPage from "templates/categoriesPageSalvattoreSubPage.hbs";
import L from "lodash/fp";
import inject from "scripts/ioc/inject";

class CategoriesPageSalvattoreSubPageView extends BaseView {
  get template() {
    return templateCategoriesPageSalvattoreSubPage;
  }

  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.salvattoreView = this.addSubView(
      new MediaSalvattoreGridView({
        collection: this.collection,
      }),
    );
  }

  render() {
    const browseByCategory = L.get(
      ["search", "category", "browse"],
      this.i18nextService.getGlossary(),
    );

    this.$el.html(this.template({ i18n: { browseByCategory } }));

    this.salvattoreView
      .appendTo(
        $("#bb-categories-page-salvattore-sub-page-salvattore-view-region"),
      )
      .render();

    return this;
  }

  sync() {
    return this.salvattoreView.fetch();
  }
}

export default CategoriesPageSalvattoreSubPageView;
