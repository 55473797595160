import _ from "lodash";
import Backbone from "backbone";
import BaseApiModel from "scripts/models/baseApiModel";

const chan = Backbone.Radio.channel;

class VoteModel extends BaseApiModel {
  parse(response) {
    return _.omit(response, "message");
  }

  urlRoot() {
    const userId = chan("security")
      .request("user")
      .getUserId();

    return `${this.apiUrlRoot}/users/${userId}/votes/`;
  }
}

export default VoteModel;
