import _ from "lodash";

if (!window.console) {
  window.console = {
    log: _.noop,
    debug: _.noop,
    info: _.noop,
    warn: _.noop,
    error: _.noop,
  };
} else {
  if (!window.console.debug) {
    window.console.debug = window.console.log;
  }

  if (!window.console.info) {
    window.console.info = window.console.log;
  }

  if (!window.console.warn) {
    window.console.warn = window.console.log;
  }

  if (!window.console.error) {
    window.console.error = window.console.log;
  }
}
