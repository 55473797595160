import Backbone from "backbone";
import BaseApiModel from "scripts/models/baseApiModel";

const chan = Backbone.Radio.channel;

class CurrentLocationModel extends BaseApiModel {
  urlRoot() {
    const userId = chan("security")
      .request("user")
      .getUserId();
    const mediaId = this.get("mediaId");

    return `${this.apiUrlRoot}/users/${userId}/currentlocation/${mediaId}`;
  }

  sync(method, model, options) {
    if (method === "create" || method === "update") {
      method = "create";
    }

    return super.sync(method, model, options);
  }
}

export default CurrentLocationModel;
