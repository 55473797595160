import Backbone from "backbone";
import inject from "scripts/ioc/inject";
import AnthologyCollectionPageNavView from "scripts/views/anthologyCollectionPageNavView";
import BaseMediaDetailsPageView from "scripts/views/baseMediaDetailsPageView";

import templateAnthologyCollectionPage from "templates/anthologyCollectionPage.hbs";

const chan = Backbone.Radio.channel;

class AnthologyCollectionPageView extends BaseMediaDetailsPageView {
  constructor(
    options,
    breadcrumbService = inject("breadcrumbService"),
    connectionService = inject("connectionService"),
    securityService = inject("securityService"),
  ) {
    super(options, breadcrumbService, connectionService, securityService);

    this.model.on("change:presentationType", this.onPresentationTypeChangeSaveUserPreference, this);

    chan("controllerView").reply("anthologyCollectionPageView", this.replyPageView, this);
  }

  get template() {
    return templateAnthologyCollectionPage;
  }

  isOverflowed(element) {
    return element && (element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth);
  }

  render() {
    super.render();

    this.renderCurationButton();
    this.renderPageHeaderDescriptionContainer();

    const $description = $("#description-container");

    //-- Make sure all anchor tags open in new tab.
    const $anchors = $description.find("a");
    $anchors.attr("target", "_blank");

    if (!this.isOverflowed($description[0])) {
      const $descriptionContainer = $("#bb-media-details-description-container");
      $descriptionContainer.removeClass("collapse");
      const $descriptionCollapseBtn = $("#description-collapse-button");
      $descriptionCollapseBtn.addClass("hidden");
    }
  }

  createNavView() {
    return new AnthologyCollectionPageNavView({
      model: this.model,
      anthologyCollectionModel: this.mediaModel,
    });
  }

  getBreadcrumbText() {
    return this.mediaModel.get("name");
  }

  getDocumentTitle() {
    return `${this.mediaModel.get("name")}`;
  }
}

export default AnthologyCollectionPageView;
