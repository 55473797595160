import AnthologyCollectionModel from "scripts/models/anthologyCollectionModel";
import AnthologyModel from "scripts/models/anthologyModel";
import BaseApiModel from "scripts/models/baseApiModel";
import ContentModel from "scripts/models/contentModel";
import ModuleModel from "scripts/models/moduleModel";
import CurationModel from "scripts/models/curationModel";

const MediaCollectionHoc = Collection => {
  return class extends Collection {
    model(attrs, options) {
      switch (attrs.mediaType) {
        case "module":
          return new ModuleModel(attrs, options);
        case "anthology":
          return new AnthologyModel(attrs, options);
        /**
         * Ideally, this would be 'anthologyCollection', but API
         * wants to remain consistent with legacy projects.
         */
        case "collection":
          return new AnthologyCollectionModel(attrs, options);
        case "content":
          return new ContentModel(attrs, options);
        case "curation":
          return new CurationModel(attrs, options);
        default:
          return new BaseApiModel(attrs, options);
      }
    }

    mediaTypePercentages() {
      let anthology = 0;
      let written = 0;
      let image = 0;
      let audio = 0;
      let video = 0;

      this.each(model => {
        const mediaType = model.get("mediaType");
        const contentType = model.get("type");

        if (mediaType === "anthology") {
          anthology++;
        } else {
          if (contentType === "ARTICLE") {
            written++;
          } else if (contentType === "AUDIO") {
            audio++;
          } else if (contentType === "BOOK") {
            written++;
          } else if (contentType === "CHAPTER") {
            written++;
          } else if (contentType === "OTHER_DOCUMENT") {
            written++;
          } else if (contentType === "IMAGE") {
            image++;
          } else if (contentType === "VIDEO") {
            video++;
          }
        }
      });

      const length = this.length;

      return {
        anthologyPercent: anthology / length * 100,
        writtenPercent: written / length * 100,
        imagePercent: image / length * 100,
        audioPercent: audio / length * 100,
        videoPercent: video / length * 100,
      };
    }
  };
};

export default MediaCollectionHoc;
