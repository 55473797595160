var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"blackMediaBadges") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":6},"end":{"line":25,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"content-provider-badge content-provider-badge--tile\">\n          <img class=\"content-provider-badge__img--tile\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" alt=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"contentProvider") : stack1)) != null ? lookupProperty(stack1,"alt") : stack1), depth0))
    + "\">\n        </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--BAR COL-->\n<div class=\"bb-list-tile-bar-column\">\n  <div class=\"list-header-media\"></div>\n</div>\n\n<!--IMAGE COL-->\n<div class=\"bb-list-tile-cover-column\">\n  <div class=\"bb-list-tile-cover\">\n    <img class=\"thumbnail-xs\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"iconUrl") || (depth0 != null ? lookupProperty(depth0,"iconUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":35},"end":{"line":9,"column":46}}}) : helper)))
    + "\" data-fallback-src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"iconFallbackUrl") || (depth0 != null ? lookupProperty(depth0,"iconFallbackUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconFallbackUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":67},"end":{"line":9,"column":86}}}) : helper)))
    + "\">\n  </div>\n</div>\n\n<!--COPY COL-->\n<div class=\"bb-list-tile-details-column-media\">\n  <div class=\"bb-list-tile-cover-media bb-list-tile-type-label\" aria-hidden=\"true\">\n    <i class=\"bbico bbico-anthology\"></i>\n    <span class=\"clr-primary-dark p-r-20 p-l-5 text-uppercase\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"anthology") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</span>\n\n    <!--content provider badges-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showBadges") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":26,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n\n    <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":29,"column":14},"end":{"line":29,"column":36}}}) : helper)))
    + "/"
    + alias4((lookupProperty(helpers,"ternary")||(depth0 && lookupProperty(depth0,"ternary"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"internalCode") : depth0),(depth0 != null ? lookupProperty(depth0,"internalCode") : depth0),(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"ternary","hash":{},"data":data,"loc":{"start":{"line":29,"column":37},"end":{"line":29,"column":77}}}))
    + "\" aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"explore") : stack1)) != null ? lookupProperty(stack1,"anthology") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\">\n      <h2 class=\"h3 bb-list-tile-title-media\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":30,"column":46},"end":{"line":30,"column":54}}}) : helper)))
    + "</h2>\n    </a>\n\n    <p class=\"bb-list-tile-subtitle\" aria-hidden=\"true\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"subName") || (depth0 != null ? lookupProperty(depth0,"subName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subName","hash":{},"data":data,"loc":{"start":{"line":33,"column":56},"end":{"line":33,"column":67}}}) : helper)))
    + "</p>\n</div>\n\n<!--BUTTONS COL-->\n<div class=\"bb-list-tile-buttons-column-media\">\n  <div class=\"bb-list-tile-text-buttons-row bb-list-tile-icon-buttons-row\">\n\n    <button aria-label=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"favorite") : stack1), depth0))
    + "\" class=\"btn circle-btn circle-btn-small btn-favorite clr-primary\" data-toggle=\"tooltip\"\n            title=\""
    + alias4((lookupProperty(helpers,"ternary")||(depth0 && lookupProperty(depth0,"ternary"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isFavorite") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"unfavoriteName") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"favoriteName") : stack1),{"name":"ternary","hash":{},"data":data,"loc":{"start":{"line":41,"column":19},"end":{"line":41,"column":79}}}))
    + "\">\n      <i class=\"bbico bbico-favorite\"></i>\n    </button>\n\n    <a class=\"btn btn-view btn-list-media\" href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":45,"column":50},"end":{"line":45,"column":72}}}) : helper)))
    + "/"
    + alias4((lookupProperty(helpers,"ternary")||(depth0 && lookupProperty(depth0,"ternary"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"internalCode") : depth0),(depth0 != null ? lookupProperty(depth0,"internalCode") : depth0),(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"ternary","hash":{},"data":data,"loc":{"start":{"line":45,"column":73},"end":{"line":45,"column":113}}}))
    + "\" aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"explore") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\" data-toggle=\"tooltip\"\n       title=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"explore") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n      <span class=\"fnt-bld\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"explore") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</span>\n    </a>\n\n    <button aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"curation") : stack1)) != null ? lookupProperty(stack1,"addTo") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\" class=\"btn btn-curation-add clr-written hide\"\n            data-toggle=\"tooltip\" title=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"curation") : stack1)) != null ? lookupProperty(stack1,"addTo") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n      <i class=\"bbico bbico-add\"></i>\n    </button>    \n  </div>\n</div>";
},"useData":true});