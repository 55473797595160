import AuthenticationSecureSyncHoc from "scripts/hoc/authenticationSecureSyncHoc";
import BaseModel from "scripts/models/baseModel";

class LogoutAuthenticationModel extends AuthenticationSecureSyncHoc(BaseModel) {
  url() {
    const organizationId = this.get("organizationId");

    return `${this
      .authenticationUrlRoot}/organizations/${organizationId}/logout`;
  }

  toJSON() {
    return {
      token: this.get("token"),
    };
  }
}

export default LogoutAuthenticationModel;
