import Promise from "bluebird";
import BaseApiModel from "scripts/models/baseApiModel";

class MediaLineageModel extends BaseApiModel {
  url() {
    return `${this.apiUrlRoot}/media/match-hierarchy`;
  }

  fetch(options) {
    options = options || {};

    const mediaLineageCandidates = this.get("mediaLineageCandidates") || [];

    // No need to make request if only 1 media id in lineage
    if (mediaLineageCandidates.length > 1) {
      options.method = "POST";
      options.contentType = "application/json";
      options.data = JSON.stringify(mediaLineageCandidates);

      return super.fetch(options);
    } else {
      this.set("mediaLineage", mediaLineageCandidates);

      return Promise.resolve(this);
    }
  }

  parse(response) {
    return {
      mediaLineage: response,
    };
  }
}

export default MediaLineageModel;
