import Backbone from "backbone";
import BaseService from "scripts/services/baseService";
import CounterModel from "scripts/models/counterModel";

const chan = Backbone.Radio.channel;

class CounterService extends BaseService {
  constructor(...args) {
    super(...args);

    const trackingChannel = chan("tracking");

    trackingChannel.on("search", this.logSearch, this);
    trackingChannel.on("search:click", this.logSearchClick, this);
    trackingChannel.on("content:record:view", this.logContentRecordView, this);
    trackingChannel.on("content:open", this.logContentOpen, this);
  }

  logSearch() {
    console.log("COUNTER search");

    this.logEvent({
      counterEventCode: "SEARCH_REGULAR",
    });
  }

  logSearchClick(attrs) {
    console.log("COUNTER search click: %s", attrs.mediaId);

    this.logEvent({
      mediaId: attrs.mediaId,
      counterEventCode: "SEARCH_RESULT_CLICK",
    });
  }

  logContentRecordView(attrs) {
    console.log("COUNTER content detail view: %s", attrs.mediaId);

    this.logEvent({
      mediaId: attrs.mediaId,
      counterEventCode: "CONTENT_RECORD_VIEW",
    });
  }

  logContentOpen(attrs) {
    console.log(
      "COUNTER content open: %s type: %s",
      attrs.mediaId,
      attrs.contentType,
    );

    let eventCode;
    switch (attrs.contentType) {
      case "BOOK":
      case "ARTICLE":
      case "CHAPTER":
      case "OTHER_DOCUMENT":
      case "AUDIOBOOK":
        eventCode = "BOOK_FULL_ACCESS";
        break;
      case "IMAGE":
      case "VIDEO":
      case "AUDIO":
        eventCode = "MULTIMEDIA_FULL_ACCESS";
        break;
      default:
        throw new Error(
          `COUNTER log error - invalid type: ${attrs.contentType}`,
        );
    }

    this.logEvent({
      mediaId: attrs.mediaId,
      counterEventCode: eventCode,
    });
  }

  logEvent(attrs) {
    new CounterModel(attrs).save();
  }
}

export default CounterService;
