import _ from "lodash";
import BaseCollection from "scripts/collections/baseCollection";
import ApiCollectionHoc from "scripts/hoc/apiCollectionHoc";

class BaseApiCollection extends ApiCollectionHoc(BaseCollection) {
  // optional fetch that returns the empty collection if offline is true
  fetch(options) {
    const { isOnline } = _.defaults(options, { isOnline: true });
    if (isOnline) {
      return super.fetch(options);
    } else {
      console.log("Offline collection fetch");
      return Promise.resolve(this);
    }
  }
}

export default BaseApiCollection;
