import BaseApiCollection from "scripts/collections/baseApiCollection";
import CurationModel from "scripts/models/curationModel";

class CurationCollection extends BaseApiCollection {
  get model() {
    return CurationModel;
  }

  get path() {
    return "/curations";
  }
}

export default CurationCollection;
