import BaseService from "scripts/services/baseService";
import { fetchPosition } from "scripts/utils/geoHelpers";

class GeolocationService extends BaseService {
  getCurrentPosition() {
    return fetchPosition(navigator.geolocation);
  }
}

export default GeolocationService;
