import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import BaseService from "scripts/services/baseService";

import { buildAttributes } from "i18n/attributes";
import { buildCommon } from "i18n/common";
import { buildGlossary } from "i18n/glossary";

class i18nextService extends BaseService {
  constructor() {
    super();

    this.attributes = {};
    this.common = {};
    this.glossary = {};
  }

  async initialize() {
    await i18next
      .use(HttpApi)
      .use(LanguageDetector)
      .init({
        // lng: "ar",
        // debug: true,
        fallbackLng: "en",
        ns: ["attributes", "common", "glossary"],
        load: "languageOnly",
        backend: {
          loadPath: "/external/i18next/locales/{{lng}}/{{ns}}.json",
        },
      })
      .then(() => {
        this.internationalizeHtml(i18next);

        this.attributes = buildAttributes();
        this.common = buildCommon();
        this.glossary = buildGlossary();

        i18next.on("languageChanged", () => {
          window.location.reload();
        });
      })
      .catch(e => {
        // TODO: how do we handle if translations don't load?
        console.log(e);
      });
  }

  formatNumber(num) {
    return Intl.NumberFormat(this.getCurrentLanguage()).format(num);
  }

  getAttributes() {
    return this.attributes;
  }

  getCommon() {
    return this.common;
  }

  getCurrentLanguage() {
    return i18next.resolvedLanguage;
  }

  getGlossary() {
    return this.glossary;
  }

  internationalizeHtml(i18next) {
    // Set text direction
    // document.body.dir = i18next.dir();

    // Set HTML language attribute
    $("html").attr("lang", i18next.resolvedLanguage);
  }

  getCurrentLngDir() {
    return i18next.dir();
  }
}

export default i18nextService;
