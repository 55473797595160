import Promise from "bluebird";
import Backbone from "backbone";
import syncError from "scripts/functions/syncError";
import ConflictSyncError from "scripts/exceptions/conflictSyncError";
import ForbiddenSyncError from "scripts/exceptions/forbiddenSyncError";
import NotFoundSyncError from "scripts/exceptions/notFoundSyncError";
import UnauthorizedSyncError from "scripts/exceptions/unauthorizedSyncError";
import UnprocessableEntitySyncError from "scripts/exceptions/unprocessableEntitySyncError";
import UnsentSyncError from "scripts/exceptions/unsentSyncError";

const chan = Backbone.Radio.channel;

const PromisedSyncHoc = Model => {
  return class extends Model {
    sync(method, model, options) {
      const success = options.success;
      const error = options.error;

      return new Promise((resolve, reject) => {
        options.success = (...args) => {
          try {
            success(...args);
          } catch (e) {
            console.log(
              "Caught exception thrown by original sync success callback, returning rejected promise...",
            );

            reject(e);
          }

          resolve(model);
        };

        options.error = (xhr, textStatus, errorThrown) => {
          try {
            error(xhr, textStatus, errorThrown);
          } catch (e) {
            console.log(
              "Caught exception thrown by original sync error callback, returning rejected promise...",
            );

            reject(e);

            return;
          }

          reject(
            syncError(xhr.status, {
              model: model,
              method: method,
              options: options,
              xhr: xhr,
            }),
          );
        };

        super.sync(method, model, options);
      }).catch(error => this.bugsnagError(error));
    }

    bugsnagError(error) {
      if (
        !(error instanceof ForbiddenSyncError) &&
        !(error instanceof ConflictSyncError) &&
        !(error instanceof NotFoundSyncError) &&
        !(error instanceof UnauthorizedSyncError) &&
        !(error instanceof UnprocessableEntitySyncError) &&
        !(error instanceof UnsentSyncError)
      ) {
        chan("bugsnag").request("notify", error); //TODO: this should be a service call
      }

      throw error;
    }
  };
};

export default PromisedSyncHoc;
