import BaseApiCollection from "scripts/collections/baseApiCollection";
import ContentModel from "scripts/models/contentModel";

class CompoundChildrenCollection extends BaseApiCollection {
  constructor(models, options) {
    super(models, options);
    this.mediaId = options.mediaId;
  }

  get model() {
    return ContentModel;
  }

  path() {
    return `/content/${this.mediaId}/compound-children`;
  }
}

export default CompoundChildrenCollection;
