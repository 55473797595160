export default function() {
  document.body.addEventListener(
    "error",
    event => {
      var $el = $(event.target);

      // Only replace the image if it hasn't already been replaced. Prevents infinite replacement
      // in case the fallback fails to load for some reason.
      if (
        $el.is("img[data-fallback-src]") &&
        $el.attr("src") !== $el.attr("data-fallback-src")
      ) {
        $el.attr("src", $el.attr("data-fallback-src"));
      }

      if ($el.is("img[data-no-fallback]")) {
        $el.addClass("hide");
      }
    },
    true,
  );
}
