import inject from "scripts/ioc/inject";
import BaseFormView from "scripts/views/baseFormView";
import BaseModel from "scripts/models/baseModel";
import { fetchOrganizationUserUsingPatronCredentials } from "scripts/utils/securityHelpers";
import L from "lodash/fp";

import template from "templates/patronVerificationForm.hbs";

class PatronVerificationFormView extends BaseFormView {
  constructor(
    options,
    securityService = inject("securityService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);

    this.securityService = securityService;
    this.i18nextService = i18nextService;

    this.organizationId = options.organizationId;
    this.organizationName = options.organizationName;
    this.pinRequired = options.pinRequired;

    const constraints = {
      cardNumber: {
        presence: true,
      },
    };

    if (this.pinRequired) {
      constraints.pin = {
        presence: true,
      };
    }

    this.bindModel(
      new BaseModel(
        {},
        {
          constraints: this.constraints,
        },
      ),
    );
  }

  get events() {
    return {
      "blur form *[name]": "onBlurValidate",
      "input form *[name]": "onInputEnableSubmit",
      "submit form": "onSubmit",
    };
  }

  render() {
    this.$el.html(
      template({
        i18n: this.getTranslations(),
        organizationName: this.organizationName,
        pinRequired: this.pinRequired,
      }),
    );

    this.loaderView
      .appendTo(this.$el.find(".bb-form-submit-button-loader-region"))
      .render();

    this.setTextDirection();

    return this;
  }

  getTranslations() {
    const glossary = this.i18nextService.getGlossary();
    const { placeholder } = this.i18nextService.getAttributes();
    const { button } = this.i18nextService.getCommon();

    const auth = L.flowRight([
      L.pick(["membership", "pleaseVerify"]),
      L.get(["auth"]),
    ])(glossary);

    const { libraryCardNum, pin } = placeholder;

    return {
      auth,
      button: L.pick(["cancel", "submit"], button),
      placeholder: { libraryCardNum, pin },
    };
  }

  setTextDirection() {
    const currentLngDir = this.i18nextService.getCurrentLngDir();
    $("#bb-patron-verification-form-modal-header").css(
      "direction",
      currentLngDir,
    );
  }

  doSubmit() {
    return fetchOrganizationUserUsingPatronCredentials({
      authScope: this.securityService.authScope,
      organizationId: this.organizationId,
      cardNumber: this.model.get("cardNumber"),
      pin: this.model.get("pin"),
      userAgent: navigator.userAgent,
    });
  }

  onSubmitFailed(error) {
    this.enableSubmit(true);
    this.trigger("failure", error);
  }
}

export default PatronVerificationFormView;
