import ConflictSyncError from "scripts/exceptions/conflictSyncError";
import ForbiddenSyncError from "scripts/exceptions/forbiddenSyncError";
import GeneralSyncError from "scripts/exceptions/generalSyncError";
import InternalServerSyncError from "scripts/exceptions/internalServerSyncError";
import NotFoundSyncError from "scripts/exceptions/notFoundSyncError";
import SubscriptionSyncError from "scripts/exceptions/subscriptionSyncError";
import UnauthorizedSyncError from "scripts/exceptions/unauthorizedSyncError";
import UnprocessableEntitySyncError from "scripts/exceptions/unprocessableEntitySyncError";
import UnsentSyncError from "scripts/exceptions/unsentSyncError";

export default function(status, props) {
  status = parseInt(status, 10);

  props = props || {};

  if (status === 0) {
    return new UnsentSyncError(props);
  } else if (status === 403) {
    return new ForbiddenSyncError(props);
  } else if (status === 404) {
    return new NotFoundSyncError(props);
  } else if (status === 401) {
    return new UnauthorizedSyncError(props);
  } else if (status === 409) {
    return new ConflictSyncError(props);
  } else if (status === 422) {
    return new UnprocessableEntitySyncError(props);
  } else if (status === 500) {
    return new InternalServerSyncError(props);
  } else if (status === 999) {
    return new SubscriptionSyncError(props);
  } else {
    return new GeneralSyncError(props);
  }
}
