var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"responsiveContent") : stack1), depth0))
    + "\">\n    <h3 class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"heading") : stack1), depth0))
    + "\">You have requested...</h3>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"infoContainer") : stack1), depth0))
    + "\">\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"thumbnail") : stack1), depth0))
    + " m-t-20\"\n             style=\"background-image: url('"
    + alias2(((helper = (helper = lookupProperty(helpers,"thumbnailUrl") || (depth0 != null ? lookupProperty(depth0,"thumbnailUrl") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"thumbnailUrl","hash":{},"data":data,"loc":{"start":{"line":5,"column":43},"end":{"line":5,"column":59}}}) : helper)))
    + "'), url('../../images/fallbacks/fallback_2_160_160.png');\"></div>\n        <div>\n            <h3 class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"routeTitle") : stack1), depth0))
    + " m-t-20\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":7,"column":53},"end":{"line":7,"column":62}}}) : helper)))
    + "</h3>\n            <span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"routeSubtitle") : stack1), depth0))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"subtitle") || (depth0 != null ? lookupProperty(depth0,"subtitle") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"subtitle","hash":{},"data":data,"loc":{"start":{"line":8,"column":51},"end":{"line":8,"column":63}}}) : helper)))
    + "</span>\n        </div>\n    </div>\n</div>\n";
},"useData":true});