var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"item") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"item") : stack1)) != null ? lookupProperty(stack1,"plural") : stack1), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"faceted-search-filter-count\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"totalFilters") || (depth0 != null ? lookupProperty(depth0,"totalFilters") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"totalFilters","hash":{},"data":data,"loc":{"start":{"line":22,"column":58},"end":{"line":22,"column":74}}}) : helper)))
    + "</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "active";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button id=\"bb-faceted-search-grid-view-link\"><i class=\"fa fa-th\"></i> &nbsp; "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"gridView") : stack1), depth0))
    + "</button>\n                <button id=\"bb-faceted-search-list-view-link\" class=\"hidden\"><i class=\"bbico bbico-list\"></i> &nbsp; "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"listView") : stack1), depth0))
    + "</button>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button id=\"bb-faceted-search-list-view-link\"><i class=\"bbico bbico-list\"></i> &nbsp; "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"listView") : stack1), depth0))
    + "</button>\n                <button id=\"bb-faceted-search-grid-view-link\" class=\"hidden\"><i class=\"fa fa-th\"></i> &nbsp; "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"gridView") : stack1), depth0))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container\">\n\n    <div class=\"faceted-search-summary-menu-results-region\">\n        <div class=\"faceted-search-summary-menu-results-value\">\n            <span class=\"faceted-search-summary-menu-total-records\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"totalRecords") || (depth0 != null ? lookupProperty(depth0,"totalRecords") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalRecords","hash":{},"data":data,"loc":{"start":{"line":5,"column":68},"end":{"line":5,"column":84}}}) : helper)))
    + "</span>\n        </div>\n        <div class=\"faceted-search-summary-menu-results-label\">\n            "
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"totalRecords") : depth0),1,{"name":"equal","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":111}}})) != null ? stack1 : "")
    + "\n        </div>\n        <div class=\"faceted-search-summary-menu-search-term-area\">\n            <span>"
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"filter") : stack1)) != null ? lookupProperty(stack1,"for") : stack1), depth0))
    + "</span>\n            <span id=\"bb-faceted-search-menu-term-link\" tabindex=\"0\" class=\"faceted-search-summary-menu-search-term\">\""
    + alias4(((helper = (helper = lookupProperty(helpers,"searchTerm") || (depth0 != null ? lookupProperty(depth0,"searchTerm") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchTerm","hash":{},"data":data,"loc":{"start":{"line":12,"column":118},"end":{"line":12,"column":132}}}) : helper)))
    + "\"</span>\n        </div>\n    </div>\n\n    <div class=\"fb-row\">\n        <div class=\"navbar-header\">\n            <button type=\"button\" id=\"bb-faceted-search-facet-menu-toggle\" class=\"search-facet-toggle navbar-toggle collapsed\" data-toggle=\"collapse\" data-target=\"#bb-faceted-search-facet-menu-region\">\n                <span class=\"sr-only\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"toggleFilter") : stack1), depth0))
    + "</span>\n                <span class=\"bbico bbico-filter clr-primary\"></span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showFilterCount") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":23,"column":23}}})) != null ? stack1 : "")
    + "            </button>\n        </div>\n\n        <div class=\"faceted-search-summary-menu-sort-region\">\n            <div class=\"btn-group\">\n                <button type=\"button\" class=\"btn btn-rounded btn-rounded-brand-primary dropdown-toggle\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\">\n                    "
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"sortBy") : stack1), depth0))
    + " <span><i class=\"fa fa-angle-down\"></i></span>\n                </button>\n                <ul class=\"dropdown-menu\">\n                    <li><button value=\"default\" class=\"faceted-search-sort-option btn-text "
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"sortField") : depth0),"default",{"name":"equal","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":91},"end":{"line":33,"column":137}}})) != null ? stack1 : "")
    + "\">\n                        "
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"relevance") : stack1), depth0))
    + "  <span class=\"m-l-20\"><i class=\"bbico bbico-check\"></i></span>\n                    </button></li>\n                    <li><button value=\"name asc\" class=\"faceted-search-sort-option btn-text "
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"sortField") : depth0),"name asc",{"name":"equal","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":92},"end":{"line":36,"column":139}}})) != null ? stack1 : "")
    + "\">\n                        "
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"name") : stack1)) != null ? lookupProperty(stack1,"asc") : stack1), depth0))
    + "  <span class=\"m-l-20\"><i class=\"bbico bbico-check\"></i></span>\n                    </button></li>\n                    <li><button value=\"name desc\" class=\"faceted-search-sort-option btn-text "
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"sortField") : depth0),"name desc",{"name":"equal","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":93},"end":{"line":39,"column":141}}})) != null ? stack1 : "")
    + "\">\n                        "
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"name") : stack1)) != null ? lookupProperty(stack1,"desc") : stack1), depth0))
    + "  <span class=\"m-l-20\"><i class=\"bbico bbico-check\"></i></span>\n                    </button></li>\n                    <li><button value=\"contentDatePublished asc\" class=\"faceted-search-sort-option btn-text "
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"sortField") : depth0),"contentDatePublished asc",{"name":"equal","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":108},"end":{"line":42,"column":171}}})) != null ? stack1 : "")
    + "\">\n                        "
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"publicationDate") : stack1)) != null ? lookupProperty(stack1,"asc") : stack1), depth0))
    + "  <span class=\"m-l-20\"><i class=\"bbico bbico-check\"></i></span>\n                    </button></li>\n                    <li><button value=\"contentDatePublished desc\" class=\"faceted-search-sort-option btn-text "
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"sortField") : depth0),"contentDatePublished desc",{"name":"equal","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":109},"end":{"line":45,"column":173}}})) != null ? stack1 : "")
    + "\">\n                        "
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"publicationDate") : stack1)) != null ? lookupProperty(stack1,"desc") : stack1), depth0))
    + "  <span class=\"m-l-20\"><i class=\"bbico bbico-check\"></i></span>\n                    </button></li>\n                </ul>\n            </div>\n        </div>\n\n        <div class=\"faceted-search-summary-menu-button-region\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isListView") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":53,"column":12},"end":{"line":59,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});