import Backbone from "backbone";
import L from "lodash/fp";
import BaseSecondaryStickyView from "scripts/views//baseSecondaryStickyNavView";
import templateModulePageNav from "templates/modulePageNav.hbs";

const chan = Backbone.Radio.channel;

class ModulePageNavView extends BaseSecondaryStickyView {
  constructor(options) {
    super(options);

    this.sortOption = options.sortOption || "name asc";

    chan("modulePageNav").on(
      "sortOptionChange",
      sortOption => {
        this.sortOption = sortOption;
        this.render();
      },
      this,
    );
  }

  get template() {
    return templateModulePageNav;
  }

  render() {
    const navModel = this.model.toJSON();
    if (navModel.collection) {
      navModel.itemCount = this.i18nextService.formatNumber(navModel.collection.length);
    }

    this.$el.html(
      this.template(
        L.merge(
          {
            i18n: this.mediaPageNavTranslations.getTranslations(),
            sortOption: this.sortOption,
          },
          navModel,
        ),
      ),
    );

    this.updateLinkState();
    this.updatePosition();

    return this;
  }
}

export default ModulePageNavView;
