import BasicApiModel from "scripts/models/basicApiModel";

class UserProfileModel extends BasicApiModel {
  url() {
    return `${this.apiUrlRoot}/session/principal`;
  }

  parse(data) {
    if (data && data.userProfile && data.userProfile.contentView) {
      let contentView = data.userProfile.contentView.toLowerCase();
      if (contentView === "visual") {
        contentView = "masonry";
      }

      data.userProfile.contentView = contentView;
    }

    return data;
  }
}

export default UserProfileModel;
