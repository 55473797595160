var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"bb-curation-multi-select-region\">\n    <div>\n        <div id=\"bb-curation-multiselect-inactive\" class=\"row\">\n            <div class=\"col-md-12 text-right\">\n                <button id=\"bb-curation-multiselect-toggle-button\" class=\"p-0\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"selectForCuration") : stack1), depth0))
    + "</button>\n            </div>\n        </div>\n        <div id=\"bb-curation-multiselect-active\" class=\"row hide\">\n            <div class=\"col-md-6 text-left\">\n            </div>\n            <div class=\"col-md-6 text-right\">\n                <button id=\"bb-curation-multiselect-add-button\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToCuration") : stack1), depth0))
    + " ("
    + alias2(((helper = (helper = lookupProperty(helpers,"selectedCount") || (depth0 != null ? lookupProperty(depth0,"selectedCount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"selectedCount","hash":{},"data":data,"loc":{"start":{"line":18,"column":88},"end":{"line":18,"column":107}}}) : helper)))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"selected") : stack1), depth0))
    + ")</button>\n                <button id=\"bb-curation-multiselect-cancel-button\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"cancel") : stack1), depth0))
    + "</button>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});