import BaseFormView from "scripts/views/baseFormView";
import ResetPasswordModel from "scripts/models/resetPasswordModel";
import templateResetPasswordForm from "templates/resetPasswordForm.hbs";

class ResetPasswordFormView extends BaseFormView {
  get template() {
    return templateResetPasswordForm;
  }

  get events() {
    return {
      "blur form *[name]": "onBlurValidate",
      "input form *[name]": "onInputEnableSubmit",
      "submit form": "onSubmit",
    };
  }

  constructor(options) {
    options = options || {};

    super(options);

    this.bindModel(new ResetPasswordModel());
  }

  render() {
    this.$el.html(this.template());

    this.loaderView
      .appendTo(this.$el.find(".bb-form-submit-button-loader-region"))
      .render();

    return this;
  }
}

export default ResetPasswordFormView;
