var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a href=\"/home\"\n                            class=\"bb-home-page-page-link btn-outline btn-outline-gray btn-outline-condensed btn-block\"\n                            aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"firstPage") : stack1), depth0))
    + "\">\n                            <i class=\"bbico bbico-beginning\"></i>\n                        </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a class=\"btn-outline btn-outline-gray btn-outline-condensed btn-block\" disabled=\"disabled\"\n                            aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"firstPage") : stack1), depth0))
    + "\">\n                            <i class=\"bbico bbico-beginning\"></i>\n                        </a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a href=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"previousPageUrl") || (depth0 != null ? lookupProperty(depth0,"previousPageUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"previousPageUrl","hash":{},"data":data,"loc":{"start":{"line":31,"column":33},"end":{"line":31,"column":52}}}) : helper)))
    + "\" aria-label=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"prevPage") : stack1), depth0))
    + "\"\n                            class=\"bb-home-page-page-link btn-outline btn-outline-gray btn-outline-condensed btn-block\">\n                            <i class=\"bbico bbico-chevron-left\"></i>\n                        </a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a class=\"btn-outline btn-outline-gray btn-outline-condensed btn-block\"\n                            aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"prevPage") : stack1), depth0))
    + "\" disabled=\"disabled\">\n                            <i class=\"bbico bbico-chevron-left\"></i>\n                        </a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a href=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"nextPageUrl") || (depth0 != null ? lookupProperty(depth0,"nextPageUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"nextPageUrl","hash":{},"data":data,"loc":{"start":{"line":45,"column":33},"end":{"line":45,"column":48}}}) : helper)))
    + "\" aria-label=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"nextPage") : stack1), depth0))
    + "\"\n                            class=\"bb-home-page-page-link btn-outline btn-outline-gray btn-outline-condensed btn-block\">\n                            <i class=\"bbico bbico-chevron-right\"></i>\n                        </a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a class=\"btn-outline btn-outline-gray btn-outline-condensed btn-block\" aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"nextPage") : stack1), depth0))
    + "\"\n                            disabled=\"disabled\">\n                            <i class=\"bbico bbico-chevron-right\"></i>\n                        </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"bb-home-page-curations-region\"></div>\n\n<!--pagination controls-->\n<div class=\"container bb-submitbar pagination-bar\" role=\"navigation\" aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"pager") : stack1), depth0))
    + "\">\n    <div class=\"row\">\n        <div class=\"col-sm-4\">\n            <a href=\"/curations\" class=\"btn btn-rounded btn-rounded-brand-primary btn-block m-b-10 text-uppercase\">\n                "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"curation") : stack1)) != null ? lookupProperty(stack1,"viewAll") : stack1), depth0))
    + "\n            </a>\n        </div>\n        <div class=\"col-sm-3 col-sm-offset-5 text-right\">\n            <div class=\"row\">\n                <div class=\"col-xs-4\">\n                    <!--first page btn-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"previousPageUrl") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":26,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"col-xs-4\">\n                    <!--prev page btn-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"previousPageUrl") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":40,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"col-xs-4\">\n                    <!--next page btn-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"nextPageUrl") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":44,"column":20},"end":{"line":54,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n    </div>\n</div>\n<!--///pagination controls-->";
},"useData":true});