import BaseView from "scripts/views/baseView";
import DisqusCommentsView from "scripts/views/disqusCommentsView";

import templateContentDetailsPageCommentsSubPage from "templates/contentDetailsPageCommentsSubPage.hbs";

class ContentDetailsPageCommentsSubPageView extends BaseView {
  get template() {
    return templateContentDetailsPageCommentsSubPage;
  }

  constructor(options) {
    super(options);

    this.disqusCommentsView = this.addSubView(
      new DisqusCommentsView({
        mediaId: this.model.get("mediaId"),
      }),
    );
  }

  render() {
    this.$el.html(this.template());

    this.disqusCommentsView
      .attachTo(this.$el.find("#bb-content-details-comment-region"))
      .render();

    return this;
  }

  sync() {
    return this.disqusCommentsView.fetch();
  }
}

export default ContentDetailsPageCommentsSubPageView;
