var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"content-details-awards p-tb-20\">\n                <label class=\"content-details-header m-b-10\">Awards &amp; Honors</label>\n\n                <div class=\"content-details-awards-row\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"awardMediaBadges") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":13,"column":29}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n\n            <svg class=\"content-details-divider\" xmlns=\"http://www.w3.org/2000/svg\" width=\"100%\" height=\"2\" fill=\"none\">\n                <line x1=\"100%\" y1=\"0%\" x2=\"0%\" y2=\"100%\" stroke=\"#CDCDCD\"/>\n            </svg>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"content-details-award\">\n                            <img src=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" alt=\"Award for "
    + alias1(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":11,"column":67},"end":{"line":11,"column":76}}}) : helper)))
    + "\" />\n                        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"p-tb-20\">\n            <label class=\"content-details-header m-b-10\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "</label>\n            <div itemprop=\"description\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"descriptionText") || (depth0 != null ? lookupProperty(depth0,"descriptionText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"descriptionText","hash":{},"data":data,"loc":{"start":{"line":26,"column":40},"end":{"line":26,"column":61}}}) : helper))) != null ? stack1 : "")
    + "</div>\n        </div>\n\n        <svg class=\"content-details-divider\" xmlns=\"http://www.w3.org/2000/svg\" width=\"100%\" height=\"2\" fill=\"none\">\n            <line x1=\"100%\" y1=\"0%\" x2=\"0%\" y2=\"100%\" stroke=\"#CDCDCD\"/>\n        </svg>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\">\n                <label>"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"series") : stack1), depth0))
    + "</label>\n                <p>"
    + alias1(((helper = (helper = lookupProperty(helpers,"series") || (depth0 != null ? lookupProperty(depth0,"series") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"series","hash":{},"data":data,"loc":{"start":{"line":41,"column":19},"end":{"line":41,"column":29}}}) : helper)))
    + "</p>\n            </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\">\n                <label>"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"language") : stack1), depth0))
    + "</label>\n                <p itemprop=\"inLanguage\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"language") || (depth0 != null ? lookupProperty(depth0,"language") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"language","hash":{},"data":data,"loc":{"start":{"line":49,"column":41},"end":{"line":49,"column":53}}}) : helper)))
    + "</p>\n            </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\">\n                <label>"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"publicationDate") : stack1), depth0))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"publicationDateCirca") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":16},"end":{"line":59,"column":23}}})) != null ? stack1 : "")
    + "                <p itemprop=\"datePublished\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"publicationDate") || (depth0 != null ? lookupProperty(depth0,"publicationDate") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"publicationDate","hash":{},"data":data,"loc":{"start":{"line":60,"column":44},"end":{"line":60,"column":63}}}) : helper)))
    + "</p>\n            </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                  <span class=\"m-r-5\">c.</span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row-information\">\n            <label>"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"ageRange") : stack1), depth0))
    + "</label>\n            <p>"
    + alias1(((helper = (helper = lookupProperty(helpers,"ageRange") || (depth0 != null ? lookupProperty(depth0,"ageRange") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ageRange","hash":{},"data":data,"loc":{"start":{"line":68,"column":15},"end":{"line":68,"column":27}}}) : helper)))
    + "</p>\n        </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\">\n                <label>"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"audioType") : stack1), depth0))
    + "</label>\n                <p>"
    + alias1(((helper = (helper = lookupProperty(helpers,"audioType") || (depth0 != null ? lookupProperty(depth0,"audioType") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"audioType","hash":{},"data":data,"loc":{"start":{"line":76,"column":19},"end":{"line":76,"column":32}}}) : helper)))
    + "</p>\n            </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\">\n                <label>"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"episode") : stack1), depth0))
    + "</label>\n                <p>"
    + alias1(((helper = (helper = lookupProperty(helpers,"episode") || (depth0 != null ? lookupProperty(depth0,"episode") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"episode","hash":{},"data":data,"loc":{"start":{"line":90,"column":19},"end":{"line":90,"column":30}}}) : helper)))
    + "</p>\n            </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\" itemscope itemprop=\"isPartOf\" itemtype=\"https://schema.org/PublicationVolume\">\n                <label>"
    + alias1(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"volume") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</label>\n                <p itemprop=\"volumeNumber\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"periodicalVolume") || (depth0 != null ? lookupProperty(depth0,"periodicalVolume") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"periodicalVolume","hash":{},"data":data,"loc":{"start":{"line":98,"column":43},"end":{"line":98,"column":63}}}) : helper)))
    + "</p>\n            </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\">\n                <label>"
    + alias1(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"volume") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</label>\n                <p>"
    + alias1(((helper = (helper = lookupProperty(helpers,"volume") || (depth0 != null ? lookupProperty(depth0,"volume") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"volume","hash":{},"data":data,"loc":{"start":{"line":106,"column":19},"end":{"line":106,"column":29}}}) : helper)))
    + "</p>\n            </div>            \n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\">\n                <label>"
    + alias1(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"edition") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</label>\n                <p>"
    + alias1(((helper = (helper = lookupProperty(helpers,"edition") || (depth0 != null ? lookupProperty(depth0,"edition") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"edition","hash":{},"data":data,"loc":{"start":{"line":114,"column":19},"end":{"line":114,"column":30}}}) : helper)))
    + "</p>\n            </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\">\n                <label>"
    + alias1(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"issue") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</label>\n                <p>"
    + alias1(((helper = (helper = lookupProperty(helpers,"issueNumber") || (depth0 != null ? lookupProperty(depth0,"issueNumber") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"issueNumber","hash":{},"data":data,"loc":{"start":{"line":122,"column":19},"end":{"line":122,"column":34}}}) : helper)))
    + "</p>\n            </div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\">\n                <label>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1), depth0))
    + "</label>\n                <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"attribution") || (depth0 != null ? lookupProperty(depth0,"attribution") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"attribution","hash":{},"data":data,"loc":{"start":{"line":130,"column":19},"end":{"line":130,"column":36}}}) : helper))) != null ? stack1 : "")
    + "</p>\n            </div>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\">\n                <label>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"audience") : stack1), depth0))
    + "</label>\n                <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"audience") || (depth0 != null ? lookupProperty(depth0,"audience") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"audience","hash":{},"data":data,"loc":{"start":{"line":138,"column":19},"end":{"line":138,"column":33}}}) : helper))) != null ? stack1 : "")
    + "</p>\n            </div>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\">\n                <label>"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"raveLink") : stack1), depth0))
    + "</label>\n                <p><a href=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"raveLink") || (depth0 != null ? lookupProperty(depth0,"raveLink") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"raveLink","hash":{},"data":data,"loc":{"start":{"line":146,"column":28},"end":{"line":146,"column":40}}}) : helper)))
    + "\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"raveLink") || (depth0 != null ? lookupProperty(depth0,"raveLink") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"raveLink","hash":{},"data":data,"loc":{"start":{"line":146,"column":42},"end":{"line":146,"column":54}}}) : helper)))
    + "</a></p>\n            </div>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\">\n                <label>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"category") : stack1), depth0))
    + "</label>\n                <div id=\"bb-content-details-book-page-details-sub-page-category-region\"></div>\n            </div>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\">\n                <label>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "</label>\n                <p>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "</p>\n            </div>\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\">\n                <label>"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"issn") : stack1), depth0))
    + "</label>\n                <p>"
    + alias1(((helper = (helper = lookupProperty(helpers,"issn") || (depth0 != null ? lookupProperty(depth0,"issn") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"issn","hash":{},"data":data,"loc":{"start":{"line":170,"column":19},"end":{"line":170,"column":27}}}) : helper)))
    + "</p>\n            </div>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\">\n                <label>"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"keywords") : stack1), depth0))
    + "</label>\n                <p>"
    + alias1(((helper = (helper = lookupProperty(helpers,"keywords") || (depth0 != null ? lookupProperty(depth0,"keywords") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"keywords","hash":{},"data":data,"loc":{"start":{"line":178,"column":19},"end":{"line":178,"column":31}}}) : helper)))
    + "</p>\n            </div>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\">\n                <label>"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"publisher") : stack1), depth0))
    + "</label>\n                <p itemprop=\"publisher\" itemscope itemtype=\"http://schema.org/Thing\"><span\n                        itemprop=\"name\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"publisherName") || (depth0 != null ? lookupProperty(depth0,"publisherName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"publisherName","hash":{},"data":data,"loc":{"start":{"line":187,"column":40},"end":{"line":187,"column":57}}}) : helper)))
    + "</span></p>\n            </div>\n";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\" itemprop=\"publication\" itemscope itemtype=\"http://schema.org/PublicationEvent\">\n                <label>"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"placeOfPublication") : stack1), depth0))
    + "</label>\n                <p itemprop=\"location\" itemscope itemtype=\"http://schema.org/Place\"><span\n                        itemprop=\"name\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"placeOfPublication") || (depth0 != null ? lookupProperty(depth0,"placeOfPublication") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"placeOfPublication","hash":{},"data":data,"loc":{"start":{"line":196,"column":40},"end":{"line":196,"column":62}}}) : helper)))
    + "</span></p>\n            </div>\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\">\n                <label>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"doi") : stack1), depth0))
    + "</label>\n                <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"doi") || (depth0 != null ? lookupProperty(depth0,"doi") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"doi","hash":{},"data":data,"loc":{"start":{"line":204,"column":19},"end":{"line":204,"column":28}}}) : helper))) != null ? stack1 : "")
    + "</p>\n            </div>\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isTorl") : depth0),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.program(50, data, 0),"data":data,"loc":{"start":{"line":211,"column":16},"end":{"line":215,"column":23}}})) != null ? stack1 : "")
    + "                <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"contentProviderCollection") || (depth0 != null ? lookupProperty(depth0,"contentProviderCollection") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"contentProviderCollection","hash":{},"data":data,"loc":{"start":{"line":216,"column":19},"end":{"line":216,"column":48}}}) : helper)))
    + "</p>\n            </div>\n";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <label>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"funder") : stack1), depth0))
    + "</label>\n";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <label>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"collectionInfo") : stack1), depth0))
    + "</label>\n";
},"52":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row-information\">\n          <label>Local Library</label>\n<!--          <label>"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"scanSource") : stack1), depth0))
    + "</label>-->\n          <p>"
    + alias1((lookupProperty(helpers,"atIndex")||(depth0 && lookupProperty(depth0,"atIndex"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"stipProperties") : depth0),0,"localLibrary",{"name":"atIndex","hash":{},"data":data,"loc":{"start":{"line":225,"column":13},"end":{"line":225,"column":56}}}))
    + "</p>\n        </div>\n";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-information\">\n                <label>"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"scanSource") : stack1), depth0))
    + "</label>\n                <p>"
    + alias1(((helper = (helper = lookupProperty(helpers,"scanSource") || (depth0 != null ? lookupProperty(depth0,"scanSource") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"scanSource","hash":{},"data":data,"loc":{"start":{"line":234,"column":19},"end":{"line":234,"column":33}}}) : helper)))
    + "</p>\n            </div>\n";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <!-- Chapters --> \n    <div id=\"bb-content-details-chapters-region\" class=\"content-details-sub-page-container\">\n        <div class=\"content-details-header-row\">\n            <button \n                class=\"content-details-header\"\n                aria-expanded=\"false\"\n                data-toggle=\"collapse\"\n                data-target=\".bb-chapter-list-tiles-container\">\n                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"chapter") : stack1)) != null ? lookupProperty(stack1,"plural") : stack1), depth0))
    + "\n            </button>\n            <i class=\"fa fa-chevron-right content-details-chevron\"></i>\n            <i class=\"fa fa-chevron-down content-details-chevron hide\"></i>\n        </div>\n\n        <div class=\"bb-chapter-list-tiles-container collapse p-t-20\"></div>\n    </div>\n\n    <svg class=\"content-details-divider\" xmlns=\"http://www.w3.org/2000/svg\" width=\"100%\" height=\"2\" fill=\"none\">\n        <line x1=\"100%\" y1=\"0%\" x2=\"0%\" y2=\"100%\" stroke=\"#CDCDCD\"/>\n    </svg>\n";
},"58":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <!-- Album Tracks -->\n        <div id=\"bb-content-details-album-tracks-region\" class=\"content-details-sub-page-container\">\n            <div class=\"content-details-header-row\">\n                <button \n                    class=\"content-details-header\"\n                    aria-expanded=\"false\"\n                    data-toggle=\"collapse\"\n                    data-target=\".bb-album-tracks-list-tiles-container\">\n                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"tracks") : stack1), depth0))
    + "\n                </button>\n                <i class=\"fa fa-chevron-right content-details-chevron\"></i>\n                <i class=\"fa fa-chevron-down content-details-chevron hide\"></i>\n            </div>\n\n            <div class=\"bb-album-tracks-list-tiles-container collapse p-t-20\"></div>\n        </div>\n\n        <svg class=\"content-details-divider\" xmlns=\"http://www.w3.org/2000/svg\" width=\"100%\" height=\"2\" fill=\"none\">\n            <line x1=\"100%\" y1=\"0%\" x2=\"0%\" y2=\"100%\" stroke=\"#CDCDCD\"/>\n        </svg>\n";
},"60":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <!-- Citations -->\n    <div class=\"content-details-sub-page-container p-\">\n        <div class=\"content-details-header-row\">\n            <button \n                id=\"bb-content-details-sub-page-citations-button\" \n                aria-expanded=\"false\" \n                class=\"content-details-header\" \n                data-toggle=\"collapse\" \n                data-target=\"#bb-citations-sub-page-container\">\n                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"citations") : stack1), depth0))
    + "\n            </button>\n            <i class=\"fa fa-chevron-right content-details-chevron\"></i>\n            <i class=\"fa fa-chevron-down content-details-chevron hide\"></i>\n        </div>\n\n        <div class=\"collapse p-t-20\" id=\"bb-citations-sub-page-container\"></div>\n    </div>\n\n    <svg class=\"content-details-divider\" xmlns=\"http://www.w3.org/2000/svg\" width=\"100%\" height=\"2\" fill=\"none\">\n        <line x1=\"100%\" y1=\"0%\" x2=\"0%\" y2=\"100%\" stroke=\"#CDCDCD\"/>\n    </svg>\n";
},"62":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <!-- Notes -->\n    <div id=\"bb-content-details-notes-region\" class=\"content-details-sub-page-container\">\n        <div class=\"content-details-header-row\">\n            <button class=\"content-details-header\" aria-expanded=\"false\" data-toggle=\"collapse\" data-target=\"#bb-content-details-page-note-form-region\">\n                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"note") : stack1)) != null ? lookupProperty(stack1,"plural") : stack1), depth0))
    + "\n            </button>\n            <i class=\"fa fa-chevron-right content-details-chevron\"></i>\n            <i class=\"fa fa-chevron-down content-details-chevron hide\"></i>\n        </div>\n\n        <div class=\"collapse p-t-20\" id=\"bb-content-details-page-note-form-region\"></div>\n\n    </div>\n\n    <svg class=\"content-details-divider\" xmlns=\"http://www.w3.org/2000/svg\" width=\"100%\" height=\"2\" fill=\"none\">\n        <line x1=\"100%\" y1=\"0%\" x2=\"0%\" y2=\"100%\" stroke=\"#CDCDCD\"/>\n    </svg>\n";
},"64":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <!-- Comments -->\n    <div id=\"bb-content-details-comments-region\" class=\"content-details-sub-page-container\">\n        <div class=\"content-details-header-row\">\n            <button class=\"content-details-header\" aria-expanded=\"false\" data-toggle=\"collapse\" data-target=\"#bb-content-details-comment-region\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"comments") : stack1), depth0))
    + "</button>\n            <i class=\"fa fa-chevron-right content-details-chevron\"></i>\n            <i class=\"fa fa-chevron-down content-details-chevron hide\"></i>\n        </div>\n\n        <div class=\"collapse\" id=\"bb-content-details-comment-region\"></div>\n\n    </div>\n\n    <svg class=\"content-details-divider\" xmlns=\"http://www.w3.org/2000/svg\" width=\"100%\" height=\"2\" fill=\"none\">\n        <line x1=\"100%\" y1=\"0%\" x2=\"0%\" y2=\"100%\" stroke=\"#CDCDCD\"/>\n    </svg> \n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", alias5=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div id=\"bb-content-details-sub-page\" class=\"container\">\n  <div class=\"row row-meta m-0 p-0\">\n        <!-- Awards -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAwards") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":20,"column":15}}})) != null ? stack1 : "")
    + "\n    <!-- Description -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"descriptionText") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":32,"column":11}}})) != null ? stack1 : "")
    + "\n    <!-- Information / Metadata -->\n    <div class=\"details-table p-tb-20\">\n        <label class=\"content-details-header m-b-10\">Information</label>\n        <!-- Series -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"series") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":43,"column":15}}})) != null ? stack1 : "")
    + "\n        <!-- Language -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"language") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":8},"end":{"line":51,"column":15}}})) != null ? stack1 : "")
    + "\n        <!-- Publication Date -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"publicationDate") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":8},"end":{"line":62,"column":15}}})) != null ? stack1 : "")
    + "\n        <!--Age Range -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ageRange") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":8},"end":{"line":70,"column":15}}})) != null ? stack1 : "")
    + "\n        <!-- Audio Type -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"audioType") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":8},"end":{"line":78,"column":15}}})) != null ? stack1 : "")
    + "\n        <!-- License Type -->\n        <div class=\"row-information\">\n            <label>"
    + alias2(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"licenseType") : stack1), depth0))
    + "</label>\n            <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"licenseType") || (depth0 != null ? lookupProperty(depth0,"licenseType") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"licenseType","hash":{},"data":data,"loc":{"start":{"line":83,"column":15},"end":{"line":83,"column":30}}}) : helper)))
    + "</p>\n        </div>\n\n        <!-- Episode -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"episode") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":8},"end":{"line":92,"column":15}}})) != null ? stack1 : "")
    + "\n        <!-- Volume Number -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"periodicalVolume") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":8},"end":{"line":100,"column":15}}})) != null ? stack1 : "")
    + "\n        <!-- Volume -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"volume") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":8},"end":{"line":108,"column":15}}})) != null ? stack1 : "")
    + "\n        <!-- Edition -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"edition") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":111,"column":8},"end":{"line":116,"column":15}}})) != null ? stack1 : "")
    + "\n        <!-- Issue -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"issueNumber") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":119,"column":8},"end":{"line":124,"column":15}}})) != null ? stack1 : "")
    + "\n        <!-- Attribution -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"attribution") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":127,"column":8},"end":{"line":132,"column":15}}})) != null ? stack1 : "")
    + "\n        <!-- Audience -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"audience") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":135,"column":8},"end":{"line":140,"column":15}}})) != null ? stack1 : "")
    + "\n        <!-- Rave Links -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"raveLink") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":143,"column":8},"end":{"line":148,"column":15}}})) != null ? stack1 : "")
    + "\n        <!-- Category -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"bisacCategories") : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":151,"column":8},"end":{"line":156,"column":15}}})) != null ? stack1 : "")
    + "\n        <!-- Product Identifiers -->\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"productIdentifiers") : depth0),{"name":"each","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":159,"column":8},"end":{"line":164,"column":17}}})) != null ? stack1 : "")
    + "\n        <!-- ISSN -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"issn") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":167,"column":8},"end":{"line":172,"column":15}}})) != null ? stack1 : "")
    + "\n        <!-- Keywords -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"keywords") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":175,"column":8},"end":{"line":180,"column":15}}})) != null ? stack1 : "")
    + "\n        <!-- Publisher -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"publisherName") : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":183,"column":8},"end":{"line":189,"column":15}}})) != null ? stack1 : "")
    + "\n        <!-- Place of Publication -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"placeOfPublication") : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":192,"column":8},"end":{"line":198,"column":15}}})) != null ? stack1 : "")
    + "\n        <!-- DOI -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"doi") : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":201,"column":8},"end":{"line":206,"column":15}}})) != null ? stack1 : "")
    + "\n        <!-- Content Provider Collection -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"contentProviderCollection") : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":209,"column":8},"end":{"line":218,"column":15}}})) != null ? stack1 : "")
    + "\n      <!-- Content Provider Collection -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stipProperties") : depth0),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":221,"column":6},"end":{"line":227,"column":13}}})) != null ? stack1 : "")
    + "\n\n      <!-- Scan Source -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"scanSource") : depth0),{"name":"if","hash":{},"fn":container.program(54, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":231,"column":8},"end":{"line":236,"column":15}}})) != null ? stack1 : "")
    + "\n    </div>\n\n    <svg class=\"content-details-divider\" xmlns=\"http://www.w3.org/2000/svg\" width=\"100%\" height=\"2\" fill=\"none\">\n        <line x1=\"100%\" y1=\"0%\" x2=\"0%\" y2=\"100%\" stroke=\"#CDCDCD\"/>\n    </svg>\n    \n\n    <!-- Also Found In -->\n    <div id=\"bb-content-details-also-found-in-region\"></div>\n\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"hasChapters") || (depth0 != null ? lookupProperty(depth0,"hasChapters") : depth0)) != null ? helper : alias3),(options={"name":"hasChapters","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":248,"column":4},"end":{"line":269,"column":20}}}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"hasChapters")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isAlbum") || (depth0 != null ? lookupProperty(depth0,"isAlbum") : depth0)) != null ? helper : alias3),(options={"name":"isAlbum","hash":{},"fn":container.program(58, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":271,"column":4},"end":{"line":292,"column":16}}}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isAlbum")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"showCitations") || (depth0 != null ? lookupProperty(depth0,"showCitations") : depth0)) != null ? helper : alias3),(options={"name":"showCitations","hash":{},"fn":container.program(60, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":295,"column":4},"end":{"line":317,"column":22}}}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"showCitations")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"hasProfile") || (depth0 != null ? lookupProperty(depth0,"hasProfile") : depth0)) != null ? helper : alias3),(options={"name":"hasProfile","hash":{},"fn":container.program(62, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":319,"column":4},"end":{"line":337,"column":19}}}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"hasProfile")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"showComments") || (depth0 != null ? lookupProperty(depth0,"showComments") : depth0)) != null ? helper : alias3),(options={"name":"showComments","hash":{},"fn":container.program(64, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":339,"column":4},"end":{"line":355,"column":21}}}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"showComments")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n  </div>\n</div>";
},"useData":true});