var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"bb-login-form-form\" class=\"bb-form\" role=\"form\">\n    <div class=\"row\">\n        <div class=\"col-md-6\">\n            <div class=\"form-group\">\n                <input aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"placeholder") : stack1)) != null ? lookupProperty(stack1,"username") : stack1), depth0))
    + "\" name=\"username\" class=\"form-control input-outline m-t-5\"\n                    id=\"bb-login-form-username-field\" placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"placeholder") : stack1)) != null ? lookupProperty(stack1,"username") : stack1), depth0))
    + "\" autocapitalize=\"off\" autocorrect=\"off\"\n                    autocomplete=\"username\">\n            </div>\n        </div>\n        <div class=\"col-md-6\">\n            <div class=\"form-group\">\n                <input aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"placeholder") : stack1)) != null ? lookupProperty(stack1,"password") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "\" name=\"password\" type=\"password\" class=\"form-control input-outline m-t-5\"\n                    id=\"bb-login-form-password-field\" placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"placeholder") : stack1)) != null ? lookupProperty(stack1,"password") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "\" autocomplete=\"current-password\">\n            </div>\n        </div>\n    </div>\n\n    <!--recaptcha-->\n    <div id=\"bb-login-form-recaptcha\" class=\"m-tb-10\"></div>\n\n    <!--submit button-->\n    <button type=\"submit\" aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"explore") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\" class=\"btn-rounded btn-rounded-brand-primary btn-block\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"explore") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</button>\n    <div class=\"bb-form-submit-button-loader-region btn-rounded btn-rounded-brand-primary btn-block disabled hide\">\n    </div>\n\n    <!--forgot link-->\n    <button id=\"bb-login-form-forgot-button\"\n        class=\"m-t-10 m-b-5 fnt-14 lnk-gray-darker text-italic pull-right d-block\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"forgotPassword") : stack1), depth0))
    + "</button>\n</form>";
},"useData":true});