import BaseView from "scripts/views/baseView";
import MainOverlayMenuView from "scripts/views/mainOverlayMenuView";
import SearchOverlayMenuView from "scripts/views/searchOverlayMenuView";
import L from "lodash/fp";
import inject from "scripts/ioc/inject";

import templateMastheadOverlay from "templates/mastheadOverlay.hbs";

class MastheadOverlayView extends BaseView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.mainOverlayMenuView = this.addSubView(new MainOverlayMenuView());
    this.searchOverlayMenuView = this.addSubView(new SearchOverlayMenuView());

    this.mainOverlayMenuView.on("close", () => this.trigger("close"), this);
    this.searchOverlayMenuView.on("close", () => this.trigger("close"), this);

    this.tabControls = [];
    this.currentTab = null;
  }

  get id() {
    return "bb-main-masthead-overlay-scroll-wrapper";
  }

  get events() {
    return {
      "click #bb-masthead-overlay-menu-close-btn": "onClickCloseButton",
      keydown: "onKeyDown",
    };
  }

  get template() {
    return templateMastheadOverlay;
  }

  sync() {
    return this.mainOverlayMenuView.sync();
  }

  render() {
    const { closeMainMenu, overlayMainMenu } = L.get(
      ["ariaLabel"],
      this.i18nextService.getAttributes(),
    );
    this.$el.html(this.template({ i18n: { closeMainMenu, overlayMainMenu } }));

    this.mainOverlayMenuView
      .appendTo($("#bb-masthead-overlay-main-menu-region"))
      .render();

    this.searchOverlayMenuView
      .appendTo($("#bb-masthead-overlay-search-menu-region"))
      .render();

    this.hide();

    return this;
  }

  isVisible() {
    return this.visible;
  }

  showMainMenu() {
    $("#bb-masthead-overlay-search-menu-region").addClass("hide");

    const $mainMenu = $("#bb-masthead-overlay-main-menu-region");
    $mainMenu.removeClass("hide");

    $("#bb-masthead-overlay-menu-close-container").removeClass("hide");

    this.tabControls = $.merge(
      $("#bb-masthead-overlay-menu-close-btn"),
      $mainMenu.find("button:not([disabled]), a, input:not([disabled])"),
    );

    // console.log(this.tabControls);

    this.show();

    setTimeout(() => {
      $("#bb-masthead-overlay-menu-close-btn").focus();
      this.currentTab = 0;
    }, 200);
  }

  showSearchMenu(searchTerm) {
    $("#bb-masthead-overlay-main-menu-region").addClass("hide");

    const $searchMenu = $("#bb-masthead-overlay-search-menu-region");
    $searchMenu.removeClass("hide");

    $("#bb-masthead-overlay-menu-close-container").removeClass("hide");

    this.tabControls = $.merge(
      $("#bb-masthead-overlay-menu-close-btn"),
      $searchMenu.find("button:not([disabled]), a, input:not([disabled])"),
    );

    this.show();

    if (searchTerm) {
      this.searchOverlayMenuView.setSearchTerm(searchTerm);
    } else {
      this.searchOverlayMenuView.clear();
    }

    // the visibility / opacity transition requires a little delay
    setTimeout(() => {
      this.searchOverlayMenuView.focus();
      this.currentTab = 1; // hard coding for the search box
    }, 200);
  }

  show() {
    $("#bb-main-masthead-overlay").scrollTop(0);

    $("body").css({
      overflow: "hidden",
    });

    $("#bb-main-masthead-overlay").css({
      visibility: "visible",
      opacity: "1",
    });
  }

  hide() {
    $("body").css({
      overflow: "auto",
    });

    $("#bb-main-masthead-overlay").css({
      visibility: "hidden",
      opacity: "0",
    });

    $("#bb-masthead-overlay-menu-close-container").addClass("hide");
  }

  close() {
    $("body").css({
      overflow: "auto",
    });

    super.close();
  }

  onClickCloseButton() {
    this.trigger("close");
  }

  onKeyDown(e) {
    if (this.tabControls.length <= 1 || e.key !== "Tab") {
      return;
    }

    e.preventDefault();

    const reverse = e.shiftKey;
    if (
      this.currentTab === null ||
      (!reverse && this.currentTab === this.tabControls.length - 1)
    ) {
      this.currentTab = 0;
    } else if (reverse && this.currentTab === 0) {
      this.currentTab = this.tabControls.length - 1;
    } else {
      const offset = reverse ? -1 : 1;
      this.currentTab += offset;
    }

    this.tabControls[this.currentTab].focus();
  }
}

export default MastheadOverlayView;
