import Promise from "bluebird";

export default function() {
  return new Promise((resolve, reject) => {
    window.onloadRecaptchaCallback = () => {
      resolve(window.grecaptcha);
    };

    $.getScript(
      "https://www.google.com/recaptcha/api.js?onload=onloadRecaptchaCallback&render=explicit",
    ).fail(reject);
  });
}
