import BaseApplicationConfiguration from "scripts/ioc/baseApplicationConfiguration";

import UnavailableService from "scripts/services/unavailableService";
import WebConnectionService from "scripts/services/webConnectionService";
import WebDeviceService from "scripts/services/webDeviceService";
import AppSchemeLoginController from "scripts/controllers/appSchemeLoginController";
import AppUpdateController from "scripts/controllers/appUpdateController";
import RefreshSessionController from "scripts/controllers/refreshSessionController";

class WebApplicationConfiguration extends BaseApplicationConfiguration {
  configureServices() {
    this.registerSingleton("deviceService", WebDeviceService);
    this.registerSingleton("connectionService", WebConnectionService);
    this.registerSingleton("appUpdateController", AppUpdateController);
    this.registerSingleton(
      "refreshSessionController",
      RefreshSessionController,
    );

    // Unavailable services
    this.registerSingleton("fileSystemService", UnavailableService);
    this.registerSingleton("downloadService", UnavailableService);
    this.registerSingleton("bookshelfService", UnavailableService);

    super.configureServices();
  }

  configureControllers() {
    super.configureControllers();
    this.registerSingleton(
      "appSchemeLoginController",
      AppSchemeLoginController,
    );
  }
}

export default new WebApplicationConfiguration();
