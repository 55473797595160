import _ from "lodash";
import Backbone from "backbone";
import Radio from "backbone.radio";

/**
 * Not sure why this is necessary. It's almost as if there
 * are two different versions of Backbone being imported
 * and Backbone.Radio is missing on the first one.
 */
Backbone.Radio = Radio;

Backbone.Radio.off = (commandName, callback, context) => {
  _.invokeMap(Backbone.Radio._channels, "off", commandName, callback, context);
};

Backbone.Radio.stopListening = (other, event, callback) => {
  _.invokeMap(
    Backbone.Radio._channels,
    "stopListening",
    other,
    event,
    callback,
  );
};

Backbone.Radio.stopReplying = (commandName, callback, context) => {
  _.invokeMap(
    Backbone.Radio._channels,
    "stopReplying",
    commandName,
    callback,
    context,
  );
};

Backbone.Radio.stopComplying = (commandName, callback, context) => {
  _.invokeMap(
    Backbone.Radio._channels,
    "stopComplying",
    commandName,
    callback,
    context,
  );
};
