import Backbone from "backbone";

class ImageViewerModel extends Backbone.Model {
  get defaults() {
    return {
      presentationType: "single",
      zoomLevel: 3,
      maxRelatedImages: 50,
      showControls: true,
    };
  }
}

export default ImageViewerModel;
