import Backbone from "backbone";
import BaseView from "scripts/views/baseView";
import ContentDetailsPageAlsoFoundInSubPageView from "scripts/views/contentDetailsPageAlsoFoundInSubPageView";
import ContentDetailsPageCommentsSubPageView from "scripts/views/contentDetailsPageCommentsSubPageView";
import ContentDetailsPageNotesSubPageView from "scripts/views/contentDetailsPageNotesSubPageView";
import ContentDetailsChaptersSubPageView from "scripts/views/contentDetailsChaptersSubPageView";
import ContentModel from "scripts/models/contentModel";
import CitationsView from "./citationsView";

import ContentDetailsPageView from "scripts/views/contentDetailsPageView";
import ContentDetailsSubPageView from "scripts/views/contentDetailsSubPageView";

const chan = Backbone.Radio.channel;

class ContentDetailsControllerView extends BaseView {
  constructor(options) {
    super(options);
    this.queryParams = options.queryParams;
    this.subPageType = options.subPageType;

    this.contentModel = new ContentModel({
      id: options.mediaId,
    });

    chan("controllerView").reply(
      "contentDetailsControllerView",
      mediaId => {
        if (this.contentModel.id === mediaId) {
          return this;
        }
      },
      this,
    );
  }

  sync() {
    return this.contentModel
      .fetch()
      .then(() => {
        if (this.contentDetailsPageView) {
          this.contentDetailsPageView.close();
        }

        this.contentDetailsPageView = this.addSubView(this.createContentDetailsPageView());
      })
      .then(() => this.contentDetailsPageView.fetch());
  }

  render() {
    this.contentDetailsPageView.appendTo(this.$el).render();

    return this;
  }

  getBreadcrumbInfo() {
    return this.contentDetailsPageView.getBreadcrumbInfo();
  }

  showContentDetailsSubPageType(subPageType) {
    this.subPageType = subPageType;

    this.contentDetailsPageView.model.set("subPageType", subPageType);

    return this.contentDetailsPageView.showSubPageView(this.createContentDetailsSubPageView());
  }

  createContentDetailsPageView() {
    const subPageView = this.createContentDetailsSubPageView();

    const pageModel = new Backbone.Model({
      mediaId: this.contentModel.get("id"),
      subPageType: this.subPageType,
      queryParams: this.queryParams,
    });

    return new ContentDetailsPageView({
      model: pageModel,
      mediaModel: this.contentModel,
      subPageView: subPageView,
    });
  }

  createContentDetailsSubPageView() {
    const mediaId = this.contentModel.get("id");

    if (this.subPageType === "details") {
      return new ContentDetailsSubPageView({
        model: this.contentModel,
      });
    } else if (this.subPageType === "notes") {
      return new ContentDetailsPageNotesSubPageView({
        // Share the model????
        model: new ContentModel({
          id: mediaId,
        }),
      });
    } else if (this.subPageType === "comments") {
      return new ContentDetailsPageCommentsSubPageView({
        model: new Backbone.Model({
          mediaId: mediaId,
        }),
      });
    } else if (this.subPageType === "also-found-in") {
      return new ContentDetailsPageAlsoFoundInSubPageView({
        model: new Backbone.Model({
          mediaId: mediaId,
        }),
      });
    } else if (this.subPageType === "chapters") {
      return new ContentDetailsChaptersSubPageView({
        model: new Backbone.Model({
          mediaId: mediaId,
        }),
      });
    } else if (this.subPageType === "citations") {
      return new CitationsView({
        model: new Backbone.Model({
          mediaId: mediaId,
        }),
      });
    }
  }

  getDocumentTitle() {
    return this.contentDetailsPageView.getDocumentTitle();
  }
}

export default ContentDetailsControllerView;
