import Promise from "bluebird";
import Controller from "scripts/controllers/controller";
// import OrganizationChooserSplashScreen from "components/authentication/OrganizationChooserSplashScreen";
// import {hmr} from 'scripts/utils/viewHelpers';

// const hot = hmr(module, require);

class WhichOrganizationController extends Controller {
  // constructor() {
  //   super();
  //
  //   hot('components/authentication/OrganizationChooserSplashScreen', m => {
  //       this.showScreenView(new m())
  //   });
  // }

  get route() {
    return "route:whichOrganization";
  }

  replyRoute() {
    return this.showScreenViewPromise(
      Promise.resolve(
        import(/* webpackChunkName: "organizationChooserSplashScreen" */ "components/authentication/OrganizationChooserSplashScreen"),
      ).then(
        ({ default: OrganizationChooserSplashScreen }) =>
          new OrganizationChooserSplashScreen(),
      ),
    );
  }
}

export default WhichOrganizationController;
