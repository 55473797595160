import BaseView from "scripts/views/baseView";
import MediaListView from "scripts/views/mediaListView";
import templateCurationsPageListSubPage from "templates/curationsPageListSubPage.hbs";
import CurationsPageNavView from "./curationsPageNavView";

class CurationsPageListSubPageView extends BaseView {
  get template() {
    return templateCurationsPageListSubPage;
  }

  constructor(options) {
    super(options);

    this.model.set("collection", this.collection);
    this.navView = this.addSubView(
        new CurationsPageNavView({
          model: this.model,
        }),
    );

    this.mediaListView = this.addSubView(
      new MediaListView({
        collection: this.collection,
      }),
    );
  }

  render() {
    this.$el.html(
      this.template({
        curationCount: this.collection.length,
      }),
    );

    this.navView
        .attachTo(this.$el.find("#bb-curations-page-sticky-nav-region"))
        .render();

    this.mediaListView
      .appendTo($("#bb-curations-page-list-sub-page-list-view-region"))
      .render();

    return this;
  }

  sync() {
    return this.mediaListView.fetch();
  }
}

export default CurationsPageListSubPageView;
