import Backbone from "backbone";
import BaseView from "scripts/views/baseView";

const chan = Backbone.Radio.channel;

class BaseModalView extends BaseView {
  get className() {
    return "modal-content";
  }

  closeModal() {
    // appView has a reference to this modal that needs to be cleared.
    this.trigger("close");
    return chan("display").request("closeModal");
  }
}

export default BaseModalView;
