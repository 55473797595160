import BaseView from "scripts/views/baseView";
import templateCitation from "templates/citation.hbs";

class CitationView extends BaseView {
  get template() {
    return templateCitation;
  }

  render() {
    this.$el.html(this.template(this.model.attributes));

    return this;
  }
}

export default CitationView;
