import BaseModalView from "scripts/views/baseModalView";
import ChangePasswordFormView from "scripts/views/changePasswordFormView";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";

import templateChangePasswordModal from "templates/changePasswordModal.hbs";

class ChangePasswordModalView extends BaseModalView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.oldPassword = options && options.oldPassword;

    this.changePasswordFormView = this.addSubView(
      new ChangePasswordFormView({
        oldPassword: this.oldPassword,
        username: options && options.username,
        token: options && options.token,
      }),
    );

    this.changePasswordFormView.on(
      "success",
      this.onChangePasswordSuccess,
      this,
    );
    this.changePasswordFormView.on(
      "failure",
      this.onChangePasswordFailure,
      this,
    );
  }

  get template() {
    return templateChangePasswordModal;
  }

  render() {
    const changePassword = L.get(
      ["actions", "changePassword"],
      this.i18nextService.getCommon(),
    );
    const close = L.get(
      ["ariaLabel", "close"],
      this.i18nextService.getAttributes(),
    );
    this.$el.html(
      this.template({
        i18n: { changePassword, close },
        oldPassword: this.oldPassword,
      }),
    );

    this.changePasswordFormView
      .appendTo(this.$el.find("#bb-change-password-form-region"))
      .render();

    return this;
  }

  onChangePasswordSuccess() {
    this.trigger("success", this);
    this.closeModal();
  }

  onChangePasswordFailure(error) {
    this.trigger("failure", error);
  }
}

export default ChangePasswordModalView;
