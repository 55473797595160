var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row-meta p-tb-20\">\n    <label class=\"content-details-header\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"alsoFoundIn") : stack1), depth0))
    + "</label>\n    <div id=\"bb-content-details-also-found-in-media-region\" class=\"p-t-20\"></div>\n</div>\n\n<svg class=\"content-details-divider\" xmlns=\"http://www.w3.org/2000/svg\" width=\"100%\" height=\"2\" fill=\"none\">\n    <line x1=\"100%\" y1=\"0%\" x2=\"0%\" y2=\"100%\" stroke=\"#CDCDCD\"/>\n</svg>";
},"useData":true});