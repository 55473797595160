var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"organizationLogo") : stack1), depth0))
    + "\" style=\"background-image: url('"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"organization") : depth0)) != null ? lookupProperty(stack1,"brandingLogoUrl") : stack1), depth0))
    + "')\"></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"organizationNameContainer") : stack1), depth0))
    + "\">\n      <span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"organizationName") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"organization") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias3((lookupProperty(helpers,"ternary")||(depth0 && lookupProperty(depth0,"ternary"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isActive") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"activeCard") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"card") : stack1),{"name":"ternary","hash":{},"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":62}}}))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"organization") : depth0)) != null ? lookupProperty(stack1,"brandingLogoUrl") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":8,"column":9}}})) != null ? stack1 : "")
    + "\n  <button class=\""
    + alias3((lookupProperty(helpers,"ternary")||(depth0 && lookupProperty(depth0,"ternary"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isActive") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"switchLinkDisabled") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"switchLink") : stack1),{"name":"ternary","hash":{},"data":data,"loc":{"start":{"line":10,"column":17},"end":{"line":10,"column":81}}}))
    + "\" "
    + alias3(((helper = (helper = lookupProperty(helpers,"disabled") || (depth0 != null ? lookupProperty(depth0,"disabled") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"disabled","hash":{},"data":data,"loc":{"start":{"line":10,"column":83},"end":{"line":10,"column":95}}}) : helper)))
    + ">"
    + alias3(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"switchButton") : stack1), depth0))
    + " <i\n      class=\"fa fa-exchange\"></i></button>\n</div>";
},"useData":true});