import L from "lodash/fp";
import inject from "scripts/ioc/inject";
import querystring from "querystring";
import Controller from "scripts/controllers/controller";
import { replaceAndLoadFragment } from "scripts/utils/routerHelpers";
import {
  parseOAuthParamsFromUrl,
  parseRedirectFragmentFromUrl,
} from "scripts/utils/urlUtil";

class AssociateOrganizationController extends Controller {
  constructor(securityService = inject("securityService")) {
    super();

    this.securityService = securityService;
  }

  get route() {
    return "route:associateOrganization";
  }

  replyRoute(route, organizationId, platform) {
    return this.securityService
      .fetchOrganizationUser()
      .then(organizationUser =>
        this.securityService.associateUserWithOrganizations(
          organizationUser.getToken(),
          [organizationId],
        ),
      )
      .then(() => {
        const user = this.securityService.getUser();
        user.setActiveOrganizationId(organizationId);
        return this.securityService.setUser(user);
      })
      .then(() => {
        const user = this.securityService.getUser();
        const redirectFragment = parseRedirectFragmentFromUrl(
          window.location.href,
        );

        if (redirectFragment) {
          if (platform === "hybridapp") {
            const params = {
              redirect_fragment: redirectFragment,
            };

            setTimeout(() => {
              window.location.href = `biblioboardlibrary://login/${user.getToken()}/?${querystring.stringify(
                params,
              )}`;
            }, 0);

            return replaceAndLoadFragment("/about");
          } else {
            return replaceAndLoadFragment(redirectFragment);
          }
        } else {
          const oAuthParams = parseOAuthParamsFromUrl(window.location.href);

          if (platform === "hybridapp") {
            setTimeout(() => {
              window.location.href = `biblioboardlibrary://manage-organizations/${user.getToken()}`;
            }, 0);

            return replaceAndLoadFragment("/about");
          } else if (L.isEmpty(oAuthParams)) {
            return replaceAndLoadFragment("/manage-organizations");
          } else {
            return replaceAndLoadFragment(
              `/manage-organizations?${querystring.stringify(oAuthParams)}`,
            );
          }
        }
      });
  }
}

export default AssociateOrganizationController;
