import Backbone from "backbone";
import Controller from "scripts/controllers/controller";
import inject from "scripts/ioc/inject";

const chan = Backbone.Radio.channel;

class BugsnagController extends Controller {
  get releaseStage() {
    return process.env.PATRON_UI_BUGSNAG_RELEASE_STAGE;
  }

  constructor(
    deviceService = inject("deviceService"),
    securityService = inject("securityService"),
  ) {
    super();

    this.deviceService = deviceService;
    this.securityService = securityService;

    chan("bugsnag").reply("notify", this.complyNotify, this);
  }

  complyNotify(error) {
    const metadata = {
      user: this.userMetadata(),
      requestInfo: this.requestInfoMetadata(error),
      device: this.deviceMetadata(),
    };

    if (this.releaseStage) {
      console.log(
        `Bugsnag Controller: notify, error: "${error.name}", message: "${error.message}",
            metadata: %O`,
        metadata,
      );

      Bugsnag.notify(error.name, error.message, metadata, "error");
    } else {
      console.log(
        `Bugsnag Controller: no release stage, skipping notify, error: "${error.name}",
            message: "${error.message}", metadata: %O`,
        metadata,
      );
    }
  }

  userMetadata() {
    const user = this.securityService.getUser();
    const metadata = {};

    if (user.hasProfile()) {
      metadata.userId = user.getUserId();
      metadata.username = user.getUsername();
    }

    metadata.organizationId = user.get("organizationId");
    metadata.organizationName = user.getActiveOrganizationName();

    return metadata;
  }

  requestInfoMetadata(error) {
    const metadata = {};
    const model = error.model;

    if (model) {
      metadata.url = typeof model.url === "function" ? model.url() : model.url;
      metadata.data = JSON.stringify(model.toJSON());
    }

    const xhr = error.xhr;

    if (xhr) {
      metadata.xhr = JSON.stringify(xhr);
    }

    return metadata;
  }

  deviceMetadata() {
    return {
      platformType: this.deviceService.platform,
    };
  }
}

export default BugsnagController;
