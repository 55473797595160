import BaseView from "scripts/views/baseView";
import MediaPlaylistRowView from "scripts/views/mediaPlaylistRowView";
import templateMediaPlaylist from "templates/mediaPlaylist.hbs";

class MediaPlaylistView extends BaseView {
  get template() {
    return templateMediaPlaylist;
  }

  render() {
    this.$el.html(this.template());

    this.collection.map(this.addPlaylistRow.bind(this));

    return this;
  }

  addPlaylistRow(contentModel, index) {
    contentModel.set("playlistIndex", index + 1);

    const playlistRowView = this.addSubView(
      new MediaPlaylistRowView({
        model: contentModel,
      }),
    );

    playlistRowView
      .appendTo($("#bb-media-playlist-table-body-region"))
      .render();

    this.bubble("playlistTrackClicked", playlistRowView);
  }
}

export default MediaPlaylistView;
