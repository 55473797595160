import _ from "lodash";
import L from "lodash/fp";
import uuid from "uuid";
import inject from "scripts/ioc/inject";
import { alert } from "scripts/alerts/alerts";
import BaseModalView from "scripts/views/baseModalView";
import MediaPlaylistView from "scripts/views/mediaPlaylistView";
import HybridMediaCollection from "scripts/collections/hybridMediaCollection";
import MediaModel from "scripts/models/mediaModel";
import ContentFileCollection from "scripts/collections/contentFileCollection";
import AudioListenEventModel from "scripts/models/audioListenEventModel";

import templateAudioModal from "templates/audioModal.hbs";
import CompoundChildrenCollection from "../collections/compoundChildrenCollection";

const chan = Backbone.Radio.channel;

class AudioModalView extends BaseModalView {
  constructor(
    options,
    relatedContentService = inject("relatedContentService"),
    currentLocationService = inject("currentLocationService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);

    this.relatedContentService = relatedContentService;
    this.currentLocationService = currentLocationService;
    this.i18nextService = i18nextService;

    this.model = options.contentModel;
    this.indexToPlay = options.indexToPlay;
    this.compoundChildrenCollection = options.compoundChildrenCollection;

    this.readingSessionId = uuid.v1();

    chan("connectionService").on(
      "status:change",
      connectionStatus => {
        if (!connectionStatus.isOnline) {
          this.closeModal();
        }
      },
      this,
    );

    this.speedIconMap = {
      0.5: "bbico-speed-half",
      1: "bbico-speed-1",
      1.25: "bbico-speed-one-quarter",
      1.5: "bbico-speed-one-half",
    };
  }

  get events() {
    return _.extend({}, super.events, {
      "click #bb-audio-modal-previous-track-btn": "onClickPreviousTrack",
      "click #bb-audio-modal-skip-back-btn": "onClickSkipBack",
      "click #bb-audio-modal-toggle-play-btn": "onClickTogglePlay",
      "click #bb-audio-modal-skip-forward-btn": "onClickSkipForward",
      "click #bb-audio-modal-next-track-btn": "onClickNextTrack",
      "click #bb-audio-modal-change-playback-speed-btn": "onClickChangePlaybackSpeed",
      "click #bb-audio-quality-option": "onClickAudioQuality",
    });
  }

  get template() {
    return templateAudioModal;
  }

  get defaultPlayerOptions() {
    return {
      skin: "five",
      fallback: false,
      muted: false,
      width: "100%",
      height: "40",
      visualplaylist: false,
      base: "https://ssl.p.jwpcdn.com/player/v/7.12.13/",
    };
  }

  render() {
    const playlistTitleLabel =
      this.model.get("type") === "AUDIOBOOK" ? "Chapters" : this.model.get("type") === "ALBUM" ? "Tracks" : "Titles";

    const templateData = _.merge(this.model.toJSON(), {
      playlistTitleLabel,
      hasMultipleFormats: this.model.get("audioFormats").length > 1,
      i18n: this.getTranslations(this.model.toJSON()),
    });

    this.$el.html(this.template(templateData));

    this.setupPlayer();

    chan("display").trigger("updateModal");
    chan("display").trigger("audioModalShown");

    return this;
  }

  getTranslations(model) {
    const { alt, ariaLabel } = this.i18nextService.getAttributes();

    return { ariaLabel, thumbnailAlt: alt.thumbnailForName(model.name) };
  }

  async setupPlayer() {
    const playerOptions = _.clone(this.defaultPlayerOptions);
    const playlist = await this.generatePlaylist();

    if (playlist.length) {
      playerOptions.playlist = playlist;
      this.initPlaylist();
    } else {
      $("#bb-audio-modal-previous-track-btn").hide();
      $("#bb-audio-modal-next-track-btn").hide();

      playerOptions.sources = this.jwPlayerSources(this.model.get("audioFormats"));
    }

    const savedLocation = await this.fetchSavedLocation();

    // the player has set the mute flag prior - should be cleared to avoid
    // user confustion
    this.resetJWPMute();

    jwplayer("bb-audio-modal-player")
      .setup(playerOptions)
      .on("playlistItem", this.onPlaylistItemChange.bind(this))
      .on("firstFrame", this.onFirstFrame.bind(this))
      .on("ready", this.onPlayerReady.bind(this, playlist, savedLocation))
      .on("time", _.throttle(this.onLocationChange.bind(this), 5000))
      // the following events just need to swap the play/pause button
      .on("play", this.updatePlayButton.bind(this, true))
      .on("complete", this.updatePlayButton.bind(this, false))
      .on("pause", this.updatePlayButton.bind(this, false))
      .on("mute", this.addPlayerFocus.bind(this))
      .on("volume", this.addPlayerFocus.bind(this))
      // .on("seek", this.addPlayerFocus.bind(this))
      .on("playAttemptFailed", this.onPlayAttemptFailed.bind(this))
      .on("setupError", this.onPlayerSetupError.bind(this))
      .on("autostartNotAllowed", this.onAutostartNotAllowed.bind(this))
      .on("error", this.onError.bind(this));

    console.log("JWPlayer initialized");
  }

  initPlaylist() {
    this.playlistView = this.addSubView(
      new MediaPlaylistView({
        collection: this.relatedAudioCollection,
      }),
    );

    this.playlistView.htmlOf($("#bb-audio-modal-player-playlist-region")).render();

    this.playlistView.on("playlistTrackClicked", this.onClickPlaylistTrack, this);
  }

  setAudioQualityButtonHTML(text) {
    return `<svg id="waveform" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M320 0c12 0 22.1 8.8 23.8 20.7l42 304.4L424.3 84.2c1.9-11.7 12-20.3 23.9-20.2s21.9 8.9 23.6 20.6l28.2 197.3 20.5-102.6c2.2-10.8 11.3-18.7 22.3-19.3s20.9 6.4 24.2 16.9L593.7 264H616c13.3 0 24 10.7 24 24s-10.7 24-24 24H576c-10.5 0-19.8-6.9-22.9-16.9l-4.1-13.4-29.4 147c-2.3 11.5-12.5 19.6-24.2 19.3s-21.4-9-23.1-20.6L446.7 248.3l-39 243.5c-1.9 11.7-12.1 20.3-24 20.2s-21.9-8.9-23.5-20.7L320 199.6 279.8 491.3c-1.6 11.8-11.6 20.6-23.5 20.7s-22.1-8.5-24-20.2l-39-243.5L167.8 427.4c-1.7 11.6-11.4 20.3-23.1 20.6s-21.9-7.8-24.2-19.3l-29.4-147-4.1 13.4C83.8 305.1 74.5 312 64 312H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H46.3l26.8-87.1c3.2-10.5 13.2-17.5 24.2-16.9s20.2 8.5 22.3 19.3l20.5 102.6L168.2 84.6c1.7-11.7 11.7-20.5 23.6-20.6s22 8.5 23.9 20.2l38.5 240.9 42-304.4C297.9 8.8 308 0 320 0z"/></svg> ${text} <span><i class="fa fa-angle-down"></i>`;
  }

  // Swap between Standard Quality (64k) and High Quality (192k) bit rates
  onClickAudioQuality() {
    const qualities = jwplayer().getQualityLevels();
    const currentQualityIndex = jwplayer().getCurrentQuality();

    const isCurrentlyStandard = qualities[currentQualityIndex].bitrate === "64k";

    $("#bb-audio-quality-button").html(this.setAudioQualityButtonHTML(isCurrentlyStandard ? "High" : "Standard"));
    $("#bb-audio-quality-option").text(isCurrentlyStandard ? "Standard" : "High");

    const indexToPlay = _.findIndex(qualities, {
      bitrate: isCurrentlyStandard ? "192k" : "64k",
    });

    jwplayer().setCurrentQuality(indexToPlay);
  }

  onClickPlaylistTrack(contentModel) {
    const indexToPlay = _.findIndex(jwplayer().getPlaylist(), {
      mediaid: contentModel.get("id"),
    });
    const currentIndex = jwplayer().getPlaylistIndex();

    if (indexToPlay !== currentIndex) {
      jwplayer().playlistItem(indexToPlay);
    }
  }

  /**
   * Triggered when playback begins.
   */
  onFirstFrame() {
    const currentPlaylistIndex = jwplayer().getPlaylistIndex();
    const playlistItem = jwplayer().getPlaylist()[currentPlaylistIndex];

    // an audio book should only send a single event on load, not on each chapter change
    if (!this.isAudioBook()) {
      const mediaId = playlistItem.mediaid || this.model.get("id");
      this.sendCounterEvent(mediaId);
    }
  }

  onPlaylistItemChange(playlistItem) {
    this.updateTitle(playlistItem.item.title, playlistItem.item.subtitle, playlistItem.item.trackTitle);
    this.initQualityBtn();

    chan("playlist").trigger("playlistItemChange", playlistItem.item.mediaid);
  }

  onPlayerReady(playlist, savedLocation) {
    if (playlist.length) {
      let indexToPlay = 0;
      let seekPosition = 0;
      if (this.isAudioBook()) {
        // should only send 1 counter even for book
        this.sendCounterEvent(this.model.get("id"));

        // look up saved location
        if (savedLocation) {
          indexToPlay = _.clamp(savedLocation.index, 0, playlist.length - 1);
          seekPosition = savedLocation.position ? savedLocation.position : 0;
        }
      } else if (this.isAlbum()) {
        indexToPlay = this.indexToPlay || indexToPlay;
      } else {
        indexToPlay = _.findIndex(playlist, {
          mediaid: this.model.get("id"),
        });
      }

      const currentIndex = jwplayer().getPlaylistIndex();

      if (indexToPlay !== currentIndex) {
        jwplayer().playlistItem(indexToPlay);
      }

      if (seekPosition > 0) {
        jwplayer().seek(seekPosition);
      }
    }

    $("#bb-audio-player").focus();
    this.addSliderTabIndex();
  }

  onPlayAttemptFailed(error) {
    console.log({ error });
    alert("Playback Error", `${error.error}`, "error").show();
    this.updatePlayButton(false);
  }

  onPlayerSetupError(errorMessage) {
    console.log({ errorMessage });
    alert("Playback Error", `${errorMessage}`, "error").show();
    this.updatePlayButton(false);
  }

  onAutostartNotAllowed(error) {
    console.log({ error });
    alert("Autostart Not Allowed", `${error.reason}`, "error").show();
    this.updatePlayButton(false);
  }

  onError(error) {
    console.log({ error });
    alert("Playback Error", `${error.code}:${error.message}`, "error").show();
    this.updatePlayButton(false);
  }

  //TODO: make focus events common
  addSliderTabIndex() {
    const volumeSlider = $("#bb-audio-modal-player .jw-slider-volume .jw-knob");
    volumeSlider.attr("tabIndex", 0);
  }

  addPlayerFocus(e) {
    const controls = {
      mute: ".jw-icon-inline.jw-icon-volume",
      volume: ".jw-slider-volume .jw-knob",
      seek: ".jw-slider-time .jw-knob",
    };
    const control = $(`#bb-audio-modal-player ${controls[e.type]}`);
    control.trigger("focus");
  }

  /**
   * JW Player version 7 in the audio player mode (height set to 40 or
   * below) does not show the option to toggle audio quality by default.
   *
   * [Tyler]: hide for all by default; added dropdown to toggle qualities instead
   */
  initQualityBtn() {
    // const currentPlaylistIndex = jwplayer().getPlaylistIndex();
    // const qualityCount = jwplayer().getPlaylist()[currentPlaylistIndex].sources.length;
    const $qualityBtn = $("#bb-audio-modal-player .jw-icon-hd");

    $qualityBtn.css("display", "none");

    // if (qualityCount > 1) {
    //   $qualityBtn.css("display", "inline-block");
    // } else {
    //   $qualityBtn.css("display", "none");
    // }
  }

  /*
   * Generate JW Player sources object from API audio formats.
   */
  jwPlayerSources(formats) {
    const bitRateOrder = ["64k", "192k"];

    const getBitRateOrderValue = bitRate => {
      const index = bitRateOrder.indexOf(bitRate);
      return index === -1 ? bitRateOrder.length : index;
    };

    return L.pipe(
      L.map(format => ({
        file: format.url,
        label: format.bitRate === "64k" ? "Standard" : "High",
        default: format.bitRate === "64k" ? false : true,
        bitrate: format.bitRate,
      })),
      sources => sources.sort((a, b) => getBitRateOrderValue(a.label) - getBitRateOrderValue(b.label)),
    )(formats);
  }

  isPlayerInitialized() {
    return jwplayer().id === "bb-audio-modal-player";
  }

  async generatePlaylist() {
    if (this.isAudioBook()) {
      const chapters = await this.fetchAudioBookChapters();
      // expects a backbone collection...
      this.relatedAudioCollection = new Backbone.Collection(chapters);
    } else if (this.isAlbum()) {
      this.relatedAudioCollection = await this.fetchAlbumTracks();
    } else {
      this.relatedAudioCollection = await this.fetchRelated();
    }

    return [].concat(this.relatedAudioCollection.map(this.getPlaylistItem.bind(this)));
  }

  getPlaylistItem(audioModel) {
    const title = audioModel.get("title");
    const subtitle = audioModel.get("subtitle");
    const trackTitle = audioModel.get("trackTitle");

    return {
      mediaid: audioModel.get("id"),
      sources: this.jwPlayerSources(audioModel.get("audioFormats")),
      title,
      subtitle,
      trackTitle,
    };
  }

  fetchRelated() {
    return this.relatedContentService.fetchSiblingsForContentType(
      new HybridMediaCollection(),
      this.model,
      new MediaModel(),
    );
  }

  fetchAudioBookChapters() {
    const contentFileCollection = new ContentFileCollection([], {
      mediaId: this.model.get("mediaId"),
    });

    const audioBookFormats = this.model.get("audioFormats");
    return contentFileCollection.fetch().then(chapters => {
      return L.pipe(
        L.filter(ch => ch.get("mimeType") === "audio/mpeg"),
        L.sortBy(ch => ch.get("sequence")),
        L.map(ch => {
          // probably should have api do this...
          const audioFormats = audioBookFormats.map(f => {
            const format = _.clone(f);
            format.url = format.url.replace(/content_/, `content/${ch.get("fileName")}_`);
            return format;
          });

          //-- If content file has a title use it
          const chTitle = ch.get("title");
          if (!chTitle) {
            ch.set("title", this.model.get("title"));
            ch.set("subtitle", this.model.get("subtitle"));
          }

          ch.set("trackTitle", chTitle ? chTitle : `Chapter: ${ch.get("sequence")}`);
          ch.set("audioFormats", audioFormats);
          return ch;
        }),
      )(chapters.models);
    });
  }

  async fetchAlbumTracks() {
    const tracks = this.compoundChildrenCollection
      ? this.compoundChildrenCollection
      : new CompoundChildrenCollection([], {
          mediaId: this.model.get("id"),
        });

    return tracks;
  }

  updateTitle(title, subtitle, trackTitle) {
    $("#bb-audio-modal .bb-audio-modal-title").text(title);
    $("#bb-audio-modal .bb-audio-modal-sub-title").text(subtitle);
    $("#bb-audio-modal .bb-audio-modal-track-title").text(trackTitle);
  }

  updatePlayButton(isPlaying) {
    const $playButton = $("#bb-audio-modal #bb-audio-modal-toggle-play-btn i");
    if (isPlaying) {
      $playButton.removeClass("bbico-play");
      $playButton.addClass("bbico-pause");
    } else {
      $playButton.removeClass("bbico-pause");
      $playButton.addClass("bbico-play");
    }
  }

  sendCounterEvent(contentMediaId) {
    console.log(`Sending counter even for ${contentMediaId}`);

    chan("tracking").trigger("content:open", {
      mediaId: contentMediaId,
      contentType: this.model.get("type"),
    });
  }

  sendAudioListenEvent(contentMediaId, chapterMediaId, position) {
    new AudioListenEventModel({
      contentMediaId,
      chapterMediaId,
      position,
    }).save();
  }

  fetchSavedLocation() {
    return this.currentLocationService
      .fetch({
        mediaId: this.model.get("id"),
      })
      .then(offset => JSON.parse(offset))
      .catch(() => null);
  }

  onLocationChange(location) {
    if (this.isPlayerInitialized() && location && this.isAudioBook()) {
      const currentIndex = jwplayer().getPlaylistIndex();
      const currentItem = this.relatedAudioCollection.at(currentIndex);

      const offset = {
        position: location.position,
        id: currentItem.get("id"),
        sequence: currentItem.get("sequence"),
        index: currentIndex,
      };

      this.currentLocationService.save({
        offset: JSON.stringify(offset),
        mediaId: this.model.get("mediaId"),
        readingSessionId: this.readingSessionId,
      });

      this.sendAudioListenEvent(this.model.get("mediaId"), currentItem.get("id"), location.position);
    }
  }

  onClickPreviousTrack(e) {
    e.preventDefault();
    const currentIndex = jwplayer().getPlaylistIndex();
    if (currentIndex > 0) {
      jwplayer().playlistItem(currentIndex - 1);
    } else {
      jwplayer().seek(0);
    }
  }

  onClickSkipBack(e) {
    e.preventDefault();
    this.seek(-30);
  }

  onClickTogglePlay(e) {
    e.preventDefault();
    if (jwplayer().getState() === "playing") {
      jwplayer().pause();
    } else {
      jwplayer().play();
    }
  }

  onClickSkipForward(e) {
    e.preventDefault();
    this.seek(30);
  }

  onClickNextTrack(e) {
    e.preventDefault();
    jwplayer().next();
  }

  resetJWPMute() {
    try {
      const isPlayerMute = localStorage.getItem("jwplayer.mute");
      if (isPlayerMute) {
        localStorage.setItem("jwplayer.mute", false);
      }
    } catch (error) {
      console.log("localStorage error: %O", error);
    }
  }

  onClickChangePlaybackSpeed(e) {
    e.preventDefault();
    const currentRate = jwplayer().getPlaybackRate();
    const newRate =
      currentRate >= 1.5 ? 0.5 : currentRate >= 1 && currentRate <= 1.25 ? currentRate + 0.25 : currentRate + 0.5;
    jwplayer().setPlaybackRate(newRate);
    console.log(`Playback rate set: ${newRate}`);

    $("#bb-audio-modal-change-playback-speed-btn i")
      .removeClass()
      .addClass("bbico " + this.speedIconMap[newRate]);
  }

  seek(timeDiff) {
    const position = _.clamp(jwplayer().getPosition() + timeDiff, 0, jwplayer().getDuration());
    jwplayer().seek(position);
  }

  isAudioBook() {
    return this.model.get("type") === "AUDIOBOOK";
  }

  isAlbum() {
    return this.model.get("type") === "ALBUM";
  }

  removePlayer() {
    if (this.isPlayerInitialized()) {
      jwplayer().remove();
      console.log("JWPlayer removed");
    }
  }

  close() {
    console.log("Closing Modal");
    this.removePlayer();
    super.close();
  }
}

export default AudioModalView;
