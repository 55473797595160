var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"rating") : stack1)) != null ? lookupProperty(stack1,"previous") : stack1), depth0))
    + " <span class=\"fnt-bld\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"usersCurrentVote") || (depth0 != null ? lookupProperty(depth0,"usersCurrentVote") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"usersCurrentVote","hash":{},"data":data,"loc":{"start":{"line":7,"column":58},"end":{"line":7,"column":78}}}) : helper)))
    + "</span> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"rating") : stack1)) != null ? lookupProperty(stack1,"outOf") : stack1), depth0))
    + " <span\n                class=\"fnt-bld\">5</span> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"rating") : stack1)) != null ? lookupProperty(stack1,"stars") : stack1), depth0))
    + ".</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-body text-center\">\n    <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"close") : stack1), depth0))
    + "\"><span\n            aria-hidden=\"true\">&times;</span></button>\n    <h2>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"rating") : stack1)) != null ? lookupProperty(stack1,"rateIt") : stack1), depth0))
    + "</h2>\n    <p>"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"howManyStars") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"usersCurrentVote") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class=\"list-group\">\n        <!-- five star-->\n        <button aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"stars") : stack1)) != null ? lookupProperty(stack1,"five") : stack1), depth0))
    + "\" class=\"list-group-item bb-star-rating\" data-star-rating=\"5\">\n            <div class=\"d-block fnt-16 clr-gray-light\">\n                <i class=\"fa fa-star clr-warning\"></i>\n                <i class=\"fa fa-star clr-warning\"></i>\n                <i class=\"fa fa-star clr-warning\"></i>\n                <i class=\"fa fa-star clr-warning\"></i>\n                <i class=\"fa fa-star clr-warning\"></i>\n            </div>\n            <label class=\"pointer\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"rating") : stack1)) != null ? lookupProperty(stack1,"loved") : stack1), depth0))
    + "</label>\n        </button>\n        <!-- four star-->\n        <button aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"stars") : stack1)) != null ? lookupProperty(stack1,"four") : stack1), depth0))
    + "\" class=\"list-group-item bb-star-rating\" data-star-rating=\"4\">\n            <div class=\"d-block fnt-16 clr-gray-light\">\n                <i class=\"fa fa-star clr-warning\"></i>\n                <i class=\"fa fa-star clr-warning\"></i>\n                <i class=\"fa fa-star clr-warning\"></i>\n                <i class=\"fa fa-star clr-warning\"></i>\n                <i class=\"fa fa-star\"></i>\n            </div>\n            <label class=\"pointer\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"rating") : stack1)) != null ? lookupProperty(stack1,"liked") : stack1), depth0))
    + "</label>\n        </button>\n        <!-- three star-->\n        <button aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"stars") : stack1)) != null ? lookupProperty(stack1,"three") : stack1), depth0))
    + "\" class=\"list-group-item bb-star-rating\" data-star-rating=\"3\">\n            <div class=\"d-block fnt-16 clr-gray-light\">\n                <i class=\"fa fa-star clr-warning\"></i>\n                <i class=\"fa fa-star clr-warning\"></i>\n                <i class=\"fa fa-star clr-warning\"></i>\n                <i class=\"fa fa-star\"></i>\n                <i class=\"fa fa-star\"></i>\n            </div>\n            <label class=\"pointer\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"rating") : stack1)) != null ? lookupProperty(stack1,"ok") : stack1), depth0))
    + "</label>\n        </button>\n        <!-- two star-->\n        <button aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"stars") : stack1)) != null ? lookupProperty(stack1,"two") : stack1), depth0))
    + "\" class=\"list-group-item bb-star-rating\" data-star-rating=\"2\">\n            <div class=\"d-block fnt-16 clr-gray-light\">\n                <i class=\"fa fa-star clr-warning\"></i>\n                <i class=\"fa fa-star clr-warning\"></i>\n                <i class=\"fa fa-star\"></i>\n                <i class=\"fa fa-star\"></i>\n                <i class=\"fa fa-star\"></i>\n            </div>\n            <label class=\"pointer\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"rating") : stack1)) != null ? lookupProperty(stack1,"notFan") : stack1), depth0))
    + "</label>\n        </button>\n        <!-- one star-->\n        <button aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"stars") : stack1)) != null ? lookupProperty(stack1,"one") : stack1), depth0))
    + "\" class=\"list-group-item bb-star-rating\" data-star-rating=\"1\">\n            <div class=\"d-block fnt-16 clr-gray-light\">\n                <i class=\"fa fa-star clr-warning\"></i>\n                <i class=\"fa fa-star\"></i>\n                <i class=\"fa fa-star\"></i>\n                <i class=\"fa fa-star\"></i>\n                <i class=\"fa fa-star\"></i>\n            </div>\n            <label class=\"pointer\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"rating") : stack1)) != null ? lookupProperty(stack1,"disappointed") : stack1), depth0))
    + "</label>\n        </button>\n    </div>\n</div>";
},"useData":true});