import _ from "lodash";
import L from "lodash/fp";
import Promise from "bluebird";
import inject from "scripts/ioc/inject";
import NoteCollection from "scripts/collections/noteCollection";
import EpubNoteCollection from "scripts/collections/epubNoteCollection";
import NoteModel from "scripts/models/noteModel";
import BaseView from "scripts/views/baseView";
import ContentDetailsPageAllNotesNoteTileView from "scripts/views/contentDetailsPageAllNotesNoteTileView";

import templateContentDetailsPageAllNotesSubPage from "templates/contentDetailsPageAllNotesSubPage.hbs";

class ContentDetailsPageAllNotesSubPageView extends BaseView {
  constructor(
    options,
    securityService = inject("securityService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);

    this.securityService = securityService;
    this.i18nextService = i18nextService;
    this.contentModel = options.contentModel;
  }

  get template() {
    return templateContentDetailsPageAllNotesSubPage;
  }

  get events() {
    return {
      "change #bb-content-details-page-all-notes-sub-page-sort": "onChangeSort",
      "click .filter": "onClickFilter",
      "click .bb-content-details-page-all-notes-sub-page-add-note":
        "onClickCreateNote",
    };
  }

  render() {
    const { content, sortBy } = this.i18nextService.getGlossary();
    const { filter } = content;
    const { note } = content.details;
    this.$el.html(this.template({ i18n: { filter, note, sortBy } }));

    this.initAddNoteButton();
    this.initFilterButtons();
    this.initSortOptions();

    this.sortNotes();
    this.applyFilter();

    return this;
  }

  /**
   * Show 'Add First Note' button when there are no notes. Otherwise, show 'Add Note' button.
   */
  initAddNoteButton() {
    const $addNoteButton = this.collection.length
      ? this.$el.find("#bb-content-details-page-all-notes-sub-page-add-note")
      : this.$el.find(
          "#bb-content-details-page-all-notes-sub-page-add-first-note",
        );

    $addNoteButton.removeClass("hide").siblings().addClass("hide");
  }

  /**
   * Image, Audio, and Video content types have no concept of page-level notes.
   * Book, Article, Document content types get global note filter named by type.
   */
  initFilterButtons() {
    const contentType = this.contentModel.get("type");

    const content = L.get(["content"], this.i18nextService.getGlossary());

    if (_.includes(["IMAGE", "AUDIO", "VIDEO"], contentType)) {
      this.hideFilterButtons();
    } else {
      const globalFilterButton = this.$el.find(".filter-global");

      if (
        contentType === "BOOK" ||
        contentType === "ARTICLE" ||
        contentType === "CHAPTER"
      ) {
        globalFilterButton.text(content[_.lowerCase(contentType)].singular);
      } else if (contentType === "OTHER_DOCUMENT") {
        globalFilterButton.text(content.document.singular);
      }
    }
  }

  /**
   * Image, Audio, and Video content types have no concept of page-level notes.
   */
  initSortOptions() {
    if (
      _.includes(["IMAGE", "AUDIO", "VIDEO"], this.contentModel.get("type"))
    ) {
      this.hidePageLevelSortOptions();
    }
  }

  renderNotes(noteModels) {
    noteModels.forEach(this.addNote.bind(this));
  }

  sync() {
    return this.contentModel.fetch().then(this.syncNoteCollections.bind(this));
  }

  syncNoteCollections(contentModel) {
    const contentNoteCollection =
      this.createContentNoteCollection(contentModel);
    const internalNoteCollection =
      this.createInternalNoteCollection(contentModel);

    return Promise.all([
      contentNoteCollection.fetch(),
      internalNoteCollection.fetch(),
    ]).then(() => {
      this.collection = contentNoteCollection.clone();
      this.collection.add(internalNoteCollection.toJSON());
    });
  }

  // Content-level notes
  createContentNoteCollection(contentModel) {
    const user = this.securityService.getUser();
    const userId = user.getUserId();
    const mediaId = contentModel.get("id");

    if (contentModel.get("mimeType") === "application/epub+zip") {
      return new EpubNoteCollection([], {
        path: `/users/${userId}/media/${mediaId}/notes`,
      });
    } else {
      return new NoteCollection([], {
        path: `/users/${userId}/media/${mediaId}/notes`,
      });
    }
  }

  // Internal (page-level) notes
  createInternalNoteCollection(contentModel) {
    const user = this.securityService.getUser();
    const userId = user.getUserId();
    const mediaId = contentModel.get("id");

    if (contentModel.get("mimeType") === "application/epub+zip") {
      return new EpubNoteCollection([], {
        path: `/users/${userId}/media/${mediaId}/notes/internal`,
      });
    } else {
      return new NoteCollection([], {
        path: `/users/${userId}/media/${mediaId}/notes/internal`,
      });
    }
  }

  addNote(noteModel) {
    const noteTileView = this.addSubView(
      new ContentDetailsPageAllNotesNoteTileView({
        model: noteModel,
      }),
    );

    this.bubble("editNote", noteTileView);

    noteTileView.$el.appendTo(
      "#bb-content-details-page-all-notes-sub-page-notes-region",
    );

    noteTileView.render();
  }

  onChangeSort(event) {
    const sortBy = event.target.value;
    this.sortNotes(sortBy);

    const $filter = this.$el.find(".filter.active");
    this.applyFilter(this.filterElementToFilter($filter));
  }

  sortNotes(sortBy) {
    sortBy = sortBy || this.model.get("sortBy");

    this.$el
      .find("#bb-content-details-page-all-notes-sub-page-sort")
      .val(sortBy);

    if (sortBy === "ADDED_ASC") {
      this.collection.sortByAdded("asc");
    } else if (sortBy === "ADDED_DESC") {
      this.collection.sortByAdded("desc");
    } else if (sortBy === "PAGE_ASC") {
      this.collection.sortByPage("asc");
    } else if (sortBy === "PAGE_DESC") {
      this.collection.sortByPage("desc");
    }

    this.model.set("sortBy", sortBy);
  }

  onClickFilter(event) {
    const $filter = $(event.target);

    this.applyFilter(this.filterElementToFilter($filter));

    return false;
  }

  /**
   * Given the filter element, return the filter.
   */
  filterElementToFilter($filter) {
    let filter = "ALL";

    if ($filter.hasClass("filter-page")) {
      filter = "PAGE";
    } else if ($filter.hasClass("filter-global")) {
      filter = "GLOBAL";
    }

    return filter;
  }

  /**
   * Given the filter, return the filter element.
   */
  filterElement(filter) {
    let $filter = this.$el.find(".filter-all");

    if (filter === "PAGE") {
      $filter = this.$el.find(".filter-page");
    } else if (filter === "GLOBAL") {
      $filter = this.$el.find(".filter-global");
    }

    return $filter;
  }

  makeFilterElementActive(filter) {
    const $filter = this.filterElement(filter);

    $filter.addClass("active").siblings().removeClass("active");
  }

  applyFilter(filter) {
    filter = filter || this.model.get("filter");

    this.makeFilterElementActive(filter);

    this.model.set("filter", filter);

    this.closeNotes();

    if (filter === "PAGE") {
      this.renderNotes(this.collection.pageNotes());
    } else if (filter === "GLOBAL") {
      this.renderNotes(this.collection.globalNotes());
    } else {
      this.renderNotes(this.collection.models);
    }
  }

  onClickCreateNote() {
    this.trigger(
      "createNote",
      new NoteModel({
        mediaId: this.contentModel.get("id"),
      }),
    );

    return false;
  }

  hideFilterButtons() {
    this.$el.find(".filter").addClass("hide");
  }

  hidePageLevelSortOptions() {
    $("#bb-content-details-page-all-notes-sub-page-sort")
      .find('option[value^="PAGE_"]')
      .addClass("hide");
  }

  closeNotes() {
    _.each(this.subViews, subView => {
      this.closeSubView(subView);
    });
  }
}

export default ContentDetailsPageAllNotesSubPageView;
