import Promise from "bluebird";
import Backbone from "backbone";
import Controller from "scripts/controllers/controller";
import UserPreferenceModel from "scripts/models/userPreferenceModel";
import inject from "scripts/ioc/inject";

const chan = Backbone.Radio.channel;

class UserPreferencesController extends Controller {
  constructor(securityService = inject("securityService")) {
    super();

    this.securityService = securityService;

    chan("preferences").reply("set", this.set, this);
  }

  set(preference, value) {
    let prefObj = {};

    if (typeof preference === "string" && value) {
      prefObj[preference] = value;
    } else if (typeof preference === "object") {
      prefObj = preference;
    }

    const user = this.securityService.getUser();
    user.setPreference(prefObj);

    return Promise.all([
      this.securityService.setUser(user),
      this.savePreferenceModel(prefObj),
    ]);
  }

  savePreferenceModel(preferences) {
    const user = this.securityService.getUser();

    if (user.hasProfile()) {
      console.log(
        "User preferences controller: persisting user preferences to server: %O",
        preferences,
      );
      return new UserPreferenceModel(preferences).save(void 0, { patch: true });
    } else {
      return Promise.resolve(preferences);
    }
  }
}

export default UserPreferencesController;
