var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"bb-login-page\">\n    <h2>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"startExploring") : stack1), depth0))
    + "</h2>\n\n    <div id=\"bb-login-page-login-region\" class=\"m-b-40\"></div>\n\n    <label for=\"bb-login-page-support-link\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"needHelp") : stack1), depth0))
    + "</label>\n\n    <a id=\"bb-login-page-support-link\" href=\"http://support.biblioboard.com\" target=\"_blank\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"support") : stack1), depth0))
    + "</a>\n\n    <div class=\"m-t-40 fnt-11\">BiblioBoard v."
    + alias2(((helper = (helper = lookupProperty(helpers,"version") || (depth0 != null ? lookupProperty(depth0,"version") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"version","hash":{},"data":data,"loc":{"start":{"line":10,"column":45},"end":{"line":10,"column":56}}}) : helper)))
    + "</div>\n    <a a href=\"/diagnostics\" class=\"m-t-40 fnt-11\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"diagnostics") : stack1), depth0))
    + "</a>\n</div>\n";
},"useData":true});