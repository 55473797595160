import BaseView from "scripts/views/baseView";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";
import templateFacetedSearchQueryPill from "templates/search/facetedSearchQueryPill.hbs";

class FacetedSearchQueryPillView extends BaseView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.param = options.param;
    this.label = options.label;
    this.value = options.value;
    this.code = options.code;
    this.hasValues = options.hasValues;
    this.totalRecords = options.totalRecords;
  }

  get template() {
    return templateFacetedSearchQueryPill;
  }

  get className() {
    return "faceted-search-query-pill closable";
  }

  get events() {
    return {
      "click .faceted-search-query-pill-close-icon": "onClickClose",
    };
  }

  render() {
    const { removeValue } = L.get(
      ["ariaLabel"],
      this.i18nextService.getAttributes(),
    );

    this.$el.html(
      this.template({
        i18n: {
          removeValue: this.hasValues
            ? removeValue(this.value)
            : removeValue(""),
        },
        value: this.value,
        label: this.label,
        hasValues: this.hasValues,
        totalRecords: this.totalRecords,
      }),
    );
  }

  onClickClose() {
    this.trigger("close", { facetParam: this.param, termCode: this.code });
  }

  setClosable(closable) {
    if (closable) {
      this.$el.addClass("closable");
    } else {
      this.$el.removeClass("closable");
    }
  }
}

export default FacetedSearchQueryPillView;
