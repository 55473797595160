var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--sign in form-->\n<div id=\"bb-login-form-region\" class=\"m-t-20\"></div>\n\n<h4 class=\"text-notransform m-t-80 m-b-5 text-center\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"dontHaveProfile") : stack1), depth0))
    + "</h4>\n\n<button id=\"bb-login-create-profile-link\" class=\"btn-outline-rounded btn-outline-gray p-lr-30 m-t-10\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"getStartedNow") : stack1), depth0))
    + "</button>";
},"useData":true});