import _ from "lodash";

class BaseSyncError extends Error {
  constructor(properties) {
    super();

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error().stack;
    }

    _.extend(this, properties || {});

    if (this.xhr && this.xhr.status) {
      this.message = "Status: " + this.xhr.status;
    } else {
      this.message = "Unknown status";
    }
  }
}

export default BaseSyncError;
