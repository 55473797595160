import inject from "scripts/ioc/inject";
import BaseApiCollection from "scripts/collections/baseApiCollection";
import HybridCollectionHoc from "scripts/hoc/hybridCollectionHoc";
import MediaCollectionHoc from "scripts/hoc/mediaCollectionHoc";

class HybridMediaCollection extends HybridCollectionHoc(
  MediaCollectionHoc(BaseApiCollection),
) {
  constructor(models, options, bookshelfService = inject("bookshelfService")) {
    super(models, options);

    this.bookshelfService = bookshelfService;
  }
}

export default HybridMediaCollection;
