import BaseListTileView from "scripts/views/baseListTileView";
import templateAnthologyListTile from "templates/anthologyListTile.hbs";

class AnthologyListTileView extends BaseListTileView {
  get template() {
    return templateAnthologyListTile;
  }
}

export default AnthologyListTileView;
