import BaseSecondaryStickyView from "scripts/views/baseSecondaryStickyNavView";
import templateCurationsPageNav from "templates/curationsPageNav.hbs";

class CurationsPageNavView extends BaseSecondaryStickyView {
  get template() {
    return templateCurationsPageNav;
  }

  shouldStick() {
    return false;
  }
}

export default CurationsPageNavView;
