import Backbone from "backbone";
import AuthenticationSecureSyncHoc from "scripts/hoc/authenticationSecureSyncHoc";
import BaseModel from "scripts/models/baseModel";
import Util from "scripts/utils/util";

const chan = Backbone.Radio.channel;

class ChangePasswordAuthenticationModel extends AuthenticationSecureSyncHoc(
  BaseModel,
) {
  constructor(attributes, options) {
    super(attributes, options);

    this.username = options && options.username;
    this.token = options && options.token;
  }

  get constraints() {
    return {
      oldPassword: {
        presence: true,
        length: {
          minimum: 8,
        },
      },

      newPassword: {
        presence: true,
        length: {
          minimum: 8,
        },
        format: {
          pattern: Util.passwordValidationRegex,
          message: "must contain at least one number",
        },
      },

      confirmPassword: {
        presence: true,
        equality: "newPassword",
      },
    };
  }

  url() {
    const username = this.username
      ? this.username
      : chan("security")
          .request("user")
          .getUsername();

    return `${this.authenticationUrlRoot}/users/${username}/password`;
  }

  sync(method, model, options) {
    if (method === "create" || method === "update") {
      method = "update";
    }

    return super.sync(method, model, options);
  }
}

export default ChangePasswordAuthenticationModel;
