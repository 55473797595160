var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <div class=\"bb-curation-media-thumbnail-disabled hide\"></div>\n    <div class=\"curation-thumbnail-background\">\n        <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"thumbnailUrl") || (depth0 != null ? lookupProperty(depth0,"thumbnailUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnailUrl","hash":{},"data":data,"loc":{"start":{"line":4,"column":18},"end":{"line":4,"column":34}}}) : helper)))
    + "\" data-fallback-src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"thumbnailFallbackUrl") || (depth0 != null ? lookupProperty(depth0,"thumbnailFallbackUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnailFallbackUrl","hash":{},"data":data,"loc":{"start":{"line":4,"column":55},"end":{"line":4,"column":79}}}) : helper)))
    + "\" class=\"img-responsive\" alt=\"Thumbnail\">\n    </div>\n\n    <div class=\"bb-curation-media-thumbnail-checkbox hide\">\n        <i class=\"bbico bbico-check\"></i>\n    </div>\n</div>";
},"useData":true});