import _ from "lodash";
import Promise from "bluebird";
import Backbone from "backbone";
import { alert } from "scripts/alerts/alerts";
import errorAlert from "scripts/alerts/errorAlert";
import inject from "scripts/ioc/inject";
import Controller from "scripts/controllers/controller";
import FavoriteModel from "scripts/models/favoriteModel";
import BookshelfLogModel from "scripts/models/bookshelfLogModel";
import RecentModel from "scripts/models/recentModel";
import BookmarksSubPageView from "scripts/views/bookmarksSubPageView";
import FavoritesSubPageView from "scripts/views/favoritesSubPageView";
import MyBoardPageView from "scripts/views/myBoardPageView";
import RecentsSubPageView from "scripts/views/recentsSubPageView";
import BookshelfSubPageView from "scripts/views/bookshelfSubPageView";
import SignInModalView from "scripts/views/signInModalView";
import { redirectToFragment } from "scripts/utils/routerHelpers";

import saIconDownload from "images/sa-icon-download.png";
import saIconOnline from "images/sa-icon-online.png";

const chan = Backbone.Radio.channel;

class MyBoardController extends Controller {
  constructor(
    connectionService = inject("connectionService"),
    bookshelfService = inject("bookshelfService"),
    securityService = inject("securityService"),
  ) {
    super();

    this.connectionService = connectionService;
    this.bookshelfService = bookshelfService;
    this.securityService = securityService;

    chan("tracking").on("anthology:open", this.saveRecent, this);
    chan("tracking").on("curation:open", this.saveRecent, this);
    chan("tracking").on("content:open", this.saveRecent, this);

    chan("myBoard").reply("favorite", this.replyFavoriteMedia, this);
    chan("myBoard").reply("unfavorite", this.replyUnfavoriteMedia, this);

    chan("myBoard").reply("bookshelfAdd", this.replyBookshelfAddMedia, this);
    chan("myBoard").reply(
      "bookshelfRemove",
      this.replyBookshelfRemoveMedia,
      this,
    );
    chan("myBoard").reply(
      "bookshelfCancelDownload",
      this.replyBookshelfCancelDownload,
      this,
    );

    chan("connectionService").on(
      "status:change",
      this.onConnectionStatusChange,
      this,
    );

    //Temporary
    chan("myBoard").reply("logBookshelfAdd", this.logBookshelfAdd, this);
  }

  get route() {
    return "route:favorites route:bookmarks route:recents route:bookshelf route:myBoard";
  }

  get defaults() {
    return {
      subPageType: "favorites",
      presentationType: "masonry",
    };
  }

  routeAttrs(route, presentationType) {
    const user = this.securityService.getUser();

    if (route === "myBoard") {
      route = this.defaults.subPageType;
    }

    return _.merge(
      {},
      this.defaults,
      {
        presentationType: user.getContentViewPreference(),
      },
      {
        subPageType: route || void 0,
        presentationType: presentationType || void 0,
      },
    );
  }

  replyRoute(route, presentationType) {
    const attrs = this.routeAttrs(route, presentationType);

    const subPageView = this.createSubPageView(attrs);

    let myBoardPageView = chan("controllerView").request("myBoardPageView");

    if (myBoardPageView) {
      myBoardPageView.model.set(attrs);

      return myBoardPageView.showSubPageView(subPageView);
    } else {
      myBoardPageView = new MyBoardPageView({
        model: new Backbone.Model(attrs),
        subPageView: subPageView,
      });

      return this.showMainBodyView(myBoardPageView);
    }
  }

  createSubPageView(attrs) {
    let subPage;

    const pageModel = new Backbone.Model({
      presentationType: attrs.presentationType,
    });

    if (attrs.subPageType === "bookmarks") {
      subPage = new BookmarksSubPageView({
        model: pageModel,
      });
    } else if (attrs.subPageType === "recents") {
      subPage = new RecentsSubPageView({
        model: pageModel,
      });
    } else if (attrs.subPageType === "bookshelf") {
      if (this.bookshelfService.isAvailable()) {
        subPage = new BookshelfSubPageView({
          model: pageModel,
        });
      } else {
        throw new Error(
          "The offline bookshelf is not available in this environment",
        );
      }
    } else {
      subPage = new FavoritesSubPageView({
        model: pageModel,
      });
    }

    return subPage;
  }

  replyFavoriteMedia(mediaModel) {
    console.log("Favorite request received for media model: %O", mediaModel);

    const user = this.securityService.getUser();

    if (user.isOrganizationGuest()) {
      chan("display").request("showModal", new SignInModalView());
      return Promise.reject(new Promise.CancellationError());
    } else {
      return new FavoriteModel({
        id: mediaModel.get("id"),
      }).save({}, { type: "post" });
    }
  }

  replyUnfavoriteMedia(mediaModel, confirmationRequired = false) {
    console.log("Unfavorite request received for media model: %O", mediaModel);

    const user = this.securityService.getUser();

    if (user.isOrganizationGuest()) {
      chan("display").request("showModal", new SignInModalView());
      return Promise.reject(new Promise.CancellationError());
    } else if (confirmationRequired) {
      return this.showUnfavoriteConfirmationModal(mediaModel).then(
        this.doUnfavorite,
      );
    } else {
      return this.doUnfavorite(mediaModel);
    }
  }

  showUnfavoriteConfirmationModal(mediaModel) {
    console.log(
      "Showing unfavorite confirmation modal for media model: %O",
      mediaModel,
    );

    const title = mediaModel.get("title") || mediaModel.get("name");

    return alert({
      title: "Favorites",
      text: `Remove "${title}" from favorites?`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    })
      .show()
      .then(() => mediaModel);
  }

  doUnfavorite(mediaModel) {
    return new FavoriteModel({
      id: mediaModel.get("id"),
    })
      .destroy()
      .then(() => {
        chan("myBoard").trigger("mediaUnfavorited", mediaModel);
      });
  }

  replyBookshelfAddMedia(mediaModel) {
    console.log(
      "Bookshelf add request received for media model: %O",
      mediaModel,
    );

    const user = this.securityService.getUser();

    if (user.isOrganizationGuest()) {
      chan("display").request("showModal", new SignInModalView());
      return Promise.reject(new Promise.CancellationError());
    } else {
      return this.showBookshelfAddConfirmationModal(mediaModel).then(() => {
        this.logBookshelfAdd(mediaModel);
        this.doAddToBookshelf(mediaModel);
      });
    }
  }

  async replyBookshelfRemoveMedia(mediaModel, showConfirmationDialog = true) {
    console.log(
      "Bookshelf remove request received for media model: %O",
      mediaModel,
    );
    const user = this.securityService.getUser();

    if (user.isOrganizationGuest()) {
      chan("display").request("showModal", new SignInModalView());
      throw new Promise.CancellationError();
    }

    if (showConfirmationDialog) {
      await this.showBookshelfRemoveConfirmationModal(mediaModel);
    }

    this.logBookshelfRemove(mediaModel);
    this.doRemoveFromBookshelf(mediaModel);
  }

  replyBookshelfCancelDownload(mediaModel) {
    console.log(
      "Bookshelf cancel download request received for media model: %O",
      mediaModel,
    );

    return this.showBookshelfCancelDownloadConfirmationModal(mediaModel).then(
      () => {
        this.logBookshelfRemove(mediaModel);
        this.doRemoveFromBookshelf(mediaModel);
      },
    );
  }

  showBookshelfAddConfirmationModal(mediaModel) {
    console.log(
      "Showing bookshelf add confirmation modal for media model: %O",
      mediaModel,
    );

    return alert({
      title: "Offline Bookshelf",
      text: `Add "${mediaModel.get("title")}" to your offline bookshelf?`,
      showCancelButton: true,
      confirmButtonText: "Add",
      imageUrl: saIconDownload,
    })
      .show()
      .then(() => mediaModel);
  }

  showBookshelfRemoveConfirmationModal(mediaModel) {
    console.log(
      "Showing bookshelf remove confirmation modal for media model: %O",
      mediaModel,
    );

    return alert({
      title: "Offline Bookshelf",
      text: `Remove "${mediaModel.get("title")}" from your offline bookshelf?`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Remove",
    }).show();
  }

  showBookshelfCancelDownloadConfirmationModal(mediaModel) {
    console.log(
      "Showing bookshelf cancel download confirmation modal for media model: %O",
      mediaModel,
    );

    return alert({
      title: "Cancel Download?",
      text: `Stop downloading "${mediaModel.get(
        "title",
      )}" to your offline bookshelf?`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    })
      .show()
      .then(() => mediaModel);
  }

  logBookshelfAdd(mediaModel) {
    if (this.connectionService.getConnectionStatus().isOnline) {
      console.log("logging bookshelf add event");

      return new BookshelfLogModel({
        id: mediaModel.get("id"),
      })
        .save({}, { type: "post" })
        .catch(e => {
          console.log("Error logging bookshelf add event: %O", e);
        });
    }

    return Promise.resolve();
  }

  logBookshelfRemove(mediaModel) {
    if (this.connectionService.getConnectionStatus().isOnline) {
      console.log("logging bookshelf remove event");

      return new BookshelfLogModel({
        id: mediaModel.get("id"),
      })
        .save()
        .catch(e => {
          console.log("Error logging bookshelf remove event: %O", e);
        });
    }

    return Promise.resolve();
  }

  doAddToBookshelf(mediaModel) {
    const organizationId = this.securityService
      .getUser()
      .getActiveOrganizationId();

    if (this.connectionService.getConnectionStatus().isOnline) {
      this.bookshelfService
        .downloadContent(mediaModel, organizationId)
        //.then(attrs => {
        //    const filesFailed = parseInt(attrs.filesFailed, 10);
        //
        //    if (filesFailed) {
        //        console.log(`There were: ${filesFailed} failed files on bookshelf download of: ${mediaModel.get('id')}, trying again...`);
        //
        //        return this.bookshelfService.downloadContent(mediaModel, 'data');
        //    }
        //})
        .catch(error => {
          console.error("Error adding content to bookshelf: %O", error);
          errorAlert(error).show();
        });
    } else {
      this.bookshelfService
        .moveCacheToData(mediaModel.get("id"), organizationId)
        .catch(error => {
          console.error("Error adding saving cache: %O", error);
          errorAlert(error).show();
        });
    }
  }

  doRemoveFromBookshelf(mediaModel) {
    const organizationId = this.securityService
      .getUser()
      .getActiveOrganizationId();

    return this.bookshelfService
      .removeContent(mediaModel.get("id"), organizationId)
      .then(() => {
        chan("myBoard").trigger("mediaRemovedFromBookshelf", mediaModel);
      })
      .catch(error => {
        console.error("Error removing content from bookshelf: %O", error);
        errorAlert(error).show();
      });
  }

  saveRecent(attrs) {
    const user = this.securityService.getUser();

    // Only save recent for non guest users
    if (!user.isOrganizationGuest()) {
      console.log(
        "My board controller: saving recent view of media id: %s",
        attrs.mediaId,
      );

      new RecentModel({
        mediaId: attrs.mediaId,
      }).save();
    }
  }

  onConnectionStatusChange(connectionStatus) {
    if (connectionStatus.isOnline) {
      this.showOnlineConfirmationModal().catch(error => {
        if (!(error instanceof Promise.CancellationError)) {
          errorAlert(error).show();
        }
      });
    }
  }

  showOnlineConfirmationModal() {
    console.log("Showing online modal");

    return alert({
      title: "We're back online!",
      text:
        "Woot! Connectivity restored. Go forth and enjoy all BiblioBoard has to offer!",
      confirmButtonText: "OK",
      imageUrl: saIconOnline,
    })
      .show()
      .then(() => {
        const user = this.securityService.getUser();

        if (user.hasProfile()) {
          chan("display").request("refreshScreen");
        } else {
          window.location.reload();
        }
      });
  }

  navigateToBookshelf() {
    redirectToFragment("/my-board/bookshelf");
  }
}

export default MyBoardController;
