import BaseView from "scripts/views/baseView";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";

import templateCommentThreadMenu from "templates/commentThreadMenu.hbs";

class CommentThreadMenuView extends BaseView {
  get tagName() {
    return "div";
  }

  get className() {
    return "row comments-thread-controls";
  }

  get template() {
    return templateCommentThreadMenu;
  }

  // get events() {
  //     return {
  //         'click .comment-favorite': 'favorite',
  //         'click .comment-subscribe': 'subscribe',
  //     };
  // }

  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.disqusModel = this.model.get("disqusModel");
    this.listenTo(this.model, "change", this.render);
  }

  render() {
    const sortBy = L.get(["button", "sortBy"], this.i18nextService.getCommon());
    this.$el.html(
      this.template(L.merge({ i18n: { sortBy } }, this.model.toJSON())),
    );

    return this;
  }

  //    favorite() {
  //        let context = this;
  //        let options = {
  //            url: 'https://disqus.com/api/3.0/threads/vote.json',
  //            type: 'POST',
  //            data: {
  //                thread: this.model.get('threadId'),
  //                // Remove the favorite if favorited and add it if not
  //                vote: (this.model.get('favorite') ? -1 : 1),
  //                api_key: this.disqusModel.get('disqusKey'),
  //                remote_auth: this.disqusModel.get('disqusAuthentication')
  //            },
  //            success(response) {
  //                context.model.set('favorite', (response.response.vote === 1));
  //            }
  //        };
  //
  //        $.ajax(options);
  //    },

  //    subscribe() {
  //        let context = this;
  //        let options = {
  //            type: 'POST',
  //            data: {
  //                thread: this.model.get('threadId'),
  //                api_key: this.disqusModel.get('disqusKey'),
  //                remote_auth: this.disqusModel.get('disqusAuthentication')
  //            },
  //
  //            success() {
  //                context.model.set('subscribe', !context.model.get('subscribe'));
  //            }
  //        };
  //
  //        if (this.model.get('subscribe')) {
  //            options.url = '/* @echo disqusUrlRoot *//api/3.0/threads/unsubscribe.json';
  //        } else {
  //            options.url = '/* @echo disqusUrlRoot *//api/3.0/threads/subscribe.json';
  //        }
  //
  //        $.ajax(options);
  //    }
}

export default CommentThreadMenuView;
