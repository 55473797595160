import _ from "lodash";
import Backbone from "backbone";
import Controller from "scripts/controllers/controller";
import { getCurrentFragment, goBack, redirectToFragment } from "scripts/utils/routerHelpers";

const chan = Backbone.Radio.channel;

class HistoryController extends Controller {
  get maxFragmentHistory() {
    return 10;
  }

  get maxScrollPositionHistory() {
    return 10;
  }

  isTrackedFragment(fragment) {
    return (
      fragment &&
      !/^login/.test(fragment) &&
      !/^error/.test(fragment) &&
      !/^diagnostics/.test(fragment) &&
      !/^sign-up/.test(fragment) &&
      !/^which-biblioboard/.test(fragment) &&
      !/^welcome/.test(fragment) &&
      !/^which-organization/.test(fragment)
    );
  }

  constructor(...args) {
    super(...args);

    this.fragmentHistory = [];
    this.lastNavigationDirection = "forward";

    this.scrollTopFragmentHistory = [];
    this.scrollTopMap = {};

    chan("history").reply("back", this.back, this);
    chan("history").reply("pushFragment", this.pushFragment, this);
    chan("history").reply("popFragment", this.popFragment, this);
    chan("history").reply("replaceFragment", this.replaceFragment, this);
    chan("history").reply("replaceScrollTop", this.replaceScrollTop, this);
    chan("history").reply("scrollToLastScrollTop", this.scrollToLastScrollTop, this);
    chan("history").reply("clear", this.clear, this);
    chan("history").reply("fragments", this.getFragments, this);
    chan("history").reply("lastFragment", this.getLastFragment, this);
    chan("history").reply("previousFragment", this.getPreviousFragment, this);
    chan("history").reply("length", this.getLength, this);
    chan("history").reply("lastNavigationDirection", this.getLastNavigationDirection, this);
    chan("history").reply("lastScrollTop", this.lastScrollTop, this);
    chan("history").reply("trackScrollTop", this.trackScrollTop, this);

    if (process.env.PATRON_UI_PUSHSTATE === "true") {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "manual";
      } else {
        //Edge actually doesn't need this hack but we're applying it anyway
        $(window).on("popstate", () => {
          $(window).one("scroll", () => {
            console.log("Popstate caused scroll, reset scroll position");
            this.scrollToLastScrollTop();
          });
        });
      }
    }

    this.trackScrollTop(true);
  }

  back() {
    if (this.fragmentHistory.length > 1) {
      goBack();
    } else {
      redirectToFragment("/home");
    }
  }

  pushFragment(fragment) {
    fragment = fragment || getCurrentFragment();

    if (this.isTrackedFragment(fragment)) {
      // console.log(`History: pushing fragment: ${fragment}`);

      if (this.fragmentHistory[this.fragmentHistory.length - 2] === fragment) {
        this.popFragment();

        console.log(
          "Backward navigation assumed during fragment push, popping, last fragment: " +
            this.fragmentHistory[this.fragmentHistory.length - 1],
        );

        this.lastNavigationDirection = "back";
      } else {
        this.fragmentHistory.push(fragment);

        if (this.fragmentHistory.length > this.maxFragmentHistory) {
          this.fragmentHistory = _.tail(this.fragmentHistory);
        }

        this.lastNavigationDirection = "forward";
        chan("history").trigger("update", this.fragmentHistory.length);
      }
    } else {
      console.log(`History: ignoring fragment: ${fragment}`);
    }
  }

  popFragment() {
    this.fragmentHistory.pop();
    chan("history").trigger("update", this.fragmentHistory.length);
  }

  replaceFragment(fragment) {
    if (this.fragmentHistory.length) {
      console.log("Replacing last history fragment with: " + fragment);
      this.fragmentHistory[this.fragmentHistory.length - 1] = fragment;
    } else {
      console.error("No fragment history, cannot replace, fragment: " + fragment);
    }
  }

  updateScrollTop(position) {
    let fragment = getCurrentFragment();

    if (this.scrollTopMap[fragment] !== void 0) {
      this.scrollTopFragmentHistory = _.without(this.scrollTopFragmentHistory, fragment);
    } else if (this.scrollTopFragmentHistory.length >= this.maxScrollPositionHistory) {
      let oldestFragment = _.head(this.scrollTopFragmentHistory);

      delete this.scrollTopMap[oldestFragment];

      this.scrollTopFragmentHistory = _.tail(this.scrollTopFragmentHistory);
    }

    this.scrollTopFragmentHistory.push(fragment);
    this.scrollTopMap[fragment] = position;

    //        console.log('...');
    //        console.log('Tracked scroll top for fragment: %s, position: %s', fragment, position);
    //        console.log('history: %s', this.scrollTopFragmentHistory.toString());
    //        console.log('map: %s', JSON.stringify(this.scrollTopMap));
    //        console.log('...');
  }

  replaceScrollTop(fragment, position) {
    if (this.scrollTopMap[fragment]) {
      console.log("Replacing scroll top for fragment: " + fragment + " position: " + position);
      this.scrollTopMap[fragment] = position;
    }
  }

  lastScrollTop(fragment) {
    fragment = fragment || _.last(this.scrollTopFragmentHistory);
    return this.scrollTopMap[fragment];
  }

  trackScrollTop(track) {
    if (track) {
      chan("display").on("scroll:stop", this.updateScrollTop, this);
    } else {
      chan("display").off("scroll:stop", this.updateScrollTop, this);
    }
  }

  scrollToLastScrollTop() {
    $(window).scrollTop(this.lastScrollTop() || 0);
  }

  clear() {
    this.fragmentHistory = [];
  }

  getFragments() {
    return _.clone(this.fragmentHistory);
  }

  getLastFragment() {
    return this.fragmentHistory.length > 0 ? this.fragmentHistory[this.fragmentHistory.length - 1] : void 0;
  }

  getPreviousFragment() {
    return this.fragmentHistory.length > 1 ? this.fragmentHistory[this.fragmentHistory.length - 2] : void 0;
  }

  getLength() {
    return this.fragmentHistory.length;
  }

  getLastNavigationDirection() {
    return this.lastNavigationDirection;
  }
}

export default HistoryController;
