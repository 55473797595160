import Controller from "scripts/controllers/controller";
import ManageOrganizations from "components/accountManagement/ManageOrganizations";
import SignUpCreateProfilePageView from "scripts/views/signUpCreateProfilePageView";
// import { hmr } from "scripts/utils/viewHelpers";

// const hot = hmr(module, require);

class SignUpController extends Controller {
  // constructor() {
  //   super();
  //   hot("components/accountManagement/ManageOrganizations", m => {
  //     // this.showAuthenticationScreenBodyView(new m.default())
  //     this.showAuthenticationScreenBodyView(new m());
  //   });
  // }

  get route() {
    return "route:signUpCreateProfile route:manageOrganizations";
  }

  // TODO: Note 'type' is no longer used, but mobile apps have links to routes that include type
  // 'platform' is needed to determine if sign up originated from a mobile app
  // so that the user can be re-directed back to the app after sign up
  replyRoute(route, type, platform) {
    if (route === "signUpCreateProfile") {
      return this.showAuthenticationScreenBodyView(
        new SignUpCreateProfilePageView({
          platform,
        }),
      );
    } else if (route === "manageOrganizations") {
      return this.showAuthenticationScreenBodyView(new ManageOrganizations());
    }
  }
}

export default SignUpController;
