import _ from "lodash";
import Backbone from "backbone";
import AuthenticationScreenView from "scripts/views/authenticationScreenView";
import MainView from "scripts/views/mainView";

const chan = Backbone.Radio.channel;

class Controller {
  constructor() {
    if (this.route) {
      chan("route").reply(this.route, this.replyRoute, this);
    }
  }

  replyRoute(route) {
    throw new Error("Reply route not implemented for route " + route);
  }

  showScreenView(screenView) {
    return chan("display").request("showScreenView", screenView);
  }

  showScreenViewPromise(screenViewPromise) {
    return chan("display").request("showScreenViewPromise", screenViewPromise);
  }

  showMainBodyView(bodyView) {
    let mainView = chan("controllerView").request("mainView");

    if (mainView) {
      return mainView.showBodyView(bodyView);
    } else {
      return this.showScreenView(
        new MainView({
          bodyView,
        }),
      );
    }
  }

  showMainBodyViewPromise(bodyViewPromise) {
    let mainView = chan("controllerView").request("mainView");

    if (mainView) {
      return mainView.showBodyViewPromise(bodyViewPromise);
    } else {
      return this.showScreenViewPromise(bodyViewPromise.then(bodyView => new MainView({ bodyView })));
    }
  }

  showAuthenticationScreenBodyView(bodyView) {
    let authenticationScreenView = chan("controllerView").request("authenticationScreenView");

    if (authenticationScreenView) {
      return authenticationScreenView.showBodyView(bodyView);
    } else {
      return this.showScreenView(
        new AuthenticationScreenView({
          bodyView: bodyView,
        }),
      );
    }
  }

  showErrorPage(error) {
    console.log("Controller is showing error page for error: %O", error);

    return chan("display").request("showErrorPage", error);
  }
}

_.extend(Controller.prototype, Backbone.Events);

export default Controller;
