import BaseSecondaryStickyView from "scripts/views/baseSecondaryStickyNavView";
import templateCurationPageNav from "templates/curationPageNav.hbs";

class CurationPageNavView extends BaseSecondaryStickyView {
  get template() {
    return templateCurationPageNav;
  }
}

export default CurationPageNavView;
