var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <span class=\"bb-vertical-separator\"> | </span>\n      <span class=\"bb-list-tile-language-label\">\n        <a href=\"/content/"
    + alias4(((helper = (helper = lookupProperty(helpers,"parentContentId") || (depth0 != null ? lookupProperty(depth0,"parentContentId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"parentContentId","hash":{},"data":data,"loc":{"start":{"line":24,"column":26},"end":{"line":24,"column":45}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"parentContentTitle") || (depth0 != null ? lookupProperty(depth0,"parentContentTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"parentContentTitle","hash":{},"data":data,"loc":{"start":{"line":24,"column":47},"end":{"line":24,"column":69}}}) : helper)))
    + "</a>\n      </span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"notEqual")||(depth0 && lookupProperty(depth0,"notEqual"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"languageIso639_2") : depth0),"zxx",{"name":"notEqual","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":6},"end":{"line":32,"column":19}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"bb-vertical-separator\"> | </span>\n        <span class=\"bb-list-tile-parent-label\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"language") || (depth0 != null ? lookupProperty(depth0,"language") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"language","hash":{},"data":data,"loc":{"start":{"line":31,"column":48},"end":{"line":31,"column":60}}}) : helper)))
    + " "
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"content") : stack1)) != null ? lookupProperty(stack1,"languageContent") : stack1), depth0))
    + "</span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p class=\"bb-list-tile-subtitle\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"subtitle") || (depth0 != null ? lookupProperty(depth0,"subtitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"subtitle","hash":{},"data":data,"loc":{"start":{"line":42,"column":37},"end":{"line":42,"column":49}}}) : helper)))
    + "</p>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"blackMediaBadges") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":4},"end":{"line":53,"column":13}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"content-provider-badge content-provider-badge--tile\">\n        <img class=\"content-provider-badge__img--tile\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" alt=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"contentProvider") : stack1)) != null ? lookupProperty(stack1,"alt") : stack1), depth0))
    + "\">\n      </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span class=\"list-ribbon list-ribbon-highlight\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"content") : stack1)) != null ? lookupProperty(stack1,"highlight") : stack1), depth0))
    + "</span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"list-ribbon list-ribbon-highlight hide\"></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--BAR COL-->\n<div class=\"bb-list-tile-bar-column\">\n  <div class=\"list-header\"></div>\n</div>\n\n<!--IMAGE COL-->\n<div class=\"bb-list-tile-cover-column\">\n  <div class=\"bb-list-tile-cover\">\n    <img class=\"thumbnail-xs\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"thumbnailUrl") || (depth0 != null ? lookupProperty(depth0,"thumbnailUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnailUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":35},"end":{"line":9,"column":51}}}) : helper)))
    + "\" data-fallback-src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"thumbnailFallbackUrl") || (depth0 != null ? lookupProperty(depth0,"thumbnailFallbackUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnailFallbackUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":72},"end":{"line":9,"column":96}}}) : helper)))
    + "\"\n         alt=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"thumbnail") : stack1)) != null ? lookupProperty(stack1,"alt") : stack1), depth0))
    + "\">\n  </div>\n</div>\n\n<!--COPY COL-->\n<div class=\"bb-list-tile-details-column\">\n\n  <div class=\"bb-list-tile-cover bb-list-tile-type-label\" aria-hidden=\"true\">\n    <i class=\"bbico bbico-book\"></i>\n    <span class=\"clr-written text-uppercase\">"
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"content") : stack1)) != null ? lookupProperty(stack1,"chapter") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</span>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"parentContentTitle") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":26,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"notEqual")||(depth0 && lookupProperty(depth0,"notEqual"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"languageIso639_2") : depth0),"eng",{"name":"notEqual","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":4},"end":{"line":33,"column":17}}})) != null ? stack1 : "")
    + "\n  </div>\n\n  <a class=\"btn-detail bb-list-tile-title-link\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"detailUrl") || (depth0 != null ? lookupProperty(depth0,"detailUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"detailUrl","hash":{},"data":data,"loc":{"start":{"line":37,"column":54},"end":{"line":37,"column":67}}}) : helper)))
    + "\" aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"content") : stack1)) != null ? lookupProperty(stack1,"viewBookDetails") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\">\n    <h2 class=\"h3 bb-list-tile-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":38,"column":38},"end":{"line":38,"column":47}}}) : helper)))
    + "</h2>\n  </a>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"subtitle") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":2},"end":{"line":43,"column":9}}})) != null ? stack1 : "")
    + "\n  <div class=\"bb-list-tile-contributor-region\"></div>\n\n  <!--content provider badges-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showBadges") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":2},"end":{"line":54,"column":9}}})) != null ? stack1 : "")
    + "\n  <!--download indicator-->\n  <div class=\"bb-download-progress-bar-region-list-tile\"></div>\n  <div class=\"bb-file-size-region\"></div>\n</div>\n\n<!--download indicator-->\n<div class=\"bb-download-progress-bar-region-list-tile\"></div>\n\n<!--BUTTONS COL-->\n<div class=\"bb-list-tile-buttons-column\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"collectionHighlight") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":67,"column":2},"end":{"line":71,"column":9}}})) != null ? stack1 : "")
    + "\n  <div class=\"bb-list-tile-text-buttons-row bb-list-tile-icon-buttons-row\">\n\n    <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"downloadUrl") || (depth0 != null ? lookupProperty(depth0,"downloadUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"downloadUrl","hash":{},"data":data,"loc":{"start":{"line":75,"column":13},"end":{"line":75,"column":28}}}) : helper)))
    + "\" download target=\"_blank\" aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n       class=\"btn circle-btn circle-btn-small btn-download clr-written hide\" data-toggle=\"tooltip\" title=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n      <i class=\"bbico bbico-download\"></i>\n    </a>\n\n    <!--offline bookshelf button-->\n    <button aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"toggleBookshelf") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\" id=\"bb-media-details-bookshelf-button\"\n            class=\"btn-bookshelf circle-btn circle-btn-brand-inverse circle-btn-small pointer hide\"\n            data-toggle=\"tooltip\"\n            title=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"toggleBookshelf") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n      <i class=\"bbico bbico-offline-bookshelf\"></i>\n    </button>\n\n    <button aria-label=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"favorite") : stack1), depth0))
    + "\" class=\"btn circle-btn circle-btn-small btn-favorite clr-written\" data-toggle=\"tooltip\"\n            title=\""
    + alias4((lookupProperty(helpers,"ternary")||(depth0 && lookupProperty(depth0,"ternary"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isFavorite") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"favoriteName") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"unfavoriteName") : stack1),{"name":"ternary","hash":{},"data":data,"loc":{"start":{"line":89,"column":19},"end":{"line":89,"column":79}}}))
    + "\">\n      <i class=\"bbico bbico-favorite\"></i>\n    </button>\n\n    <a class=\"btn btn-view btn-list clr-written\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"viewerUrl") || (depth0 != null ? lookupProperty(depth0,"viewerUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"viewerUrl","hash":{},"data":data,"loc":{"start":{"line":93,"column":55},"end":{"line":93,"column":68}}}) : helper)))
    + "\" aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"readBook") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\" data-toggle=\"tooltip\"\n       title=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"readName") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n      <span class=\"fnt-bld\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"read") : stack1), depth0))
    + "</span>\n    </a>\n\n    <button aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"curation") : stack1)) != null ? lookupProperty(stack1,"addTo") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\" class=\"btn btn-curation-add clr-written hide\"\n            data-toggle=\"tooltip\" title=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"curation") : stack1)) != null ? lookupProperty(stack1,"addTo") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n      <i class=\"bbico bbico-add\"></i>\n    </button>\n  </div>\n</div>";
},"useData":true});