import _ from "lodash";
import { isRelativeUrl } from "scripts/utils/urlUtil";

import BaseAuthenticationModel from "scripts/models/baseAuthenticationModel";

class VerifyEmailModel extends BaseAuthenticationModel {
  initialize(attributes, options) {
    this.username = options && options.username;
    this.accessKey = options && options.accessKey;
    this.signature = options && options.signature;
    this.emailAddress = options && options.emailAddress;
  }

  url() {
    return `${this.authenticationUrlRoot}/users/${this
      .username}/email_address_verified`;
  }

  syncOptions(options) {
    const opts = super.syncOptions(options);
    const crossDomain = !isRelativeUrl(this.authenticationUrlRoot);

    return _.merge(opts, {
      headers: {
        "X-Access-Key": this.accessKey,
        "X-Auth-Signature": this.signature,
      },
      crossDomain: crossDomain,
    });
  }
}

export default VerifyEmailModel;
