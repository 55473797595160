import inject from "scripts/ioc/inject";
import BaseMediaSalvattoreTileView from "scripts/views/baseMediaSalvattoreTileView";

import templateAnthologyCollectionSalvattoreTile from "templates/anthologyCollectionSalvattoreTile.hbs";

class AnthologyCollectionSalvattoreTileView extends BaseMediaSalvattoreTileView {
  constructor(
    options,
    securityService = inject("securityService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options, securityService);

    this.i18nextService = i18nextService;
  }

  get template() {
    return templateAnthologyCollectionSalvattoreTile;
  }

  setTextDirection() {
    const currentLngDir = this.i18nextService.getCurrentLngDir();
    $("#parent-anthology-container").css("direction", currentLngDir);
  }
}

export default AnthologyCollectionSalvattoreTileView;
