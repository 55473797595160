var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--tile-->\n<div class=\"category-tile\">\n    <div class=\"tile-main-body\">\n        <a href=\"/search-results/_cat=%22"
    + alias4(((helper = (helper = lookupProperty(helpers,"bisacCode") || (depth0 != null ? lookupProperty(depth0,"bisacCode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bisacCode","hash":{},"data":data,"loc":{"start":{"line":4,"column":41},"end":{"line":4,"column":54}}}) : helper)))
    + "%22%26_f=%22BOOK%22%26eft=false%26sort=true\" class=\"tile-action\">\n\n                <!--image-->\n                <div class=\"media-image-container\">\n                    <!--media image-->\n                    <img class=\"bb-media-salvattore-tile-image\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"iconUrl") || (depth0 != null ? lookupProperty(depth0,"iconUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":69},"end":{"line":9,"column":80}}}) : helper)))
    + "\" data-fallback-src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"iconFallbackUrl") || (depth0 != null ? lookupProperty(depth0,"iconFallbackUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconFallbackUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":101},"end":{"line":9,"column":120}}}) : helper)))
    + "\"\n                         alt=\""
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"thumbnailAlt") : stack1), depth0))
    + "\" />\n                </div>\n\n                <div class=\"title\">\n                    <!--title-->\n                    <div class=\"h3 truncate-2\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":15,"column":47},"end":{"line":15,"column":55}}}) : helper)))
    + "</div>\n                </div>\n\n            </a>\n    </div>\n</div>\n";
},"useData":true});