import BaseSecondaryStickyView from "scripts/views/baseSecondaryStickyNavView";

import templateCategoriesPageNav from "templates/categoriesPageNav.hbs";

class CategoriesPageNavView extends BaseSecondaryStickyView {
  get template() {
    return templateCategoriesPageNav;
  }

  shouldStick() {
    return true;
  }
}

export default CategoriesPageNavView;
