var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"bb-new-curation-form\" class=\"bb-form\">\n    <div class=\"form-group\">\n        <input id=\"bb-new-curation-form-curation-title\" class=\"form-control input-outline m-t-30 p-lr-20\" name=\"title\"\n            placeholder=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"curationTitle") : stack1), depth0))
    + "\" maxlength=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"maxTitleLength") || (depth0 != null ? lookupProperty(depth0,"maxTitleLength") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"maxTitleLength","hash":{},"data":data,"loc":{"start":{"line":4,"column":60},"end":{"line":4,"column":78}}}) : helper)))
    + "\" autofocus>\n    </div>\n\n    <div class=\"text-right m-b-20\">\n        <button id=\"bb-new-curation-form-curation-list\" class=\"bb-btn-a\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"returnToList") : stack1), depth0))
    + "</button>\n    </div>\n\n    <!--submit button-->\n\n    <div class=\"row m-t-10 m-b-20 m-lr-0 text-right\">\n      <button id=\"bb-new-curation-form-cancel\" class=\"btn btn-link\" data-dismiss=\"modal\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"cancel") : stack1), depth0))
    + "</button>\n      <button type=\"submit\" class=\"btn-rounded btn-rounded-brand-primary p-lr-40\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"createAndAdd") : stack1), depth0))
    + "</button>\n      <div class=\"bb-form-submit-button-loader-region btn btn-rounded btn-rounded-brand-primary disabled hide\">\n      </div>\n    </div>\n</form>";
},"useData":true});