import Handlebars from "handlebars-template-loader/runtime";

Handlebars.registerHelper("getEnv", key => process.env[key]);

Handlebars.registerHelper("ifEnv", (key, options) => {
  const value = process.env[key];

  if (value === "true") {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});
