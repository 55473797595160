import _ from "lodash";
import BaseApiModel from "scripts/models/baseApiModel";

import fallBackImage from "images/fallbacks/fallback_160_160.png";

class ModuleModel extends BaseApiModel {
  urlRoot() {
    return `${this.apiUrlRoot}/modules/`;
  }

  parse(response) {
    // TODO: need proper image for this model
    // Add module icon fallback image
    response.iconFallbackUrl = fallBackImage;

    response.whiteMediaBadges = _.filter(response.mediaBadges, {
      type: "white",
    });
    response.blackMediaBadges = _.filter(response.mediaBadges, {
      type: "black",
    });

    return response;
  }
}

export default ModuleModel;
