import _ from "lodash";
import BaseView from "scripts/views/baseView";
import templateCurationMediaThumbnailTile from "templates/curationMediaThumbnailTile.hbs";

class CurationMediaThumbnailTileView extends BaseView {
  get className() {
    return `bb-curation-media-thumbnail-tile`;
  }

  get events() {
    return _.extend({}, super.events, {
      "click img": "onClickThumbnail",
    });
  }

  get template() {
    return templateCurationMediaThumbnailTile;
  }

  constructor(options) {
    super(options);

    this.curationModel = options.curationModel;
  }

  render() {
    this.$el.html(
      this.template({
        thumbnailUrl: this.getThumbnailUrl(),
        thumbnailFallbackUrl: this.model.get("thumbnailFallbackUrl"),
      }),
    );

    this.initSelectedThumbnail();

    return this;
  }

  getThumbnailUrl() {
    const mediaType = this.model.get("mediaType");

    if (
      mediaType === "anthology" ||
      mediaType === "collection" ||
      mediaType === "module"
    ) {
      return this.model.get("iconUrl");
    } else if (mediaType === "content") {
      return this.model.get("thumbnailUrl");
    } else {
      throw new Error(
        `Cannot determine thumbnail for curation media thumbnail tile, media type: ${mediaType}`,
      );
    }
  }

  initSelectedThumbnail() {
    if (this.curationModel.get("thumbnailUrl") === this.getThumbnailUrl()) {
      this.selectThumbnail();
    }
  }

  onClickThumbnail() {
    this.deselectAllThumbnails();
    this.selectThumbnail();
    this.trigger("thumbnailSelected", this.model);
  }

  deselectAllThumbnails() {
    const $siblings = this.$el.siblings();

    $siblings.find(".curation-thumbnail-background").removeClass("selected");

    $siblings.find(".bb-curation-media-thumbnail-checkbox").addClass("hide");
  }

  selectThumbnail() {
    this.$el.find(".curation-thumbnail-background").addClass("selected");

    this.$el.find(".bb-curation-media-thumbnail-checkbox").removeClass("hide");
  }
}

export default CurationMediaThumbnailTileView;
