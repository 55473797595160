import _ from "lodash";
import toastr from "toastr";

export default function(level, msg, options) {
  var toastrDefaults = {
    closeButton: true,
    hideDuration: 0, // toastr animations not working, so prevent animation delay
  };

  toastr.options = _.merge(toastrDefaults, options);
  toastr[level](msg);
}
