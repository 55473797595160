import _ from "lodash";
import Backbone from "backbone";
import inject from "scripts/ioc/inject";
import BaseMediaDetailsPageView from "scripts/views/baseMediaDetailsPageView";
import CurationPageNavView from "scripts/views/curationPageNavView";
import { redirectToFragment } from "scripts/utils/routerHelpers";
import templateCurationPage from "templates/curationPage.hbs";

const chan = Backbone.Radio.channel;

class CurationPageView extends BaseMediaDetailsPageView {
  constructor(
    options,
    breadcrumbService = inject("breadcrumbService"),
    connectionService = inject("connectionService"),
    securityService = inject("securityService"),
  ) {
    super(options, breadcrumbService, connectionService, securityService);

    this.model.on(
      "change:presentationType",
      this.onPresentationTypeChangeSaveUserPreference,
      this,
    );

    chan("controllerView").reply("curationPageView", this.replyPageView, this);
  }

  get template() {
    return templateCurationPage;
  }

  get events() {
    return _.extend({}, super.events, {
      "click #bb-curation-page-edit": "onClickEdit",
    });
  }

  render() {
    super.render();

    this.renderPageHeaderDescriptionContainer();

    this.initEditButton();

    return this;
  }

  initEditButton() {
    const user = this.securityService.getUser();

    if (user.hasCuratorRole()) {
      this.showEditButton();
    } else {
      this.hideEditButton();
    }
  }

  onClickEdit() {
    const manageCurationUrl = `/manage-curation/${this.mediaModel.get("id")}`;

    redirectToFragment(manageCurationUrl);

    return false;
  }

  createNavView() {
    return new CurationPageNavView({
      model: this.model,
    });
  }

  showEditButton() {
    $("#bb-curation-page-edit").removeClass("hide");
  }

  hideEditButton() {
    $("#bb-curation-page-edit").addClass("hide");
  }
}

export default CurationPageView;
