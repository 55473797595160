import BaseApiModel from "scripts/models/baseApiModel";

class ContentFileModel extends BaseApiModel {
  urlRoot() {
    return `${this.apiUrlRoot}/contentFile/`;
  }

  parse(response) {
    // modify to match other UI components
    response.audioDurationSeconds = response.duration;
    return response;
  }
}

export default ContentFileModel;
