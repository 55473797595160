import Controller from "scripts/controllers/controller";
import ContentModel from "scripts/models/contentModel";
import { replaceAndLoadFragment } from "scripts/utils/routerHelpers";

class LegacyContentUrlForwardController extends Controller {
  get route() {
    return "route:legacyContentUrlForward";
  }

  replyRoute(route, acid) {
    return new ContentModel({
      id: acid,
    })
      .fetch()
      .then(contentModel => {
        replaceAndLoadFragment(this.contentDetailsRoute(contentModel));
      });
  }

  contentDetailsRoute(contentModel) {
    const contentType = contentModel.get("type");
    const contentTypePath = this.contentTypePath(contentType);
    const mediaId = contentModel.get("id");

    return `/content/${contentTypePath}/${mediaId}`;
  }

  contentTypePath(contentType) {
    if (contentType === "ARTICLE") {
      return "article";
    } else if (contentType === "AUDIO") {
      return "audio";
    } else if ((contentType === "BOOK") || (contentType === "CHAPTER")) {
      return "book";
    } else if (contentType === "IMAGE") {
      return "image";
    } else if (contentType === "OTHER_DOCUMENT") {
      return "other-document";
    } else if (contentType === "VIDEO") {
      return "video";
    } else {
      throw new Error(
        "Cannot associate media model with content details route, content type: " +
          contentType,
      );
    }
  }
}

export default LegacyContentUrlForwardController;
