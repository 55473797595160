import BaseListTileView from "scripts/views/baseListTileView";
import templateAnthologyCollectionListTile from "templates/anthologyCollectionListTile.hbs";

class AnthologyCollectionListTileView extends BaseListTileView {
  get template() {
    return templateAnthologyCollectionListTile;
  }
}

export default AnthologyCollectionListTileView;
