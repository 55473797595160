import Backbone from "backbone";
import BaseApiModel from "scripts/models/baseApiModel";
import inject from "scripts/ioc/inject";

const chan = Backbone.Radio.channel;

class BookshelfLogModel extends BaseApiModel {
  constructor(attrs, options, deviceService = inject("deviceService")) {
    super(attrs, options);

    this.deviceService = deviceService;

    this.setDefaultModelProperties();
  }

  urlRoot() {
    const userId = chan("security")
      .request("user")
      .getUserId();

    return `${this.apiUrlRoot}/users/${userId}/bookshelf/`;
  }

  setDefaultModelProperties() {
    this.set({
      deviceId: this.deviceService.uuid,
      platformType: this.deviceService.platform,
    });
  }
}

export default BookshelfLogModel;
