import _ from "lodash";
import inject from "scripts/ioc/inject";
import BaseMediaSalvattoreTileView from "scripts/views/baseMediaSalvattoreTileView";

import templateArticleSalvattoreTile from "templates/articleSalvattoreTile.hbs";

class ArticleSalvattoreTileView extends BaseMediaSalvattoreTileView {
  constructor(options, securityService = inject("securityService")) {
    super(options, securityService);

    if (this.bookshelfAvailable()) {
      this.initializeBookshelfDeps();
    }
  }

  get template() {
    return templateArticleSalvattoreTile;
  }

  get events() {
    return _.extend({}, super.events, {
      "click .btn-bookshelf": "onClickBookshelfButton",
    });
  }

  render() {
    super.render();

    if (this.bookshelfAvailable()) {
      this.renderBookshelfElements();
    }

    return this;
  }
}

export default ArticleSalvattoreTileView;
