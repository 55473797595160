import Backbone from "backbone";
import Promise from "bluebird";
import Controller from "scripts/controllers/controller";
import LegalNoticesPageView from "scripts/views/legalNoticesPageView";

class GenericController extends Controller {
  get route() {
    return "route:about route:metadataInfo route:eula route:privacy route:cookiePolicy";
  }

  replyRoute(route) {
    if (route === "about") {
      return this.showMainBodyViewPromise(
        Promise.resolve(
          import(
            /* webpackChunkName: "aboutPage" */ "scripts/views/aboutPageView"
          ),
        ).then(({ default: AboutPageView }) => new AboutPageView()),
      );
    } else if (route === "metadataInfo") {
      return this.showMainBodyViewPromise(
        Promise.resolve(
          import(
            /* webpackChunkName: "metadataInfo" */ "scripts/views/metadataInfoView"
          ),
        ).then(({ default: MetadataInfoView }) => new MetadataInfoView()),
      );
    } else if (route === "eula") {
      return this.showMainBodyViewPromise(
        Promise.resolve(
          import(
            /* webpackChunkName: "legalNoticesPageEulaSubPage" */ "scripts/views/legalNoticesPageEulaSubPageView"
          ),
        ).then(
          ({ default: LegalNoticesPageEulaSubPageView }) =>
            new LegalNoticesPageView({
              subPageView: new LegalNoticesPageEulaSubPageView(),
              model: new Backbone.Model({
                route,
              }),
            }),
        ),
      );
    } else if (route === "privacy") {
      return this.showMainBodyViewPromise(
        Promise.resolve(
          import(
            /* webpackChunkName: "legalNoticesPagePrivacySubPage" */ "scripts/views/legalNoticesPagePrivacySubPageView"
          ),
        ).then(
          ({ default: LegalNoticesPagePrivacySubPageView }) =>
            new LegalNoticesPageView({
              subPageView: new LegalNoticesPagePrivacySubPageView(),
              model: new Backbone.Model({
                route,
              }),
            }),
        ),
      );
    } else if (route === "cookiePolicy") {
      return this.showMainBodyViewPromise(
        Promise.resolve(
          import(
            /* webpackChunkName: "legalNoticesPageCookieSubPage" */ "scripts/views/legalNoticesPageCookieSubPageView"
          ),
        ).then(
          ({ default: LegalNoticesPageCookieSubPageView }) =>
            new LegalNoticesPageView({
              subPageView: new LegalNoticesPageCookieSubPageView(),
              model: new Backbone.Model({
                route,
              }),
            }),
        ),
      );
    }
  }
}

export default GenericController;
