import _ from "lodash";
import BaseApiModel from "scripts/models/baseApiModel";

import fallBackImageAnthology from "images/fallbacks/fallback_160_160.png";

class AnthologyModel extends BaseApiModel {
  urlRoot() {
    return `${this.apiUrlRoot}/anthologies/`;
  }

  parse(response) {
    response.iconFallbackUrl = fallBackImageAnthology;

    response.typeLabelLowerCase = response.typeLabel.toLowerCase();

    if (response.typeCode === "ANTHOLOGY") {
      response.collectionTypeCode = "COLLECTION";
      response.collectionTypeLabel = "Collection";
    } else {
      response.collectionTypeCode = "CHAPTER";
      response.collectionTypeLabel = "Chapter";
    }

    response.collectionTypeLabelLowerCase = response.collectionTypeLabel.toLowerCase();

    response.whiteMediaBadges = _.filter(response.mediaBadges, {
      type: "white",
    });
    response.blackMediaBadges = _.filter(response.mediaBadges, {
      type: "black",
    });

    return response;
  }

  toJSON() {
    let attributes = _.clone(this.attributes); //TODO: why not call super?
    attributes.shortDescription = this.getShortDescription();
    return attributes;
  }

  getShortDescription() {
    return _.prune(this.get("description"), 255);
  }
}

export default AnthologyModel;
