import BaseAuthenticationCollection from "scripts/collections/baseAuthenticationCollection";
import OrganizationVerificationAuthenticationModel from "scripts/models/organizationVerificationAuthenticationModel";

class OrganizationsVerificationsAuthenticationCollection extends BaseAuthenticationCollection {
  get model() {
    return OrganizationVerificationAuthenticationModel;
  }

  get path() {
    return "/organizations/verifications";
  }
}

export default OrganizationsVerificationsAuthenticationCollection;
