var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container bookshelf-header\">\n    <div class=\"pull-left recents-title\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":2,"column":41},"end":{"line":2,"column":50}}}) : helper)))
    + "</div>\n    <div class=\"pull-right\">"
    + alias3((lookupProperty(helpers,"formatBytes")||(depth0 && lookupProperty(depth0,"formatBytes"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"totalContentSize") : depth0),0,{"name":"formatBytes","hash":{},"data":data,"loc":{"start":{"line":3,"column":28},"end":{"line":3,"column":62}}}))
    + " "
    + alias3(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"onDevice") : stack1), depth0))
    + "</div>\n</div>\n\n\n\n<div id=\"bb-bookshelf-sub-page-content-region\"></div>\n";
},"useData":true});