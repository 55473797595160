import _ from "lodash";
import BaseApiPageableCollection from "scripts/collections/baseApiPageableCollection";
import MediaCollectionHoc from "scripts/hoc/mediaCollectionHoc";
import HybridCollectionHoc from "scripts/hoc/hybridCollectionHoc";

class SearchResultCollection extends HybridCollectionHoc(
  MediaCollectionHoc(BaseApiPageableCollection),
) {
  get defaultQueryStringParams() {
    return _.merge({ "facet-list": "" }, super.defaultQueryStringParams);
  }

  parse(response, options) {
    this.searchTime = response.searchTime;

    return super.parse(response.media, options);
  }
}

export default SearchResultCollection;
