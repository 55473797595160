import Backbone from "backbone";
import Controller from "scripts/controllers/controller";
import syncError from "scripts/functions/syncError";
import inject from "scripts/ioc/inject";
import ErrorPageModel from "scripts/models/errorPageModel";
import ErrorPageView from "scripts/views/errorPageView";

const chan = Backbone.Radio.channel;

class ErrorController extends Controller {
  constructor(
    securityService = inject("securityService"),
    sessionStorageService = inject("sessionStorageService"),
  ) {
    super();

    this.securityService = securityService;
    this.sessionStorageService = sessionStorageService;

    chan("display").reply("showErrorPage", this.showErrorPage, this);
  }

  get route() {
    return "route:error";
  }

  replyRoute(route, statusCode) {
    return this.showErrorPage(syncError(statusCode));
  }

  showErrorPage(error) {
    console.log("Showing error page for error: %O", error);

    return this.showScreenView(
      new ErrorPageView({
        model: new ErrorPageModel({
          error: error,
        }),
      }),
    );
  }
}

export default ErrorController;
