var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"bb-verify-email-notification-form\" class=\"bb-form\">\n       <input name=\"emailAddress\" type=\"hidden\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"emailAddress") || (depth0 != null ? lookupProperty(depth0,"emailAddress") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"emailAddress","hash":{},"data":data,"loc":{"start":{"line":2,"column":55},"end":{"line":2,"column":71}}}) : helper)))
    + "\"\n              id=\"bb-verify-email-notification-emailAddress-field\" autocomplete=\"email\">\n\n       <input name=\"targetUrl\" type=\"hidden\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"targetUrl") || (depth0 != null ? lookupProperty(depth0,"targetUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"targetUrl","hash":{},"data":data,"loc":{"start":{"line":5,"column":52},"end":{"line":5,"column":65}}}) : helper)))
    + "\" id=\"bb-verify-email-notification-targetUrl-field\">\n\n       <!--submit button-->\n       <button type=\"submit\" class=\"btn-solid btn-solid-brand-primary btn-block m-tb-20\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"auth") : stack1)) != null ? lookupProperty(stack1,"resend") : stack1), depth0))
    + " <span\n                     class=\"hidden-xs\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"auth") : stack1)) != null ? lookupProperty(stack1,"verification") : stack1), depth0))
    + "</span> "
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + "</button>\n\n       <!--this is hacky way to work around base form class-->\n       <!--somewhere in the code, a submit button reveals the below button as a loading indicator-->\n       <div class=\"bb-form-submit-button-loader-region btn-solid btn-solid-brand-primary btn-block opacity-050 text-notransform disabled hide\"\n              style=\"margin-top:20px\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"auth") : stack1)) != null ? lookupProperty(stack1,"sending") : stack1), depth0))
    + "</div>\n</form>\n\n<!--home button-->\n<a href=\"/home\" class=\"btn-outline btn-outline-gray btn-block m-t-5\">BiblioBoard Home</a>";
},"useData":true});