import _ from "lodash";
import inject from "scripts/ioc/inject";
import BaseService from "scripts/services/baseService";
import MediaLineageModel from "scripts/models/mediaLineageModel";

class RelatedContentService extends BaseService {
  constructor(
    breadcrumbService = inject("breadcrumbService"),
    connectionService = inject("connectionService"),
  ) {
    super();

    this.breadcrumbService = breadcrumbService;
    this.connectionService = connectionService;
  }

  isOnline() {
    return this.connectionService.getConnectionStatus().isOnline;
  }

  fetchMediaLineageModel(contentModel) {
    return new MediaLineageModel({
      mediaLineageCandidates: this.breadcrumbService.getMediaLineageCandidates(
        contentModel.get("id"),
      ),
    }).fetch();
  }

  fetchParentModel(parentMediaModel, parentMediaId) {
    parentMediaModel.set("id", parentMediaId);

    return parentMediaModel.fetch();
  }

  async fetchSiblingsForContentType(
    collection,
    contentModel,
    parentMediaModel,
  ) {
    if (this.isOnline()) {
      let parentMediaId;
      const referringBreadcrumbModel = _.findLast(
        _.takeRight(this.breadcrumbService.navigationHistory, 2),
        breadcrumbModel => {
          return (
            breadcrumbModel.has("referrer") &&
            breadcrumbModel.get("referrer").child.get("id") ===
              contentModel.get("id")
          );
        },
      );

      console.log("##########################################################");
      console.log("contentModel");
      console.log(contentModel);

      if (referringBreadcrumbModel) {
        parentMediaId = referringBreadcrumbModel
          .get("referrer")
          .parent.get("id");
        console.log("Retrieving related content via referrer breadcrumb");
      } else {
        const mediaLineageModel = await this.fetchMediaLineageModel(
          contentModel,
        );

        console.log("mediaLineageModel");
        console.log(mediaLineageModel);

        const mediaLineage = mediaLineageModel
          ? mediaLineageModel.get("mediaLineage")
          : null;
        parentMediaId =
          mediaLineage && mediaLineage.length > 1 ? mediaLineage[1] : null;
        console.log("Retrieving related content via media lineage");

        console.log("mediaLineage");
        console.log(mediaLineage);
      }

      console.log("parentMediaId");
      console.log(parentMediaId);

      if (parentMediaId) {
        await this.fetchParentModel(parentMediaModel, parentMediaId);
        const path = this.getPath(parentMediaModel, contentModel);

        console.log("path");
        console.log(path);

        if (path) {
          collection.path = path;
          return collection.fetch();
        }
      }
    }

    console.log("collection");
    console.log(collection);
    console.log("##########################################################");

    return collection;
  }

  getPath(parentMediaModel, contentModel) {
    const parentMediaType = parentMediaModel.get("mediaType");
    const parentMediaId = parentMediaModel.get("id");
    const contentTypePath = this.getPathContentType(contentModel);
    let path;

    if (parentMediaType === "curation") {
      path = `/curations/${parentMediaId}/content/${contentTypePath}`;
    } else if (parentMediaType === "anthology") {
      path = `/anthologies/${parentMediaId}/content/${contentTypePath}`;
    } else if (parentMediaType === "collection") {
      path = `/collections/${parentMediaId}/content/${contentTypePath}`;
    }

    return path;
  }

  getPathContentType(contentModel) {
    const contentType = contentModel.get("type");

    if (_.includes(["ARTICLE", "BOOK", "CHAPTER", "VIDEO", "IMAGE", ""], contentType)) {
      return contentType.toLowerCase() + "s";
    } else if (contentType === "AUDIO") {
      return "audio";
    } else if (contentType === "OTHER_DOCUMENT") {
      return "other-documents";
    } else {
      throw new Error(
        `Cannot determine content type for model: ${contentModel}`,
      );
    }
  }
}

export default RelatedContentService;
