var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/home\">\n    <img id=\"bb-main-nav-logo-image\" src=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"organizationLogoUrl") || (depth0 != null ? lookupProperty(depth0,"organizationLogoUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"organizationLogoUrl","hash":{},"data":data,"loc":{"start":{"line":2,"column":42},"end":{"line":2,"column":67}}}) : helper))) != null ? stack1 : "")
    + "\" alt=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"orgLogoAlt") : stack1), depth0))
    + "\"/>\n</a>\n";
},"useData":true});