import BaseView from "scripts/views/baseView";
import CitationCollection from "scripts/collections/citationCollection";
import CitationView from "scripts/views/citationView";

import templateCitations from "templates/citations.hbs";

class CitationsView extends BaseView {
  get template() {
    return templateCitations;
  }

  // get className() {
  //   return "row";
  // }

  constructor(options) {
    super(options);
    const mediaId = options.mediaId ||  this.model.get("mediaId");

    this.collection = new CitationCollection([], {
      path: `/media/${mediaId}/citations`,
    });
  }

  render() {
    this.$el.html(this.template());

    this.collection.each(this.addCitation.bind(this));

    return this;
  }

  sync() {
    return this.collection.fetch();
  }

  addCitation(citationModel) {
    const citationView = this.addSubView(
      new CitationView({
        model: citationModel,
      }),
    );

    citationView.appendTo($("#bb-citations-sub-page-container")).render();
  }
}

export default CitationsView;
