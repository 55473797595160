var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button id=\"bb-error-page-back-button\" class=\"btn-outline-rounded btn-outline-gray text-uppercase\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"takeMeBack") : stack1), depth0))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"error-page bg-white\">\n    <div class=\"container text-center m-t-100 m-b-40\">\n        <div class=\"row m-b-50\">\n            <div class=\"col-xs-12\">\n                <img class=\"hidden-xs error-img-403 m-t-50\" src=\"" + require("/opt/atlassian/pipelines/agent/build/node_modules/handlebars-template-loader/file-loader.js?url=..%2Fimages%2Ferror403-landscape.png!../images/error403-landscape.png") + "\" alt=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"error") : stack1)) != null ? lookupProperty(stack1,"forbidden") : stack1), depth0))
    + "\">\n                <img class=\"visible-xs error-img-403\" src=\"" + require("/opt/atlassian/pipelines/agent/build/node_modules/handlebars-template-loader/file-loader.js?url=..%2Fimages%2Ferror403-portrait.png!../images/error403-portrait.png") + "\" alt=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"error") : stack1)) != null ? lookupProperty(stack1,"forbidden") : stack1), depth0))
    + "\">\n            </div>\n        </div>\n\n        <div class=\"row m-b-30\">\n            <div class=\"col-xs-12 col-md-6 col-md-offset-3\">\n              <p class=\"fnt-36\"><strong>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"errorPage") : stack1)) != null ? lookupProperty(stack1,"403") : stack1)) != null ? lookupProperty(stack1,"halt") : stack1), depth0))
    + "</strong></p>\n              <p class=\"fnt-20\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"errorPage") : stack1)) != null ? lookupProperty(stack1,"403") : stack1)) != null ? lookupProperty(stack1,"noAccess") : stack1), depth0))
    + "</p>\n            </div>\n        </div>\n\n        <div class=\"row m-b-20\">\n            <div class=\"col-xs-12\">\n                <!-- button container-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"canGoBack") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":22,"column":23}}})) != null ? stack1 : "")
    + "\n                <button id=\"bb-error-page-home-button\" class=\"btn-rounded btn-solid-brand-primary text-uppercase\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"visitHome") : stack1), depth0))
    + "</button>\n            </div>\n        </div>\n\n        <div class=\"row\">\n            <div class=\"col-xs-12\">\n                <a href=\"http://support.biblioboard.com\" target=\"_blank\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"support") : stack1), depth0))
    + "\n                </a>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});