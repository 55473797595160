const pad = num => {
  const norm = Math.floor(Math.abs(num));
  return (norm < 10 ? "0" : "") + norm;
};

/**
 * Adapted from: https://stackoverflow.com/questions/17415579/how-to-iso-8601-format-a-date-with-timezone-offset-in-javascript?answertab=votes#tab-top
 */
export const formatIso8601 = (
  fullYear,
  month,
  date,
  hours,
  minutes,
  seconds,
  timezoneOffset,
) => {
  const tzo = -timezoneOffset;
  const dif = tzo >= 0 ? "+" : "-";

  return (
    fullYear +
    "-" +
    pad(month + 1) +
    "-" +
    pad(date) +
    "T" +
    pad(hours) +
    ":" +
    pad(minutes) +
    ":" +
    pad(seconds) +
    dif +
    pad(tzo / 60) +
    ":" +
    pad(tzo % 60)
  );
};
