import BaseModalView from "scripts/views/baseModalView";
import { groupedPublicationYearTerms } from "scripts/views/search/facetMapper";
import templateFacetedSearchPublicationYearModal from "templates/search/facetedSearchPublicationYearModal.hbs";

class FacetedSearchPublicationYearModalView extends BaseModalView {
  constructor(model, options = {}) {
    super(model);

    this.options = {
      showCounts: true,
      ...options,
    };
  }

  get events() {
    return {
      "click .bb-faceted-search-modal-checkbox-group-collapse-link":
        "onClickCollapse",
      "click .bb-faceted-search-modal-checkbox-group-expand-link":
        "onClickExpand",
      "click #bb-faceted-search-facet-modal-apply-button": "onClickApply",
      "click .bb-faceted-search-modal-checkbox-group": "onClickCheckboxGroup",
      "click .faceted-search-modal-checkbox": "onClickChildCheckbox",
    };
  }

  get className() {
    return "modal-content faceted-search-modal";
  }

  get template() {
    return templateFacetedSearchPublicationYearModal;
  }

  render() {
    this.$el.html(
      this.template({
        facetLabel: this.model.get("facetLabel"),
        termGroups: groupedPublicationYearTerms(
          this.model.get("facetValues"),
          this.model.get("chosenTermCodes"),
        ),
        showCounts: this.options.showCounts,
      }),
    );

    return this;
  }

  onClickCollapse(e) {
    const $currentTarget = $(e.currentTarget);
    $currentTarget
      .closest("li")
      .find("ul")
      .addClass("hidden");

    $currentTarget.addClass("hidden");
    $currentTarget
      .siblings(".bb-faceted-search-modal-checkbox-group-expand-link")
      .removeClass("hidden");
  }

  onClickExpand(e) {
    const $currentTarget = $(e.currentTarget);

    $currentTarget
      .closest("li")
      .find("ul")
      .removeClass("hidden");

    $currentTarget
      .addClass("hidden")
      .siblings(".bb-faceted-search-modal-checkbox-group-collapse-link")
      .removeClass("hidden");

    $(window).trigger("resize.bs.modal");
  }

  onClickCheckboxGroup(e) {
    const $currentTarget = $(e.currentTarget);

    const $children = this.getChildCheckboxesForGroup($currentTarget);

    if ($currentTarget.is(":checked")) {
      $children.prop("checked", true);
    } else {
      $children.prop("checked", false);
    }
  }

  onClickChildCheckbox(e) {
    const $currentTarget = $(e.currentTarget);

    const $groupCheckbox = this.getGroupCheckboxForChild($currentTarget);

    if ($currentTarget.is(":checked")) {
      const $siblingCheckboxes = this.getChildCheckboxesForGroup(
        $groupCheckbox,
      );

      let allChecked = true;

      $siblingCheckboxes.each((index, checkbox) => {
        if (!checkbox.checked) {
          allChecked = false;
          return false;
        }
      });

      if (allChecked) {
        $groupCheckbox.prop("checked", true);
      }
    } else {
      $groupCheckbox.prop("checked", false);
    }
  }

  getGroupCheckboxForChild($child) {
    return $child
      .closest("ul")
      .closest("li")
      .find(".bb-faceted-search-modal-checkbox-group");
  }

  getChildCheckboxesForGroup($group) {
    return $group.closest("li").find(".faceted-search-modal-checkbox");
  }

  onClickApply() {
    const termCodes = [];

    this.$el
      .find(".bb-faceted-search-modal-checkbox-group")
      .toArray()
      .forEach(groupCheckbox => {
        if (groupCheckbox.checked) {
          termCodes.push(groupCheckbox.value);
        } else {
          this.getChildCheckboxesForGroup($(groupCheckbox))
            .toArray()
            .forEach(checkbox => {
              if (checkbox.checked) {
                termCodes.push(checkbox.value);
              }
            });
        }
      });

    this.trigger("apply", this.model.get("facetParam"), termCodes);
  }
}

export default FacetedSearchPublicationYearModalView;
