import _ from "lodash";
import Backbone from "backbone";
import Controller from "scripts/controllers/controller";
import CurationModel from "scripts/models/curationModel";
import CurationPageModel from "scripts/models/curationPageModel";
import CurationPageView from "scripts/views/curationPageView";
import CurationPageMediaSubPageView from "scripts/views/curationPageMediaSubPageView";
import inject from "scripts/ioc/inject";

const chan = Backbone.Radio.channel;

class CurationController extends Controller {
  constructor(securityService = inject("securityService")) {
    super();

    this.securityService = securityService;
  }

  get route() {
    return "route:curation";
  }

  get defaults() {
    return {
      contentType: "all-media",
      presentationType: "masonry",
    };
  }

  routeAttrs() {
    const user = this.securityService.getUser();

    let pageIndex = parseInt(arguments[3], 10);

    if (!isNaN(pageIndex)) {
      pageIndex = pageIndex - 1;
    } else {
      pageIndex = 0;
    }

    return _.merge(
      {},
      this.defaults,
      {
        presentationType: user.getContentViewPreference(),
      },
      {
        mediaId: arguments[0],
        presentationType: arguments[1] || void 0,
        contentType: arguments[2] || void 0,
        pageIndex,
      },
    );
  }

  replyRoute() {
    let attrs = this.routeAttrs.apply(this, _.tail(arguments));

    const subPageView = this.createSubPageView(attrs);
    let curationPageView = chan("controllerView").request(
      "curationPageView",
      attrs.mediaId,
    );

    if (curationPageView) {
      curationPageView.model.set(attrs);

      return curationPageView.showSubPageView(subPageView);
    } else {
      curationPageView = new CurationPageView({
        model: new CurationPageModel(attrs),
        mediaModel: new CurationModel({
          id: attrs.mediaId,
        }),
        subPageView: subPageView,
      });

      return this.showMainBodyView(curationPageView);
    }
  }

  createSubPageView(attrs) {
    return new CurationPageMediaSubPageView({
      model: new Backbone.Model({
        mediaId: attrs.mediaId,
        presentationType: attrs.presentationType,
        contentType: attrs.contentType,
        pageIndex: attrs.pageIndex,
      }),
    });
  }
}

export default CurationController;
