import _ from "lodash";
import BaseView from "scripts/views/baseView";
import Velocity from "velocity-animate";

class BaseGrowlAlertView extends BaseView {
  get events() {
    return {
      mouseover: "onMouseover",
    };
  }

  get defaultAutoCloseDelay() {
    return 5000;
  }

  constructor(options) {
    options = options || {};

    super(options);

    if (options.autoClose) {
      this.initAutoClose(options.autoCloseDelay);
    }
  }

  animateIn() {
    return Velocity.animate(
      this.$el.find(".bb-growl-alert"),
      "transition.expandIn",
      {
        duration: 300,
      },
    ).then(() => {
      $(".bb-growl-alert").attr("role", "alert");
    });
  }

  animateOut() {
    return Velocity.animate(
      this.$el.find(".bb-growl-alert"),
      "transition.expandOut",
      {
        duration: 300,
      },
    );
  }

  initAutoClose(delay = this.defaultAutoCloseDelay) {
    this.autoCloseTimerId = _.delay(() => {
      this.trigger("close");
    }, delay);
  }

  onMouseover() {
    this.cancelAutoClose();
  }

  cancelAutoClose() {
    if (this.autoCloseTimerId) {
      clearTimeout(this.autoCloseTimerId);
      this.autoCloseTimerId = null;
    }
  }

  close() {
    this.cancelAutoClose();

    super.close();
  }
}

export default BaseGrowlAlertView;
