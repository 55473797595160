import _ from "lodash";
import Backbone from "backbone";
import Promise from "bluebird";
import BaseView from "scripts/views/baseView";
import LoaderView from "scripts/views/loaderView";
import fallbackImages from "scripts/functions/fallbackImages";
import pushstateLinks from "scripts/functions/pushstateLinks";
import inject from "scripts/ioc/inject";
import { refreshRoute } from "scripts/utils/routerHelpers";
import { getFaviconOption } from "scripts/utils/whiteLabelUtil";
import { isIos, isWindowsInTabletMode } from "scripts/utils/userAgentUtil";
import templateApp from "templates/app.hbs";
import { isAndroid } from "../utils/userAgentUtil";

const chan = Backbone.Radio.channel;

const setFaviconOption = faviconOptionName => {
  const iconOption = getFaviconOption(faviconOptionName);

  const favicon = document.getElementById("favicon");
  if (favicon) {
    favicon.href = iconOption.png;

    const faviconShortcut = document.getElementById("favicon-shortcut");
    faviconShortcut.href = iconOption.ico;
  }
};

class AppView extends BaseView {
  get template() {
    return templateApp;
  }

  get className() {
    return "bb-app";
  }

  get screenSmMin() {
    return 768;
  }

  get screenMdMin() {
    return 992;
  }

  get screenLgMin() {
    return 1200;
  }

  get tabletMaxWidth() {
    // return 991;
    return 1024;
  }

  /**
   * If user moves this value or less pixels, the touch event is interpreted
   * as a touch (tap), otherwise its a touch move (swipe / scroll).
   */
  get touchMoveThreshold() {
    return 15;
  }

  constructor(options, connectionService = inject("connectionService")) {
    super(options);

    this.connectionService = connectionService;

    this.triggerWindowResize = _.debounce(() => {
      chan("display").trigger("window:resize", window.innerHeight);
    }, 100);

    this.triggerScrollStop = _.debounce(() => {
      this.scrolling = false;

      chan("display").trigger("scroll:stop", $(window).scrollTop());

      //TODO: figure out a better way to deal with this, why can't we use a custom event name?
      $(window).trigger("scrollstop"); // hack for lazy load jquery plugin
    }, 100);

    this.triggerScrolling = _.throttle(() => {
      const newScrollTop = $(window).scrollTop();
      const delta = this.scrollingScrollTop - newScrollTop;
      this.scrollingScrollTop = newScrollTop;

      chan("display").trigger("scroll:scrolling", $(window).scrollTop(), delta);
    }, 10);

    this.loaderView = this.addSubView(new LoaderView());

    const displayChannel = chan("display");

    displayChannel.reply("showScreenView", this.showScreenView, this);
    displayChannel.reply("showScreenViewPromise", this.showScreenViewPromise, this);
    displayChannel.reply("touch:touchMoved", this.hasTouchMoved.bind(this), this);
    displayChannel.reply("scroll:scrolling", this.isScrolling, this);
    displayChannel.reply("showModal", this.showModal, this);
    displayChannel.reply("updateModal", this.updateModal, this);
    displayChannel.reply("closeModal", this.closeModal, this);
    displayChannel.reply("setFavicon", setFaviconOption, this);

    $(window).on("resize", this.triggerWindowResize);

    this.scrollingScrollTop = 0;
    $(window).on("scroll", this.triggerScrollStop);
    $(window).on("scroll", this.triggerScrolling);
    $(window).on("scroll", this.setScrolling);
    $(window).on("click", this.triggerClick);

    $(document).on("touchstart", this.onTouchStart.bind(this));
    $(document).on("touchmove", this.onTouchMove.bind(this));

    chan("display").reply("refreshScreen", this.refreshScreen, this);

    this.numShowBlockingLoaderRequests = 0;
    chan("display").reply("showBlockingLoader", this.showBlockingLoader, this);
    chan("display").reply("hideBlockingLoader", this.hideBlockingLoader, this);

    //xs =    0 - 767     isSmallerThanSm
    //sm =    768 - 991   isLargerThanXs && isSmallerThanMd
    //md =    992 - 1199  isLargerThanSm && isSmallerThanLg
    //lg =    1200 +      isLargerThanMd

    chan("display").reply("isSmallerThanSm", () => window.innerWidth < this.screenSmMin, this);
    chan("display").reply("isSmallerThanMd", () => window.innerWidth < this.screenMdMin, this);
    chan("display").reply("isSmallerThanLg", () => window.innerWidth < this.screenLgMin, this);
    chan("display").reply("isLargerThanXs", () => window.innerWidth >= this.screenSmMin, this);
    chan("display").reply("isLargerThanSm", () => window.innerWidth >= this.screenMdMin, this);
    chan("display").reply("isLargerThanMd", () => window.innerWidth >= this.screenLgMin, this);

    chan("display").reply("isDesktopWidth", () => window.innerWidth > this.tabletMaxWidth, this);

    chan("display").reply("isDesktopMode", () => window.innerWidth > this.tabletMaxWidth && !isIos(), this);

    chan("display").reply("isTouchEnabled", () => isIos() || isWindowsInTabletMode() || isAndroid(), this);

    chan("connectionService").on("status:change", this.onConnectionStatusChange, this);
  }

  render() {
    this.$el.html(this.template());

    this.loaderView.appendTo(this.$el.find("#bb-app-blocking-loading-indicator-loader-region")).render();

    this.updateConnectionState();

    fallbackImages();
    pushstateLinks();

    return this;
  }

  showModal(modalView, options) {
    options = options || {};

    return this.closeModal()
      .bind(this)
      .then(() => {
        this.modalView = this.addSubView(modalView);

        this.modalView.appendTo(this.$el.find("#bb-app-modal-content-region")).render();

        const $modal = this.$el.find("#bb-app-modal");

        if ($modal.data("bs.modal")) {
          $modal.data("bs.modal").options = _.merge(
            {
              backdrop: true,
              keyboard: true,
              show: true,
            },
            options,
          );
        } else {
          $modal.modal(options);
        }

        $modal.modal("show").one("hidden.bs.modal", () => {
          this.modalView.trigger("close");
          this.cleanupModal();
        });

        return new Promise(resolve => {
          $modal.one("shown.bs.modal", resolve);
        });
      });
  }

  updateModal() {
    const $modal = this.$el.find("#bb-app-modal");
    $modal.modal("handleUpdate");
  }

  cleanupModal() {
    this.closeSubView(this.modalView);
    this.modalView = undefined;
  }

  closeModal() {
    if (this.modalView) {
      const $modal = this.$el.find("#bb-app-modal").modal("hide");

      return new Promise(resolve => {
        $modal.one("hidden.bs.modal", resolve);
      });
    } else {
      return Promise.resolve();
    }
  }

  showScreenView(screenView) {
    return this.showScreenViewPromise(Promise.resolve(screenView));
  }

  showScreenViewPromise(screenViewPromise) {
    this.showBlockingLoader();

    return screenViewPromise.then(screenView => {
      return this.showSubView("screenView", screenView, this.$el.find("#bb-app-screen-region"))
        .then(view => {
          $("#bb-app-loader").remove();
          return view;
        })
        .finally(() => {
          this.hideBlockingLoader();

          let initialScrollTop = screenView.initialScrollTop();

          if (initialScrollTop === "bottom") {
            initialScrollTop = $(document).height() - $(window).height();
          } else {
            initialScrollTop = parseInt(initialScrollTop, 10);
          }

          if (!isNaN(initialScrollTop)) {
            $(window).scrollTop(initialScrollTop);
          }
        });
    });
  }

  triggerClick() {
    chan("display").trigger("click");
  }

  /**
   * Returns true if a 'touchmove' event occurs after a 'touchstart' event.
   * Useful for differentiating a "click" from a "scroll" on a touch device.
   */
  hasTouchMoved() {
    return this.touchMoved || false;
  }

  isScrolling() {
    return this.scrolling || false;
  }

  onTouchMove(e) {
    const deltaX = e.originalEvent.changedTouches[0].pageX - this.touchStartX;
    const deltaY = e.originalEvent.changedTouches[0].pageY - this.touchStartY;
    const diff = deltaX * deltaX + deltaY * deltaY;

    if (Math.sqrt(diff) > this.touchMoveThreshold) {
      this.touchMoved = true;
    }
  }

  onTouchStart(e) {
    this.touchStartX = e.originalEvent.changedTouches[0].pageX;
    this.touchStartY = e.originalEvent.changedTouches[0].pageY;

    this.touchMoved = false;
  }

  setScrolling() {
    this.scrolling = true;
  }

  /**
   * @param delay In milliseconds, the amount of time to wait before
   * showing the blocking loader. Defaults to 200 ms. To show loader
   * immediately, pass in 0.
   */
  showBlockingLoader(delay = 200, showBackdrop = true) {
    if (this.screenView) {
      this.numShowBlockingLoaderRequests++;

      if (this.numShowBlockingLoaderRequests === 1) {
        this.blockingLoaderTimerId = _.delay(() => {
          $("#bb-app-blocking-loading-indicator").removeClass("hide");
          if (showBackdrop) {
            $("#bb-app-blocking-loading-indicator-backdrop").removeClass("transparent");
          } else {
            $("#bb-app-blocking-loading-indicator-backdrop").addClass("transparent");
          }
          this.loaderView.startAnimation();
        }, delay);
      }
    } else {
      console.log("request to show blocking loading indicator ignored, no screen yet");
    }
  }

  hideBlockingLoader(force = false) {
    if (this.blockingLoaderTimerId) {
      clearTimeout(this.blockingLoaderTimerId);
    }

    if (force) {
      this.numShowBlockingLoaderRequests = 0;
    }

    if (this.numShowBlockingLoaderRequests > 0) {
      this.numShowBlockingLoaderRequests--;
    }

    if (this.numShowBlockingLoaderRequests === 0) {
      $("#bb-app-blocking-loading-indicator").addClass("hide");
      $("#bb-app-blocking-loading-indicator-backdrop").removeClass("hide");
      this.loaderView.stopAnimation();
    }
  }

  refreshScreen(fragment) {
    console.log("Refreshing screen...");
    chan("controllerView").stopReplying();
    refreshRoute(fragment);
  }

  onConnectionStatusChange() {
    this.updateConnectionState();
  }

  updateConnectionState() {
    const isOnline = this.connectionService.getConnectionStatus().isOnline;

    if (isOnline) {
      this.$el.removeClass("offline");
    } else {
      this.$el.addClass("offline");
    }
  }

  close() {
    super.close();
    $(window).off("resize", this.triggerWindowResize);
    $(window).off("scroll", this.triggerScrollStop);
    $(window).off("scroll", this.triggerScrolling);
    $(window).off("scroll", this.setScrolling);
    $(window).off("click", this.triggerClick);
    $(document).off("touchstart", this.onTouchStart);
    $(document).off("touchmove", this.onTouchMove);
  }
}

export default AppView;
