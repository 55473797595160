import i18next from "i18next";

export const buildCommon = () => ({
  about: i18next.t("about", "About", { ns: "common" }),
  aboutUs: i18next.t("aboutUs", "About Us", { ns: "common" }),
  button: {
    accept: i18next.t("button.accept", "Accept", { ns: "common" }),
    add: i18next.t("button.add", "Add", { ns: "common" }),
    addFirstNote: i18next.t("Add First Note", { ns: "common" }),
    addNote: i18next.t("Add Note", { ns: "common" }),
    apply: i18next.t("button.apply", "Apply", { ns: "common" }),
    back: i18next.t("button.back", "Back", { ns: "common" }),
    biblioHome: i18next.t("Biblioboard Home", { ns: "common" }),
    breadcrumb: i18next.t("breadcrumb", { ns: "common" }),
    cancel: i18next.t("button.cancel", "Cancel", { ns: "common" }),
    change: i18next.t("button.change", "Change", { ns: "common" }),
    clear: i18next.t("button.clear", "Clear", { ns: "common" }),
    createAndAdd: i18next.t("button.createAndAdd", "Create and Add", {
      ns: "common",
    }),
    createProfile: i18next.t("button.createProfile", "Create Profile", {
      ns: "common",
    }),
    createYourProfile: i18next.t("button.createYourProfile", "Create Your Profile", { ns: "common" }),
    edit: i18next.t("button.edit", "Edit", { ns: "common" }),
    gridView: i18next.t("button.gridView", "Grid View", { ns: "common" }),
    less: i18next.t("button.less", "less", { ns: "common" }),
    listView: i18next.t("button.listView", "List View", { ns: "common" }),
    logOut: i18next.t("button.logOut", "Log out", { ns: "common" }),
    more: i18next.t("button.more", "more", { ns: "common" }),
    move: {
      toBottom: i18next.t("button.move.toBottom", "Move to bottom", {
        ns: "common",
      }),
      toTop: i18next.t("button.move.toTop", "Move to top", { ns: "common" }),
    },
    play: i18next.t("button.play", "Play", { ns: "common" }),
    publish: i18next.t("button.publish", "Publish", { ns: "common" }),
    refresh: i18next.t("button.refresh", "Refresh", { ns: "common" }),
    remove: i18next.t("button.remove", "Remove", { ns: "common" }),
    reply: i18next.t("button.reply", "Reply", { ns: "common" }),
    reset: i18next.t("Reset", { ns: "common" }),
    resetFilters: i18next.t("button.resetFilters", "Reset Filters", {
      ns: "common",
    }),
    save: i18next.t("button.save", "Save", { ns: "common" }),
    saveEdit: i18next.t("button.saveEdit", "Save Edit", { ns: "common" }),
    seeResults: i18next.t("button.seeResults", "See Results", { ns: "common" }),
    selected: i18next.t("button.selected", "Selected", { ns: "common" }),
    signIn: i18next.t("button.signIn", "Sign In", { ns: "common" }),
    skipToContent: i18next.t("button.skipToContent", "Skip to content", {
      ns: "common",
    }),
    skipToFooter: i18next.t("button.skipToFooter", "Skip to footer", {
      ns: "common",
    }),
    sortBy: {
      newest: i18next.t("button.sortBy.newest", "Sort by Newest", {
        ns: "common",
      }),
      oldest: i18next.t("button.sortBy.oldest", "Sort by Oldest", {
        ns: "common",
      }),
    },
    submit: i18next.t("button.submit", "Submit", { ns: "common" }),
    switch: i18next.t("button.switch", "Switch", { ns: "common" }),
    takeMeBack: i18next.t("button.takeMeBack", "Take me back", {
      ns: "common",
    }),
    toggleFilter: i18next.t("button.toggleFilter", "Toggle filter", {
      ns: "common",
    }),
    unpublish: i18next.t("button.unpublish", "Unpublish", { ns: "common" }),
    view: i18next.t("button.view", "View", { ns: "common" }),
    visitHome: i18next.t("button.visitHome", "Visit the homepage", {
      ns: "common",
    }),
  },
  actions: {
    addNameToMedia: i18next.t("Add {{name}} to {{media}}", { ns: "common" }),
    addToMedia: i18next.t("Add to {{media}}", { ns: "common" }),
    added: i18next.t("actions.added", "added", { ns: "common" }),
    browse: i18next.t("actions.browse", "Browse", { ns: "common" }),
    browseName: name =>
      i18next.t("actions.browseName", "Browse {{name}}", {
        name,
        ns: "common",
      }),
    changePassword: i18next.t("actions.changePassword", "Change Password", {
      ns: "common",
    }),
    close: i18next.t("actions.close", "Close", { ns: "common" }),
    confirm: i18next.t("actions.confirm", "Confirm", { ns: "common" }),
    download: {
      chapter: i18next.t("actions.download.chapter", "Download Chapter", {
        ns: "common",
      }),
    },
    explore: {
      singular: i18next.t("actions.explore.singular", "Explore", {
        ns: "common",
      }),
      name: i18next.t("Explore {{name}} {{media}}", { ns: "common" }),
    },
    favorite: i18next.t("Favorite", { ns: "common" }),
    listen: i18next.t("actions.listen", "Listen", { ns: "common" }),
    post: i18next.t("actions.post", "Post", { ns: "common" }),
    read: i18next.t("actions.read", "Read", { ns: "common" }),
    share: i18next.t("Share", { ns: "common" }),
    shareName: i18next.t("Share {{name}}", { ns: "common" }),
    shareIt: i18next.t("actions.shareIt", "Share it!", { ns: "common" }),
    toggleNav: i18next.t("actions.toggleNav", "Toggle navigation", {
      ns: "common",
    }),
    unfavorite: i18next.t("Unfavorite", { ns: "common" }),
    watch: i18next.t("actions.watch", "Watch", { ns: "common" }),
  },
  advanced: i18next.t("advanced", "Advanced", { ns: "common" }),
  click: i18next.t("Click", { ns: "common" }),
  contactUs: i18next.t("Contact Us", { ns: "common" }),
  email: i18next.t("email", "email", { ns: "common" }),
  follow: {
    blog: i18next.t("follow.blog", "Check out our Blog", { ns: "common" }),
    social: socialMedia =>
      i18next.t("follow.social", "Follow us on {{socialMedia}}", {
        socialMedia,
        ns: "common",
      }),
  },
  gettingStarted: i18next.t("gettingStarted", "Getting Started", {
    ns: "common",
  }),
  here: i18next.t("Here", { ns: "common" }),
  home: i18next.t("home", "Home", { ns: "common" }),
  keepUp: i18next.t("keepUp", "Keep Up", { ns: "common" }),
  legalNotices: i18next.t("legalNotices", "Legal Notices", { ns: "common" }),
  link: i18next.t("link", "Link", { ns: "common" }),
  status: {
    loading: i18next.t("status.loading", "Loading...", { ns: "common" }),
    pending: i18next.t("Pending", { ns: "common" }),
  },
  moreInformation: i18next.t("moreInformation", "More Information", {
    ns: "common",
  }),
  page: i18next.t("page", "page", { ns: "common" }),
  password: i18next.t("password", { ns: "common" }),
  pin: i18next.t("pin", "Pin", { ns: "common" }),
  preview: i18next.t("preview", "Preview", { ns: "common" }),
  support: i18next.t("support", "Support", { ns: "common" }),
  tweet: i18next.t("tweet", "Tweet", { ns: "common" }),
  using: i18next.t("using", "Using", { ns: "common" }),
  usingName: name => i18next.t("usingName", "Using {{name}}", { name, ns: "common" }),
});
