import _ from "lodash";
import L from "lodash/fp";
import BaseFormView from "./baseFormView";
import CurationModel from "scripts/models/curationModel";
import CurationMultiSelectModel from "scripts/models/curationMultiSelectModel";
import inject from "scripts/ioc/inject";

import templateCreateCurationForm from "templates/createCurationForm.hbs";

class CreateCurationFormView extends BaseFormView {
  get template() {
    return templateCreateCurationForm;
  }

  get events() {
    return {
      "blur form *[name]": "onBlurValidate",
      "input form *[name]": "onInputEnableSubmit",
      "submit form": "onSubmit",
      "click #bb-new-curation-form-curation-list": "onClickCurationList",
    };
  }

  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.mediaModel = options.mediaModel;

    this.bindModel(new CurationModel());
  }

  render() {
    const { cancel, createAndAdd } = L.get(
      ["button"],
      this.i18nextService.getCommon(),
    );
    const curationTitle = L.get(
      ["placeholder", "curation", "title"],
      this.i18nextService.getAttributes(),
    );
    const { content, curation } = this.i18nextService.getGlossary();

    this.$el.html(
      this.template({
        i18n: {
          cancel,
          createAndAdd,
          curationTitle: curationTitle(content.title),
          returnToList: curation.returnToList,
        },
        maxTitleLength: this.model.maxTitleLength,
      }),
    );

    this.loaderView
      .appendTo(this.$el.find(".bb-form-submit-button-loader-region"))
      .render();

    this.enableSubmit(this.isValid());

    return this;
  }

  getMediaThumbnailUrl() {
    const media = this.mediaModel.get("media")[0];
    return media.get("thumbnailUrl") || media.get("iconUrl");
  }

  save() {
    this.clearErrors();

    // Set curation thumbnail to first media added thumbnail
    const data = _.merge(
      {},
      {
        thumbnailUrl: this.getMediaThumbnailUrl(),
      },
      this.toJSON(),
    );

    return this.model
      .save(data, {
        type: "POST",
      })
      .then(this.addMediaToCuration.bind(this));
  }

  addMediaToCuration(curationModel) {
    return new CurationMultiSelectModel().save(
      {
        curation: curationModel.get("id"),
        ids: _.map(this.mediaModel.get("media"), i => i.get("id")),
      },
      {
        type: "POST",
      },
    );
  }

  onSubmitSucceeded() {
    this.enableSubmit(false);
    this.trigger("success", this.model.get("id"));
  }

  onClickCurationList() {
    this.trigger("curationList");
  }
}

export default CreateCurationFormView;
