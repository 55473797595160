import L from "lodash/fp";
import FetchModel from "scripts/models/fetchModel";
import LogoutAuthenticationModel from "scripts/models/logoutAuthenticationModel";
import LogoutUserAuthenticationModel from "scripts/models/logoutUserAuthenticationModel";
import OrganizationAuthenticationModel from "scripts/models/organizationAuthenticationModel";
import OrganizationsVerificationsAuthenticationCollection from "scripts/collections/organizationsVerificationsAuthenticationCollection";
import PatronVerificationAuthenticationModel from "scripts/models/patronVerificationAuthenticationModel";
import TokenAuthenticationModel from "scripts/models/tokenAuthenticationModel";
import UnauthorizedSyncError from "scripts/exceptions/unauthorizedSyncError";
import UserAuthenticationModel from "scripts/models/userAuthenticationModel";
import SessionSubjectModel from "scripts/models/sessionSubjectModel";
import { addQueryStringParams } from "scripts/utils/urlUtil";
import { fetchToJson, saveToJson } from "scripts/utils/modelHelpers";

const SECURITY_URL_ROOT = process.env.PATRON_UI_GENERAL_AUTHENTICATION_URL_ROOT;
const AUDIENCE = process.env.PATRON_UI_BIBLIO_AUDIENCE;

const getScopeForAuthScope = authScope => {
  if ("ohiolinkedu" === authScope) {
    return ["ohiolink", "ohiolinkejc", "infohio", "oplin"];
  } else if ("openresearchlibrary" === authScope) {
    return ["orl", "torl"];
  } else {
    return [authScope];
  }
};

export const securityUrl = (path, queryParams = {}) => {
  const url = `${SECURITY_URL_ROOT}${path}`;

  if (L.isEmpty(queryParams)) {
    return url;
  } else {
    return addQueryStringParams(queryParams, url);
  }
};

export const fetchSubjectFromSecurity = ({ token }) => {
  console.log("Fetching subject from security");
  const subjectModel = new SessionSubjectModel({ httpMethod: "POST", token });

  return saveToJson(subjectModel);
};

export const fetchUserForTokenFromSecurity = ({ authScope, token }) => {
  console.log(
    "Fetching user for token from security, authScope: %O, token: %O",
    authScope,
    token,
  );

  const authModel = new TokenAuthenticationModel({
    httpMethod: "GET",
    token,
  });

  if (authScope) {
    authModel.set("scope", getScopeForAuthScope(authScope));
  }

  return saveToJson(authModel);
};

export const fetchOrganizationUserFromSecurity = ({
  authScope,
  token,
  requestedUrl,
  referer,
  coords,
  userAgent,
}) => {
  console.log("Fetching organization user from security");
  console.log(
    "authScope: %O, token: %O, requestedUrl: %O, referer: %O, coords: %O, userAgent: %O",
    authScope,
    token,
    requestedUrl,
    referer,
    coords,
    userAgent,
  );

  const authModel = new OrganizationAuthenticationModel({
    httpMethod: "GET",
    requestedUrl: requestedUrl,
  });

  if (referer) {
    authModel.set("referer", referer);
  }

  if (authScope) {
    authModel.set("scope", getScopeForAuthScope(authScope));
  }

  if (token) {
    authModel.set("token", token);
  }

  if (coords) {
    authModel.set("latitude", coords.latitude);
    authModel.set("longitude", coords.longitude);
  }

  if (userAgent) {
    authModel.set("userAgent", navigator.userAgent);
  }

  return saveToJson(authModel);
};

export const fetchOrganizationUserUsingPatronCredentialsFromSecurity = ({
  authScope,
  organizationId,
  cardNumber,
  pin,
  userAgent,
}) => {
  console.log("Fetching organization using patron credentials from security");
  console.log(
    "authScope, organizationId: %O, cardNumber: %O, pin: %O, userAgent: %O",
    authScope,
    organizationId,
    cardNumber,
    pin,
    userAgent,
  );

  let authModel = new PatronVerificationAuthenticationModel({
    organizationId: organizationId,
    cardNumber: cardNumber,
  });

  if (pin) {
    authModel.set("pin", pin);
  }

  if (authScope) {
    authModel.set("scope", getScopeForAuthScope(authScope));
  }

  if (userAgent) {
    authModel.set("userAgent", userAgent);
  }

  return saveToJson(authModel);
};

export const fetchUserWithUsernameAndPasswordFromSecurity = ({
  authScope,
  username,
  password,
  patronId,
  captcha,
  audience,
  userAgent,
}) => {
  console.log("Fetching user with username and password from security...");

  const authModel = new UserAuthenticationModel({
    username: username,
    password: password,
  });

  if (authScope) {
    authModel.set("scope", getScopeForAuthScope(authScope));
  }

  if (patronId) {
    authModel.set("patronId", patronId);
  }

  if (captcha) {
    authModel.set("captcha", captcha);
  }

  if (audience) {
    authModel.set("audience", audience);
  }

  if (userAgent) {
    authModel.set("userAgent", userAgent);
  }

  return saveToJson(authModel);
};

export const fetchOrganizationVerificationsFromSecurity = () =>
  fetchToJson(new OrganizationsVerificationsAuthenticationCollection());

export const invalidateTokenForUserId = (userId, token) => {
  console.log("Invalidating token for user id: %O", userId);

  return new LogoutUserAuthenticationModel({
    userId: userId,
    token: token,
  })
    .save()
    .catch(error => {
      if (!(error instanceof UnauthorizedSyncError)) {
        console.error("Error invalidating user session token: %O", error);
      }

      return null;
    });
};

export const invalidateTokenForOrganizationId = (organizationId, token) => {
  console.log("Invalidating token for organization id: %O", organizationId);

  return new LogoutAuthenticationModel({
    organizationId: organizationId,
    token: token,
  })
    .save()
    .catch(error => {
      if (!(error instanceof UnauthorizedSyncError)) {
        console.error(
          "Error invalidating organization session token: %O",
          error,
        );
      }

      return null;
    });
};

export const associateUserWithOrganization = (
  userToken,
  organizationToken,
  organizationId,
  userId,
  patronId,
) => {
  const model = new FetchModel({
    audience: AUDIENCE,
    token: userToken,
    url: securityUrl(`/users/${userId}/organizations/${organizationId}`, {
      token: organizationToken,
    }),
  });

  model.set("id", userId); // set an id to PUT instead of POST

  if (patronId) {
    model.set("patronId", patronId);
  }

  return model.save().then(m => m.toJSON());
};

export const disassociateUserFromOrganization = (
  userToken,
  organizationToken,
  organizationId,
  userId,
) => {
  const model = new FetchModel({
    audience: AUDIENCE,
    token: userToken,
    url: securityUrl(`/users/${userId}/organizations/${organizationId}`, {
      token: organizationToken,
    }),
  });

  model.set("id", userId); // set an id so that isNew is false and a DELETE is issued

  return model
    .destroy({
      contentType: "application/json",
    })
    .then(m => m.toJSON());
};

export const fetchOrganizationVerificationForAliasFromSecurity = alias =>
  fetchToJson(
    new FetchModel({
      url: securityUrl(`/organizations/alias/${alias}`),
    }),
  );

export const fetchUserFromSecurity = (userToken, userId) =>
  fetchToJson(
    new FetchModel({
      token: userToken,
      url: securityUrl(`/users/${userId}`),
    }),
  );

export const logSessionLocation = (
  sessionId,
  organizationId,
  timestamp,
  refererUrl,
  latitude,
  longitude,
) => {
  const model = new FetchModel({
    url: securityUrl("/session/location/log"),
  });

  model.set({
    sessionId,
    organizationId,
    timestamp,
    latitude,
    longitude,
    refererUrl,
    audience: AUDIENCE,
  });

  return model.save(
    {},
    {
      dataType: "text",
    },
  );
};
