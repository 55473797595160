import L from "lodash/fp";
import { sequencep } from "bluebird-promisell";
import FetchModel from "scripts/models/fetchModel";
import OrganizationProfileModel from "scripts/models/organizationProfileModel";
import ForbiddenSyncError from "scripts/exceptions/forbiddenSyncError";
import UserProfileModel from "scripts/models/userProfileModel";
import { addQueryStringParams } from "scripts/utils/urlUtil";
import { fetchToJson } from "scripts/utils/modelHelpers";

export const API_URL_ROOT = process.env.PATRON_UI_API_URL_ROOT;
export const AUDIENCE = process.env.PATRON_UI_BIBLIO_AUDIENCE;

export const apiUrl = (path, queryParams = {}) => {
  const url = `${API_URL_ROOT}${path}`;

  if (L.isEmpty(queryParams)) {
    return url;
  } else {
    return addQueryStringParams(queryParams, url);
  }
};

export const fetchFromApi = (token, path, queryParams = {}) =>
  new FetchModel({
    audience: AUDIENCE,
    token,
    url: apiUrl(path, queryParams),
  })
    .fetch()
    .then(m => m.toJSON());

export const fetchOrganizationProfileFromApi = ({
  token,
  organizationId,
  isPublic,
}) =>
  fetchToJson(
    new OrganizationProfileModel({
      token,
      id: organizationId,
      public: isPublic,
    }),
  );

export const fetchOrganizationProfilesFromApi = (token, organizationIds) =>
  sequencep(
    L.map(
      organizationId =>
        fetchOrganizationProfileFromApi({
          token,
          organizationId,
          isPublic: false,
        }),
      organizationIds,
    ),
  );

// user id is not currently used! so it'll be the profile assoc with token
export const fetchUserProfileFromApi = token =>
  fetchToJson(
    new UserProfileModel({
      token,
    }),
  );

export const fetchMediaMetadataFromApi = (token, organizationId, mediaId) =>
  fetchFromApi(token, `/media/${mediaId}`, { "org-id": organizationId });

export const fetchOpenMediaMetadataFromApi = mediaId =>
  fetchFromApi(null, `/media/${mediaId}`, {});

export const fetchCanOrganizationAccessMediaFromApi = L.curry(
  (token, mediaId, organizationId) =>
    fetchMediaMetadataFromApi(token, organizationId, mediaId)
      .then(() => true)
      .catch(error => {
        if (error instanceof ForbiddenSyncError) {
          return false;
        } else {
          throw error;
        }
      }),
);

export const fetchOrganizationsWithAccessToMediaFromApi = (
  token,
  mediaId,
  organizations,
) => {
  const canAccess = fetchCanOrganizationAccessMediaFromApi(token, mediaId);
  return sequencep(
    L.map(
      organization =>
        canAccess(organization.organizationId).then(
          access => (access ? organization : false),
        ),
      organizations,
    ),
  ).then(L.filter(L.identity));
};
