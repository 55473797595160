var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <select aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"category") : stack1), depth0))
    + "\" name=\"bisacCode\" class=\"chosen-select\" multiple data-placeholder=\" \">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <select aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"category") : stack1), depth0))
    + "\" disabled name=\"bisacCode\" class=\"chosen-select\" multiple\n                  data-placeholder=\" \">\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":40,"column":19}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <!--              <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data,"loc":{"start":{"line":35,"column":47},"end":{"line":35,"column":55}}}) : helper)))
    + "\" selected>"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":35,"column":66},"end":{"line":35,"column":75}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"count") || (depth0 != null ? lookupProperty(depth0,"count") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"count","hash":{},"data":data,"loc":{"start":{"line":35,"column":77},"end":{"line":35,"column":86}}}) : helper)))
    + ")</option>-->\n              <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data,"loc":{"start":{"line":36,"column":29},"end":{"line":36,"column":37}}}) : helper)))
    + "\" selected>"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":36,"column":48},"end":{"line":36,"column":57}}}) : helper)))
    + "</option>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <!--              <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data,"loc":{"start":{"line":38,"column":47},"end":{"line":38,"column":55}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":38,"column":57},"end":{"line":38,"column":66}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"count") || (depth0 != null ? lookupProperty(depth0,"count") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"count","hash":{},"data":data,"loc":{"start":{"line":38,"column":68},"end":{"line":38,"column":77}}}) : helper)))
    + ")</option>-->\n              <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data,"loc":{"start":{"line":39,"column":29},"end":{"line":39,"column":37}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":39,"column":39},"end":{"line":39,"column":48}}}) : helper)))
    + "</option>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <select aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"contentType") : stack1), depth0))
    + "\" name=\"omniType\" class=\"chosen-select\" multiple data-placeholder=\" \">\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <select aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"contentType") : stack1), depth0))
    + "\" disabled name=\"omniType\" class=\"chosen-select\" multiple\n                  data-placeholder=\" \">\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":56,"column":12},"end":{"line":60,"column":19}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data,"loc":{"start":{"line":57,"column":29},"end":{"line":57,"column":37}}}) : helper)))
    + "\" selected>"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":57,"column":48},"end":{"line":57,"column":57}}}) : helper)))
    + "</option>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data,"loc":{"start":{"line":59,"column":29},"end":{"line":59,"column":37}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":59,"column":39},"end":{"line":59,"column":48}}}) : helper)))
    + "</option>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <select aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"contributor") : stack1), depth0))
    + "\" name=\"contentCreatorName\" class=\"chosen-select\" multiple\n                  data-placeholder=\" \">\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <select aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"contributor") : stack1), depth0))
    + "\" disabled name=\"contentCreatorName\" class=\"chosen-select\" multiple\n                  data-placeholder=\" \">\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <select aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"publicationYear") : stack1), depth0))
    + "\" id=\"bb-advanced-search-publication-year\" name=\"contentDatePublished\"\n                    multiple\n                    data-placeholder=\" \"></select>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <select aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"publicationYear") : stack1), depth0))
    + "\" disabled id=\"bb-advanced-search-publication-year\"\n                    name=\"contentDatePublished\" multiple\n                    data-placeholder=\" \"></select>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <select aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"language") : stack1), depth0))
    + "\" id=\"bb-advanced-search-language\" name=\"contentLanguage\" class=\"chosen-select\"\n                  multiple data-placeholder=\" \">\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <select aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"language") : stack1), depth0))
    + "\" id=\"bb-advanced-search-language\" disabled name=\"contentLanguage\"\n                  class=\"chosen-select\" multiple data-placeholder=\" \">\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <select aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"publisher") : stack1), depth0))
    + "\" name=\"contentPublisherName\" class=\"chosen-select\" multiple\n                  data-placeholder=\" \">\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <select aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"publisher") : stack1), depth0))
    + "\" disabled name=\"contentPublisherName\" class=\"chosen-select\" multiple\n                  data-placeholder=\" \">\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <select aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"placeOfPublication") : stack1), depth0))
    + "\" name=\"contentPlaceOfPublication\" class=\"chosen-select\" multiple\n                  data-placeholder=\" \">\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <select aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"placeOfPublication") : stack1), depth0))
    + "\" disabled name=\"contentPlaceOfPublication\" class=\"chosen-select\"\n                  multiple data-placeholder=\" \">\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <select aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"licenseType") : stack1), depth0))
    + "\" name=\"contentLicenseType\" class=\"chosen-select\" multiple\n                  data-placeholder=\" \">\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <select aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"licenseType") : stack1), depth0))
    + "\" disabled name=\"contentLicenseType\" class=\"chosen-select\" multiple\n                  data-placeholder=\" \">\n";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <select aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"module") : stack1), depth0))
    + "\" name=\"modules\" class=\"chosen-select\" multiple\n                  data-placeholder=\" \">\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <select aria-label=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"module") : stack1), depth0))
    + "\" disabled name=\"modules\" class=\"chosen-select\"\n                  multiple data-placeholder=\" \">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"bb-advanced-search-page container m-tb-40\">\n  <form id=\"bb-advanced-search-form\">\n    <div class=\"row\">\n      <div class=\"col-xs-12 input-icon p-lr-10 m-b-20\">\n        <i class=\"bbico bbico-search\"></i>\n        <input id=\"bb-advanced-search-query\" class=\"form-control input-outline m-b-10 search-bar\" name=\"query\" type=\"text\" role=\"search\" aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"input") : stack1), depth0))
    + "\"\n               placeholder="
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"placeholder") : stack1)) != null ? lookupProperty(stack1,"search") : stack1), depth0))
    + " autocomplete=\"off\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"query") || (depth0 != null ? lookupProperty(depth0,"query") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"query","hash":{},"data":data,"loc":{"start":{"line":7,"column":81},"end":{"line":7,"column":90}}}) : helper)))
    + "\">\n\n        <!--<label>\n          <input type=\"checkbox\" id=\"bb-advanced-search-full-text-btn\" class=\"btn btn-outline-rounded-xs btn-chktoggle btn-chktoggle-checked\">\n          <span class=\"bbico bbico-check\"></span>\n          Full Text\n        </label>-->\n\n        <button id=\"bb-advanced-search-full-text-btn\" class=\"btn-outline btn-chktoggle btn-chktoggle-checked\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"fullText") : stack1), depth0))
    + "\n        </button>\n        <button id=\"bb-advanced-search-title-btn\" class=\"btn-outline btn-chktoggle btn-chktoggle-unchecked\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"titleOnly") : stack1), depth0))
    + "\n        </button>\n\n      </div>\n    </div>\n\n    <div class=\"row\">\n      <div class=\"column\">\n        <fieldset>\n          <legend>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"category") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"facets") : depth0)) != null ? lookupProperty(stack1,"bisacCode") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":27,"column":10},"end":{"line":32,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"facets") : depth0)) != null ? lookupProperty(stack1,"bisacCode") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":10},"end":{"line":41,"column":19}}})) != null ? stack1 : "")
    + "        </select>\n        </select>\n        </fieldset>\n      </div>\n      <div class=\"column\">\n        <fieldset>\n          <legend>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"contentType") : stack1), depth0))
    + "</legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"facets") : depth0)) != null ? lookupProperty(stack1,"omniType") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":49,"column":10},"end":{"line":54,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"facets") : depth0)) != null ? lookupProperty(stack1,"omniType") : stack1),{"name":"each","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":10},"end":{"line":61,"column":19}}})) != null ? stack1 : "")
    + "        </select>\n        </fieldset>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"column\">\n        <fieldset>\n          <legend>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"contributor") : stack1), depth0))
    + "</legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"facets") : depth0)) != null ? lookupProperty(stack1,"contentCreatorName") : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":70,"column":10},"end":{"line":76,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"facets") : depth0)) != null ? lookupProperty(stack1,"contentCreatorName") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":10},"end":{"line":85,"column":19}}})) != null ? stack1 : "")
    + "        </select>\n        </fieldset>\n      </div>\n      <div class=\"column\">\n        <fieldset>\n          <legend>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"publicationYear") : stack1), depth0))
    + "</legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"facets") : depth0)) != null ? lookupProperty(stack1,"contentDatePublished") : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":92,"column":10},"end":{"line":100,"column":17}}})) != null ? stack1 : "")
    + "        </fieldset>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"column\">\n        <fieldset>\n          <legend>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"language") : stack1), depth0))
    + "</legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"facets") : depth0)) != null ? lookupProperty(stack1,"contentLanguage") : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":108,"column":10},"end":{"line":114,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"facets") : depth0)) != null ? lookupProperty(stack1,"contentLanguage") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":10},"end":{"line":123,"column":19}}})) != null ? stack1 : "")
    + "        </select>\n        </fieldset>\n      </div>\n      <div class=\"column\">\n        <fieldset>\n          <legend>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"publisher") : stack1), depth0))
    + "</legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"facets") : depth0)) != null ? lookupProperty(stack1,"contentPublisherName") : stack1),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":130,"column":10},"end":{"line":136,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"facets") : depth0)) != null ? lookupProperty(stack1,"contentPublisherName") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":137,"column":10},"end":{"line":145,"column":19}}})) != null ? stack1 : "")
    + "        </select>\n        </fieldset>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"column\">\n        <fieldset>\n          <legend>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"placeOfPublication") : stack1), depth0))
    + "</legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"facets") : depth0)) != null ? lookupProperty(stack1,"contentPlaceOfPublication") : stack1),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(37, data, 0),"data":data,"loc":{"start":{"line":154,"column":10},"end":{"line":160,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"facets") : depth0)) != null ? lookupProperty(stack1,"contentPlaceOfPublication") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":161,"column":10},"end":{"line":169,"column":19}}})) != null ? stack1 : "")
    + "        </select>\n        </fieldset>\n      </div>\n      <div class=\"column\">\n        <fieldset>\n          <legend>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"licenseType") : stack1), depth0))
    + "</legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"facets") : depth0)) != null ? lookupProperty(stack1,"contentLicenseType") : stack1),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.program(41, data, 0),"data":data,"loc":{"start":{"line":176,"column":10},"end":{"line":182,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"facets") : depth0)) != null ? lookupProperty(stack1,"contentLicenseType") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":183,"column":10},"end":{"line":191,"column":19}}})) != null ? stack1 : "")
    + "        </select>\n        </fieldset>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"column\">\n        <fieldset>\n          <legend>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"module") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"facets") : depth0)) != null ? lookupProperty(stack1,"modules") : stack1),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.program(45, data, 0),"data":data,"loc":{"start":{"line":200,"column":10},"end":{"line":206,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"facets") : depth0)) != null ? lookupProperty(stack1,"modules") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":207,"column":10},"end":{"line":215,"column":19}}})) != null ? stack1 : "")
    + "        </select>\n        </fieldset>\n      </div>\n      <div class=\"column\"></div>\n    </div>\n    <div class=\"row\">\n      <div class=\"column search-submit\" style=\"display: flex; justify-content: flex-end;\">\n        <button type=\"submit\"\n                class=\"btn btn-rounded btn-solid-brand-primary m-tb-10 p-lr-20\"\n                style=\"width: 180px;\"\n                aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"submitButton") : stack1), depth0))
    + "\">\n          "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"search") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "\n        </button>\n      </div>\n    </div>\n  </form>\n</div>\n";
},"useData":true});