var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-body\">\n  <button class=\"close\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"close") : stack1), depth0))
    + "\" data-dismiss=\"modal\">\n    <i class=\"bbico bbico-times fnt-12\"></i>\n  </button>\n    <h2 class=\"modal-title p-t-30 p-b-20 text-center\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToCuration") : stack1), depth0))
    + "</h2>\n    <div id=\"bb-curation-add-media-form-region\" class=\"container-fluid\"></div>\n</div>";
},"useData":true});