import querystring from "querystring";
import Controller from "scripts/controllers/controller";
import VerifyEmailPageView from "scripts/views/verifyEmailPageView";
import VerifyEmailModel from "scripts/models/verifyEmailModel";

class VerifyEmailController extends Controller {
  get route() {
    return "route:verifyEmail";
  }

  replyRoute() {
    let params = querystring.parse(window.location.search.replace("?", ""));

    return this.showScreenView(
      new VerifyEmailPageView({
        model: new VerifyEmailModel(
          {},
          {
            accessKey: params.accessKey,
            signature: params.signature,
            username: params.username,
            emailAddress: params.email,
          },
        ),
      }),
    );
  }
}

export default VerifyEmailController;
