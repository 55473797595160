import _ from "lodash";
import L from "lodash/fp";
import Backbone from "backbone";
import querystring from "querystring";
import Url from "url";
import totp from "totp-generator";
import base32Encode from "base32-encode";

export const OAUTH_POST_USER_CREATION_URL =
  process.env.PATRON_UI_OAUTH_POST_USER_CREATION_URL;

export const isRelativeUrl = url => {
  return !/^https?:\/\/|^\/\//i.test(url);
};

/**
 * Given a URI, add query string or update an existing one.
 * See http://stackoverflow.com/questions/5999118/add-or-update-query-string-parameter?lq=1
 */
const updateQueryStringParam = (uri, key, value) => {
  const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  const separator = uri.indexOf("?") !== -1 ? "&" : "?";

  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
};

export const addQueryStringParams = L.curry((queryStringParams, url) => {
  _.forIn(queryStringParams, (value, key) => {
    url = updateQueryStringParam(url, key, value);
  });

  return url;
});

export const getParameterFromQueryString = L.curry((parameter, query) => {
  const urlParameters = querystring.parse(query);

  if (urlParameters[parameter]) {
    return decodeURIComponent(urlParameters[parameter]);
  }
});

export const parseQueryString = queryString =>
  queryString ? querystring.parse(decodeURIComponent(queryString)) : [];

export const getUrlParameter = L.curry((parameter, url) => {
  const urlObject = Url.parse(url);
  const urlParameters = querystring.parse(urlObject.query);

  if (urlParameters[parameter]) {
    return decodeURIComponent(urlParameters[parameter]);
  }
});

export const removeUrlParameter = L.curry((parameter, url) => {
  const urlObject = Url.parse(url);
  const urlParameters = querystring.parse(urlObject.query);

  if (urlParameters[parameter]) {
    delete urlParameters[parameter];
  }

  urlObject.search = querystring.stringify(urlParameters);

  return Url.format(urlObject);
});

export const replaceCurrentUrl = newUrl => {
  window.history.replaceState(null, document.title, newUrl);
};

export const removeParameterFromCurrentUrl = parameter => {
  replaceCurrentUrl(removeUrlParameter(parameter, window.location.href));
};

export const normalizeFragment = fragment => {
  if (!L.isNil(fragment)) {
    return Backbone.History.prototype.getFragment(fragment);
  } else {
    return void 0;
  }
};

export const createAbsoluteUrl = fragment => {
  const anchor = document.createElement("a");
  anchor.href = `/${normalizeFragment(fragment)}`; // Returns absolute URL
  return anchor.href;
};

export const parseOAuthParamsFromUrl = url => {
  const urlObject = Url.parse(url);
  const {
    client_id,
    redirect_uri,
    scope,
    state,
    email_required,
  } = querystring.parse(urlObject.query);

  if (client_id && redirect_uri) {
    const params = {
      client_id,
      redirect_uri,
    };

    if (scope) {
      params.scope = scope;
    }

    if (state) {
      params.state = state;
    }

    if (email_required) {
      params.email_required = true;
    } else {
      params.email_required = false;
    }

    return params;
  } else {
    return {};
  }
};

export const parseRedirectFragmentFromUrl = url => {
  const urlObject = Url.parse(url);
  const { redirect_fragment } = querystring.parse(urlObject.query);

  if (redirect_fragment) {
    return redirect_fragment;
  } else {
    return null;
  }
};

export const oAuthRedirectUrl = (oAuthParams, token) =>
  `${OAUTH_POST_USER_CREATION_URL}&${querystring.stringify(
    oAuthParams,
  )}&token=${token}`;

export const getHostname = url => Url.parse(url).hostname;

export const parseUrl = Url.parse;

export const isProxyHostname = url => {
  const hostname = getHostname(url);

  return (
    !hostname.endsWith("0.0.0.0") &&
    !hostname.endsWith("127.0.0.1") &&
    !hostname.endsWith("localhost") &&
    !hostname.endsWith("biblioboard.com") &&
    !hostname.endsWith("openresearchlibrary.org")
  );
};

const encodeToUint8 = string => {
  const bytes = new ArrayBuffer(string.length);
  const bufView = new Uint8Array(bytes);
  for (let i = 0; i < string.length; i++) {
    bufView[i] = string.charCodeAt(i);
  }

  return bytes;
};

export const getCodedUrl = (url, uuid) => {
  const key = uuid.replaceAll("-", "");
  const bytes = encodeToUint8(key);
  const b32 = base32Encode(bytes, "RFC4648");

  // the below params *must* match what is on the server
  const code = totp(b32, {
    digits: 8,
    algorithm: "SHA-1",
    period: 2,
  });

  return `${url}&t=${code}`;
};
