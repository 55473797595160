import Backbone from "backbone";

import fallBackImage from "images/fallbacks/fallback_380_380.png";

class CategoryModel extends Backbone.Model {
  parse(response) {
    response.iconFallbackUrl = fallBackImage;
    return response;
  }
}

export default CategoryModel;
