import errorAlert from "scripts/alerts/errorAlert";
import BaseView from "scripts/views/baseView";
import ContentDetailsPageNoteFormView from "scripts/views/contentDetailsPageNoteFormView";
import templateContentDetailsPageNoteSubPage from "templates/contentDetailsPageNoteSubPage.hbs";

class ContentDetailsPageNoteSubPageView extends BaseView {
  get template() {
    return templateContentDetailsPageNoteSubPage;
  }

  constructor(options) {
    super(options);

    this.noteFormView = this.addSubView(
      new ContentDetailsPageNoteFormView({
        model: this.model,
      }),
    );

    this.noteFormView.on("success", this.onSaveNoteSuccess, this);
    this.noteFormView.on("failure", this.onSaveNoteFailure, this);
    this.noteFormView.on("cancel", this.onCancel, this);
  }

  render() {
    this.$el.html(this.template());

    this.noteFormView
      .appendTo(this.$el.find("#bb-content-details-page-note-form-region"))
      .render();

    return this;
  }

  onSaveNoteSuccess() {
    this.trigger("allNotes");
  }

  onSaveNoteFailure(error) {
    errorAlert(error).show();
  }

  onCancel() {
    this.trigger("allNotes");
  }
}

export default ContentDetailsPageNoteSubPageView;
