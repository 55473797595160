import Promise from "bluebird";
import Backbone from "backbone";
import BaseView from "scripts/views/baseView";
import BreadcrumbModel from "scripts/models/breadcrumbModel";
import CategoriesPageNavView from "scripts/views/categoriesPageNavView";
import { getCurrentFragment } from "scripts/utils/routerHelpers";

import templateCategoriesPage from "templates/categoriesPage.hbs";

const chan = Backbone.Radio.channel;

class CategoriesPageView extends BaseView {
  get template() {
    return templateCategoriesPage;
  }

  constructor(options) {
    super(options);

    this.navView = this.addSubView(
      new CategoriesPageNavView({
        model: this.model,
      }),
    );

    this.subPageView = this.addSubView(options.subPageView);

    this.model.on(
      "change:presentationType",
      this.onPresentationTypeChangeSaveUserPreference,
      this,
    );

    chan("controllerView").reply(
      "categoriesPageView",
      this.replyCategoriesPageView,
      this,
    );
  }

  replyCategoriesPageView() {
    return this;
  }

  render() {
    this.$el.html(this.template(this.model.toJSON()));

    this.navView
      .attachTo(this.$el.find("#bb-categories-page-sticky-nav-region"))
      .render();

    this.subPageView
      .attachTo(this.$el.find("#bb-categories-page-sub-page-region"))
      .render();

    return this;
  }

  sync() {
    return this.subPageView.fetch();
  }

  //TODO: make common
  showSubPageView(subPageView) {
    if (this.subPageViewPromise && this.subPageViewPromise.isPending()) {
      this.subPageViewPromise.cancel();
    }

    chan("display").trigger("bodyViewLoading");

    this.subPageViewPromise = this.showSubView(
      "subPageView",
      subPageView,
      this.$el.find("#bb-categories-page-sub-page-region"),
    )
      .catch(Promise.CancellationError, () => {
        // swallow cancellation
        console.debug("show sub page view cancelled");
      })
      .finally(value => {
        chan("display").trigger("bodyViewLoaded");

        if (this.navView.isStuck()) {
          $(window).scrollTop(this.navView.scrollTopToStick());
        }

        return value;
      });

    return this.subPageViewPromise;
  }

  getBreadcrumbInfo() {
    return {
      breadcrumbModel: new BreadcrumbModel({
        text: "Categories",
        path: "#" + getCurrentFragment(),
        topLevel: true,
      }),
    };
  }

  getDocumentTitle() {
    return "Categories";
  }
}

export default CategoriesPageView;
