import inject from "scripts/ioc/inject";
import BaseFormView from "scripts/views/baseFormView";
import NewUserProfileModel from "scripts/models/newUserProfileModel";
import templateCreateProfileForm from "templates/createProfileForm.hbs";

class CreateProfileFormView extends BaseFormView {
  constructor(
    options,
    securityService = inject("securityService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);

    this.securityService = securityService;
    this.i18nextService = i18nextService;

    const user = this.securityService.getUser();
    const patronId = this.securityService.patronId;
    const scope = [this.securityService.authScope];

    this.bindModel(
      new NewUserProfileModel({
        id: user.hasProfile() ? user.getUserId() : void 0,
        organizationId: user.getActiveOrganizationId(),
        patronId,
        scope,
      }),
    );
  }

  get template() {
    return templateCreateProfileForm;
  }

  get events() {
    return {
      "blur form *[name]": "onBlurValidate",
      "input form *[name]": "onInputEnableSubmit",
      "submit form": "onSubmit",
    };
  }

  render() {
    const { profile } = this.i18nextService.getGlossary();
    const { ariaLabel, placeholder } = this.i18nextService.getAttributes();
    const { button } = this.i18nextService.getCommon();

    this.$el.html(
      this.template({
        i18n: {
          createProfile: button.createProfile,
          emailAriaLabel: ariaLabel.email,
          placeholder,
          profile,
        },
      }),
    );

    this.loaderView
      .appendTo(this.$el.find(".bb-form-submit-button-loader-region"))
      .render();

    return this;
  }
}

export default CreateProfileFormView;
