var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li><button class=\"bb-btn-a comment-delete\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"comment") : stack1)) != null ? lookupProperty(stack1,"delete") : stack1), depth0))
    + "</button></li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"comment-edit-wrapper\">\n                <form class=\"comment-edit-form form-horizontal\">\n                    <div class=\"form-group\">\n                        <div class=\"col-xs-12\">\n                            <textarea class=\"form-control\" name=\"textMessage\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"textMessage") || (depth0 != null ? lookupProperty(depth0,"textMessage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"textMessage","hash":{},"data":data,"loc":{"start":{"line":33,"column":78},"end":{"line":33,"column":93}}}) : helper)))
    + "</textarea>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <div class=\"col-xs-12 text-right\">\n                            <button class=\"comment-edit-cancel btn btn-default\">"
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"cancel") : stack1), depth0))
    + "</button>\n                            <button class=\"comment-edit-submit btn btn-primary\">"
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"saveEdit") : stack1), depth0))
    + "</button>\n                        </div>\n                    </div>\n                </form>\n            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"comment-score\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"score") || (depth0 != null ? lookupProperty(depth0,"score") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"score","hash":{},"data":data,"loc":{"start":{"line":49,"column":52},"end":{"line":49,"column":61}}}) : helper)))
    + "</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"comment-edit\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"edit") : stack1), depth0))
    + "</li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"comment-reply\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"reply") : stack1), depth0))
    + "</li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"comment-body\">\n    <div class=\"comment-user\">\n        <img src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"author") : depth0)) != null ? lookupProperty(stack1,"avatarLarge") : stack1), depth0))
    + "\" alt=\"avatar\" class=\"comment-user-avatar\" />\n    </div>\n    <ul class=\"comment-menu\">\n        <li class=\"comment-toggle minus\"><i class=\"glyphicon glyphicon-minus\"></i></li>\n        <li class=\"comment-toggle plus\"><i class=\"glyphicon glyphicon-plus\"></i></li>\n        <li>&nbsp;|&nbsp;</li>\n        <li>\n            <div class=\"dropdown\">\n                <i class=\"glyphicon glyphicon-flag\" data-toggle=\"dropdown\"></i>\n                <ul class=\"dropdown-menu pull-right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"canDelete") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":15,"column":27}}})) != null ? stack1 : "")
    + "                    <li><button class=\"bb-btn-a comment-flag\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"comment") : stack1)) != null ? lookupProperty(stack1,"flag") : stack1), depth0))
    + "</button></li>\n                </ul>\n            </div>\n        </li>\n    </ul>\n    <header class=\"comment-header\">\n        <span><a>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"author") : depth0)) != null ? lookupProperty(stack1,"displayName") : stack1), depth0))
    + "</a></span>\n        &nbsp;-&nbsp;\n        <span class=\"comment-created\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"createdMessage") || (depth0 != null ? lookupProperty(depth0,"createdMessage") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"createdMessage","hash":{},"data":data,"loc":{"start":{"line":24,"column":38},"end":{"line":24,"column":56}}}) : helper)))
    + "</span>\n    </header>\n    <div class=\"comment-content-wrapper\">\n        <div class=\"comment-content\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"htmlMessage") || (depth0 != null ? lookupProperty(depth0,"htmlMessage") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"htmlMessage","hash":{},"data":data,"loc":{"start":{"line":27,"column":37},"end":{"line":27,"column":54}}}) : helper))) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"editable") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":44,"column":15}}})) != null ? stack1 : "")
    + "        <footer class=\"comment-footer\">\n            <ul class=\"comment-footer-menu clearfix\">\n                <li class=\"comment-vote\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"score") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":20},"end":{"line":50,"column":27}}})) != null ? stack1 : "")
    + "                    <span class=\"comment-like comment-vote-button\"><i class=\"glyphicon glyphicon-thumbs-up\"></i></span>\n                    <span class=\"comment-divider\">&nbsp;|&nbsp;</span>\n                    <span class=\"comment-dislike comment-vote-button\"><i\n                            class=\"glyphicon glyphicon-thumbs-down\"></i></span>\n                </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"editable") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":16},"end":{"line":58,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"canReply") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":16},"end":{"line":61,"column":23}}})) != null ? stack1 : "")
    + "            </ul>\n        </footer>\n    </div>\n    <div class=\"clearfix\"></div>\n</div>\n<div class=\"comment-reply-wrapper\"></div>\n<!--<div class=\"comment-children clearfix\"></div>-->";
},"useData":true});