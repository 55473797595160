import _ from "lodash";
import Backbone from "backbone";

class CurationPageModel extends Backbone.Model {
  toJSON() {
    const attributes = _(this.attributes).clone();
    attributes.presentationTypeToggleUrl = this.urlFor(
      attributes.presentationType,
    );
    attributes.presentationTypeToggleIcon =
      attributes.presentationType === "masonry"
        ? "glyphicon-list"
        : "glyphicon-th";

    return attributes;
  }

  urlFor(presentationType) {
    const mediaId = this.get("mediaId");

    let url;

    if (presentationType === "list") {
      url = `/curation/${mediaId}/masonry/`;
    } else if (presentationType === "masonry") {
      url = `/curation/${mediaId}/list/`;
    }

    return url;
  }
}

export default CurationPageModel;
