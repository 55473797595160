import BaseAuthenticationModel from "scripts/models/baseAuthenticationModel";

class TokenAuthenticationModel extends BaseAuthenticationModel {
  url() {
    return `${this.authenticationUrlRoot}/authentication`;
  }

  // parse(data) {
  //     if (data.organization) {
  //         console.log(`Parsing organization info for token: ${data.token}`);
  //
  //         return _.merge({}, {
  //             token: data.token,
  //             public: data.public,
  //             bot: data.bot,
  //         }, data.organization);
  //     } else {
  //         console.log(`Parsing user info for token: ${data.token}`);
  //
  //         return _.merge({}, {
  //             password: void 0,
  //             token: data.token,
  //             bot: data.bot,
  //         }, data.user);
  //     }
  // }
}

export default TokenAuthenticationModel;
