var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"search-facet-widget-container\">\n    <div id=\"bb-faceted-search-menu-options\"></div>\n    <div id=\"bb-faceted-search-menu-items\"></div>\n\n    <div id=\"bb-faceted-search-menu-actions\">\n        <button type=\"button\" class=\"btn-rounded btn-rounded-brand-primary btn-block btn-faceted-search-close-filters navbar-toggle collapsed\" data-toggle=\"collapse\" data-target=\"#bb-faceted-search-facet-menu-region\">\n            "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"seeResults") : stack1), depth0))
    + "\n        </button>\n    </div>\n</div>\n\n";
},"useData":true});