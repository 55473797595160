import faviconDefaultPng from "images/favicon.png";
import faviconDefaultIco from "images/favicon.ico";
import faviconOrlPng from "images/favicon_orl.png";
import faviconOrlIco from "images/favicon_orl.ico";
import faviconOlEbcPng from "images/favicon_ohiolink.png";
import faviconOlEbcIco from "images/favicon_ohiolink.ico";
import faviconOlEbcPng2 from "images/favicon_ohiolink_2.png";
import faviconOlEbcIco2 from "images/favicon_ohiolink_2.ico";
import mainMenuLogoBiblioboard from "images/logo-bb.png";
import mainMenuLogoOrl from "images/logo-orl.png";
import mainMenuLogoOlEbc from "images/logo-ol-ebc-main.png";
import footerLogoBiblioboard from "images/logo-bb-horo.png";
import footerLogoOlEbc from "images/logo-ol-ebc-footer.png";
import footerLogoOlEbc2 from "images/logo-ol-ebc-footer-2.png";
import mainMenuLogoTarget from "images/logo-target-main.png";
import footerLogoTarget from "images/logo-target-footer.png";
import mainMenuLogoTpp from "images/logo-tpp.png";

export const FAVICON_OPTIONS = {
  ORL: {
    ico: faviconOrlIco,
    png: faviconOrlPng,
  },
  OL: {
    ico: faviconOlEbcIco,
    png: faviconOlEbcPng,
  },
  OL2: {
    ico: faviconOlEbcIco2,
    png: faviconOlEbcPng2,
  },
  DEFAULT: {
    ico: faviconDefaultIco,
    png: faviconDefaultPng,
  },
};

export const MAIN_MENU_LOGO_OPTIONS = {
  OL: mainMenuLogoOlEbc,
  OL2: footerLogoOlEbc2,
  ORL: mainMenuLogoOrl,
  TARGET: mainMenuLogoTarget,
  DEFAULT: mainMenuLogoBiblioboard,
  TPP: mainMenuLogoTpp,
};

export const FOOTER_LOGO_OPTIONS = {
  OL: footerLogoOlEbc,
  OL2: footerLogoOlEbc2,
  ORL: mainMenuLogoOrl,
  TARGET: footerLogoTarget,
  DEFAULT: footerLogoBiblioboard,
  TPP: mainMenuLogoTpp,
};

export const getFaviconOption = faviconOptionName =>
  FAVICON_OPTIONS[faviconOptionName] ? FAVICON_OPTIONS[faviconOptionName] : FAVICON_OPTIONS.DEFAULT;

export const getMainMenuLogoOption = logoOptionName =>
  MAIN_MENU_LOGO_OPTIONS[logoOptionName] ? MAIN_MENU_LOGO_OPTIONS[logoOptionName] : MAIN_MENU_LOGO_OPTIONS.DEFAULT;

export const getFooterLogoOption = logoOptionName =>
  FOOTER_LOGO_OPTIONS[logoOptionName] ? FOOTER_LOGO_OPTIONS[logoOptionName] : FOOTER_LOGO_OPTIONS.DEFAULT;
