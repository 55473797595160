var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"content-details-nav\">\n    <div class=\"container\">\n        <div class=\"row-same-height row-full-height\">\n            <nav>\n                <div class=\"navigation-container\">\n                    <div class=\"tab-less-navigation-header\">\n                        <div class=\"navbar-header-container\">\n                            <div class=\"active-tab\">\n                                <div id=\"bb-content-details-page-nav-heading-details\" class=\"active\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"content") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</div>\n                            </div>\n                            <div class=\"collection-view-toggle\">\n                                <!--switch view type button-->\n                                <a id=\"bb-secondary-sticky-nav-presentation-type-toggle-list\"\n                                   aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"list") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                                   class=\"fade-on-hover hide\"\n                                   href=\"/curation/"
    + alias2(((helper = (helper = lookupProperty(helpers,"mediaId") || (depth0 != null ? lookupProperty(depth0,"mediaId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"mediaId","hash":{},"data":data,"loc":{"start":{"line":16,"column":51},"end":{"line":16,"column":62}}}) : helper)))
    + "/list/"
    + alias2(((helper = (helper = lookupProperty(helpers,"contentType") || (depth0 != null ? lookupProperty(depth0,"contentType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"contentType","hash":{},"data":data,"loc":{"start":{"line":16,"column":68},"end":{"line":16,"column":83}}}) : helper)))
    + "\">\n                                    <div><i class=\"bbico bbico-list m-r-5\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"list") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</div>\n                                </a>\n\n                                <a id=\"bb-secondary-sticky-nav-presentation-type-toggle-masonry\"\n                                   aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"grid") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                                   class=\"fade-on-hover hide\"\n                                   href=\"/curation/"
    + alias2(((helper = (helper = lookupProperty(helpers,"mediaId") || (depth0 != null ? lookupProperty(depth0,"mediaId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"mediaId","hash":{},"data":data,"loc":{"start":{"line":23,"column":51},"end":{"line":23,"column":62}}}) : helper)))
    + "/masonry/"
    + alias2(((helper = (helper = lookupProperty(helpers,"contentType") || (depth0 != null ? lookupProperty(depth0,"contentType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"contentType","hash":{},"data":data,"loc":{"start":{"line":23,"column":71},"end":{"line":23,"column":86}}}) : helper)))
    + "\">\n                                    <div><i class=\"fa fa-th m-r-5\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"grid") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</div>\n                                </a>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </nav>\n        </div>\n    </div>\n</div>\n";
},"useData":true});