import BaseSecondaryStickyView from "scripts/views/baseSecondaryStickyNavView";
import FacetedSearchQueryMenuView from "scripts/views/search/facetedSearchQueryMenuView";
import FacetedSearchFacetMenuView from "scripts/views/search/facetedSearchFacetMenuView";
import FacetedSearchSummaryMenuView from "scripts/views/search/facetedSearchSummaryMenuView";
import templateFacetedSearchResultsMenu from "templates/search/facetedSearchResultsMenu.hbs";

class FacetedSearchResultsMenuView extends BaseSecondaryStickyView {
  constructor(options) {
    super(options);

    this.resultCollection = options.resultCollection;

    //TODO: factor this render listener out of BaseSecondaryStickyNavView
    this.model.off(null, null, this);

    this.queryMenuView = this.addSubView(
      new FacetedSearchQueryMenuView({
        model: this.model,
        collection: this.collection,
      }),
    );

    this.facetMenuView = this.addSubView(
      new FacetedSearchFacetMenuView({
        model: this.model,
        collection: this.collection,
      }),
    );

    this.summaryMenuView = this.addSubView(
      new FacetedSearchSummaryMenuView({
        model: this.model,
        collection: this.resultCollection,
      }),
    );

    this.summaryMenuView.on("showSearchTermModal", () => {
      this.facetMenuView.showSearchTermModal();
    });

    this.summaryMenuView.on("sort", sortField => {
      this.facetMenuView.setSearchSort(sortField);
    });

    this.facetMenuView.on("click", facetParam => {
      this.queryMenuView.showFacetModal(facetParam);
    });

    this.summaryMenuView.on("refine", () => {
      this.toggleRefineMenu();
    });

    this.summaryMenuView.on("includeFacetTerms", (facetParam, termCodes) => {
      this.queryMenuView.includeFacetTerms(facetParam, termCodes);
    });
  }

  toggleRefineMenu() {
    $("#bb-faceted-search-facet-menu-region").toggleClass("collapsed");
  }

  expandRefineMenu() {
    $("#bb-faceted-search-facet-menu-region").removeClass("collapsed");
  }

  get template() {
    return templateFacetedSearchResultsMenu;
  }

  // get id() {
  //   return "bb-secondary-sticky-nav";
  // }

  get className() {
    return "faceted-search-results-menu";
  }

  update() {
    this.queryMenuView.update();
    this.facetMenuView.update();
    this.summaryMenuView.update();
  }

  render() {
    this.$el.html(this.template(this.model.toJSON()));

    this.queryMenuView
      .attachTo($("#bb-faceted-search-query-menu-region"))
      .render();

    this.facetMenuView
      .attachTo($("#bb-faceted-search-facet-menu-region"))
      .render();

    this.summaryMenuView
      .attachTo($("#bb-faceted-search-summary-menu-region"))
      .render();

    this.updatePosition();

    return this;
  }

  shouldStick() {
    return true;
  }

  //TODO: factor this out of BaseSecondaryStickyView
  updateLinkState() {}
}

export default FacetedSearchResultsMenuView;
