import inject from "scripts/ioc/inject";
import L from "lodash/fp";
import BaseFormView from "scripts/views/baseFormView";
import ForgotPasswordModel from "scripts/models/forgotPasswordModel";
import templateForgotPasswordForm from "templates/forgotPasswordForm.hbs";

class ForgotPasswordFormView extends BaseFormView {
  get hybridForgotPasswordEmailUrl() {
    return process.env.PATRON_UI_HYBRID_FORGOT_PASSWORD_EMAIL_URL;
  }

  get template() {
    return templateForgotPasswordForm;
  }

  get events() {
    return {
      "input form *[name]": "onInputEnableSubmit",
      "submit form": "onSubmit",
    };
  }

  constructor(
    options,
    securityService = inject("securityService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);

    this.securityService = securityService;
    this.i18nextService = i18nextService;

    const targetUrl = this.hybridForgotPasswordEmailUrl
      ? this.hybridForgotPasswordEmailUrl
      : `${window.location.origin}/login`;

    this.bindModel(
      new ForgotPasswordModel({
        targetUrl: targetUrl,
      }),
    );
  }

  render() {
    this.$el.html(this.template({ i18n: this.getTranslations() }));

    this.loaderView
      .appendTo(this.$el.find(".bb-form-submit-button-loader-region"))
      .render();

    return this;
  }

  getTranslations() {
    const glossary = this.i18nextService.getGlossary();
    const attributes = this.i18nextService.getAttributes();
    const password = L.flowRight([
      L.pick(["forgot", "send"]),
      L.get(["auth", "forgotPassword"]),
    ])(glossary);
    const placeholder = L.flowRight([
      L.pick(["enterEmail"]),
      L.get(["placeholder"]),
    ])(attributes);

    return L.merge(password, placeholder);
  }

  onSubmitSucceeded() {
    this.enableSubmit(true);
    this.trigger("success");
  }

  toJSON() {
    const json = super.toJSON();

    if (this.securityService.authScope) {
      json.scope = [this.securityService.authScope];
    }

    return json;
  }
}

export default ForgotPasswordFormView;
