var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"tile-thumb\">\n            <img class=\"bb-media-salvattore-tile-image\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"thumbnailUrl") || (depth0 != null ? lookupProperty(depth0,"thumbnailUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnailUrl","hash":{},"data":data,"loc":{"start":{"line":59,"column":61},"end":{"line":59,"column":77}}}) : helper)))
    + "\"\n                 data-fallback-src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"thumbnailFallbackUrl") || (depth0 != null ? lookupProperty(depth0,"thumbnailFallbackUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnailFallbackUrl","hash":{},"data":data,"loc":{"start":{"line":60,"column":36},"end":{"line":60,"column":60}}}) : helper)))
    + "\"\n                 alt=\""
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"thumbnailAlt") : stack1), depth0))
    + "\" />\n          </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "          <div class=\"tile-thumb-icon\">\n            <div class=\"bb-media-salvattore-tile-image\">\n              <i class=\"bbico bbico-audio\"></i>\n            </div>\n          </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div id=\"bb-media-details-highlight\" class=\"content-details-highlight-banner\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"highlight") : stack1), depth0))
    + "</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"blackMediaBadges") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":10},"end":{"line":83,"column":19}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"content-provider-badge content-provider-badge--tile\">\n              <img class=\"content-provider-badge__img--tile\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" alt=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"contentProvider") : stack1), depth0))
    + "\">\n            </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"h4 truncate-2\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"subtitle") || (depth0 != null ? lookupProperty(depth0,"subtitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"subtitle","hash":{},"data":data,"loc":{"start":{"line":93,"column":37},"end":{"line":93,"column":49}}}) : helper)))
    + "</div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"contributor-list\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"contributorList") || (depth0 != null ? lookupProperty(depth0,"contributorList") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"contributorList","hash":{},"data":data,"loc":{"start":{"line":100,"column":40},"end":{"line":100,"column":59}}}) : helper)))
    + "</div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"notEqual")||(depth0 && lookupProperty(depth0,"notEqual"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"languageIso639_2") : depth0),"zxx",{"name":"notEqual","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":4},"end":{"line":109,"column":17}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"tile-language\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"language") || (depth0 != null ? lookupProperty(depth0,"language") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"language","hash":{},"data":data,"loc":{"start":{"line":108,"column":33},"end":{"line":108,"column":45}}}) : helper)))
    + " "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"languageContent") : stack1), depth0))
    + "</div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <!-- awards -->\n    <div class=\"tile-awards-row\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"awardMediaBadges") : depth0),{"name":"each","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":6},"end":{"line":117,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <img class=\"tile-award-img\" src=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" alt=\"award badge\">        \n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--tile-->\n<div class=\"content-tile\">\n\n  <!-- header -->\n  <div class=\"tile-header\">\n    <div class=\"tile-header-content-type album-salvattore-title\">\n      <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 448 512\"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path fill=\"white\" d=\"M0 96C0 60.7 28.7 32 64 32l320 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM384 256A160 160 0 1 0 64 256a160 160 0 1 0 320 0zM224 224a32 32 0 1 1 0 64 32 32 0 1 1 0-64z\"/></svg>\n      "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"content") : stack1)) != null ? lookupProperty(stack1,"album") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0)) != null ? stack1 : "")
    + "\n    </div>\n\n\n    <div class=\"tile-header-actions\">\n      <!--offline bookshelf button-->\n      <div>\n        <button aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToBookshelf") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                class=\"btn-bookshelf circle-btn-mini hide\"\n                data-toggle=\"tooltip\"\n                title=\""
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToBookshelf") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0)) != null ? stack1 : "")
    + "\">\n          <i class=\"bbico bbico-offline-bookshelf\"></i>\n          <i class=\"bbico bbico-offline-bookshelf-filled\"></i>\n        </button>\n      </div>\n\n      <div class=\"btn-favorite\">\n        <button aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToFavorites") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                class=\"btn-favorite circle-btn-mini\"\n                data-toggle=\"tooltip\"\n                title=\""
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToFavorites") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0)) != null ? stack1 : "")
    + "\">\n          <i class=\"bbico bbico-favorite\"></i>\n          <i class=\"bbico bbico-favorite-filled\"></i>\n        </button>\n      </div>\n\n      <div>\n        <button aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToCuration") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                class=\"btn-curation-add circle-btn-mini hide\"\n                data-toggle=\"tooltip\"\n                title=\""
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToCuration") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0)) != null ? stack1 : "")
    + "\">\n          <i class=\"bbico bbico-add\"></i>\n        </button>\n      </div>\n    </div>\n\n  </div>\n\n  <div class=\"tile-main-body\">\n    <a href=\"/content/"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":47,"column":22},"end":{"line":47,"column":28}}}) : helper)))
    + "\" class=\"btn-detail tile-action\">\n\n\n      <!--image-->\n      <div class=\"media-image-container\">\n        <!--file size indicator-->\n        <div class=\"bb-file-size-region\"></div>\n        <!--download indicator-->\n        <div class=\"bb-download-progress-bar-region\"></div>\n        <!--media image-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"thumbnailUrl") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":57,"column":8},"end":{"line":69,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n\n      <div class=\"tile-banners\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"collectionHighlight") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":8},"end":{"line":75,"column":15}}})) != null ? stack1 : "")
    + "\n        <!--content provider badges-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"showBadges") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":8},"end":{"line":84,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n\n\n      <div class=\"title\">\n        <!--title-->\n        <div class=\"h3 truncate-2\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":90,"column":35},"end":{"line":90,"column":44}}}) : helper)))
    + "</div>\n        <!--subtitle-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"subtitle") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":92,"column":8},"end":{"line":94,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n\n      <!--metadata-->\n      <div class=\"tile-metadata\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"contributorList") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":8},"end":{"line":101,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n    </a>\n  </div>\n\n"
    + ((stack1 = (lookupProperty(helpers,"notEqual")||(depth0 && lookupProperty(depth0,"notEqual"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"languageIso639_2") : depth0),"eng",{"name":"notEqual","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":2},"end":{"line":110,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"showAwards") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":2},"end":{"line":119,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});