import L from "lodash/fp";
import Backbone from "backbone";
import Velocity from "velocity-animate";
import querystring from "querystring";
import { alert } from "scripts/alerts/alerts";
import errorAlert from "scripts/alerts/errorAlert";
import inject from "scripts/ioc/inject";
import BaseView from "scripts/views/baseView";
import CreateProfileFormView from "scripts/views/createProfileFormView";
import { passP } from "scripts/utils/generalHelpers";
import { redirectToFragment, parseFragment } from "scripts/utils/routerHelpers";
import {
  isRelativeUrl,
  oAuthRedirectUrl,
  parseOAuthParamsFromUrl,
} from "scripts/utils/urlUtil";
import { isPublicOrganization } from "scripts/utils/userHelpers";

import template from "templates/signUpCreateProfilePage.hbs";

const chan = Backbone.Radio.channel;

const getAppSchemeUrl = token =>
  `biblioboardlibrary://sign-up/verified/${token}`;

const getRedirectUrl = (originatingPlatform, url, user, isAuthScope) => {
  const oAuthParams = parseOAuthParamsFromUrl(url);

  if (isAuthScope) {
    if (L.isEmpty(oAuthParams)) {
      return "/home";
    } else {
      return oAuthRedirectUrl(oAuthParams, user.getToken());
    }
  } else {
    if (originatingPlatform === "mobile") {
      return "/about";
    } else if (L.isEmpty(oAuthParams)) {
      return "/manage-organizations";
    } else {
      if (
        L.all(
          L.compose(L.isNil, L.prop("oauthDisplayName")),
          user.getOrganizations(),
        )
      ) {
        return `/manage-organizations?${querystring.stringify(oAuthParams)}`;
      } else {
        return oAuthRedirectUrl(oAuthParams, user.getToken());
      }
    }
  }
};

class SignUpCreateProfilePageView extends BaseView {
  constructor(
    options,
    deviceService = inject("deviceService"),
    googleAnalyticsService = inject("googleAnalyticsService"),
    securityService = inject("securityService"),
    historyController = inject("historyController"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);

    this.originatingPlatform = options.platform;
    this.deviceService = deviceService;
    this.googleAnalyticsService = googleAnalyticsService;
    this.securityService = securityService;
    this.historyController = historyController;
    this.i18nextService = i18nextService;

    this.createProfileFormView = this.addSubView(new CreateProfileFormView());

    this.createProfileFormView.on("success", this.onFormSuccess, this);
    this.createProfileFormView.on("failure", this.onFormFailure, this);
  }

  get events() {
    return {
      "click #bb-sign-up-create-profile-cancel-btn": "onClickCancel",
    };
  }

  render() {
    this.$el.html(
      template({
        i18n: this.getTranslations(),
        isOAuth: !L.isEmpty(parseOAuthParamsFromUrl(window.location.href)),
      }),
    );

    this.createProfileFormView
      .htmlOf($("#bb-sign-up-create-profile-form-region"))
      .render();

    chan("display").trigger("window:resize");

    this.scrollToBody();

    const requestedRouteProps = parseFragment(
      this.historyController.getLastFragment(),
    );

    window.sessionStorage.setItem(
      "create-profile-initial-route-props",
      JSON.stringify(requestedRouteProps),
    );

    return this;
  }

  getTranslations() {
    const glossary = this.i18nextService.getGlossary();

    const auth = L.flowRight([
      L.pick(["createYourProfile", "noThanks", "unlock", "viewPrivacyPolicy"]),
      L.get(["auth"]),
    ])(glossary);
    const menu = L.flowRight([
      L.pick([
        "addNotes",
        "createBookmarks",
        "saveFavorites",
        "syncPreferences",
      ]),
      L.get(["menu"]),
    ])(glossary);

    return { auth, menu };
  }

  scrollToBody() {
    Velocity.animate($("#bb-authentication-screen-body-region"), "scroll", {
      duration: 1600,
      easing: "easeInOutQuint",
    });
  }

  onFormSuccess() {
    const organizationUser = this.securityService.getUser();

    const organizationToAssociate = L.filter(
      L.complement(isPublicOrganization),
      organizationUser.getOrganizations(),
    );

    this.googleAnalyticsService.sendEvent(
      "signUp",
      "success",
      "accountCreation",
      false,
    );

    const profileModel = this.createProfileFormView.model;

    const loginParams = {
      username: profileModel.get("username"),
      password: profileModel.get("password"),
    };

    if (this.originatingPlatform === "mobile") {
      loginParams.audience = "biblioboard-signup-hybrid";
    }

    chan("display").request("showBlockingLoader", 0);

    this.securityService
      .loginWithUsernameAndPassword(loginParams)
      .then(() =>
        this.securityService.associateUserWithOrganizations(
          organizationUser.getToken(),
          L.map(L.prop("organizationId"), organizationToAssociate),
        ),
      )
      .then(
        passP(() =>
          alert({
            title: "Profile Created!",
            text: "Your profile has been created!",
            type: "success",
          }).show(),
        ),
      )
      .then(() => this.redirect())
      .finally(() => {
        chan("display").request("hideBlockingLoader");
      });
  }

  redirect() {
    const user = this.securityService.getUser();

    const redirectUrl = getRedirectUrl(
      this.originatingPlatform,
      window.location.href,
      user,
      !!this.securityService.authScope,
    );

    if (isRelativeUrl(redirectUrl)) {
      redirectToFragment(redirectUrl);
    } else {
      window.location.href = redirectUrl;
    }

    if (this.originatingPlatform === "mobile") {
      setTimeout(() => {
        window.location.href = getAppSchemeUrl(user.getToken());
      }, 0);
    }
  }

  onFormFailure(error) {
    errorAlert(error).show();
  }

  onClickCancel() {
    const createProfileInitRouteProps = JSON.parse(
      window.sessionStorage.getItem("create-profile-initial-route-props"),
    );

    if (
      createProfileInitRouteProps &&
      createProfileInitRouteProps.route !== "whichOrganization"
    ) {
      redirectToFragment(createProfileInitRouteProps.fragment);
    } else {
      redirectToFragment("/home");
    }

    window.sessionStorage.removeItem("create-profile-initial-route-props");

    return false;
  }

  initialScrollTop() {
    return "bottom";
  }
}

export default SignUpCreateProfilePageView;
