import Backbone from "backbone";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";
import { alert } from "scripts/alerts/alerts";
import BaseModalView from "scripts/views/baseModalView";
import ForgotPasswordFormView from "scripts/views/forgotPasswordFormView";
import SignInModalView from "scripts/views/signInModalView";

import templateForgotPasswordModal from "templates/forgotPasswordModal.hbs";

const chan = Backbone.Radio.channel;

class ForgotPasswordModalView extends BaseModalView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.showRemember = options && options.showRemember;

    this.forgotPasswordFormView = this.addSubView(new ForgotPasswordFormView());

    this.forgotPasswordFormView.on("success", this.onSuccess, this);
    this.forgotPasswordFormView.on("failure", this.onFailure, this);
  }

  get template() {
    return templateForgotPasswordModal;
  }

  get events() {
    return {
      "click #bb-forgot-password-modal-remember-btn": "onClickRemember",
      'click *[data-dismiss="modal"]': "onClickDismiss",
    };
  }

  render() {
    this.$el.html(
      this.template({
        i18n: this.getTranslations(),
        showRemember: this.showRemember,
      }),
    );

    this.forgotPasswordFormView
      .appendTo(this.$el.find("#bb-forgot-password-modal-form-region"))
      .render();

    return this;
  }

  getTranslations() {
    const glossary = this.i18nextService.getGlossary();
    const attributes = this.i18nextService.getAttributes();
    const remember = L.flowRight([
      L.pick(["remember"]),
      L.get(["auth", "forgotPassword"]),
    ])(glossary);
    const close = L.flowRight([L.pick(["close"]), L.get(["ariaLabel"])])(
      attributes,
    );

    return L.merge(close, remember);
  }

  onClickRemember() {
    this.closeModal().then(() => {
      return chan("display").request("showModal", new SignInModalView());
    });

    return false;
  }

  onSuccess() {
    this.closeModal();

    const alertMsg =
      "<p>If our system recognizes the email you've provided, you'll receive an email shortly.</p>" +
      "<p>If you have not provided an email, or do not receive an email, contact " +
      '<a href="mailto:support@biblioboard.com">support@biblioboard.com</a></p>';

    alert({
      title: "Check your inbox!",
      text: alertMsg,
      html: true,
      type: "success",
    }).show();
  }

  onFailure() {
    alert(
      "Oops",
      "That didn't work. Are you sure you have the right email address?",
      "error",
    ).show();
  }
}

export default ForgotPasswordModalView;
