import BaseFormView from "scripts/views/baseFormView";
import templateContentDetailsPageNoteForm from "templates/contentDetailsPageNoteForm.hbs";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";

class ContentDetailsPageNoteFormView extends BaseFormView {
  get template() {
    return templateContentDetailsPageNoteForm;
  }

  get constraints() {
    return {
      text: {
        presence: true,
      },
    };
  }

  get events() {
    return {
      "click #bb-content-details-page-note-form-cancel": "onClickCancel",
      "blur form *[name]": "onBlurValidate",
      "input form *[name]": "onInputEnableSubmit",
      "submit form": "onSubmit",
    };
  }

  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.model.constraints = this.constraints;

    this.bindModel(this.model);
  }

  render() {
    const note = L.get(
      ["content", "details", "note"],
      this.i18nextService.getGlossary(),
    );

    const { cancel, submit } = L.get(
      ["button"],
      this.i18nextService.getCommon(),
    );

    this.$el.html(
      this.template(
        L.merge({ i18n: { cancel, note, submit } }, this.model.toJSON()),
      ),
    );

    this.loaderView
      .appendTo(this.$el.find(".bb-form-submit-button-loader-region"))
      .render();

    this.enableSubmit(this.isValid());

    return this;
  }

  onClickCancel() {
    this.trigger("cancel");
  }
}

export default ContentDetailsPageNoteFormView;
