import _ from "lodash";
import BaseListTileView from "scripts/views/baseListTileView";
import templateChapterListTile from "templates/chapterListTile.hbs";

class ChapterListTileView extends BaseListTileView {
  get template() {
    return templateChapterListTile;
  }

  get events() {
    return _.extend({}, super.events, {
      "click .btn-bookshelf": "onClickBookshelfButton",
    });
  }

  constructor(options) {
    super(options);

    if (this.bookshelfAvailable()) {
      this.initializeBookshelfDeps();
    }
  }

  onConnectionStatusChange() {
    super.onConnectionStatusChange();

    const parentContent = this.model.get("parentContent");
    if (parentContent) {
      const $parentTitleLink = this.$el.find(".bb-list-tile-parent-title-link");
      this.connectionService.isOnline()
        ? $parentTitleLink.attr("href", `/content/${parentContent.id}`)
        : $parentTitleLink.attr("href", "#");
    }
  }

  render() {
    const user = this.securityService.getUser();
    const modelData = this.model.toJSON();

    const templateData = _.merge(modelData, {
      viewerUrl: this.getViewerUrl(),
      detailUrl: this.getDetailUrl(),
      showBadges: user.getShowBadges(),
      parentContentTitle: modelData.parentContent ? modelData.parentContent.title : null,
      parentContentId: modelData.parentContent ? modelData.parentContent.id : null,
      thumbnailUrl: modelData.parentContent ? modelData.parentContent.thumbnailUrl : modelData.thumbnailUrl,
      downloadUrl: this.getDownloadUrl(),
      i18n: super.getTranslations(modelData),
    });

    this.$el.html(this.template(templateData));

    this.initDetailButton();
    this.initFavoriteButton();
    this.initCurationAddButton();
    this.initTooltips();
    this.initializeContributors();

    if (this.bookshelfAvailable()) {
      this.renderBookshelfElements();
    }

    this.initializeDownloadButton();

    this.initializeCurationMultiSelect();

    return this;
  }
}

export default ChapterListTileView;
