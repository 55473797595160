import _ from "lodash";
import L from "lodash/fp";
import HorizontalScrollerBehavior from "scripts/hoc/horizontalScrollerBehavior";
import BaseView from "scripts/views/baseView";
import inject from "scripts/ioc/inject";
import {
  facetLabelForFacetParam,
  facetParamOrder,
  facetNameForFacetParam,
  termLabelForPublicationDates,
  searchFields,
  termLabelsForTermCodes,
} from "scripts/views/search/facetMapper";
import FacetedSearchQueryPillView from "scripts/views/search/facetedSearchQueryPillView";

import templateFacetedSearchQueryMenu from "templates/search/facetedSearchQueryMenu.hbs";

class FacetedSearchQueryMenuView extends HorizontalScrollerBehavior(BaseView) {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.pillViews = new Map();
  }

  get template() {
    return templateFacetedSearchQueryMenu;
  }

  get id() {
    return "bb-faceted-search-query-menu";
  }

  get className() {
    return "faceted-search-query-menu";
  }

  get events() {
    return {
      "click .bb-horizontal-scroller-right-button": "onClickScrollRight",
      "click .bb-horizontal-scroller-left-button": "onClickScrollLeft",
      "click #faceted-search-query-menu-clear-btn": "onClickClear",
    };
  }

  getSearchField() {
    //assuming single search field - different from facets
    const queryParams = this.model.get("queryParams");
    const field = _.flow([
      _.partial(_.intersection, searchFields),
      _.partialRight(_.nth, 0),
    ])(Object.keys(queryParams));

    const term = queryParams[field];

    if (term) {
      const isFullText = queryParams["full-text"] === "true";
      return { field, term, isFullText };
    }
  }

  getTermsMap() {
    const queryParams = this.model.get("queryParams");
    const termsMap = new Map();

    Object.keys(queryParams).forEach(facetParam => {
      //key: facetParam, value: Array of termCodes
      if (
        !searchFields.includes(facetParam) &&
        facetParamOrder.includes(facetParam)
      ) {
        if (facetParam === "_pd") {
          termsMap.set(facetParam, queryParams[facetParam].split(" OR "));
        } else {
          termsMap.set(
            facetParam,
            queryParams[facetParam]
              .split('" OR "')
              .map(term => term.replace(/"/g, "")),
          );
        }
      }
    });

    return termsMap;
  }

  getTermsParams(termsMap) {
    const termsParams = {};

    Array.from(termsMap.keys()).forEach(queryParam => {
      termsParams[queryParam] = termsMap
        .get(queryParam)
        .map(term => {
          if (queryParam === "_pd") {
            return term;
          } else {
            return `"${term}"`;
          }
        })
        .join(" OR ");
    });

    return termsParams;
  }

  getQueryParams(searchField, termsMap) {
    const queryParams = {};

    if (searchField) {
      queryParams[searchField.field] = searchField.term;
      queryParams["full-text"] = searchField.isFullText ? "true" : "false";
    } else {
      queryParams["g"] = "*";
    }

    return _.merge(queryParams, this.getTermsParams(termsMap));
  }

  update() {
    this.render();
  }

  render() {
    if (!this.collection || !this.collection.facets) {
      return;
    }

    const i18n = this.getTranslations();

    const termsMap = this.getTermsMap();
    const searchField = this.getSearchField();

    this.$el.html(
      this.template({
        i18n,
        hasFilters: this.hasFilters(searchField, termsMap),
      }),
    );

    if (searchField) {
      if (searchField.isFullText || searchField.field === "t") {
        this.addSearchTermPill(searchField);
      }
    }

    Array.from(termsMap.keys()).forEach(facetParam => {
      const selectedTerms = termsMap.get(facetParam);
      selectedTerms.forEach(term => {
        this.addFacetPill(facetParam, term);
      });
    });

    this.initializeScrollerBehavior();
  }

  getTranslations() {
    return L.flowRight(
      L.pick("resetFilters"),
      L.get("button"),
    )(this.i18nextService.getCommon());
  }

  onClickClear() {
    const searchField = this.getSearchField();
    searchField.isFullText = false;
    searchField.field = "g";

    this.model.set("queryParams", this.getQueryParams(searchField, new Map()));
  }

  getFacetPillValue(facetParam, termCode) {
    if (facetParam === "_pd") {
      return termLabelForPublicationDates(termCode);
    } else {
      const termLabel = termLabelsForTermCodes(
        this.collection.facets,
        facetParam,
        termCode,
      );

      return termLabel[0];
    }
  }

  hasFilters(searchField, termsMap) {
    const hasAppliedTerms = termsMap.size > 0;

    if (searchField) {
      const hasAppliedSearchFilter =
        searchField.isFullText || searchField.field === "t";
      return hasAppliedTerms || hasAppliedSearchFilter;
    } else {
      return hasAppliedTerms;
    }
  }

  createSearchTermPill(searchField) {
    const value = searchField.isFullText ? "Full Text" : "Title Only";

    const pillView = new FacetedSearchQueryPillView({
      param: searchField.field === "t" ? "t" : "g",
      label: "Search",
      value: value,
      hasValues: true,
      totalRecords: _.numberFormat(this.collection.totalRecords),
    });

    pillView.on("close", ({ facetParam }) => {
      this.removeSearchTerm(facetParam);
    });

    return pillView;
  }

  removeSearchTerm(facetParam) {
    this.removeSearchTermPill(facetParam);
    this.model.set(
      "queryParams",
      this.getQueryParams(null, this.getTermsMap()),
    );
  }

  removeSearchTermPill(facetParam) {
    const pillView = this.pillViews.get(facetParam);
    this.pillViews.delete(facetParam);
    this.closeSubView(pillView);
  }

  addSearchTermPill(searchField) {
    const pillView = this.createSearchTermPill(searchField);

    this.pillViews.set("g", pillView);

    this.addSubView(pillView)
      .appendTo(this.$el.find(".bb-horizontal-scroller-content"))
      .render();
  }

  hasFacetValue(facetParam, termCode) {
    if (facetParam === "_pd") {
      return true;
    }
    const facet = this.collection.facets[facetNameForFacetParam(facetParam)];
    return facet && _.some(facet.facetValues, ["code", termCode]);
  }

  createFacetPill(facetParam, termCode) {
    const facetLabel =
      facetParam === "_pd"
        ? "Publication Year(s)"
        : facetLabelForFacetParam(facetParam);

    const pillView = new FacetedSearchQueryPillView({
      param: facetParam,
      label: facetLabel,
      value: this.getFacetPillValue(facetParam, [termCode]),
      code: termCode,
      hasValues: this.hasFacetValue(facetParam, termCode),
    });

    pillView.on("close", ({ facetParam, termCode }) => {
      this.removeTerm(facetParam, termCode);
    });

    return pillView;
  }

  removeTerm(facetParam, termCode) {
    this.removeFacetPill(termCode);

    const termsMap = this.getTermsMap();
    const previousTerms = termsMap.get(facetParam);
    const updatedTerms = _.without(previousTerms, termCode);

    _.isEmpty(updatedTerms)
      ? termsMap.delete(facetParam)
      : termsMap.set(facetParam, updatedTerms);

    this.model.set(
      "queryParams",
      this.getQueryParams(this.getSearchField(), termsMap),
    );

    // this.updateScrollButtonVisibility();
  }

  removeFacetPill(termCode) {
    const pillView = this.pillViews.get(termCode);
    this.pillViews.delete(termCode);
    this.closeSubView(pillView);
  }

  addFacetPill(facetParam, termCode) {
    const pillView = this.createFacetPill(facetParam, termCode);

    const key = facetParam === "_pd" ? facetParam : termCode;

    this.pillViews.set(key, pillView);

    this.addSubView(pillView)
      .appendTo(this.$el.find(".bb-horizontal-scroller-content"))
      .render();

    //TODO: allow this here
    //this.updateScrollButtonVisibility();
  }
}

export default FacetedSearchQueryMenuView;
