import BaseCollection from "scripts/collections/baseCollection";
import CommentModel from "scripts/models/commentModel";

const comparators = {
  sortNew: (first, second) =>
    second.get("created").getTime() - first.get("created").getTime(),
  sortOld: (first, second) =>
    first.get("created").getTime() - second.get("created").getTime(),
};

class CommentCollection extends BaseCollection {
  url() {
    const parameters = {
      limit: 50,
      forum: this.disqusModel.get("disqusForumName"),
      "thread:ident": this.disqusModel.get("disqusThread"),
      api_key: this.disqusModel.get("disqusKey"),
      order: this.sortOrder,
    };

    // Add the cursor if available
    if (this.hasNext) {
      parameters.cursor = this.nextCursor;
    }

    return `${process.env
      .PATRON_UI_DISQUS_URL_ROOT}/api/3.0/threads/listPosts.json?${$.param(
      parameters,
    )}`;
  }

  model(attrs, options) {
    attrs.disqusModel = options.collection.disqusModel;
    return new CommentModel(attrs, options);
  }

  initialize(models, options) {
    this.disqusModel = options.disqusModel;
    this.setSort("NEW");
    this.orphans = {};
  }

  parse(response) {
    this.hasNext = response.cursor.hasNext;
    this.nextCursor = response.cursor.next;

    return response.response;
  }

  create(model, options) {
    if (options.wait) {
      const callback = options.success || (() => {});

      options.success = () => {
        callback();
        this.trigger("create", model, options, { create: true });
      };
    }

    super.create(model, options);

    if (!options.wait) {
      this.trigger("create");
    }
  }

  setSort(order, reload) {
    if (order === "NEW") {
      this.comparator = comparators.sortNew;
      this.sortOrder = "desc";
    } else if (order === "OLD") {
      this.comparator = comparators.sortOld;
      this.sortOrder = "asc";
    }

    if (reload) {
      this.fetch({ reset: true });
    }
  }

  addChild(child) {
    const parentId = child.get("parentId");
    const parent = this.get(parentId);

    if (parent) {
      parent.addChild(child);
    } else {
      let siblings = this.orphans[parentId];
      siblings = siblings || [];
      siblings.push(child);

      this.orphans[parentId] = siblings;
    }
  }

  getChildren(parent) {
    const commentId = parent.get("commentId");
    const children = this.orphans[commentId];

    delete this.orphans[commentId];

    return children;
  }

  loadNext() {
    this.fetch({ remove: false, merge: true });
  }
}

export default CommentCollection;
