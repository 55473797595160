var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"cntntmast-thumb content-thumb\">\n                <!--file size indicator-->\n                <div class=\"bb-file-size-region\"></div>\n                <!--download indicator-->\n                <div class=\"bb-download-progress-bar-region\"></div>\n                <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"thumbnailUrl") || (depth0 != null ? lookupProperty(depth0,"thumbnailUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnailUrl","hash":{},"data":data,"loc":{"start":{"line":13,"column":26},"end":{"line":13,"column":42}}}) : helper)))
    + "\" alt=\""
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"thumbnailAlt") : stack1), depth0))
    + "\">\n                <meta itemprop=\"thumbnailUrl\" content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"thumbnailUrl") || (depth0 != null ? lookupProperty(depth0,"thumbnailUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnailUrl","hash":{},"data":data,"loc":{"start":{"line":14,"column":55},"end":{"line":14,"column":71}}}) : helper)))
    + "\" />\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"cntntmast-thumb-icon\">\n                <div class=\"bb-media-salvattore-tile-image\">\n                    <i class=\"bbico bbico-audio\"></i>\n                </div>\n            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <!-- play audio button -->\n                        <button id=\"bb-audio-details-player-button\"\n                            class=\"bb-init-audio-player btn-rounded btn-rounded-brand-primary btn-full\"\n                            aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"listen") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\">\n                            <span class=\"text-uppercase\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"play") : stack1), depth0))
    + "</span>\n                        </button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isAlbum") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":34,"column":20},"end":{"line":68,"column":20}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <!-- play album button -->\n                        <button id=\"bb-album-details-player-button\"\n                            class=\"bb-init-album-player btn-rounded btn-rounded-brand-primary btn-full\"\n                            aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"listen") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\">\n                            <span class=\"text-uppercase\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"play") : stack1), depth0))
    + "</span>\n                        </button>\n\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isAlbumTrack") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":42,"column":20},"end":{"line":68,"column":20}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <!-- play album button -->\n                        <button id=\"bb-album-details-player-button\"\n                            class=\"bb-init-album-player btn-rounded btn-rounded-brand-primary btn-full\"\n                            aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"listen") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\">\n                            <span class=\"text-uppercase\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"play") : stack1), depth0))
    + "</span>\n                        </button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isVideo") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":49,"column":20},"end":{"line":68,"column":20}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <!-- watch button -->\n                        <button id=\"bb-video-details-player-button\"\n                            class=\"bb-init-video-player btn-rounded btn-rounded-brand-primary btn-full\"\n                            aria-label=\"watch "
    + alias1(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":53,"column":46},"end":{"line":53,"column":55}}}) : helper)))
    + "\">\n                            <span class=\"text-uppercase\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"watch") : stack1), depth0))
    + "</span>\n                        </button>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <!--read button-->\n                        <a href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"viewerUrl") || (depth0 != null ? lookupProperty(depth0,"viewerUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"viewerUrl","hash":{},"data":data,"loc":{"start":{"line":58,"column":33},"end":{"line":58,"column":46}}}) : helper)))
    + "\" class=\"btn-rounded btn-rounded-brand-primary bb-content-details-viewer-button btn-full\"\n                        itemprop=\"url\" aria-label=\""
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"read") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0)) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAudioBook") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":60,"column":24},"end":{"line":66,"column":31}}})) != null ? stack1 : "")
    + "                        </a>\n                    ";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"text-uppercase\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"listen") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</span>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isImage") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":62,"column":24},"end":{"line":66,"column":24}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"text-uppercase\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"view") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</span>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"text-uppercase\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"read") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</span>\n                        ";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"purchaseLink") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":24},"end":{"line":87,"column":31}}})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div>\n                                <a id=\"bb-media-details-purchase-link\" href=\""
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"purchaseLink") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0)) != null ? stack1 : "")
    + "\" target=\"_blank\"\n                                    class=\"btn btn-text btn-text-brand-primary btn-full\"\n                                    aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"purchaseLink") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":83,"column":71},"end":{"line":83,"column":80}}}) : helper)))
    + "\">\n                                    "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"purchaseLink") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\n                                </a>\n                            </div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    return "                            <svg class=\"icon p-r-10\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 448 512\"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path fill=\"#127DA9\" d=\"M0 96C0 60.7 28.7 32 64 32l320 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM384 256A160 160 0 1 0 64 256a160 160 0 1 0 320 0zM224 224a32 32 0 1 1 0 64 32 32 0 1 1 0-64z\"/></svg>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <i class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"icon") : depth0)) != null ? lookupProperty(stack1,"class") : stack1), depth0))
    + " p-r-10\"></i>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"blackMediaBadges") : depth0),{"name":"each","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":32},"end":{"line":119,"column":41}}})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"content-provider-badge content-provider-badge--content-details p-lr-10\">\n                                        <img class=\"content-provider-badge__img--content-details content-details-badge-img\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" alt=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"contentProvider") : stack1), depth0))
    + "\" />\n                                    </div>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"p-lr-10\" aria-label=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"content") : stack1)) != null ? lookupProperty(stack1,"mediumFormat") : stack1), depth0))
    + "\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"mediumFormat") || (depth0 != null ? lookupProperty(depth0,"mediumFormat") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"mediumFormat","hash":{},"data":data,"loc":{"start":{"line":124,"column":96},"end":{"line":124,"column":112}}}) : helper)))
    + "</div>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <!-- share button -->\n                                <div class=\"media-details-button-wrapper\">\n                                    <button aria-label=\""
    + container.escapeExpression(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"share") : stack1), depth0))
    + "\" id=\"bb-media-details-share-button\"\n                                            class=\"btn-action brand-primary pointer\" data-toggle=\"tooltip\"\n                                            title=\""
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"share") : stack1), depth0)) != null ? stack1 : "")
    + "\">\n                                        <svg xmlns=\"http://www.w3.org/2000/svg\" height=\"1.1em\" viewBox=\"0 0 512 512\" fill=\"currentColor\">\n                                            <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->\n                                            <path d=\"M278.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L224 109.3V320c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l41.4 41.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-96-96zM64 112c0-8.8 7.2-16 16-16h8c17.7 0 32-14.3 32-32s-14.3-32-32-32H80C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H432c44.2 0 80-35.8 80-80V112c0-44.2-35.8-80-80-80h-8c-17.7 0-32 14.3-32 32s14.3 32 32 32h8c8.8 0 16 7.2 16 16V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112z\"/>\n                                        </svg>\n                                    </button>\n                                </div>\n";
},"38":function(container,depth0,helpers,partials,data) {
    return "";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <button aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToBookshelf") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\" id=\"bb-media-details-bookshelf-button\"\n                                        class=\"btn-bookshelf btn-action brand-primary pointer hide\"\n                                        data-toggle=\"tooltip\"\n                                        title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToBookshelf") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n                                            <i id=\"bb-media-details-bookshelf-button-icon\" class=\"bbico bbico-offline-bookshelf\"></i>\n                                </button>\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a href=\"/content/"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"parentMediaId") || (depth0 != null ? lookupProperty(depth0,"parentMediaId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"parentMediaId","hash":{},"data":data,"loc":{"start":{"line":194,"column":46},"end":{"line":194,"column":65}}}) : helper))) != null ? stack1 : "")
    + "\">\n                                <p class=\"chapter-book-title m-b-10\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"parentContentTitle") || (depth0 != null ? lookupProperty(depth0,"parentContentTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"parentContentTitle","hash":{},"data":data,"loc":{"start":{"line":195,"column":69},"end":{"line":195,"column":91}}}) : helper)))
    + "</p>\n                            </a>\n";
},"44":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"m-b-20\">\n                                <h2>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"subtitle") || (depth0 != null ? lookupProperty(depth0,"subtitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"subtitle","hash":{},"data":data,"loc":{"start":{"line":205,"column":36},"end":{"line":205,"column":48}}}) : helper)))
    + "</h2>\n                            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"bb-content-details\" itemscope itemtype=\"http://schema.org/"
    + alias4(((helper = (helper = lookupProperty(helpers,"itemtype") || (depth0 != null ? lookupProperty(depth0,"itemtype") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"itemtype","hash":{},"data":data,"loc":{"start":{"line":1,"column":67},"end":{"line":1,"column":79}}}) : helper)))
    + "\">\n\n    <!-- Thumbnail and Actions -->\n    <div class=\"content-details-column-left\">\n            <div class=\"content-details-content-thumbnail\">\n            <!-- content image-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"thumbnailUrl") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":22,"column":19}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"actions-container\">\n                <div class=\"m-b-5\">\n                    <!-- Action Buttons -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAudio") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":68,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n\n                <div class=\"rating-row p-tb-5\">\n                    <div class=\"content-details-rating\">\n                        <!--rating-->\n                        <div id=\"bb-content-details-rating-region\"></div>\n                    </div>\n                </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showBuyLinks") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":16},"end":{"line":88,"column":27}}})) != null ? stack1 : "")
    + "            </div>\n    </div>\n\n    <div class=\"content-details-column-right\">\n\n        <div id=\"content-details-container\" class=\"bg-details\">\n            <div class=\"container\">\n\n                <!-- Headline and Share -->\n                <!-- This moves between left and right column -->\n                <div class=\"content-details-content-headline\">\n                    <div class=\"content-details-content-headline-column-left\">\n                        <div class=\"headline-content-type icon-clr-primary p-r-20\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAlbum") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":103,"column":28},"end":{"line":107,"column":35}}})) != null ? stack1 : "")
    + "                            "
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"icon") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "\n                        </div>\n\n                        <div class=\"fb-row fb-ai-center\">\n                            <div id=\"bb-media-details-highlight\" class=\"content-details-highlight-banner\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"content") : stack1)) != null ? lookupProperty(stack1,"highlight") : stack1), depth0))
    + "</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showBadges") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":28},"end":{"line":120,"column":35}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"mediumFormat") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":28},"end":{"line":125,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n\n                <div class=\"content-details-share\">\n                        <div class=\"fb-row fb-justify-end fb-gap-5\">\n                            \n                            <!-- fav button-->\n                            <button aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToFavorite") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\" id=\"bb-media-details-favorite-button\"\n                                        class=\"btn-action brand-primary-original btn-favorite pointer\"\n                                    data-toggle=\"tooltip\"\n                                    title=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToFavorite") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n                                <i class=\"bbico bbico-favorite-filled\"></i>\n                                <i class=\"bbico bbico-favorite\"></i>\n                            </button>\n\n                            <!-- download button -->\n                            <div id=\"bb-media-details-download-button-wrapper\" class=\"media-details-button-wrapper\" class=\"hide\">\n                                <a id=\"bb-media-details-download-button\" aria-label=\""
    + ((stack1 = alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0)) != null ? stack1 : "")
    + "\"\n                                    class=\"btn-action brand-primary pointer\"\n                                    download href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"downloadUrl") || (depth0 != null ? lookupProperty(depth0,"downloadUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"downloadUrl","hash":{},"data":data,"loc":{"start":{"line":145,"column":51},"end":{"line":145,"column":66}}}) : helper)))
    + "\" target=\"_blank\"\n                                    rel=\"noreferrer noopener\"\n                                    data-toggle=\"tooltip\" title=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n                                    <svg xmlns=\"http://www.w3.org/2000/svg\" height=\"1.2em\" viewBox=\"0 0 384 512\" fill=\"currentColor\">\n                                        <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->\n                                        <path d=\"M360 480c13.3 0 24-10.7 24-24s-10.7-24-24-24L24 432c-13.3 0-24 10.7-24 24s10.7 24 24 24l336 0zM174.5 344.4c4.5 4.8 10.9 7.6 17.5 7.6s12.9-2.7 17.5-7.6l128-136c9.1-9.7 8.6-24.8-1-33.9s-24.8-8.6-33.9 1L216 267.5l0-83.5 0-128c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 128 0 83.5L81.5 175.6c-9.1-9.7-24.3-10.1-33.9-1s-10.1 24.3-1 33.9l128 136z\"/>\n                                    </svg>\n                                </a>\n                            </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showShareLinks") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":155,"column":28},"end":{"line":167,"column":35}}})) != null ? stack1 : "")
    + "\n                            <!-- curation add button -->\n                            <button aria-label=\""
    + ((stack1 = alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addNameToCuration") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0)) != null ? stack1 : "")
    + "\" id=\"bb-media-details-curation-button\"\n                                    class=\"btn-action brand-primary pointer hide\" data-toggle=\"tooltip\"\n                                    title=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToCuration") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n                                <i class=\"bbico bbico-add\"></i>\n                            </button>\n\n                            <!--offline bookshelf button-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAudioBook") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":177,"column":32},"end":{"line":185,"column":39}}})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n                </div>\n\n                <!--Title -->\n                <div class=\"row-same-height content-details-masthead\">\n                    <div class=\"content-details-content-title\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"parentContentTitle") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":193,"column":24},"end":{"line":197,"column":31}}})) != null ? stack1 : "")
    + "\n                        <div class=\"m-b-5\">\n                            <h1 itemprop=\"name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":200,"column":48},"end":{"line":200,"column":57}}}) : helper)))
    + "</h1>\n                        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"subtitle") : depth0),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":203,"column":24},"end":{"line":207,"column":31}}})) != null ? stack1 : "")
    + "\n                        <div id=\"contributorsList\" class=\"collapse\">\n                            <div id=\"bb-content-details-page-contributor-region\"></div>\n                        </div>\n                        <div id=\"contributorListBtn\">\n                            <button type=\"button\" class=\"btn-text btn-text-brand-primary collapsed\" data-toggle=\"collapse\" data-target=\"#contributorsList\">\n                            <span class=\"more\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"more") : stack1), depth0))
    + "</span>\n                            <span class=\"less\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"less") : stack1), depth0))
    + "</span>\n                            </button>\n                        </div>\n                    </div>\n\n                    <svg class=\"content-details-divider\" xmlns=\"http://www.w3.org/2000/svg\" width=\"100%\" height=\"2\" fill=\"none\">\n                        <line x1=\"100%\" y1=\"0%\" x2=\"0%\" y2=\"100%\" stroke=\"#CDCDCD\"/>\n                    </svg>\n                </div>\n            </div>\n\n            <div id=\"bb-media-details-sub-page-region\" class=\"content-details-sub-page-region\"></div>\n        </div>\n    </div>\n\n</div>";
},"useData":true});