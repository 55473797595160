import Backbone from "backbone";
import BaseApiModel from "scripts/models/baseApiModel";
import inject from "scripts/ioc/inject";
import UnauthorizedSyncError from "scripts/exceptions/unauthorizedSyncError";
import UnsentSyncError from "scripts/exceptions/unsentSyncError";

const chan = Backbone.Radio.channel;

class CounterModel extends BaseApiModel {
  constructor(attrs, options, deviceService = inject("deviceService")) {
    super(attrs, options);

    this.deviceService = deviceService;

    this.setDefaultModelProperties();
  }

  setDefaultModelProperties() {
    this.set({
      sessionId: chan("security")
        .request("user")
        .getToken(),
      platformType: this.deviceService.platform,
    });
  }

  url() {
    return `${this.apiUrlRoot}/counter`;
  }

  bugsnagError(error) {
    if (
      !(error instanceof UnauthorizedSyncError) &&
      !(error instanceof UnsentSyncError)
    ) {
      chan("bugsnag").request("notify", error);
    }

    throw error;
  }
}

export default CounterModel;
