import BaseView from "./baseView";

class CommentMasterView extends BaseView {
  constructor(options) {
    super(options);

    this.commentThreadModel = options.commentThreadModel;

    this.listenTo(
      this.commentThreadModel.get("comments"),
      "create",
      function() {
        this.updateCommentsCount("create");
      },
    );

    this.listenTo(
      this.commentThreadModel.get("comments"),
      "remove",
      function() {
        this.updateCommentsCount("remove");
      },
    );

    this.$loading = this.$el.find(".comments-loading");
    this.$navigation = this.$el.find(".comments-navigation");
    this.$content = this.$el.find(".tab-content");

    this.$commentsCount = this.$el.find(".comments-count");

    this.render();
  }

  render() {
    this.$el.removeClass("loading");

    this.$commentsCount.text(
      "%x Comment%s"
        .replace("%x", this.commentThreadModel.get("commentsCount"))
        .replace(
          "%s",
          this.commentThreadModel.get("commentsCount") === 1 ? "" : "s",
        ),
    );

    this.$loading.hide();
    this.$navigation.show();
    this.$content.show();

    return this;
  }

  updateCommentsCount(event) {
    if (event === "create") {
      this.commentThreadModel.set(
        "commentsCount",
        this.commentThreadModel.get("commentsCount") + 1,
      );
    } else if (event === "remove") {
      this.commentThreadModel.set(
        "commentsCount",
        this.commentThreadModel.get("commentsCount") - 1,
      );
    }

    this.render();
  }
}

export default CommentMasterView;
