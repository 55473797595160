import Backbone from "backbone";
import BaseModalView from "scripts/views/baseModalView";
import CurationAddMediaFormView from "scripts/views/curationAddMediaFormView";
import CreateCurationFormView from "scripts/views/createCurationFormView";
import L from "lodash/fp";
import inject from "scripts/ioc/inject";

import templateCurationAddMediaModal from "templates/curationAddMediaModal.hbs";

const chan = Backbone.Radio.channel;

class CurationAddMediaModalView extends BaseModalView {
  get template() {
    return templateCurationAddMediaModal;
  }

  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.createAddMediaFormView();
  }

  render() {
    const close = L.get(
      ["ariaLabel", "close"],
      this.i18nextService.getAttributes(),
    );

    const addToCuration = L.get(
      ["curation", "modal", "addToCuration"],
      this.i18nextService.getGlossary(),
    );

    this.$el.html(this.template({ i18n: { addToCuration, close } }));

    this.curationAddMediaFormView
      .appendTo($("#bb-curation-add-media-form-region"))
      .render();

    return this;
  }

  sync() {
    return this.curationAddMediaFormView.fetch();
  }

  createAddMediaFormView() {
    this.curationAddMediaFormView = this.addSubView(
      new CurationAddMediaFormView({
        model: this.model,
      }),
    );

    this.curationAddMediaFormView.on("success", this.onAddSuccess, this);
    this.curationAddMediaFormView.on("failure", this.onAddFailure, this);
    this.curationAddMediaFormView.on(
      "createCuration",
      this.onCreateCuration,
      this,
    );
  }

  onAddSuccess(curationId) {
    this.trigger("success", curationId);
    this.closeModal();
  }

  onAddFailure(error) {
    this.trigger("failure", error);
  }

  onCreateSuccess(curationId) {
    this.trigger("success", curationId);
    this.closeModal();
  }

  onCreateFailure(error) {
    this.trigger("failure", error);
  }

  setModalTitle(title) {
    this.$el.find(".modal-title ").text(title);
  }

  onCreateCuration() {
    this.showCreateCurationFormView();
  }

  showCreateCurationFormView() {
    this.curationAddMediaFormView.close();

    this.setModalTitle("Create a Curation");

    this.createCurationFormView = this.addSubView(
      new CreateCurationFormView({
        mediaModel: this.model,
      }),
    );

    this.createCurationFormView.on("success", this.onCreateSuccess, this);
    this.createCurationFormView.on("failure", this.onCreateFailure, this);
    this.createCurationFormView.on(
      "curationList",
      this.showAddMediaFormView,
      this,
    );

    this.createCurationFormView
      .appendTo($("#bb-curation-add-media-form-region"))
      .render();
  }

  showAddMediaFormView() {
    chan("display").request("showBlockingLoader");

    this.createAddMediaFormView();

    this.fetch().then(() => {
      this.createCurationFormView.close();

      this.render();

      chan("display").request("hideBlockingLoader");
    });
  }
}

export default CurationAddMediaModalView;
