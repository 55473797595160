import _ from "lodash";
import Backbone from "backbone";
import querystring from "querystring";
import Url from "url";
import inject from "scripts/ioc/inject";
import AdvancedSearchPageView from "scripts/views/advancedSearchPageView";
import Controller from "scripts/controllers/controller";
import FacetedSearchResultsPageView from "scripts/views/search/facetedSearchResultsPageView";
import { legacyToFacet } from "scripts/views/search/facetMapper";

const chan = Backbone.Radio.channel;

class SearchController extends Controller {
  constructor(securityService = inject("securityService")) {
    super();

    this.securityService = securityService;
  }

  get route() {
    return "route:searchResults route:advancedSearch";
  }

  get defaultState() {
    return {
      queryParams: [],
      presentationType: "masonry",
      pageIndex: 0,
    };
  }

  mapLegacyParamsToFacetParms(queryParams) {
    const facetQueryParams = {};

    Object.keys(queryParams).forEach(key => {
      const asFacet = legacyToFacet[key];

      if (asFacet) {
        facetQueryParams[asFacet] = queryParams[key];
      } else {
        facetQueryParams[key] = queryParams[key];
      }
    });

    return facetQueryParams;
  }

  parseRouteParameters(routeParameters) {
    let pageIndex = parseInt(routeParameters[2], 10);

    if (!isNaN(pageIndex)) {
      pageIndex = pageIndex - 1;
    } else {
      pageIndex = 0;
    }

    //const query = routeParameters[0];
    // work around, what appears to be, multiple decodings
    const urlObject = Url.parse(window.location.href);
    let query;
    if (process.env.PATRON_UI_PUSHSTATE === "true") {
      query = decodeURIComponent(urlObject.pathname.split("/")[2]);
    } else {
      query = decodeURIComponent(urlObject.hash.split("/")[1]);
    }

    return _.defaults(
      {
        queryParams: this.mapLegacyParamsToFacetParms(querystring.parse(query)),
        presentationType: routeParameters[1] || void 0,
        pageIndex: pageIndex,
      },
      {
        presentationType: this.securityService
          .getUser()
          .getContentViewPreference(),
      },
      this.defaultState,
    );
  }

  replyRoute(route, ...routeParameters) {
    if (route === "advancedSearch") {
      return this.showMainBodyView(new AdvancedSearchPageView());
    } else {
      const pageAttributes = this.parseRouteParameters(routeParameters);

      // const lastNavigationDirection = chan("history").request(
      //   "lastNavigationDirection",
      // );

      // if (lastNavigationDirection !== "back") {
      chan("tracking").trigger("search", pageAttributes);
      // }

      return this.showMainBodyView(
        new FacetedSearchResultsPageView({
          model: new Backbone.Model(pageAttributes),
        }),
      );
    }
  }
}

export default SearchController;
