import _ from "lodash";
import inject from "scripts/ioc/inject";
import BaseMediaSalvattoreTileView from "scripts/views/baseMediaSalvattoreTileView";
import templateChapterSalvattoreTile from "templates/chapterSalvattoreTile.hbs";

class ChapterSalvattoreTileView extends BaseMediaSalvattoreTileView {
  get template() {
    return templateChapterSalvattoreTile;
  }

  get events() {
    return _.extend({}, super.events, {
      "click .btn-bookshelf": "onClickBookshelfButton",
    });
  }

  constructor(options, securityService = inject("securityService")) {
    super(options, securityService);

    if (this.bookshelfAvailable()) {
      this.initializeBookshelfDeps();
    }
  }

  onConnectionStatusChange() {
    super.onConnectionStatusChange();

    this.$el.find(".tile-action").attr("href", this.getDetailUrl());
  }

  render() {
    const user = this.securityService.getUser();
    const modelData = this.model.toJSON();

    const templateData = _.merge(modelData, {
      viewerUrl: this.getViewerUrl(),
      detailUrl: this.getDetailUrl(),
      showBadges: user.getShowBadges(),
      showAwards: !_.isEmpty(modelData.awardMediaBadges),
      parentContentTitle: modelData.parentContent ? modelData.parentContent.title : null,
      thumbnailUrl: modelData.parentContent ? modelData.parentContent.thumbnailUrl : modelData.thumbnailUrl,
      downloadUrl: this.getDownloadUrl(),
      i18n: super.getTranslations(modelData),
    });

    this.$el.html(this.template(templateData));

    this.initDetailButton();
    this.initFavoriteButton();
    this.initCurationAddButton();
    this.initTooltips();

    if (this.securityService.getUser().get("bot")) {
      this.animateHoverState(0);
    }

    if (this.bookshelfAvailable()) {
      this.renderBookshelfElements();
    }

    this.initializeDownloadButton();

    this.initializeCurationMultiSelect();

    return this;
  }
}

export default ChapterSalvattoreTileView;
