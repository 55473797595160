import BaseView from "scripts/views/baseView";
import {
  facetLabelForFacetParam,
  facetParamForFacetName,
  facetParamOrder,
  searchFields,
  comboboxFacetParams,
} from "scripts/views/search/facetMapper";
import templateFacetedSearchFacetMenu from "templates/search/facetedSearchFacetMenu.hbs";
import templateFacetedSearchFacetMenuPlaceholder from "templates/search/facetedSearchFacetMenuPlaceholder.hbs";
import FacetedSearchComboBoxMultiSelectMenuItemView from "./facetedSearchComboBoxMultiSelectMenuItemView";
import FacetedSearchCheckboxMultiSelectMenuItemView from "./facetedSearchCheckboxMultiSelectMenuItemView";
import FacetedSearchSliderMenuItemView from "./facetedSearchSliderMenuItemView";

import _ from "lodash";
import inject from "scripts/ioc/inject";
import FacetedSearchTermModalView from "./facetedSearchTermModalView";
import FacetedSearchOptionsMenuItemView from "./facetedSearchOptionsMenuItemView";

const chan = Backbone.Radio.channel;

class FacetedSearchFacetMenuView extends BaseView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;
  }

  get template() {
    return templateFacetedSearchFacetMenu;
  }

  get id() {
    return "bb-faceted-search-facet-menu";
  }

  get className() {
    return "faceted-search-facet-menu";
  }

  update() {
    this.render();
  }

  render() {
    const i18n = {
      seeResults: _.get(this.i18nextService.getCommon(), [
        "button",
        "seeResults",
      ]),
    };

    if (!this.collection || !this.collection.facets) {
      this.$el.html(templateFacetedSearchFacetMenuPlaceholder);
      return;
    }
    const facets = Object.keys(this.collection.facets)
      .map(facetName => {
        const facetParam = facetParamForFacetName(facetName);
        const facetLabel = facetLabelForFacetParam(facetParam);

        if (facetParam && facetLabel) {
          const facetInfo = this.collection.facets[facetName];

          if (
            facetInfo &&
            facetInfo.facetValues &&
            facetInfo.facetValues.length
          ) {
            return {
              facetName,
              facetParam,
              facetLabel,
              facetValues: facetInfo.facetValues,
            };
          }
        }

        return null;
      })
      .filter(o => o)
      .sort((a, b) => {
        const aIndex = facetParamOrder.indexOf(a.facetParam);
        const bIndex = facetParamOrder.indexOf(b.facetParam);

        if (aIndex >= 0 && bIndex >= 0) {
          return aIndex - bIndex;
        } else if (aIndex > 0) {
          return -1;
        } else if (bIndex > 0) {
          return 1;
        } else {
          return a.facetLabel.localeCompare(b.facetLabel);
        }
      });

    this.$el.html(this.template({ i18n }));
    const searchField = this.getSearchField();

    const optionsView = new FacetedSearchOptionsMenuItemView({
      model: new Backbone.Model({ searchField }),
    });
    optionsView.on("apply", searchField => this.onSearchTermApply(searchField));
    optionsView.on("clearAll", () => this.onClearAll());

    this.addSubView(optionsView)
      .appendTo(this.$el.find("#bb-faceted-search-menu-options"))
      .render();

    const termsMap = this.getTermsMap();

    facets.forEach(facet => {
      const chosenTermCodes = termsMap.get(facet.facetParam) || [];
      const facetValues =
        (this.collection.facets[facet.facetName] || {}).facetValues || [];
      const model = new Backbone.Model({
        facetParam: facet.facetParam,
        facetLabel: facet.facetLabel,
        facetValues,
        chosenTermCodes,
      });

      if (facet.facetParam === "_pd") {
        const comboBoxView = new FacetedSearchSliderMenuItemView({ model });
        comboBoxView.on("apply", (facetParam, termCodes) =>
          this.onFacetApply(facetParam, termCodes),
        );

        this.addSubView(comboBoxView)
          .appendTo(this.$el.find("#bb-faceted-search-menu-items"))
          .render();
      } else if (comboboxFacetParams.includes(facet.facetParam)) {
        const comboBoxView = new FacetedSearchComboBoxMultiSelectMenuItemView({
          model,
        });
        comboBoxView.on("apply", (facetParam, termCodes) =>
          this.onFacetApply(facetParam, termCodes),
        );

        this.addSubView(comboBoxView)
          .appendTo(this.$el.find("#bb-faceted-search-menu-items"))
          .render();
      } else {
        const comboBoxView = new FacetedSearchCheckboxMultiSelectMenuItemView({
          model,
        });
        comboBoxView.on("apply", (facetParam, termCodes) =>
          this.onFacetApply(facetParam, termCodes),
        );

        this.addSubView(comboBoxView)
          .appendTo(this.$el.find("#bb-faceted-search-menu-items"))
          .render();
      }
    });
  }

  getTermsMap() {
    const queryParams = this.model.get("queryParams");
    const termsMap = new Map();

    Object.keys(queryParams).forEach(facetParam => {
      //key: facetParam, value: Array of termCodes
      if (
        !searchFields.includes(facetParam) &&
        facetParamOrder.includes(facetParam)
      ) {
        if (facetParam === "_pd") {
          termsMap.set(facetParam, queryParams[facetParam].split(" OR "));
        } else {
          termsMap.set(
            facetParam,
            queryParams[facetParam]
              .split('" OR "')
              .map(term => term.replace(/"/g, "")),
          );
        }
      }
    });

    return termsMap;
  }

  getSearchField() {
    //assuming single search field - different from facets
    const queryParams = this.model.get("queryParams");
    const field = _.flow([
      _.partial(_.intersection, searchFields),
      _.partialRight(_.nth, 0),
    ])(Object.keys(queryParams));

    const term = queryParams[field];

    if (term) {
      const isFullText = queryParams["full-text"] === "true";
      return { field, term, isFullText };
    }
  }

  getTermsParams(termsMap) {
    const termsParams = {};

    Array.from(termsMap.keys()).forEach(queryParam => {
      termsParams[queryParam] = termsMap
        .get(queryParam)
        .map(term => {
          if (queryParam === "_pd" || queryParam === "pd") {
            return term;
          } else {
            return `"${term}"`;
          }
        })
        .join(" OR ");
    });

    return termsParams;
  }

  getTotalAppliedTerms(searchField, termsMap) {
    if (searchField) {
      const selectedTerms = Array.from(termsMap.values());

      // Check if titleOnly or fullText facets are selected
      const { field, isFullText } = searchField;
      const total = field === "t" || isFullText ? 1 : 0;

      const totalAppliedTerms = selectedTerms.reduce(
        (total, selectedTerm) => total + selectedTerm.length,
        total,
      );

      return totalAppliedTerms;
    }
  }

  getQueryParams(searchField, termsMap, sortField) {
    const queryParams = {};

    if (searchField) {
      queryParams[searchField.field] = searchField.term;
      queryParams["full-text"] = searchField.isFullText ? "true" : "false";
    } else {
      queryParams["g"] = "*";
    }

    if (sortField) {
      queryParams["sort"] = true;
      queryParams["sort-fields"] = sortField;
    }

    return termsMap
      ? _.merge(queryParams, this.getTermsParams(termsMap))
      : queryParams;
  }

  showSearchTermModal() {
    if (!this.model.get("loading")) {
      const searchField = this.getSearchField();
      const term = searchField ? searchField.term : "";

      const modal = new FacetedSearchTermModalView({
        searchTerm: term,
      });

      modal.on("apply", searchTerm => this.onSearchModalApply(searchTerm));

      chan("display").request("showModal", modal);
    } else {
      console.log("Currently loading, ignoring search term modal click");
    }
  }

  onSearchTermApply(searchField) {
    this.model.set(
      "queryParams",
      this.getQueryParams(searchField, this.getTermsMap()),
    );
  }

  setSearchSort(sortField) {
    this.model.set(
      "queryParams",
      this.getQueryParams(this.getSearchField(), this.getTermsMap(), sortField),
    );
  }

  onSearchModalApply(searchTerm) {
    const term = searchTerm || "*";

    const existingSearchField = this.getSearchField();

    const newSearchField = existingSearchField
      ? _.merge(existingSearchField, { term: term })
      : { field: "g", term: searchTerm, isFullText: false };

    this.model.set(
      "queryParams",
      this.getQueryParams(newSearchField, this.getTermsMap()),
    );
  }

  onClearAll() {
    const searchField = this.getSearchField();
    searchField.isFullText = false;
    searchField.field = "g";

    this.model.set("queryParams", this.getQueryParams(searchField));
  }

  onFacetApply(facetParam, termCodes) {
    if (termCodes && termCodes.length) {
      this.includeFacetTerms(facetParam, termCodes);
    } else {
      this.removeFacetParam(facetParam);
    }
  }

  includeFacetTerms(facetParam, termCodes) {
    const termsMap = this.getTermsMap();
    termsMap.set(facetParam, termCodes);

    this.model.set(
      "queryParams",
      this.getQueryParams(this.getSearchField(), termsMap),
    );
  }

  removeFacetParam(facetParam) {
    const termsMap = this.getTermsMap();
    termsMap.delete(facetParam);

    this.model.set(
      "queryParams",
      this.getQueryParams(this.getSearchField(), termsMap),
    );
  }
}

export default FacetedSearchFacetMenuView;
