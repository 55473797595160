import i18next from "i18next";

export const buildAttributes = () => ({
  alt: {
    singlePageViewTitle: i18next.t("{{title}} - Single Page View", {
      ns: "attributes",
    }),
    amazon: i18next.t("alt.amazon", "Amazon", { ns: "attributes" }),
    appStore: i18next.t("alt.appStore", "App Store", { ns: "attributes" }),
    biblioPatron: i18next.t("BiblioBoard Patron", { ns: "attributes" }),
    biblioPublic: i18next.t("BiblioBoard Public", { ns: "attributes" }),
    contentProvider: i18next.t("alt.contentProvider", "Content provider", {
      ns: "attributes",
    }),
    error: {
      forbidden: i18next.t("alt.error.forbidden", "Error 403", {
        ns: "attributes",
      }),
      notFound: i18next.t("alt.error.notFound", "Error 404", {
        ns: "attributes",
      }),
      subscription: i18next.t("alt.error.subscription", "Subscription ", {
        ns: "attributes",
      }),
    },
    loading: i18next.t("Loading", { ns: "attributes" }),
    orgLogo: i18next.t("alt.orgLogo", "Organization logo", {
      ns: "attributes",
    }),
    playStore: i18next.t("alt.playStore", "Play Store", { ns: "attributes" }),
    footerLogo: i18next.t("Read View Listen Watch ENJOY", {
      ns: "attributes",
    }),
    singlePageView: i18next.t("alt.singlePageView", "Single page view", {
      ns: "attributes",
    }),
    thumbnailForName: name =>
      i18next.t("alt.thumbnailForName", "Thumbnail for {{name}}", {
        name: name,
        ns: "attributes",
      }),
  },
  ariaLabel: {
    toggleBookshelf: title =>
      i18next.t("ariaLabel.toggleBookshelf", "{{title}} toggle bookshelf", {
        title: title,
        ns: "attributes",
      }),
    addNameToCuration: name =>
      i18next.t("Add {{name}} to Curation", {
        name: name,
        ns: "attributes",
      }),
    addNameToFavorites: name =>
      i18next.t("ariaLabel.addNameToFavorites", "Add {{name}} to favorites", {
        name: name,
        ns: "attributes",
      }),
    addNameToBookshelf: i18next.t("Add {{name}} to Offline Bookshelf", {
      ns: "attributes",
    }),
    addBookmark: i18next.t("ariaLabel.addBookmark", "Add Bookmark", {
      ns: "attributes",
    }),
    addToCuration: i18next.t("ariaLabel.addToCuration", "Add to curation", {
      ns: "attributes",
    }),
    addToFavorites: i18next.t("ariaLabel.addToFavorites", "Add to Favorites", {
      ns: "attributes",
    }),
    addToBookshelf: i18next.t("ariaLabel.addToBookshelf", "Add to Offline Bookshelf", {
      ns: "attributes",
    }),
    bookmarkPage: i18next.t("ariaLabel.bookmarkPage", "Bookmark this page", {
      ns: "attributes",
    }),
    branding: i18next.t("Branding", { ns: "attributes" }),
    breadcrumbs: i18next.t("ariaLabel.breadcrumbs", "Breadcrumbs", {
      ns: "attributes",
    }),
    browseName: i18next.t("browse {{displayName}}", { ns: "attributes" }),
    search: {
      category: i18next.t("ariaLabel.search.category", "Category (BISAC)", {
        ns: "attributes",
      }),
      contributor: i18next.t("ariaLabel.search.contributor", "Contributor", {
        ns: "attributes",
      }),
      language: i18next.t("ariaLabel.search.language", "Language", {
        ns: "attributes",
      }),
      licenseType: i18next.t("ariaLabel.search.licenseType", "License Type", {
        ns: "attributes",
      }),
      module: i18next.t("ariaLabel.search.module", "Module", {
        ns: "attributes",
      }),
      placeOfPublication: i18next.t("ariaLabel.search.placeOfPublication", "Place of Publication", {
        ns: "attributes",
      }),
      publicationYear: i18next.t("ariaLabel.search.publicationYear", "Publication Year", {
        ns: "attributes",
      }),
      publisher: i18next.t("ariaLabel.search.publisher", "Publisher Name", {
        ns: "attributes",
      }),
      singular: i18next.t("ariaLabel.search.singular", "Search", {
        ns: "attributes",
      }),
      input: i18next.t("ariaLabel.search.input", "Search input", {
        ns: "attributes",
      }),
      submitButton: i18next.t("ariaLabel.search.submitButton", "Search submit button", {
        ns: "attributes",
      }),
    },
    changeOrgMenu: i18next.t("ariaLabel.changeOrgMenu", "change organization menu", {
      ns: "attributes",
    }),
    audio: {
      changePlayback: i18next.t("ariaLabel.audio.changePlayback", "Change playback speed", {
        ns: "attributes",
      }),
      viewTrackList: i18next.t("ariaLabel.audio.viewTrackList", "View Track List", {
        ns: "attributes",
      }),
    },
    clearInput: i18next.t("ariaLabel.clearInput", "Clear input", {
      ns: "attributes",
    }),
    close: i18next.t("ariaLabel.close", "Close", { ns: "attributes" }),
    closeMenu: i18next.t("ariaLabel.closeMenu", "Close an open menu overlay", {
      ns: "attributes",
    }),
    closeMainMenu: i18next.t("ariaLabel.closeMainMenu", "Close main menu", {
      ns: "attributes",
    }),
    closeModal: i18next.t("ariaLabel.closeModal", "Close modal", {
      ns: "attributes",
    }),
    viewer: {
      theme: {
        dark: i18next.t("ariaLabel.viewer.theme.dark", "dark viewer theme", {
          ns: "attributes",
        }),
        light: i18next.t("ariaLabel.viewer.theme.light", "light viewer theme", {
          ns: "attributes",
        }),
        sepia: i18next.t("ariaLabel.viewer.theme.sepia", "sepia viewer theme", {
          ns: "attributes",
        }),
      },
      font: {
        default: i18next.t("ariaLabel.viewer.font.default", "default viewer font", {
          ns: "attributes",
        }),
        roboto: i18next.t("ariaLabel.viewer.font.roboto", "Roboto viewer font", {
          ns: "attributes",
        }),
        openDyslexic: i18next.t("ariaLabel.viewer.font.openDyslexic", "OpenDislexic viewer font", {
          ns: "attributes",
        }),
      },
      fontSize: {
        decrease: i18next.t("ariaLabel.viewer.fontSize.decrease", "Decrease font size", {
          ns: "attributes",
        }),
        increase: i18next.t("ariaLabel.viewer.fontSize.increase", "Increase font size", {
          ns: "attributes",
        }),
      },
    },
    datePublished: date =>
      i18next.t("ariaLabel.datePublished", "date published {{date}}", {
        date,
        ns: "attributes",
      }),

    download: i18next.t("ariaLabel.download", "Download", { ns: "attributes" }),
    downloadName: name =>
      i18next.t("ariaLabel.downloadName", "download {{name}}", {
        name,
        ns: "attributes",
      }),
    edition: number =>
      i18next.t("ariaLabel.edition", "edition {{number}}", {
        number,
        ns: "attributes",
      }),
    email: i18next.t("ariaLabel.email", "Email address", { ns: "attributes" }),
    exit: i18next.t("ariaLabel.exit", "Exit", { ns: "attributes" }),
    explore: {
      singular: i18next.t("ariaLabel.explore.singular", "explore", {
        ns: "attributes",
      }),
      collection: i18next.t("explore {{collectionName}} collection", {
        ns: "attributes",
      }),
      anthology: i18next.t("explore {{name}} anthology - {{subName}}", {
        ns: "attributes",
      }),
      category: i18next.t("explore category {{name}}", {
        ns: "attributes",
      }),
      curation: i18next.t("explore curation {{name}}", {
        ns: "attributes",
      }),
    },
    exploreNameModule: i18next.t("explore {{displayName}} module", {
      ns: "attributes",
    }),
    exploreMenu: i18next.t("explore menu", { ns: "attributes" }),
    externalLinks: i18next.t("ariaLabel.externalLinks", "External links", {
      ns: "attributes",
    }),
    facebook: i18next.t("Facebook", { ns: "attributes" }),
    filterList: {
      showAllMediaCount: i18next.t("filter list show {{media}} ({{count}})", {
        ns: "attributes",
      }),
      showMedia: (media, count) =>
        i18next.t("ariaLabel.filterList.showMedia", "Filter list show {{media}} ({{count}})", {
          media,
          count,
          ns: "attributes",
        }),
    },
    firstPage: i18next.t("ariaLabel.firstPage", "First page", {
      ns: "attributes",
    }),
    stars: {
      five: i18next.t("ariaLabel.stars.five", "Five stars (Loved it)", {
        ns: "attributes",
      }),
      four: i18next.t("ariaLabel.stars.four", "Four stars (Liked it)", {
        ns: "attributes",
      }),
      one: i18next.t("ariaLabel.stars.one", "One star (disappointed)", {
        ns: "attributes",
      }),
      three: i18next.t("ariaLabel.stars.three", "Three stars (It was ok)", {
        ns: "attributes",
      }),
      two: i18next.t("ariaLabel.stars.two", "Two stars (Not a fan)", {
        ns: "attributes",
      }),
    },
    anthology: {
      foundIn: i18next.t("found in {{anthologyName}} anthology", {
        ns: "attributes",
      }),
    },
    foundIn: i18next.t("found in {{name}} {{media}}", { ns: "attributes" }),
    hideDetails: i18next.t("hide details", { ns: "attributes" }),
    instagram: i18next.t("Instagram", { ns: "attributes" }),
    issue: number =>
      i18next.t("ariaLabel.issue", "issue {{number}}", {
        number,
        ns: "attributes",
      }),
    itemDetails: i18next.t("ariaLabel.itemDetails", "Item details", {
      ns: "attributes",
    }),
    linkedIn: i18next.t("LinkedIn", { ns: "attributes" }),
    listen: name =>
      i18next.t("ariaLabel.listen", "Listen to {{name}}", {
        name: name,
        ns: "attributes",
      }),
    mainMenu: i18next.t("ariaLabel.mainMenu", "Main menu", {
      ns: "attributes",
    }),
    mainMenuArea: i18next.t("Main menu area", { ns: "attributes" }),
    makeFavorite: i18next.t("ariaLabel.makeFavorite", "Make Favorite", {
      ns: "attributes",
    }),
    mediumFormat: format =>
      i18next.t("ariaLabel.mediumFormat", "medium format {{format}}", {
        format,
        ns: "attributes",
      }),
    menu: i18next.t("ariaLabel.menu", "Menu", { ns: "attributes" }),
    menuOpen: i18next.t("Menu (Open the main menu overlay)", {
      ns: "attributes",
    }),
    nextPage: i18next.t("ariaLabel.nextPage", "Go to next page", {
      ns: "attributes",
    }),
    oneUpView: i18next.t("ariaLabel.oneUpView", "One-Up View", {
      ns: "attributes",
    }),
    overlayMainMenu: i18next.t("ariaLabel.overlayMainMenu", "overlay main menu", { ns: "attributes" }),
    pageView: i18next.t("ariaLabel.pageView", "Page View", {
      ns: "attributes",
    }),
    pager: i18next.t("ariaLabel.pager", "pager", { ns: "attributes" }),
    prevPage: i18next.t("ariaLabel.prevPage", "Go to previous page", {
      ns: "attributes",
    }),
    rateThisItem: i18next.t("Rate this item", { ns: "attributes" }),
    readName: name =>
      i18next.t("ariaLabel.readName", "read {{name}}", {
        name,
        ns: "attributes",
      }),
    readBook: i18next.t("read book", { ns: "attributes" }),
    removeValue: value =>
      i18next.t("ariaLabel.removeValue", "remove {{value}}", {
        value,
        ns: "attributes",
      }),
    rotateClockwise: i18next.t("ariaLabel.rotateClockwise", "Rotate clockwise", { ns: "attributes" }),
    scaleFit: i18next.t("ariaLabel.scaleFit", "Scale to fit", {
      ns: "attributes",
    }),
    scrollToTop: i18next.t("ariaLabel.scrollToTop", "to top", {
      ns: "attributes",
    }),
    searchOpen: i18next.t("ariaLabel.searchOpen", "Search (Open the search menu overlay)", {
      ns: "attributes",
    }),
    searchName: i18next.t("search {{name}}", { ns: "attributes" }),
    searchContent: i18next.t("ariaLabel.searchContent", "Search content", {
      ns: "attributes",
    }),
    skipToContent: i18next.t("ariaLabel.skipToContent", "Skip To Content", {
      ns: "attributes",
    }),
    skipToFooter: i18next.t("ariaLabel.skipToFooter", "Skip To Footer", {
      ns: "attributes",
    }),
    sort: {
      bookmarks: i18next.t("sort bookmarks", { ns: "attributes" }),
      notes: i18next.t("sort notes", { ns: "attributes" }),
    },
    sortBy: {
      newest: i18next.t("ariaLabel.sortBy.newest", "sort by newest", {
        ns: "attributes",
      }),
      oldest: i18next.t("ariaLabel.sortBy.oldest", "sort by oldest", {
        ns: "attributes",
      }),
    },
    switchView: view =>
      i18next.t("ariaLabel.switchView", "Switch to {{view}} view", {
        view,
        ns: "attributes",
      }),
    takeNote: i18next.t("ariaLabel.takeNote", "Take a note", {
      ns: "attributes",
    }),
    toggleView: view =>
      i18next.t("ariaLabel.toggleView", "Toggle {{view}}", {
        view,
        ns: "attributes",
      }),
    toggleViewType: i18next.t("ariaLabel.toggleViewType", "Toggle view type", {
      ns: "attributes",
    }),
    twitter: i18next.t("Twitter", { ns: "attributes" }),
    twoUpView: i18next.t("ariaLabel.twoUpView", "Two-Up View", {
      ns: "attributes",
    }),
    unfavorite: i18next.t("ariaLabel.unfavorite", "Unfavorite", {
      ns: "attributes",
    }),
    username: i18next.t("Username", { ns: "attributes" }),
    viewBookDetails: i18next.t("view book details of {{name}}", {
      ns: "attributes",
    }),
    viewChapterDetails: i18next.t("view chapter details of {{name}}", {
      ns: "attributes",
    }),
    viewImage: i18next.t("view image", { ns: "attributes" }),
    viewerOptions: i18next.t("ariaLabel.viewerOptions", "Viewer Options", {
      ns: "attributes",
    }),
    volume: number =>
      i18next.t("ariaLabel.volume", "volume {{number}}", {
        number,
        ns: "attributes",
      }),
    watch: i18next.t("Watch {{title}}", { ns: "attributes" }),
    zoomIn: i18next.t("ariaLabel.zoomIn", "Zoom in", { ns: "attributes" }),
    zoomOut: i18next.t("ariaLabel.zoomOut", "Zoom out", { ns: "attributes" }),
    zoomToFit: i18next.t("ariaLabel.zoomToFit", "Zoom to fit", {
      ns: "attributes",
    }),
    zoomFullSize: i18next.t("ariaLabel.zoomFullSize", "Zoom to full size", {
      ns: "attributes",
    }),
  },
  title: {
    addNameToCuration: i18next.t("Add {{name}} to Curation", {
      ns: "attributes",
    }),
    addTitleToBookshelf: i18next.t("Add {{title}} to Offline Bookshelf", {
      ns: "attributes",
    }),
    addToCuration: i18next.t("title.addToCuration", "Add to Curation", {
      ns: "attributes",
    }),
    addToFavorites: i18next.t("title.addToFavorites", "Add to Favorites", {
      ns: "attributes",
    }),
    diagnosticsCopied: i18next.t("title.diagnosticsCopied", "Diagnostics copied!", { ns: "attributes" }),
    downloadName: i18next.t("Download {{name}}", { ns: "attributes" }),
    editCuration: i18next.t("title.editCuration", "Edit Curation", {
      ns: "attributes",
    }),
    explore: {
      name: i18next.t("Explore {{name}}", { ns: "attributes" }),
    },
    favoriteName: i18next.t("Favorite {{name}}", { ns: "attributes" }),
    listenTo: i18next.t("Listen to {{name}}", { ns: "attributes" }),
    readName: i18next.t("Read {{name}}", { ns: "attributes" }),
    shareName: name =>
      i18next.t("title.shareName", "Share {{name}}", {
        name: name,
        ns: "attributes",
      }),
    unfavoriteName: i18next.t("Unfavorite {{name}}", { ns: "attributes" }),
  },
  data: {
    originalTitle: {
      bookmarkPage: i18next.t("data.originalTitle.bookmarkPage", "Bookmark this page", {
        ns: "attributes",
      }),
      fontSize: {
        decrease: i18next.t("data.originalTitle.fontSize.decrease", "Decrease font size", {
          ns: "attributes",
        }),
        increase: i18next.t("data.originalTitle.fontSize.increase", "Increase font size", {
          ns: "attributes",
        }),
      },
      download: i18next.t("data.originalTitle.download", "Download", {
        ns: "attributes",
      }),
      makeFavorite: i18next.t("data.originalTitle.makeFavorite", "Make Favorite", {
        ns: "attributes",
      }),
      pageView: i18next.t("data.originalTitle.pageView", "Page View", {
        ns: "attributes",
      }),
      rotateClockwise: i18next.t("data.originalTitle.rotateClockwise", "Rotate clockwise", {
        ns: "attributes",
      }),
      scaleFit: i18next.t("data.originalTitle.scaleFit", "Scale to fit", {
        ns: "attributes",
      }),
      takeNote: i18next.t("data.originalTitle.takeNote", "Take a note", {
        ns: "attributes",
      }),
      unfavorite: i18next.t("data.originalTitle.unfavorite", "Unfavorite", {
        ns: "attributes",
      }),
      zoomIn: i18next.t("data.originalTitle.zoomIn", "Zoom in", {
        ns: "attributes",
      }),
      zoomOut: i18next.t("data.originalTitle.zoomOut", "Zoom out", {
        ns: "attributes",
      }),
      zoomToFit: i18next.t("data.originalTitle.zoomToFit", "Zoom to fit", {
        ns: "attributes",
      }),
      zoomFullSize: i18next.t("data.originalTitle.zoomFullSize", "Zoom to full size", {
        ns: "attributes",
      }),
    },
    placeholder: i18next.t("data.placeholder", "Start typing or select", {
      ns: "attributes",
    }),
  },
  placeholder: {
    password: {
      confirm: i18next.t("placeholder.password.confirm", "confirm password", {
        ns: "attributes",
      }),
      desired: i18next.t("desired password", { ns: "attributes" }),
      new: i18next.t("placeholder.password.new", "new password", {
        ns: "attributes",
      }),
      old: i18next.t("plcaeholder.password.old", "old password", {
        ns: "attributes",
      }),
      singular: i18next.t("placeholder.password.singular", "password", {
        ns: "attributes",
      }),
    },
    curation: {
      title: title =>
        i18next.t("placeholder.curation.title", "Curation {{title}}", {
          title: title,
          ns: "attributes",
        }),
    },
    emailOptional: i18next.t("placeholder.emailOptional", "email address (optional)", { ns: "attributes" }),
    enterEmail: i18next.t("placeholder.enterEmail", "enter your email address", { ns: "attributes" }),
    comment: {
      joinDiscussion: i18next.t("placeholder.comment.joinDiscussion", "Join the discussion...", { ns: "attributes" }),
    },
    libraryCardNum: i18next.t("placeholder.libraryCardNum", "Library Card #", {
      ns: "attributes",
    }),
    pin: i18next.t("placeholder.pin", "PIN / Password", { ns: "attributes" }),
    search: i18next.t("placeholder.search", "Search", { ns: "attributes" }),
    searchChapter: i18next.t("placeholder.searchChapter", "Search this chapter", { ns: "attributes" }),
    subtitle: i18next.t("placeholder.subtitle", "Subtitle", {
      ns: "attributes",
    }),
    username: i18next.t("placeholder.username", "Username", {
      ns: "attributes",
    }),
  },
  role: {
    nav: i18next.t("navigation", { ns: "attributes" }),
    search: i18next.t("search", { ns: "attributes" }),
  },
});
