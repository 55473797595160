var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"p-t-20 master-container loading\">\n    <div class=\"row comments-loading\">\n        <div class=\"progress progress-striped active\">\n            <div class=\"progress-bar\"  role=\"progressbar\" style=\"width: 100%\">\n                <span class=\"sr-only\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"disqus") : stack1)) != null ? lookupProperty(stack1,"loadingComments") : stack1), depth0))
    + "</span>\n            </div>\n        </div>\n    </div>\n    <div class=\"comments-loading-error\">\n        <div class=\"alert bg-warning\" role=\"alert\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"disqus") : stack1)) != null ? lookupProperty(stack1,"loadingError") : stack1), depth0))
    + "</div>\n    </div>\n    <div class=\"row comments-navigation\">\n        <div class=\"col-xs-12\">\n            <ul class=\"nav nav-tabs comments-tab\">\n                <li class=\"active\"><a href=\"#thread\" data-toggle=\"tab\" class=\"comments-count\"></a></li>\n                <!--<li><a href=\"#forum\" data-toggle=\"tab\" class=\"forum-display-name\"></a></li>-->\n            </ul>\n            <ul class=\"comments-navigation-user\">\n                <li class=\"user-name\"></li>\n            </ul>\n        </div>\n    </div>\n    <div class=\"tab-content\">\n        <div id=\"thread\" class=\"tab-pane active\">\n            <div class=\"row\">\n                <div class=\"col-xs-12 comments-container\"></div>\n            </div>\n        </div>\n        <!--<div id=\"forum\" class=\"tab-pane\">-->\n            <!--<div class=\"row\">-->\n                <!--<div class=\"col-xs-12 forum-container\"></div>-->\n            <!--</div>-->\n        <!--</div>-->\n    </div>\n</div>\n\n<div id=\"comments-loading-modal\" class=\"modal fade\" role=\"dialog\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <div class=\"modal-body\">\n                <div class=\"progress\">\n                    <div class=\"progress-bar progress-bar-striped active\"  style=\"width: 100%\">\n                        <span class=\"sr-only\">Loading...</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n\n<div id=\"comments-display-name-modal\" class=\"modal fade\" role=\"dialog\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <div class=\"modal-header\">\n                <button type=\"button\" class=\"close\" data-dismiss=\"modal\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"close") : stack1), depth0))
    + "</span></button>\n                <h4 class=\"modal-title\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"disqus") : stack1)) != null ? lookupProperty(stack1,"displayName") : stack1), depth0))
    + "</h4>\n            </div>\n            <div class=\"modal-body\">\n                <form class=\"form-horizontal\">\n                    <div class=\"form-group\">\n                        <label for=\"comments-display-name\" class=\"col-xs-3 control-label\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"disqus") : stack1)) != null ? lookupProperty(stack1,"displayName") : stack1), depth0))
    + ":</label>\n                        <div class=\"col-xs-9\">\n                            <input type=\"text\" id=\"comments-display-name\" class=\"form-control\">\n                        </div>\n                    </div>\n                </form>\n            </div>\n            <div class=\"modal-footer\">\n                <button type=\"button\" class=\"btn btn-link\" data-dismiss=\"modal\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"cancel") : stack1), depth0))
    + "</button>\n                <button type=\"submit\" class=\"btn-rounded btn-rounded-brand-primary btn-confirm\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"confirm") : stack1), depth0))
    + "</button>\n            </div>\n        </div>\n    </div>\n</div>\n\n<div id=\"comments-flag-confirm-modal\" class=\"modal fade\" role=\"dialog\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <div class=\"modal-header\">\n                <button type=\"button\" class=\"close\" data-dismiss=\"modal\">\n                    <span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"close") : stack1), depth0))
    + "</span>\n                </button>\n                <h4 class=\"modal-title\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"disqus") : stack1)) != null ? lookupProperty(stack1,"flagComment") : stack1)) != null ? lookupProperty(stack1,"header") : stack1), depth0))
    + "</h4>\n            </div>\n            <div class=\"modal-body\">\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"disqus") : stack1)) != null ? lookupProperty(stack1,"flagComment") : stack1)) != null ? lookupProperty(stack1,"body") : stack1), depth0))
    + "\n            </div>\n            <div class=\"modal-footer\">\n                <button type=\"button\" class=\"btn btn-primary btn-close\" data-dismiss=\"modal\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"close") : stack1), depth0))
    + "</button>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});