var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"col-sm-12\" id=\"libPinInputContainer\">\n                <div class=\"form-group\">\n                    <input class=\"form-control input-outline m-t-5\" name=\"pin\" placeholder=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"placeholder") : stack1)) != null ? lookupProperty(stack1,"pin") : stack1), depth0))
    + "\">\n                </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"bb-patron-verification-form\">\n    <h3 id=\"bb-patron-verification-form-modal-header\" class=\"text-notransform m-tb-20\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"auth") : stack1)) != null ? lookupProperty(stack1,"pleaseVerify") : stack1), depth0))
    + " <span\n            class=\"fnt-bld clr-primary\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"organizationName") || (depth0 != null ? lookupProperty(depth0,"organizationName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"organizationName","hash":{},"data":data,"loc":{"start":{"line":3,"column":40},"end":{"line":3,"column":60}}}) : helper)))
    + "</span> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"auth") : stack1)) != null ? lookupProperty(stack1,"membership") : stack1), depth0))
    + "</h3>\n\n    <!--form fields row-->\n    <div class=\"row m-b-20\">\n          <div class=\"col-sm-12\">\n            <div class=\"form-group\">\n                <input class=\"form-control input-outline m-t-5\" name=\"cardNumber\" placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"placeholder") : stack1)) != null ? lookupProperty(stack1,"libraryCardNum") : stack1), depth0))
    + "\">\n            </div>\n          </div>\n\n        <!--i've added an ID on this column...if PIN is not need, hide this column...not just the field-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"pinRequired") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":20,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <!--///form fields row-->\n\n    <!--button row-->\n    <div class=\"row text-right\">\n        <div class=\"col-md-12\">\n          <button class=\"btn btn-link m-t-5\" id=\"bb-patron-verification-modal-cancel-button\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"button") : stack1)) != null ? lookupProperty(stack1,"cancel") : stack1), depth0))
    + "</button>\n          <button type=\"submit\" class=\"btn-rounded btn-rounded-brand-primary m-t-5 p-lr-30\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"button") : stack1)) != null ? lookupProperty(stack1,"submit") : stack1), depth0))
    + "</button>\n            <div class=\"bb-form-submit-button-loader-region btn-rounded btn-rounded-brand-primary disabled hide\"></div>\n        </div>\n    </div>\n    <!--///button row-->\n</form>";
},"useData":true});