export function isIpad() {
  return (
    (/iPad/.test(navigator.platform) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
    !window.MSStream
  );
}

export function isIpod() {
  return /iPod/.test(navigator.platform) && !window.MSStream;
}

export function isIphone() {
  return /iPhone/.test(navigator.platform) && !window.MSStream;
}

export function isIos() {
  return isIpad() || isIpod() || isIphone();
}

export function isWindowsInTabletMode() {
  // can we just detect touch points and enable if on?
  return (/Win/.test(navigator.platform) && navigator.maxTouchPoints > 0);
  // return navigator.maxTouchPoints > 0;
}

export function isAndroid() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return (/Android/.test(userAgent));
}

export default {
  isIpad,
  isIpod,
  isIphone,
  isIos,
  isWindowsInTabletMode,
  isAndroid
};
