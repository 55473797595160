var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"authScopeOrganizationBrandingLogoUrl") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":10,"column":28},"end":{"line":16,"column":35}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <img src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"authScopeOrganizationBrandingLogoUrl") || (depth0 != null ? lookupProperty(depth0,"authScopeOrganizationBrandingLogoUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"authScopeOrganizationBrandingLogoUrl","hash":{},"data":data,"loc":{"start":{"line":11,"column":42},"end":{"line":11,"column":82}}}) : helper)))
    + "\"\n                                    class=\"img-responsive bb-authentication-screen-branded-logo\"\n                                    alt=\"Organization logo\" />\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <h1>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"authScopeOrganizationName") || (depth0 != null ? lookupProperty(depth0,"authScopeOrganizationName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"authScopeOrganizationName","hash":{},"data":data,"loc":{"start":{"line":15,"column":36},"end":{"line":15,"column":65}}}) : helper)))
    + "</h1>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                            <img src=\"" + require("/opt/atlassian/pipelines/agent/build/node_modules/handlebars-template-loader/file-loader.js?url=..%2Fimages%2Flogo-bb-horo.png!../images/logo-bb-horo.png") + "\" class=\"img-responsive\" alt=\"BiblioBoard\" />\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"m-t-10 m-b-20\">\n                            "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"notYourLibrary") : stack1), depth0))
    + " <a href=\"https://library.biblioboard.com/\" class=\"p-t-20\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"switch") : stack1), depth0))
    + "</a>\n                        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                        <!--message-->\n                        <div class=\"contentwell bg-gray-lighter text-left m-t-40\">\n                            <h3 class=\"m-b-5\"><i class=\"fa fa-exclamation-triangle clr-warning\"></i> Important Update!\n                            </h3>\n                            <p class=\"\">Primis parturient per dapibus metus sociosqu eu hac purus massa nulla ante dui\n                                scelerisque phasellus ultricies feugiat quis ad nibh a proin.</p>\n                        </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"bb-authentication-screen-powered-by\">\n                        <h5>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"poweredBy") : stack1), depth0))
    + "</h5>\n                        <h3>biblioboard</h3>\n                    </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <h2>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"sorry") : stack1), depth0))
    + "</h2>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"authScopeOrganizationName") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":63,"column":20},"end":{"line":67,"column":27}}})) != null ? stack1 : "")
    + "                    <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"pleaseVisit") : stack1), depth0))
    + " <a href=\"https://library.biblioboard.com\">library.biblioboard.com</a>.</p>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"inactive") : stack1), depth0))
    + "</p>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"notFound") : stack1), depth0))
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"bb-authentication-screen\">\n    <div class=\"container bg-white h-inherit\">\n        <div class=\"row h-inherit\">\n            <!--col-->\n            <div class=\"col-sm-6 text-center h-inherit\" id=\"bb-authentication-screen-sign-in-brand\">\n                <div role=\"banner\">\n                    <!--biblioboard logo-->\n                    <a href=\"/home\" class=\"lnk-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"authScope") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":19,"column":31}}})) != null ? stack1 : "")
    + "                    </a>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"authScope") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":26,"column":27}}})) != null ? stack1 : "")
    + "\n                    <!--sign in links for mobile-->\n                    <div class=\"row m-tb-40 visible-xs\">\n                        <div class=\"col-xs-7\">\n                            <button id=\"bb-authentication-screen-mobile-sign-up-link\"\n                                class=\"btn-outline-rounded btn-outline-gray btn-create-profile\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"createProfile") : stack1), depth0))
    + "</button>\n                        </div>\n                        <div class=\"col-xs-5\">\n                            <button id=\"bb-authentication-screen-mobile-sign-in-link\"\n                                class=\"btn-rounded btn-rounded-brand-primary\" href=\"/login\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"signIn") : stack1), depth0))
    + "</button>\n                        </div>\n                    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,false,{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":20},"end":{"line":48,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"authScope") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":16},"end":{"line":55,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n            <!--///col-->\n            <!--col-->\n            <div class=\"col-sm-6 text-center bg-white h-inherit\">\n                <div id=\"bb-authentication-screen-body-region\" role=\"main\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"authScopeOrganizationInvalid") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":16},"end":{"line":69,"column":23}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n            <!--///col-->\n        </div>\n    </div>\n\n</div>";
},"useData":true});