import validate from "validate.js";

validate.validators.matches = function(value, options, key, attributes) {
  if (!options.attribute) {
    throw new Error(
      "The attribute to match against must be specified as an option",
    );
  }

  if (value !== attributes[options.attribute]) {
    return "doesn't match " + options.attribute;
  }
};
