import inject from "scripts/ioc/inject";
import L from "lodash/fp";
import _ from "lodash";
import BaseView from "scripts/views/baseView";
import templateFooter from "templates/footer.hbs";
import { getFooterLogoOption } from "scripts/utils/whiteLabelUtil";

const chan = Backbone.Radio.channel;

class FooterView extends BaseView {
  get template() {
    return templateFooter;
  }

  constructor(
    options,
    selfEService = inject("selfEService"),
    securityService = inject("securityService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);

    this.selfEService = selfEService;
    this.securityService = securityService;
    this.i18nextService = i18nextService;
  }

  get events() {
    return {
      "click #bb-footer-cookie-settings-link": "onClickCookieSettingsLink",
    };
  }

  render() {
    const user = this.securityService.getUser();
    const useDefaultSocialLink = user.getActiveOrganizationDynamicProperty("useDefaultSocialLink")
      ? user.getActiveOrganizationDynamicProperty("hideSocialLinks") === "1"
      : true;

    const externalUrls = JSON.parse(user.getActiveOrganizationDynamicProperty("externalUrls"));

    const showSelfe = this.selfEService.getShowSelfE();
    const pressbooksUrl = user.getActiveOrganizationProperty("pressbooksUrl");
    const partnerSelfEUrl = this.selfEService.getPartnerSelfEUrl();
    const showAuthorsMenu = pressbooksUrl || partnerSelfEUrl || showSelfe;

    const i18n = this.getTranslations();

    this.$el.html(
      this.template({
        i18n: {
          usingBiblioboard: i18n.usingName("BiblioBoard"),
          usingPlatform: i18n.usingName(user.getActiveOrganizationDynamicProperty("platformTitle")),
          ...i18n,
        },
        showAuthorsMenu,
        showSelfe,
        pressbooksUrl,
        generalSelfEUrl: this.selfEService.getGeneralSelfEUrl(),
        partnerSelfEUrl,
        marketingUrl: user.getMarketingUrl(),
        marketingUrlName: user.getMarketingUrlName(),
        externalUrls,
        attributionStmt: user.getActiveOrganizationProperty("attributionStmt"),

        logo: getFooterLogoOption(user.getActiveOrganizationDynamicProperty("footerLogo")),
        pledgeUrl: user.getActiveOrganizationDynamicProperty("pledgeUrl"),
        platformTitle: user.getActiveOrganizationDynamicProperty("platformTitle"),
        orgSupportEmail: user.getActiveOrganizationDynamicProperty("supportEmail"),
        blogUrl: user.getActiveOrganizationDynamicProperty("blogUrl"),
        hideBiblioBlog: user.getActiveOrganizationDynamicProperty("hideBiblioBlog"),
        orgInfoEmail: user.getActiveOrganizationDynamicProperty("infoEmail"),
        externalAboutPageUrl: user.getActiveOrganizationDynamicProperty("aboutPageUrl"),
        twitterUrl:
          user.getActiveOrganizationDynamicProperty("twitterUrl") ||
          (useDefaultSocialLink ? "https://twitter.com/biblioboard" : null),
        facebookUrl:
          user.getActiveOrganizationDynamicProperty("facebookUrl") ||
          (useDefaultSocialLink ? "https://www.facebook.com/BiblioBoard" : null),
        instagramUrl:
          user.getActiveOrganizationDynamicProperty("instagramUrl") ||
          (useDefaultSocialLink ? "http://instagram.com/biblioboard" : null),
        linkedInUrl:
          user.getActiveOrganizationDynamicProperty("linkedInUrl") ||
          (useDefaultSocialLink
            ? "https://www.linkedin.com/company/1641574?trk=tyah&trkInfo=idx%3A1-1-1%2CtarId%3A1424272183652%2Ctas%3Abibliolabs"
            : null),
        showLegalNotices: user.getActiveOrganizationDynamicProperty("hideLegalNotices") !== "1",
        showAppLogos:
          process.env.PATRON_UI_SHOW_APP_LOGOS && !user.getActiveOrganizationDynamicProperty("hideAppLogos"),
        showAppleAppLogo: !user.getActiveOrganizationDynamicProperty("hideAppleAppLogo"),
        appleAppDownloadUrl:
          user.getActiveOrganizationDynamicProperty("appleAppDownloadUrl") ||
          "https://itunes.apple.com/us/app/biblioboard-library/id613149061?ls=1&mt=8",
        showGoogleAppLogo: !user.getActiveOrganizationDynamicProperty("hideGoogleAppLogo"),
        googleAppDownloadUrl:
          user.getActiveOrganizationDynamicProperty("googleAppDownloadUrl") ||
          "https://play.google.com/store/apps/details?id=com.biblioboard",
        showKindleAppLogo: !user.getActiveOrganizationDynamicProperty("hideKindleAppLogo"),
        kindleAppDownloadUrl:
          user.getActiveOrganizationDynamicProperty("googleAppDownloadUrl") || "https://biblioboard.com/kindle/",
      }),
    );

    return this;
  }

  getTranslations() {
    const common = this.i18nextService.getCommon();
    const glossary = this.i18nextService.getGlossary();
    const attributes = this.i18nextService.getAttributes();

    const diagnostics = L.flowRight(L.pick("plural"), L.get(["app", "diagnostics"]))(glossary);

    const footer = L.pick(["footer"], glossary);

    const alt = L.flowRight(L.pick(["amazon", "appStore", "playStore"]), L.get("alt"))(attributes);

    return _.merge(
      alt,
      { diagnostics: diagnostics.plural },
      L.pick(["aboutUs", "gettingStarted", "moreInformation", "support", "using", "usingName"], common),
      footer,
    );
  }

  onClickCookieSettingsLink() {
    chan("display").request("showCookieSettingsModal");
    this.trigger("close");
  }
}

export default FooterView;
