import inject from "scripts/ioc/inject";
import BaseMediaSalvattoreTileView from "scripts/views/baseMediaSalvattoreTileView";
import templateCategorySalvattoreTile from "templates/categorySalvattoreTile.hbs";

class CategorySalvattoreTileView extends BaseMediaSalvattoreTileView {
  constructor(options, securityService = inject("securityService")) {
    super(options, securityService);
  }

  get template() {
    return templateCategorySalvattoreTile;
  }
}

export default CategorySalvattoreTileView;
