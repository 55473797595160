var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <li>\n        <label class=\"faceted-search-modal-checkbox-group-label\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":9,"column":10},"end":{"line":13,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showCounts") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":14,"column":10},"end":{"line":18,"column":17}}})) != null ? stack1 : "")
    + "        </label>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"expanded") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":33,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"expanded") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":35,"column":8},"end":{"line":39,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"terms") : depth0),{"name":"each","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":8},"end":{"line":55,"column":17}}})) != null ? stack1 : "")
    + "        </ul>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <input type=\"checkbox\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"expression") || (depth0 != null ? lookupProperty(depth0,"expression") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"expression","hash":{},"data":data,"loc":{"start":{"line":10,"column":42},"end":{"line":10,"column":56}}}) : helper)))
    + "\" class=\"bb-faceted-search-modal-checkbox-group\" checked />\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <input type=\"checkbox\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"expression") || (depth0 != null ? lookupProperty(depth0,"expression") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"expression","hash":{},"data":data,"loc":{"start":{"line":12,"column":42},"end":{"line":12,"column":56}}}) : helper)))
    + "\" class=\"bb-faceted-search-modal-checkbox-group\" />\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + alias3(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":15,"column":21}}}) : helper)))
    + " ("
    + alias3((lookupProperty(helpers,"formatNumber")||(depth0 && lookupProperty(depth0,"formatNumber"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"count") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":15,"column":23},"end":{"line":15,"column":45}}}))
    + ")\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"label","hash":{},"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":17,"column":21}}}) : helper)))
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "          <button class=\"bb-faceted-search-modal-checkbox-group-collapse-link\" aria-label=\"collapse\"><i\n              class=\"fa fa-angle-down\"></i></button>\n          <button class=\"bb-faceted-search-modal-checkbox-group-expand-link hidden\" aria-label=\"expand\"><i\n              class=\"fa fa-angle-up\"></i>\n          </button>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "          <button class=\"bb-faceted-search-modal-checkbox-group-collapse-link hidden\" aria-label=\"collapse\"><i\n              class=\"fa fa-angle-down\"></i>\n          </button>\n          <button class=\"bb-faceted-search-modal-checkbox-group-expand-link\" aria-label=\"expand\"><i\n              class=\"fa fa-angle-up\"></i></button>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "          <ul>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "          <ul class=\"hidden\">\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <li>\n        <label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":43,"column":10},"end":{"line":47,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showCounts") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":48,"column":10},"end":{"line":52,"column":17}}})) != null ? stack1 : "")
    + "        </label>\n      </li>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <input type=\"checkbox\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"code","hash":{},"data":data,"loc":{"start":{"line":44,"column":42},"end":{"line":44,"column":50}}}) : helper)))
    + "\" class=\"faceted-search-modal-checkbox\" checked />\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <input type=\"checkbox\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"code","hash":{},"data":data,"loc":{"start":{"line":46,"column":42},"end":{"line":46,"column":50}}}) : helper)))
    + "\" class=\"faceted-search-modal-checkbox\" />\n";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":49,"column":12},"end":{"line":49,"column":21}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"count") || (depth0 != null ? lookupProperty(depth0,"count") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"count","hash":{},"data":data,"loc":{"start":{"line":49,"column":23},"end":{"line":49,"column":32}}}) : helper)))
    + ")\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-header\">\n  <h2 class=\"modal-title text-center fnt-22 m-t-15\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"facetLabel") || (depth0 != null ? lookupProperty(depth0,"facetLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"facetLabel","hash":{},"data":data,"loc":{"start":{"line":2,"column":52},"end":{"line":2,"column":66}}}) : helper)))
    + "</h2>\n</div>\n<div class=\"modal-body\">\n  <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"termGroups") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":57,"column":17}}})) != null ? stack1 : "")
    + "        </ul>\n   </li>\n  </ul>\n</div>\n<div class=\"modal-footer\">\n  <button class=\"faceted-search-facet-modal-cancel-button btn-link m-r-10\" data-dismiss=\"modal\">Cancel</button>\n  <button id=\"bb-faceted-search-facet-modal-apply-button\" class=\"faceted-search-facet-modal-apply-button\"\n    data-dismiss=\"modal\">Apply\n  </button>\n</div>";
},"useData":true});