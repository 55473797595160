var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Citation -->\n<div class=\"row row-meta\">\n    <label>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"style") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</label>\n    <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"citation") || (depth0 != null ? lookupProperty(depth0,"citation") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"citation","hash":{},"data":data,"loc":{"start":{"line":4,"column":7},"end":{"line":4,"column":23}}}) : helper))) != null ? stack1 : "")
    + "</p>\n</div>\n<!-- ///Citation -->";
},"useData":true});