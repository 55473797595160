import _ from "lodash";
import Backbone from "backbone";
import Controller from "scripts/controllers/controller";
import CategoryCollection from "scripts/collections/categoryCollection";
import CategoriesPageView from "scripts/views/categoriesPageView";
import CategoriesPageListSubPageView from "scripts/views/categoriesPageListSubPageView";
import CategoriesPageSalvattoreSubPageView from "scripts/views/categoriesPageSalvattoreSubPageView";
import inject from "scripts/ioc/inject";

const chan = Backbone.Radio.channel;

class CategoriesController extends Controller {
  constructor(securityService = inject("securityService")) {
    super();

    this.securityService = securityService;
  }

  get route() {
    return "route:categories";
  }

  get defaults() {
    return {
      presentationType: "masonry",
    };
  }

  routeAttrs() {
    const user = this.securityService.getUser();

    return _.merge(
      {},
      this.defaults,
      {
        presentationType: user.getContentViewPreference(),
      },
      {
        presentationType: arguments[0] || undefined,
      },
    );
  }

  replyRoute() {
    let attrs = this.routeAttrs.apply(this, _.tail(arguments));

    let subPageView = this.createSubPageView(attrs);
    let categoriesPageView = chan("controllerView").request(
      "categoriesPageView",
    );

    if (categoriesPageView) {
      categoriesPageView.model.set(attrs);

      return categoriesPageView.showSubPageView(subPageView);
    } else {
      categoriesPageView = new CategoriesPageView({
        model: new Backbone.Model(attrs),
        subPageView: subPageView,
      });

      return this.showMainBodyView(categoriesPageView);
    }
  }

  createSubPageView(attrs) {
    let subPage;

    if (attrs.presentationType === "masonry") {
      subPage = new CategoriesPageSalvattoreSubPageView({
        collection: new CategoryCollection(),
      });
    } else {
      subPage = new CategoriesPageListSubPageView({
        collection: new CategoryCollection(),
      });
    }

    return subPage;
  }
}

export default CategoriesController;
