var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"organizationId") || (depth0 != null ? lookupProperty(depth0,"organizationId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"organizationId","hash":{},"data":data,"loc":{"start":{"line":13,"column":23},"end":{"line":13,"column":41}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"organizationName") || (depth0 != null ? lookupProperty(depth0,"organizationName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"organizationName","hash":{},"data":data,"loc":{"start":{"line":13,"column":43},"end":{"line":13,"column":63}}}) : helper)))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"geoRegion") : stack1), depth0))
    + "\">\n    <h4 class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"geoLabel") : stack1), depth0))
    + "\">Some libraries use location-based access</h4>\n    <button class=\"target-geoButton btn-outline-rounded btn-outline-brand-primary "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"geoButton") : stack1), depth0))
    + "\">\n      <i class=\"fa fa-location-arrow\"></i> &nbsp; CHECK MY LOCATION\n    </button>\n  </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2 class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"header") : stack1), depth0))
    + "\">Your libraries</h2>\n\n<div class=\"target-cardsRegion "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"cardsRegion") : stack1), depth0))
    + "\"></div>\n\n<h2 class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"addAnotherLibraryLabel") : stack1), depth0))
    + "\">Find a library to add to your profile</h2>\n\n<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"libraryListRegion") : stack1), depth0))
    + "\">\n  <h4 class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"libraryListLabel") : stack1), depth0))
    + "\">Select a library</h4>\n  <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"librarySelectRegion") : stack1), depth0))
    + "\">\n    <select class=\"target-libraryList "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"librarySelect") : stack1), depth0))
    + "\" data-placeholder=\"Search the list ...\">\n      <option></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"organizationVerifications") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "    </select>\n  </div>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"showGeo") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":0},"end":{"line":26,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"buttonRegion") : stack1), depth0))
    + "\">\n  <button type=\"submit\" class=\"target-doneButton btn-rounded btn-rounded-brand-primary "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styles") : depth0)) != null ? lookupProperty(stack1,"doneButton") : stack1), depth0))
    + "\">DONE</button>\n</div>";
},"useData":true});