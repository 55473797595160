import BaseAuthenticationModel from "scripts/models/baseAuthenticationModel";

class PatronVerificationAuthenticationModel extends BaseAuthenticationModel {
  url() {
    const organizationId = this.get("organizationId");
    return `${
      this.organizationAuthenticationUrlRoot
    }/organizations/${organizationId}/verification`;
  }
}

export default PatronVerificationAuthenticationModel;
