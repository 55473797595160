import _ from "lodash";
import Backbone from "backbone";

class CommentUserModel extends Backbone.Model {
  get idAttribute() {
    return "userId";
  }

  get defaults() {
    return {
      userId: "",
      name: "",
      username: "",
      avatar: "",
      profileUrl: "",
      postCount: 0,
      postCountText: "",
      following: false,
      currentUser: false,
    };
  }

  constructor(attrs, options) {
    super(attrs, options);
    this.disqusModel = attrs.disqusModel;
  }

  initialize(attrs) {
    this.disqusModel = attrs.disqusModel;
  }

  url() {
    return (
      `${process.env.PATRON_UI_DISQUS_URL_ROOT}/api/3.0/users/details.json?` +
      $.param({
        remote_auth: this.disqusModel.get("disqusAuthentication"),
        api_key: this.disqusModel.get("disqusKey"),
      })
    );
  }

  parse(response) {
    response = response.response || response;

    let data = {
      userId: response.id,
      name: response.name,
      username: response.username,
      avatar: response.avatar.permalink,
      profileUrl: response.profileUrl,
      postCount: response.numPosts,
      following: response.isFollowing,
    };

    data.postCountText =
      data.postCount + " Comment" + (data.postCount === 1 ? "" : "s");
    data.currentUser = this.disqusModel.get("disqusUsername") === data.username;
    data.canFollow =
      !data.currentUser &&
      !data.following &&
      !!this.disqusModel.get("disqusAuthentication");

    return _.extend({}, this.defaults, data);
  }
}

export default CommentUserModel;
