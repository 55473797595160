import BaseView from "scripts/views/baseView";

import styles from "./RouteInfo.css";
import template from "./RouteInfo.hbs";

class RouteInfo extends BaseView {
  constructor(options) {
    super(options);

    this.template = () =>
      template({
        styles,
        ...options,
      });
  }

  get className() {
    return styles.container;
  }

  render() {
    this.$el.html(this.template());
    return this;
  }
}

export default RouteInfo;
