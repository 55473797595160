import _ from "lodash";
import Backbone from "backbone";
import Controller from "scripts/controllers/controller";
import ModuleCollection from "scripts/collections/moduleCollection";
import ModulesPageView from "scripts/views/modulesPageView";
import ModulesPageListSubPageView from "scripts/views/modulesPageListSubPageView";
import ModulesPageSalvattoreSubPageView from "scripts/views/modulesPageSalvattoreSubPageView";
import inject from "scripts/ioc/inject";

const chan = Backbone.Radio.channel;

class ModulesController extends Controller {
  constructor(securityService = inject("securityService")) {
    super();

    this.securityService = securityService;
  }

  get route() {
    return "route:modules";
  }

  get defaults() {
    return {
      presentationType: "masonry",
    };
  }

  routeAttrs() {
    const user = this.securityService.getUser();

    return _.merge(
      {},
      this.defaults,
      {
        presentationType: user.getContentViewPreference(),
      },
      {
        presentationType: arguments[0] || undefined,
      },
    );
  }

  replyRoute() {
    const attrs = this.routeAttrs.apply(this, _.tail(arguments));
    const subPageView = this.createSubPageView(attrs);
    let modulesPageView = chan("controllerView").request("modulesPageView");

    if (modulesPageView) {
      modulesPageView.model.set(attrs);

      return modulesPageView.showSubPageView(subPageView);
    } else {
      modulesPageView = new ModulesPageView({
        model: new Backbone.Model(attrs),
        subPageView: subPageView,
      });

      return this.showMainBodyView(modulesPageView);
    }
  }

  createSubPageView(attrs) {
    let subPage;

    let moduleCollection = new ModuleCollection([], {
      path: "/modules",
    });

    if (attrs.presentationType === "masonry") {
      subPage = new ModulesPageSalvattoreSubPageView({
        collection: moduleCollection,
        model: new Backbone.Model(attrs),
      });
    } else {
      subPage = new ModulesPageListSubPageView({
        collection: moduleCollection,
        model: new Backbone.Model(attrs),
      });
    }

    return subPage;
  }
}

export default ModulesController;
