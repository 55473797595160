import Promise from "bluebird";
import Backbone from "backbone";
import BreadcrumbModel from "scripts/models/breadcrumbModel";
import BaseView from "scripts/views/baseView";
import { getCurrentFragment } from "scripts/utils/routerHelpers";

import templateCurationsPage from "templates/curationsPage.hbs";

const chan = Backbone.Radio.channel;

class CurationsPageView extends BaseView {
  get template() {
    return templateCurationsPage;
  }

  initialize(options) {
    this.subPageView = this.addSubView(options.subPageView);

    this.model.on(
      "change:presentationType",
      this.onPresentationTypeChangeSaveUserPreference,
      this,
    );

    chan("controllerView").reply(
      "curationsPageView",
      this.replyCurationsPageView,
      this,
    );
  }

  replyCurationsPageView() {
    return this;
  }

  updateLinkState() {
    const presentationType = this.model.get("presentationType");

    if (presentationType === "masonry") {
      $("#bb-secondary-sticky-nav-presentation-type-toggle-list").removeClass(
          "hide",
      );
    } else {
      $(
          "#bb-secondary-sticky-nav-presentation-type-toggle-masonry",
      ).removeClass("hide");
    }
  }


  render() {
    this.$el.html(this.template(this.model.toJSON()));

    this.subPageView
      .attachTo(this.$el.find("#bb-curations-page-sub-page-region"))
      .render();


    this.updateLinkState();

    return this;
  }

  sync() {
    return this.subPageView.fetch();
  }

  //TODO: make common
  showSubPageView(subPageView) {
    if (this.subPageViewPromise && this.subPageViewPromise.isPending()) {
      this.subPageViewPromise.cancel();
    }

    chan("display").trigger("bodyViewLoading");

    this.subPageViewPromise = this.showSubView(
      "subPageView",
      subPageView,
      this.$el.find("#bb-curations-page-sub-page-region"),
    )
      .catch(Promise.CancellationError, () => {
        // swallow cancellation
        console.debug("show sub page view cancelled");
      })
      .finally(value => {
        chan("display").trigger("bodyViewLoaded");

        // if (this.navView.isStuck()) {
        //   $(window).scrollTop(this.navView.scrollTopToStick());
        // }

        return value;
      });

    return this.subPageViewPromise;
  }

  getBreadcrumbInfo() {
    return {
      breadcrumbModel: new BreadcrumbModel({
        text: "Curations",
        path: "#" + getCurrentFragment(),
        topLevel: true,
        id: "curationsPage",
      }),
    };
  }

  getDocumentTitle() {
    return "Curations";
  }
}

export default CurationsPageView;
