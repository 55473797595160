import BaseView from "scripts/views/baseView";

import styles from "./SelectableOrganizationCard.css";
import template from "./SelectableOrganizationCard.hbs";

class SelectableOrganizationCard extends BaseView {
  constructor(options) {
    super(options);

    const { organizationInfo, isPublic } = options;

    this.state = {
      ...organizationInfo,
      isPublic,
    };

    this.template = () =>
      template({
        styles,
        ...this.state,
      });
  }

  get className() {
    return styles.container;
  }

  get events() {
    return {
      click: "onClick",
    };
  }

  render() {
    this.$el.html(this.template());
    return this;
  }

  onClick() {
    this.trigger("click", this);
    return false;
  }
}

export default SelectableOrganizationCard;
