import _ from "lodash";
import Backbone from "backbone";
import BaseService from "scripts/services/baseService";
import BreadcrumbModel from "scripts/models/breadcrumbModel";
import { getCurrentFragment } from "scripts/utils/routerHelpers";

const chan = Backbone.Radio.channel;

class BreadcrumbService extends BaseService {
  constructor() {
    super();

    this.navigationHistory = [];

    this.active = null;
    this.paths = [];
  }

  get maxNavigationHistory() {
    return 10;
  }

  get maxBreadcrumbHistoryDisplay() {
    return 3;
  }

  getActive() {
    return this.active;
  }

  getPaths() {
    return _.clone(this.paths);
  }

  getHistoryLength() {
    return this.navigationHistory.length;
  }

  getLastPath() {
    const length = this.navigationHistory.length;

    if (length > 1) {
      return this.navigationHistory[length - 2].get("path");
    } else {
      return null;
    }
  }

  addBreadcrumb({ breadcrumbModel, mediaLineage = [] }) {
    console.debug(
      "Add breadcrumb, model: %O, mediaLineage: %O",
      breadcrumbModel,
      mediaLineage,
    );

    this.updateNavigationHistory(breadcrumbModel);

    const current = _.last(this.navigationHistory);
    const remaining = _.dropRight(this.navigationHistory);

    this.active = current.get("text") || null;
    this.paths = this.getBreadcrumbPathsRecursive(
      current,
      remaining,
      [],
      mediaLineage,
    );

    chan("breadcrumb").trigger("update");
  }

  /**
   * Updates the most recent breadcrumb in the navigation history with
   * the attributes of the incoming navigation model. Attributes on the
   * existing model not included in the incoming model remain unchanged.
   * If no Navigation model is provided, the path of the most recent
   * breadcrumb will be updated to the current path.
   * @param breadcrumbModel Optional. Model that contains the
   * navigation attributes to update the history.
   */
  updateBreadcrumb({ breadcrumbModel } = { breadcrumbModel: null }) {
    if (!this.navigationHistory.length) {
      throw new Error("Cannot update empty breadcrumb history");
    }

    breadcrumbModel =
      breadcrumbModel ||
      new BreadcrumbModel({
        path: "#" + getCurrentFragment(),
      });

    console.debug("Update breadcrumb: %O", breadcrumbModel);

    _.last(this.navigationHistory).set(breadcrumbModel.attributes);

    //chan('breadcrumb').trigger('update');
  }

  /**
   * Returns an array of media ids from the most recent breadcrumbs up
   * until but not including the first top level page encountered.
   * @param mediaId The media id of the current page.
   */
  getMediaLineageCandidates(mediaId) {
    let history = _.clone(this.navigationHistory);

    let backwardsNavigationAssumed =
      history.length > 1
        ? mediaId === history[history.length - 2].get("id")
        : false;
    let isDuplicateId =
      history.length > 0
        ? mediaId === history[history.length - 1].get("id")
        : false;

    if (backwardsNavigationAssumed) {
      history.pop();
    }

    let current = _.last(history);
    let remaining = _.dropRight(history);

    let lineageCandidates = this.getMediaLineageCandidatesRecursive(
      current,
      remaining,
      [],
    );

    if (!backwardsNavigationAssumed && !isDuplicateId) {
      lineageCandidates.unshift(mediaId);
    }

    return lineageCandidates;
  }

  getMediaLineageCandidatesRecursive(current, remaining, lineage) {
    if (
      current &&
      !current.get("topLevel") &&
      lineage.length <= this.maxBreadcrumbHistoryDisplay
    ) {
      lineage.push(current.get("id"));

      let next = _.last(remaining);

      if (next && !next.get("topLevel")) {
        return this.getMediaLineageCandidatesRecursive(
          next,
          _.dropRight(remaining),
          lineage,
        );
      }
    }

    return lineage;
  }

  getBreadcrumbPathsRecursive(current, remaining, paths, mediaLineage) {
    if (
      !current.get("topLevel") &&
      remaining.length &&
      paths.length < this.maxBreadcrumbHistoryDisplay
    ) {
      let next = _.last(remaining);
      let isParent = this.isParent(current, next, mediaLineage);

      if (next.get("topLevel") || isParent) {
        paths.unshift({
          text: next.get("text"),
          path: next.get("path"),
          ariaLabel: next.get("ariaLabel")
            ? next.get("ariaLabel")
            : next.get("text"),
        });

        if (isParent) {
          return this.getBreadcrumbPathsRecursive(
            next,
            _.dropRight(remaining),
            paths,
            mediaLineage,
          );
        }
      }
    }

    return paths;
  }

  isParent(child, parent, mediaLineage) {
    let childIndex = _.indexOf(mediaLineage, child.get("id"));
    let parentIndex = _.indexOf(mediaLineage, parent.get("id"));

    return childIndex !== -1 && parentIndex !== -1 && childIndex < parentIndex;
  }

  updateNavigationHistory(breadcrumbModel) {
    if (this.isDuplicate(breadcrumbModel)) {
      console.debug(
        "Duplicate breadcrumb detected - not adding route to history",
      );
    } else if (this.isBackwardNavigationAssumed(breadcrumbModel)) {
      console.debug("Backward navigation assumed");
      this.navigationHistory.pop();
    } else {
      this.navigationHistory.push(breadcrumbModel);

      if (this.navigationHistory.length > this.maxNavigationHistory) {
        this.navigationHistory = _.tail(this.navigationHistory);
      }
    }

    console.debug(
      "Navigation history: %O",
      _.map(this.navigationHistory, "attributes"),
    );
  }

  /**
   * A duplicate can occur when navigating to a page with no breadcrumbs
   * (viewers, create profile, etc.) and then navigating backwards.
   */
  isDuplicate(breadcrumbModel) {
    let history = this.navigationHistory;

    return history.length > 0
      ? _.isEqual(
          history[history.length - 1].attributes,
          breadcrumbModel.attributes,
        )
      : false;
  }

  isBackwardNavigationAssumed(breadcrumbModel) {
    let history = this.navigationHistory;

    //TODO: paths like /home-page/ and /home-page should be considered equal

    return history.length > 1
      ? _.isEqual(
          history[history.length - 2].attributes,
          breadcrumbModel.attributes,
        )
      : false;
  }
}

export default BreadcrumbService;
