import BaseNoteTileView from "scripts/views/baseNoteTileView";
import templateNoteTile from "templates/noteTile.hbs";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";

class ContentDetailsPageAllNotesNoteTileView extends BaseNoteTileView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;
  }

  get tagName() {
    return "a";
  }

  get className() {
    return "note-card";
  }

  get template() {
    return templateNoteTile;
  }

  render() {
    this.$el.html(
      this.template(
        L.merge({ i18n: this.getTranslations() }, this.model.attributes),
      ),
    );

    this.initIcon();

    return this;
  }

  getTranslations() {
    const { actions, page } = this.i18nextService.getCommon();
    const { added } = actions;
    return { added, page };
  }

  /**
   * Page level notes display the page number.
   * Global notes display a globe icon.
   * Epub CFI notes have no icon.
   */
  initIcon() {
    if (this.model.has("pageSequence")) {
      // Page note
      this.$el.find(".bb-note-tile-page").removeClass("hide");
    } else if (!this.model.has("cfi")) {
      // Global note
      this.$el.find(".bb-note-tile-global").removeClass("hide");
    }
  }
}

export default ContentDetailsPageAllNotesNoteTileView;
