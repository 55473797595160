var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--<a href=\"/search-results/_cat=%22"
    + alias4(((helper = (helper = lookupProperty(helpers,"bisacCode") || (depth0 != null ? lookupProperty(depth0,"bisacCode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bisacCode","hash":{},"data":data,"loc":{"start":{"line":1,"column":37},"end":{"line":1,"column":50}}}) : helper)))
    + "%22%26_f=%22BOOK%22%26eft=false%26sort=true\">-->\n<!--  <div class=\"bb-category-tile-thumbnail\">-->\n<!--  </div>-->\n<!--  <div class=\"bb-category-tile-name\">-->\n<!--    <span class=\"bb-category-tile-name-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":5,"column":50},"end":{"line":5,"column":58}}}) : helper)))
    + "</span>-->\n<!--  </div>-->\n<!--</a>-->\n\n<div class=\"category-tile\">\n    <div class=\"tile-main-body\">\n        <a href=\"/search-results/_cat=%22"
    + alias4(((helper = (helper = lookupProperty(helpers,"bisacCode") || (depth0 != null ? lookupProperty(depth0,"bisacCode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bisacCode","hash":{},"data":data,"loc":{"start":{"line":11,"column":41},"end":{"line":11,"column":54}}}) : helper)))
    + "%22%26_f=%22BOOK%22%26eft=false%26sort=true\" class=\"tile-action\">\n\n            <!--image-->\n            <div class=\"media-image-container\">\n                <!--media image-->\n                <img class=\"bb-media-salvattore-tile-image\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"iconUrl") || (depth0 != null ? lookupProperty(depth0,"iconUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconUrl","hash":{},"data":data,"loc":{"start":{"line":16,"column":65},"end":{"line":16,"column":76}}}) : helper)))
    + "\" data-fallback-src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"iconFallbackUrl") || (depth0 != null ? lookupProperty(depth0,"iconFallbackUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconFallbackUrl","hash":{},"data":data,"loc":{"start":{"line":16,"column":97},"end":{"line":16,"column":116}}}) : helper)))
    + "\"\n                     alt=\"Thumbnail "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":17,"column":36},"end":{"line":17,"column":44}}}) : helper)))
    + "\" />\n            </div>\n\n            <div class=\"title\">\n                <!--title-->\n                <div class=\"h3 truncate-2\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":22,"column":43},"end":{"line":22,"column":51}}}) : helper)))
    + "</div>\n            </div>\n\n        </a>\n    </div>\n</div>\n";
},"useData":true});