export const chosenAriaLabels = (idx, el) => {
  const $el = $(el);

  const ariaLabel = $el.attr("aria-label");

  $el
    .siblings(".chosen-container")
    .find("input")
    .each((idx, inputEl) => {
      inputEl.setAttribute("aria-label", ariaLabel);
    });
};
