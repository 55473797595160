import _ from "lodash";
import Backbone from "backbone";
import Promise from "bluebird";
import errorAlert from "scripts/alerts/errorAlert";
import BreadcrumbModel from "scripts/models/breadcrumbModel";
import MediaLineageModel from "scripts/models/mediaLineageModel";
import BaseView from "scripts/views/baseView";
import CurationAddMediaModalView from "scripts/views/curationAddMediaModalView";
import CurationMediaAddedGrowlAlertView from "scripts/views/curationMediaAddedGrowlAlertView";
import CurationMultiSelectModel from "scripts/models/curationMultiSelectModel";
import ShareModalView from "scripts/views/shareModalView";
import { getCurrentFragment } from "scripts/utils/routerHelpers";
import { getDownloadUrl } from "scripts/utils/mediaHelpers";
import inject from "scripts/ioc/inject";

const chan = Backbone.Radio.channel;

class BaseMediaDetailsPageView extends BaseView {
  constructor(
    options,
    breadcrumbService,
    connectionService,
    securityService,
    i18nextService = inject("i18nextService"),
  ) {
    super(options);

    this.breadcrumbService = breadcrumbService;
    this.connectionService = connectionService;
    this.securityService = securityService;
    this.i18nextService = i18nextService;

    this.mediaModel = options.mediaModel;

    if (options.mediaModel.get("mediaType") !== "content") {
      this.navView = this.addSubView(this.createNavView());
    }

    this.mediaLineageModel = new MediaLineageModel({
      mediaLineageCandidates: this.breadcrumbService.getMediaLineageCandidates(this.mediaModel.get("id")),
    });

    this.subPageView = this.addSubView(options.subPageView);
  }

  get events() {
    return {
      "click #bb-media-details-favorite-button": "onClickFavorite",
      "click #bb-media-details-curation-button": "onClickCuration",
      "click #bb-media-details-share-button": "onClickShare",
    };
  }

  replyPageView(mediaId) {
    if (this.mediaModel.id === mediaId) {
      return this;
    }
  }

  createNavView() {
    //abstract
  }

  sync() {
    return Promise.all([this.mediaModel.fetch(), this.subPageView.fetch(), this.mediaLineageModel.fetch()]);
  }

  getTemplateData() {
    const user = this.securityService.getUser();

    const mediaModelData = this.mediaModel.toJSON();

    const templateData = _.merge(mediaModelData, {
      showBuyLinks: user.getShowBuyLinks(),
      showShareLinks: user.getShowShareLinks(),
      showBadges: user.getShowBadges(),
      viewerUrl: this.getViewerUrl(),
      downloadUrl: getDownloadUrl(this.mediaModel),
      i18n: this.getTranslations(mediaModelData),
      isAudiobook: mediaModelData.type === "AUDIOBOOK",
      isInAnthology: _.has(mediaModelData, "anthology"),
    });

    return templateData;
  }

  render() {
    this.$el.html(this.template(this.getTemplateData()));
    if (this.mediaModel.get("mediaType") !== "content") {
      this.navView.appendTo($("#bb-media-details-sticky-nav-region")).render();
    }

    this.subPageView.appendTo($("#bb-media-details-sub-page-region")).render();
    this.initTooltips();
    this.initializeDownloadButton();
    this.resetTabPosition();
    return this;
  }

  getTranslations(mediaModelData) {
    const attributes = this.i18nextService.getAttributes();
    const common = this.i18nextService.getCommon();
    const glossary = this.i18nextService.getGlossary();

    return {
      addNameToCuration: {
        ariaLabel: attributes.ariaLabel.addNameToCuration(mediaModelData.name),
      },
      addToBookshelf: {
        ariaLabel: attributes.ariaLabel.toggleBookshelf(mediaModelData.name),
        title: attributes.ariaLabel.addToBookshelf,
      },
      addToCuration: {
        title: attributes.title.addToCuration,
      },
      addToFavorite: {
        ariaLabel: attributes.ariaLabel.addNameToFavorites(mediaModelData.name),
        title: attributes.title.addToFavorites,
      },
      anthology: {
        singular: glossary.anthology.singular,
      },
      anthologyIncluded: glossary.anthology.included,
      collection: { singular: glossary.collection.singular },
      content: {
        article: { singular: glossary.content.article.singular },
        album: { singular: glossary.content.album.singular },
        audio: { singular: glossary.content.audio.singular },
        audiobook: { singular: glossary.content.audiobook.singular },
        book: { singular: glossary.content.book.singular },
        chapter: { singular: glossary.content.chapter.singular },
        document: { singular: glossary.content.document.singular },
        datePublished: {
          ariaLabel: attributes.ariaLabel.datePublished(mediaModelData.publicationDate),
        },
        edition: {
          singular: glossary.content.ed(mediaModelData.edition === undefined ? "" : mediaModelData.edition),
          ariaLabel: attributes.ariaLabel.edition(mediaModelData.edition === undefined ? null : mediaModelData.edition),
        },
        highlight: glossary.content.highlight,
        image: { singular: glossary.content.image.singular },
        issue: {
          singular: glossary.content.no(mediaModelData.issueNumber === undefined ? "" : mediaModelData.issueNumber),
          ariaLabel: attributes.ariaLabel.issue(
            mediaModelData.issueNumber === undefined ? null : mediaModelData.issueNumber,
          ),
        },
        mediumFormat: attributes.ariaLabel.mediumFormat(
          mediaModelData.mediumFormat === undefined ? "" : mediaModelData.mediumFormat,
        ),
        video: {
          singular: glossary.content.video.singular,
        },
        volume: {
          singular: glossary.content.vol(mediaModelData.volume === undefined ? "" : mediaModelData.volume),
          ariaLabel: attributes.ariaLabel.volume(mediaModelData.volume === undefined ? null : mediaModelData.volume),
        },
      },
      contentProvider: attributes.alt.contentProvider,
      contributeToProject: glossary.project.contribute,
      curation: {
        singular: glossary.curation.singular,
        edit: { title: attributes.title.editCuration },
        ariaLabel: attributes.placeholder.curation.title(mediaModelData.name),
      },
      download: {
        ariaLabel: attributes.ariaLabel.downloadName(mediaModelData.name),
        title: attributes.ariaLabel.download,
      },
      less: common.button.less,
      listen: {
        ariaLabel: attributes.ariaLabel.listen(mediaModelData.name),
        singular: common.actions.listen,
      },
      module: { singular: glossary.module.singular },
      more: common.button.more,
      orgLogoAlt: attributes.alt.orgLogo,
      play: common.button.play,
      read: {
        ariaLabel: attributes.ariaLabel.readName(mediaModelData.name),
        singular: common.actions.read,
      },
      share: attributes.title.shareName(mediaModelData.name),
      submitToProject: glossary.project.submit,
      thumbnailAlt: attributes.alt.thumbnailForName(mediaModelData.name),
      view: {
        singular: common.button.view,
      },
      watch: common.actions.watch,
    };
  }

  initTooltips() {
    // set up to NOT initialize for tablets...just caused too many conflicts
    if (chan("display").request("isLargerThanSm")) {
      this.$el.find('*[data-toggle="tooltip"]').tooltip({
        placement: "top",
        trigger: "hover",
        delay: 350,
        container: "body",
      });
    }
  }

  initializeDownloadButton() {
    const isOnline = this.connectionService.getConnectionStatus().isOnline;
    console.log("from download");

    const contentAllowDownload = this.mediaModel.get("allowDownload") && !this.mediaModel.get("isAudioBook");

    if (process.env.PATRON_UI_PREVENT_FILE_DOWNLOAD !== "true" && contentAllowDownload) {
      this.showDownloadButton();
    } else {
      this.hideDownloadButton();
    }

    if (isOnline) {
      this.enableDownloadButton();
    } else {
      this.disableDownloadButton();
    }
  }

  showDownloadButton() {
    this.$el.find("#bb-media-details-download-button-wrapper").removeClass("hide");
  }

  hideDownloadButton() {
    this.$el.find("#bb-media-details-download-button-wrapper").addClass("hide");
  }

  enableDownloadButton() {
    this.$el.find("#bb-media-details-download-button").removeClass("disabled");
  }

  disableDownloadButton() {
    this.$el.find("#bb-media-details-download-button").addClass("disabled");
  }

  destroyTooltips() {
    this.$el.find('*[data-toggle="tooltip"]').tooltip("destroy");
  }

  renderPageHeaderDescriptionContainer() {
    const $descriptionContainer = $("#bb-media-details-description-container");
    const descriptionContainerEl = $descriptionContainer.get(0);

    // descriptionContainer has overflow
    if (
      descriptionContainerEl.offsetHeight < descriptionContainerEl.scrollHeight ||
      descriptionContainerEl.offsetWidth < descriptionContainerEl.scrollWidth
    ) {
      $descriptionContainer.addClass("hasOverflow");
      $descriptionContainer.one("click", () => {
        $descriptionContainer.removeClass("hasOverflow");
        $descriptionContainer.addClass("expanded");
      });
    }
  }

  renderCurationButton() {
    const user = this.securityService.getUser();
    const mediaType = this.mediaModel.get("mediaType");

    if (user.hasCuratorRole() && _.includes(["content", "anthology", "collection", "module"], mediaType)) {
      const isOnline = this.connectionService.getConnectionStatus().isOnline;

      if (isOnline) {
        this.showCurationButton();
      } else {
        this.hideCurationButton();
      }
    }
  }

  onClickCuration() {
    const curationAddMediaModal = new CurationAddMediaModalView({
      model: new CurationMultiSelectModel({
        media: [this.mediaModel],
      }),
    });

    curationAddMediaModal.once(
      "success",
      curationId => {
        const curationMediaAddedGrowlAlertView = this.addSubView(
          new CurationMediaAddedGrowlAlertView({
            curationId: curationId,
            autoClose: true,
          }),
        );

        curationMediaAddedGrowlAlertView.on("close", () => {
          curationMediaAddedGrowlAlertView.animateOut().then(() => this.closeSubView(curationMediaAddedGrowlAlertView));
        });

        curationMediaAddedGrowlAlertView.appendTo($("#bb-app-growl-alert-region")).render();
      },
      this,
    );

    curationAddMediaModal.once("failure", errorAlert);

    chan("display").request("showBlockingLoader");

    curationAddMediaModal.fetch().then(() => {
      chan("display").request("hideBlockingLoader");
      chan("display").request("showModal", curationAddMediaModal);
    });

    return false;
  }

  renderFavoriteButton() {
    const $favoriteBadge = $("#bb-media-details-favorite");

    if (this.mediaModel.get("isFavorite")) {
      this.checkFavoriteButton();
      $favoriteBadge.removeClass("hide");
    } else {
      this.uncheckFavoriteButton();
      $favoriteBadge.addClass("hide");
    }
  }

  renderHighlightBadge() {
    const $highlightBadge = $("#bb-media-details-highlight");

    if (this.mediaModel.get("collectionHighlight")) {
      $highlightBadge.removeClass("hide");
    } else {
      $highlightBadge.addClass("hide");
    }
  }

  showSubPageView(subPageView) {
    if (this.showSubPagePromise && this.showSubPagePromise.isPending()) {
      this.showSubPagePromise.cancel();
    }

    chan("display").trigger("bodyViewLoading");

    this.showSubPagePromise = this.showSubView("subPageView", subPageView, $("#bb-media-details-sub-page-region"))
      .then(() => {
        this.breadcrumbService.updateBreadcrumb();
      })
      .catch(Promise.CancellationError, () => {
        // swallow cancellation
        console.debug("show sub page view cancelled");
      })
      .finally(value => {
        chan("display").trigger("bodyViewLoaded");

        if (this.navView.isStuck()) {
          $(window).scrollTop(this.navView.scrollTopToStick());
        }

        return value;
      });

    return this.showSubPagePromise;
  }

  onClickFavorite() {
    if (this.mediaModel.get("isFavorite")) {
      this.unfavorite();
    } else {
      this.favorite();
    }

    return false;
  }

  favorite() {
    this.mediaModel.set("isFavorite", true);
    this.renderFavoriteButton();

    chan("myBoard")
      .request("favorite", this.mediaModel)
      .catch(error => {
        this.mediaModel.set("isFavorite", false);
        this.renderFavoriteButton();

        if (!(error instanceof Promise.CancellationError)) {
          errorAlert(error).show();
        }
      });
  }

  unfavorite() {
    this.mediaModel.set("isFavorite", false);
    this.renderFavoriteButton();

    chan("myBoard")
      .request("unfavorite", this.mediaModel)
      .catch(error => {
        this.mediaModel.set("isFavorite", true);
        this.renderFavoriteButton();

        if (!(error instanceof Promise.CancellationError)) {
          errorAlert(error).show();
        }
      });
  }

  checkFavoriteButton() {
    this.$el.find("#bb-media-details-favorite-button").addClass("active");
  }

  uncheckFavoriteButton() {
    this.$el.find("#bb-media-details-favorite-button").removeClass("active");
  }

  hideCurationButton() {
    $("#bb-media-details-curation-button").addClass("hide");
  }

  showCurationButton() {
    $("#bb-media-details-curation-button").removeClass("hide");
  }

  getBreadcrumbInfo() {
    return {
      breadcrumbModel: new BreadcrumbModel({
        text: this.getBreadcrumbText(),
        path: "#" + getCurrentFragment(),
        topLevel: false,
        id: this.mediaModel.get("id"),
      }),
      mediaLineage: this.mediaLineageModel.get("mediaLineage"),
    };
  }

  getBreadcrumbText() {
    return this.mediaModel.get("title");
  }

  getDocumentTitle() {
    return `${this.mediaModel.get("title")}`;
  }

  close() {
    this.destroyTooltips();

    super.close();
  }

  onClickShare() {
    const shareModalView = new ShareModalView({
      mediaModel: this.mediaModel,
    });
    chan("display").request("showModal", shareModalView);
  }

  getViewerUrl() {
    const modelId = this.mediaModel.get("id");
    let viewerUrl = `/viewer/${modelId}`;

    const queryParams = this.model.get("queryParams");
    const searchTerm = queryParams ? queryParams["searchTerm"] : undefined;

    if (!_.isNil(searchTerm)) {
      viewerUrl = `${viewerUrl}?searchTerm=${encodeURIComponent(searchTerm)}`;
    }

    return viewerUrl;
  }
}

export default BaseMediaDetailsPageView;
