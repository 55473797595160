var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-xs-6 dropdown\">\n    <button aria-label=\"sort by newest\" data-toggle=\"dropdown\" class=\"bb-btn-a comments-thread-sort\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"newest") : stack1), depth0))
    + "\n        <span class=\"caret\"></span></button>\n    <ul class=\"dropdown-menu\">\n        <li><button aria-label=\"sort by newest\" class=\"bb-btn-a comments-thread-sort-action sort-new\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"newest") : stack1), depth0))
    + "t</button>\n        </li>\n        <li><button aria-label=\"sort by oldest\" class=\"bb-btn-a comments-thread-sort-action sort-old\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"oldest") : stack1), depth0))
    + "</button>\n        </li>\n    </ul>\n</div>\n<div class=\"col-xs-6 text-right\">\n    <ul class=\"comments-thread-social\">\n    </ul>\n</div>";
},"useData":true});