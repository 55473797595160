import Backbone from "backbone";
import BaseView from "scripts/views/baseView";
import mediaPageNavTranslations from "i18n/mediaPageNavTranslations";
import L from "lodash/fp";
import inject from "scripts/ioc/inject";

const chan = Backbone.Radio.channel;

class BaseSecondaryStickyView extends BaseView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.mediaPageNavTranslations = new mediaPageNavTranslations();

    chan("display").reply("isStuck", this.isStuck, this);
    chan("display").reply("scrollTopToStick", this.scrollTopToStick, this);

    chan("display").on("scroll:scrolling", this.handleScroll, this);

    chan("display").on(
      "bodyViewLoading",
      () => {
        //note: this has to happen after mainView resets the masthead
        this.resetPosition();
      },
      this,
    );

    chan("display").on(
      "bodyViewLoaded",
      () => {
        //note: this has to happen after mainView resets the masthead
        this.resetPosition();
        this.updatePosition(); // main view won't have set the initial scroll top until this is triggered
      },
      this,
    );

    this.model.on("change", this.render, this);
  }

  render() {
    const navModel = this.model.toJSON();
    if (navModel.collection) {
      navModel.itemCount = this.i18nextService.formatNumber(navModel.collection.length);
    }

    this.$el.html(this.template(L.merge({ i18n: this.mediaPageNavTranslations.getTranslations() }, navModel)));

    this.updateLinkState();
    this.updatePosition();

    return this;
  }

  handleScroll(...args) {
    this.updatePosition(...args);
  }

  updatePosition(scrollTop = $(window).scrollTop(), delta = 0) {
    if (scrollTop >= 0) {
      if (this.shouldStick()) {
        this.stick(delta);
      } else {
        this.unstick();
      }
    }
  }

  scrollTopToStick() {
    const $masthead = $("#bb-main-masthead-region");
    const mastheadHeight = $masthead.height();
    const mastheadTop = parseInt($masthead.css("top"), 10);

    const stickyNavDocumentOffsetTop = this.$el.offset().top;

    return stickyNavDocumentOffsetTop - (mastheadHeight + mastheadTop);
  }

  resetPosition() {
    chan("display").off("scroll:scrolling", this.handleScroll, this);

    const $masthead = $("#bb-main-masthead-region");
    const mastheadHeight = $masthead.height();

    if (this.isStuck()) {
      $("#bb-secondary-sticky-nav").css({
        position: "fixed",
        top: mastheadHeight,
      });
    }

    setTimeout(() => {
      chan("display").on("scroll:scrolling", this.handleScroll, this);
    }, 100);
  }

  shouldStick() {
    return this.calcShouldStick();
  }

  calcShouldStick() {
    const scrollTop = $(document).scrollTop();
    return scrollTop >= this.scrollTopToStick();
  }

  isStuck() {
    return $("#bb-secondary-sticky-nav").css("position") === "fixed";
  }

  stick(delta) {
    const $masthead = $("#bb-main-masthead-region");
    const mastheadHeight = $masthead.height();
    const mastheadTop = parseInt($masthead.css("top"), 10);

    const $stickyNav = $("#bb-secondary-sticky-nav");
    const stickyNavHeight = $stickyNav.height() + 20;
    const isFixed = $stickyNav.css("position") === "fixed";

    const stickyNavTop = isFixed ? parseInt($stickyNav.css("top"), 10) : mastheadHeight + mastheadTop;

    const minTop = -1 * stickyNavHeight;
    const maxTop = mastheadHeight;

    let newTop = stickyNavTop + delta;

    if (newTop < minTop) {
      newTop = minTop;
    } else if (newTop > maxTop) {
      newTop = maxTop;
    }

    $stickyNav.css({
      position: "fixed",
      top: newTop,
    });
  }

  unstick() {
    $("#bb-secondary-sticky-nav").css({
      position: "",
      top: "",
    });
  }

  updateLinkState() {
    const presentationType = this.model.get("presentationType");

    if (presentationType === "masonry") {
      $("#bb-secondary-sticky-nav-presentation-type-toggle-list").removeClass("hide");
    } else {
      $("#bb-secondary-sticky-nav-presentation-type-toggle-masonry").removeClass("hide");
    }
  }
}

export default BaseSecondaryStickyView;
