import BaseView from "scripts/views/baseView";
import MediaSalvattoreGridView from "scripts/views/mediaSalvattoreGridView";
import templateCurationsPageSalvattoreSubPage from "templates/curationsPageSalvattoreSubPage.hbs";
import CurationsPageNavView from "./curationsPageNavView";

class CurationsPageSalvattoreSubPageView extends BaseView {
  get template() {
    return templateCurationsPageSalvattoreSubPage;
  }

  constructor(options) {
    super(options);

    this.model.set("collection", this.collection);
    this.navView = this.addSubView(
      new CurationsPageNavView({
        model: this.model,
      }),
    );

    this.salvattoreView = this.addSubView(
      new MediaSalvattoreGridView({
        collection: this.collection,
      }),
    );
  }

  render() {
    this.$el.html(
      this.template({
        curationCount: this.collection.length,
      }),
    );

    this.navView
      .attachTo(this.$el.find("#bb-curations-page-sticky-nav-region"))
      .render();

    this.salvattoreView
      .appendTo(
        $("#bb-curations-page-salvattore-sub-page-salvattore-view-region"),
      )
      .render();

    return this;
  }

  sync() {
    return this.salvattoreView.fetch();
  }
}

export default CurationsPageSalvattoreSubPageView;
