import validate from "validate.js";

const ValidatableModelHoc = Model => {
  return class extends Model {
    constructor(attrs, options) {
      super(attrs, options);

      options = options || {};

      if (options.constraints) {
        this.constraints = options.constraints;
      }
    }

    validate(attrs) {
      if (this.constraints) {
        return validate(attrs, this.constraints);
      }
    }

    validateAttribute(key, attrs) {
      let errors = this.validate(attrs);

      if (errors) {
        let errorsForKey = errors[key];

        if (errorsForKey) {
          let attributeErrors = {};
          attributeErrors[key] = errorsForKey;
          return attributeErrors;
        }
      }
    }
  };
};

export default ValidatableModelHoc;
