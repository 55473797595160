import BaseApiModel from "scripts/models/baseApiModel";

class CurationMultiSelectModel extends BaseApiModel {
  urlRoot() {
    const curationMediaId = this.get("curation") || this.collection.mediaId;

    return `${this.apiUrlRoot}/curations/${curationMediaId}/media`;
  }
}

export default CurationMultiSelectModel;
