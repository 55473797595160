import Handlebars from "handlebars-template-loader/runtime";

Handlebars.registerHelper("atIndex", (array, index, prop) => {
  console.log("Array:")
  console.log(array);
  const item = array[index];
  console.log("Item:")
  console.log(item);
  console.log("Prop:")
  console.log(prop);
  if (prop) {
    const val = item[prop];
    console.log("Val:")
    console.log(val);
    return val;
  } else {
    return item
  }
});
