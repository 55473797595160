import Controller from "scripts/controllers/controller";
import RequestAccessScreen from "components/leads/RequestAccessScreen";

class RequestAccessController extends Controller {
  get route() {
    return "route:requestAccess";
  }

  replyRoute(route, mediaId) {
    return this.showScreenView(
      new RequestAccessScreen({
        mediaId,
      }),
    );
  }
}

export default RequestAccessController;
