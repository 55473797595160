import inject from "scripts/ioc/inject";
import L from "lodash/fp";
import BaseModalView from "scripts/views/baseModalView";
import templateRatingModal from "templates/ratingModal.hbs";

class RatingModalView extends BaseModalView {
  get template() {
    return templateRatingModal;
  }

  get events() {
    return {
      "click .bb-star-rating": "onClickStarRating",
    };
  }

  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.ratingModel = options.ratingModel;
    this.contentModel = options.contentModel;
  }

  render() {
    this.$el.html(
      this.template({
        i18n: this.getTranslations(this.contentModel),
        title: this.contentModel.get("title"),
        usersCurrentVote: this.ratingModel.get("usersCurrentVote"),
      }),
    );

    return this;
  }

  getTranslations(model) {
    const attributes = this.i18nextService.getAttributes();

    const ariaLabel = L.flowRight([
      L.pick(["close", "stars"]),
      L.get(["ariaLabel"]),
    ])(attributes);
    const { rating } = this.i18nextService.getGlossary();

    return {
      ariaLabel,
      howManyStars: rating.howManyStars(model.get("title")),
      rating,
    };
  }

  onClickStarRating(event) {
    const starRating = $(event.currentTarget).data("star-rating");

    this.trigger("rateMedia", starRating);

    return false;
  }
}

export default RatingModalView;
