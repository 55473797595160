import _ from "lodash";
import Backbone from "backbone";
import Controller from "scripts/controllers/controller";
import CurationCollection from "scripts/collections/curationCollection";
import CurationsPageView from "scripts/views/curationsPageView";
import CurationsPageListSubPageView from "scripts/views/curationsPageListSubPageView";
import CurationsPageSalvattoreSubPageView from "scripts/views/curationsPageSalvattoreSubPageView";
import inject from "scripts/ioc/inject";

const chan = Backbone.Radio.channel;

class CurationsController extends Controller {
  constructor(securityService = inject("securityService")) {
    super();

    this.securityService = securityService;
  }

  get route() {
    return "route:curations";
  }

  get defaults() {
    return {
      presentationType: "masonry",
    };
  }

  routeAttrs() {
    const user = this.securityService.getUser();

    return _.merge(
      {},
      this.defaults,
      {
        presentationType: user.getContentViewPreference(),
      },
      {
        presentationType: arguments[0] || undefined,
      },
    );
  }

  replyRoute() {
    const attrs = this.routeAttrs.apply(this, _.tail(arguments));
    const subPageView = this.createSubPageView(attrs);
    let curationsPageView = chan("controllerView").request("curationsPageView");

    if (curationsPageView) {
      curationsPageView.model.set(attrs);

      return curationsPageView.showSubPageView(subPageView);
    } else {
      curationsPageView = new CurationsPageView({
        model: new Backbone.Model(attrs),
        subPageView: subPageView,
      });

      return this.showMainBodyView(curationsPageView);
    }
  }

  createSubPageView(attrs) {
    let subPage;

    const curationCollection = new CurationCollection([], {
      comparator: "title",
      queryStringParams: {
        sort: "title",
      },
    });

    if (attrs.presentationType === "masonry") {
      subPage = new CurationsPageSalvattoreSubPageView({
        model: new Backbone.Model(attrs),
        collection: curationCollection,
      });
    } else {
      subPage = new CurationsPageListSubPageView({
        model: new Backbone.Model(attrs),
        collection: curationCollection,
      });
    }

    return subPage;
  }
}

export default CurationsController;
