import HybridMediaCollection from "scripts/collections/hybridMediaCollection";
import BaseView from "scripts/views/baseView";
import MediaSalvattoreGridView from "scripts/views/mediaSalvattoreGridView";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";

import templateContentDetailsPageAlsoFoundInSubPage from "templates/contentDetailsPageAlsoFoundInSubPage.hbs";

class ContentDetailsPageAlsoFoundInSubPageView extends BaseView {
  get template() {
    return templateContentDetailsPageAlsoFoundInSubPage;
  }

  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    const mediaId = this.model.get("mediaId");
    const collection = new HybridMediaCollection([], {
      path: `/media/${mediaId}/found-in`,
    });

    this.mediaSalvattoreView = this.addSubView(
      new MediaSalvattoreGridView({
        collection: collection,
      }),
    );
  }

  render() {
    const alsoFoundIn = L.get(
      ["content", "details", "alsoFoundIn"],
      this.i18nextService.getGlossary(),
    );

    this.$el.html(this.template({ i18n: { alsoFoundIn } }));

    this.mediaSalvattoreView
      .appendTo(this.$el.find("#bb-content-details-also-found-in-media-region"))
      .render();

    return this;
  }

  sync() {
    return this.mediaSalvattoreView.fetch();
  }
}

export default ContentDetailsPageAlsoFoundInSubPageView;
