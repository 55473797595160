import BaseView from "scripts/views/baseView";
import VerifyEmailNotificationFormView from "scripts/views/verifyEmailNotificationFormView";
import { alert } from "scripts/alerts/alerts";
import inject from "scripts/ioc/inject";
import L from "lodash/fp";

import verifyEmailErrorSubPage from "templates/verifyEmailErrorSubPage.hbs";

class VerifyEmailPageView extends BaseView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;
  }

  get template() {
    return verifyEmailErrorSubPage;
  }

  initialize() {
    this.verifyEmailNotificationForm = this.addSubView(
      new VerifyEmailNotificationFormView({
        emailAddress: this.model.emailAddress,
      }),
    );

    this.verifyEmailNotificationForm.on("success", this.onSuccess, this);
    this.verifyEmailNotificationForm.on("failure", this.onFailure, this);
  }

  render() {
    const glossary = this.i18nextService.getGlossary();
    const auth = L.flowRight([
      L.pick(["failed", "emailVerification", "problem"]),
      L.get(["auth"]),
    ])(glossary);

    this.$el.html(this.template({ i18n: { auth } }));

    this.verifyEmailNotificationForm
      .appendTo(this.$el.find("#bb-email-verification-page-resend-form-region"))
      .render();

    return this;
  }

  onSuccess() {
    alert("Email sent!", "Check your inbox!", "success").show();
  }

  onFailure() {
    alert(
      "Oops",
      "That didn't work. Please contact support if the problem persists.",
      "error",
    ).show();
  }
}

export default VerifyEmailPageView;
