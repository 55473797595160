import _ from "lodash";
import L from "lodash/fp";
import inject from "scripts/ioc/inject";
import BaseGrowlAlertView from "scripts/views/baseGrowlAlertView";
import templateSessionRefreshGrowlAlert from "templates/refreshSessionGrowlAlert.hbs";

import { fetchSubjectFromSecurity } from "scripts/utils/fetchSecurity";

class RefreshSessionAlertView extends BaseGrowlAlertView {
  constructor(
    options,
    securityService = inject("securityService"),
    i18nextService = inject("i18nextService"),
  ) {
    super(options);
    this.securityService = securityService;
    this.i18nextService = i18nextService;
  }

  get template() {
    return templateSessionRefreshGrowlAlert;
  }

  get events() {
    return _.extend({}, super.events, {
      "click #bb-app-update-growl-alert-refresh-button": "onClickRefresh",
    });
  }

  render() {
    const i18n = this.getTranslations();

    this.$el.html(this.template({ i18n }));

    this.animateIn();

    return this;
  }

  getTranslations() {
    const glossary = this.i18nextService.getGlossary();

    const session = L.isEmpty(glossary)
      ? {
          existing: "Your existing session is about to expire.",
          extend: "Extend Session",
        }
      : L.get(["auth", "session"], glossary);

    return { session };
  }

  onClickRefresh() {
    Promise.resolve(
      fetchSubjectFromSecurity({
        token: this.securityService.getUser().getToken(),
      }),
    )
      .then(() => this.animateOut())
      .catch(() => window.location.reload());
    return false;
  }
}

export default RefreshSessionAlertView;
