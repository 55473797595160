import _ from "lodash";
import Backbone from "backbone";
import Controller from "scripts/controllers/controller";
import AnthologyCollectionModel from "scripts/models/anthologyCollectionModel";
import AnthologyCollectionPageView from "scripts/views/anthologyCollectionPageView";
import AnthologyCollectionPageContentSubPageView from "scripts/views/anthologyCollectionPageContentSubPageView";
import inject from "scripts/ioc/inject";
import querystring from "querystring";

const chan = Backbone.Radio.channel;

class AnthologyCollectionController extends Controller {
  constructor(securityService = inject("securityService")) {
    super();

    this.securityService = securityService;
  }

  get route() {
    return "route:anthologyCollection";
  }

  get defaults() {
    return {
      contentType: "all-content",
      presentationType: "masonry",
      contentSort: null,
    };
  }

  routeAttrs() {
    const user = this.securityService.getUser();
    const queryParam = querystring.parse(arguments[5]);

    let pageIndex = parseInt(arguments[4], 10);

    if (!isNaN(pageIndex)) {
      pageIndex = pageIndex - 1;
    } else {
      pageIndex = 0;
    }

    return _.merge(
      {},
      this.defaults,
      {
        presentationType: user.getContentViewPreference(),
      },
      {
        anthologyId: arguments[0],
        anthologyCollectionId: arguments[1],
        presentationType: arguments[2] || void 0,
        contentType: arguments[3] || void 0,
        pageIndex,
        contentSort: queryParam.sort || void 0,
      },
    );
  }

  replyRoute() {
    const attrs = this.routeAttrs.apply(this, _.tail(arguments));

    const subPageView = new AnthologyCollectionPageContentSubPageView({
      model: new Backbone.Model({
        anthologyId: attrs.anthologyId,
        anthologyCollectionId: attrs.anthologyCollectionId,
        presentationType: attrs.presentationType,
        contentType: attrs.contentType,
        pageIndex: attrs.pageIndex,
        contentSort: attrs.contentSort,
      }),
      mediaModel: new AnthologyCollectionModel({
        id: attrs.anthologyCollectionId,
      }),
    });

    let anthologyCollectionPageView = chan("controllerView").request(
      "anthologyCollectionPageView",
      attrs.anthologyCollectionId,
    );

    if (anthologyCollectionPageView) {
      anthologyCollectionPageView.model.set(attrs);

      return anthologyCollectionPageView.showSubPageView(subPageView);
    } else {
      anthologyCollectionPageView = new AnthologyCollectionPageView({
        model: new Backbone.Model(attrs),
        mediaModel: new AnthologyCollectionModel({
          id: attrs.anthologyCollectionId,
        }),
        subPageView: subPageView,
      });

      return this.showMainBodyView(anthologyCollectionPageView);
    }
  }
}

export default AnthologyCollectionController;
