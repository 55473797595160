import L from "lodash/fp";
import Promise from "bluebird";
import BaseService from "scripts/services/baseService";
import UserOrganizationPreferencesLocalStorageModel from "scripts/models/userOrganizationPreferencesLocalStorageModel";
import { isBlank } from "../utils/generalHelpers";

class UserOrganizationPreferencesService extends BaseService {
  save(attrs) {
    attrs.id = this.setId(attrs.id);
    console.log("Saving user organization preferences %O", attrs);
    return new UserOrganizationPreferencesLocalStorageModel(attrs).save().catch(e => {
      console.log("Error saving user organization preferences: %O", e);
    });
  }

  getPreferences(attrs) {
    return this.fetchLocal(attrs)
      .then(model => {
        if (L.isNil(model)) {
          return Promise.resolve([]);
        }
        return Promise.resolve(model.get("organizationPreferences"));
      })
      .catch(e => console.log(e));
  }

  async addOrg(attrs) {
    try {
      const id = this.setId(attrs.id);
      const preferences = await this.getPreferences({ id });

      if (L.isEmpty(preferences)) {
        return this.save({
          id,
          organizationPreferences: attrs.orgIds,
        });
      }

      const newPreferences = L.filter(orgId => orgId !== attrs.newOrg, preferences);

      newPreferences.unshift(attrs.newOrg);

      await this.save({
        id,
        organizationPreferences: newPreferences,
      });
    } catch (e) {
      return null;
    }
  }

  async removeOrg(attrs) {
    try {
      const id = this.setId(attrs.id);
      const preferences = await this.getPreferences({ id });

      if (L.isEmpty(preferences)) {
        return this.save({
          id,
          organizationPreferences: attrs.orgIds,
        });
      }

      const newPreferences = L.filter(orgId => orgId !== attrs.orgId, preferences);

      const activeOrgId =
        L.indexOf(attrs.activeOrgId, newPreferences) < 0
          ? attrs.activeOrgId
          : L.head(newPreferences.splice(L.indexOf(attrs.activeOrgId, newPreferences), 1));
      newPreferences.unshift(activeOrgId);

      await this.save({
        id,
        organizationPreferences: newPreferences,
      });
    } catch (e) {
      return null;
    }
  }

  async updatePreferences(attrs) {
    try {
      const { activeOrganizationId } = attrs;
      const id = this.setId(attrs.id);
      const preferences = await this.getPreferences({ id });

      if (L.isEmpty(preferences)) {
        return this.save({
          id,
          organizationPreferences: attrs.orgIds,
        });
      }

      const activeOrgId =
        L.indexOf(activeOrganizationId, preferences) < 0
          ? activeOrganizationId
          : L.head(preferences.splice(L.indexOf(activeOrganizationId, preferences), 1));
      preferences.unshift(activeOrgId);

      await this.save({
        id,
        organizationPreferences: preferences,
      });
    } catch (e) {
      return null;
    }
  }

  fetchLocal(attrs) {
    const id = this.setId(attrs.id);
    return new UserOrganizationPreferencesLocalStorageModel({ id })
      .fetch()
      .then(model => {
        if (!model.has("id")) {
          console.log(model);
          return Promise.reject(model);
        }
        return Promise.resolve(model);
      })
      .catch(() => null);
  }

  setId(id) {
    return isBlank(id) ? "orgUser" : id;
  }
}

export default UserOrganizationPreferencesService;
