import _ from "lodash";
import HybridPageableMediaCollection from "scripts/collections/hybridPageableMediaCollection";

class CurationMediaCollection extends HybridPageableMediaCollection {
  constructor(models, options) {
    super(models, options);

    this.mediaId = options.mediaId;
  }

  /**
   * Filter out media without thumbnails. Currently,
   * audio content has no thumbnail.
   */
  thumbnailModels() {
    return _.filter(this.models, curationMediaModel => {
      return !(
        curationMediaModel.get("mediaType") === "content" &&
        curationMediaModel.get("type") === "AUDIO"
      );
    });
  }
}

export default CurationMediaCollection;
