var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":6},"end":{"line":15,"column":17}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data,"loc":{"start":{"line":14,"column":23},"end":{"line":14,"column":31}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":14,"column":33},"end":{"line":14,"column":42}}}) : helper)))
    + " ("
    + alias4((lookupProperty(helpers,"formatNumber")||(depth0 && lookupProperty(depth0,"formatNumber"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"count") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":14,"column":44},"end":{"line":14,"column":66}}}))
    + ")</option>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":38,"column":11}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"faceted-search-facet-widget-combobox-selected-term\">\n        <div class=\"selected-term-label\">\n          "
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":25,"column":10},"end":{"line":25,"column":19}}}) : helper)))
    + "\n          ("
    + alias4((lookupProperty(helpers,"formatNumber")||(depth0 && lookupProperty(depth0,"formatNumber"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"count") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":26,"column":11},"end":{"line":26,"column":33}}}))
    + ")\n        </div>\n        <button\n          aria-label=\"Remove "
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":29,"column":29},"end":{"line":29,"column":38}}}) : helper)))
    + "\"\n          value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data,"loc":{"start":{"line":30,"column":17},"end":{"line":30,"column":25}}}) : helper)))
    + "\"\n          class=\"bb-faceted-search-facet-multiselect-remove-button\"\n          data-toggle=\"tooltip\"\n          title=\"Remove\"\n        >\n          <i class=\"bbico bbico-times\"></i>\n        </button>\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<label class=\"faceted-search-facet-widget-header\" for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"facetLabel") || (depth0 != null ? lookupProperty(depth0,"facetLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"facetLabel","hash":{},"data":data,"loc":{"start":{"line":1,"column":55},"end":{"line":1,"column":69}}}) : helper)))
    + "\">\n  "
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"facetLabel") : stack1), depth0))
    + "\n</label>\n<div class=\"faceted-search-facet-widget-combobox\">\n  <select\n    id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"facetLabel") || (depth0 != null ? lookupProperty(depth0,"facetLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"facetLabel","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":22}}}) : helper)))
    + "\"\n    aria-label="
    + alias4(((helper = (helper = lookupProperty(helpers,"facetLabel") || (depth0 != null ? lookupProperty(depth0,"facetLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"facetLabel","hash":{},"data":data,"loc":{"start":{"line":7,"column":15},"end":{"line":7,"column":29}}}) : helper)))
    + "\n    class=\"selectize-combo\"\n    data-placeholder=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"placeholder") : stack1), depth0))
    + "\"\n  >\n    <option></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"terms") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":16,"column":13}}})) != null ? stack1 : "")
    + "  </select>\n</div>\n\n<div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"terms") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":2},"end":{"line":39,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});