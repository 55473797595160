import BaseView from "scripts/views/baseView";

class CommentLoadingDialogView extends BaseView {
  constructor(options) {
    super(options);

    this.$el = $("#comments-loading-modal");
  }

  render() {
    this.$el.modal("show");

    return this;
  }

  remove() {
    this.$el.modal("hide");

    return this;
  }
}

export default CommentLoadingDialogView;
