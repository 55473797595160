var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "active";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <span class=\"fnt-bld\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"username") || (depth0 != null ? lookupProperty(depth0,"username") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"username","hash":{},"data":data,"loc":{"start":{"line":30,"column":32},"end":{"line":30,"column":44}}}) : helper)))
    + "</span>\n          <div class=\"m-tb-10\">\n            <button id=\"bb-main-overlay-logout-link\"\n              class=\"btn btn-outline-rounded btn-outline-brand-primary text-uppercase\">\n              "
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"button") : stack1)) != null ? lookupProperty(stack1,"logOut") : stack1), depth0))
    + "\n            </button>\n          </div>\n          <button id=\"bb-main-overlay-change-password-link\" class=\"bb-btn-a clr-primary p-lr-10\">"
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"actions") : stack1)) != null ? lookupProperty(stack1,"changePassword") : stack1), depth0))
    + "</button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <h5 class=\"m-t-0\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"haveProfile") : stack1), depth0))
    + "</h5>\n          <button id=\"bb-main-overlay-sign-in-link\" class=\"btn-rounded btn-rounded-brand-primary\">\n            "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"button") : stack1)) != null ? lookupProperty(stack1,"signIn") : stack1), depth0))
    + "\n          </button>\n\n          <div class=\"m-tb-10\">\n            <a href=\"/sign-up/create-profile\" id=\"bb-main-overlay-sign-up-link\"\n              class=\"btn btn-outline-gray btn-outline-rounded-xs btn-xs fade-on-hover\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"dontHaveProfile") : stack1), depth0))
    + " <span class=\"fnt-bld\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"createYoursNow") : stack1), depth0))
    + "</span></a>\n          </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "        <nav aria-label=\"User menu\">\n          <ul>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasCuratorRole") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":12},"end":{"line":65,"column":19}}})) != null ? stack1 : "");
  stack1 = ((helper = (helper = lookupProperty(helpers,"hasOfflineBookshelf") || (depth0 != null ? lookupProperty(depth0,"hasOfflineBookshelf") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"hasOfflineBookshelf","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":12},"end":{"line":68,"column":36}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"hasOfflineBookshelf")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            <li><a href=\"/my-board/favorites\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"favorites") : stack1), depth0))
    + "</a></li>\n            <li><a href=\"/my-board/bookmarks\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"bookmarks") : stack1), depth0))
    + "</a></li>\n            <li><a href=\"/my-board/recents\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"recents") : stack1), depth0))
    + "</a></li>\n          </ul>\n        </nav>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <li><a href=\"/manage-curations\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"manageCurations") : stack1), depth0))
    + "</a></li>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <li><a href=\"/my-board/bookshelf\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"offlineBookshelf") : stack1), depth0))
    + "</a></li>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"fnt-bld\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"createProfile") : stack1)) != null ? lookupProperty(stack1,"optional") : stack1), depth0))
    + "</span><br />\n\n        <span>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"createProfile") : stack1)) != null ? lookupProperty(stack1,"unlockFeatures") : stack1), depth0))
    + "</span>\n\n        <div class=\"text-uppercase m-b-20 m-t-10\">\n          <span class=\"label bg-gray-dark\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"saveFavorites") : stack1), depth0))
    + "</span>\n          <span class=\"label bg-gray-dark\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"createBookmarks") : stack1), depth0))
    + "</span>\n          <span class=\"label bg-gray-dark\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"addNotes") : stack1), depth0))
    + "</span>\n          <span class=\"label bg-gray-dark\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"syncPreferences") : stack1), depth0))
    + "</span>\n        </div>\n\n        <div>\n          <a id=\"bb-main-overlay-create-profile-link\" href=\"/sign-up/create-profile\"\n            class=\"btn-outline-rounded btn-outline-brand-primary\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"button") : stack1)) != null ? lookupProperty(stack1,"createYourProfile") : stack1), depth0))
    + "</a>\n        </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <li><a href=\"/curations\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"curations") : stack1), depth0))
    + "</a></li>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li><a href=\"/search-results/_ot=%22VIDEO%22\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"actions") : stack1)) != null ? lookupProperty(stack1,"watch") : stack1), depth0))
    + "</a></li>\n            <li><a href=\"/search-results/_ot=%22BOOK%22%20OR%20%22OTHER_DOCUMENT%22%20OR%20%22ARTICLE%22\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"actions") : stack1)) != null ? lookupProperty(stack1,"read") : stack1), depth0))
    + "</a>\n            </li>\n            <li><a href=\"/search-results/_ot=%22AUDIO%22%20OR%20%22AUDIOBOOK%22%20OR%20%22ALBUM%22\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"actions") : stack1)) != null ? lookupProperty(stack1,"listen") : stack1), depth0))
    + "</a></li>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li>\n              <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"marketingUrl") || (depth0 != null ? lookupProperty(depth0,"marketingUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"marketingUrl","hash":{},"data":data,"loc":{"start":{"line":117,"column":23},"end":{"line":117,"column":39}}}) : helper)))
    + "\" target=\"_blank\" rel=\"noreferrer noopener\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"marketingUrlName") || (depth0 != null ? lookupProperty(depth0,"marketingUrlName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"marketingUrlName","hash":{},"data":data,"loc":{"start":{"line":117,"column":83},"end":{"line":117,"column":103}}}) : helper)))
    + "</a>\n            </li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"externalUrls") : depth0),{"name":"each","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":12},"end":{"line":125,"column":21}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <li>\n                <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" target=\"_blank\" rel=\"noreferrer noopener\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</a>\n              </li>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li><i class=\"bbico bbico-arrow-right\"></i> &nbsp;\n                  <a href=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"twitterUrl") || (depth0 != null ? lookupProperty(depth0,"twitterUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"twitterUrl","hash":{},"data":data,"loc":{"start":{"line":132,"column":27},"end":{"line":132,"column":41}}}) : helper)))
    + "\" target=\"_blank\"\n                     rel=\"noreferrer noopener\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"follow") : stack1)) != null ? lookupProperty(stack1,"twitter") : stack1), depth0))
    + "</a>\n                </li>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li><i class=\"bbico bbico-arrow-right\"></i> &nbsp;\n                  <a href=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"facebookUrl") || (depth0 != null ? lookupProperty(depth0,"facebookUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"facebookUrl","hash":{},"data":data,"loc":{"start":{"line":138,"column":27},"end":{"line":138,"column":42}}}) : helper)))
    + "\" target=\"_blank\"\n                     rel=\"noreferrer noopener\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"follow") : stack1)) != null ? lookupProperty(stack1,"facebook") : stack1), depth0))
    + "</a>\n                </li>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li><i class=\"bbico bbico-arrow-right\"></i> &nbsp;\n                  <a href=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"instagramUrl") || (depth0 != null ? lookupProperty(depth0,"instagramUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"instagramUrl","hash":{},"data":data,"loc":{"start":{"line":144,"column":27},"end":{"line":144,"column":43}}}) : helper)))
    + "\" target=\"_blank\"\n                     rel=\"noreferrer noopener\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"follow") : stack1)) != null ? lookupProperty(stack1,"instagram") : stack1), depth0))
    + "</a>\n                </li>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li><i class=\"bbico bbico-arrow-right\"></i> &nbsp;\n                  <a href=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"blogUrl") || (depth0 != null ? lookupProperty(depth0,"blogUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"blogUrl","hash":{},"data":data,"loc":{"start":{"line":150,"column":27},"end":{"line":150,"column":38}}}) : helper)))
    + "\" target=\"_blank\" rel=\"noreferrer noopener\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"follow") : stack1)) != null ? lookupProperty(stack1,"blog") : stack1), depth0))
    + "</a>\n                </li>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li>\n              <span class=\"fnt-bld text-italic\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"for") : stack1)) != null ? lookupProperty(stack1,"institutions") : stack1), depth0))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showLinksToMetadataPage") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":158,"column":14},"end":{"line":162,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"pledgeUrl") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":163,"column":14},"end":{"line":168,"column":21}}})) != null ? stack1 : "")
    + "            </li>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <ul>\n                  <li><i class=\"bbico bbico-arrow-right\"></i> &nbsp; <a href=\"/metadata-info\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"metadata") : stack1), depth0))
    + "</a></li>\n                </ul>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <ul>\n                  <li><i class=\"bbico bbico-arrow-right\"></i> &nbsp; <a href=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"pledgeUrl") || (depth0 != null ? lookupProperty(depth0,"pledgeUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"pledgeUrl","hash":{},"data":data,"loc":{"start":{"line":165,"column":78},"end":{"line":165,"column":91}}}) : helper)))
    + "\" target=\"_blank\"\n                      rel=\"noreferrer noopener\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"pledgeSupport") : stack1), depth0))
    + "</a></li>\n                </ul>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li>\n              <span class=\"fnt-bld text-italic\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"for") : stack1)) != null ? lookupProperty(stack1,"publishers") : stack1), depth0))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"publisherParticipationLink") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":174,"column":14},"end":{"line":179,"column":21}}})) != null ? stack1 : "")
    + "            </li>\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <ul>\n                  <li><i class=\"bbico bbico-arrow-right\"></i> &nbsp; <a href=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"publisherParticipationLink") || (depth0 != null ? lookupProperty(depth0,"publisherParticipationLink") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"publisherParticipationLink","hash":{},"data":data,"loc":{"start":{"line":176,"column":78},"end":{"line":176,"column":108}}}) : helper)))
    + "\"\n                      target=\"_blank\" rel=\"noreferrer noopener\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"participate") : stack1), depth0))
    + "</a>\n                </ul>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li>\n              <span class=\"fnt-bld text-italic\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"for") : stack1)) != null ? lookupProperty(stack1,"authors") : stack1), depth0))
    + "</span>\n              <ul>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"authorsOpenAccessInfoUrl") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":186,"column":16},"end":{"line":189,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"pressbooksUrl") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":190,"column":16},"end":{"line":193,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"partnerSelfEUrl") : depth0),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":194,"column":16},"end":{"line":197,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSelfe") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":198,"column":16},"end":{"line":201,"column":23}}})) != null ? stack1 : "")
    + "              </ul>\n            </li>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <li><i class=\"bbico bbico-arrow-right\"></i> &nbsp; <a href=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"authorsOpenAccessInfoUrl") || (depth0 != null ? lookupProperty(depth0,"authorsOpenAccessInfoUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"authorsOpenAccessInfoUrl","hash":{},"data":data,"loc":{"start":{"line":187,"column":78},"end":{"line":187,"column":108}}}) : helper))) != null ? stack1 : "")
    + "\"\n                      target=\"_blank\" rel=\"noreferrer noopener\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"openAccessAuthors") : stack1), depth0))
    + "</a></li>\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <li><i class=\"bbico bbico-arrow-right\"></i> &nbsp; <a href=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"pressbooksUrl") || (depth0 != null ? lookupProperty(depth0,"pressbooksUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"pressbooksUrl","hash":{},"data":data,"loc":{"start":{"line":191,"column":78},"end":{"line":191,"column":97}}}) : helper))) != null ? stack1 : "")
    + "\" target=\"_blank\"\n                      rel=\"noreferrer noopener\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"createYourBook") : stack1), depth0))
    + "</a></li>\n";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <li><i class=\"bbico bbico-arrow-right\"></i> &nbsp; <a href=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"partnerSelfEUrl") || (depth0 != null ? lookupProperty(depth0,"partnerSelfEUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"partnerSelfEUrl","hash":{},"data":data,"loc":{"start":{"line":195,"column":78},"end":{"line":195,"column":99}}}) : helper))) != null ? stack1 : "")
    + "\" target=\"_blank\"\n                      rel=\"noreferrer noopener\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"shareYourBook") : stack1), depth0))
    + "</a></li>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <li><i class=\"bbico bbico-arrow-right\"></i> &nbsp; <a href=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"generalSelfEUrl") || (depth0 != null ? lookupProperty(depth0,"generalSelfEUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"generalSelfEUrl","hash":{},"data":data,"loc":{"start":{"line":199,"column":78},"end":{"line":199,"column":99}}}) : helper))) != null ? stack1 : "")
    + "\" target=\"_blank\"\n                      rel=\"noreferrer noopener\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"learnAboutIAP") : stack1), depth0))
    + "</a></li>\n";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <a href=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"externalAboutPageUrl") || (depth0 != null ? lookupProperty(depth0,"externalAboutPageUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"externalAboutPageUrl","hash":{},"data":data,"loc":{"start":{"line":210,"column":27},"end":{"line":210,"column":51}}}) : helper)))
    + "\" target=\"_blank\" rel=\"noreferrer noopener\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"aboutUs") : stack1), depth0))
    + "</a>\n";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <a href=\"/about\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"aboutUs") : stack1), depth0))
    + "</a>\n";
},"52":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li><i class=\"bbico bbico-arrow-right\"></i> &nbsp; <a\n                    href=\"mailto:"
    + alias1(((helper = (helper = lookupProperty(helpers,"orgSupportEmail") || (depth0 != null ? lookupProperty(depth0,"orgSupportEmail") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"orgSupportEmail","hash":{},"data":data,"loc":{"start":{"line":217,"column":33},"end":{"line":217,"column":52}}}) : helper)))
    + "?bcc=support@biblioboard.com\" target=\"_blank\"\n                    rel=\"noreferrer noopener\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"support") : stack1), depth0))
    + "</a>\n                </li>\n";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li><i class=\"bbico bbico-arrow-right\"></i> &nbsp; <a href=\"mailto:support@biblioboard.com\"\n                    target=\"_blank\" rel=\"noreferrer noopener\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"support") : stack1), depth0))
    + "</a>\n                </li>\n";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li><i class=\"bbico bbico-arrow-right\"></i> &nbsp; <a href=\"/eula\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"legalNotices") : stack1), depth0))
    + "</a></li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container\" style=\"position: relative;\">\n\n  <div id=\"bb-main-overlay-change-language-region\" class=\"flt-right\">\n      <div class=\"btn-group\">\n        <button \n            type=\"button\" \n            class=\"btn btn-rounded btn-rounded-brand-primary dropdown-toggle\" \n            data-toggle=\"dropdown\" \n            aria-haspopup=\"true\" \n            aria-expanded=\"false\"\n        >\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"currentLanguage") || (depth0 != null ? lookupProperty(depth0,"currentLanguage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currentLanguage","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":31}}}) : helper)))
    + " <span><i class=\"fa fa-angle-down\"></i></span>\n        </button>\n        <ul class=\"dropdown-menu\">\n            <li><button value=\"en\" class=\"change-language-btn btn-text "
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"currentLanguageCode") : depth0),"en",{"name":"equal","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":71},"end":{"line":15,"column":122}}})) != null ? stack1 : "")
    + "\">\n                "
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"languages") : depth0)) != null ? lookupProperty(stack1,"en") : stack1), depth0))
    + "  <span class=\"m-l-20\"><i class=\"bbico bbico-check\"></i></span>\n            </button></li>\n            <li><button value=\"ar\" class=\"change-language-btn btn-text "
    + ((stack1 = (lookupProperty(helpers,"equal")||(depth0 && lookupProperty(depth0,"equal"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"currentLanguageCode") : depth0),"ar",{"name":"equal","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":71},"end":{"line":18,"column":122}}})) != null ? stack1 : "")
    + "\">\n                "
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"languages") : depth0)) != null ? lookupProperty(stack1,"ar") : stack1), depth0))
    + "  <span class=\"m-l-20\"><i class=\"bbico bbico-check\"></i></span>\n            </button></li>\n        </ul>\n      </div>\n  </div>\n  <div class=\"row\">\n    <div id=\"bb-main-overlay-profile-row\" class=\"col-xs-8 col-sm-12 col-md-5\">\n      <img id=\"main-overlay-menu-logo\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"logo") || (depth0 != null ? lookupProperty(depth0,"logo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"logo","hash":{},"data":data,"loc":{"start":{"line":26,"column":44},"end":{"line":26,"column":52}}}) : helper)))
    + "\" class=\"bb-main-overlay-logo m-r-20 m-b-20\" alt=\"BiblioBoard\" />\n\n      <div class=\"flt-left m-b-20\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"userId") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":29,"column":8},"end":{"line":48,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n\n    <div class=\"col-xs-12 col-md-7 m-t-30\">\n      <nav id=\"bb-main-overlay-change-org-region\" aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"changeOrgMenu") : stack1), depth0))
    + "\"></nav>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-md-4 m-b-30\">\n      <h4><i class=\"bbico bbico-symbol-circle\"></i>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1)) != null ? lookupProperty(stack1,"myBoard") : stack1), depth0))
    + "</h4>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"userId") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":60,"column":6},"end":{"line":90,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"col-md-4 m-b-30\">\n      <h4><i class=\"bbico bbico-search-circle\"></i>"
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"actions") : stack1)) != null ? lookupProperty(stack1,"explore") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "</h4>\n      <nav aria-label=\"explore menu\">\n        <ul>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideCurations") : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":10},"end":{"line":98,"column":21}}})) != null ? stack1 : "")
    + "          <li><a href=\"/categories\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"categories") : stack1), depth0))
    + "</a></li>\n          <li><a href=\"/modules\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"modules") : stack1), depth0))
    + "</a></li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideWatchReadListen") : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":10},"end":{"line":106,"column":21}}})) != null ? stack1 : "")
    + "          <li class=\"text-uppercase\"><a href=\"/home\"><i class=\"bbico bbico-home m-r-5\"></i>"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"home") : stack1), depth0))
    + "</a></li>\n        </ul>\n      </nav>\n    </div>\n    <div class=\"col-md-4 m-b-30\">\n      <h4><i class=\"bbico bbico-more-circle\"></i>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"button") : stack1)) != null ? lookupProperty(stack1,"more") : stack1), depth0))
    + "</h4>\n      <nav aria-label=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"ariaLabel") : stack1)) != null ? lookupProperty(stack1,"externalLinks") : stack1), depth0))
    + "\">\n        <ul>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"marketingUrl") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":10},"end":{"line":119,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"externalUrls") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":120,"column":10},"end":{"line":126,"column":17}}})) != null ? stack1 : "")
    + "          <li>\n            <span class=\"fnt-bld text-italic\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"keepUp") : stack1), depth0))
    + "</span>\n            <ul>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"twitterUrl") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":130,"column":14},"end":{"line":135,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"facebookUrl") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":136,"column":14},"end":{"line":141,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"instagramUrl") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":142,"column":14},"end":{"line":147,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"blogUrl") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":14},"end":{"line":152,"column":21}}})) != null ? stack1 : "")
    + "            </ul>\n          </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showInstitutionsMenu") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":155,"column":10},"end":{"line":170,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPublishersMenu") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":10},"end":{"line":181,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAuthorsMenu") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":182,"column":10},"end":{"line":204,"column":17}}})) != null ? stack1 : "")
    + "          <li>\n            <span class=\"fnt-bld text-italic\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"about") : stack1), depth0))
    + "</span>\n            <ul>\n              <li><i class=\"bbico bbico-arrow-right\"></i> &nbsp;\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"externalAboutPageUrl") : depth0),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.program(50, data, 0),"data":data,"loc":{"start":{"line":209,"column":16},"end":{"line":213,"column":23}}})) != null ? stack1 : "")
    + "              </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"orgSupportEmail") : depth0),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.program(54, data, 0),"data":data,"loc":{"start":{"line":215,"column":14},"end":{"line":224,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showLegalNotices") : depth0),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":225,"column":14},"end":{"line":227,"column":21}}})) != null ? stack1 : "")
    + "            </ul>\n          </li>\n        </ul>\n      </nav>\n    </div>\n  </div>\n</div>";
},"useData":true});