import BaseView from "scripts/views/baseView";
import Velocity from "velocity-animate";
import templateLoader from "templates/loader.hbs";

class LoaderView extends BaseView {
  get template() {
    return templateLoader;
  }

  render() {
    this.$el.html(this.template());
    return this;
  }

  startAnimation() {
    this.animate();
  }

  animate() {
    const icons = this.el.querySelectorAll("i");

    Velocity.animate(icons, "transition.bounceIn", {
      stagger: 125,
    });

    return Velocity.animate(icons, "transition.bounceOut").then(
      this.animate.bind(this),
    );
  }

  stopAnimation() {
    const icons = this.el.querySelectorAll("i");

    Velocity.animate(icons, "stop", true);
    $(icons).attr("style", "");
  }

  close() {
    this.stopAnimation();
    super.close();
  }
}

export default LoaderView;
