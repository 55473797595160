const domTokenListToggle = DOMTokenList.prototype.toggle;

DOMTokenList.prototype.toggle = function() {
  if (arguments.length === 2) {
    if (arguments[1]) {
      return this.add(arguments[0]);
    } else {
      return this.remove(arguments[0]);
    }
  } else {
    return domTokenListToggle.apply(this, arguments);
  }
};
