var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <div class=\"row m-0 p-0\">\n\n        <div class=\"m-b-10\">\n            <a href=\"#\"\n               id=\"bb-content-details-page-all-notes-sub-page-add-note\"\n               class=\"bb-content-details-page-all-notes-sub-page-add-note hide btn-rounded btn-solid-brand-primary\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"note") : stack1)) != null ? lookupProperty(stack1,"add") : stack1), depth0))
    + "</a>\n            <a href=\"#\"\n               id=\"bb-content-details-page-all-notes-sub-page-add-first-note\"\n               class=\"bb-content-details-page-all-notes-sub-page-add-note hide btn-rounded btn-solid-brand-primary\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"note") : stack1)) != null ? lookupProperty(stack1,"addFirst") : stack1), depth0))
    + "</a>\n        </div>\n\n        <div class=\"m-b-10\">\n            <!--sorting controls-->\n            <div class=\"form-group\">\n                <select aria-label=\"Sort by\" id=\"bb-content-details-page-all-notes-sub-page-sort\" class=\"form-control input-outline\">\n                    <option value=\"ADDED_DESC\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"date") : stack1)) != null ? lookupProperty(stack1,"new") : stack1), depth0))
    + "</option>\n                    <option value=\"ADDED_ASC\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"date") : stack1)) != null ? lookupProperty(stack1,"oldest") : stack1), depth0))
    + "</option>\n                    <option value=\"PAGE_ASC\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"page") : stack1)) != null ? lookupProperty(stack1,"lowest") : stack1), depth0))
    + "</option>\n                    <option value=\"PAGE_DESC\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"sortBy") : stack1)) != null ? lookupProperty(stack1,"page") : stack1)) != null ? lookupProperty(stack1,"highest") : stack1), depth0))
    + "</option>\n                </select>\n            </div>\n            <!--///sorting controls-->\n\n            <!--filtering controls-->\n            <div class=\"row m-0 p-0\">\n                <a href=\"#\" class=\"filter filter-all active\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"filter") : stack1)) != null ? lookupProperty(stack1,"all") : stack1), depth0))
    + "</a>\n                <a href=\"#\" class=\"filter filter-global\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"filter") : stack1)) != null ? lookupProperty(stack1,"global") : stack1), depth0))
    + "</a>\n                <a href=\"#\" class=\"filter filter-page\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"filter") : stack1)) != null ? lookupProperty(stack1,"page") : stack1), depth0))
    + "</a>\n            </div>\n            <!--///filtering controls-->\n        </div>\n\n    </div>\n\n    <div id=\"bb-content-details-page-all-notes-sub-page-notes-region\"></div>\n</div>";
},"useData":true});