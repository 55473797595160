import inject from "scripts/ioc/inject";
import BaseMediaSalvattoreTileView from "scripts/views/baseMediaSalvattoreTileView";
import templateModuleSalvattoreTile from "templates/moduleSalvattoreTile.hbs";

class ModuleSalvattoreTileView extends BaseMediaSalvattoreTileView {
  constructor(options, securityService = inject("securityService")) {
    super(options, securityService);
  }

  get template() {
    return templateModuleSalvattoreTile;
  }
}

export default ModuleSalvattoreTileView;
