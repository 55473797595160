var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"playlistIndex") || (depth0 != null ? lookupProperty(depth0,"playlistIndex") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"playlistIndex","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":21}}}) : helper)))
    + "</td>\n<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"rowTitle") || (depth0 != null ? lookupProperty(depth0,"rowTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rowTitle","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":16}}}) : helper)))
    + "</td>\n<td>"
    + alias4((lookupProperty(helpers,"formatSeconds")||(depth0 && lookupProperty(depth0,"formatSeconds"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"audioDurationSeconds") : depth0),{"name":"formatSeconds","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":42}}}))
    + "</td>";
},"useData":true});