import _ from "lodash";
import Promise from "bluebird";
import L from "lodash/fp";
import { purep, reject } from "bluebird-promisell";

export const propEq = L.curry((prop, value, obj) => !L.isNil(obj) && !L.isEmpty(obj) && obj[prop] === value);

export const equals = L.curry((one, two) => one === two);

export const not = a => !a;

export const complement = f => L.compose(not, f);

//TODO: replace with a general invoke ala Ramda
export const invokeGetter = L.curry((get, obj) => obj[get]());

//TODO: rename tap
export const pass = f => a => {
  f(a);
  return a;
};

export const isInstance = L.curry((clazz, o) => o instanceof clazz);

export const when = L.curry((predicate, f, x) => (predicate(x) ? f(x) : x));
export const unless = L.curry((predicate, f, x) => (predicate(x) ? x : f(x)));
export const ifElse = L.curry((predicate, yes, no, x) => (predicate(x) ? yes(x) : no(x)));

// promise stuff below... (eventually move these into a separate helper)

export const catchIfP = L.curry((predicate, f, error) => (predicate(error) ? f(error) : reject(error)));

// passP :: (a -> Promise a) -> a -> Promise a
export const passP = f => a => f(a).then(() => a);

export const waitForP = p => a => p.then(() => a);

export const delayP = L.curry(
  (delay, a) =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(a);
      }, delay);
    }),
);

// filterP :: predicate -> Promise -> Promise
export const filterP = L.curry((predicate, p) => p.then(v => (predicate(v) ? v : null)));

export const nullP = () => purep(null);

export const andThenP = L.curry((f, p) => p.then(f));

export const isBlank = value => value === undefined || L.isNil(value) || L.isEmpty(value);

export const getObjectDiff = (obj1, obj2) => {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
};
