import Promise from "bluebird";

export const fetchPosition = geolocation => {
  return new Promise((resolve, reject) => {
    const t0 = performance.now();

    if (geolocation) {
      geolocation.getCurrentPosition(
        position => {
          const timeTaken = performance.now() - t0;

          window.sessionStorage.setItem(
            "lastSeenCoords",
            JSON.stringify({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            }),
          );

          resolve({
            position: position,
            timeTaken: timeTaken,
          });
        },
        reject,
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 300000,
        },
      );
    } else {
      reject(
        new Error(
          "Geolocation services are not supported by this device or browser",
        ),
      );
    }
  });
};
