import BaseView from "scripts/views/baseView";
import CommentThreadMenuView from "scripts/views/commentThreadMenuView";
import CommentFormView from "scripts/views/commentFormView";
import CommentsView from "scripts/views/commentsView";

class CommentThreadView extends BaseView {
  get events() {
    return {
      "submit .comment-new-form": "addComment",
      "click .comment-load-next": "loadNext",
      "click .comments-thread-sort-action": "sort",
    };
  }

  constructor(options) {
    super(options);

    this.disqusModel = this.model.get("disqusModel");

    this.model
      .get("comments")
      .fetch()
      .then(() => {
        this.threadMenuView = this.addSubView(
          new CommentThreadMenuView({
            model: this.model,
          }),
        );

        this.threadCommentForm = this.addSubView(
          new CommentFormView({
            disqusModel: this.disqusModel,
          }),
        );

        this.commentsView = this.addSubView(
          new CommentsView({
            model: this.model.get("comments"),
            filter(comment) {
              return comment && !comment.get("parentId");
            },
          }),
        );
      })
      .then(this.render.bind(this));

    this.$commentsContainer = this.$el.find(".comments-container");
  }

  remove() {
    this.$commentsContainer.empty();
  }

  render() {
    this.$el.append(this.threadMenuView.render().el);

    // Only show the comments if the user is authenticated
    if (this.disqusModel.get("disqusAuthentication")) {
      this.$el.append(this.threadCommentForm.render().el);
    } else {
      // Show an alert informing the user they must be logged in to participate
      this.$el.append(
        $(document.createElement("div"))
          .addClass("alert alert-warning text-center")
          .text("You must be logged in to comment."),
      );
    }

    this.$el.append(
      $(document.createElement("div"))
        .addClass("row")
        .html(this.commentsView.render().$el.addClass("col-xs-12")),
    );

    return this;
  }

  addComment(event) {
    event.preventDefault();

    const $input = this.$el.find(".comment-input-content");

    const data = {
      textMessage: $input.val(),
    };

    this.model.get("comments").create(data, { wait: true });

    $input.val("");
  }

  loadNext() {
    this.model.get("comments").loadNext();
  }

  sort(event) {
    let $target = $(event.currentTarget);

    this.$el
      .find(".comments-thread-sort")
      .text($target.text() + " ")
      .append($(document.createElement("span")).addClass("caret"));

    let collection = this.model.get("comments");

    if ($target.hasClass("sort-new")) {
      collection.setSort("NEW", true);
    } else if ($target.hasClass("sort-old")) {
      collection.setSort("OLD", true);
    }
  }
}

export default CommentThreadView;
