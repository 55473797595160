import _ from "lodash";
import Backbone from "backbone";
import Velocity from "velocity-animate";

const chan = Backbone.Radio.channel;

const HorizontalScrollerBehavior = View => {
  return class extends View {
    initializeScrollerBehavior() {
      chan("display").off(null, this.updateScrollButtonVisibility, this);
      chan("display").on(
        "window:resize",
        this.updateScrollButtonVisibility,
        this,
      );

      this.$container = this.$el.find(".bb-horizontal-scroller-container");
      this.$scrollingRegion = this.$el.find(
        ".bb-horizontal-scroller-scrolling-region",
      );
      this.$content = this.$el.find(".bb-horizontal-scroller-content");

      this.$leftButton = this.$el.find(".bb-horizontal-scroller-left-button");
      this.$rightButton = this.$el.find(".bb-horizontal-scroller-right-button");

      this.$scrollingRegion.on(
        "scroll",
        _.debounce(() => {
          this.updateScrollButtonVisibility();
        }, 100),
      );

      setTimeout(() => this.updateScrollButtonVisibility(), 0);
    }

    updateScrollButtonVisibility() {
      if (this.canScrollLeft()) {
        this.$leftButton.removeClass("hidden");
      } else {
        this.$leftButton.addClass("hidden");
      }

      if (this.canScrollRight()) {
        this.$rightButton.removeClass("hidden");
      } else {
        this.$rightButton.addClass("hidden");
      }

      if (chan("display").request("isLargerThanSm")) {
        this.$scrollingRegion.css("overflow-x", "hidden");
      } else {
        this.$scrollingRegion.css("overflow-x", "auto");
      }
    }

    canScrollLeft() {
      return Math.ceil(this.$scrollingRegion.scrollLeft()) > 0;
    }

    canScrollRight() {
      const scrollLeft = this.$scrollingRegion.scrollLeft();
      const scrollingRegionWidth = this.$scrollingRegion.width();
      const contentWidth = this.$content.width();

      return contentWidth - scrollLeft > scrollingRegionWidth;
    }

    scrollLeft() {
      this.incrementScrollLeft(-1 * this.getScrollOffset());
    }

    scrollRight() {
      this.incrementScrollLeft(this.getScrollOffset());
    }

    getScrollOffset() {
      return (
        this.$scrollingRegion.width() -
        (this.$leftButton.width() + this.$rightButton.width())
      );
    }

    incrementScrollLeft(amount) {
      Velocity.animate(this.$scrollingRegion, "scroll", {
        container: this.$scrollingRegion,
        offset: amount,
        axis: "x",
        easing: "easeInOut",
        mobileHA: false,
        duration: 400,
      }).then(() => {
        this.updateScrollButtonVisibility();
      });
    }

    onClickScrollLeft() {
      this.scrollLeft();
    }

    onClickScrollRight() {
      this.scrollRight();
    }
  };
};

export default HorizontalScrollerBehavior;
