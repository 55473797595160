import BaseView from "scripts/views/baseView";
import templatePager from "templates/elliipsisPager.hbs";
import L from "lodash/fp";
import inject from "scripts/ioc/inject";

class EllipsisPagerView extends BaseView {
  get template() {
    return templatePager;
  }

  get events() {
    return {
      "click .bb-pager-view-first-page-link": "onClickFirstPage",
      "click .bb-pager-previous-page-link": "onClickPreviousPage",
      "click .bb-pager-next-page-link": "onClickNextPage",
      "click .bb-pager-page-btn": "onClickPage",
    };
  }

  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.mediaView = this.addSubView(options.mediaView);

    this.pageNumber = options.pageNumber;
    this.totalPages = options.totalPages;
    this.hasPreviousPage = options.hasPreviousPage;
    this.hasNextPage = options.hasNextPage;
    this.pageList = this.pagination(options.pageNumber, options.totalPages);
  }

  pagination(current, total) {
    if (total <= 1) {
      return [1];
    }

    const center = [
        current - 2,
        current - 1,
        current,
        current + 1,
        current + 2,
      ],
      filteredCenter = center.filter(p => p > 1 && p < total),
      includeThreeLeft = current === 5,
      includeThreeRight = current === total - 4,
      includeLeftDots = current > 5,
      includeRightDots = current < total - 4;

    if (includeThreeLeft) filteredCenter.unshift(2);
    if (includeThreeRight) filteredCenter.push(total - 1);

    if (includeLeftDots) filteredCenter.unshift("X");
    if (includeRightDots) filteredCenter.push("X");

    return [1, ...filteredCenter, total];
  }

  render() {
    this.$el.html(
      this.template({
        hasPreviousPage: this.hasPreviousPage,
        hasNextPage: this.hasNextPage,
        i18n: this.getTranslations(),
        pageNumber: this.pageNumber,
        totalPages: this.totalPages,
        pageList: this.pageList,
        showPager:
          this.totalPages > 1 || this.hasNextPage || this.hasPreviousPage,
      }),
    );

    this.mediaView
      .appendTo(this.$el.find(".bb-pager-media-view-region"))
      .render();

    const currentPageSelector = `.pager-page-num-${this.pageNumber}`;
    const $currentPageLink = $(currentPageSelector);
    $currentPageLink.addClass("active");

    return this;
  }

  getTranslations() {
    const attributes = this.i18nextService.getAttributes();
    const glossary = this.i18nextService.getGlossary();

    const ariaLabel = L.flowRight(
      L.pick(["nextPage", "pager", "prevPage"]),
      L.get(["ariaLabel"]),
    )(attributes);

    const { pager } = glossary;

    return { ariaLabel, pager };
  }

  onClickFirstPage() {
    this.trigger("pageSequenceChanged", 1);
  }

  onClickNextPage() {
    this.trigger("pageSequenceChanged", this.pageNumber + 1);
  }

  onClickPreviousPage() {
    this.trigger("pageSequenceChanged", this.pageNumber - 1);
  }

  onClickPage(e) {
    const pageNumber = e.target.value;
    this.trigger("pageSequenceChanged", pageNumber);
  }
}

export default EllipsisPagerView;
