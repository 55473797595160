import Backbone from "backbone";
import BaseApiModel from "scripts/models/baseApiModel";

const chan = Backbone.Radio.channel;

class UserPreferenceModel extends BaseApiModel {
  defaults() {
    return {
      id: chan("security")
        .request("user")
        .getUserId(),
    };
  }

  toJSON() {
    let jsonData = super.toJSON(arguments);

    // The server must not receive the id for this model
    if (jsonData.id) {
      delete jsonData.id;
    }

    jsonData.contentView = jsonData.contentView.toUpperCase();
    if (jsonData.contentView === "MASONRY") {
      jsonData.contentView = "VISUAL";
    }

    return jsonData;
  }

  urlRoot() {
    return `${this.apiUrlRoot}/users`;
  }
}

export default UserPreferenceModel;
