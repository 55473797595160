import BaseView from "scripts/views/baseView";
import MediaSalvattoreGridView from "scripts/views/mediaSalvattoreGridView";
import templateModulesPageSalvattoreSubPage from "templates/modulesPageSalvattoreSubPage.hbs";
import ModulesPageNavView from "./modulesPageNavView";

class ModulesPageSalvattoreSubPageView extends BaseView {
  get template() {
    return templateModulesPageSalvattoreSubPage;
  }

  constructor(options) {
    super(options);

    this.model.set("collection", this.collection);
    this.navView = this.addSubView(
        new ModulesPageNavView({
          model: this.model,
        }),
    );

    this.salvattoreView = this.addSubView(
      new MediaSalvattoreGridView({
        collection: this.collection,
      }),
    );
  }

  render() {
    this.$el.html(
      this.template({
        moduleCount: this.collection.length,
      }),
    );

    this.navView
        .attachTo(this.$el.find("#bb-modules-page-sticky-nav-region"))
        .render();

    this.salvattoreView
      .appendTo(
        $("#bb-modules-page-salvattore-sub-page-salvattore-view-region"),
      )
      .render();

    return this;
  }

  sync() {
    return this.salvattoreView.fetch();
  }
}

export default ModulesPageSalvattoreSubPageView;
